import React from 'react'

import type { BoxProps } from '@mui/material';
import { Typography } from '@mui/material'

import { Box, useTheme } from '@mui/material'
import { Button, IconListWithBulletsFull } from 'design-system'

export interface RuleActivityLogFooterProps extends BoxProps {
  onShowMoreClick?: () => void
  rangeStart: number
  rangeEnd: number
  total: number
  disableButton?: boolean
}

const testId = 'RuleActivityLogFooter'
export const RuleActivityLogFooter = (props: RuleActivityLogFooterProps) => {
  const {
    sx,
    onShowMoreClick,
    rangeStart,
    rangeEnd,
    total,
    disableButton,
    ...otherProps
  } = props;

  const theme = useTheme();

  return (
    <Box
      data-testid={testId}
      className={testId}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        ...sx
      }}
      {...otherProps}
    >

      {
        rangeEnd < total && (
          <Button
            disabled={disableButton}
            sizeVariant={"small"}
            sx={{
              width: '100%',
              maxWidth: 170,
              justifyContent: 'space-between',
              paddingX: '15px',
              marginRight: '30px'
            }}
            onClick={onShowMoreClick}
          >
            <Box
              sx={{ 
                fontSize: '15px',
                fontWeight: 600,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              Show more 
              <IconListWithBulletsFull
                fill={disableButton ? theme.palette.coinrule.basic.grey : theme.palette.coinrule.brand.primaryDark}
                width={"17px"}
                height={'17px'}
              />
            </Box>
          </Button>
        )
      }

      <Typography
        sx={{
          fontSize: '15px',
          fontWeight: 600,
        }}
      > 
        {rangeStart} — {rangeEnd} 
        <Box
          component={'span'}
          sx={{
            fontWeight: 400,
            marginLeft: '5px'
          }}
        >
          from {total}
        </Box>
      </Typography>

    </Box>
  )
}

export default RuleActivityLogFooter
