import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { toJS, autorun } from 'mobx';
import stores from 'stores';
import { getCoinData, numberWithCommas } from 'helpers/utils';

const AboutCoin = observer(({ exchangeId, coin }) => {
  const [coinData, setCoinData] = useState(null);

  useEffect(() => {
    const { info, userInfo } = stores;
    // @FIXME this is probably unnecessary call as we already should have lists loaded
    info.getLists().then((authorized) => stores.app.unauthorized(authorized));
    userInfo.getExchanges().then((authorized) => stores.app.unauthorized(authorized));
  }, []);

  useEffect(
    () =>
      autorun(() => {
        const assets = toJS(stores.exchangeInfo.assets);

        const _coinData =
          assets && Object.keys(assets)?.length ? getCoinData({ assets, exchangeId, coin }) : null;

        if (_coinData) setCoinData(_coinData);
      }),
    [coin, exchangeId]
  );

  if (!coinData) return null;

  const { name, base, volume, price, growth } = coinData;

  // TODO: Confirm when it's growth and when it's not
  // isGrowth serving as a placeholder determinant;
  const isGrowth = growth > 1;

  return (
    <div className={'about-coin'}>
      <p className='coin-text'>
        {name} price today is <span className='purple-text'>${numberWithCommas(`${price}`)}</span>{' '}
        with a 24-hour trading volume of{' '}
        <span className='purple-text'>${numberWithCommas(`${volume}`)} </span>. {base} price is{' '}
        {isGrowth ? 'up' : 'down'}{' '}
        <span className='purple-text'>{numberWithCommas(`${growth}`)}%</span> in the last 24 hours.
      </p>
    </div>
  );
});

export default AboutCoin;
