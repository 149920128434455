import React from 'react';

import './style.scss';

import { ReactComponent as DotIcon } from 'assets/images/Onboarding/DotIcon.svg';

export const tGraphCls = 'TemplateGraph';

export interface IIndicator {
  point: 'low' | 'high';
  value: string;
}
export interface IIndicatorProps extends IIndicator {}
export const Indicator: React.FC<IIndicatorProps> = ({ point, value }) => (
  <>
    <div className={`${tGraphCls}__indicator ${tGraphCls}__indicator--${point}`}>
      {' '}
      <DotIcon fill={point === 'low' ? 'red' : 'blue'} /> {point}: {value}{' '}
    </div>
    <div className={`${tGraphCls}__line ${tGraphCls}__line--${point}`} />
  </>
);

export interface IDetailGraphIndicator {
  pointer?: React.ReactNode;
  indicator?: React.ReactNode;
}
export interface ITemplateGraphProps {
  indicators?: IIndicator[];
  bemCls?: string;
  type?: 'details' | 'option';
  lowIndicator?: IDetailGraphIndicator;
  highIndicator?: IDetailGraphIndicator;
  hideIndicators?: boolean;
}

export type TTemplateGraph = React.FC<ITemplateGraphProps>;

const TemplateGraph: TTemplateGraph = ({
  children,
  indicators,
  bemCls = '',
  type = 'option',
  lowIndicator,
  highIndicator,
  hideIndicators,
}) => {
  const cls = `${tGraphCls}-${bemCls}`;

  const renderIndicator = () => {
    if (hideIndicators) return;

    if (type === 'details')
      return (
        <>
          {lowIndicator?.indicator}
          {lowIndicator?.pointer}

          {highIndicator?.indicator}
          {highIndicator?.pointer}
        </>
      );

    return indicators?.map((indicator, index) => <Indicator key={index} {...indicator} />);
  };

  return (
    <div
      className={`${tGraphCls}__container ${cls}__container ${
        hideIndicators ? `${tGraphCls}__container--hideIndicator` : ''
      }`}
    >
      {renderIndicator()}
      {children}
    </div>
  );
};

export default TemplateGraph;
