import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ACTION_TYPE, CONDITION_TYPE, NOTIFY_TYPE } from '../../../stores/addRule';
import DetailTextTrigger from './Trigger';
import Warning from './Warning';
import stores from '../../../stores';
import { getCoinValue } from '../../../pages/AddRulePage/ShowCoinsValue';
import { EXCHANGES_TYPE } from '../../../constants/exchanges';

import './style.scss';
import { toJS } from 'mobx';
import { ErrorBoundary } from '../../ErrorBoundary';
import { ReactComponent as CopyIcon } from 'assets/images/icons/copy.svg';
import { ClickToCopy } from 'components/ClickToCopy';
import HookMessage, { getHookMessage } from './HookMessage';
import SequenceBaseText from './SequenceBaseText';
import SequenceDetailText from './SequenceDetailText';

@observer
class DetailSequenceTexts extends Component {
  handleSectionClick = (sectionIndex) => {
    const targetBlock = document.getElementById(`sequence${sectionIndex}`);
    const targetBlockPositioning = targetBlock ? targetBlock.offsetTop : null;

    if (targetBlock) {
      window.scrollTo({
        top: targetBlockPositioning,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  retrieveActionParameters() {
    // Base condition for warning is that it should not be showed if first action of the rule has another action right after it.

    // Check if rule sequence contains action and is first action doesn't followed by another action
    const seqInitSequences = [...toJS(this.props.store.seqInit.sequences)];
    let firstActionIndex = seqInitSequences.findIndex(
      (element) => element.type?.toLowerCase() === 'action'
    );
    let isFirstActionSingle =
      firstActionIndex !== -1 &&
      seqInitSequences[firstActionIndex + 1]?.type?.toLowerCase() !== 'action';

    // Check wallet balance in the action
    const firstAction = seqInitSequences[firstActionIndex];
    const actionType = firstAction?.data?.actions?.[0]?.at;

    if (firstAction?.data?.actions?.[0]?.do?.toLowerCase() === NOTIFY_TYPE.toLowerCase())
      return null;

    // Define all users wallets
    const allUsersWallets =
      stores.userInfo.sortedBalances[
        this.props.store.rule.ex + seqInitSequences?.[0]?.data?.conditions?.[0]?.a?.s
      ] ||
      stores.userInfo.sortedBalances[this.props.store.rule.ex] ||
      [];

    // Define target coin
    let targetCoin =
      firstAction?.data?.actions?.[0]?.ort === 'base'
        ? firstAction?.data?.actions?.[0]?.b?.[0]
        : firstAction?.data?.actions?.[0]?.ort;
    targetCoin =
      targetCoin === 'that_coin'
        ? seqInitSequences[firstActionIndex - 1]?.data?.conditions?.[0]?.ifs?.[0] ||
          seqInitSequences[firstActionIndex - 2]?.data?.conditions?.[0]?.ifs?.[0] ||
          seqInitSequences[firstActionIndex - 3]?.data?.conditions?.[0]?.ifs?.[0] ||
          seqInitSequences[firstActionIndex - 4]?.data?.conditions?.[0]?.ifs?.[0]
        : targetCoin;
    targetCoin = typeof targetCoin === 'string' ? targetCoin.toLowerCase() : targetCoin;

    // Define target coin balance
    let targetCoinBalance = allUsersWallets.find(
      (wallet) => wallet.asset.toLowerCase() === targetCoin
    )?.balance;
    targetCoinBalance = targetCoinBalance === undefined ? 0 : targetCoinBalance;

    // Define target coin balance in currency that used in rule
    let targetCoinBalanceInCurrency = allUsersWallets.find(
      (wallet) => wallet.asset.toLowerCase() === targetCoin
    )?.fiatValue;
    targetCoinBalanceInCurrency =
      targetCoinBalanceInCurrency === undefined ? 0 : targetCoinBalanceInCurrency;

    // Define target coin price
    let targetCoinPrice = getCoinValue(targetCoin?.toUpperCase(), this.props.store.rule.ex).price;
    targetCoinPrice = targetCoinPrice === undefined ? 0 : targetCoinPrice;
    targetCoinPrice = isNaN(targetCoinPrice) ? 0 : targetCoinPrice;

    // Define target wallet
    let targetWallet = firstAction?.data.actions[0].q.toLowerCase();
    targetWallet = targetWallet === '---' ? undefined : targetWallet;

    // Define target wallet balance
    let targetWalletBalance = allUsersWallets.find(
      (wallet) => wallet.asset.toLowerCase() === targetWallet
    )?.balance;
    targetWalletBalance = targetWalletBalance === undefined ? 0 : targetWalletBalance;

    // Deinfe target wallet balance in currency that used in rule
    let targetWalletBalanceInCurrency = allUsersWallets.find(
      (wallet) => wallet.asset.toLowerCase() === targetWallet
    )?.fiatValue;
    targetWalletBalanceInCurrency =
      targetWalletBalanceInCurrency === undefined ? 0 : targetWalletBalanceInCurrency;

    // Define target amount
    let targetAmount = firstAction?.data?.actions?.[0]?.v?.iv;
    targetAmount = targetAmount === undefined ? 0 : +targetAmount;

    // Define target units
    const targetUnits = firstAction?.data?.actions?.[0]?.v?.s?.toLowerCase();

    // Define currency that used in rule
    const currentCurrency = this.props.store.tempCurrency.toLowerCase();

    const actionParameters = {
      isFirstActionSingle: isFirstActionSingle,
      actionType: actionType,
      targetCoin: targetCoin,
      targetCoinBalance: targetCoinBalance,
      targetCoinBalanceInCurrency: targetCoinBalanceInCurrency,
      targetCoinPrice: targetCoinPrice,
      targetAmount: targetAmount,
      targetUnits: targetUnits,
      targetWallet: targetWallet,
      targetWalletBalance: targetWalletBalance,
      targetWalletBalanceInCurrency: targetWalletBalanceInCurrency,
      currentCurrency: currentCurrency,
    };

    return actionParameters;
  }

  renderThatCoinWarning() {
    let isWarningVisible = false;
    let isAnyCoinCondition = false;

    let lastSymbol = false;
    for (let i in this.props.store.seqInit.sequences) {
      const sequence = this.props.store.seqInit.sequences[i];
      if (sequence.type === CONDITION_TYPE) {
        for (let j in sequence.data.conditions) {
          const condition = sequence.data.conditions[j];
          if (condition.ifc === 'symbol') {
            lastSymbol = true;
          } else if (condition.ifc !== 'that_coin') {
            lastSymbol = false;
          }
          isAnyCoinCondition =
            condition.ifc === 'any_coin' ||
            condition.ifc === 'any_my_coin' ||
            (condition.ifc === 'that_coin' && !lastSymbol) ||
            condition.ifc === 'best_market' ||
            condition.ifc === 'worst_market' ||
            condition.ifc === 'best_mine' ||
            condition.ifc === 'worst_mine';
        }
      }
      if (sequence.type === ACTION_TYPE) {
        for (let j in sequence.data.actions) {
          const action = sequence.data.actions[j];
          isWarningVisible =
            (action.ort === 'that_coin' ||
              action.ort === 'any_coin' ||
              action.ort === 'any_my_coin' ||
              action.ort === 'best_market' ||
              action.ort === 'worst_market' ||
              action.ort === 'best_mine' ||
              action.ort === 'worst_mine') &&
            action.v.s === '-' &&
            isAnyCoinCondition;
        }
      }
    }

    if (!isWarningVisible) return null;

    return (
      <Warning>
        <React.Fragment>
          <b>The 'coins' option requires a specific coin to trade.</b>
          {` `}
          Please select % or currency if you are trading multiple coins.
        </React.Fragment>
      </Warning>
    );
  }

  renderLowBalanceWarning(actionParameters) {
    const isLeverageExchange =
      stores.exchangeInfo.exchangeType[this.props.store.rule.ex] === EXCHANGES_TYPE.LEVERAGE;

    if (isLeverageExchange || !actionParameters) {
      return null;
    }

    const {
      isFirstActionSingle,
      actionType,
      targetCoin,
      targetCoinBalance,
      targetCoinBalanceInCurrency,
      targetCoinPrice,
      targetAmount,
      targetUnits,
      targetWallet,
      targetWalletBalance,
      targetWalletBalanceInCurrency,
      currentCurrency,
    } = actionParameters;

    if (!isFirstActionSingle) {
      return null;
    }

    let isWarningVisible = false;

    // ================================================
    // Set warning visibility
    if (actionType === 'buy' && targetWallet !== undefined) {
      if (targetUnits === '%') {
        isWarningVisible = targetWalletBalance === 0;
      } else if (targetUnits === currentCurrency) {
        isWarningVisible = targetAmount > targetWalletBalanceInCurrency;
      } else if (targetUnits === '-') {
        isWarningVisible = targetAmount * targetCoinPrice > targetWalletBalanceInCurrency;
      }
    }

    if (
      actionType === 'sell' &&
      targetCoin !== undefined &&
      targetCoin !== 'best_market' &&
      targetCoin !== 'worst_market' &&
      targetCoin !== 'best_mine' &&
      targetCoin !== 'worst_mine'
    ) {
      if (targetUnits === '%') {
        isWarningVisible = targetCoinBalance === 0;
      } else if (targetUnits === currentCurrency) {
        isWarningVisible = targetAmount > targetCoinBalanceInCurrency;
      } else if (targetUnits === '-') {
        isWarningVisible =
          (targetWallet !== undefined &&
            targetAmount * targetCoinPrice > targetCoinBalanceInCurrency) ||
          targetAmount > targetCoinBalance;
      }
    }

    if (!isWarningVisible) return null;

    return (
      <Warning>
        <React.Fragment>
          <b>
            The current balance of the selected {actionType === 'buy' ? 'wallet' : 'coin'} is too
            low.
          </b>
          {` `}
          The rule may not execute.
        </React.Fragment>
      </Warning>
    );
  }

  renderMaxOpenPositionWarning() {
    if (this.props.type !== 'base') {
      return null;
    }

    const isAnyTimeOperator = this.props.store.isAnyTimeOperator();
    const rule = this.props.store.rule;

    if (!isAnyTimeOperator || +rule.rpt.l < 2 || (rule.mop && rule.mop > 0)) {
      return null;
    }

    return (
      <Warning>
        <div>
          <strong>Limit open positions</strong>
          <p>
            We recommend to set a limit for open positions when using the "Any Time" operator to
            reduce risk.
          </p>
        </div>
      </Warning>
    );
  }

  renderDangerousTrailingSetupWarning() {
    if (this.props.type !== 'base') {
      return null;
    }

    const trailingSetup = this.props.store.checkTrailingAction();

    if (!trailingSetup.trailingFound) {
      return null;
    }

    if (
      (trailingSetup.trailingDirection === 'dec' && trailingSetup.trailingAction === 'sell') ||
      (trailingSetup.trailingDirection === 'inc' && trailingSetup.trailingAction === 'buy')
    ) {
      // safe trading
      return null;
    }

    const trailingBuySell =
      trailingSetup.trailingDirection === 'dec' ? 'Trailing Stop' : 'Trailing Buy';
    const buySell =
      trailingSetup.trailingAction.charAt(0).toUpperCase() + trailingSetup.trailingAction.slice(1);

    return (
      <Warning>
        <div>
          <strong>Your setup is risky</strong>
          <p>
            Connecting a {trailingBuySell} with a "{buySell}" Action is dangerous.{' '}
            <a
              href='https://help.coinrule.com/en/articles/5872200-trailing-stop-buy'
              target='_blank'
              rel='noopener noreferrer'>
              Read more
            </a>
          </p>
        </div>
      </Warning>
    );
  }

  renderTradingViewIntegrationAlert() {
    if (
      !this.props.store.usingTradingViewSignal[this.props.store.rule.tr] ||
      !this.props.preLaunchReviewModal
    ) {
      return null;
    }

    return (
      <Warning>
        <div>
          <strong>Your rule uses the TradingView integration</strong>

          <p className='tradingViewIntergration__description'>
            To run your rule, copy and paste the following keys into the "Webhook URL" field and in
            the "Message" field on TradingView.{' '}
            <a
              href='https://help.coinrule.com/en/articles/5464133-tradingview-signals'
              target='_blank'
              rel='noopener noreferrer'>
              Read more
            </a>
          </p>
        </div>
        <div>
          <div className='tradingViewIntergration__copyItem'>
            Webhook URL{' '}
            <ClickToCopy
              icon=''
              className='tradingViewIntergration__copyButton'
              textToCopy={stores.user.tradingViewWebhook}>
              <span className='truncate'>{stores.user.tradingViewWebhook}</span> <CopyIcon />
            </ClickToCopy>
          </div>

          <div className='tradingViewIntergration__copyItem'>
            Message{' '}
            <ClickToCopy
              icon=''
              className='tradingViewIntergration__copyButton'
              textToCopy={getHookMessage(this.props.store)}>
              <span className='truncate'>
                <HookMessage ruleStore={this.props.store} />
              </span>{' '}
              <CopyIcon />
            </ClickToCopy>
          </div>
        </div>
      </Warning>
    );
  }

  renderDemoPerformanceWarning() {
    const isDemoExchange = stores.info.demoExchangesIDs.includes(this.props.store.rule.ex);

    if (this.props.type !== 'base' || !isDemoExchange) return null;

    return <Warning>Demo performance may vary from live exchange</Warning>;
  }

  render() {
    if (!this.props.show) return null;

    const filteredEx = stores.info.exchanges.filter((ex) => ex.id === this.props.store.rule.ex);
    const exchange = filteredEx.length === 1 ? toJS(filteredEx[0]) : null;

    const html =
      this.props.type === 'base' ? (
        <SequenceBaseText
          exchange={exchange}
          store={this.props.store}
          stores={stores}
          handleSectionClick={this.handleSectionClick}
        />
      ) : (
        <SequenceDetailText
          exchange={exchange}
          store={this.props.store}
          stores={stores}
          handleSectionClick={this.handleSectionClick}
        />
      );

    let exchangeName = exchange ? exchange.name : 'No exchange selected yet';

    if (exchangeName.toLowerCase() === 'demo exchange') {
      exchangeName = 'Demo Exchange';
    }

    return (
      <ErrorBoundary name={'boundary_DetailTextSequence'}>
        <ul className='details ruleSummaryText' ref={this.props.contentRef}>
          {this.props.showExchange && (
            <div
              className={`ruleSummaryText__section
																	ruleSummaryText__section_exchange
																	${stores.addRule.sfpPendingSectionId === -1 && 'ruleSummaryText__section_preloader'}`}
              onClick={() => this.handleSectionClick(-1)}>
              <span className={`ruleSummaryText__keyWord`} style={{ fontWeight: 700 }}>
                ON{' '}
              </span>
              <span>{exchangeName}</span>
            </div>
          )}

          {this.props.showMarker && (
            <div
              className={`ruleSummaryText__marker`}
              style={{ top: `${this.props.markerPosition}%` }}>
              &nbsp;
            </div>
          )}

          {html}
          {this.props.showTriggerText && (
            <DetailTextTrigger
              store={this.props.store}
              isRuleNew={this.props.isRuleNew}
              handleClick={this.handleSectionClick}
            />
          )}
        </ul>

        {this.renderLowBalanceWarning(this.retrieveActionParameters())}
        {this.renderThatCoinWarning()}
        {this.renderTradingViewIntegrationAlert()}
        {this.renderMaxOpenPositionWarning()}
        {this.renderDangerousTrailingSetupWarning()}
        {this.renderDemoPerformanceWarning()}
      </ErrorBoundary>
    );
  }
}

DetailSequenceTexts.propTypes = {
  contentRef: PropTypes.any,
  isRuleNew: PropTypes.bool,
  markerPosition: PropTypes.number,
  preLaunchReviewModal: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  showExchange: PropTypes.bool,
  showMarker: PropTypes.bool,
  showTriggerText: PropTypes.bool.isRequired,
  store: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['detail', 'base']).isRequired,
};

export default DetailSequenceTexts;
