import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Router, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import cookie from 'react-cookies';
//import LogRocket from 'logrocket'; // logrocket disabled on Ruben's request
//import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer';

import './style.scss';
import './styles/buttons.scss';
import './styles/fonts.scss';
import './styles/promoBarInvest.scss';

import 'noty/lib/noty.css';

import * as serviceWorker from './serviceWorker';

import { setDataLocalStorage, setIntercomVisibility } from 'helpers/utils';
import history from 'helpers/history';
import App from 'pages/App';
import LazyLoading from './components/Loading/LazyLoading';

import { StoreProvider, store } from 'v2/ui'
import { ThemeProvider } from 'design-system';

let prevLocation = {};
setIntercomVisibility(window.location)
history.listen((location) => {
  const pathChanged = prevLocation.pathname !== location.pathname;

  if (pathChanged) {
    window.scrollTo(0, 0);
    window.Intercom('update');
  }

  prevLocation = location;
  setIntercomVisibility(location); // show/hide Intercom based on page
});

TagManager.initialize({ gtmId: 'GTM-T82SSXN' });
window.rga = ReactGA;
window.rga.initialize('UA-115365636-5');
window.rga.pageview(window.location.pathname + window.location.search);
window.rga.set({ referrer: cookie.load('initialReferrer') || 'https://coinrule.com' });

if (window.location.pathname !== '/') {
  const pathLocation = window.location.pathname;
  setDataLocalStorage('directLink', pathLocation);
}

// eslint-disable-next-line no-restricted-globals
// if (IS_PROD) {
//   const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup([
//     // Enter fields that should not be logged here
//     'password',
//     'key',
//     'passphrase',
//     'secret',
//     'cid',
//   ]);
//   LogRocket.init('epnvvg/coinrule', {
//     network: {
//       requestSanitizer,
//       responseSanitizer,
//     },
//   });
// }

// ==================================================================================
// ==================================================================================
// ==================================================================================
// HOTFIX (START)
// This part of code is necessary to fix Google Translate Plugins issue
// more info https://github.com/facebook/react/issues/11538#issuecomment-417504600
// This part of code should be removed when translations will be implemented in the app
/*if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function(child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function(newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments);
  }
}*/
// HOTFIX (END)
// ==================================================================================
// ==================================================================================
// ==================================================================================

ReactDOM.render(
  <BrowserRouter>
    <React.Suspense fallback={<LazyLoading />}>
      <StoreProvider store={store}>
        <ThemeProvider>
          <Router history={history}>
            <Switch>
              <App />
            </Switch>
          </Router>
        </ThemeProvider>
      </StoreProvider>
    </React.Suspense>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();

// Not sure what's this??
// if (module.hot) {
//   module.hot.accept();
// }
