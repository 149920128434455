import type { TReducer } from "v2/types/TReducer"

import type { TDomainActionShareRule } from "./actionsShareRule";
import type { DTOErrorCreateRuleSlug } from "./DTOs/DTOErrorCreateRuleSlug";
import type { DTOShareRuleSlug } from "./DTOs/DTOShareRuleSlug";

import { EDomainActionsShareRule } from "./actionsShareRule"
import { EAsyncStatus } from "v2/types/EAsyncStatus";

export type TDomainStateShareRule = {
  data: DTOShareRuleSlug | null,
  error: DTOErrorCreateRuleSlug | null
  status: EAsyncStatus
}

export const initialStateShareRule: TDomainStateShareRule = {
  data: null,
  error: null,
  status: EAsyncStatus.IDLE
}

export const reducerShareRule: TReducer<TDomainStateShareRule, TDomainActionShareRule> = (state = initialStateShareRule, action): TDomainStateShareRule => {
  switch (action.type) {
    case EDomainActionsShareRule.FETCH_STARTED: {
      return {
        data: null,
        error: null,
        status: EAsyncStatus.PENDING
      }
    }
    case EDomainActionsShareRule.FETCH_SUCCEEDED: {
      return {
        data: action.payload.shareRuleSlug,
        error: null,
        status: EAsyncStatus.RESOLVED
      }
    }
    case EDomainActionsShareRule.FETCH_FAILED: {
      return {
        data: null,
        error: action.payload.error,
        status: EAsyncStatus.REJECTED
      }
    }
    default:
      return state
  }
}