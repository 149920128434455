import React, { useEffect, useState } from 'react';
import { Swipeable } from 'react-swipeable';
import { EventData } from 'react-swipeable/types';
import storesInjector from 'stores/storesInjector';
import { getConditionalClasses, setter } from 'helpers/helpers';

import { ErrorBoundary } from '../../../components/ErrorBoundary';
import UpgradeOverlayMessage from './UpgradeOverlayMessage';
import { CustomWindow } from 'typings/types';
import './style.scss';
import TemplatesList, { StartBlankTemplate } from './TemplatesList';
import Popover from 'components/Popover';
import SearchInput from './SearchInput';
import TemplateCategories from './TemplateCategories';
import TemplateHeadline from './TemplateHeadline';
import { getAdditionalTemplateData } from 'constants/templates';

declare let window: CustomWindow;

const analytics = window.mixpanel;

export interface TemplatesListContainerProps {
  store: any;
  parent: any;
  shown: boolean;
  isRuleNew: boolean;
  templatesStore: any;
  userStore: any;
  userInfoStore: any;
  ruleDetailsStore: any;
}
const TemplatesListContainer: React.FC<TemplatesListContainerProps> = ({
  store,
  parent,
  isRuleNew,
  shown,
  templatesStore,
  userStore,
  userInfoStore,
  ruleDetailsStore,
}) => {
  const {
    isSelect,
    showOverlay,
    templates: originalTemplates,
    totalTemplates,
    resultCount,
    categories,
    fetchTemplates,
    handleTemplateApply,
    fetchTemplate,
    blankRule,
    itemsPerPage,
    setShowOverlay,
  } = templatesStore;
  const { hasRealExchange } = userInfoStore;
  const {
    user: { plan },
  } = userStore;

  const [state, setState] = useState({
    isTemplateLibOpened: true,
    isTemplateApplied: false,
    searchTerm: '',
    showSearchBar: false,
    openedTemplateID: -1,
    selectedCategory: 'All',
    templates: [],
    currentPage: 1,
    appliedTemplate: null,
  });

  useEffect(() => {
    setState({ ...state, templates: originalTemplates });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalTemplates]);

  const {
    isTemplateLibOpened,
    isTemplateApplied,
    searchTerm,
    showSearchBar,
    selectedCategory,
    openedTemplateID,
    templates,
    currentPage,
    appliedTemplate,
  } = state;

  useEffect(() => {
    if (isTemplateApplied) {
      if (window.document.querySelector('body')?.scrollIntoView)
        window.document
          .querySelector('body')
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemplateApplied]);

  const toggleSearchBar = () => setter({ showSearchBar: !state.showSearchBar }, setState);

  const toggleTemplateOpen = (id: number) => {
    setter({ openedTemplateID: openedTemplateID === id ? -1 : id }, setState);
  };

  const handleSelectCategory = (category: string) => {
    const newCategory =
      selectedCategory === 'All' ? category : selectedCategory === category ? 'All' : category;

    setter({ selectedCategory: newCategory }, setState);

    // Filter by cateogries
    if (newCategory !== 'All') {
      getTemplates({ categoryId: newCategory });
      setter({ currentPage: 1 }, setState);
    } else {
      setter({ templates: originalTemplates }, setState);
      getTemplates({ });
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setState((prev) => ({ ...prev, searchTerm: value, openedTemplateID: -1 }));

    getTemplates({
      searchStr: value,
      ...(selectedCategory && selectedCategory !== 'All' && { categoryId: selectedCategory }),
    });
  };

  const getTemplates = (
    options: { offset?: number; searchStr?: string; categoryId?: string } = {}
  ) => {
    fetchTemplates(options);
  };

  const onPageChange = (page: number) => {
    setter({ currentPage: page, openedTemplateID: -1 }, setState);

    const params = {
      // ask for the next page => shift to offset with the page size
      offset: (page - 1) * itemsPerPage,
      ...(searchTerm && { searchStr: searchTerm }),
      ...(selectedCategory && selectedCategory !== 'All' && { categoryId: selectedCategory }),
    };
    getTemplates(params);
  };

  const handleSwipe = (event: EventData) => {
    if (window.innerWidth > 679 || !(event.deltaY > -30 && event.deltaY < 30)) {
      return;
    }

    if (event.deltaX > 0) {
      onPageChange(currentPage + 1);
    } else {
      onPageChange(currentPage - 1);
    }

    return;
  };

  const resetStateOnTemplateApply = (template: Record<string, any>) => {
    setter(
      {
        appliedTemplate: template,
        isTemplateApplied: true,
        showSearchBar: false,
        searchTerm: '',
        selectedCategory: 'All',
      },
      setState
    );
  };

  const onTemplateApply = async (templateId: string) => {
    const template = await fetchTemplate(templateId);

    if (template) {
      const additionalTemplateDetails = getAdditionalTemplateData(template._id);
      resetStateOnTemplateApply({...template, ...additionalTemplateDetails, pair: template.pair});
      handleTemplateApply({ template, ruleStore: store });
    }
  };

  const startWithBlankTemplate = () => {
    ruleDetailsStore.setTemplatesListVisibility(false);

    resetStateOnTemplateApply(blankRule);

    store.resetRule();
    templatesStore.setIsSelect(true);
  };

  const removeAppliedTemplate = () => {
    setter(
      {
        isTemplateApplied: false,
        openedTemplateID: -1,
        currentPage: 1,
        searchTerm: '',
        selectedCategory: 'All',
        appliedTemplate: null,
      },
      setState
    );

    store.resetRule();
    store.resetTemplate();
    templatesStore.setIsSelect(false);
    ruleDetailsStore.setTemplatesListVisibility(false);
  };

  const innerCls = getConditionalClasses([
    { condition: true, className: 'rulesTemplatesList' },
    { condition: !isRuleNew, className: 'rulesTemplatesList_notNewRule' },
    { condition: isSelect, className: 'rulesTemplatesList_choosedTemplate' },
  ]);

  const showUpgradeOverlayMessage = showOverlay && !(plan.isPremium || hasRealExchange);

  if ((!shown && !appliedTemplate) || ruleDetailsStore.isTemplateApplyingPending) return null;

  const showStartBlank =
    !templates?.length || (currentPage === 1 && !searchTerm && selectedCategory === 'All');

  return (
    <ErrorBoundary name='boundary_AddRulePage_TemplatesList'>
      <div
        className='rule-settings'
        data-get_paid_plan_msg={showOverlay}
        data-is_template_lib_opened={isTemplateLibOpened}>
        <Swipeable onSwiped={handleSwipe} className='swipeablePluginWrapper template-list-area'>
          <div data-testid='rulesTemplatesList' className={innerCls}>
            <div className='rulesTemplatesList__headline'>
              <div className='rulesTemplatesList__titleNsearchLabel'>
                <TemplateHeadline
                  isTemplateApplied={isTemplateApplied}
                  appliedTemplate={appliedTemplate}
                  totalTemplates={totalTemplates}
                  isTemplateLibOpened={isTemplateLibOpened}
                  handleTemplateReset={removeAppliedTemplate}
                />

                {!isTemplateApplied && (
                  <Popover text='Search Templates'>
                    <div
                      role={'button'}
                      className={`rulesTemplatesList__searchLabel ${
                        showSearchBar ? `rulesTemplatesList__searchLabel_opened` : ''
                      }`}
                      onClick={toggleSearchBar}>
                      <span className={`rulesTemplatesList__searchLabelText`}>Search</span>
                    </div>
                  </Popover>
                )}
              </div>

              <SearchInput
                showSearchBar={showSearchBar}
                value={searchTerm}
                onChange={handleSearchChange}
              />

              {!isTemplateApplied && categories?.length && (
                <TemplateCategories
                  selectedCategory={selectedCategory}
                  categories={categories}
                  onSelectCategory={handleSelectCategory}
                />
              )}
            </div>

            {showStartBlank && !isTemplateApplied && (
              <StartBlankTemplate onClick={startWithBlankTemplate} />
            )}

            {!isTemplateApplied && (
              <TemplatesList
                totalCount={resultCount}
                templates={templates}
                openedTemplateID={openedTemplateID}
                toggleOpenTemplate={toggleTemplateOpen}
                currentPage={currentPage}
                onPageChange={onPageChange}
                handleTemplateApply={onTemplateApply}
                itemsPerPage={itemsPerPage}
              />
            )}

            {showUpgradeOverlayMessage && (
              <UpgradeOverlayMessage
                hasRealExchange={hasRealExchange}
                analytics={analytics}
                onClose={() => setShowOverlay(false)}
              />
            )}
          </div>
        </Swipeable>
      </div>
    </ErrorBoundary>
  );
};

const selector = ({
  templatesStore,
  user: userStore,
  userInfo: userInfoStore,
  ruleDetailsStore,
}: any) => ({
  templatesStore,
  userStore,
  userInfoStore,
  ruleDetailsStore,
});

export default storesInjector(selector)(TemplatesListContainer);
