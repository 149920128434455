import React from 'react';
import Popover from '../../../components/Popover';

export interface TemplateCategoriesProps {
  categories: Array<Record<string, any>>;
  onSelectCategory: (category: string) => void;
  selectedCategory: string;
}

const TemplateCategories: React.FC<TemplateCategoriesProps> = ({
  categories,
  onSelectCategory,
  selectedCategory,
}) => (
  <div className={`rulesTemplatesList__branchesNtags`}>
    <div className={`rulesTemplatesList__tags`}>
      <div className={`rulesTemplatesList__tagsTitle`}>Tags:</div>

      <div className={`rulesTemplatesList__tagsList`}>
        {categories.map((cat) => (
          <CategoryTag
            key={cat._id}
            name={cat.nam}
            isSelected={selectedCategory === cat._id}
            onClick={() => onSelectCategory(cat._id)}
          />
        ))}
      </div>
    </div>
  </div>
);

export default TemplateCategories;

export const TEMPLATE_NAMES_MAP: Record<string, any> = {
  Long: 'Accumulation',
  Trend: 'Trend Following',
  Contrarian: 'Contrarian Strategy',
  Rebalance: 'Portfolio Rebalancing',
  Maximize: 'Profit Maximize',
  Protect: 'Protect',
  'Tradingview Strategies': 'Tradingview',
};

export interface CategoryTagProps {
  name: string;
  onClick: () => void;
  isSelected: boolean;
}
export const CategoryTag: React.FC<CategoryTagProps> = ({ onClick, isSelected, name }) => {
  if (!name) return null;

  const getText = (name: string) => {
    return (TEMPLATE_NAMES_MAP[name] || name) + ' Templates';
  };

  return (
    <Popover text={getText(name)}>
      <div
        role='button'
        className={`rulesTemplatesList__tag
                                  ${isSelected ? `rulesTemplatesList__tag_selected` : ''}`}
        onClick={onClick}>
        {name}
      </div>
    </Popover>
  );
};
