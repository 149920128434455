import React, { Component } from 'react';

import './style.scss';

class Disclaimer extends Component {
  render() {
    return (
      <div className='pageDisclaimer'>
        The information provided does not constitute investment or trading advice. Coinrule does not take any liability for rule performance. More info in{' '}
        <a href='https://coinrule.com/terms.html' target='_blank' rel='noopener noreferrer'>
          Terms of Use
        </a>
        .
      </div>
    );
  }
}

export default Disclaimer;
