import React from 'react'

import type { TypographyProps } from '@mui/material'
import type { TRuleDisplayedStatus, TRuleTriggers } from 'design-system'

import { useTheme, Typography } from '@mui/material'

export const MESSAGES_DEPENDING_DIRECTLY_ON_STATUS: Partial<Record<TRuleDisplayedStatus, string>> = {
  scheduled: 'Scheduled',
  paused: 'Paused',
  finished: 'Finished',
  archived: 'Archived',
  gotError: 'Rule has an Error',
}
export interface RuleStatusTextProps extends TypographyProps {
  status: TRuleDisplayedStatus
  trigger: TRuleTriggers
}

const testId = 'RuleStatusText'
export const RuleStatusText = (props: RuleStatusTextProps) => {
  const {
    sx,
    status,
    trigger,
    ...otherProps
  } = props

  const theme = useTheme()

  return (
    <Typography
      data-testid={testId}
      className={testId}
      sx={{
        fontSize: '12px',
        lineHeight: '15px',
        color: theme.palette.coinrule.basic.greyDark,
        ...sx
      }}
      {...otherProps}
    >
      {
        Object.keys(MESSAGES_DEPENDING_DIRECTLY_ON_STATUS).includes(status) ?
          MESSAGES_DEPENDING_DIRECTLY_ON_STATUS[status]
          :
          // this can't be scheduled for the future
          trigger === 'directOrder' ?
            'Executing'
            :
            trigger === 'time' ?
              'This rule is timer scheduled'
              :
              trigger === 'event' ?
                'Waiting for condition to be met'
                :
                null
      }
    </Typography>
  )
}

export default RuleStatusText
