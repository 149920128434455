import React from 'react';
import { observer } from 'mobx-react';
import { Icon, Button } from 'antd';

import stores from '../../../stores/index';
import Popover from '../../../components/Popover';
import AddButtonsBlock from '../AddButtonsBlock';
import ActionSequencePart from './ActionSequencePart';

import {
  ACTION_TYPE,
  TRADE_TYPES,
  TRIGGERS,
  OPERATOR_TYPE,
  OPERATOR_TYPES,
  NOTIFY_TYPE,
  CONDITION_TYPE,
} from '../../../stores/addRule';

import './style.scss';
import { EXCHANGES_TYPE } from '../../../constants/exchanges';
import NotifySequence from './NotifySequencePart/NotifySequencePart';
import { toJS } from 'mobx';

import arrowRight from '../../../assets/images/arrow-right.svg';
import ErrorBlock from '../../../components/Sequences/ErrorBlock';
import { getBooleanFromString } from 'helpers/helpers';

@observer
class Then extends React.Component {
  isPossibleToAddAction(index) {
    const user = stores.user.getUser();
    return (
      user.plan.maximumActions < 1 ||
      (this.props.store.seqInit.sequences[index] &&
        this.props.store.seqInit.sequences[index].data.actions.length < user.plan.maximumActions)
    );
  }

  isLeverage() {
    const exchangeType = stores.exchangeInfo.exchangeType[this.props.store.rule.ex];
    return exchangeType && exchangeType === EXCHANGES_TYPE.LEVERAGE;
  }

  checkIfExchangeLoaded() {
    return (
      this.props.store.rule.ex &&
      stores.userInfo.balances[this.props.store.rule.ex] &&
      stores.exchangeInfo.assets[this.props.store.rule.ex] &&
      ((this.isLeverage() && stores.exchangeInfo.instruments[this.props.store.rule.ex]) ||
        stores.exchangeInfo.markets[this.props.store.rule.ex])
    );
  }

  addAction = (index) => {
    if (this.isPossibleToAddAction(index)) {
      this.props.store.addAction();
    }
  };

  renderActionAddButton(index) {
    if (this.isPossibleToAddAction()) {
      return (
        <div className='add-button-wrapper'>
          <Popover text='Include additional Action'>
            <Button type='link' className='add-button' onClick={this.addAction(index)}>
              <span>Add Action</span>
              <Icon type='plus-circle' />
            </Button>
          </Popover>
        </div>
      );
    } else {
      return null;
    }
  }

  renderJoinOperator(index) {
    let desc;
    if (index > 0) {
      if (
        this.props.store.rule.tr === TRIGGERS.DIRECT_ORDER ||
        (this.props.store.rule.tr === TRIGGERS.TIME &&
          ['now', 'on'].indexOf(this.props.store.rule.tim) > -1 &&
          index === 1)
      ) {
        desc = '';
      } else {
        if (this.props.showAndText) {
          desc = 'And';
        } else {
          desc = 'Then';
        }
      }
    } else {
      if (this.props.showAndText) {
        desc = 'And';
      } else {
        desc = 'Then';
      }
    }

    return <div className='part-title title-blue'>{desc}</div>;
  }

  handleActionChange(index, type, sequenceIndex) {
    if (!this.props.store.isQuickEdit()) {
      this.props.store.setActionType(index, type, sequenceIndex);
    }
  }

  renderTradeAction(index, sequenceIndex, item) {
    const cls = 'newRuleTitleTabs';
    const { store } = this.props;

    const previousElement = store.seqInit.getPreviousElement(sequenceIndex);

    const isPreviousElementCondition = previousElement?.type === CONDITION_TYPE;

    const hideNotify =
      store.rule.tr === TRIGGERS.DIRECT_ORDER ||
      (store.rule.tr === TRIGGERS.TIME && !isPreviousElementCondition) || 
      !getBooleanFromString(process.env.REACT_APP_RENDER_NOTIFY);

    return (
      <React.Fragment>
        <Popover text={'Buy Coins'}>
          <div
            className={`${cls}__tab
              ${cls}__tab_iconLeftArr
              ${item.at?.toLowerCase() === 'buy' && `${cls}__tab_active`}
              ${this.props.store.isQuickEdit() && 'disabled-button disabled-button_radius'}`}
            onClick={() => this.handleActionChange(index, TRADE_TYPES.BUY, sequenceIndex)}>
            {TRADE_TYPES.BUY}
          </div>
        </Popover>
        <Popover text={'Sell Coins'}>
          <div
            className={`${cls}__tab
              ${cls}__tab_iconRightArr
              ${item.at?.toLowerCase() === 'sell' && `${cls}__tab_active`}
              ${this.props.store.isQuickEdit() && 'disabled-button disabled-button_radius'}`}
            onClick={() => this.handleActionChange(index, TRADE_TYPES.SELL, sequenceIndex)}>
            {TRADE_TYPES.SELL}
          </div>
        </Popover>
        {!hideNotify && (
          <Popover text={'Notify'}>
            <div
              className={`${cls}__tab
                ${cls}__tab_iconNotify
                ${item.do?.toLowerCase() === NOTIFY_TYPE.toLowerCase() && `${cls}__tab_active`}
                ${this.props.store.isQuickEdit() && 'disabled-button disabled-button_radius'}`}
              onClick={() => this.handleActionChange(index, NOTIFY_TYPE, sequenceIndex)}>
              {NOTIFY_TYPE}
            </div>
          </Popover>
        )}
      </React.Fragment>
    );
  }

  renderWrapper(children, item) {
    const index = 0;
    const sequenceIndex = this.props.store.seqInit.getIndexById(this.props.id);
    let showError = false;
    let errorText = '';

    if (
      toJS(this.props.store.seqInit.errors[sequenceIndex]) !== undefined &&
      this.props.store.seqInit.errors[sequenceIndex].length > 0
    ) {
      showError = true;
      errorText = this.props.store.seqInit.errors[sequenceIndex][0].text;
    } else {
      showError = false;
    }

    return (
      <div className={'rule-settings'}>
        <Popover text='Buy or Sell Action'>
          <div className='title-wrapper'>
            <img src={arrowRight} alt='Action' />
            {this.renderJoinOperator(sequenceIndex)}
          </div>
        </Popover>

        <div className={`settings`}>
          <div
            className={`newRuleTitleTabs
                            newRuleTitleTabs_blue`}>
            {this.renderTradeAction(index, sequenceIndex, item)}
          </div>
          {children}
          <ErrorBlock content={errorText} show={showError} />
        </div>
      </div>
    );
  }

  render() {
    const { store } = this.props;
    const sequenceIndex = store.seqInit.getIndexById(this.props.id);
    const previousElement = store.seqInit.getPreviousElement(sequenceIndex);
    const nextElement = store.seqInit.getNextElement(sequenceIndex);
    const sequence = store.seqInit.sequences[sequenceIndex];
    let otherCss = '';
    let showAndText = false;

    if (
      previousElement &&
      previousElement.type === OPERATOR_TYPE &&
      previousElement.data.operators[0].t === OPERATOR_TYPES.DO_NOT
    ) {
      showAndText = true;
    }

    if (
      previousElement &&
      previousElement.type !== ACTION_TYPE &&
      nextElement &&
      nextElement.type === ACTION_TYPE
    ) {
      otherCss = 'firstAction';
    } else if (nextElement && nextElement.type === ACTION_TYPE) {
      otherCss = 'middleAction';
      showAndText = true;
    }

    if (
      previousElement &&
      (previousElement.type === ACTION_TYPE || previousElement.type === NOTIFY_TYPE)
    ) {
      otherCss = 'moreActions';
      showAndText = true;
    }

    const action = sequence?.data?.actions?.[0];
    const notify = sequence?.data?.notifies?.[0];

    return (
      <div
        className={`then
											${!store.rule.ex && 'show-section_unavailable'}
											animate-flicker`}
        data-sequence={this.props.id}
        id={`sequence${this.props.id}`}
        onClick={() => stores.addRule.setSFPpendingSection(this.props.id)}>
        <div className={'rule-settings-block ' + otherCss}>
          <div className='rsb-line rsb-line_blue'>&nbsp;</div>

          {action
            ? this.renderWrapper(
                <ActionSequencePart
                  sequenceIndex={sequenceIndex}
                  actionId={this.props.id}
                  index={0}
                  item={action}
                  store={store}
                  parent={this}
                  showAndText={showAndText}
                />,
                action
              )
            : notify
            ? this.renderWrapper(
                <NotifySequence
                  actionId={this.props.id}
                  canBeRemoved={store.canSequenceBeRemoved(sequenceIndex)}
                  sequenceIndex={sequenceIndex}
                  index={0}
                  item={notify}
                  store={store}
                  userEmail={stores.user.user.user.email}
                  telegramId={stores.user.userAccountInfo?.telegramId}
                  updateTelegramId={stores.user.updateTelegramId}
                />,
                notify
              )
            : null}
        </div>

        <AddButtonsBlock store={store} id={this.props.id} sequenceIndex={sequenceIndex} />
      </div>
    );
  }
}

export default Then;
