import React from 'react';
import './style.scss';

export const SPINNER_TYPES = {
  WHOLE_PAGE: 'whole-page',
};

const Spinner = ({ type }) => {
  const renderSpinner = () => {
    switch (type) {
      case SPINNER_TYPES.WHOLE_PAGE:
        return (
          <div
            data-testid='whole-page-spinner'
            className={`whole-page-spinner whole-page-spinner_visible`}>
            &nbsp;
          </div>
        );

      default:
        return <div data-testid='spinner' className='spinner'></div>;
    }
  };

  return renderSpinner();
};

export default Spinner;
