import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import './style.scss';
import {
  setDataLocalStorage, 
  checkIfOnRuleDetailPageFromLocation, 
  checkIfOnRuleEditPageFromLocation
} from '../../../helpers/utils';

export interface IBackIconProps {
  isLink?: boolean;
  onClick?: () => void;
  title1?: string;
  title2?: string;
  to?: LinkProps['to'];
  title?: React.ReactNode;
  isLocatedOnRuleDetailsPage?: boolean;
  isLocatedOnRuleEditPage?: boolean;
}

const BackIcon: React.FC<IBackIconProps> = ({
  isLink = true,
  onClick,
  title,
  to,
  title1,
  title2,
  isLocatedOnRuleDetailsPage = checkIfOnRuleDetailPageFromLocation(),
  isLocatedOnRuleEditPage = checkIfOnRuleEditPageFromLocation(),
}) => (
  <div className={`backIcon`}>
    {isLink ? (
      <Link
        className={`backIcon__backLink`}
        to={to}
        onClick={() => {
          if (title === 'New Rule') {
            setDataLocalStorage('should_restore_rule', false);
          }
        }}
      >
        &nbsp;
      </Link>
    ) : (
      <div
        role='button'
        className={`backIcon__backLink`}
        onClick={() => {
          if (title === 'New Rule') {
            setDataLocalStorage('should_restore_rule', false);
          }
          onClick && onClick();
        }}
      >
        &nbsp;
      </div>
    )}
    <h1
      className={`backIcon__h1
												${isLocatedOnRuleEditPage ? 'backIcon__h1_reducedWidth' : ''}
												${isLocatedOnRuleDetailsPage ? 'backIcon__h1_fullWidth' : ''}`}
    >
      {title && <span className={`backIcon__darkGrayTitle`}>{title}</span>}
      {title1 && <span className={`backIcon__lightGrayTitle`}>{title1} </span>}
      {title2 && <span className={`backIcon__darkGrayTitle`}>{title2}</span>}
    </h1>
  </div>
);

export default BackIcon;
