import React from 'react'

import type { StackProps } from '@mui/material'

import { Stack } from '@mui/material'

import {
  TwitterShareButton,
  EmailShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from 'react-share';

import { IconShareViaEmail, IconShareViaWhatsApp, IconShareViaTelegram, IconShareViaTwitter } from 'design-system'

export enum EShareButtonsClickedSource {
  Twitter = 'twitter', 
  Telegram = 'telegram', 
  Whatsapp = 'whatsapp', 
  Email = 'email',
}

export interface ShareButtonsProps extends StackProps {
  link: string,
  shareTitle: string,
  onClickCallback?: (source: EShareButtonsClickedSource) => void
}

const testId = 'ShareButtons'
export const ShareButtons = (props: ShareButtonsProps) => {
  const {
    sx,
    link,
    shareTitle,
    onClickCallback,
    ...otherProps
  } = props
  
  const sharedProps = {
    url: link,
    title: shareTitle
  }

  return (
    <Stack
      data-testid={testId}
      className={testId}
      sx={{
        fontSize: 59,
        ...sx
      }}
      direction={'row'}
      gap={'20px'}
      {...otherProps}
    >
      <TwitterShareButton 
        {...sharedProps} 
        via={'CoinRuleHQ'} 
        hashtags={['coinrule', 'CoinRuleHQ', 'cryptocurrency', 'trading']} 
        related={['CoinRuleHQ']}
        onClick={() => onClickCallback?.(EShareButtonsClickedSource.Twitter)}
        data-testid={`${testId}__twitter`}
      >
        <IconShareViaTwitter />
      </TwitterShareButton>

      <TelegramShareButton 
        {...sharedProps}
        onClick={() => onClickCallback?.(EShareButtonsClickedSource.Telegram)}
        data-testid={`${testId}__telegram`}
      >
        <IconShareViaTelegram />
      </TelegramShareButton>

      <WhatsappShareButton 
        {...sharedProps} 
        onClick={() => onClickCallback?.(EShareButtonsClickedSource.Whatsapp)}
        data-testid={`${testId}__whatsapp`}
      >
        <IconShareViaWhatsApp />
      </WhatsappShareButton>

      <EmailShareButton
        subject={shareTitle}
        {...sharedProps}
        onClick={() => onClickCallback?.(EShareButtonsClickedSource.Email)}
        data-testid={`${testId}__email`}
      >
        <IconShareViaEmail />
      </EmailShareButton>
    </Stack>
  )
}

export default ShareButtons
