import http, { checkResponse } from 'helpers/http';

export default class AuthService {
  constructor(rootService) {
    this.root = rootService;
  }

  async login(
    email,
    password,
    securityHash,
    recaptchaHash,
    verificationCode = null,
    pin = null
  ) {
    const body = {
      email: email,
      password: btoa(encodeURIComponent(password)),
      securityHash: securityHash || undefined,
      recaptchaHash: recaptchaHash,
    };

    if (verificationCode) {
      body.verificationCode = verificationCode;
    }

    if (pin) {
      body.pin = pin;
    }

    try {
      const result = checkResponse(await http.post('/auth/login', body));

      return result.ok ? result.data : null;
    }
    catch (error) {
      if (error.response) {
        checkResponse(error.response);
      }
    }
  }

  async lookup(email, securityHash, recaptchaHash) {
    const body = {
      email: email,
      securityHash: securityHash,
      recaptchaHash: recaptchaHash,
    };

    try {
      const result = checkResponse(await http.post('/auth/lookup', body));

      return result.ok ? result.data : null;
    }
    catch (error) {
      if (error.response) {
        checkResponse(error.response);
      }
    }
  }

  async register(email, password, securityHash, recaptchaHash, verificationCode, coupon) {
    const body = {
      email: email,
      password: btoa(encodeURIComponent(password)),
      securityHash: securityHash,
      recaptchaHash: recaptchaHash,
      verificationCode: verificationCode,
      coupon: coupon,
    };

    try {
      const result = checkResponse(await http.post('/auth/register', body));

      return result.ok ? result.data : null;
    }
    catch (error) {
      if (error.response) {
        checkResponse(error.response);
      }
    }
  }

  async sendEmail(email, securityHash, recaptchaHash, pin) {
    const body = {
      email,
      securityHash,
      recaptchaHash,
      pin,
    };

    try {
      const result = checkResponse(await http.post('/auth/send-email', body));

      return result.ok ? result.data : null;
    }
    catch (error) {
      if (error.response) {
        checkResponse(error.response);
      }
    }
  }

  async sendEmail2(email, securityHash, recaptchaHash) {
    const body = {
      email,
      securityHash,
      recaptchaHash,
    };

    try {
      const result = checkResponse(await http.post('/auth/verification-required', body));

      return result.ok ? result.data : null;
    }
    catch (error) {
      if (error.response) {
        checkResponse(error.response);
      }
    }
  }

  async hash() {
    try {
      const result = checkResponse(await http.get('/auth/hash'));

      return result.ok ? result.data : null;
    }
    catch (error) {
      if (error.response) {
        checkResponse(error.response);
      }
    }
  }

  async verify2FAToken(token) {
    try {
      const result = checkResponse(await http.post('/auth/2fa/verify', { token }));

      return result.ok ? result.data : null;
    }
    catch (error) {
      if (error.response) {
        checkResponse(error.response);
      }
    }
  }

  async oauthGoogleVerify(params, coupon) {
    if (coupon) {
      params += '&coupon=' + coupon;
    }

    try {
      const result = checkResponse(await http.get('/auth/oauth/google/verify' + params));

      return result.ok ? result.data : null;
    }
    catch (error) {
      if (error.response) {
        checkResponse(error.response);
      }
    }
  }

  async oauthFacebookVerify(params, coupon) {
    if (coupon) {
      params += '&coupon=' + coupon;
    }

    try {
      const result = checkResponse(await http.get('/auth/oauth/facebook/verify' + params));

      return result.ok ? result.data : null;
    }
    catch (error) {
      if (error.response) {
        checkResponse(error.response);
      }
    }
  }
}
