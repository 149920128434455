import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Layout } from 'antd';

import { observer } from 'mobx-react';
import logout from '../../helpers/logout';
import MenuItems from '../MenuItems';

import './style.scss';
import logo from '../../assets/images/logo-purple.svg';
import menuItem from '../../assets/images/logout.svg';
import { setDataLocalStorage } from '../../helpers/utils';
import history from '../../helpers/history';
import stores from '../../stores';
import { ErrorBoundary } from '../ErrorBoundary';
import { CoinruleButton } from '../CoinruleButton';

const analytics = window.mixpanel;
const { Header } = Layout;

@observer
class MobileMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuActive: false,
    };
  }

  handleClickToggleMenu = () => {
    stores.addRule.dataStudioVisible = this.state.menuActive;
    this.setState({
      menuActive: !this.state.menuActive,
    });
  };

  handleClickHideMenu = () => {
    stores.addRule.dataStudioVisible = true;
    this.setState({
      menuActive: false,
    });
  };

  handleClickLogout = (event) => {
    setDataLocalStorage('should_restore_rule', false);
    analytics.track('"Logout" button clicked');
    logout(event);
  };

  handleReturnToDashboard = (event) => {
    event.preventDefault();

    if (history.location.pathname !== '/') {
      history.push('/');
    }
  };

  componentWillUnmount() {
    stores.addRule.dataStudioVisible = true;
  }

  render() {
    const menuClass = this.state.menuActive ? 'menu-mobile active' : 'menu-mobile';

    return (
      <div
        className={`menu-mobile-wrap
                        ${
                          !stores.addRule.isSequenceFloatingPanelOpened &&
                          'menu-mobile-wrap_hightZindex'
                        }`}
      >
        <Header>
          <ErrorBoundary name={"boundary_MobileMenu"}>
            <div className={menuClass}>
              <div className='head-div'>
                <NavLink className='logo ' to='/' onClick={() => this.handleClickHideMenu()}>
                  &nbsp;
                  {stores?.user?.user?.plan?.isPremium && (
                    <div className='premium-badge'>{stores.user.user.plan.name.toUpperCase()}</div>
                  )}
                </NavLink>

                <div className='close-menu' onClick={() => this.handleClickToggleMenu()}>
                  <i className='fa fa-times' aria-hidden="true"/>
                </div>
              </div>

              <MenuItems
                useImg={false}
                clickHandler={() => this.handleClickHideMenu()}
                logout={
                  <NavLink to='/logout' onClick={this.handleClickLogout}>
                    <img src={menuItem} alt='Logout' />
                  </NavLink>
                }
                getPlanBut={
                  <li key={99}>
                    <CoinruleButton
                      fillType='solid'
                      icon='Check'
                      className='menu-mobile__getPlanBut'
                      component={NavLink}
                      to='/settings/billing'
                      onClick={() => {
                        analytics.track('"Get Plan" button clicked', {
                          page: 'Mobile Menu',
                        });
                      }}
                    >
                      Get Plan
                    </CoinruleButton>
                  </li>
                }
              />
            </div>

            <div onClick={this.handleReturnToDashboard} className='logo'>
              <img src={logo} alt='Coinrule' />
              {stores?.user?.user?.plan?.isPremium && <div className='premium-badge'>{stores.user.user.plan.name.toUpperCase()}</div>}
            </div>

            <div className='burger' onClick={this.handleClickToggleMenu}>
              &nbsp;
            </div>
          </ErrorBoundary>
        </Header>
      </div>
    );
  }
}

export default MobileMenu;
