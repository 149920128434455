import React from 'react'

import type { TypographyProps} from '@mui/material';
import { useTheme, Typography, Box } from '@mui/material'

import type { IRuleActivityLog } from 'design-system';

export interface RuleActivityLogDataProps extends Omit<TypographyProps, 'id'>, IRuleActivityLog {
  formatCoinValue: (value: number) => string
}

const testId = 'RuleActivityLogData'
export const RuleActivityLogData = (props: RuleActivityLogDataProps) => {
  const {
    sx,
    tradedCoinPrice,
    tradedCoinPriceCurrency,
    tradedCoinQuantity,
    tradedCoinSymbol,
    tradedCoinUSDValue,
    prefix,
    intersection,
    suffix,
    formatCoinValue,
    ...otherProps
  } = props

  const theme = useTheme();

  const ruleDataStyles = {
    backgroundColor: theme.palette.coinrule.basic.greySuperLightBackgroundColor,
    padding: '0.5px 2px',
    borderRadius: '3px',
  };

  return (
    <Typography
      data-testid={testId}
      className={testId}
      sx={{
        fontSize: '14px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
      {...otherProps}
    >
      {prefix}
      {
        tradedCoinQuantity && (
          <Box
            component={'span'}
            sx={{ ...ruleDataStyles, marginX: '4px' }}
          >
            {formatCoinValue(tradedCoinQuantity)} {tradedCoinSymbol}
          </Box>
        )
      }

      {intersection}

      {
        tradedCoinPrice && (
          <Box
            component={'span'}
            sx={{ ...ruleDataStyles, marginLeft: '4px' }}
          >
            {formatCoinValue(tradedCoinPrice)} {tradedCoinPriceCurrency}
          </Box>
        )
      }

      {tradedCoinUSDValue && `($${tradedCoinUSDValue})`}
      {suffix}   
    </Typography>
  )
}

export default RuleActivityLogData
