import type { TDataProviderUser } from "v2/dataProviders/user/createDataProviderUser"
import type { TDispatchAction, TGetState } from "v2/types"

import type { TDomainStateLoginDialogForNotAuthenticatedUsers } from "./stateLoginDialogForNotAuthenticatedUsers"

import { actionCreatorsLoginDialogForNotAuthenticatedUsers, EDomainActionsLoginDialogForNotAuthenticatedUsers } from "./actionsLoginDialogForNotAuthenticatedUsers"

export type TUseCasesLoginDialogForNotAuthenticatedUsers = ReturnType<typeof createUseCasesLoginDialogForNotAuthenticatedUsers>

const WAIT_PERIOD = 5000

export const createUseCasesLoginDialogForNotAuthenticatedUsers = ({ dataProviderUser, dispatch, getState }: { dataProviderUser: TDataProviderUser, dispatch: TDispatchAction, getState: TGetState<TDomainStateLoginDialogForNotAuthenticatedUsers> }) => {
  return {
    openDialog: async () => {
      const isAuthenticated = await dataProviderUser.checkIfUserIsAuthenticated()

      if(isAuthenticated) {
        dispatch(actionCreatorsLoginDialogForNotAuthenticatedUsers[EDomainActionsLoginDialogForNotAuthenticatedUsers.BOUNCED]())
        return
      }

      const timeoutId = setTimeout(async () => {
        // recheck authentication as it could changed during the timeout wait period
        const isAuthenticated = await dataProviderUser.checkIfUserIsAuthenticated()
        if(isAuthenticated) {
          dispatch(actionCreatorsLoginDialogForNotAuthenticatedUsers[EDomainActionsLoginDialogForNotAuthenticatedUsers.BOUNCED]())
          return
        }
        dispatch(actionCreatorsLoginDialogForNotAuthenticatedUsers[EDomainActionsLoginDialogForNotAuthenticatedUsers.SET_OPEN]({ isOpen: true }))
      }, WAIT_PERIOD) as unknown as number // this is window timeout retuning number

      dispatch(actionCreatorsLoginDialogForNotAuthenticatedUsers[EDomainActionsLoginDialogForNotAuthenticatedUsers.SET_TIMEOUT_ID]({ timeoutId }))
    },
    cancel: () => {
      const state = getState()

      if(state.timeoutId) {
        clearTimeout(state.timeoutId)
        dispatch(actionCreatorsLoginDialogForNotAuthenticatedUsers[EDomainActionsLoginDialogForNotAuthenticatedUsers.BOUNCED]())
      }
    }
  }
}
