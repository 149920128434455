import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import stores from '../../../../stores/index';
import {
  TRIGGERS,
} from '../../../../stores/addRule';
import { getPeriodName } from '../../../../helpers/utils';
import moment from 'moment';

@observer
class DetailTextTrigger extends Component {
  renderMaxOpenPositionsLimit(rule) {
    const isAnyTimeOperator = this.props.store.isAnyTimeOperator();

    if (!isAnyTimeOperator || +rule.rpt.l < 2 || !rule.mop) {
      return '';
    }

    return (
      <>
        {' '}with a limit of{' '}
        <strong>{+rule.mop > 0 ? rule.mop : '---'}</strong>{' '}
        open positions at the same time
      </>
    );
  }

  render() {
    // =================================================================
    /* Gabriel want's to leave "Launch" section empty for new rules
        until user start to interact with "Execure" ot "Go" sections
        or template being selected */
    let isLaunchSectionEmpty = false;
    if (
      !(this.props.isRuleNew === false) &&
      !this.props.store.isSFPlaunchSectionWasClicked &&
      !stores.templatesStore.isSelect
    ) {
      isLaunchSectionEmpty = true;
    }
    // =================================================================

    let { store } = this.props;
    const rule = toJS(store.rule);
    const triggerData = rule.tr;
    const period = rule.pe;

    let trigger;

    if (triggerData === TRIGGERS.TIME) {
      let startTime = 'immediately';

      // "ON" cases
      if (rule.tim === 'on') {
        if (rule.sd) {
          startTime = 'on ' + moment(rule.sd).format('D/M/YYYY HH:mm');
        }

        trigger = (
          <li
            className={`ruleSummaryText__section
                        ruleSummaryText__section_launch
                        ${isLaunchSectionEmpty && 'ruleSummaryText__section_empty'}
                      ${stores.addRule.sfpPendingSectionId === 9999 &&
              'ruleSummaryText__section_preloader'
              }`}
            onClick={() => this.props.handleClick(9999)}
          >
            <span
              className={`ruleSummaryText__keyWord 
                              ruleSummaryText__keyWord_launch`}
            >
              Execute{' '}
            </span>
            <span>
              <strong>{startTime}</strong>, execute 1 time
            </span>
          </li>
        );

        // "RIGHT NOW" cases
      } else if (rule.tim === 'now') {
        if (rule.rpt.t === 'total') {
          trigger = (
            <li
              className={`ruleSummaryText__section
                        ruleSummaryText__section_launch
                        ${isLaunchSectionEmpty && 'ruleSummaryText__section_empty'}
                      ${stores.addRule.sfpPendingSectionId === 9999 &&
                'ruleSummaryText__section_preloader'
                }`}
              onClick={() => this.props.handleClick(9999)}
            >
              <span
                className={`ruleSummaryText__keyWord
                              ruleSummaryText__keyWord_launch`}
              >
                Execute{' '}
              </span>
              <span>
                Run <strong>right now</strong>, run{' '}
                <strong>{+rule.rpt.l === 0 ? '---' : rule.rpt.l}</strong>{' '}
                {+rule.rpt.l === 1 ? 'time' : 'times'} <strong>in total</strong>
              </span>
            </li>
          );
        } else if (rule.rpt.t === 'no_more_than_once') {
          trigger = (
            <li
              className={`ruleSummaryText__section
                        ruleSummaryText__section_launch
                        ${isLaunchSectionEmpty && 'ruleSummaryText__section_empty'}
                      ${stores.addRule.sfpPendingSectionId === 9999 &&
                'ruleSummaryText__section_preloader'
                }`}
              onClick={() => this.props.handleClick(9999)}
            >
              <span
                className={`ruleSummaryText__keyWord
                              ruleSummaryText__keyWord_launch`}
              >
                Execute{' '}
              </span>
              <span>
                Run <strong>right now,</strong> execute{' '}
                <strong>{+rule.rpt.l === 0 ? '---' : rule.rpt.l}</strong>{' '}
                {+rule.rpt.l === 1 ? 'time' : 'times'} <strong>but not more than</strong> once every{' '}
                <strong>{getPeriodName(rule.rpt.nmt)}</strong>
                {this.renderMaxOpenPositionsLimit(rule)}, {' '}
              </span>
            </li>
          );
        }

        // "EVERY" cases
      } else if (rule.tim === 'every') {
        const every = 'Run every';
        let textRepeat = '';

        if (rule.sd) {
          startTime = 'on ' + moment(rule.sd).format('D/M/YYYY HH:mm');
        }
        if (rule.rpt.t === 'no_more_than_once') {
          textRepeat = (
            <span>
              execute{' '}
              <strong>
                {+rule.rpt.l === 0 ? '---' : rule.rpt.l} {+rule.rpt.l === 1 ? 'time' : 'times'}
              </strong>
              , <strong>but not more than</strong> once every{' '}
              <strong>{getPeriodName(rule.rpt.nmt)}</strong>
              {this.renderMaxOpenPositionsLimit(rule)}, {' '}
            </span>
          );
        } else if (rule.rpt.t === 'total') {
          textRepeat = (
            <span>
              execute{' '}
              <strong>
                {+rule.rpt.l === 0 ? '---' : rule.rpt.l} {+rule.rpt.l === 1 ? 'time' : 'times'} in
                total
              </strong>
            </span>
          );
        }

        trigger = (
          <li
            className={`ruleSummaryText__section
                        ruleSummaryText__section_launch
                        ${isLaunchSectionEmpty && 'ruleSummaryText__section_empty'}
                      ${stores.addRule.sfpPendingSectionId === 9999 &&
              'ruleSummaryText__section_preloader'
              }`}
            onClick={() => this.props.handleClick(9999)}
          >
            <span
              className={`ruleSummaryText__keyWord 
                              ruleSummaryText__keyWord_launch`}
            >
              Execute{' '}
            </span>
            <span>
              {every} <strong>{getPeriodName(period)}</strong>, starting{' '}
              <strong> {startTime}, </strong>
              {textRepeat}
            </span>
          </li>
        );
      }
    } else if (triggerData === TRIGGERS.DIRECT_ORDER) {
      trigger = (
        <li
          className={`ruleSummaryText__section
                        ruleSummaryText__section_launch
                        ${isLaunchSectionEmpty && 'ruleSummaryText__section_empty'}
                      ${stores.addRule.sfpPendingSectionId === 9999 &&
            'ruleSummaryText__section_preloader'
            }`}
          onClick={() => this.props.handleClick(9999)}
        >
          <span
            className={`ruleSummaryText__keyWord 
                            ruleSummaryText__keyWord_launch`}
          >
            Execute{' '}
          </span>
          <span>
            Run <strong>right now</strong>{' '}
          </span>
        </li>
      );
    } else if (triggerData === TRIGGERS.EVENT) {
      let startTime = 'immediately';
      let textRepeat = '';

      if (rule.sd) {
        startTime = 'on ' + moment(rule.sd).format('D.M.YYYY HH:mm');
      }

      if (rule.rpt.t === 'total') {
        textRepeat = ''; //'in total';
      } else if (rule.rpt.t === 'no_more_than_once') {
        textRepeat = (
          <span>
            {' '}
            <strong>but not more than</strong> once every{' '}
            <strong>{getPeriodName(rule.rpt.nmt)}</strong>
            {this.renderMaxOpenPositionsLimit(rule)}, {' '}
          </span>
        );
      }

      trigger = (
        /*        <li className={`ruleSummaryText__section
                        ruleSummaryText__section_launch
                        ${stores.addRule.isSFPexecuteSectionDisabled && 'ruleSummaryText__section_disabled'}*/
        <li
          className={`ruleSummaryText__section
                        ruleSummaryText__section_launch
                        ${isLaunchSectionEmpty && 'ruleSummaryText__section_empty'}
                      ${stores.addRule.sfpPendingSectionId === 9999 &&
            'ruleSummaryText__section_preloader'
            }`}
          onClick={() => this.props.handleClick(9999)}
        >
          <span
            className={`ruleSummaryText__keyWord 
                            ruleSummaryText__keyWord_launch`}
          >
            Execute{' '}
          </span>
          <span>Run</span> <strong>{rule.rpt.l ? rule.rpt.l : 0} </strong>
          <span>times, {textRepeat} start</span> <strong> {startTime} </strong>
          {/*<strong> {textRepeat} </strong>*/}
        </li>
      );
    }

    return <React.Fragment>{trigger}</React.Fragment>;
  }
}

export default withRouter(DetailTextTrigger);
