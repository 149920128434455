import type { DTOSharedRule, TRuleActivityLog } from "v2/domains/accessSharedRule"
import { EActivityLogTradeTypes } from "v2/dataProviders/accessSharedRule/externalDataModels";
import type { TModelRuleActivityLogs } from "./externalDataModels";

import type { TModelSharedRule } from "./externalDataModels/TModelSharedRule"

import { EModelRuleStatus} from "./externalDataModels/TModelSharedRule";

const mapRuleStatusToRuleDisplayedStatus = ({ status, startDate, currentDate }: { status: TModelSharedRule['status'], startDate: Date, currentDate: Date }): DTOSharedRule['displayedStatus'] => {
  // active status depends on start date and current date
  const activeStatus = startDate < currentDate ? 'waiting' as const : 'scheduled' as const
  const map = {
    draft: null,
    'active': activeStatus,
    'paused': 'paused' as const,
    'finished': 'finished' as const,
    'archived': 'archived' as const,
    'easy_error': 'gotError' as const,
    'fatal_error': 'gotError' as const,
  }

  const mappedStatus = map[status]

  if(!mappedStatus) throw new Error(`${status} is not supported`)

  return mappedStatus
}

const isRuleAfterStartDate = ({ startDate, currentDate }: { startDate: Date, currentDate: Date }): boolean => {
  return currentDate >= startDate
}

const isRuleBeforeExpirationTime = ({ endDate, currentDate }: { endDate: Date, currentDate: Date }): boolean => {
  return currentDate < endDate
}

const isRuleBeforeExecutionLimit = ({ numberOfExecutions, executionLimit }: { numberOfExecutions: number, executionLimit: number }): boolean => {
  if (executionLimit > 0 && (numberOfExecutions >= executionLimit)) return false

  return true
}

const isRuleBeforeExpirationTimeAndExecutionLimit = ({ endDate, numberOfExecutions, executionLimit, currentDate }: { endDate?: Date, numberOfExecutions: number, executionLimit: number, currentDate: Date }): boolean => {
  // if end date is set this will indicate if rule is still active
  if(endDate) return isRuleBeforeExpirationTime({ endDate, currentDate })

  return isRuleBeforeExecutionLimit({ numberOfExecutions, executionLimit })
}

const isRuleLive = ({ status, isActive, startDate, endDate, numberOfExecutions, executionLimit, currentDate }: { status: EModelRuleStatus, isActive: boolean, startDate: Date, endDate?: Date, numberOfExecutions: number, executionLimit: number, currentDate: Date }): boolean => {
  return (
    status === EModelRuleStatus.ACTIVE &&
    isActive &&
    isRuleAfterStartDate({ startDate, currentDate }) &&
    isRuleBeforeExpirationTimeAndExecutionLimit({ endDate, numberOfExecutions, executionLimit, currentDate })
  )
}

export const getRuleStatusText = ({ status, isActive, startDate, endDate, numberOfExecutions, executionLimit, currentDate }: { status: EModelRuleStatus, isActive: boolean, startDate: Date, endDate?: Date, numberOfExecutions: number, executionLimit: number, currentDate: Date }): string => {
  if(status === EModelRuleStatus.FATAL_ERROR) {
    return 'has an Error'
  } else if (isRuleLive({ status, isActive, startDate, endDate, numberOfExecutions, executionLimit, currentDate })) {
    return 'is Live'
  } else if (status === EModelRuleStatus.ACTIVE && !isActive) {
    return 'is Waiting for API keys'
  } else if (status === EModelRuleStatus.PAUSED) {
    return 'is Paused'
  } else if (status === EModelRuleStatus.DRAFT) {
    return 'is Draft'
  } else if (status === EModelRuleStatus.ARCHIVED) {
    return 'is Archived'
  } else {
    if (status === EModelRuleStatus.EASY_ERROR) {
      return 'is Live'
    } else if (isRuleAfterStartDate({ startDate, currentDate })) {
      return 'is Finished'
    } else {
      return 'is Waiting for activation time'
    }
  }
}

const getTotalFills = (fills: {price: number, quantity: number}[]) => {
  return fills.reduce((acc, fill) => ({
    totalPrice: acc.totalPrice + (fill.price * fill.quantity),
    totalQuantity: acc.totalQuantity + fill.quantity
  }), {
    totalPrice: 0,
    totalQuantity: 0,
  });
}

type TErrorDetailsTradeData = Omit<TRuleActivityLog, 'id' | 'date' | 'type' | 'name'>;

const mapErrorDetailsToTradeData = (error: TModelRuleActivityLogs['activities'][0]['error'], tradeType: string): TErrorDetailsTradeData  => {
  const EXCHANGE_ERROR_MESSAGE = 'Exchange rejected trade';
  const INSUFFICIENT_BALANCE_MESSAGE = 'Insufficient balance';
  
  const {errorType: errType, wallet, amount, message, side, before, after, unit,} = (error || {}) as TModelRuleActivityLogs['activities'][0]['error'] & {amount: number};
  const BALANCE_ERROR_PREFIX = `Not enough ${wallet} to perform the action`;

  const errorType = errType?.toLowerCase()

  switch (errorType) {
    case EActivityLogTradeTypes.API_KEY:
      return {
        description:'API key invalid'
      };

    case EActivityLogTradeTypes.MARGIN:
      return {description: 'Insufficient margin'};

    case EActivityLogTradeTypes.LOW_AMOUNT:
      const data: TErrorDetailsTradeData = {
        description: 'Waiting for balance',
        prefix: `Order size lower than min. req.`,
        suffix: 'by exchange'
      }
      if(message === 'WALLET_BALANCE') {
        data.prefix = BALANCE_ERROR_PREFIX
        data.suffix = ''
      };
      if(amount) data.tradedCoinQuantity = amount

      return data;

    case EActivityLogTradeTypes.SOURCE_INCORRECT:
      return {description:'Inconsistent rule'};

    case EActivityLogTradeTypes.PAIR_NOT_AVAILABLE:
      return {
        description: 'Trade Rejected',
        prefix: `Trading pair not available your region`

      };

    case EActivityLogTradeTypes.ORDER_NOT_EXECUTED:
      const common = {
        description: 'Order not executed'
      }
      if(side) return {
        ...common,
        prefix: `The market price was ${side === 'buy' ? 'greater' : 'lower'} than the price of the condition`
      }

      return {
        ...common,
      };

    case EActivityLogTradeTypes.SYSTEM_UPGRADE:
      return {
        description: 'Rule stopped', 
        prefix: 'The rule was stopped due to a system upgrade.'
      };

    case EActivityLogTradeTypes.BALANCE:
      const hasBeforeAndAfter = before && after;

      if(tradeType === EActivityLogTradeTypes.RULE_ALERT && hasBeforeAndAfter) return {
        description: INSUFFICIENT_BALANCE_MESSAGE,
        prefix: side === 'buy' ? 'Bought' : 'Sold',
        ...(after && {
          tradedCoinQuantity: after,
          tradedCoinSymbol: unit
        }),
        ...(before && {
          tradedCoinPrice: before,
          tradedCoinPriceCurrency: unit,
          ...(unit !== wallet && {
            suffix: `worth of ${wallet}`
          })}),
        intersection: 'instead of',
      }
      return {
        description: INSUFFICIENT_BALANCE_MESSAGE,
        prefix: BALANCE_ERROR_PREFIX
      };

    case EActivityLogTradeTypes.EX_REJECT:
      return {description: EXCHANGE_ERROR_MESSAGE};

    default:
      return {description: EXCHANGE_ERROR_MESSAGE};
  }
}

const mapTypesToTradeData = ({tradeType, error}: {tradeType: string, error?: TModelRuleActivityLogs['activities'][0]['error']}): Omit<TRuleActivityLog, 'id' | 'date' | 'type'> => {  
  const { errorType: erType } = error || {};
  const errorType = erType?.toLowerCase();

  const getErrorName = (tradeType: string, errorType?: string): string => {
    const isAlert = errorType === EActivityLogTradeTypes.BALANCE || errorType === EActivityLogTradeTypes.LOW_AMOUNT;    
    if (tradeType === EActivityLogTradeTypes.RULE_ERROR_PUBLIC && !isAlert) 
      return 'Error';

    return 'Alert'
  }
  
  switch (tradeType) {
    // Trade
    case EActivityLogTradeTypes.TRADE_BUY:
      return {
        name: 'Trade',
        description:'Buy',
      };
    case EActivityLogTradeTypes.TRADE_SELL:
      return {
        name: 'Trade',
        description: 'Sell'
      };
    case EActivityLogTradeTypes.TRADE_WAIT:
      return {
        name: 'Trade',
        description:'Waiting for data',
        prefix: 'Waiting for Trade Confirmation From Exchange'
      };

    // Rule Actions
    case EActivityLogTradeTypes.RULE_CREATE:
      return {
        name: 'Action',
        description:'Rule created'
      };
    case EActivityLogTradeTypes.RULE_UPDATE:
      return {
        name: 'Action',
        description:'Rule edited'
      };
    case EActivityLogTradeTypes.RULE_ARCHIVED:
      return {
        name: 'Action',
        description:'Rule archived'
      };
    case EActivityLogTradeTypes.RULE_DELETE:
      return {
        name: 'Action',
        description:'Rule deleted'
      };
    case EActivityLogTradeTypes.RULE_FINISHED:
      return {
        name: 'Action',
        description:'Rule finished'};
    case EActivityLogTradeTypes.RULE_PAUSED:
      return {
        name: 'Action',
        description: 'Rule paused'};
    case EActivityLogTradeTypes.RULE_ENABLED:
      return {
        name: 'Action',
        description:'Rule enabled'
      };

    // Condition Triggers
    case EActivityLogTradeTypes.CONDITION_MET:
      return {
        name: 'Trigger',
        description:'Condition met'
      };
    case EActivityLogTradeTypes.CONDITIONS_MET:
      return {
        name: 'Trigger',
        description:'Conditions met'
      };

    // Error
    case EActivityLogTradeTypes.RULE_ERROR_PUBLIC:
    case EActivityLogTradeTypes.RULE_ALERT:
      return {
        name: getErrorName(tradeType, errorType),
        ...mapErrorDetailsToTradeData(error, tradeType),
      }

    default:
      return {
        name: '',
        description: ''
      }
  }
}


export const converterAccessSharedRule =  {
  toDTO: ({ sharedRule, currentDate, ruleActivityLogs }: { sharedRule: TModelSharedRule, currentDate: Date, ruleActivityLogs: TModelRuleActivityLogs }): DTOSharedRule => {
    const startDate = new Date(sharedRule.startDate)
    const endDate = sharedRule.endDate ? new Date(sharedRule.endDate) : undefined

    const timezoneOffset = startDate.getTimezoneOffset()
    const timezoneString = `UTC${timezoneOffset === 0 ? '' : `${timezoneOffset < 0 ? ' +' : ' -'}${Math.abs(timezoneOffset / 60)}`}`

    const {total, activities} = ruleActivityLogs;

    const ruleActivityLogsConverted: TRuleActivityLog[] = activities.map(({ date, type, id, trade, error }) => {
      const {fills, base = '', quote = '', usdValue} = trade || {}

      const { totalPrice, totalQuantity } = getTotalFills(fills || [])
      const tradedCoinUnitPrice = (totalPrice && totalPrice) && totalPrice / totalQuantity;

      const { name, description, prefix, suffix, intersection, tradedCoinPrice, tradedCoinQuantity, tradedCoinUSDValue } = mapTypesToTradeData({tradeType: type, error});

      const ruleActivityLogConverted: TRuleActivityLog = {
        id,
        type,
        date: new Date(date),
        name,
        description,
        prefix,
        intersection,
        suffix,
        tradedCoinPrice, 
        tradedCoinQuantity, 
        tradedCoinUSDValue,
      };

      if(totalQuantity) ruleActivityLogConverted.tradedCoinQuantity = totalQuantity;
      if(base) ruleActivityLogConverted.tradedCoinSymbol = base

      if(tradedCoinUnitPrice) ruleActivityLogConverted.tradedCoinPrice = tradedCoinUnitPrice
      if(quote) ruleActivityLogConverted.tradedCoinPriceCurrency = quote
      if(usdValue) ruleActivityLogConverted.tradedCoinUSDValue = usdValue
      if(trade) ruleActivityLogConverted.intersection = 'at'

      return ruleActivityLogConverted;
    })
    const lastChartPoint = sharedRule.chart[sharedRule.chart.length - 1]
    const priceTrendSign = lastChartPoint ? lastChartPoint.percentageDifference > 0 ? '+' : lastChartPoint.percentageDifference < 0 ? '-' : '' : '' 

    return {
      ruleName: sharedRule.name,
      startDate,
      endDate,
      exchange: sharedRule.exchangeUid,
      exchangeName: sharedRule.exchangeName,
      trigger: sharedRule.trigger,
      displayedStatus: mapRuleStatusToRuleDisplayedStatus({ status: sharedRule.status, startDate, currentDate }),
      profitOrLossPercent: lastChartPoint ? `${priceTrendSign}${Math.abs(lastChartPoint.percentageDifference).toFixed(2)}%` : '0%',
      priceTrend: (lastChartPoint && lastChartPoint.percentageDifference >= 0) ? 'up' : 'down' ,
      numberOfExecutions: sharedRule.numberOfExecutions,
      executionLimit: sharedRule.executionLimit,
      timezoneString: timezoneString,
      baseCurrency: sharedRule.baseCurrency,
      chartDataPoints: sharedRule.chart,
      iconVariant: isRuleLive({ 
        status: sharedRule.status, 
        isActive: sharedRule.isActive, 
        startDate: startDate, 
        endDate: endDate, 
        numberOfExecutions: sharedRule.numberOfExecutions, 
        executionLimit: sharedRule.executionLimit, 
        currentDate 
      }) ? 'play' : 'pause',
      statusText: getRuleStatusText({ 
        status: sharedRule.status, 
        isActive: sharedRule.isActive, 
        startDate: startDate, 
        endDate: endDate, 
        numberOfExecutions: sharedRule.numberOfExecutions, 
        executionLimit: sharedRule.executionLimit, 
        currentDate 
      }),
      ruleActivityLogs: {
        activities: ruleActivityLogsConverted,
        total,
      },
    }
  }
}