import React, { Component } from 'react';

import Footer from '../Footer';

import './style.scss';

class MobileFooter extends Component {
  render() {
    return (
      <div className='footer-mobile'>
        <Footer mixinClass={`globalSidebar`} />
      </div>
    );
  }
}

export default MobileFooter;
