import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './style.scss';
import {
  setDataLocalStorage,
  checkIfOnRuleDetailPageFromLocation,
  checkIfOnRuleEditPageFromLocation,
} from '../../helpers/utils';

class BackHeader extends Component {
  render() {
    return (
      <div className={`backHeader ${checkIfOnRuleEditPageFromLocation() && 'backHeader_extendedHeight'}`}>
        <Link
          className={`backHeader__backLink`}
          to={this.props.to}
          onClick={() => {
            if (this.props.title === 'New Rule') {
              setDataLocalStorage('should_restore_rule', false);
            }
          }}
        >
          &nbsp;
        </Link>
        <h1
          className={`backHeader__h1
            ${checkIfOnRuleEditPageFromLocation() && 'backHeader__h1_reducedWidth'}
            ${checkIfOnRuleDetailPageFromLocation() && 'backHeader__h1_fullWidth'}`}
        >
          {this.props.title && (
            <span className={`backHeader__darkGrayTitle`}>{this.props.title}</span>
          )}
          {this.props.title1 && (
            <span className={`backHeader__lightGrayTitle`}>{this.props.title1} </span>
          )}
          {this.props.title2 && (
            <span className={`backHeader__darkGrayTitle 
              ${checkIfOnRuleEditPageFromLocation() && 'backHeader__secondLine'}`}
            >
              {this.props.title2}
            </span>
          )}
        </h1>
      </div>
    );
  }
}

export default BackHeader;
