import React from 'react';
import stores from 'stores';
import { CoinruleButton } from '../../../../components/CoinruleButton';

export const CreateTemplateButton = () => {
  return (
    <CoinruleButton
      size='big'
      color='gray'
      fillType='outline'
      icon='Edit'
      className='save-button'
      onClick={() => stores.addRule.setShowCreateTemplateModal(true)}
    >
      Create template
    </CoinruleButton>
  );
};
