import React from 'react'

import type { StackProps } from '@mui/material'

import { Stack } from '@mui/material'

export interface CenterProps extends StackProps {}

const testId = 'Center'
export const Center = (props: CenterProps) => {
  const {
    sx,
    ...otherProps
  } = props

  return (
    <Stack
      data-testid={testId}
      className={testId}
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      width={'100%'}
      height={'100%'}
      boxSizing={'border-box'}
      sx={{
        ...sx
      }}
      {...otherProps}
    />
  )
}

export default Center
