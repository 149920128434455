import React from 'react'

export interface IconExternalLinkProps extends React.SVGProps<SVGSVGElement> { }

const testId = 'IconExternalLink'
export const IconExternalLink = (props: IconExternalLinkProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      width={11}
      height={10}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...otherProps}
    >
      <g
        opacity={0.3}
        fill={"#000"}
      >
        <path d={"M6.893 1.326h2.854l-.63-.63V3.55c0 .162.07.33.185.445.11.11.289.191.445.185a.635.635 0 0 0 .445-.185.653.653 0 0 0 .185-.445V.696a.64.64 0 0 0-.63-.63H6.893c-.161 0-.33.07-.445.185a.644.644 0 0 0-.184.445c.007.163.06.332.184.445a.651.651 0 0 0 .445.185Z"} />
        <path d={"m5.453 5.872.472-.472L7.056 4.27 8.425 2.9l1.183-1.183c.192-.192.385-.382.575-.575a.649.649 0 0 0 .193-.453.633.633 0 0 0-.63-.63.655.655 0 0 0-.445.185 19035.114 19035.114 0 0 1-1.604 1.603L6.33 3.215 5.146 4.398c-.192.192-.385.382-.575.575l-.009.009a.648.648 0 0 0-.185.445c0 .154.069.339.185.445.12.11.278.192.446.184a.655.655 0 0 0 .445-.184Z"} />
        <path d={"M9.116 5.855v2.071c0 .116.009.235-.007.35l.022-.168a.8.8 0 0 1-.048.176l.063-.15a.803.803 0 0 1-.097.167l.099-.127a.826.826 0 0 1-.135.135l.127-.098a.777.777 0 0 1-.167.096l.15-.063a.784.784 0 0 1-.177.049l.168-.022c-.086.011-.175.007-.262.007H3.065c-.267 0-.534.002-.8 0a.735.735 0 0 1-.106-.007l.168.022a.799.799 0 0 1-.177-.049l.15.063a.803.803 0 0 1-.166-.096l.127.098a.826.826 0 0 1-.136-.135l.099.127a.776.776 0 0 1-.097-.167l.063.15a.783.783 0 0 1-.048-.176l.022.168c-.012-.086-.007-.175-.007-.263V2.226c0-.267-.002-.533 0-.799 0-.035.002-.071.007-.107l-.022.168a.798.798 0 0 1 .048-.176l-.063.15a.803.803 0 0 1 .097-.167l-.099.127a.826.826 0 0 1 .136-.135l-.127.098A.776.776 0 0 1 2.3 1.29l-.15.063a.784.784 0 0 1 .177-.049l-.168.022c.096-.012.197-.007.295-.007h2.125c.162 0 .33-.07.445-.185A.644.644 0 0 0 5.21.688a.635.635 0 0 0-.185-.445A.653.653 0 0 0 4.58.06H2.416c-.184 0-.37 0-.548.057-.124.04-.252.094-.358.171-.067.05-.14.102-.2.161-.065.062-.118.137-.171.209-.157.21-.231.487-.238.746-.003.131 0 .263 0 .394v6.045c0 .213-.004.415.034.626a.697.697 0 0 0 .033.122c.032.085.067.17.11.25.06.11.141.215.232.302.183.177.428.319.682.36.12.019.23.03.348.03H8.923c.047 0 .095 0 .142-.003.132-.01.269-.026.394-.076.08-.031.162-.062.237-.106a1.66 1.66 0 0 0 .223-.164 1.343 1.343 0 0 0 .405-.647c.05-.177.05-.355.05-.535V5.85c0-.161-.07-.33-.184-.445a.644.644 0 0 0-.445-.184.635.635 0 0 0-.445.184.668.668 0 0 0-.184.45Z"} />
      </g>
    </svg>
  )
}

export default IconExternalLink
