import { observable, action } from 'mobx';
import http from '../helpers/http';
import { errorNotification, successNotification } from '../helpers/notification';
import { AbstractStore } from './AbstractStore';

export default class AdminStore extends AbstractStore {
  constructor(rootStore) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  @observable usersList = [];
  @observable rulesList = [];
  @observable currentPage = 1;
  @observable filter;
  @observable isNewFilter = false;
  @observable isLoading = false;
  @observable isLoaded = false;
  @observable totalPages = 0;
  @observable userDetailActivities;
  @observable userDetailActivitiesPage;
  @observable userDetailActivitiesTotalPage;
  @observable itemsPerPage = 10;
  @observable activeDemoRules = 0;
  @observable activeLiveRules = 0;
  @observable arbiterInstances = [];

  @action
  async makeAnonymousUser(userId) {
    return await this.updateUser('/admin/makeUserAnonymous', {
      userId: userId,
    });
  }

  @action
  async changeUserNotifs(userId, newNotifs, hash) {
    return await this.updateUser('/admin/updateNotifs', {
      userId: userId,
      notifs: newNotifs,
      hash: hash || '',
    });
  }

  async getUserNotifs(userId, hash) {
    if (this.isLoading) {
      return;
    }

    try {
      let response;

      this.isLoading = true;

      if (hash) {
        response = await http.post('/admin/notifs', {
          userId: userId,
          hash: hash,
        });
      } else {
        response = await http.post('/admin/notifs', { userId: userId });
      }
      this.isLoading = false;

      if (response.data && response.data.status === 'OK') {
        const uniqueSet = new Set(response.data.data.notifs);
        return [...uniqueSet];
      } else {
        throw new Error('Unexpected response');
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoading = false;
    }
  }

  @action
  async changeUserPlan(userId, plan) {
    return await this.updateUser('/admin/changePlan', {
      userId: userId,
      plan: plan,
    });
  }

  @action
  async getUserDetail(userId, params = null) {
    if (this.isLoading) {
      return;
    }

    params = {
      ...params,
      userId,
    };

    try {
      this.isLoading = true;
      const resp = await http.post('/admin/userDetail', params);

      this.isLoading = false;

      if (resp.data) {
        if (resp.data.status === 'OK') {
          this.isLoaded = true;
          this.userDetailActivities = resp.data.data.activities;
          this.userDetailActivitiesTotalPage = resp.data.data.totalPages;
          this.activeDemoRules = resp.data.data.activeDemoRules;
          this.activeLiveRules = resp.data.data.activeLiveRules;
        } else {
          errorNotification(resp.data.message);
        }

        return resp.data.authorized;
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoading = false;
    }
  }

  async getUsersStats() {
    try {
      const resp = await http.get('/adminstats/users');

      if (resp.data) {
        if (resp.data.status === 'OK') {
          return resp.data;
        } else {
          throw new Error('Bad response');
        }
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      errorNotification(
        'Something has gone wrong while getting users stats. Please contact Ondra ;)'
      );
    }
  }

  async getUsersGrowth() {
    try {
      const resp = await http.get('/adminstats/usersGrowth');

      if (resp.data) {
        if (resp.data.status === 'OK') {
          return resp.data;
        } else {
          throw new Error('Bad response');
        }
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      errorNotification(
        'Something has gone wrong while getting users stats. Please contact Ondra ;)'
      );
    }
  }

  async getCountriesStats() {
    try {
      const resp = await http.get('/adminstats/countries');

      if (resp.data) {
        if (resp.data.status === 'OK') {
          return resp.data;
        } else {
          throw new Error('Bad response');
        }
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      errorNotification(
        'Something has gone wrong while getting users stats. Please contact Ondra ;)'
      );
    }
  }

  async getRulesStats() {
    try {
      const resp = await http.get('/adminstats/rules');

      if (resp.data) {
        if (resp.data.status === 'OK') {
          return resp.data;
        } else {
          throw new Error('Bad response');
        }
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      errorNotification(
        'Something has gone wrong while getting users stats. Please contact Ondra ;)'
      );
    }
  }

  @action
  async getUsersList(params = null) {
    if (this.isLoading) {
      return;
    }

    try {
      this.isLoading = true;

      if (this.filter) {
        if (
          this.filter.conditionQuery.exchanges.length > 0 ||
          this.filter.conditionQuery.rulesType
        ) {
          params = {
            ...params,
            ...this.filter,
          };
        }
      }

      this.isNewFilter = true;

      const resp = await http.post('/admin/usersList', params);

      this.isLoading = false;
      this.isNewFilter = false;

      if (resp.data) {
        if (resp.data.status === 'OK') {
          this.usersList = resp.data.data;
          this.totalPages = resp.data.totalPages;
          this.isLoaded = true;
          this.itemsPerPage = resp.data.itemsPerPage;
          return resp.data.data;
        } else {
          errorNotification(resp.data.message);
        }
        // Save the list of users to cache
        localStorage.setItem('usersList', JSON.stringify(this.usersList));

        return resp.data.authorized;
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoading = false;
    }
  }

  async getRulesList(params = null) {
    try {
      const resp = await http.post('/admin/rulesList', params);

      if (resp.data) {
        if (resp.data.status === 'OK') {
          this.rulesList = resp.data.data;
          this.totalPages = resp.data.totalPages;
          this.isLoaded = true;
          this.itemsPerPage = resp.data.itemsPerPage;
          return resp.data.data;
        } else {
          errorNotification(resp.data.message);
        }
        return resp.data.authorized;
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`getRulesList has gone wrong. Please try it again later.`);
      this.isLoading = false;
    }
  }

  async searchForUsers(input) {
    try {
      this.isLoading = true;
      const resp = await http.get('/admin/searchForUsers?input=' + input);

      if (resp.data) {
        if (resp.data.status === 'OK') {
          return resp.data.users;
        } else {
          errorNotification(resp.data.message);
        }
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoading = false;
    }
  }

  async createCoupon(inputs) {
    try {
      console.log('Inputs: ', inputs);
      this.isLoading = true;

      const resp = await http.post('/admin/coupon', inputs);

      if (resp.data) {
        if (resp.data.status === 'OK') {
          return resp.data.couponUrl;
        } else {
          errorNotification(resp.data.message);
        }
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoading = false;
    }
  }

  @action
  async sendUserVerifCode(userId) {
    return await this.updateUser('/admin/verifCode', {
      userId: userId,
    });
  }

  @action
  async setPinCode(userId) {
    return await this.updateUser('/admin/setPin', {
      userId: userId,
    });
  }

  async getLists() {
    try {
      const resp = await http.get('/admin/lists');

      if (resp.data) {
        if (resp.data.status === 'OK') {
          this.arbiterInstances = resp.data.arbiterInstances;
        } else {
          throw new Error('Bad response');
        }
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      errorNotification(
        'Something has gone wrong while getting admin data.'
      );
    }
  }

  async changeUserServer(userId, ai) {
    return await this.updateUser('/admin/changeServer', {
      userId: userId,
      ai: ai,
    });
  }

  async updateUser(path, params) {
    if (this.isLoading) {
      return;
    }

    if (!params.userId) {
      errorNotification(`User id missing for request`);
      return;
    }

    try {
      this.isLoading = true;
      const resp = await http.post(path, params);
      this.isLoading = false;

      if (resp.data) {
        if (resp.data.status === 'OK') {
          await this.getUsersList({ userId: params.userId });
          successNotification('Done');
          return resp.data.data;
        } else {
          errorNotification(resp.data.message);
        }
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoading = false;
    }

    return '';
  }

  @action
  async sendProServerNotifications(userId) {
    return await this.updateUser('/admin/proServerNotification', {
      userId: userId,
    });
  }
}
