import React from 'react';
import { Button } from 'antd';
import { handleCopy } from 'helpers/utils';
import { ButtonProps } from 'antd/lib/button';

interface IClickToCopyProps extends ButtonProps {
  textToCopy: string;
  itemName?: string;
}

export const ClickToCopy: React.FC<IClickToCopyProps> = ({
  textToCopy,
  itemName,
  children,
  ...otherProps
}) => (
  <Button icon='copy' {...otherProps} onClick={() => handleCopy(textToCopy, itemName)}>
    {children || `Copy ${itemName}`}
  </Button>
);
