import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';

import logout from '../../helpers/logout';
import history from '../../helpers/history';
import Footer from '../Footer';
import MenuItems from '../MenuItems';

import './style.scss';

import stores from '../../stores';
import { ErrorBoundary, GlobalErrorStore } from '../ErrorBoundary';
import { CoinruleButton } from '../CoinruleButton';

import { Button, IconPlay, Box } from 'design-system';

const analytics = window.mixpanel;

@observer
class GlobalSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSidebarFolded: true,
      isLogoAvaliable: false,
    };
  }

  isEditRulePage() {
    return (
      this.props.match.path === '/rule' ||
      this.props.match.path === '/rule/:id/edit'
    );
  }

  isAdminPage() {
    return this.props.location.pathname === '/admin';
  }


  checkIfSignup(target) {
    return target.pathname === '/signup';
  }

  handleLogin = (e) => {
    e.preventDefault();
    this.props.history.push('/login');
  };

  handleClickLogout = (e) => {
    logout(e);
  };

  handleReturnToDashboard = (e) => {
    e.preventDefault();

    if (history.location.pathname !== '/') {
      history.push('/');
    }
  };

  renderLoginButton() {
    return (
      <div className='sidebar-content'>
        <div className='login'>
          <Button
            style={{
              width: '100%',
              paddingLeft: '17px',
              paddingRight: '17px',
              fontWeight: 600,
            }}
            onClick={this.handleLogin}
            colorVariant={'primary'}
          >
            <Box
              sx={{ 
                lineHeight: '17px',
                fontSize: '15px',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: 0
              }}
            >
              Login
              <IconPlay
                width={"17px"}
                height={'17px'}
                opacity={0.4}
              />
            </Box>
          </Button>
        </div>
      </div>
    );
  }

  handleSidebarFolding = (whatToDo) => {
    if (whatToDo === 'fold') this.setState({ isSidebarFolded: true });
    if (whatToDo === 'unfold') this.setState({ isSidebarFolded: false });
  };

  makeLogoAvailable = () => {
    this.setState(() => {
      return { isLogoAvaliable: true };
    });
  };

  makeLogoAvailableTimeout;

  componentDidMount() {
    this.makeLogoAvailableTimeout = setTimeout(this.makeLogoAvailable, 850);
  }

  componentWillUnmount(prevProps, prevState, snapshot) {
    clearTimeout(this.makeLogoAvailableTimeout);
  }

  render() {
    if (!this.props.loggedOut)
      return (
        /*Global Sidebar*/
        <div
          className={`globalSidebar
													${!this.props.forceOpened && (this.isEditRulePage() || this.isAdminPage())
              ? 'globalSidebar_foldable'
              : ''
            }
													${this.props.extraClass}
                        `}
          onMouseEnter={() => this.handleSidebarFolding('unfold')}
          onClick={() => this.handleSidebarFolding('unfold')}
          onMouseLeave={() => this.handleSidebarFolding('fold')}
        >
          <div
            className={`globalSidebar__wrapper
														${this.state.isSidebarFolded && `globalSidebar__wrapper_folded`}`}
            style={{ paddingBottom: `${stores.user.isPromoBarInvestShowed ? '58px' : '0'}` }}
          >
            {/*Logo in Global Sidebar*/}
            <div
              className={`gsLogo
														${!this.state.isLogoAvaliable && 'gsLogo_unavailable'}
														${this.state.isSidebarFolded && 'gsLogo_folded'}
														globalSidebar__logo`}
            >
              <div
                className={`gsLogo__clickableArea`}
                onClick={(e) => {
                  this.handleReturnToDashboard(e);
                  GlobalErrorStore.setErrorFalse();
                }}
              >
                &nbsp;
              </div>

              <div className={`gsLogo__emblem`}>
                <img
                  src={require('../../assets/images/gsLogo_emblemImg.gif')}
                  alt='Coinrule'
                  className={`gsLogo__emblemImg`}
                />
              </div>

              <div className={`gsLogo__textPartWrap`}>
                <div className={`gsLogo__text`}>
                  <img src={require('../../assets/images/logo-text.svg')} alt='Coinrule' />
                </div>

                <div className={`gsLogo__badgesWrap`}>
                  {stores.user?.user?.plan?.isPremium && (
                    <div className={`gsLogo__planBadge`}>
                      <div className={`gsLogo__planBadgeInWrap`}>
                        {stores.user?.user?.plan?.name?.toUpperCase()}
                      </div>
                    </div>
                  )}

                  <div className={`gsLogo__versionBadge`}>Beta</div>
                </div>
              </div>
            </div>

            {/*Main Menu in Global Sidebar*/}
            <div
              className={`gsMainMenu
															${this.state.isSidebarFolded && 'gsMainMenu_folded'}
															globalSidebar__mainMenu`}
            >
              <MenuItems useImg={true} noIcons={true} />
            </div>

            {/*Unfold arrow*/}
            <div className={`globalSidebar__unfoldArrow`}>&nbsp;</div>

            {/*Bottom buttons*/}
            <div className={`globalSidebar__bottomButtons`}>
              <CoinruleButton
                fillType='solid'
                icon='Check'
                className={`sidebar-bottom__buttonLink
														sidebar-bottom__getPlanButtonLink`}
                component={NavLink}
                to='/settings/billing'
                onClick={() => {
                  analytics.track('"Get Plan" button clicked', { page: 'Sidebar' });
                }}
                ariaLabel='Get Plan'
              >
                <span className={`coinruleBut__text`}>Get Plan</span>
              </CoinruleButton>

              <CoinruleButton
                color='violet'
                icon='HelpCenter'
                className={`sidebar-bottom__buttonLink`}
                component={'a'}
                href='https://coinrule.com/help'
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => {
                  analytics.track('"Help Center" button clicked', { page: 'Sidebar' });
                }}
                ariaLabel='Help Center'
              >
                <span className={`coinruleBut__text`}>Help Center</span>
              </CoinruleButton>

              <NavLink
                to='/logout'
                className={`globalSidebar__logoutButton`}
                onClick={() => {
                  this.handleClickLogout();
                  GlobalErrorStore.setErrorFalse();
                }}
              >
                <span className={`globalSidebar__logoutButtonText`}>Logout</span>
              </NavLink>
            </div>

            <div className={`globalSidebar__copyright`}>
              <Footer mixinClass={`globalSidebar`} />
            </div>
          </div>
        </div>
      );

    return (
      <div className={`globalSidebar ${this.props.extraClass}`}>
        <ErrorBoundary name={'boundary_GlobalSidebar'}>
          <div className='sidebar'>
            <div className='sidebar-top'>
              <div
                onClick={(e) => {
                  this.handleReturnToDashboard(e);
                  GlobalErrorStore.setErrorFalse();
                }}
                className='logo'
              >
                <img src={require('../../assets/images/logo-robot.svg')} alt='logo' />
              </div>
              <div
                onClick={(e) => {
                  this.handleReturnToDashboard(e);
                  GlobalErrorStore.setErrorFalse();
                }}
                className='cr-name'
              >
                <img src={require('../../assets/images/logo-text.svg')} alt='' />
              </div>
              <div className='beta'>BETA</div>
            </div>

            {this.renderLoginButton()}
          </div>
        </ErrorBoundary>
      </div>
    );
  }
}

export default withRouter(GlobalSidebar);
