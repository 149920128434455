import React from 'react'

export interface IconShareViaEmailProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconShareViaEmail'
export const IconShareViaEmail = (props: IconShareViaEmailProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-id={testId}
      width={"1em"}
      height={"1em"}
      viewBox={"0 0 59 59"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...otherProps}
    >
      <path
        d={"M22.5416 20.8125C21.5139 20.8125 20.5283 21.2208 19.8016 21.9475C19.0749 22.6742 18.6666 23.6598 18.6666 24.6875V25.0769L30.2916 31.337L41.9166 25.0789V24.6875C41.9166 23.6598 41.5084 22.6742 40.7817 21.9475C40.055 21.2208 39.0693 20.8125 38.0416 20.8125H22.5416Z"}
        fill={"#CCCCCC"}
      />
      <path
        d={"M41.9166 27.2781L30.7508 33.2901C30.6097 33.3661 30.4519 33.4059 30.2916 33.4059C30.1313 33.4059 29.9736 33.3661 29.8324 33.2901L18.6666 27.2781V36.3126C18.6666 37.3404 19.0749 38.326 19.8016 39.0527C20.5283 39.7794 21.5139 40.1876 22.5416 40.1876H38.0416C39.0693 40.1876 40.055 39.7794 40.7817 39.0527C41.5084 38.326 41.9166 37.3404 41.9166 36.3126V27.2781Z"}
        fill={"#CCCCCC"}
      />
      <circle
        cx={29.5}
        cy={29.5}
        r={28}
        stroke={"#CCCCCC"}
        strokeWidth={3}
      />
    </svg>
  )
}

export default IconShareViaEmail
