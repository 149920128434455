import React from 'react'

import type { StackProps } from '@mui/material'

import { Box, Stack, useTheme, useMediaQuery, ClickAwayListener, tooltipClasses } from '@mui/material'

import type { TExchangeUid } from 'design-system'
import { Spacer, IconInfo, Tooltip, IconCopy, Link, CopyLinkArea, BadgeOutlinedSuccess, Typography, ExchangeLogoWithWordmarkSprite } from 'design-system';

export interface ExchangeConnectedProps extends StackProps {
  exchange: TExchangeUid
  exchangeName: string
  apiKey: string
  isLive: boolean
  ips?: string[]
  onClickRemove: () => void
  onClickCopyIps: () => void
  showInfoTooltip: boolean
  tooltipText: string
}

const testId = 'ExchangeConnected'
export const ExchangeConnected = (props: ExchangeConnectedProps) => {
  const {
    sx,
    exchange,
    exchangeName,
    apiKey,
    ips,
    isLive,
    onClickRemove,
    onClickCopyIps,
    showInfoTooltip,
    tooltipText,
    ...otherProps
  } = props

  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Stack
      data-testid={testId}
      className={testId}
      justifyContent={'space-between'}
      alignItems={isXs ? 'flex-start' : 'center'}
      direction={isXs ? 'column' : 'row'}
      sx={{
        ...sx,
        width: '100%',
        maxWidth: '397px',
        minHeight: '129px',
        height: 'auto',
        padding: '15px 14px 8px',
        border: `1px solid ${theme.palette.coinrule.basic.greyBorder}`,
        borderRadius: '4px',
        position: 'relative',
      }}
      {...otherProps}
    >
      <Box
        sx={{
          display: 'flex',
          width: '106px',
          height: isXs ? 'auto' : '106px',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: '15px',
        }}
      >
        <ExchangeLogoWithWordmarkSprite
          exchange={exchange}
        />
      </Box>
      <Stack
        direction={'column'}
        sx={{
          overflow: 'hidden',
          fontSize: '16px',
          fontWeight: '300',
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: '1.5',
          letterSpacing: 'normal',
          color: theme.palette.coinrule.basic.black,
          width: '100%',
        }}
      >
        <Stack
          direction={'row'}
          sx={{
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '1.5',
              letterSpacing: 'normal',
            }}
          >
            {exchangeName}
          </Typography>
          {
            isLive ?
              <>
                <Spacer x={12} />
                <BadgeOutlinedSuccess>
                  LIVE
                </BadgeOutlinedSuccess>
              </>
              :
              null
          }
        </Stack>
        <Typography
          noWrap={true}
          sx={{
            fontSize: '16px',
            fontWeight: '300',
            lineHeight: '1.5',
            letterSpacing: 'normal',
          }}
        >
          API Key: {apiKey}
        </Typography>
        <Stack
          direction={'row'}
          alignItems={'center'}
        >
          {
            ips ?
              <>
                <CopyLinkArea
                  sx={{
                    width: 'calc(100% - 70px)',
                  }}
                  icon={<IconCopy />}
                  variant={'small'}
                  colorVariant={'grey'}
                  onClick={onClickCopyIps}
                >
                  {ips.join(', ')}
                </CopyLinkArea>
                <Spacer x={10} />
              </>
              :
              null
          }
          <Link
            sx={{
              width: '60px',
              color: theme.palette.coinrule.basic.grey,
              fontSize: '16px',
              textDecoration: 'underline',
              lineHeight: '24px',
              letterSpacing: '0',
              '&:hover': {
                fontWeight: 500,
                color: theme.palette.coinrule.basic.grey,
                textDecoration: 'underline',
              }
            }}
            onClick={onClickRemove}
          >
            Remove
          </Link>
        </Stack>
      </Stack>
      {
        showInfoTooltip ?
          <ClickAwayListener
            onClickAway={handleTooltipClose}
          >
            <div>
              <Tooltip
                title={tooltipText}
                onClick={handleTooltipOpen}
                onClose={handleTooltipClose}
                open={open}
                PopperProps={{
                  // @ts-ignore according to docs and runtime sx is a valid prop on Popper component https://mui.com/api/popper/
                  sx: {
                    [`& .${tooltipClasses.arrow}`]: {
                      color: '#1c2236',
                    },
                    [`& .${tooltipClasses.tooltip}`]: {
                      backgroundColor: '#1c2236',
                    },
                  }
                }}
                sx={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  cursor: 'pointer',
                }}
              >
                <Box>
                  <IconInfo />
                </Box>
              </Tooltip>
            </div>
          </ClickAwayListener>
          :
          null
      }
    </Stack>
  )
}

export default ExchangeConnected
