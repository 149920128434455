import React from 'react';
import { observer } from 'mobx-react';

//import stores from '../../../stores/index';

import './style.scss';

// const content = {
//   // INDEX 111
//   'choose-which-coin': {
//     title: 'Select Coin',
//     text: 'Decide which coin this rule should follow',
//     placement: 'topLeft'
//   },
//   'choose-coin': {
//     title: '(INDEX 222) NEED TO BE CHANGED Choose coin',
//     text: 'NEED TO BE EDITED Choose coin description',
//     placement: 'topLeft'
//   },
//   'condition-amount': {
//     title: 'Choose Condition Value',
//     text: 'Select the amount which will trigger the rule',
//     placement: 'topLeft'
//   },
//   'action-amount': {
//     title: 'Choose Trade Amount',
//     text: 'Select the value which will trigger the rule',
//     placement: 'bottomLeft'
//   },
//   // INDEX 333.1
//   'dropdown-amount-buy': {
//     title: 'Select Coin',
//     text: 'Choose what coin you would like to buy',
//     placement: 'bottomLeft'
//   },
//   // INDEX 333.2
//   'dropdown-amount-sell': {
//     title: 'Select Coin',
//     text: 'Choose what coin you would like to sell',
//     placement: 'bottomLeft'
//   },
//   // INDEX 444.1
//   'with-my-wallet-buy': {
//     title: 'Select Wallet',
//     text: 'Choose with which wallet you will buy the selected coin',
//     placement: 'bottomLeft'
//   },
//   // INDEX 444.2
//   'with-my-wallet-sell': {
//     title: 'Select Wallet',
//     text: 'Choose to which wallet you will sell the selected coin',
//     placement: 'bottomLeft'
//   },
//   'launch-amount': {
//     title: 'Number of Executions',
//     text: 'Choose how often the rule should run',
//     placement: 'top'
//   },
// };

@observer
class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      index: null,
      changedTooltip: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.templateId && this.props.templateId !== prevProps.templateId) {
      this.setState({
        index: this.props.store.getTooltipIndex(),
      });

      this.props.store.addTooltip(this);
    }
  }

  componentDidMount() {
    if (this.props.templateId) {
      this.setState({
        index: this.props.store.getTooltipIndex(),
      });

      this.props.store.addTooltip(this);

      // Focus on the input
      if (this.input) {
        this.input.focus();
      }
    }
  }

  componentWillUnmount() {
    // this.props.store.resetStages();
  }

  checkIfPopoverVisible() {
    return false; // temporarily stopped

    /*return stores.user.tooltipCount < 2
      && this.props.templateId
      && this.props.inputClass.includes('template-pre-fill');*/
  }

  moveToNextTooltip(delay, event) {
    // Handle events set by the child element itself
    if (event) {
      this.props.children.props.onChange(event);
    }

    this.setState({
      changedTooltip: true,
    });
    setTimeout(() => this.props.store.increaseGuideStage(), delay ? 700 : 0);
  }

  render() {
    return this.props.children;

    // if (this.checkIfPopoverVisible()) {
    //   const child = React.cloneElement(this.props.children, {
    //     // onChange: event => this.moveToNextTooltip(true, event),
    //     // onBlur: () => this.moveToNextTooltip(false),
    //     onClick: () => this.moveToNextTooltip(false),
    //     ref: input => this.input = input,
    //   });

    //   return(
    //     <Popover overlayClassName={`Popover
    //                                 ${content[this.props.typeOfField].placement}
    //                                 tooltipCreateRuleForm`}
    //              title={content[this.props.typeOfField].title}
    //              autoAdjustOverflow={false}
    //              content={
    //                <div onClick={() => this.moveToNextTooltip(false)}
    //                     className='content-div'>
    //                  <div className='content-text'>
    //                    {content[this.props.typeOfField].text}
    //                  </div>
    //                </div>
    //              }
    //              visible={this.props.store.isCurrentTooltip(this.state.index)}
    //              placement={content[this.props.typeOfField].placement}>
    //       {child}
    //     </Popover>
    //   )
    // }
    // return this.props.children
  }
}

export default Tooltip;
