/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ErrorModel {
  type: string;
  message: string;
  errors?: ErrorModel[];
}

export interface RuleActivities {
  authorized: boolean;

  /** @format date-time */
  timestamp: string;
  status: 'OK' | 'ERROR';
  data: {
    totalPages: number;
    totalActivities: number;
    activities: {
      ip: boolean;
      _id: string;
      o: string;
      t: string;
      r: string;
      or: number;
      createdAt?: string;
      s: boolean;
      d: ActivityDetail;
    }[];
  };
}

/**
 * Activity detail
 */
export interface ActivityDetail {
  message?: string;
  more?: {
    trade?: {
      more?: {
        tradeId?: string;
        tradeDetail?: {
          status?: string;
          base?: string;
          quote?: string;
          symbol?: string;
          orderSide?: string;
          orderType?: string;
          orderQuantity?: number;
          fills?: { commission?: number; asset?: string; price?: string; quantity?: string }[];
          usdValue?: number;
        };
        trade?: object;
      };
    };
  };
}

export interface TemplateCategory {
  nam?: string;
  _id?: string;
}

export interface Template {
  templateName?: string;
  category?: TemplateCategory[];
  description?: string;
  teaserText?: string;
  complexity?: number;
  pair?: string;
  graph?: string;
  amount?: number;
  percentage?: string;
  number?: number;
  unlocked?: boolean;
  requiredClearance?: number;
}

export type TemplateDetail = Template & { params?: object };

export interface Templates {
  totalCount?: number;
  templates?: Template[];
  templateCategories?: TemplateCategory[];
}

export interface Success {
  /** Response timestamp */
  timestamp?: string;

  /** Response status */
  status: 'OK' | 'ERROR';

  /** Authorization status */
  authorized: boolean;
}

export type PublicRuleViewSuccess = Success & { data: PublicRuleView };

export interface PublicRuleView {
  id: string;
  name: string;
  trigger: 'time' | 'event' | 'direct_order';
  exchangeUid: string;
  exchangeName: string;
  status:
    | 'draft'
    | 'active'
    | 'paused'
    | 'archived'
    | 'finished'
    | 'hidden'
    | 'easy_error'
    | 'fatal_error';

  /** times of execution in total or in a time period, same as repeats.limit */
  executionLimit: number;
  repeats: { limit?: number; type?: 'total' | 'no_more_than_once'; noMoreThanOncePer?: string };
  isActive: boolean;

  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate?: string;
  baseCurrency: string;

  /** combined number of buys and sells */
  numberOfExecutions: number;
  chart: { percentageDifference: number; date: string }[];
}

export interface PublicRuleHistory {
  /** total number of records */
  total: number;
  activities: {
    id: string;
    type: string;
    date: string;
    trade?: {
      fills: { price: number; quantity: number }[];
      base: string;
      quote: string;
      symbol: string;
      usdValue: number;
      orderSide: string;
      orderType: string;
      status: string;
    };
    error?: {
      errorType: string;
      wallet?: string;
      side?: string;
      unit?: string;
      before?: number;
      after?: number;
      message?: string;
    };
  }[];
}

export type PublicRuleHistorySuccess = Success & { data: PublicRuleHistory };

export interface RequestFailed {
  /** Response timestamp */
  timestamp?: string;

  /** Response status */
  status?: 'OK' | 'ERROR';

  /** Authorization status */
  authorized?: boolean;

  /** Failure information */
  message?: string;
}

export interface ShareRuleSuccess {
  /** Response timestamp */
  timestamp?: string;

  /** Response status */
  status: 'OK' | 'ERROR';

  /** Authorization status */
  authorized: boolean;
  data: { slug: string };
}

export interface Error {
  message: string;
  type: string;
  errors?: Error[];
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
          ? JSON.stringify(property)
          : `${property}`
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      requestParams.headers.common = { Accept: '*/*' };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Coinrule backend API
 * @version 1.0.10
 * @termsOfService https://www.coinrule.com/
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  rule = {
    /**
     * No description
     *
     * @name ShareRule
     * @summary Make a rule public and return shareable URL
     * @request POST:/rule/share
     */
    shareRule: (query?: { rule_id?: string }, params: RequestParams = {}) =>
      this.request<ShareRuleSuccess | RequestFailed, any>({
        path: `/rule/share`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns rule activities
     *
     * @name GetRuleActivities
     * @request GET:/rule/activities
     */
    getRuleActivities: (query: { rule_id: string }, params: RequestParams = {}) =>
      this.request<RuleActivities, ErrorModel>({
        path: `/rule/activities`,
        method: 'GET',
        query: query,
        ...params,
      }),
  };
  view = {
    /**
     * No description
     *
     * @name GetSharedRuleBySlug
     * @summary Get a publicly available rule data
     * @request GET:/view/rule/{slug}
     */
    getSharedRuleBySlug: (slug: string, params: RequestParams = {}) =>
      this.request<PublicRuleViewSuccess, RequestFailed>({
        path: `/view/rule/${slug}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetActivitiesBySlug
     * @summary Get activity history for a publicly available rule
     * @request GET:/view/rule-history/{slug}
     */
    getActivitiesBySlug: (
      slug: string,
      query?: { offset?: number; limit?: number; trades?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<PublicRuleHistorySuccess, RequestFailed>({
        path: `/view/rule-history/${slug}`,
        method: 'GET',
        query: query,
        ...params,
      }),
  };
  templates = {
    /**
     * @description Returns a page of templates
     *
     * @name GetTemplates
     * @request GET:/templates
     */
    getTemplates: (query?: { limit?: number; skip?: number }, params: RequestParams = {}) =>
      this.request<Templates, ErrorModel>({
        path: `/templates`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description Returns template details
     *
     * @name GetTemplateDetails
     * @request GET:/templates/{templateId}
     */
    getTemplateDetails: (templateId: string, params: RequestParams = {}) =>
      this.request<Templates, ErrorModel>({
        path: `/templates/${templateId}`,
        method: 'GET',
        ...params,
      }),
  };
}
