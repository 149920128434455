import React from 'react';
import './style.scss';
import NewsItem from './NewsItem';
import Spinner from 'components/Loading/Spinner';
import ScrollBar from 'components/ScrollBar';

const News = ({ newsItems, isLoading, elementRef }) => {
  const sortedNewsItems = newsItems.sort((a, b) => {
    if (new Date(a.pubDate) > new Date(b.pubDate)) return -1;

    if (new Date(a.pubDate) < new Date(b.pubDate)) return 1;

    return 0;
  });

  return (
    <ScrollBar>
      {sortedNewsItems.map((newsItem, index) => (
        <NewsItem
          newsItem={newsItem}
          key={newsItem?.title}
          {...(index + 1 === sortedNewsItems?.length && { elementRef })}
        />
      ))}

      {isLoading && <Spinner />}
    </ScrollBar>
  );
};

export default React.memo(News);
