import React, { useState, useEffect, useRef } from 'react';
import "./styles.scss";
import Dropdown from '../../../../assets/images/datastudio/dropdown_icon.png'
import { decideIconClassName } from 'helpers/utils';
import '../../CustomSelect/style.scss';
import SelectDropdown from 'pages/DataStudioPage/CustomSelect/SelectDropdown';

const ExchangePairDropdown = ({ 
  updatePairNames, 
  exchanges,
  to,
  from,
  exchangeName
}) => {
  const [selected, setSelected] = useState(null)
  const [isSelecting, setIsSelecting] = useState(false)

  const ref = useRef(null)

  useEffect(() => {
    const toggle = (event) => {
      event.stopPropagation()
      const {target} = event || {};
      const {current} = ref || {}

      const isValidTarget = typeof(target?.className) === 'string' && target?.className?.includes;

      if(!isValidTarget) return;

      if(!isSelecting) {
        if(
            (target === current) ||
            target?.className?.includes('ExchangePairDropdown') || 
            target?.className?.includes('SelectDropdown__control')
          ) {
          setIsSelecting(true)

          setTimeout(() => {
            const input = document.querySelector('.SelectDropdown__input')
            input.focus();
          }, 200)
        }
      } else {
        if(!target?.className?.includes('ExchangePairDropdown') && !target?.className?.includes('SelectDropdown')) {
          setIsSelecting(false)
        }
      }
    }

    document.addEventListener('click', toggle)

  }, [isSelecting])

  const handleChange = (value) => {
    const { from, to, exchangeName, exchangeId } = value || {}

    updatePairNames({from, to, exchangeName, exchangeId})

    setSelected(value)
    setIsSelecting(false);
  };

  return (
    <>
      <div 
        className={`ExchangePairDropdown__selected-pair ${!isSelecting ? 'open' : ''}`} 
        ref={ref}
      >
        <p className='ExchangePairDropdown__selected-pair-value'>
          {`${from}${to}`.toLocaleUpperCase()}
        </p>
        <div 
          className={decideIconClassName('ExchangePairDropdown', exchangeName)}
        />
        <img 
          role='button'
          src={Dropdown} 
          alt='Dropdown' 
          className='ExchangePairDropdown__dropdown' 
        />
      </div>

      <SelectDropdown
        className={isSelecting ? 'open' : ''} 
        onChange={handleChange}
        options={exchanges} 
        value={selected}
        isSelecting={isSelecting}
      />
    </>
  )
}

export default ExchangePairDropdown