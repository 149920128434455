import React from 'react';

export default function ChangePaymentMethod({ currentPlan, changePaymentFunction }) {

  const handPointer = (e) => {
    e.target.style.cursor = 'pointer';
  }

  let paymentMethod;

  if (currentPlan) {
    paymentMethod = <span
      onClick={changePaymentFunction()}
      onMouseOver={handPointer}
    >Change Payment Method</span>
  } else {
    paymentMethod = <span></span>;
  }

  return (
    <div>{paymentMethod}</div>
  );
}