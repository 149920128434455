import React from 'react';
import MediaQuery from 'react-responsive';
import Joyride from 'react-joyride';
import { observer } from 'mobx-react';
import './style.scss';
import { Link } from 'react-router-dom';
import stores from '../../../stores';
import { tourWasActivated } from '../../../helpers/tour';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import SmartGuide from './SmartGuide';
import { getDataLocalStorage } from 'helpers/utils';
import Welcome3StepsTour, { FIRST_STEP, SECOND_STEP, THIRD_STEP } from './Welcome3StepsTour';
import { toJS } from 'mobx';
import history from 'helpers/history';

@observer
class RuleTour extends React.Component {
  state = {
    addRuleTour: true,
    windowWidth: 0,
  };

  getSettings() {
    let steps = this.getDesktopSettings();

    const { welcomeTourSlideToSkip } = stores.tourGuide;

    if (typeof welcomeTourSlideToSkip === 'number') {
      steps.splice(welcomeTourSlideToSkip, 1);
    }

    return (
      <Joyride
        key={this.state.windowWidth}
        disableCloseOnEsc={false}
        disableOverlayClose={false}
        steps={steps}
        run={true}
        continuous={true}
        callback={this.removeFlag}
        locale={{ last: 'Finish' }}
      />
    );
  }

  welcomeTourProps() {
    return {
      buttonNext: {
        backgroundColor: 'white',
        color: '#1C2237',
        fontSize: '1.1rem',
        marginBottom: '2rem',
      },
      options: {
        width: 1150,
      },
      tooltipFooter: {
        display: 'block',
        textAlign: 'center',
      },
    };
  }

  getDesktopSettings() {
    const { hasRule, hasLiveRule } = stores.user;
    const { exchanges } = stores.userInfo;

    let completedSteps = [];

    if (hasRule) completedSteps.push(FIRST_STEP);
    if (exchanges?.length > 1) completedSteps.push(SECOND_STEP);
    if (hasLiveRule) completedSteps.push(THIRD_STEP);

    const currentStep = completedSteps?.length || 1;

    if (currentStep === 3) return null;

    let buttonProps = {
      children: <span>Add Exchange</span>,
      component: 'button',
      onClick: () => {
        // close welcome tour
        stores.user.removeTour();
        history.push('/exchanges');
      },
      ariaLabel: 'Add Exchange',
    };

    if (currentStep === 2 || currentStep === 3)
      buttonProps = {
        children: <span>Trade Live</span>,
        component: 'button',
        onClick: () => {
          // Show smartGuide
          stores.tourGuide.setForceShow(true);

          // close welcome tour
          stores.user.removeTour();

          history.push('/rule');
        },
      };

    if (currentStep === 1 && !completedSteps.includes(FIRST_STEP))
      buttonProps = {
        children: <span>Create Rule</span>,
        component: 'button',
        onClick: () => {
          stores.tourGuide.setForceShow(true);

          // close welcome tour
          stores.user.removeTour();

          history.push('/rule');
        },
      };

    return [
      {
        target: 'body',
        disableBeacon: true,
        isFixed: true,
        content: (
          <Welcome3StepsTour
            completedSteps={completedSteps}
            currentStep={currentStep}
            buttonProps={buttonProps}
          />
        ),
        placement: 'center',
        floaterProps: 'middle',
        locale: {
          next: (
            <>
              or <span style={{ textDecoration: 'underline' }}>Continue Tour</span>
            </>
          ),
        },
        styles: this.welcomeTourProps(),
        hideBackButton: false,
      },
      {
        target: '.rulesAccountBlock__butNewRuleLink',
        disableBeacon: true,
        isFixed: true,
        content: (
          <div className='tour-content-area'>
            <h2>Here You Can Create Strategies</h2>
            <div className='tour-content'>
              Use the simple rule editor to build your first strategy or choose one of the many
              ready-made templates.
            </div>
          </div>
        ),
        placement: 'bottom-end',
        locale: { next: 'NEXT' },
        hideBackButton: true,
        styles: this.welcomeTourStyles(),
      },
      {
        target:
          this.state.windowWidth === '<=991'
            ? '.rulesAccountBlock__butLinkExchange'
            : '.gsMainMenu .gsMainMenu__list .gsMainMenu__listElement_exchanges',
        disableBeacon: true,
        isFixed: true,
        content: (
          <div className='tour-content-area'>
            <h2>To Trade Live, Connect An Exchange</h2>
            <div className='tour-content'>
              Connecting your favorite exchanges is easy and allows you to trade live on many
              markets.
            </div>
          </div>
        ),
        placement: 'bottom-end',
        locale: { next: 'NEXT' },
        hideBackButton: true,
        styles: this.welcomeTourStyles(),
      },
      {
        target: '.rules-rows .tradingRulesList',
        isFixed: true,
        disableBeacon: true,
        content: (
          <div className='tour-content-area'>
            <h2>Let’s Start</h2>
            <div className='tour-content'>
              The Dashboard lets you see the overall portfolio and how each rule is performing on
              the market. At the top you can access a unified view of your wallets.
            </div>
          </div>
        ),
        placement: 'bottom-end',
        locale: { next: 'NEXT' },
        hideBackButton: true,
        styles: this.welcomeTourStyles(),
      },
    ];
  }

  welcomeTourStyles() {
    return {
      overlay: { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
      buttonNext: {
        fontWeight: '700',
        fontFamily: '"cr_roboto", sans-serif',
        color: '#4a4a4a',
        background: '#ffffff',
        marginTop: '-20px',
      },
    };
  }

  welcomeTour() {
    return <MediaQuery maxWidth={767}>{(matches) => this.getSettings(matches)}</MediaQuery>;
  }

  volume100ReachedTour() {
    const steps = [
      //Exchange select
      {
        target: '.sidebar-bottom__getPlanButtonLink',
        spotlightClicks: true,
        disableBeacon: true,
        isFixed: true,
        content: (
          <div className='text-left'>
            <h2 className='title-add-rule'>Max Trading Volume Reached</h2>
            <p>
              <Link to='/settings/billing'>Upgrade</Link> to unlock more trading volume and keep
              running your rules
            </p>
          </div>
        ),
        placement: 'top-end',
        locale: { last: 'Close' },
        hideBackButton: true,
        styles: this.ruleAddTourStyle(),
      },
    ];

    return (
      <div className='add-rule-tour'>
        <Joyride
          // key={stores.tourGuide.isRuleTourShowed}
          key={true}
          disableCloseOnEsc={false}
          disableOverlayClose={false}
          steps={steps}
          run={stores.tourGuide.isVolume100reachedTourShowed}
          continuous={false}
          callback={this.removeFlag}
          styles={this.ruleAddTourStyle()}
        />
      </div>
    );
  }

  addRuleTour() {
    return (
      <SmartGuide
        store={this.props.store}
        removeFlag={this.removeFlag}
        run={stores.tourGuide.isRuleTourShowed}
        show={!getDataLocalStorage('hasSeenRuleDetailTooltip') || stores.tourGuide.forceShow}
        setConnectExchangeClicked={this.props.setConnectExchangeClicked}
      />
    );
  }

  ruleAddTourStyle() {
    return {
      buttonNext: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        fontWeight: '500',
        border: 'solid 1px transparent',
        borderRadius: '50px',
        marginBottom: '0px',
        padding: '10px 18px',
        color: '#fff',
        minWidth: '100px',
        justifyContent: 'center',
      },
    };
  }

  removeFlag = (data) => {
    const { status, action, index, type } = data;

    if (status === 'ready') {
      stores.tourGuide.setForceShow(false);
    }

    if (action === 'close' && index === 4) {
      /* This function is needed to inform 'exchange' tooltips (which is located in main menu)
          that it's time to be shown */
      stores.user.changeOnboarding3stepsGuideStatus();
    }

    if (status === 'ready') {
      stores.user.removeTour();
      /* This function is needed to inform 'exchange' tooltips (which is located in main menu)
          that it's time to be shown */
      stores.user.changeOnboarding3stepsGuideStatus();
    } else if (action === 'close' || (status === 'finished' && type === 'tour:end')) {
      stores.tourGuide.toggleRuleTour('close');
      stores.tourGuide.toggleIsWelcomeTourShowed('close');
    }
  };

  chooseTour(type, targetTour) {
    if (targetTour === type) {
      tourWasActivated();

      switch (type) {
        case 'welcome':
          return this.welcomeTour();
        case 'addRule':
          return this.addRuleTour();
        case 'volume100ReachedTour':
          return this.volume100ReachedTour();
        default:
          return null;
      }
    }

    return null;
  }

  setWindowWidth = () => {
    let windowWidth = 0;

    if (window.innerWidth <= 991) windowWidth = '<=991';
    else windowWidth = '>991';

    if (this.state.windowWidth !== windowWidth) {
      this.setState(() => {
        return { windowWidth };
      });
    }
  };

  componentDidMount() {
    this.setWindowWidth();
    window.addEventListener('resize', () => {
      this.setWindowWidth();
    });
  }

  render() {
    const { typeTour, targetTour } = this.props;

    if (this.props.isRuleNotNew && !this.props.isShowed) return null;

    // This is a tiny little trick to trigger re-rendering on update to exchanges
    // Do not remove. If you must, then make sure exchanges is updated and component is re-rendered
    console.log(toJS(stores.userInfo.exchanges));

    return (
      <ErrorBoundary name={'boundary_RulesDashboardPage_RuleTour'}>
        {this.chooseTour(typeTour, targetTour)}
      </ErrorBoundary>
    );
  }
}

export default RuleTour;
