import React from 'react';
import Popover from '../../../../../components/Popover';
import { Select, Tooltip } from 'antd';

const Option = Select.Option;

export interface OrderTypeDropdownItem {
  orderType: string;
  // defaults to false
  disabled: boolean;
}

export interface OrderTypeDropdownProps {
  orderTypes: OrderTypeDropdownItem[];
  defaultValue?: string;
  disabledText: string;
  handleOrderTypeChange: (orderType: string) => void;
  className?: string;
}

const OrderTypeDropdown: React.FC<OrderTypeDropdownProps> = ({
  orderTypes,
  disabledText,
  defaultValue,
  handleOrderTypeChange,
  className = '',
}) => {
  const dropdownValue = defaultValue || '';

  const renderTooltip = (disabled: boolean, orderType: string) => {
    if (disabled && !!disabledText) {
      return (
        <Tooltip title={disabledText} placement='right'>
          {orderType} order
        </Tooltip>
      );
    }
  };

  return (
    <Popover text={orderTypes.length <= 1 ? 'Order type' : 'Select Order Type'}>
      <Select
        size={'default'}
        showSearch
        onChange={(value: string) => {
          handleOrderTypeChange(value)
        }}
        value={dropdownValue}
        className={className}>
        {orderTypes.map(({ disabled, orderType }) => {
          return (
            <Option
              value={orderType}
              key={orderType}
              style={
                disabled
                  ? {
                      color: 'rgba(0, 0, 0, 0.25)',
                      cursor: 'not-allowed',
                    }
                  : {}
              }
              disabled={disabled}
            >
              {!disabled && `${orderType} order`}
              {renderTooltip(disabled, orderType)}
            </Option>
          );
        })}
      </Select>
    </Popover>
  );
};

export default OrderTypeDropdown;