import React from 'react';
import { ReactComponent as BackIcon } from 'assets/images/icons/ArrowLeft.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/cancelX.svg';
import './style.scss';
import { Button } from 'antd';
export interface IProps {
  onClose: () => void;
  goBack: () => void;
}
const HeaderNavigation: React.FC<IProps> = ({ onClose, goBack, children }) => {
  const cls = `HeaderNavigation`;
  return (
    <div className={cls}>
      {' '}
      <Button className={`${cls}__icon-button`} onClick={goBack}>
        {' '}
        <BackIcon className={`${cls}__icon ${cls}__icon--back`} />{' '}
      </Button>{' '}
      {children}{' '}
      <Button className={`${cls}__icon-button`} onClick={onClose}>
        {' '}
        <CloseIcon className={`${cls}__icon`} />{' '}
      </Button>{' '}
    </div>
  );
};
export default HeaderNavigation;
