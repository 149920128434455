import React from 'react'
import clsx from 'clsx'

import type { TypedUseSelectorHook } from 'react-redux'

import type { TDomainUseCasesAccessSharedRule, TDomainStateAccessSharedRule } from "v2/domains/accessSharedRule";

import type { TypographyProps} from '@mui/material';
import { useTheme } from '@mui/material'

import type { ChartRuleStatsLegendFormatNumber, RuleActivityLogTableProps} from 'design-system';
import { Center, Loader, Typography, RuleDescription, RuleActivityLogFooter, Checkbox, RuleDetailSequencesRestricted, RuleStatusBar, RulePerformanceChart, RuleDetailTitle, IconButton, IconChevronLeft, LayoutRuleDetails, RuleActivityLogTable } from 'design-system'

import { EAsyncStatus } from 'v2/types';

const TypographyHeader = ({sx, ...otherProps}: TypographyProps) => {
  const theme = useTheme();

  return (
    <Typography
      sx={{
        fontSize: '22px',
        lineHeight: '33px',
        fontWeight: 600,
        color: theme.palette.coinrule.basic.greyDark,
        [theme.breakpoints.up('sm')]: {
          fontSize: '28px',
        },
        ...sx,
      }}
      {...otherProps}
    />
  )
}

export interface ViewAccessSharedRuleProps extends React.HTMLProps<HTMLDivElement> {
  formatNumber: ChartRuleStatsLegendFormatNumber
  formatCoinValue: RuleActivityLogTableProps['formatCoinValue']
  ruleSlug: string
  BackButtonWrapper?: React.FC
}

const testId = 'ViewAccessSharedRule'
export const createViewAccessSharedRule = ({
  useCasesAccessSharedRule,
  useSelector,
}: {
  useCasesAccessSharedRule: TDomainUseCasesAccessSharedRule,
  useSelector: TypedUseSelectorHook<TDomainStateAccessSharedRule>
}) => {
  const ViewAccessSharedRule = (props: ViewAccessSharedRuleProps) => {
    const {
      className,
      formatNumber,
      formatCoinValue,
      ruleSlug,
      BackButtonWrapper,
      ...otherProps
    } = props

    const offset = useSelector((state) => state.offset)
    const showOnlyTrades = useSelector((state) => state.showOnlyTrades)
    const sharedRuleData = useSelector((state) => state.data)
    const requestStatus = useSelector((state) => state.status)
    const requestError = useSelector((state) => state.error)

    const onShowMoreClick = () => {
      useCasesAccessSharedRule.setOffset({ offset: offset + 10 })
      useCasesAccessSharedRule.getSharedRule({ ruleSlug })
    }

    const toggleShowOnlyTrade = () => {
      useCasesAccessSharedRule.toggleShowOnlyTrades()
      useCasesAccessSharedRule.setOffset({ offset: 0 })
      useCasesAccessSharedRule.getSharedRule({ ruleSlug })
    }

    React.useEffect(() => {
      useCasesAccessSharedRule.setOffset({ offset: 0 })
      useCasesAccessSharedRule.getSharedRule({ ruleSlug })
    }, [ruleSlug])

    const renderSharedRuleWithData = () => {
      if(!sharedRuleData) return null;

      const {
        startDate,
        exchange,
        exchangeName,
        displayedStatus,
        trigger,
        profitOrLossPercent,
        numberOfExecutions,
        executionLimit,
        timezoneString,
        baseCurrency,
        chartDataPoints,
        iconVariant,
        statusText,
        ruleActivityLogs,
        priceTrend,
        ruleName,
      } = sharedRuleData;

      const {total, activities} = ruleActivityLogs;

      return (
        <LayoutRuleDetails 
          contentBackButton={
            BackButtonWrapper ? 
              <BackButtonWrapper>
                <IconButton>
                  <IconChevronLeft />
                </IconButton>
              </BackButtonWrapper>
              :
              null
          }
          headerTitle={<RuleDetailTitle>{ruleName}</RuleDetailTitle>}
          headerPerformance={<TypographyHeader>Performance</TypographyHeader>}
          contentStatus={(
            <RuleStatusBar
              startDate={startDate}
              status={displayedStatus}
              trigger={trigger}
              exchange={exchange}
              exchangeName={exchangeName}
              executionTimesCounter={numberOfExecutions}
              timezoneString={timezoneString}
            />
          )}
          contentChart={(
            <RulePerformanceChart 
              profit={undefined}
              growth={String(profitOrLossPercent)}
              priceTrend={priceTrend}
              data={chartDataPoints}
              baseCurrency={baseCurrency}
              formatNumber={formatNumber}
            />
          )}
          contentDescription={
            <RuleDescription
              iconVariant={iconVariant}
              statusText={statusText}
              startDate={startDate}
              exchange={exchange}
              exchangeName={exchangeName}
              executionTimesCounter={numberOfExecutions}
              executionTimesLimit={executionLimit}
            />
          }
          contentSequences={<RuleDetailSequencesRestricted />}

          actionHistory={
            <Checkbox
              label={"Show only trades"}
              checked={showOnlyTrades}
              onChange={toggleShowOnlyTrade}
              disabled={requestStatus === EAsyncStatus.PENDING}
            />
          }
          headerHistory={<TypographyHeader>History</TypographyHeader>}
          contentHistory={
            (requestStatus === EAsyncStatus.PENDING && offset === 0) ?
              <Center padding={'20px'}>
                <Loader />
              </Center>
              :
              <RuleActivityLogTable
                formatCoinValue={formatCoinValue}
                data={activities}
              />
          }
          footerHistory={
            <RuleActivityLogFooter
              rangeStart={1}
              rangeEnd={activities?.length}
              total={total}
              disableButton={requestStatus === EAsyncStatus.PENDING}
              onShowMoreClick={onShowMoreClick}
            />
          }
        />
      )

    }

    return (
      <div
        data-testid={testId}
        className={clsx(testId, className)}
        style={{
          height: requestStatus === EAsyncStatus.REJECTED || requestStatus === EAsyncStatus.PENDING ? '100%' : 'auto'
        }}
        {...otherProps}
      >
        {
          requestStatus === EAsyncStatus.REJECTED ?
            <Center padding={'20px'}>
              <Typography
                variant={'h6'}
                align={'center'}
              >
                {requestError?.message}!
              </Typography>
            </Center>
            :
            (requestStatus === EAsyncStatus.PENDING && !sharedRuleData) ? 
              <Center padding={'20px'}>
                <Loader />
              </Center>
              :
              sharedRuleData ? 
                renderSharedRuleWithData() 
                : 
                null
        }
      </div>
    )
  }
  
  return ViewAccessSharedRule
}

export default createViewAccessSharedRule
