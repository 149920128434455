export class AbstractStore {
  _initialStoreState = new Map();

  isVariable(key) {
    return key !== '_initialStoreState' && key !== 'root' && typeof this[key] !== 'function';
  }

  valueBasedOnType(original) {
    if (Array.isArray(original)) return [...original];
    if (typeof original === 'object') return { ...original };
    return original;
  }

  storeInitialState() {
    for (let key in this) {
      if (this.isVariable(key)) {
        this._initialStoreState.set(key, this.valueBasedOnType(this[key]))
      }
    }
  }

  resetStore() {
    for (let key in this) {
      if (this.isVariable(key)) {
        const originalValue = this._initialStoreState.get(key);
        this[key] = this.valueBasedOnType(originalValue);
      }
    }
  }
}