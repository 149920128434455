import React from 'react';

import {
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  LinkedinShareButton,
  TwitterShareButton,
WhatsappShareButton,
EmailShareButton,
} from 'react-share';

const NewsShareIcons = ({ link }) => {
  const shareTitle = `Check out this article I found via @coinrule`
  const source = 'https://coinrule.com'
  
  const sharedProps = {
    url: link,
    className: 'news__share-button',
    title: shareTitle
  }

  return (
    <div className='news__share-container'>

      <p className="news__share-text">Share on: </p>

      <LinkedinShareButton 
        {...sharedProps} 
        summary={shareTitle} 
        source={source}
      >
        <LinkedinIcon round size={35} className='news__share-icon' />
      </LinkedinShareButton>

      <TwitterShareButton 
        {...sharedProps} 
        via={'CoinRuleHQ'} 
        hashtags={['coinrule', 'CoinRuleHQ', 'cryptocurrency', 'trading']} 
        related={['CoinRuleHQ']}
      >
        <TwitterIcon round size={35} className='news__share-icon' />
      </TwitterShareButton>
      
      <WhatsappShareButton {...sharedProps}>
        <WhatsappIcon round size={35} className='news__share-icon' />
      </WhatsappShareButton>

      <EmailShareButton subject={shareTitle} {...sharedProps}>
        <EmailIcon
          round size={35} 
          bgStyle={{
            fill: '#1C2237'
          }} 
          className='news__share-icon news__share-icon--transparent' 
        />
      </EmailShareButton>
      
      {/* <WebsiteIcon className='news__share-icon' /> */}
    </div>
  )
}

export default NewsShareIcons;