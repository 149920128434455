import type { TReducer } from 'v2/types/TReducer'

import type { TDomainActionConnectExchangeBinanceOAuthGetAccessToken } from './actionsFetchGetAccessToken';
import type { DTOFetchConnectExchangeBinanceOAuthGetAccessToken } from './DTOs/DTOFetchConnectExchangeBinanceOAuthGetAccessToken';
import type { DTOErrorFetchConnectExchangeBinanceOAuthGetAccessToken } from './DTOs/DTOErrorFetchConnectExchangeBinanceOAuthGetAccessToken';

import { EAsyncStatus } from 'v2/types/EAsyncStatus';
import { EDomainActionsConnectExchangeBinanceOAuthGetAccessToken } from './actionsFetchGetAccessToken'

export type TDomainStateConnectExchangeBinanceOAuthGetAccessToken = {
  data: DTOFetchConnectExchangeBinanceOAuthGetAccessToken | null,
  error: DTOErrorFetchConnectExchangeBinanceOAuthGetAccessToken | null
  status: EAsyncStatus
}

export const initialStateConnectExchangeBinanceOAuthGetAccessToken: TDomainStateConnectExchangeBinanceOAuthGetAccessToken = {
  data: null,
  error: null,
  status: EAsyncStatus.IDLE
}

export const reducerConnectExchangeBinanceOAuthGetAccessToken: TReducer<TDomainStateConnectExchangeBinanceOAuthGetAccessToken, TDomainActionConnectExchangeBinanceOAuthGetAccessToken> = (state = initialStateConnectExchangeBinanceOAuthGetAccessToken, action): TDomainStateConnectExchangeBinanceOAuthGetAccessToken => {
  switch (action.type) {
    case EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_STARTED: {
      return {
        data: null,
        error: null,
        status: EAsyncStatus.PENDING
      }
    }
    case EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_SUCCEEDED: {
      return {
        data: action.payload.dto,
        error: null,
        status: EAsyncStatus.RESOLVED
      }
    }
    case EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_FAILED: {
      return {
        data: null,
        error: action.payload.error,
        status: EAsyncStatus.REJECTED
      }
    }
    default:
      return state
  }
}