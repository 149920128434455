import React from 'react';

import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';

import './style.scss';

export interface ITutorialVideoProps extends ModalProps {
  videoUrl: string;
}

const TutorialVideo: React.FC<ITutorialVideoProps> = ({ videoUrl, ...otherProps }) => {
  return (
    <Modal
      className='exchange-tutorial-video-modal'
      centered
      footer={null}
      width={window.innerWidth <= 1024 ? '100%' : '50%'}
      {...otherProps}>
      <div className='video-container' data-testid='video-container'>
        <iframe
          width='100%'
          height='480'
          src={videoUrl}
          data-testid='video-iframe'
          frameBorder='0'
          title='Video'
          allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen>
          {' '}
        </iframe>
      </div>
    </Modal>
  );
};

export default TutorialVideo;
