import React from 'react'
import { observable, action, toJS } from 'mobx';

import stores from './index';
import http from '../helpers/http';
import dbg from '../helpers/dbg';
import { errorNotification, successNotification, infoNotification } from '../helpers/notification';
import { IS_PROD } from 'constants/isProd';
import { AbstractStore } from './AbstractStore';

//const analytics = window.mixpanel;

export default class ExchangeProfileStore extends AbstractStore {
  constructor(rootStore) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  @observable selectedExchange;
  @observable selectedKeys = {};

  @observable isAddWorking = false;
  @observable isDeleteWorking = false;

  @observable activeTutorialPoint;

  @observable showAddExchangeModal = false;
  @observable shouldLaunchLiveOnAddExchange = false;

  @observable addExchangesModalTitle = (
    <>
      <span>One Last Step Before Launching The Rule</span>
      <br/>
      <span className='AddExchangesModal__title--bold'>Connect An Exchange</span>
    </>
 )


 @action
 updateExchangesModalTitle = (title = '') => this.addExchangesModalTitle = title

  // This stores a global instance of selectedExchangeUID
  // that doesn't reset when AddApiExchange re-renders
  @observable selectedExchangeUID = '';

  @action
  setSelectedExchangeUID = (value) => (this.selectedExchangeUID = value);

  @action
  toggleShowAddExchangeModal = (isVisible, title='') => {
    this.showAddExchangeModal = isVisible;
    if(title) this.addExchangesModalTitle = title
  };

  @action
  toggleShouldLaunchLiveOnAddExchange = (value) => {
    this.shouldLaunchLiveOnAddExchange = value;
  };

  handleShouldLaunchLiveOnAddExchange(ruleStore) {
    /***
     *  User cannot add exchange when creating rule if user is editing the rule
     *  or have more than 1 exchange ie demo and another exchange
     */
    if (ruleStore.rule.rule_id || toJS(stores.userInfo.exchanges)?.length > 1) {
      this.toggleShouldLaunchLiveOnAddExchange(false);
    } else {
      this.toggleShouldLaunchLiveOnAddExchange(true);
    }
  }

  @action
  setActiveTutorialPoint(point) {
    this.activeTutorialPoint = point;
  }

  @action
  setSelectedExchange(exchange) {
    this.selectedExchange = exchange;
    if (window.innerWidth > 767) {
      stores.info.setShowExchangeTutorial(true);
    }
  }

  @action
  setSelectedKey(key, value) {
    this.selectedKeys[key] = value;
  }

  @action
  async addExchange(data, callback) {
    if (!this.isAddWorking) {
      this.isAddWorking = true;
      let isAuthorized, userError;

      try {
        const resp = await http.post('/user/exchange', data);

        if (resp.data) {
          isAuthorized = resp.data.authorized;

          if (resp.data.status === 'OK') {
            successNotification(
              `Amazing! Your API keys has been added. ${this.shouldLaunchLiveOnAddExchange ? '' : `Let's create a rule`
              } ;)`
            );
            this.selectedExchange = undefined;
            this.selectedKeys = {};

            callback && callback();

            const authorized = await stores.userInfo.getExchanges(true)
            stores.app.unauthorized(authorized)

            await stores.walletsStores.handleSyncWallets();

            if (IS_PROD) {
              try {
                /* eslint-disable no-unused-expressions */
                window.rga?.event({
                  category: 'exchange',
                  action: 'Exchange Connected',
                  label: data.exchange_uid,
                });

                /* eslint-disable no-unused-expressions */
                window.fbq?.('trackCustom', 'UserConnectExchange', {
                  // Facebook conversion tracking
                  exchange: data.exchange_uid,
                });
                /**
               * Note: DO NOT DELETE. Code will be revisited in near future.
               * 
               * 
                window.twttr?.conversion.trackPid('o4v90'); // Twitter conversion tracking
                /* eslint-enable no-unused-expressions

                http.get('https://px.ads.linkedin.com/collect/?pid=2525658&conversionId=3088066&fmt=gif')
                  .catch((err) => console.error('Error while performing tracking: ', err)); // LinkedIn conversion tracking
                */
              } catch (err) {
                console.error('Error while performing tracking: ', err);
              }
            }
          } else {
            userError = resp.data.message;
            throw new Error(userError);
          }
        } else {
          dbg('Unknown response', resp);
          throw new Error('Unknown response');
        }
      } catch (err) {
        this.isAddWorking = false;

        const errorMessage = userError || `Something has gone wrong. Please try it again later.`;
        
        dbg('Error', err);

        errorNotification(errorMessage);
        throw new Error(errorMessage);
      }

      this.isAddWorking = false;

      return isAuthorized;
    }
  }

  @action
  async removeExchange(data) {
    if (!this.isDeleteWorking) {
      this.isDeleteWorking = true;
      let isAuthorized, userError;

      try {
        const resp = await http.delete('/user/exchange', { data });

        if (resp.data) {
          isAuthorized = resp.data.authorized;

          if (resp.data.status === 'OK') {
            infoNotification(`The key has been removed.`);
            stores.userInfo
              .getExchanges(true)
              .then((authorized) => stores.app.unauthorized(authorized));
          } else {
            userError = resp.data.message;
            throw new Error(userError);
          }
        } else {
          dbg('Unknown response', resp);
          throw new Error('Unknown response');
        }
      } catch (err) {
        dbg('Error', err);
        errorNotification(userError || `Something has gone wrong. Please try it again later.`);
      }

      this.isDeleteWorking = false;

      return isAuthorized;
    }
  }
}
