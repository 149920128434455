import { observable, action, toJS } from 'mobx';

import http from '../helpers/http';
import { errorNotification } from '../helpers/notification';
import stores from './index';
import { STATUSES } from '../helpers/utils';
import { AbstractStore } from './AbstractStore';

export default class UserRulesStore extends AbstractStore {
  lastOpenRuleStatus = null;

  constructor(rootStore) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  updateRulesOnDashboard(ruleId, useItself, newParams) {
    const newRules = [];
    const rulesSync = [];
    const loadingRules = stores.userInfo.loadingRules;
    let newRule;

    for (const i in stores.userInfo.rules) {
      if (stores.userInfo.rules[i].id === ruleId) {
        if (useItself) {
          newRule = {
            ...stores.userInfo.rules[i],
            ...newParams,
          };

          newRules.push(newRule);
          loadingRules[ruleId] = false;

          if (newRule.ia) {
            rulesSync.push(ruleId);
          }
        }
      } else {
        newRules.push(stores.userInfo.rules[i]);

        if (stores.userInfo.rules[i].ia) {
          rulesSync.push(stores.userInfo.rules[i].id);
        }
      }
    }

    stores.userInfo.loadingRules = loadingRules;
    stores.userInfo.rules = newRules;
    stores.userInfo.rulesSync = rulesSync;

    stores.userInfo.numberOfAutocreatedRules = newRules.filter((rule) => rule.au).length;
    stores.userInfo.totalActiveRules =
      newRules.filter((rule) => rule.s === STATUSES.ACTIVE).length -
      stores.userInfo.numberOfAutocreatedRules;
  }

  @observable isRemovingRule = false;
  @observable removedRules = [];

  @action
  async deleteRule(ruleId) {
    if (this.isRemovingRule || !ruleId) {
      return;
    }

    try {
      this.isRemovingRule = true;
      this.removedRules.push(ruleId);

      const resp = await http.delete('/rule', { data: { rule_id: ruleId } });

      this.isRemovingRule = false;

      if (resp.data) {
        if (resp.data.status === 'OK') {
          stores.userInfo.getRules(true);
          this.updateRulesOnDashboard(ruleId, false);
        } else {
          errorNotification(resp.data.message);
        }

        return resp.data.authorized;
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isRemovingRule = false;
    }
  }

  @observable isTogglingRule = false;

  @action
  async toggleRule(ruleId, fromDetail = false) {
    if (this.isTogglingRule) {
      return;
    }

    try {
      this.isTogglingRule = true;

      const resp = await http.put('/rule/status', { rule_id: ruleId });

      this.isTogglingRule = false;

      if (resp.data) {
        if (resp.data.status === 'OK') {
          this.updateRulesOnDashboard(ruleId, true, {
            s: resp.data.rule_status,
            ia: resp.data.rule_status === STATUSES.ACTIVE,
          });

          if (!fromDetail) {
            await this.getActivities(ruleId);
          }
          stores.userInfo.getRules(true);
        } else {
          errorNotification(resp.data.message);
          stores.userInfo.loadingRules[ruleId] = false;
        }

        return resp.data.authorized;
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isTogglingRule = false;
    }
  }

  @observable rulesProfit = 0;

  @action
  getRulesProfit = () => {
    const { activeLiveRules } = stores.userInfo;

    if (!activeLiveRules?.length) return;

    this.rulesProfit = activeLiveRules.reduce(
      (acc, { performance }) => acc + (performance?.profit || 0),
      0
    );
  };


  @action
  resetRulesProfit = async () => {
    this.rulesProfit = 0;

    await stores.userInfo.getActiveLiveRules();
    this.getRulesProfit();
  };

  @observable performanceProfit = 0;
  @observable performanceGrowth = 0;
  @observable performanceSince = '';
  @observable isLoadingPerformance = false;

  @action
  async getProfit() {
    if (this.isLoadingPerformance) {
      return;
    }

    try {
      this.isLoadingPerformance = true;

      const resp = await http.get(`/rules/stats`);

      this.isLoadingPerformance = false;

      if (resp.data) {
        if (resp.data.status === 'OK') {
          const gains = resp.data.data;
          const newRules = [];
          let gain, newRule;

          for (const i in stores.userInfo.rules) {
            gain = gains[stores.userInfo.rules[i].id];
            newRule = {
              ...stores.userInfo.rules[i],
              performance: gain || stores.userInfo.rules[i].performance,
            };

            newRules.push(newRule);
          }

          const growth = (this.rulesProfit / stores.walletsStores.totalWalletsValue) * 100;

          this.performanceProfit = resp.data.profit;
          this.performanceGrowth = Math.abs(growth) === Infinity ? 0 : growth;
          this.performanceSince = resp.data.since;

          stores.userInfo.rules = newRules;
        } else {
          errorNotification(resp.data.message);
        }

        return resp.data.authorized;
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoadingGains = false;
    }
  }

  @observable activities = {};
  @observable isLoadingActivities = false;

  @action
  async getActivities(ruleId) {
    if (this.isLoadingActivities) {
      return;
    }

    try {
      this.isLoadingActivities = true;
      let query = '';

      if (ruleId) {
        query = '?rule_id=' + ruleId;
      }

      const resp = await http.get('/rules/activities' + query);

      this.isLoadingActivities = false;
      if (resp.data) {
        if (resp.data.status === 'OK') {
          if (ruleId) {
            const activitiesBackup = toJS(this.activities);
            activitiesBackup[ruleId] = resp.data.data[ruleId];
            this.activities = activitiesBackup;
          } else {
            this.activities = resp.data.data;
          }
        } else {
          errorNotification(resp.data.message);
        }
        return resp.data.authorized;
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoadingActivities = false;
    }
  }

  @observable ruleDetails = {};
  @observable isLoadingRuleDetails = false;
  @observable isLoadedRuleDetails = false;

  @action
  async getRuleDetails(ruleId, store) {
    if (this.isLoadingRuleDetails) {
      return;
    }

    try {
      this.ruleDetails = {};
      this.isLoadedRuleDetails = false;
      this.isLoadingRuleDetails = true;

      const resp = await http.get(`/rule?rule_id=${ruleId}`);

      this.isLoadingRuleDetails = false;

      if (resp.data) {
        if (resp.data.status === 'OK') {
          this.ruleDetails = {
            ...resp.data.data,
            rule_id: ruleId,
            owner: resp.data.owner,
          };

          this.lastOpenRuleStatus = resp.data.data.s;

          if (this.ruleDetails.pe === '1W') {
            this.ruleDetails.pe = 'week';
          } else if (this.ruleDetails.pe === '1D') {
            this.ruleDetails.pe = 'day';
          } else if (this.ruleDetails.pe === '1M') {
            this.ruleDetails.pe = 'month';
          } else if (this.ruleDetails.pe === '1h') {
            this.ruleDetails.pe = 'hour';
          }

          store.setEditedRule({ ...this.ruleDetails, details: null }, this.ruleDetails.details);

          if (this.ruleDetails.exchange_id) {
            stores.userInfo
              .getBalances(this.ruleDetails.exchange_id)
              .then((authorized) => stores.app.unauthorized(authorized));
            stores.exchangeInfo
              .getAssets(this.ruleDetails.exchange_id)
              .then((authorized) => stores.app.unauthorized(authorized));
            stores.info.updateLastSelectedExchange(this.ruleDetails.exchange_id);
          }
          this.isLoadedRuleDetails = true;
        } else {
          errorNotification(resp.data.message);
        }

        return resp.data.authorized;
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoadingRuleDetails = false;
    }
  }

  @action setPerformanceGrowth = (newValue) => (this.performanceGrowth = newValue);

  @action
  setProfit(newData) {
    for (const i in stores.userInfo.rules) {
      const ruleId = stores.userInfo.rules[i].id;

      if (ruleId === newData.ruleId) {
        stores.userInfo.rules[i].performance.chart.push({
          vd: newData.chart.vd,
        });

        if (!newData.isDemo) {
          this.addNetProfit(newData.profit - stores.userInfo.rules[i].performance.profit);
          this.addNetGrowth(newData.growth - stores.userInfo.rules[i].performance.growth);
        }

        stores.userInfo.rules[i].performance.profit = newData.profit;
        stores.userInfo.rules[i].performance.growth = newData.growth;
      }
    }
  }

  @action
  addNetProfit(newProfit) {
    this.performanceProfit += newProfit;
  }

  @action
  addNetGrowth(newGrowth) {
    this.performanceGrowth += newGrowth;
  }

  @action
  setNetProfit(newProfit) {
    this.performanceProfit = newProfit;
  }

  @action
  setNetGrowth(newGrowth) {
    this.performanceGrowth = newGrowth;
  }

  @action
  setSince(since) {
    this.performanceSince = since;
  }
}
