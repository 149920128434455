import Tabs from 'pages/DataStudioPage/Tabs';
import React from 'react';

const ExchangeChart = ({ exchangeName, from, to }) => (
  <Tabs>
    <div label='Simple'>
      <iframe
        id='tradingview_70ff2'
        title='iframe'
        src={`https://www.tradingview.com/mediumwidgetembed/?symbols=${exchangeName}%3A${from}${to}%7C12M&locale=en&trendLineColor=%231976d2&underLineColor=rgba(55%2C%20166%2C%20239%2C%200.15)&fontColor=%23787b86&gridLineColor=%232a2e39&width=100%25&height=100%25&colorTheme=dark&isTransparent=1&utm_source=www.tradingview.com&utm_medium=widget_new&utm_campaign=symbol-overview`}
        style={{ boxSizing: 'border-box', height: '300px', width: '100%', }}
        frameBorder='0'
        allowtransparency='true'
        scrolling='no'
      ></iframe>
    </div>
    <div label='Advanced'>
      <iframe
        id='tradingview_3ebab'
        title='iframe'
        src={`https://www.tradingview.com/widgetembed/?frameElementId=tradingview_3ebab&symbol=${exchangeName}%3A${from}${to}&interval=D&symboledit=1&saveimage=1&toolbarbg=f1f3f6&studies=%5B%5D&theme=dark&style=1&timezone=Etc%2FUTC&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en&utm_source=www.tradingview.com&utm_medium=widget_new&utm_campaign=chart&utm_term=BITSTAMP%3ABTCUSD`}
        style={{ width: '100%', height: '300px', margin: 0, padding: '0', }}
        frameBorder='0'
        allowtransparency='false'
        scrolling='no'
        allowFullScreen=''
      ></iframe>
    </div>
  </Tabs>
        
)

export default ExchangeChart;