import React from 'react'

export interface IconArrowDownOutlineProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconArrowDownOutline'
export const IconArrowDownOutline = (props: IconArrowDownOutlineProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      width={"1em"}
      height={"1em"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...otherProps}
    >
      <path
        d={"M6.5 7.8a.829.829 0 0 1-.333-.07.869.869 0 0 1-.283-.2L.255 1.573A.95.95 0 0 1 0 .92.95.95 0 0 1 .255.27.847.847 0 0 1 .871 0c.23 0 .452.097.615.27L6.5 5.575 11.514.27a.869.869 0 0 1 .282-.2.83.83 0 0 1 .949.2c.08.085.145.187.189.299a.967.967 0 0 1-.189 1.004L7.115 7.53a.869.869 0 0 1-.282.2.829.829 0 0 1-.333.07Z"}
        fill={"#555"}
      />
    </svg>
  )
}

export default IconArrowDownOutline
