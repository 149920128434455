import stores from './index';

export default class AppStore {
  constructor(rootStore) {
    this.root = rootStore;
  }

  app;

  setApp(app) {
    this.app = app;
  }

  unauthorized(isAuthorized) {
    if (isAuthorized === false) {
      stores.user.setAuth(isAuthorized);
      this.app.setState({
        isAuthorized,
      });
    }
  }
}
