import { observable, action } from 'mobx';
import { AbstractStore } from './AbstractStore';

export default class TopPanel extends AbstractStore {
  constructor(rootStore) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  @observable isWalletsDropdownShowed = false;
  @observable isGamifDropdownShowed = false;
  @observable isNotifDropdownShowed = false;
  @observable closeByButton = true;

  @action showDropdown = (whatToShow) => {
    if (whatToShow === 'wallets') {
      this.isGamifDropdownShowed = false;
      this.isNotifDropdownShowed = false;

      if (this.isWalletsDropdownShowed) {
        this.isWalletsDropdownShowed = false;
      } else {
        this.isWalletsDropdownShowed = true;
      }
    } else if (whatToShow === 'gamification') {
      this.isWalletsDropdownShowed = false;
      this.isNotifDropdownShowed = false;

      if (this.isGamifDropdownShowed) {
        this.isGamifDropdownShowed = false;
      } else {
        this.isGamifDropdownShowed = false;
      }
    } else if (whatToShow === 'notifications') {
      this.isWalletsDropdownShowed = false;
      this.isGamifDropdownShowed = false;

      if (this.isNotifDropdownShowed) {
        this.isNotifDropdownShowed = false;
      } else {
        this.isNotifDropdownShowed = true;
      }
    }
  };

  @action hideAllDropdowns = (event, whatToDo) => {
    if (
      whatToDo === 'hideAll' || event ? !event.currentTarget.contains(event.relatedTarget) : false
    ) {
      this.isWalletsDropdownShowed = false;
      this.isGamifDropdownShowed = false;
      this.isNotifDropdownShowed = false;
    }
  };

  @action setCloseByButton = (newValue) => (this.closeByButton = newValue);
}
