import { pluralise } from 'helpers/helpers';
import { capitalize } from 'helpers/utils';
import React from 'react';

export const COUPON_DURATION_TYPES = {
  LIMITED: "limited_period",
  UNLIMITED: "unlimited_period",
  ONE_TIME: "one_time"
}

export const PlanPromoInfo = ({ coupon }) => {
  const { duration_type, period, period_unit, discount_percentage } = coupon || {};

  const percentageText = discount_percentage ? `${discount_percentage}% off -` : '';

  const getPromoInfoText = () => {
    if(duration_type ===  COUPON_DURATION_TYPES.UNLIMITED) return `${percentageText} Lifetime Promo`;
    if(duration_type === COUPON_DURATION_TYPES.ONE_TIME) return `${percentageText} One Time Promo`;
    if(duration_type === COUPON_DURATION_TYPES.LIMITED && period && period_unit) return `${percentageText} ${period} ${capitalize(pluralise(period, period_unit))} Promo`;

    return ''
  }

  return <div className={`partnershipText`}>{getPromoInfoText()}</div>;
};

const CouponData = ({ coupon, symbol, monthlyDiscountPrice }) => {
  const { discount_percentage } = coupon || {};

  const isFree = discount_percentage >= 100;

  const getDiscountedHTMLContent = () => {
    return (
      <>
        <div className='left-side'>
          <div className='symbol'>{symbol || '$'}</div>

          <div className={`price`}>{monthlyDiscountPrice}</div>
        </div>

        <div className={`right-side`}>
          <div className={`price`}>.99</div>
          <div className={`period`}>/month</div>
        </div>
      </>
    );
  };

  const getFreeHTMLContent = () => {
    return (
      <div className='left-side'>
        <div className={`price free-price`}>Free Trial</div>
      </div>
    );
  };

  const renderMainContent = () => {
    if (isFree) return getFreeHTMLContent();

    return getDiscountedHTMLContent();
  };

  return (
    <React.Fragment>
      <div className='cost has-price' data-testid='coupon-data'>
        {renderMainContent()}
      </div>

      <PlanPromoInfo coupon={coupon} />
    </React.Fragment>
  );
};

export default CouponData;
