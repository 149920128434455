import React from 'react';

export interface SearchInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  showSearchBar: boolean;
}

const SearchInput: React.FC<SearchInputProps> = ({ showSearchBar, ...otherProps }) => (
  <div
    data-testid='search-input'
    className={`rulesTemplatesList__searchBar ${
      showSearchBar ? `rulesTemplatesList__searchBar_opened` : ''
    }`}>
    <form>
      <input
        type='search'
        className={`rulesTemplatesList__searchInput`}
        placeholder='Search for the templates'
        {...otherProps}
      />
    </form>
  </div>
);

export default SearchInput;
