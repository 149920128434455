import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';

import './style.scss';
import { ErrorBoundary } from '../ErrorBoundary';

// The named export is here for when we want to use this component WITHOUT router (so for example in our lazy loading loading fallback). Do not remove this export!
export const GlobalWrapper = observer(({ children, extraClass, style, onClick =() => {} }) => {
  return (
    <div
      className={`globalWrapper  ${extraClass ? extraClass : ''}`}
      style={style}
      onClick={onClick}
    >
      <ErrorBoundary name={'boundary_GlobalWrapper'}>{children}</ErrorBoundary>
    </div>
  );
});

export default withRouter(GlobalWrapper);
