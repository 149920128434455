export default class TmpStore {
  constructor(rootStore) {
    this.root = rootStore;
  }

  tmp;

  setTmp(tmp) {
    this.tmp = tmp;
    this.tmp.rule.sd = null;
    this.tmp.rule.ed = null;
    this.tmp.rule.s = 'draft';
    delete this.tmp.rule.rule_id;
  }

  isTmp() {
    return !!this.tmp;
  }

  getTmp() {
    return this.tmp;
  }

  removeTmp() {
    this.tmp = null;
  }
}
