import React from 'react';

import Pagination from 'components/Pagination';
import TemplateItem from './TemplateItem';
import { getAdditionalTemplateData } from 'constants/templates';

export interface TemplatesListProps {
  totalCount: number;
  templates: Array<Record<string, any>>;
  openedTemplateID: number;
  toggleOpenTemplate: (id: number) => void;
  currentPage: number;
  onPageChange: (page: number) => void;
  handleTemplateApply: (templateName: string) => void;
  itemsPerPage: number;
}

const TemplatesList: React.FC<TemplatesListProps> = ({
  totalCount,
  templates,
  openedTemplateID,
  toggleOpenTemplate,
  currentPage,
  onPageChange,
  handleTemplateApply,
  itemsPerPage,
}) => {
  if (!templates?.length)
    return <div className={`rulesTemplatesList__emptySearchResult`}>Nothing found</div>;

  return (
    <>
      {templates.length > 0 &&
        templates.map((template, index) => {
          const additionalProps = getAdditionalTemplateData(template._id);
          return (
            <TemplateItem
              onTemplateChoose={toggleOpenTemplate}
              openedTemplateID={openedTemplateID}
              bemParentClassItem={`rulesTemplatesList__item`}
              bemParentClassItemDetails={`rulesTemplatesList__itemDetails`}
              onTemplateApply={handleTemplateApply}
              graph={`${index}.svg`}
              key={template._id}
              {...template}
              {...additionalProps}
            />
          );
        })}

      <Pagination
        currentPage={currentPage}
        totalCount={totalCount}
        sizePerPage={itemsPerPage}
        onPageChange={onPageChange}
        classname='rulesTemplatesList__pagination'
        getPageClassname={(page) =>
          `rulesTemplatesList__pageNumber ${
            currentPage === page ? 'rulesTemplatesList__pageNumber_active' : ''
          }`
        }
      />
    </>
  );
};

export interface IStartBlankTemplateProps {
  onClick: () => void;
}

export const StartBlankTemplate: React.FC<IStartBlankTemplateProps> = ({ onClick }) => (
  <>
    <div
      data-testid='startBlankTemplate'
      role='button'
      className={`rulesTemplatesList__item
                            blankTemplateStartBut`}
      onClick={onClick}>
      <div className={`blankTemplateStartBut__innerWrap`}>&nbsp;</div>
    </div>
    <div className={`rulesTemplatesList__itemDetails`} />
  </>
);

export default TemplatesList;
