import React from 'react'

export interface IconTradeSellProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconTradeSell'
export const IconTradeSell = (props: IconTradeSellProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      xmlns={"http://www.w3.org/2000/svg"}
      x={0}
      y={0}
      viewBox={"0 0 23 23"}
      xmlSpace={"preserve"}
      width={"1rem"}
      height={"1rem"}
      {...otherProps}
    >
      <path
        fill={"#3642e1"}
        d={"M11.5 2.5c-5 0-9 4-9 9 0 4.9 4 9 9 9s9-4 9-9c0-4.9-4-9-9-9zm0 16.5C7.4 19 4 15.6 4 11.5S7.4 4 11.5 4 19 7.4 19 11.5 15.6 19 11.5 19z"}
      />
      <path
        fill={"#3642e1"}
        d={"m11.7 6.7-4.4 4.9 4.4 4.9v-3.4h3.4v-3h-3.4V6.7z"}
      />
    </svg>
  )
}

export default IconTradeSell
