import React from 'react'

import type { BoxProps } from '@mui/material'
import type { TRuleDisplayedStatus } from 'design-system';

import { Box } from '@mui/material'

import { ImageRuleStatusesSprite } from 'design-system'

export const ruleStatusesSpritesPositions: Record<TRuleDisplayedStatus, { left: number }> = {
  scheduled: {
    left: -42 * 0,
  },
  paused: {
    left: -42 * 1,
  },
  finished: {
    left: -42 * 2,
  },
  archived: {
    left: -42 * 3,
  },
  gotError: {
    left: -42 * 4,
  },
  waiting: {
    left: -42 * 5,
  },
  waitingSoon: {
    left: -42 * 5,
  },
}

export const ruleStatusesSpritesAnimations = {
  scheduled: {},
  paused: {},
  finished: {},
  archived: {},
  gotError: {},
  waiting: {
    '@keyframes spinSandClock': {
      '0%': {
        transform: 'rotateZ(0deg)',
      },
      '4%': {
        transform: 'rotateZ(180deg)',
      },
      '100%': {
        transform: 'rotateZ(180deg)',
      },
    },
    animation: 'spinSandClock 20s linear infinite'
  },
  waitingSoon: {
    '@keyframes spinSandClockQuickly': {
      '0%': {
        transform: 'rotateZ(0deg)',
      },
      '38%': {
        transform: 'rotateZ(180deg)',
      },
      '100%': {
        transform: 'rotateZ(180deg)',
      },
    },
    animation: 'spinSandClockQuickly 2s linear infinite'
  },
}

export interface RuleStatusIconSpriteProps extends BoxProps {
  status: keyof typeof ruleStatusesSpritesPositions
}

const testId = 'RuleStatusIconSprite'
export const RuleStatusIconSprite = (props: RuleStatusIconSpriteProps) => {
  const {
    sx,
    status,
    ...otherProps
  } = props

  return (
    <Box
      data-testid={testId}
      className={testId}
      sx={{
        
        width: '42px',
        height: '42px',
        display: 'inline-block',
        overflow: 'hidden',
        position: 'relative',
        ...ruleStatusesSpritesAnimations[status],
        ...sx
      }}
      {...otherProps}
    >
      {
        // do not render first icon when status is not passed
        status ?
          <ImageRuleStatusesSprite
            style={{
              height: '100%',
              position: 'absolute',
              top: 0,
              ...ruleStatusesSpritesPositions[status],
            }}
          />
          :
          null
      }
    </Box>
  )
}

export default RuleStatusIconSprite
