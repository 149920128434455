import React from 'react'

import type { StackProps, TypographyProps } from '@mui/material'
import type { RuleStatusBadgeProps, TRuleDisplayedStatus, TRuleTriggers, ExchangeLogoSpriteWithNameProps } from 'design-system'

import { useTheme, Stack, Typography } from '@mui/material'

import { Tooltip, IconInfo, DateFormatted, ExchangeLogoSpriteWithName, RuleStatusText, RuleStatusProgressBar, RuleStatusIconSprite, RuleStatusBadge, Spacer } from 'design-system'

const TypographyStyled = (props: TypographyProps) => {
  const theme = useTheme()

  return (
    <Typography
      sx={{
        fontSize: '12px',
        fontWeight: 400,
        textTransform: 'uppercase',
        color: theme.palette.coinrule.basic.greyLight,
        alignItems: 'center',
        display: 'flex'
      }}
      {...props}
    />
  )
}

export interface RuleStatusBarProps extends StackProps {
  startDate: Date,
  status: TRuleDisplayedStatus
  trigger: TRuleTriggers
  exchange: ExchangeLogoSpriteWithNameProps['exchange']
  exchangeName: ExchangeLogoSpriteWithNameProps['exchangeName']
  executionTimesCounter: RuleStatusBadgeProps['executionTimesCounter']
  timezoneString: string
}

const testId = 'RuleStatusBar'
export const RuleStatusBar = (props: RuleStatusBarProps) => {
  const {
    sx,
    startDate,
    status,
    trigger,
    exchange,
    exchangeName,
    executionTimesCounter,
    timezoneString,
    ...otherProps
  } = props

  const theme = useTheme()

  return (
    <Stack
      data-testid={testId}
      className={testId}
      direction={'row'}
      gap={'20px'}
      flexWrap={'wrap'}
      sx={{
        ...sx
      }}
      {...otherProps}
    >
      <Stack>
        <RuleStatusProgressBar 
          value={0}
        >
          <RuleStatusIconSprite 
            status={status}
          />
        </RuleStatusProgressBar>
      </Stack>
      <Stack
        alignItems={'flex-start'}
      >
        <TypographyStyled>
          STATUS
        </TypographyStyled>
        <Spacer y={10} />
        <RuleStatusBadge 
          executionTimesCounter={executionTimesCounter}
        />
        <Spacer y={5} />
        <RuleStatusText 
          status={status}
          trigger={trigger}
        />
      </Stack>
      <Stack>
        <div>
          <Tooltip
            title={timezoneString}
            placement={'right'}
          >
            <Stack
              direction={'row'}
              display={'inline-flex'}
              alignItems={'center'}
              gap={'5px'}
            >
              <TypographyStyled>
                START
              </TypographyStyled>
              <TypographyStyled>
                <IconInfo />
              </TypographyStyled>
            </Stack>
          </Tooltip>
        </div>
        <Spacer y={10} />
        <DateFormatted 
          date={startDate}
          format={'MMMM do yyyy, h:mm a'}
          sx={{
            fontSize: '12px',
            lineHeight: '15px',
            color: theme.palette.coinrule.basic.greyDark,
          }}
        />
      </Stack>
      <Stack>
        <TypographyStyled>
        EXCHANGE
        </TypographyStyled>
        <Spacer y={5} />
        <ExchangeLogoSpriteWithName 
          exchange={exchange}
          exchangeName={exchangeName}
        />
      </Stack>
    </Stack>
  )
}

export default RuleStatusBar
