import { observer } from 'mobx-react';
import React from 'react';
import { Tooltip } from 'antd';

import AddButtonsBlock from '../AddButtonsBlock';
import Wait from './Wait';
import DoNot from './DoNot';

import './style.scss';
import {
  BLOCK_TYPES,
  CONDITION_TYPE,
  OPERATOR_TYPE,
  OPERATOR_TYPES,
} from '../../../stores/addRule';
import RemoveSequence from '../../../components/Sequences/RemoveSequence';

import { Container } from '../../../components/Help';
import Help from '../../../components/Help';
import stores from '../../../stores';
import { EXCHANGES_TYPE } from '../../../constants/exchanges';
import PaymentGate from '../../../components/PaymentGate';

@observer
class Operator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedType: '',
      operator_index: 0,
      showPaywall: false,
    };
  }

  isLeverage() {
    const exchangeType = stores.exchangeInfo.exchangeType[this.props.store.rule.ex];
    return exchangeType && exchangeType === EXCHANGES_TYPE.LEVERAGE;
  }

  renderTriggerTitle(sequenceIndex) {
    let triggerTitle = 'AND';

    if (
      this.props.store.seqInit.sequences[sequenceIndex].data.operators[0].t === OPERATOR_TYPES.OR
    ) {
      triggerTitle = 'OR';
    } else if (
      this.props.store.seqInit.sequences[sequenceIndex].data.operators[0].t ===
      OPERATOR_TYPES.DO_NOT
    ) {
      triggerTitle = 'BUT';
    } else if (
      this.props.store.seqInit.sequences[sequenceIndex].data.operators[0].t ===
      OPERATOR_TYPES.PARALLEL
    ) {
      triggerTitle = 'AND AT';
    }

    return `${triggerTitle}`;
  }

  renderOperatorDetail(type, index, operator_index, removeBut) {
    let help = (
      <Container className={`helpTooltip`}>
        <div className={`helpTooltip__title`}>Select An Operator</div>
        <div>
          <p>Use an operator to connect blocks. </p>
        </div>
        <div>
          <p>
            Conditions and Actions that follow "THEN" and "WAIT" operators will trigger in sequence
            after the previous action has been executed.
          </p>
        </div>
        <div>
          <p>
            "Any Time" sets following actions and conditions to execute as soon as triggered in
            parallel to any other condition or action in the rule.
          </p>
        </div>
        <div>
          <p>"Do Not" will exclude certain coins from being sold or bought.</p>
        </div>
        <div>
          <p>
            The "Else" trigger lets you set up what will happen if your condition has not been met.
          </p>
        </div>
      </Container>
    );

    const helpIcon = (
      <div className={`newRuleFormSection__helpIcon`}>
        <Help message={help} />
      </div>
    );

    if (type === OPERATOR_TYPES.THEN) {
      return null;
    } else if (type === OPERATOR_TYPES.OR) {
      return null;
    } else if (type === OPERATOR_TYPES.PARALLEL) {
      return null;
    } else if (type === OPERATOR_TYPES.DO_NOT) {
      return (
        <DoNot
          key={0}
          store={this.props.store}
          sequenceIndex={index}
          id={this.props.id}
          operator_index={operator_index}
          helpIcon={helpIcon}
          removeBut={removeBut}
        />
      );
    } else if (type === OPERATOR_TYPES.WAIT) {
      return (
        <Wait
          key={0}
          store={this.props.store}
          sequenceIndex={index}
          id={this.props.id}
          operator_index={operator_index}
          helpIcon={helpIcon}
          removeBut={removeBut}
        />
      );
    }
  }

  handleClickOperator(index, type) {
    if (type === OPERATOR_TYPES.THEN) {
      if (this.props.store.seqInit.sequences.length - 1 === index) {
        this.props.store.seqInit.addToSequence(null, CONDITION_TYPE);
      }

      this.props.store.setOperatorType(index, 'then');
    } else if (type === OPERATOR_TYPES.WAIT) {
      this.props.store.setOperatorType(index, 'wait');

      if (this.props.store.seqInit.sequences.length - 1 > index) {
        this.props.store.seqInit.removeFromSequenceDirect(
          this.props.store.seqInit.sequences.length - 1
        );
      }
    } else if (type === OPERATOR_TYPES.DO_NOT && !this.isLeverage()) {
      this.props.store.setOperatorType(index, 'do_not');

      if (this.props.store.seqInit.sequences.length - 1 > index) {
        this.props.store.seqInit.removeFromSequenceDirect(
          this.props.store.seqInit.sequences.length - 1
        );
      }
    } else if (type === OPERATOR_TYPES.OR) {
      if (this.props.store.seqInit.sequences.length - 1 === index) {
        this.props.store.seqInit.addToSequence(null, CONDITION_TYPE);
      }

      this.props.store.setOperatorType(index, 'or', this.isLeverage());
    } else if (type === OPERATOR_TYPES.PARALLEL) {
      if (stores.user.user.plan.hat === false) {
        // user does NOT have the "At any time" operator
        stores.addRule.setPaymentGateVisible({ type: '', isVisible: true });
      } else {
        if (this.props.store.seqInit.sequences.length - 1 === index) {
          this.props.store.seqInit.addToSequence(null, CONDITION_TYPE);
        }

        this.props.store.setOperatorType(index, OPERATOR_TYPES.PARALLEL);
      }
    }

    this.props.store.seqInit.countingSequence();
  }

  renderThenButton(type, index) {
    let text = 'Run in Sequence';
    let customClasses = ['ruleSectionTitleTabs__tab_operator'];
    let canClick = true;

    if (type === OPERATOR_TYPES.THEN) {
      customClasses.push('newRuleTitleTabs__tab_active');
    }

    if (this.props.store.isQuickEdit()) {
      customClasses.push('disabled-button disabled-button_radius');
      canClick = false;
    } else if (type !== OPERATOR_TYPES.THEN) {
      if (
        !this.props.store.seqInit.canBeTabChange(index) &&
        type !== OPERATOR_TYPES.PARALLEL &&
        type !== OPERATOR_TYPES.THEN
      ) {
        customClasses.push('disabled-button');
        canClick = false;
        text = 'Option Disabled';
      }

      if (!this.props.store.seqInit.validationBlock(OPERATOR_TYPE, index, 'tabs', 'then')) {
        customClasses.push('disabled-button');
        canClick = false;
        text = 'Option Disabled';
      }

      // if (!this.props.store.seqInit.canBeTabChange(index) && type !== OPERATOR_TYPES.THEN) {
      //   customClasses.push('disabled-button');
      //   canClick = false;
      //   text = 'Option Disabled';
      // }
    }

    return (
      <Tooltip placement='top' title={text} defaultVisible={false}>
        <div
          className={`newRuleTitleTabs__tab
                          newRuleTitleTabs__tab_iconSequenceArrow
                          ${customClasses.join(' ')}`}
          onClick={() => {
            if (canClick) {
              this.handleClickOperator(index, OPERATOR_TYPES.THEN);
            }
          }}>
          then
        </div>
      </Tooltip>
    );
  }

  renderParallelButton(type, index) {
    let text = 'Run in Parallel';
    let customClasses = ['ruleSectionTitleTabs__tab_operator'];
    let canClick = true;

    if (type === OPERATOR_TYPES.PARALLEL) {
      customClasses.push('newRuleTitleTabs__tab_active');
    }

    if (this.props.store.isQuickEdit()) {
      customClasses.push('disabled-button disabled-button_radius');
      canClick = false;
    } else if (type !== OPERATOR_TYPES.PARALLEL) {
      if (
        !this.props.store.seqInit.canBeTabChange(index) &&
        type !== OPERATOR_TYPES.PARALLEL &&
        type !== OPERATOR_TYPES.THEN
      ) {
        customClasses.push('disabled-button');
        canClick = false;
        text = 'Option Disabled';
      }

      if (
        !this.props.store.seqInit.validationBlock(OPERATOR_TYPE, index, 'tabs', 'parallel') ||
        this.props.store.usingTradingViewSignal[this.props.store.rule.tr]
      ) {
        customClasses.push('disabled-button');
        canClick = false;
        text = 'Option Disabled';
      }

      // if (!this.props.store.seqInit.canBeTabChange(index) && type !== OPERATOR_TYPES.PARALLEL) {
      //   customClasses.push('disabled-button');
      //   canClick = false;
      //   text = 'Option Disabled';
      // }
    }

    return (
      <Tooltip placement='top' title={text} defaultVisible={false}>
        <div
          className={`newRuleTitleTabs__tab
                          newRuleTitleTabs__tab_iconParallelArrows
                          ${customClasses.join(' ')}`}
          onClick={() => {
            if (canClick) {
              this.handleClickOperator(index, OPERATOR_TYPES.PARALLEL);
            }
          }}>
          ANY TIME
        </div>
      </Tooltip>
    );
  }

  renderOrButton(type, index) {
    let customClasses = ['ruleSectionTitleTabs__tab_operator'];
    let text = 'Or Do';
    let canClick = true;

    if (type === OPERATOR_TYPES.OR) {
      customClasses.push('newRuleTitleTabs__tab_active');
    }

    if (this.props.store.isQuickEdit()) {
      customClasses.push('disabled-button disabled-button_radius');
      canClick = false;
    } else if (type !== OPERATOR_TYPES.OR) {
      if (!this.props.store.seqInit.canBeTabChange(index) && type !== OPERATOR_TYPES.OR) {
        customClasses.push('disabled-button');
        canClick = false;
        text = 'Option Disabled';
      }

      if (!this.props.store.seqInit.validationBlock(OPERATOR_TYPE, index, 'tabs', 'or')) {
        customClasses.push('disabled-button');
        canClick = false;
        text = 'Option Disabled';
      }
    }

    return (
      <Tooltip placement='top' title={text} defaultVisible={false}>
        <div
          className={`newRuleTitleTabs__tab
                          newRuleTitleTabs__tab_iconDiffDirectArrows
                          ${customClasses.join(' ')}`}
          onClick={() => {
            if (canClick) {
              this.handleClickOperator(index, OPERATOR_TYPES.OR);
            }
          }}>
          or
        </div>
      </Tooltip>
    );
  }

  renderDoNotButton(type, index) {
    let text = 'Exclude Coins';
    let customClasses = ['ruleSectionTitleTabs__tab_operator'];
    let canClick = true;

    if (type === OPERATOR_TYPES.DO_NOT) {
      customClasses.push('newRuleTitleTabs__tab_active');
    }

    if (this.props.store.isQuickEdit()) {
      customClasses.push('disabled-button disabled-button_radius');
      canClick = false;
    } else if (type !== OPERATOR_TYPES.DO_NOT) {
      if (!this.props.store.seqInit.validationBlock(OPERATOR_TYPE, index, 'tabs', 'do_not')) {
        customClasses.push('disabled-button');
        canClick = false;
        text = 'Option Disabled';
      }

      if (!this.props.store.seqInit.canBeTabChange(index) && type !== OPERATOR_TYPES.DO_NOT) {
        customClasses.push('disabled-button');
        canClick = false;
        text = 'Option Disabled';
      }
    }

    return (
      <Tooltip placement='top' title={text} defaultVisible={false}>
        <div
          className={`newRuleTitleTabs__tab
                          newRuleTitleTabs__tab_iconForbidden
                          ${customClasses.join(' ')}`}
          onClick={() => {
            if (canClick) {
              this.handleClickOperator(index, OPERATOR_TYPES.DO_NOT);
            }
          }}>
          do not
        </div>
      </Tooltip>
    );
  }

  renderWaitButton(type, index) {
    let canClick = true;
    let text = 'Pause Execution';
    let customClasses = ['ruleSectionTitleTabs__tab_operator'];

    if (type === OPERATOR_TYPES.WAIT) {
      customClasses.push('newRuleTitleTabs__tab_active');
    }

    if (this.props.store.isQuickEdit()) {
      customClasses.push('disabled-button disabled-button_radius');
      canClick = false;
    } else if (type !== OPERATOR_TYPES.WAIT) {
      if (!this.props.store.seqInit.canBeTabChange(index) && type !== OPERATOR_TYPES.WAIT) {
        customClasses.push('disabled-button');
        canClick = false;
        text = 'Option Disabled';
      }

      if (!this.props.store.seqInit.validationBlock(OPERATOR_TYPE, index, 'tabs', 'wait')) {
        customClasses.push('disabled-button');
        canClick = false;
        text = 'Option Disabled';
      }
    }

    return (
      <Tooltip placement='top' title={text} defaultVisible={false}>
        <div
          className={`newRuleTitleTabs__tab
                          newRuleTitleTabs__tab_iconPause
                        ${customClasses.join(' ')}`}
          onClick={() => {
            if (canClick) {
              this.handleClickOperator(index, OPERATOR_TYPES.WAIT);
            }
          }}>
          wait
        </div>
      </Tooltip>
    );
  }

  componentDidMount() {
    const sequenceIndex = this.props.store.seqInit.getIndexById(this.props.id);
    if (!this.props.store.seqInit.isBeforeElement(sequenceIndex, CONDITION_TYPE)) {
      this.props.store.seqInit.sequences[sequenceIndex].errors.push(
        'You cannot use else in this case because you have setup time trigger.'
      );
    }
  }

  renderOperatorNote(index) {
    let text;

    if (this.props.store.seqInit.sequences[index].type === OPERATOR_TYPES.ELSE) {
      text = 'The “Or Else” Operator applies to the latest condition selected above.';
    } else if (this.props.store.seqInit.sequences[index].type === OPERATOR_TYPES.THEN) {
      text = 'The "Then" operator must be used before adding a "Condition"';
      const nextElement = this.props.store.seqInit.getNextElement(index);

      if (nextElement !== null && nextElement.type === BLOCK_TYPES.CONDITION) {
        text = 'The "Then" operator must be used before adding a "Condition"';
      }
    } else {
      return null;
    }

    return <div className='info-text'>{text}</div>;
  }

  render() {
    const { store } = this.props;
    const sequenceIndex = store.seqInit.getIndexById(this.props.id);
    const type = store.seqInit.sequences[sequenceIndex].data.operators[0].t;
    const operator_index = 0;

    const removeBut = (
      <RemoveSequence
        canBeRemoved={store.canSequenceBeRemoved(sequenceIndex)}
        onClick={() => store.seqInit.removeFromSequence(sequenceIndex)}
      />
    );

    return (
      <React.Fragment>
        <PaymentGate
          stores={stores}
          visible={stores.addRule.paymentGateVisible}
          subtitle='Choose a plan to get access to advanced operators'
        />
        <div
          className={`operators
                      show-section
                      show-section-end
                      ${!this.props.store.rule.ex && 'show-section_unavailable'}
                      ${this.props.store.seqInit.idCount === this.props.id && 'animation-show'}`}
          data-sequence={this.props.id}
          id={`sequence${this.props.id}`}
          onClick={() => stores.addRule.setSFPpendingSection(this.props.id)}>
          <div className='rule-settings-block'>
            <div
              className='rsb-line
                          rsb-line_purple'>
              &nbsp;
            </div>
            <div className='rule-settings'>
              <div className='title-wrapper'>
                <div className='part-title title-purple'>
                  {this.renderTriggerTitle(sequenceIndex)}
                </div>
              </div>
              <div className='settings'>
                <div
                  className={`newRuleTitleTabs
                                newRuleTitleTabs_purple`}>
                  {this.renderThenButton(type, sequenceIndex)}
                  {this.renderParallelButton(type, sequenceIndex)}
                  {this.renderOrButton(type, sequenceIndex)}
                  {this.renderDoNotButton(type, sequenceIndex)}
                  {this.renderWaitButton(type, sequenceIndex)}
                </div>

                {this.renderOperatorDetail(type, sequenceIndex, operator_index, removeBut)}
              </div>
            </div>
            {this.renderOperatorNote(sequenceIndex)}
          </div>

          <AddButtonsBlock
            store={this.props.store}
            open={true}
            sequenceIndex={sequenceIndex}
            id={this.props.id}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Operator;
