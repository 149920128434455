import type { TReducer } from 'v2/types/TReducer'

import type { TDomainActionConnectExchangeBinanceOAuthCreateApiKeys } from './actionsFetchCreateApiKeys';
import type { DTOFetchConnectExchangeBinanceOAuthCreateApiKeys } from './DTOs/DTOFetchConnectExchangeBinanceOAuthCreateApiKeys';
import type { DTOErrorFetchConnectExchangeBinanceOAuthCreateApiKeys } from './DTOs/DTOErrorFetchConnectExchangeBinanceOAuthCreateApiKeys';

import { EAsyncStatus } from 'v2/types/EAsyncStatus';
import { EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys } from './actionsFetchCreateApiKeys'

export type TDomainStateConnectExchangeBinanceOAuthCreateApiKeys = {
  data: DTOFetchConnectExchangeBinanceOAuthCreateApiKeys | null,
  error: DTOErrorFetchConnectExchangeBinanceOAuthCreateApiKeys | null
  status: EAsyncStatus
}

export const initialStateConnectExchangeBinanceOAuthCreateApiKeys: TDomainStateConnectExchangeBinanceOAuthCreateApiKeys = {
  data: null,
  error: null,
  status: EAsyncStatus.IDLE
}

export const reducerConnectExchangeBinanceOAuthCreateApiKeys: TReducer<TDomainStateConnectExchangeBinanceOAuthCreateApiKeys, TDomainActionConnectExchangeBinanceOAuthCreateApiKeys> = (state = initialStateConnectExchangeBinanceOAuthCreateApiKeys, action): TDomainStateConnectExchangeBinanceOAuthCreateApiKeys => {
  switch (action.type) {
    case EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_STARTED: {
      return {
        data: null,
        error: null,
        status: EAsyncStatus.PENDING
      }
    }
    case EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_SUCCEEDED: {
      return {
        data: action.payload.dto,
        error: null,
        status: EAsyncStatus.RESOLVED
      }
    }
    case EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_FAILED: {
      return {
        data: null,
        error: action.payload.error,
        status: EAsyncStatus.REJECTED
      }
    }
    default:
      return state
  }
}