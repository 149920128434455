import React from 'react'

export interface ImageShareRuleProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'ImageShareRule'
export const ImageShareRule = (props: ImageShareRuleProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      width={295}
      height={182}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...otherProps}
    >
      <path
        opacity={0.3}
        d={"M59.214 9.2C28.659 16.517 4.52 33.56.65 64.876c-3.258 26.381 4.266 71.221 56.115 75.994 104.814 9.645 216.477 19.308 231.579-26.164 10.242-30.83 13.617-85.991-42.948-102.944C207.03.25 124.707-6.472 59.214 9.2Z"}
        fill={"#E6E6E6"}
      />
      <path
        opacity={0.45}
        d={"M150.063 181.489c78.336 0 141.84-5.453 141.84-12.18 0-6.727-63.504-12.18-141.84-12.18-78.336 0-141.84 5.453-141.84 12.18 0 6.727 63.504 12.18 141.84 12.18Z"}
        fill={"#E6E6E6"}
      />
      <path
        d={"M129.918 103.292a3.31 3.31 0 0 0 2.394-.208 4.51 4.51 0 0 0 1.89-1.407 3.083 3.083 0 0 0 .621-2.247 1.187 1.187 0 0 0-.504-.902c-.234-.136-.54-.145-.765-.289a2.331 2.331 0 0 1-.549-.704 2.353 2.353 0 0 0-1.8-.902 4.612 4.612 0 0 0-2.043.451 3.301 3.301 0 0 0-2.034 1.949 2.706 2.706 0 0 0 1.17 2.707 8.076 8.076 0 0 0 2.943 1.118"}
        fill={"#24285B"}
      />
      <path
        d={"M112 96.75a2.7 2.7 0 0 1 2.466-.82c.292.086.596.123.9.108.24-.066.469-.163.684-.289 1.17-.541 2.538-.054 3.735.442.395.198.828.309 1.269.325a3.459 3.459 0 0 0 1.08-.27 14.309 14.309 0 0 1 2.52-.632 1.589 1.589 0 0 1 1.224.171c.167.157.324.326.468.505.343.283.763.456 1.206.497a4.555 4.555 0 0 1 2.538.902c.234.234.405.523.63.749.225.225.45.37.648.586a3.24 3.24 0 0 1 .459 2.454 1.472 1.472 0 0 1-.189.704 1.42 1.42 0 0 1-.99.451l-4.932.794c-3.276.523-6.552 1.056-9.855 1.452-1.67.266-3.366.321-5.049.163-1.8-.235-2.286-1.002-2.439-2.707-.243-2.625 2.115-3.942 3.627-5.584Z"}
        fill={"#E6E6E6"}
      />
      <path
        d={"M136.383 99.457H107.34v12.442h29.043V99.457Z"}
        fill={"#FFD200"}
      />
      <path
        d={"m83.121 97.958 2.358-5.981L90.69 78.74l5.292-13.47c1.368-3.456 2.754-6.758 4.437-10.078a12.79 12.79 0 0 1 1.944-3.077 4.295 4.295 0 0 1 3.204-1.497.718.718 0 0 1 .441.117c.1.09.175.205.216.334.52 1.43.561 2.992.117 4.448a24.621 24.621 0 0 1-1.188 3.212 8.507 8.507 0 0 0-.567 2.562c0 .108-.045.307-.063.433l-.459 3.077-1.8 11.918-2.043 13.75-1.278 8.589c0 .126.9-4.61-.072.532-.972 5.143-9.963 14.219-15.75-1.633Z"}
        fill={"#F4A28C"}
      />
      <path
        d={"M133.464 131.342s7.893 7.452-2.331 7.154c-10.224-.297-6.462-8.273 2.331-7.154Z"}
        fill={"#6869FC"}
      />
      <path
        d={"M74.542 47.506s15.3 11.467 8.1 15.888c-7.2 4.421-12.77-16.953-12.77-16.953l4.67 1.065Z"}
        fill={"#24285B"}
      />
      <path
        d={"M74.47 37.737s1.152 4.854.8 8.255a2.504 2.504 0 0 1-1.786 2.146c-.316.092-.648.12-.976.083a5.526 5.526 0 0 1-2.951-1.027 5.547 5.547 0 0 1-1.927-2.465l-2.106-4.105a4.522 4.522 0 0 1 1.26-5.025c2.484-2.41 7.137-.866 7.686 2.138Z"}
        fill={"#F4A28C"}
      />
      <path
        d={"m65.938 43.168 1.53 16.294 8.9-1.489-4.688-11.26-5.743-3.545ZM69.855 42.735s-.315-1.895-1.719-1.543c-1.404.352-.963 3.104 1.008 3.077l.711-1.534ZM75.018 40.832l1.656 1.705a.804.804 0 0 1-.315 1.318l-1.8.631.459-3.654Z"}
        fill={"#F4A28C"}
      />
      <path
        d={"M104.036 118.008s30.447-7.741 34.416 5.747c3.969 13.488-42.57 12.162-42.57 12.162l-10.593-8.4 18.747-9.509Z"}
        fill={"#24285B"}
      />
      <path
        d={"M72.644 57.16s17.721 1.805 30.069 15.96c7.335 8.4 4.563 15.401 1.098 19.705a14.174 14.174 0 0 0-2.313 13.254l3.672 11.467s-19.602 12.631-31.986 11.332c-12.384-1.299-51.354-69.769-.54-71.718Z"}
        fill={"#6869FC"}
      />
      <path
        opacity={0.08}
        d={"M48.505 71.544s-2.197 6.74-1 13.254c1.197 6.514 7.326 49.775 47.961 38.092l9.703-5.414-10.296-8.688L75.17 88.127 66.55 71.535l-18.045.01Z"}
        fill={"#000"}
      />
      <path
        d={"M62.418 100.774c-8.712-26.454-47.6-22.123-50.93 5.539-3.43 28.555.458 56.488 46.313 61.604 72.9 8.12 84.402-13.434 74.115-30.829-10.287-17.395-61.47-8.255-69.11-35.078-.118-.443-.244-.84-.388-1.236Z"}
        fill={"#E6E6E6"}
      />
      <path
        d={"M122.566 115.681s10.17 2.472 10.899-4.015l-10.899-1.182v5.197ZM60.763 72.281c-.108-6.126 6.732-10.069 11.826-6.694 3.492 2.31 6.93 7.064 9 16.411 4.05 18.261 14.13 26.165 40.977 28.483v5.197s-60.714 15.473-61.803-43.397Z"}
        fill={"#F4A28C"}
      />
      <path
        d={"M73.46 38.92a3.602 3.602 0 0 0 2.228-2.83 3.617 3.617 0 0 0-1.327-3.35 7.546 7.546 0 0 0-1.8-1.038c-4.24-1.804-14.4-.532-13.725 9.13.675 9.663-15.417 31.632-.9 37.596 14.517 5.964 26.622-1.913 26.622-9.022 0-7.11-13.788-8.779-14.688-14.968a492.71 492.71 0 0 1-1.017-7.48 7.738 7.738 0 0 1 .967-4.79 7.71 7.71 0 0 1 3.64-3.248Z"}
        fill={"#24285B"}
      />
      <path
        opacity={0.45}
        d={"M38.12 130.838c-5.13-4.692-13.168.451-10.954 7.046 3.141 9.329 10.36 18.478 26.217 21.184 35.631 6.081 32.157-7.136 29.853-12.901-1.917-4.71-25.479 2.634-45.117-15.329Z"}
        fill={"#fff"}
      />
      <path
        d={"M106.925 42.52s-1.089-4.367 2.493-6.063l.468 1.281s-1.962.072-2.268 4.89l-.693-.108Z"}
        fill={"#6869FC"}
      />
      <path
        opacity={0.08}
        d={"M106.925 42.52s-1.089-4.367 2.493-6.063l.468 1.281s-1.962.072-2.268 4.89l-.693-.108Z"}
        fill={"#000"}
      />
      <path
        d={"M104.028 54.463a3.97 3.97 0 0 1 2.556.316l.279.135a2.45 2.45 0 0 0 2.493-.189 11.157 11.157 0 0 0 4.95-7.93c.432-4.674-2.367-5.621-4.833-5.035a4.625 4.625 0 0 1-3.501-.568 4.13 4.13 0 0 0-3.825-.55c-2.493 1.1-3.825 8.904-.126 13.154a2.072 2.072 0 0 0 2.007.667Z"}
        fill={"#FFD200"}
      />
      <path
        opacity={0.43}
        d={"M112.818 45.019a10.238 10.238 0 0 1-1.422 5.557.583.583 0 0 1-.959.044.591.591 0 0 1-.121-.35v-5.206a1.248 1.248 0 0 1 1.061-1.246c.197-.03.398-.01.586.055a1.225 1.225 0 0 1 .855 1.146Z"}
        fill={"#fff"}
      />
      <path
        d={"M107.285 38.532s.117-4.61-4.887-3.608c0-.045.054 4.628 4.887 3.608Z"}
        fill={"#6869FC"}
      />
      <path
        opacity={0.46}
        d={"M107.285 38.532s.117-4.61-4.887-3.608c0-.045.054 4.628 4.887 3.608Z"}
        fill={"#fff"}
      />
      <path
        d={"M202.614 104.133c-4.167 4.944-9.531 9.094-15.786 10.691a3.601 3.601 0 0 0-2.268 1.164 3.078 3.078 0 0 0 .072 2.77 18.654 18.654 0 0 0 7.96 9.08 18.582 18.582 0 0 0 11.84 2.288 25.054 25.054 0 0 0 9.351-3.879 20.888 20.888 0 0 0 6.714-6.388c2.016-3.293 2.583-7.263 2.799-11.116a74.83 74.83 0 0 0-.135-10.438c-.324-3.826-3.096-13.2-8.928-11.341-2.403.758-3.996 5.864-5.193 7.894a66.084 66.084 0 0 1-6.426 9.275Z"}
        fill={"#6869FC"}
      />
      <path
        opacity={0.08}
        d={"M202.614 104.133c-4.167 4.944-9.531 9.094-15.786 10.691a3.601 3.601 0 0 0-2.268 1.164 3.078 3.078 0 0 0 .072 2.77 18.654 18.654 0 0 0 7.96 9.08 18.582 18.582 0 0 0 11.84 2.288 25.054 25.054 0 0 0 9.351-3.879 20.888 20.888 0 0 0 6.714-6.388c2.016-3.293 2.583-7.263 2.799-11.116a74.83 74.83 0 0 0-.135-10.438c-.324-3.826-3.096-13.2-8.928-11.341-2.403.758-3.996 5.864-5.193 7.894a66.084 66.084 0 0 1-6.426 9.275Z"}
        fill={"#000"}
      />
      <path
        d={"M195.683 110.069c.798.234 1.656.16 2.403-.208a4.501 4.501 0 0 0 1.89-1.407 3.023 3.023 0 0 0 .612-2.247 1.223 1.223 0 0 0-.504-.902c-.234-.135-.531-.144-.765-.289a2.303 2.303 0 0 1-.54-.703 2.346 2.346 0 0 0-1.8-.902 4.544 4.544 0 0 0-2.034.451 3.267 3.267 0 0 0-1.231.746 3.3 3.3 0 0 0-.794 1.202 2.707 2.707 0 0 0 1.179 2.707 7.934 7.934 0 0 0 2.934 1.119"}
        fill={"#24285B"}
      />
      <path
        d={"M177.792 103.527a2.74 2.74 0 0 1 2.466-.821c.292.086.596.122.9.108.235-.066.461-.16.675-.28 1.179-.541 2.547-.054 3.744.434.395.198.827.312 1.269.333.369-.035.731-.13 1.071-.279a13.492 13.492 0 0 1 2.52-.623 1.584 1.584 0 0 1 1.224.163c.169.155.326.324.468.505.346.284.769.457 1.215.496a4.578 4.578 0 0 1 2.529.902c.234.235.405.523.63.758.225.235.459.361.648.577a3.267 3.267 0 0 1 .459 2.464c.001.243-.061.482-.18.694a1.437 1.437 0 0 1-.99.451l-4.932.794c-3.285.533-6.561 1.056-9.855 1.462a20.203 20.203 0 0 1-5.058.153c-1.8-.234-2.286-1.001-2.43-2.706-.234-2.617 2.115-3.934 3.627-5.585Z"}
        fill={"#E6E6E6"}
      />
      <path
        d={"M202.172 106.242h-29.043v12.442h29.043v-12.442Z"}
        fill={"#FFD200"}
      />
      <path
        d={"M171.969 71.398H163.4a1.066 1.066 0 0 0-1.005.7 1.074 1.074 0 0 0-.066.428l.702 14.183a1.922 1.922 0 0 0 1.917 1.805h5.608a1.924 1.924 0 0 0 1.917-1.85l.567-14.174a1.092 1.092 0 0 0-.31-.768 1.083 1.083 0 0 0-.761-.324Z"}
        fill={"#6869FC"}
      />
      <path
        d={"m169.521 84.733-.63-.045 1.116-16.186a.32.32 0 0 1 .117-.225.359.359 0 0 1 .252-.063l5.193.902-.099.623-4.86-.822-1.089 15.816Z"}
        fill={"#24285B"}
      />
      <path
        opacity={0.44}
        d={"M171.672 76.578h-7.902l.351 9.654a1.195 1.195 0 0 0 1.197 1.155h4.743a1.202 1.202 0 0 0 1.197-1.146l.414-9.663Z"}
        fill={"#fff"}
      />
      <path
        d={"M222.126 36.608s-.135 5.702 1.053 9.42a2.856 2.856 0 0 0 3.6 1.804 6.298 6.298 0 0 0 3.061-1.85 6.325 6.325 0 0 0 1.565-3.22l1.386-5.08a5.127 5.127 0 0 0-2.592-5.323c-3.33-2.067-8.163.748-8.073 4.25Z"}
        fill={"#F4A28C"}
      />
      <path
        d={"m232.555 38.621 4.095 18.252-10.197 1.543 1.116-13.903 4.986-5.892Z"}
        fill={"#F4A28C"}
      />
      <path
        d={"M223.519 36.737a22.89 22.89 0 0 0 5.211-.731 4.747 4.747 0 0 0 .333 5.206 3.85 3.85 0 0 0 4.752 1.254l-.549-7.317a5.837 5.837 0 0 0-3.258-4.99 22.428 22.428 0 0 0-2.52-1.037c-2.151-.713-5.139.902-7.119-.415a1.377 1.377 0 0 0-2.116 1.037c-.189 2.247.432 5.73 3.51 6.758a5.504 5.504 0 0 0 1.756.235Z"}
        fill={"#24285B"}
      />
      <path
        d={"M228.47 41.12s-.099-2.202 1.557-2.13c1.656.072 1.8 3.23-.405 3.672l-1.152-1.543ZM222.223 40.219l-1.404 2.264a.897.897 0 0 0-.063.89.898.898 0 0 0 .729.509l2.196.27-1.458-3.933Z"}
        fill={"#F4A28C"}
      />
      <path
        opacity={0.31}
        d={"M227.202 47.544a6.724 6.724 0 0 0 3.195-2.192s.072 3.419-3.456 7.028l.261-4.836Z"}
        fill={"#CE8172"}
      />
      <path
        d={"m241.599 129.024-27.558-1.804s-.396-2.707-.999-7.11c-.432-3.167-.981-7.218-1.566-11.638-.963-7.335-2.025-15.843-2.871-23.657-2.079-19.1 17.847-26.417 17.847-26.417l10.197-1.543c46.332 7.218 4.95 72.169 4.95 72.169Z"}
        fill={"#6869FC"}
      />
      <path
        opacity={0.08}
        d={"M239.251 101.134c-12.843 15.672-16.749 25.181-16.749 25.181l-9.459-6.189c-.432-3.167-.981-7.218-1.566-11.639l7.542-11.16 22.05-24.811.693.162s10.332 12.785-2.511 28.456Z"}
        fill={"#000"}
      />
      <path
        d={"M240.852 68.267a9.428 9.428 0 0 0-4.098-5.461 9.385 9.385 0 0 0-6.702-1.251c-5.238 1.01-11.484 4.51-13.68 15.184a81.083 81.083 0 0 1-9.225 24.522 5.838 5.838 0 0 1-4.088 2.811 5.838 5.838 0 0 1-2.535-.146 5.843 5.843 0 0 1-2.233-1.212l-21.924-18.947-4.914 5.784 25.632 26.841a10.572 10.572 0 0 0 13.32 1.633c12.861-8.165 36.972-26.905 30.447-49.758Z"}
        fill={"#6869FC"}
      />
      <path
        opacity={0.46}
        d={"M240.852 68.267a9.428 9.428 0 0 0-4.098-5.461 9.385 9.385 0 0 0-6.702-1.251c-5.238 1.01-11.484 4.51-13.68 15.184a81.083 81.083 0 0 1-9.225 24.522 5.838 5.838 0 0 1-4.088 2.811 5.838 5.838 0 0 1-2.535-.146 5.843 5.843 0 0 1-2.233-1.212l-21.924-18.947-4.914 5.784 25.632 26.841a10.572 10.572 0 0 0 13.32 1.633c12.861-8.165 36.972-26.905 30.447-49.758Z"}
        fill={"#fff"}
      />
      <path
        d={"M176.359 83.749s-6.723-7.11-10.215-2.229 5.301 8.012 5.301 8.012l4.914-5.783Z"}
        fill={"#F4A28C"}
      />
      <path
        d={"M178.464 131.342s-7.893 7.452 2.34 7.154c10.233-.297 6.462-8.273-2.34-7.154Z"}
        fill={"#6869FC"}
      />
      <path
        d={"M211.308 119.74s-33.849-9.473-37.8 4.015c-3.951 13.488 42.57 12.162 42.57 12.162l10.584-8.399-15.354-7.778Z"}
        fill={"#24285B"}
      />
      <path
        d={"M237.684 100.774c8.712-26.454 47.61-22.123 50.931 5.539 3.429 28.555-.459 56.488-46.314 61.604-72.9 8.12-84.402-13.434-74.115-30.829 10.287-17.395 61.47-8.255 69.12-35.078.117-.443.243-.84.378-1.236Z"}
        fill={"#E6E6E6"}
      />
      <path
        opacity={0.45}
        d={"M262.004 130.838c5.13-4.692 13.167.451 10.953 7.046-3.132 9.329-10.359 18.478-26.217 21.184-35.622 6.081-32.148-7.136-29.853-12.901 1.872-4.71 25.47 2.634 45.117-15.329Z"}
        fill={"#fff"}
      />
      <path
        opacity={0.58}
        d={"M35.878 158.102s-8.82-2.418-10.737-10.646c0 0 13.662-2.761 14.049 11.377l-3.312-.731Z"}
        fill={"#6869FC"}
      />
      <path
        opacity={0.73}
        d={"M36.957 157.228s-6.165-9.771-.738-18.892c0 0 10.386 6.613 5.769 18.947l-5.031-.055Z"}
        fill={"#6869FC"}
      />
      <path
        d={"M38.543 157.235s3.258-10.312 13.077-12.27c0 0 1.8 6.694-6.372 12.297l-6.705-.027Z"}
        fill={"#6869FC"}
      />
      <path
        d={"m32.152 157 1.782 12.261 11.26.045 1.664-12.243L32.152 157Z"}
        fill={"#24285B"}
      />
    </svg>
  )
}

export default ImageShareRule
