import { isNegativeNumber } from 'helpers/helpers';
import React from 'react';
import Popover from '../../../components/Popover';

export interface TemplateHeadlineProps {
  appliedTemplate: Record<string, any> | null;
  totalTemplates: number;
  isTemplateLibOpened: boolean;
  handleTemplateReset: () => void;
  isTemplateApplied: boolean;
}

const TemplateHeadline: React.FC<TemplateHeadlineProps> = ({
  appliedTemplate,
  totalTemplates,
  isTemplateLibOpened,
  handleTemplateReset,
  isTemplateApplied,
}) => {
  if (!appliedTemplate)
    return (
      <Popover text='Chose Template Strategy'>
        <div className='rulesTemplatesList__titleText'>
          Template Library
          <span className='rulesTemplatesList__totalTemplatesNum'>({totalTemplates}+)</span>
        </div>
      </Popover>
    );

  const { percentage, pair, amount, templateName, tutorialUrl } = appliedTemplate;

  const percentageColor = !isNegativeNumber(percentage)
    ? 'appliedTemplate__percentage_positive'
    : 'appliedTemplate__percentage_negative';

  const worksOn = `Works on ${pair === 'Single Coin' ? 'a' : ''} ${pair}`;

  return (
    <div className='rulesTemplatesList__title'>
      <div
        className={`${isTemplateApplied ? 'rulesTemplatesList__titleText' : ''} ${
          isTemplateLibOpened ? 'rulesTemplatesList__titleText' : ''
        }	${!isTemplateLibOpened ? 'rulesTemplatesList__titleText' : ''}`}>
        Start with Template
      </div>

      <div
        className='rulesTemplatesList__appliedTemplate
                        appliedTemplate'>
        <div className='appliedTemplate__template'>
          <div className='appliedTemplate__templateName'>{templateName}</div>
          <div className='appliedTemplate__indicators'>
            {amount > 0 ? <div className='appliedTemplate__amount'>{`$${amount}`}</div> : null}

            {percentage && (
              <div
                className={`appliedTemplate__percentage
                                  ${percentageColor}`}>
                {percentage}
              </div>
            )}

            {pair && <div className='appliedTemplate__pair'>{worksOn}</div>}

            {tutorialUrl && (
              <a href={tutorialUrl} target='_blank' rel='noopener noreferrer'>
                Read more
              </a>
            )}

          </div>
        </div>
        <div
          role='button'
          data-testid='appliedTemplate__closeBut'
          className='appliedTemplate__closeBut'
          onClick={handleTemplateReset}>
          &nbsp;
        </div>
      </div>
    </div>
  );
};

export default TemplateHeadline;
