import { ADVANCED_ONBOARDING_TEMPLATE_PAYWALL_NAME } from 'pages/RulesDashboardPage/Onboarding/utils';
import React, { ReactNode } from 'react';

export interface PaymentGateTitleProps {
  type?: string;
  clearance: number;
  title?: ReactNode;
  subtitle?: ReactNode;
  couponTrial?: number;
}

const PaymentGateTitle: React.FC<PaymentGateTitleProps> = ({
  clearance,
  type,
  subtitle,
  title,
  couponTrial,
}) => {
  const getTitle = (alternative: ReactNode, title?: ReactNode) => title || alternative;

  const getSubtitle = (subtitle: ReactNode, cancelText: ReactNode = getCancelText()) => (
    <>
      {subtitle} {type !== ADVANCED_ONBOARDING_TEMPLATE_PAYWALL_NAME && cancelText}
    </>
  );

  const getCancelText = () => <div className={`light-font`}>Cancel Anytime</div>;

  const getExecutionTitle = () => {
    const executionTitle = getTitle('Get More Executions Today', title);

    const subtitleAlt = couponTrial ? `For Free For ${couponTrial} Days` : '';
    const executionSubtitle = getSubtitle(getTitle(subtitleAlt, subtitle));

    return (
      <div>
        <h1>{executionTitle}</h1>
        <h2>{executionSubtitle}</h2>
      </div>
    );
  };

  const getTitleBasedOnClearance = (clearance: number) => {
    const titleConfig: Record<number, { clearanceTitle: ReactNode; clearanceSubtitle: ReactNode }> =
      {
        0: {
          clearanceTitle: getTitle('Trade Smarter', title),
          clearanceSubtitle: getSubtitle(getTitle('Raise Your Trading Game Today', subtitle)),
        },
        1: {
          clearanceTitle: getTitle('Expand Your Trading', title),
          clearanceSubtitle: getSubtitle(
            getTitle('Get Access To New Advanced Features Today', subtitle)
          ),
        },
        2: {
          clearanceTitle: getTitle('Start With The Pro Suite', title),
          clearanceSubtitle: getSubtitle(
            getTitle('The Full Power Of Automated Trading Today', subtitle)
          ),
        },
        3: {
          clearanceTitle: getTitle('Trade Smarter', title),
          clearanceSubtitle: getSubtitle(getTitle('Raise Your Trading Game Today', subtitle)),
        },
      };

    const { clearanceTitle, clearanceSubtitle } = titleConfig[clearance || 0];

    return (
      <div>
        <h1>{clearanceTitle}</h1>
        <h2>{clearanceSubtitle}</h2>
      </div>
    );
  };

  if (type === 'execution') return getExecutionTitle();

  return getTitleBasedOnClearance(clearance);
};

export default PaymentGateTitle;
