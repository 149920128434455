import type { TAction } from "v2/types/TAction";
import type { DTOErrorGetSharedRule } from "./DTOs/DTOErrorGetSharedRule";
import type { DTOSharedRule } from "./DTOs/DTOSharedRule";

export enum EDomainActionsAccessSharedRule {
  FETCH_STARTED = 'domains/accessSharedRule/FETCH_STARTED',
  FETCH_SUCCEEDED = 'domains/accessSharedRule/FETCH_SUCCEEDED',
  FETCH_FAILED = 'domains/accessSharedRule/FETCH_FAILED',
  FETCH_RESET = 'domains/accessSharedRule/FETCH_RESET',

  SET_OFFSET = 'domains/accessSharedRule/SET_OFFSET',
  SET_SHOW_ONLY_TRADES = 'domains/accessSharedRule/SET_SHOW_ONLY_TRADES',
}

export type TDomainActionsAccessSharedRule = {
  [EDomainActionsAccessSharedRule.FETCH_STARTED]: TAction<EDomainActionsAccessSharedRule.FETCH_STARTED>,
  [EDomainActionsAccessSharedRule.FETCH_SUCCEEDED]: TAction<EDomainActionsAccessSharedRule.FETCH_SUCCEEDED, { sharedRule: DTOSharedRule }>,
  [EDomainActionsAccessSharedRule.FETCH_FAILED]: TAction<EDomainActionsAccessSharedRule.FETCH_FAILED, { error: DTOErrorGetSharedRule }>,
  [EDomainActionsAccessSharedRule.FETCH_RESET]: TAction<EDomainActionsAccessSharedRule.FETCH_RESET>,
  [EDomainActionsAccessSharedRule.SET_OFFSET]: TAction<EDomainActionsAccessSharedRule.SET_OFFSET, { offset: number }>
  [EDomainActionsAccessSharedRule.SET_SHOW_ONLY_TRADES]: TAction<EDomainActionsAccessSharedRule.SET_SHOW_ONLY_TRADES, { showOnlyTrades: boolean }>
}

export type TDomainActionAccessSharedRule = TDomainActionsAccessSharedRule[keyof TDomainActionsAccessSharedRule]

export const createActionFetchAccessSharedRuleStated = (): TDomainActionsAccessSharedRule[EDomainActionsAccessSharedRule.FETCH_STARTED] => ({
  type: EDomainActionsAccessSharedRule.FETCH_STARTED
})

export const createActionFetchAccessSharedRuleSucceeded = ({ sharedRule }: { sharedRule: DTOSharedRule }): TDomainActionsAccessSharedRule[EDomainActionsAccessSharedRule.FETCH_SUCCEEDED] => ({
  type: EDomainActionsAccessSharedRule.FETCH_SUCCEEDED,
  payload: {
    sharedRule
  }
})

export const createActionFetchAccessSharedRuleFailed = ({ error }: { error: DTOErrorGetSharedRule }): TDomainActionsAccessSharedRule[EDomainActionsAccessSharedRule.FETCH_FAILED] => ({
  type: EDomainActionsAccessSharedRule.FETCH_FAILED,
  payload: {
    error
  }
})

export const createActionSetOffset = ({ offset }: { offset: number }): TDomainActionsAccessSharedRule[EDomainActionsAccessSharedRule.SET_OFFSET] => ({
  type: EDomainActionsAccessSharedRule.SET_OFFSET,
  payload: {
    offset
  }
})

export const createActionSetShowOnlyTrades = ({ showOnlyTrades }: { showOnlyTrades: boolean }): TDomainActionsAccessSharedRule[EDomainActionsAccessSharedRule.SET_SHOW_ONLY_TRADES] => ({
  type: EDomainActionsAccessSharedRule.SET_SHOW_ONLY_TRADES,
  payload: {
    showOnlyTrades
  }
})

export const createActionFetchAccessSharedRuleReset = (): TDomainActionsAccessSharedRule[EDomainActionsAccessSharedRule.FETCH_RESET] => ({
  type: EDomainActionsAccessSharedRule.FETCH_RESET
})

export const actionCreatorsAccessSharedRule = {
  [EDomainActionsAccessSharedRule.FETCH_STARTED]: createActionFetchAccessSharedRuleStated,
  [EDomainActionsAccessSharedRule.FETCH_SUCCEEDED]: createActionFetchAccessSharedRuleSucceeded,
  [EDomainActionsAccessSharedRule.FETCH_FAILED]: createActionFetchAccessSharedRuleFailed,
  [EDomainActionsAccessSharedRule.FETCH_RESET]: createActionFetchAccessSharedRuleReset,
  [EDomainActionsAccessSharedRule.SET_OFFSET]: createActionSetOffset,
  [EDomainActionsAccessSharedRule.SET_SHOW_ONLY_TRADES]: createActionSetShowOnlyTrades
}