import type { DTOFetchConnectExchangeBinanceOAuthCreateApiKeys } from 'v2/domains/connectExchangeBinanceOAuth/DTOs/DTOFetchConnectExchangeBinanceOAuthCreateApiKeys'
import type { Api } from '__generated__/backend.api.client/Api'

export type TDataProviderConnectExchangeBinanceOAuthCreateApiKeys = ReturnType<typeof createDataProviderConnectExchangeBinanceOAuthCreateApiKeys>

export type TErorInRequestBody = {
  authorized: boolean
  message: string
  requestId: string
  status: "ERROR"
  timestamp: string
}

export const createDataProviderConnectExchangeBinanceOAuthCreateApiKeys = <SecurityDataType extends unknown = unknown>({ httpClient }: { httpClient: Api<SecurityDataType> }) => {
  return {
    createApiKeys: async ({ accessToken }: { accessToken: string }): Promise<DTOFetchConnectExchangeBinanceOAuthCreateApiKeys> => {
      const response = await (
        httpClient.instance.post<DTOFetchConnectExchangeBinanceOAuthCreateApiKeys | TErorInRequestBody>(
          process.env.REACT_APP_API_URL + '/user/exchange/binance?access_token=' + accessToken,
        )
      )

      // check for error in the response body
      if((response.data as TErorInRequestBody).status === 'ERROR') {
        const error = new Error((response.data as TErorInRequestBody).message)
        // @ts-ignore
        error.isAxiosError = true
        throw error
      }

      const dtoConnectExchangeBinanceOAuthCreateApiKeys: DTOFetchConnectExchangeBinanceOAuthCreateApiKeys = response.data

      return dtoConnectExchangeBinanceOAuthCreateApiKeys
    }
  }
}