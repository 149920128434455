import { CONDITION_TYPE, SYMBOL_TYPES, TRADE_TYPES } from 'stores/addRule';

export const getHookMessage = (ruleStore) => {
  let found = false;
  let useBuy = false;
  let useSell = false;

  ruleStore.seqInit.sequences.forEach(sequence => {
    let condition;

    if (sequence.t === CONDITION_TYPE) {
      condition = sequence.d;
    } else if (sequence.type === CONDITION_TYPE) {
      condition = sequence.data.conditions[0];
    }

    if (condition) {
      if (condition.ift === SYMBOL_TYPES.SIGNAL) {
        found = true;

        if (condition.in === TRADE_TYPES.BUY) {
          useBuy = true;
        } else if (condition.in === TRADE_TYPES.SELL) {
          useSell = true;
        }
      }
    }
  });

  if (!found) {
    return null;
  }

  const command = 
    (useBuy && !useSell) ? 
    'buy' : 
    (!useBuy && useSell) ?
    'sell' : 
    '{{strategy.order.action}}'
  ;
  const hookId = ruleStore.getTradingViewHookId();

  return `${command} ${hookId}`;
}

const HookMessage = ({ruleStore}) => getHookMessage(ruleStore);

export default HookMessage;