// general types
import type { IActionsInterceptor } from 'v2/types/IActionsInterceptor';

// business logic
import type { TDomainActionsShareRule } from 'v2/domains/shareRule/actionsShareRule'
import type { TDomainActionsAccessSharedRule } from 'v2/domains/accessSharedRule/actionsAccessSharedRule';

import { reducerShareRule } from 'v2/domains/shareRule/stateShareRule';
import { createUseCasesShareRule } from 'v2/domains/shareRule/createUseCasesShareRule';
import { createUseCasesAccessSharedRule, reducerAccessSharedRule } from 'v2/domains/accessSharedRule';
import { createUseCasesLoginDialogForNotAuthenticatedUsers, reducerLoginDialogForNotAuthenticatedUsers } from 'v2/domains/loginDialogForNotAuthenticatedUsers';

// adapters
import { createStore } from './adapters/store/redux/createStore';
import { ActionsInterceptor } from './adapters/effects/ActionsInterceptor';

// infrastructure
import { Api } from '__generated__/backend.api.client/Api'
import http from 'helpers/http';

// data providers
import type { TDataProviderDate } from 'v2/dataProviders/date/createDataProviderDate';
import type { TDataProviderUser } from 'v2/dataProviders/user/createDataProviderUser';
import type { TDataProviderAnalytics } from 'v2/dataProviders/analytics';

import { createDataProviderDate } from 'v2/dataProviders/date/createDataProviderDate';
import { createDataProviderAccessSharedRule } from 'v2/dataProviders/accessSharedRule';
import { createDataProviderUser } from 'v2/dataProviders/user/createDataProviderUser';
import { createDataProviderShareRule } from 'v2/dataProviders/shareRule/createDataProviderShareRule';
import { createDataProviderAnalytics } from 'v2/dataProviders/analytics';
import { createUseCasesConnectExchangeBinanceOAuth } from 'v2/domains/connectExchangeBinanceOAuth/createUseCasesConnectExchangeBinanceOAuth';
import { createDataProviderConnectExchangeBinanceOAuthPrepare } from 'v2/dataProviders/connectExchangeBinanceOAuth/createDataProviderConnectExchangeBinanceOAuthPrepare';
import { createDataProviderConnectExchangeBinanceOAuthGetAccessToken } from 'v2/dataProviders/connectExchangeBinanceOAuth/createDataProviderConnectExchangeBinanceOAuthGetAccessToken';
import { reducerConnectExchangeBinanceOAuth } from 'v2/domains/connectExchangeBinanceOAuth/stateConnectExchangeBinanceOAuth'
import { createDataProviderConnectExchangeBinanceOAuthCreateApiKeys } from 'v2/dataProviders/connectExchangeBinanceOAuth/createDataProviderConnectExchangeBinanceOAuthCreateApiKeys';

// effects

export type TAllActions = TDomainActionsShareRule | TDomainActionsAccessSharedRule;

export type TCoreState = {
  shareRule: ReturnType<typeof reducerShareRule>,
  accessSharedRule: ReturnType<typeof reducerAccessSharedRule>,
  loginDialogForNotAuthenticatedUsers: ReturnType<typeof reducerLoginDialogForNotAuthenticatedUsers>,
  connectExchangeBinanceOAuth: ReturnType<typeof reducerConnectExchangeBinanceOAuth>
}

export const createApplication = () => {
  const dataProviderDate = createDataProviderDate()
  const actionsInterceptor = new ActionsInterceptor()
  const dataProviderUser = createDataProviderUser()
  const dataProviderAnalytics = createDataProviderAnalytics({ mixpanel: window.mixpanel })
  const serviceLocation = window.location

  const httpClientBackend = new Api({
    baseURL: process.env.REACT_APP_API_URL
  })
  httpClientBackend.instance = http

  return createApplicationWithMockableDependencies({
    httpClientBackend,
    actionsInterceptor,
    dataProviderDate,
    dataProviderUser,
    dataProviderAnalytics,
    serviceLocation
  })
}

export const createApplicationWithMockableDependencies =  <SecurityDataType extends unknown = unknown>({
  actionsInterceptor,
  httpClientBackend,
  dataProviderDate,
  dataProviderUser,
  dataProviderAnalytics,
  serviceLocation,
}: {
  httpClientBackend: Api<SecurityDataType>
  actionsInterceptor: IActionsInterceptor
  dataProviderDate: TDataProviderDate
  dataProviderUser: TDataProviderUser
  dataProviderAnalytics: TDataProviderAnalytics
  serviceLocation: { replace: Window['location']['replace'] }
}) => {
  const dataProviderShareRule = createDataProviderShareRule({ httpClient: httpClientBackend });
  const dataProviderAccessSharedRule = createDataProviderAccessSharedRule({ httpClient: httpClientBackend, dataProviderDate });
  const dataProviderConnectExchangeBinanceOAuthPrepare = createDataProviderConnectExchangeBinanceOAuthPrepare({ httpClient: httpClientBackend });
  const dataProviderConnectExchangeBinanceOAuthGetAccessToken = createDataProviderConnectExchangeBinanceOAuthGetAccessToken({ httpClient: httpClientBackend });
  const dataProviderConnectExchangeBinanceOAuthCreateApiKeys = createDataProviderConnectExchangeBinanceOAuthCreateApiKeys({ httpClient: httpClientBackend });

  const store = createStore<TCoreState>({
    reducers: {
      shareRule: reducerShareRule,
      accessSharedRule: reducerAccessSharedRule,
      loginDialogForNotAuthenticatedUsers: reducerLoginDialogForNotAuthenticatedUsers,
      connectExchangeBinanceOAuth: reducerConnectExchangeBinanceOAuth,
    },
    actionsInterceptor
  })
  
  const dispatch = store.dispatch
  const getState = store.getState

  const useCasesShareRule = createUseCasesShareRule({ dataProviderAnalytics, dataProviderShareRule, dispatch, getState: () => getState().shareRule })
  const useCasesAccessSharedRule = createUseCasesAccessSharedRule({ dataProviderAccessSharedRule, dispatch, getState: () => getState().accessSharedRule })
  const useCasesLoginDialogForNotAuthenticatedUsers = createUseCasesLoginDialogForNotAuthenticatedUsers({ dataProviderUser, dispatch, getState: () => getState().loginDialogForNotAuthenticatedUsers})
  const useCasesConnectExchangeBinanceOAuth = createUseCasesConnectExchangeBinanceOAuth({ serviceLocation, dataProviderConnectExchangeBinanceOAuthCreateApiKeys, dataProviderConnectExchangeBinanceOAuthPrepare, dataProviderConnectExchangeBinanceOAuthGetAccessToken, dispatch, getState: () => getState().connectExchangeBinanceOAuth})
  
  return {
    store,
    actionsInterceptor,
    useCases: {
      shareRule: useCasesShareRule,
      accessSharedRule: useCasesAccessSharedRule,
      loginDialogForNotAuthenticatedUsers: useCasesLoginDialogForNotAuthenticatedUsers,
      connectExchangeBinanceOAuth: useCasesConnectExchangeBinanceOAuth,
    }
  }
}
