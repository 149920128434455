import React from 'react';
import './style.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/chevron-left.svg';
import { ReactComponent as OpenIcon } from '../../../assets/images/icons/chevron-right.svg';

const DataStudioPageToggler = ({ isOpen, onClick, isOnAddRulePage }) => {
  return (
    <div
      className={`DataStudioPage-toggler
      ${isOnAddRulePage ? 'DataStudioPage-toggler--isOnAddRulePage' : ''}
      ${!isOpen ? 'DataStudioPage-toggler--close' : ''}`}
      role='button'
      onClick={onClick}
    >
      {isOpen ? (
        <CloseIcon className='icon' data-testid='close-icon' />
      ) : (
        <OpenIcon className='icon' data-testid='open-icon' />
      )}

      <p className='DataStudioPage-toggler__text'>Data Studio</p>
    </div>
  );
};

export default DataStudioPageToggler;
