import React from 'react'

import type { TypographyProps } from '@mui/material'

import { useTheme, Typography } from '@mui/material'

export interface CodeProps extends TypographyProps {}

const testId = 'Code'
export const Code = (props: CodeProps) => {
  const {
    sx,
    ...otherProps
  } = props

  const theme = useTheme()

  return (
    <Typography
      data-testid={testId}
      className={testId}
      component={'span'}
      sx={{
        margin: '0 3px',
        padding: '3px 5px 1px',
        fontSize: '85%',
        wordWrap: 'normal',
        backgroundColor: theme.palette.coinrule.basic.greyBackgroundColor,
        border: `1px solid ${theme.palette.coinrule.basic.greyBorder}`,
        borderRadius: '3px',
        ...sx
      }}
      {...otherProps}
    />
  )
}

export default Code
