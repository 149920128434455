import { action, observable, runInAction } from 'mobx';
import { errorNotification } from '../helpers/notification';
import stores from '../stores';
import http from '../helpers/http';
import { calculateTotalWalletsAmount } from '../helpers/utils';
import { AbstractStore } from './AbstractStore';

export default class Wallets extends AbstractStore {
  constructor(rootStore) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  @observable walletsVisibleColumns = 0;
  @observable walletsMaxPossibleShift = 0;
  @observable walletsActualColumnsNum = 0;
  @observable walletsListShift = 0;

  @observable isWalletsListExpanded = false;
  @observable highlightedWallet = 0;
  @observable openedWalletsListId = -2;
  @observable totalWalletsValue = 0;
  @observable isLoadingWallets = false;
  @observable isSyncWallets = false;
  @observable syncExchangeBalancesDone = [];
  @observable syncExchangeAssetsDone = [];
  @observable syncUpdateRunning = false;
  @observable showDemoExchange = true;

  @action
  toggleVisibilityDemoExchange = () => {
    this.showDemoExchange = !this.showDemoExchange;
    this.walletsListShift = 0;
  };

  @action
  setVisibilityDemoExchange = (visibility) => {
    this.showDemoExchange = visibility;
    this.walletsListShift = 0;
  };

  @action setTotalWalletsValue = (newValue) => (this.totalWalletsValue = newValue);
  @action setHighlightedWallet = (newValue) => (this.highlightedWallet = newValue);
  @action setIsWalletsListExpanded = (newValue) => (this.isWalletsListExpanded = newValue);
  @action setOpenedWalletsListId = (newValue) => (this.openedWalletsListId = newValue);
  @action setWalletsVisibleColumns = (newValue) => (this.walletsVisibleColumns = newValue);
  @action setWalletsActualColumnsNum = (newValue) => (this.walletsActualColumnsNum = newValue);
  @action setWalletsListShift = (newValue) => (this.walletsListShift = newValue);
  @action setWalletsMaxPossibleShift = (newValue) => (this.walletsMaxPossibleShift = newValue);

  @action
  async syncUserExchanges() {
    if (this.isSyncWallets) {
      return;
    }

    try {
      this.isSyncWallets = true;
      this.syncUpdateRunning = true;
      const resp = await http.get(`/user/syncExchanges`);

      if (resp.data.status === 'OK') {
        const syncUpdateSuccess = resp.data.data.syncUpdateSuccess;
        if (syncUpdateSuccess) {
          stores.userInfo.isSyncedBalances = [];
          await this.getWallets();
        } else {
          errorNotification(`Something has gone wrong. Please try it again later.`);
        }
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
    } finally {
      this.isSyncWallets = false;
      this.syncUpdateRunning = false;
    }
  }

  @action
  async getWallets(force) {
    if (this.isLoadingWallets && !force) {
      return;
    } else {
      this.isLoadingWallets = true;
    }

    try {
      if (!stores.userInfo.isLoadedExchanges) {
        await stores.userInfo.getExchanges(true);
      }
      if (stores.userInfo.exchanges.length > 0) {
        for (const exchange of stores.userInfo.exchanges) {
          await stores.exchangeInfo.getAssets(exchange?.id, true);
          await stores.userInfo.getBalances(exchange?.id, true);
        }

        runInAction(() => {
          this.isLoadingWallets = false;
          this.totalWalletsValue = calculateTotalWalletsAmount();
        });
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
    } finally {
      this.isLoadingWallets = false;
    }
  }

  // Returns biggest number of wallets among all exchanges in walletsList array
  // EXAMPLE: If exchangeX have 5 wallets and exhangeY have 2 wallets
  // than this function will return number 5
  @action
  maxWalletsCoins = () => {
    if (stores.userInfo.isLoadedBalances.length > 0) {
      const numbersOfWallets = [];

      for (const i in stores.userInfo.balances) {
        const balance = stores.userInfo.balances[i].data;
        numbersOfWallets.push(balance.length);
      }

      return Math.max(...numbersOfWallets);
    }

    return 4;
  };

  @action
  changeSyncUpdateStatus(newStatus) {
    this.syncUpdateRunning = newStatus;
  }

  @action
  addToSyncExchangeBalancesDone(exchangeId) {
    this.syncExchangeBalancesDone.push(exchangeId);
  }

  @action setSyncExchangeBalancesDone = (newValue) => (this.syncExchangeBalancesDone = newValue);
  @action setSyncExchangeAssetsDone = (newValue) => (this.syncExchangeAssetsDone = newValue);

  @action
  addToSyncExchangeAssetsDone(exchangeId) {
    this.syncExchangeAssetsDone.push(exchangeId);
  }

  async handleSyncWallets() {
    this.setSyncExchangeBalancesDone([]);
    this.setSyncExchangeAssetsDone([]);
    await this.syncUserExchanges();
  }
}
