import React, { Component } from 'react';
import { Tooltip } from 'antd';

import './style.scss';

class Popover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      isMobile: window.innerWidth <= 575,
    };
  }

  showPopover() {
    setTimeout(() => {
      this.setState({
        visible: true,
      });
    }, 300);
  }

  hidePopover() {
    setTimeout(
      () => {
        this.setState({
          visible: false,
        });
      },
      this.state.isMobile ? 1000 : 300
    );
  }

  evalOnClickFromProps() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    if (this.props.hidden) {
      return this.props.children;
    }

    const child = React.cloneElement(this.props.children, {
      onFocus: () => this.hidePopover(),
    });

    return (
      <Tooltip
        title={this.props.text}
        placement={this.props.placement || 'top'}
        visible={this.state.visible}
        onMouseEnter={() => this.showPopover()}
        onMouseLeave={() => this.hidePopover()}
        onClick={() => {
          this.hidePopover();
          this.evalOnClickFromProps();
        }}
      >
        {child}
      </Tooltip>
    );
  }
}

export default Popover;
