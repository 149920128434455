import React from 'react'

import {  Input } from 'antd';
import blankIcon from '../../assets/images/settings-blank.svg';
import wrongIcon from '../../assets/images/settings-wrong.svg';
import successfulIcon from '../../assets/images/settings-page-checkmark.svg';

export interface ConnectTelegramProps {
  isLoading: boolean,
  telegramId: string,
  onChangeCallback?: (newTelegramId:  string) => void
  children?: (newTelegramId: string) => React.ReactNode
}

export const ConnectTelegram = (props: ConnectTelegramProps) => {
  const {
    isLoading,
    telegramId,
    onChangeCallback,
    children
  } = props


  const [currentValue, setCurrentValue] = React.useState<string>(telegramId)

  React.useEffect(() => {
    setCurrentValue(telegramId)
  }, [telegramId])


  const validateTelegramId = React.useCallback((telegramId: string) => {
    const isNumbersOnly = /^\d+$/.test(telegramId);

    if (telegramId?.length > 0 && !isNumbersOnly) {
      return false;
    }
    return true;
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;

    if(validateTelegramId(value)) {
      setCurrentValue(e.target.value)
      onChangeCallback?.(value)
    }
  }

  return (
    <ul className={`tradingLayoutSubCell__tutorialList`}>
      <li className={`tradingLayoutSubCell__tutorialListItem`}>
        1. On Telegram, search for{` `}
        <a href='https://t.me/coinrulebot' target='_blank' rel='noreferrer noopener'>
          <b>CoinruleBot</b>
        </a>
      </li>
      <li className={`tradingLayoutSubCell__tutorialListItem`}>
        2. Press Start and Receive the Code(chat ID)
      </li>
      <li className={`tradingLayoutSubCell__tutorialListItem`}>
        3. Simply paste here below the Code you received
      </li>
      <div
        className={`tradingLayoutCell__inputWrapper
                      tradingLayoutCell__inputWrapper_wide`}>
        <Input
          onChange={handleChange}
          style={{ width: '80%', margin: '1em 0' }}
          value={currentValue}
          placeholder={!isLoading ? 'Telegram Code' : 'Loading...'}
          suffix={
            telegramId &&
            validateTelegramId(telegramId) === true ? (
              <img src={successfulIcon} alt='successful-checkmark' />
            ) : validateTelegramId(telegramId) === false ? (
              <img src={wrongIcon} alt='wrong' />
            ) : (
              <img src={blankIcon} alt='' />
            )
          }
        />

        {children?.(currentValue)}
      </div>
      Need Help?{' '}
      <a
        href='https://youtu.be/xphIvic0D_E'
        target='_blank'
        rel='noopener noreferrer'>
        Watch a Guide
      </a>
    </ul>
  )
}

export default ConnectTelegram
