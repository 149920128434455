import type { TAction } from 'v2/types/TAction'
import type { DTOFetchConnectExchangeBinanceOAuthCreateApiKeys } from './DTOs/DTOFetchConnectExchangeBinanceOAuthCreateApiKeys'
import type { DTOErrorFetchConnectExchangeBinanceOAuthCreateApiKeys } from './DTOs/DTOErrorFetchConnectExchangeBinanceOAuthCreateApiKeys'

export enum EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys {
  CREATE_API_KEYS_FETCH_STARTED = 'domains/connectExchangeBinanceOAuth/create_api_keys/FETCH_STARTED',
  CREATE_API_KEYS_FETCH_SUCCEEDED = 'domains/connectExchangeBinanceOAuth/create_api_keysFETCH_SUCCEEDED',
  CREATE_API_KEYS_FETCH_FAILED = 'domains/connectExchangeBinanceOAuth/create_api_keys/FETCH_FAILED',
  CREATE_API_KEYS_FETCH_BOUNCED = 'domains/connectExchangeBinanceOAuth/create_api_keys/FETCH_BOUNCED',
}

export type TDomainActionsConnectExchangeBinanceOAuthCreateApiKeys = {
  [EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_STARTED]: TAction<EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_STARTED>,
  [EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_SUCCEEDED]: TAction<EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_SUCCEEDED, { dto: DTOFetchConnectExchangeBinanceOAuthCreateApiKeys }>,
  [EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_FAILED]: TAction<EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_FAILED, { error: DTOErrorFetchConnectExchangeBinanceOAuthCreateApiKeys }>,
  [EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_BOUNCED]: TAction<EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_BOUNCED, { reason: string }>,
}

export type TDomainActionConnectExchangeBinanceOAuthCreateApiKeys = TDomainActionsConnectExchangeBinanceOAuthCreateApiKeys[keyof TDomainActionsConnectExchangeBinanceOAuthCreateApiKeys]

export const createActionFetchConnectExchangeBinanceOAuthCreateApiKeysStated = (): TDomainActionsConnectExchangeBinanceOAuthCreateApiKeys[EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_STARTED] => ({
  type: EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_STARTED
})

export const createActionFetchConnectExchangeBinanceOAuthCreateApiKeysSucceeded = ({ dto }: { dto: DTOFetchConnectExchangeBinanceOAuthCreateApiKeys }): TDomainActionsConnectExchangeBinanceOAuthCreateApiKeys[EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_SUCCEEDED] => ({
  type: EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_SUCCEEDED,
  payload: {
    dto
  }
})

export const createActionFetchConnectExchangeBinanceOAuthCreateApiKeysFailed = ({ error }: { error: DTOErrorFetchConnectExchangeBinanceOAuthCreateApiKeys }): TDomainActionsConnectExchangeBinanceOAuthCreateApiKeys[EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_FAILED] => ({
  type: EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_FAILED,
  payload: {
    error
  }
})

export const createActionFetchConnectExchangeBinanceOAuthCreateApiKeysBounced = ({ reason }:  { reason: string }): TDomainActionsConnectExchangeBinanceOAuthCreateApiKeys[EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_BOUNCED] => ({
  type: EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_BOUNCED,
  payload: {
    reason
  }
})

export const actionCreatorsConnectExchangeBinanceOAuthCreateApiKeys = {
  [EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_STARTED]: createActionFetchConnectExchangeBinanceOAuthCreateApiKeysStated,
  [EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_SUCCEEDED]: createActionFetchConnectExchangeBinanceOAuthCreateApiKeysSucceeded,
  [EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_FAILED]: createActionFetchConnectExchangeBinanceOAuthCreateApiKeysFailed,
  [EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_BOUNCED]: createActionFetchConnectExchangeBinanceOAuthCreateApiKeysBounced,
}
