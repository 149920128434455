import React from 'react'

export interface IconListWithBulletsFullProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconListWithBulletsFull'
export const IconListWithBulletsFull = (props: IconListWithBulletsFullProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      xmlns={"http://www.w3.org/2000/svg"}
      width={"1em"}
      height={"1em"}
      viewBox={"0 0 504 440"}
      {...otherProps}
    >
      <path
        d={"M479.708 439.857H202.27a23.556 23.556 0 0 1-23.556-23.557v-78.518a23.556 23.556 0 0 1 23.556-23.557h277.438a23.557 23.557 0 0 1 23.557 23.557V416.3a23.557 23.557 0 0 1-23.557 23.557Zm0-157.041H202.27a23.556 23.556 0 0 1-23.556-23.557v-78.521a23.554 23.554 0 0 1 23.556-23.554h277.438a23.555 23.555 0 0 1 23.557 23.554v78.521a23.557 23.557 0 0 1-23.557 23.557Zm0-157.041H202.27a23.554 23.554 0 0 1-23.556-23.557V23.7A23.556 23.556 0 0 1 202.27.143h277.438A23.557 23.557 0 0 1 503.265 23.7v78.521a23.555 23.555 0 0 1-23.557 23.554ZM123.75 439.857H24.291A23.557 23.557 0 0 1 .735 416.3v-78.518a23.557 23.557 0 0 1 23.556-23.557h99.459a23.557 23.557 0 0 1 23.556 23.557V416.3a23.557 23.557 0 0 1-23.556 23.557Zm0-157.041H24.291A23.557 23.557 0 0 1 .735 259.259v-78.521a23.554 23.554 0 0 1 23.556-23.554h99.459a23.554 23.554 0 0 1 23.556 23.554v78.521a23.557 23.557 0 0 1-23.556 23.557Zm0-157.041H24.291A23.555 23.555 0 0 1 .735 102.218V23.7A23.556 23.556 0 0 1 24.291.143h99.459A23.557 23.557 0 0 1 147.306 23.7v78.521a23.555 23.555 0 0 1-23.556 23.554Z"}
        style={{
          fillRule: "evenodd",
        }}
      />
    </svg>
  )
}

export default IconListWithBulletsFull
