import React, { PropsWithChildren } from 'react';
// These styles aren't in use right now. We use styles from /src/styles/buttons.scss
// In future this buttons.scss file should be migrated to local style.scss for this component.
import './style.scss';

// Matches fill types in buttons.scss, className: `coinruleBut_{fillType}`
type Size = 'small' | 'medium' | 'big' | 'xl';

// Matches colors in buttons.scss, className: `coinruleBut_{color}`
type Color =
  | 'crimson'
  | 'violet'
  | 'white'
  | 'gray'
  | 'lightGray'
  | 'lightGray2'
  | 'orange'
  | 'deepDarkBlue'
  | 'green'
  | 'lightGreen';

// Matches fill types in buttons.scss, className: `coinruleBut_{fillType}`
type FillType = 'solid' | 'outline' | '';

// Matches icons in buttons.scss, className: `coinruleBut_icon{icon}`
// DO NOT FORGET ABOUT THE &White ICONS!
type Icon =
  | 'Connect'
  | 'Check'
  | 'CheckWhite'
  | 'Lock'
  | 'Plus'
  | 'Spinner'
  | 'CaretRight'
  | 'Exit'
  | 'HelpCenter'
  | 'Logout'
  | 'LogoutWhite'
  | 'List'
  | 'Edit'
  | 'Demo';

export type HtmlWrapper = 'button' | 'a' | 'div';

// Matches icons in buttons.scss, className: `coinruleBut_icon{icon}--{iconPosition}`
type TIconPosition = 'right' | 'left';

export interface IButtonProps<C extends React.ElementType | HtmlWrapper> {
  size?: Size;
  color?: Color;
  fillType?: FillType;
  icon?: Icon;
  disabled?: boolean;
  loading?: boolean;
  component?: C;
  className?: string;
  withGlow?: boolean;
  iconPosition?: TIconPosition;
  ariaLabel?: string;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export type TCoinruleButtonProps<C extends React.ElementType | HtmlWrapper> = PropsWithChildren<
  IButtonProps<C>
> &
  React.ComponentProps<C>;

export const CoinruleButton = function <C extends React.ElementType | HtmlWrapper>({
  size = 'medium',
  color = 'crimson',
  fillType,
  icon,
  disabled,
  loading,
  component,
  className,
  children,
  withGlow,
  iconPosition,
  ariaLabel,
  ...props
}: TCoinruleButtonProps<C>) {
  const classNames = [`coinruleBut`];
  classNames.push(...[size, color, fillType].map((item) => `coinruleBut_${item}`).filter(Boolean));
  if (icon) classNames.push(`coinruleBut_icon${icon}`);
  if (iconPosition) classNames.push(`coinruleBut_icon${icon}--${iconPosition}`);
  if (disabled) classNames.push(`coinruleBut_disabled`);
  if (loading) classNames.push(`coinruleBut_iconLoading`);
  if (withGlow) classNames.push(`coinruleBut_withGlow`);

  let FinalComponent: React.ComponentType;
  switch (component) {
    case 'a':
      FinalComponent = AWrapper;
      break;
    case 'div':
      FinalComponent = DivWrapper;
      break;
    case 'button':
      FinalComponent = ButtonWrapper;
      break;
    default:
      FinalComponent = (component as React.ComponentType) || DivWrapper; // TODO Change css, so buttons is not broken -> Better accessibility
  }

  return (
    <FinalComponent
      disabled={disabled}
      {...props}
      className={`${classNames.join(' ')} ${className}`}
      {...(ariaLabel && {
        'aria-label': ariaLabel,
      })}
    >
      {children}
    </FinalComponent>
  );
};

const ButtonWrapper: React.FC<JSX.IntrinsicElements['button']> = ({ children, ...props }) => (
  <button {...props}>{children}</button>
);
const DivWrapper: React.FC<JSX.IntrinsicElements['div']> = ({ children, ...props }) => (
  <div {...props}>{children}</div>
);
const AWrapper: React.FC<JSX.IntrinsicElements['a']> = ({ children, ...props }) => (
  <a {...props}>{children}</a>
);
