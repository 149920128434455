import type { DTOFetchConnectExchangeBinanceOAuthPrepare } from 'v2/domains/connectExchangeBinanceOAuth/DTOs/DTOFetchConnectExchangeBinanceOAuthPrepare'
import type { Api } from '__generated__/backend.api.client/Api'

export type TDataProviderConnectExchangeBinanceOAuthPrepare = ReturnType<typeof createDataProviderConnectExchangeBinanceOAuthPrepare>

export const createDataProviderConnectExchangeBinanceOAuthPrepare = <SecurityDataType extends unknown = unknown>({ httpClient }: { httpClient: Api<SecurityDataType> }) => {
  return {
    prepare: async (): Promise<DTOFetchConnectExchangeBinanceOAuthPrepare> => {
      const redirectURL = process.env.REACT_APP_API_URL + '/auth/oauth/binance/prepare'

      return { redirectURL }
    }
  }
}