import type { PublicRuleView } from "__generated__/backend.api.client/Api"

export enum EModelRuleStatus { 
  DRAFT = 'draft', 
  ACTIVE = 'active',
  PAUSED = 'paused', 
  FINISHED = 'finished', 
  ARCHIVED = 'archived', 
  EASY_ERROR = 'easy_error', 
  FATAL_ERROR = 'fatal_error',
}

export enum EModelRuleTriggers { 
  TIME = 'time', 
  EVENT = 'event',
  DIRECT_ORDER = 'directOrder', 
}

export type TModelRuleExchange = 'poloniex' | 'bittrex' | 'okex' | 'binance-us' | 'hitbtc' | 'kraken' | 'coinbasepro' | 'bitstamp' | 'bitmex' | 'liquid' | 'binance' | 'binance-futures' | 'bitpanda' | 'demo' | 'bitfinex' | 'deribit' | 'kucoin' 

export type TModelSharedRule = Omit<PublicRuleView, 'id' | 'repeats' | 'trigger' | 'status' | 'exchangeUid'> & {
  status: EModelRuleStatus,
  trigger: EModelRuleTriggers,
  exchangeUid: TModelRuleExchange,
}