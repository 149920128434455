import React from 'react';

const BINANCE_REFERRAL = 'https://www.binance.com/?ref=35796183';

export interface IExchangePromo {
  promoUrl?: string;
}

const ExchangePromo: React.FC<IExchangePromo> = ({
  promoUrl = BINANCE_REFERRAL,
}) => {
  return (
    <>
      <div className='exchange-promo'>
        Don't have any exchange account yet?{' '}
        <a href={promoUrl} target='_blank' rel='noopener noreferrer'>
          Create one for free.
        </a>
      </div>
    </>
  );
};

export default ExchangePromo;
