import React from 'react'

import type { BoxProps} from '@mui/material';

import { useTheme, Box, Typography } from '@mui/material'

export interface PaletteColorProps extends BoxProps {
  color: React.CSSProperties['backgroundColor']
  colorName: string
}

const testId = 'PaletteColor'
export const PaletteColor = (props: PaletteColorProps) => {
  const {
    sx,
    color,
    colorName,
    ...otherProps
  } = props

  const theme = useTheme()

  return (
    <Box
      data-testid={testId}
      className={testId}
      sx={{
        ...sx
      }}
      {...otherProps}
    >
      <Box
        sx={{
          backgroundColor: color,
          width: 260,
          height: 60,
          borderRadius: 4
        }}
      >
      </Box>
      <Typography
        sx={{
          fontSize: 18,
          fontWeight: 'bold',
          color: theme.palette.coinrule.basic.black
        }}
      >
        {colorName}
      </Typography>
      <Typography
        sx={{
          fontSize: 14,
          color: theme.palette.coinrule.basic.grey
        }}
      >
        {color}
      </Typography>
    </Box>
  )
}

export default PaletteColor
