import { observer } from 'mobx-react';
import React from 'react';
import {
  ACTION_TYPE,
  CONDITION_OPERATORS,
  CONDITION_TYPE,
  SYMBOL_TYPES,
  CONDITION_TYPES,
  OPERATOR_TYPE,
  OPERATOR_TYPES,
  PERCENTAGE_SYMBOL,
  TRADE_TYPES,
  TRIGGERS,
  NOTIFY_TYPE,
} from '../../../../stores/addRule';
import Popover from '../../../../components/Popover/index';
import {
  canBeRender,
  checkItemInParent,
  formattedCounter,
  getFieldCssClass,
  getIndicator,
  getOperator,
  getPeriodName,
  setDataLocalStorage,
} from '../../../../helpers/utils';
import stores from '../../../../stores/index';
import { Input, Select } from 'antd';
import Tooltip from '../../Tooltip/index';
import ShowCoinsValue, { checkIfShowCoinValue } from '../../ShowCoinsValue/index';
import Orand from '../../Trigger/Orand/index';
import ErrorBlock from '../../../../components/Sequences/ErrorBlock/index';
import { EXCHANGES_TYPE } from '../../../../constants/exchanges';
import './style.scss';
import { ReactComponent as AddIcon } from 'assets/images/icons/CoinMultipleCoinSelectAddIcon.svg';
import { toJS } from 'mobx';
import { isNegativeNumber, makeDotDecimals } from 'helpers/helpers';

const analytics = window.mixpanel;

const { Option, OptGroup } = Select;

@observer
class ConditionItem extends React.Component {
  constructor(props) {
    super(props);

    this.coinListSelectRef = React.createRef();
  }

  componentDidMount() {
    const { sequenceIndex } = this.props;

    if (sequenceIndex === 0) {
      this.handleToggleCoinListSelectOpen(0, 0);
    }
  }

  componentDidUpdate() {
    const { sequenceIndex } = this.props;

    if (sequenceIndex === 0) {
      this.handleCloseSelectOnDocumentClick(0, 0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', (event) => {
      this.selectCloseHandler({ event, sequenceIndex: 0, index: 0 });
    });
  }

  getSelectProps = (sequenceIndex, index) => {
    const { rule } = this.props.store;
    const selectsProps = toJS(this.props.store.selectsProps);

    return selectsProps[rule.tr + sequenceIndex] && selectsProps[rule.tr + sequenceIndex][index];
  };

  handleToggleCoinListSelectOpen = (sequenceIndex = 0, index = 0) => {
    const coinListSelect = this.coinListSelectRef?.rcSelect?.rootRef;

    const { store } = this.props;

    coinListSelect.addEventListener('click', () => {
      const { allowSelectingMultipleCoins, isOpen } =
        this.getSelectProps(sequenceIndex, index) || {};

      this.focusOnCoinListSelectInput();

      if (allowSelectingMultipleCoins) {
        if (!isOpen) {
          store.setSelectsProps({ sequenceIndex, index, updates: { isOpen: true } });
        }
      } else {
        store.setSelectsProps({ sequenceIndex, index, updates: { isOpen: !isOpen } });
      }
    });
  };

  selectCloseHandler = ({ event: { target }, index, sequenceIndex }) => {
    const coinListSelect = document.querySelector(`#CoinListSelect_${sequenceIndex}_${index}`);
    const coinListSelectDropdown = document.querySelector('.CoinListSelect__dropdown');

    const { isOpen } = this.getSelectProps(0, 0) || {};

    if (isOpen) {
      if (
        checkItemInParent(coinListSelect, target) ||
        checkItemInParent(coinListSelectDropdown, target)
      )
        return;

      this.props.store.setSelectsProps({
        sequenceIndex,
        index,
        updates: { isOpen: false },
      });
    }
  };

  handleCloseSelectOnDocumentClick = (sequenceIndex = 0, index = 0) => {
    document.addEventListener('click', (event) => {
      const coinListSelectDropdown = document.querySelector('.CoinListSelect__dropdown');

      if (coinListSelectDropdown) {
        this.selectCloseHandler({
          event,
          sequenceIndex,
          index,
        });
      }
    });
  };

  getCssClass() {
    let cls = ['newRuleFormSection content'];

    if (this.props.store.rule.tr === TRIGGERS.TIME) {
      cls.push('time-trigger');
    }

    return cls.join(' ');
  }

  renderLogicalConditionDesc(sequenceIndex) {
    if (sequenceIndex > 0) {
      const previousElement = this.props.store.seqInit.getPreviousElement(sequenceIndex);
      if (previousElement.type === CONDITION_TYPE) {
        const op = this.props.item.co === CONDITION_OPERATORS.OR ? 'Or' : 'And';

        return <div className='logical-condition-if'>{op}</div>;
      }
    }

    return null;
  }

  renderLogicalConditionSelect(index, sequenceIndex) {
    if (sequenceIndex > 0) {
      const previousElement = this.props.store.seqInit.getPreviousElement(sequenceIndex);
      if (previousElement.type === CONDITION_TYPE) {
        return (
          <Orand
            index={index}
            store={this.props.store}
            conditionIndex={sequenceIndex}
            conditionId={this.props.conditionId}
          />
        );
      }
    }

    return null;
  }

  shouldBeDisabled(isLeverageExchange, optionsLength) {
    if (this.props.store.isQuickEdit()) {
      return true;
    }

    if (!isLeverageExchange) {
      return false;
    }

    return optionsLength === 1;
  }

  updateSelectProps = ({
    sequenceIndex,
    index,
    updates = { allowSelectingMultipleCoins: false, isOpen: false },
    store = this.props.store,
  }) => {
    store.setSelectsProps({
      sequenceIndex,
      index,
      updates,
    });
  };

  renderCoinListSelect(index, sequenceIndex) {
    let options = null;
    let dropdownOptions = [];
    let advancedOptions = [];
    let allActionIndexes = [];
    let skipCoin = [];
    let skipCoinHelper = {};
    let startIndex;
    let previousConditionBlockIndex;
    let stop;
    let thatCoinAdded = false;
    let defaultValue = '---';
    const isLeverageExchange =
      stores.exchangeInfo.exchangeType[this.props.store.rule.ex] === EXCHANGES_TYPE.LEVERAGE;

    if (
      this.props.item.ift !== SYMBOL_TYPES.COIN &&
      this.props.item.ift !== SYMBOL_TYPES.RULE &&
      this.props.item.ift !== SYMBOL_TYPES.SIGNAL
    ) {
      return null;
    }

    const coinOrInstrument =
      this.props.parent.checkIfExchangeLoaded() && isLeverageExchange ? 'instrument' : 'coin';

    const thenIndex = this.props.store.seqInit.findIndexSequenceBeforeByType(
      OPERATOR_TYPE,
      sequenceIndex,
      OPERATOR_TYPES.THEN
    );
    const parallelIndex = this.props.store.seqInit.findIndexSequenceBeforeByType(
      OPERATOR_TYPE,
      sequenceIndex,
      OPERATOR_TYPES.PARALLEL
    );
    const parallelIndexAfter = this.props.store.seqInit.findIndexSequenceAfterByType(
      OPERATOR_TYPE,
      sequenceIndex,
      OPERATOR_TYPES.PARALLEL
    );
    const orIndex = this.props.store.seqInit.findIndexSequenceBeforeByType(
      OPERATOR_TYPE,
      sequenceIndex,
      OPERATOR_TYPES.OR
    );
    const waitIndex = this.props.store.seqInit.findIndexSequenceBeforeByType(
      OPERATOR_TYPE,
      sequenceIndex,
      OPERATOR_TYPES.WAIT
    );
    let conditionIndex = this.props.store.seqInit.findIndexSequenceBeforeByType(
      CONDITION_TYPE,
      sequenceIndex
    );

    const getPreviousBlock = (index = sequenceIndex) => this.props.store.seqInit.getPreviousElement(index)

    const previousBlock = getPreviousBlock();
    const isPrevActionNotify = getPreviousBlock(sequenceIndex - 1)?.type === NOTIFY_TYPE;

    if (
      previousBlock &&
      (OPERATOR_TYPE === previousBlock.type || previousBlock.type === CONDITION_TYPE)
    ) {
      if (
        OPERATOR_TYPE === previousBlock.type &&
        [OPERATOR_TYPES.THEN, OPERATOR_TYPES.PARALLEL].indexOf(previousBlock.data.operators[0].t) >
        -1
      ) {
        startIndex = thenIndex;

        if (thenIndex === -1) {
          startIndex = parallelIndex;
        }

        stop = 0;
        if (conditionIndex > -1) {
          stop = conditionIndex;
        }

        allActionIndexes = this.props.store.seqInit.findAllIndexesSequenceBeforeByType(
          ACTION_TYPE,
          startIndex,
          stop
        );
      } else if (
        OPERATOR_TYPE === previousBlock.type &&
        OPERATOR_TYPES.WAIT === previousBlock.data.operators[0].t
      ) {
        startIndex = waitIndex;
        stop = 0;

        if (orIndex > -1) {
          stop = orIndex;
        } else if (parallelIndex > -1) {
          stop = parallelIndex;
        } else if (thenIndex > -1) {
          stop = thenIndex;
        }

        allActionIndexes = this.props.store.seqInit.findAllIndexesSequenceBeforeByType(
          ACTION_TYPE,
          startIndex,
          stop
        );

        if (allActionIndexes.length === 0) {
          previousConditionBlockIndex = conditionIndex;
        }
      } else if (
        (OPERATOR_TYPE === previousBlock.type &&
          OPERATOR_TYPES.OR === previousBlock.data.operators[0].t) ||
        previousBlock.type === CONDITION_TYPE
      ) {
        if (parallelIndex > -1) {
          startIndex = parallelIndex;
          stop = this.props.store.seqInit.findIndexSequenceBeforeByType(CONDITION_TYPE, startIndex);
        } else if (thenIndex > -1) {
          startIndex = thenIndex;
          stop = this.props.store.seqInit.findIndexSequenceBeforeByType(CONDITION_TYPE, startIndex);
        } else if (conditionIndex > -1) {
          startIndex = conditionIndex;
        }

        if (stop === -1) {
          stop = 0;
        }

        allActionIndexes = this.props.store.seqInit.findAllIndexesSequenceBeforeByType(
          ACTION_TYPE,
          startIndex,
          stop
        );
      }
    }

    if (allActionIndexes.length > 0) {
      for (let i in allActionIndexes) {
        let text = formattedCounter(Number(i) + 1);

        if (
          !isLeverageExchange &&
          this.props.store.seqInit.sequences[allActionIndexes[i]].data.actions[0].ort === 'base' &&
          stores.exchangeInfo.assets[this.props.store.rule.ex] &&
          stores.exchangeInfo.assets[this.props.store.rule.ex].length > 0
        ) {
          let coin = this.props.store.seqInit.sequences[allActionIndexes[i]].data.actions[0].b[0];
          const assetObj = stores.exchangeInfo.assets[this.props.store.rule.ex][coin];

          if (coin !== '---') {
            const name =
              assetObj && assetObj.name?.toUpperCase() === coin
                ? coin
                : `${coin} ${assetObj?.name}`;
            skipCoin.push(coin);
            skipCoinHelper[coin] = 'from_sequence_' + allActionIndexes[i];
            dropdownOptions.push(
              <Option title='' value={'from_sequence_' + allActionIndexes[i]} key={i}>
                {name}
              </Option>
            );
          }
        } else if (
          //this.props.store.seqInit.sequences[allActionIndexes[i]].data.actions[0].ort === 'base' &&
          isLeverageExchange &&
          this.props.store.instrumentQuote &&
          stores.exchangeInfo.instruments[this.props.store.rule.ex] &&
          stores.exchangeInfo.instruments[this.props.store.rule.ex].length > 0
        ) {
          //let coin = this.props.store.seqInit.sequences[allActionIndexes[i]].data.actions[0].b[0];
          let coin = this.props.store.instrumentQuote;
          const instrObj = stores.exchangeInfo.instruments[this.props.store.rule.ex].find(
            (a) => a.symbol === coin
          );

          const name = instrObj && instrObj.symbol.toUpperCase();
          skipCoin.push(coin);
          skipCoinHelper[coin] = 'from_sequence_' + allActionIndexes[i];
          dropdownOptions.push(
            <Option title='' value={'from_sequence_' + allActionIndexes[i]} key={i}>
              {name}
            </Option>
          );
          thatCoinAdded = true;
        } else {
          if (allActionIndexes.length === 1 && !thatCoinAdded) {
            dropdownOptions.push(
              <Option title='' value={'from_sequence_' + allActionIndexes[i]} key={i}>
                {isLeverageExchange && this.props.store.instrumentQuote
                  ? this.props.store.instrumentQuote
                  : `that ${coinOrInstrument}`}
              </Option>
            );
            thatCoinAdded = true;
          } else {
            dropdownOptions.push(
              <Option title='' value={'from_sequence_' + allActionIndexes[i]} key={i}>
                {coinOrInstrument} from {text} action
              </Option>
            );
          }
        }
      }
    }

    if (
      this.props.store.seqInit.sequences[
        previousConditionBlockIndex > -1 ? previousConditionBlockIndex : sequenceIndex - 1
      ]?.data.conditions?.[0].ift === 'coin' &&
      !thatCoinAdded &&
      ((previousBlock && previousBlock.type === CONDITION_TYPE) ||
        previousConditionBlockIndex > -1) &&
      !(
        thenIndex < 0 &&
        parallelIndex < 0 &&
        waitIndex < 0 &&
        this.props.store.seqInit.sequences[sequenceIndex].data.conditions[index].co ===
        CONDITION_OPERATORS.OR
      )
    ) {
      dropdownOptions.push(
        <Option title='' value='that_coin' key={1}>
          {isLeverageExchange && this.props.store.instrumentQuote
            ? this.props.store.instrumentQuote
            : `that ${coinOrInstrument}`}
        </Option>
      );
      thatCoinAdded = true;
    }

    // Fix for 1. in https://gitlab.com/coinrule-v2/project-board/-/issues/1630
    if (
      (thenIndex > 0 && thenIndex === sequenceIndex - 1) ||
      (parallelIndex > 0 && parallelIndex === sequenceIndex - 1)
    ) {
      advancedOptions.push(
        <Option
          title=''
          value={CONDITION_TYPES.RULE_IN_PROFIT}
          key={CONDITION_TYPES.RULE_IN_PROFIT}>
          this rule is in profit
        </Option>
      );

      advancedOptions.push(
        <Option title='' value={CONDITION_TYPES.RULE_IN_LOSS} key={CONDITION_TYPES.RULE_IN_LOSS}>
          this rule is in loss
        </Option>
      );
    }

    if (
      this.props.store.rule.tr === TRIGGERS.EVENT &&
      ((sequenceIndex === 0 && !parallelIndexAfter) ||
        (thenIndex > 0 && thenIndex === sequenceIndex - 1))
    ) {
      // the very first condition or the first condition right after THEN operator
      // no ANY TIME operator at all
      advancedOptions.push(
        <Option
          title=''
          value={CONDITION_TYPES.TRADINGVIEW_SIGNAL}
          key={CONDITION_TYPES.TRADINGVIEW_SIGNAL}
          onClick={() => this.updateSelectProps({ sequenceIndex: 0, index: 0 })}>
          TradingView Signal
        </Option>
      );
    }

    if (!isLeverageExchange) {
      if (
        sequenceIndex === 0 ||
        // or (sequenceIndex is 1 and 0 is a tradingview signal)
        (sequenceIndex === 1 &&
          previousBlock.type === CONDITION_TYPE &&
          this.props.store.seqInit.isTradingViewSignalCondition(
            previousBlock.data.conditions[0]
          )) ||
        ((thenIndex < 0 &&
          parallelIndex < 0 &&
          waitIndex < 0 &&
          this.props.store.seqInit.sequences[sequenceIndex].data.conditions[index].co ===
          CONDITION_OPERATORS.OR)) ||
        isPrevActionNotify
      ) {
        // the very first condition or with OR between conditions
        // not after any THEN/ANY TIME/WAIT operator at all
        dropdownOptions.push(
          <Option
            title=''
            value={CONDITION_TYPES.ANY_COIN}
            key={1}
            onClick={() => this.updateSelectProps({ sequenceIndex: 0, index: 0 })}>
            any coin
          </Option>
        );

        dropdownOptions.push(
          <Option
            title=''
            value={CONDITION_TYPES.ANY_MY_COIN}
            key={2}
            onClick={() => this.updateSelectProps({ sequenceIndex: 0, index: 0 })}>
            any of my coins
          </Option>
        );
      }

      if (sequenceIndex === 0) {
        // the very first condition only
        advancedOptions.push(
          <Option
            title=''
            value={CONDITION_TYPES.BEST_MARKET}
            key={CONDITION_TYPES.BEST_MARKET}
            className='badge-new'
            onClick={() => this.updateSelectProps({ sequenceIndex: 0, index: 0 })}>
            best performing coin on the market
          </Option>
        );
        advancedOptions.push(
          <Option
            title=''
            value={CONDITION_TYPES.WORST_MARKET}
            key={CONDITION_TYPES.WORST_MARKET}
            className='badge-new'
            onClick={() => this.updateSelectProps({ sequenceIndex: 0, index: 0 })}>
            worst performing coin on the market
          </Option>
        );
        advancedOptions.push(
          <Option
            title=''
            value={CONDITION_TYPES.BEST_MINE}
            key={CONDITION_TYPES.BEST_MINE}
            className='badge-new'
            onClick={() => this.updateSelectProps({ sequenceIndex: 0, index: 0 })}>
            best performing coin in my portfolio
          </Option>
        );
        advancedOptions.push(
          <Option
            title=''
            value={CONDITION_TYPES.WORST_MINE}
            key={CONDITION_TYPES.WORST_MINE}
            className='badge-new'
            onClick={() => this.updateSelectProps({ sequenceIndex: 0, index: 0 })}>
            worst performing coin in my portfolio
          </Option>
        );
      }
    }

    let emptyOption = (
      <Option title='' value={'---'}>
        {'---'}
      </Option>
    );

    if (this.props.parent.checkIfExchangeLoaded()) {
      if (!isLeverageExchange) {
        options = Object.keys(stores.exchangeInfo.assets[this.props.store.rule.ex])
          .sort()
          .map((asset, i) => {
            const assetObj = stores.exchangeInfo.assets[this.props.store.rule.ex][asset];
            const name =
              assetObj?.name?.toUpperCase() === asset ? asset : `${asset} ${assetObj?.name}`;
            if (skipCoin.indexOf(asset) > -1) {
              return null;
            }
            return (
              <Option title='' key={asset} value={asset}>
                {name}
              </Option>
            );
          });

        defaultValue = this.prepareCoinListValue(skipCoinHelper);
      }

      if (isLeverageExchange) {
        const maxIndexForInstruments =
          this.props.store.getDisableListOfLeverageInstrumentsAfterIndex();
        const listAfterOrOperator =
          orIndex > 0 && orIndex === sequenceIndex - 1 && !(thenIndex > 0 || parallelIndex > 0);
        if (
          maxIndexForInstruments < 0 ||
          maxIndexForInstruments >= sequenceIndex ||
          listAfterOrOperator
        ) {
          options = Object.keys(stores.exchangeInfo.instruments[this.props.store.rule.ex])
            .sort()
            .map((instr, i) => {
              const instrObj = stores.exchangeInfo.instruments[this.props.store.rule.ex][instr];
              const name = instrObj.symbol.toUpperCase();
              const symbol = instrObj.symbol;

              if (listAfterOrOperator && this.props.store.instrumentQuote !== symbol) {
                return null;
              }

              return (
                <Option title='' key={symbol} value={symbol}>
                  {name}
                </Option>
              );
            });
        }

        defaultValue = this.prepareInstrumentListValue(skipCoinHelper);
      }
    } else {
      dropdownOptions = null;
      advancedOptions = null;
      options = (
        <Option title='' value='No exchange' disabled={true}>
          No exchange selected
        </Option>
      );
    }

    if (Array.isArray(dropdownOptions) && dropdownOptions.length < 1) {
      dropdownOptions = null;
    }

    if (!this.props.store.seqInit.isThatCoinAllowed(sequenceIndex) && sequenceIndex > 0) {
      dropdownOptions = null;
      defaultValue =
        !Array.isArray(defaultValue) &&
          (defaultValue === 'that_coin' || defaultValue.substring(0, 13) === 'from_sequence')
          ? '---'
          : defaultValue;
    }

    const { isOpen, allowSelectingMultipleCoins } = this.getSelectProps(sequenceIndex, index) || {};

    const isMobile = window.innerWidth <= 575;

    const sortOptions = () => {
      const selectedOptions = options.filter((option) => defaultValue.includes(option.props.value));

      options.unshift(
        ...selectedOptions,
        <Option value='-sep2-' title='' disabled={true} key='-sep2-'>
          {' '}
        </Option>
      );

      const flag = {};
      const uniqueOptions = [];

      // Removing duplicate options
      options.forEach((option) => {
        if (option?.props && !flag[option?.props?.value]) {
          flag[option?.props?.value] = true;
          uniqueOptions.push(option);
        }
      });

      options = uniqueOptions;
    };

    if (
      allowSelectingMultipleCoins &&
      options?.length &&
      Array.isArray(defaultValue) &&
      defaultValue?.length
    ) {
      sortOptions();
    }

    const shouldBeDisabled = this.shouldBeDisabled(
      isLeverageExchange,
      (options?.length || 0) + (advancedOptions?.length || 0)
    );

    return (
      <Popover text='Define Input Symbol' placement='left'>
        <Select
          ref={(r) => (this.coinListSelectRef = r)}
          title=''
          size={'default'}
          showSearch
          style={{ minWidth: 150 }}
          onChange={(value, option) =>
            this.handleCoinListChange({
              sequenceIndex,
              value,
              allowSelectingMultipleCoins,
              index,
              option,
            })
          }
          value={defaultValue}
          disabled={shouldBeDisabled}
          optionFilterProp='children'
          filterOption={(input, option) => {
            if (option.props.children) {
              if (Array.isArray(option.props.children)) {
              } else {
                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
            }

            return false;
          }}
          className={`${!this.props.store.isQuickEdit() && getFieldCssClass(defaultValue, true)} ${allowSelectingMultipleCoins ? 'multiple-coin-select' : ''
            }`}
          id={`CoinListSelect_${sequenceIndex}_${index}`}
          dropdownClassName='CoinListSelect__dropdown'
          mode={allowSelectingMultipleCoins ? 'multiple' : 'default'}
          {...(sequenceIndex === 0 &&
            index === 0 &&
            !this.props.store.isQuickEdit() && {
            open: isOpen,
            loading: !this.props.parent.checkIfExchangeLoaded(),
          })}
          {...(allowSelectingMultipleCoins && {
            showArrow: true,
            optionLabelProp: 'value',
            maxTagCount: isMobile ? 2 : 3,
            suffixIcon: <AddIcon />,
          })}>
          {emptyOption}
          {dropdownOptions}
          {advancedOptions}
          {dropdownOptions === null || options === null ? null : (
            <Option title='' value='-sep1-' disabled={true}>
              {' '}
            </Option>
          )}
          {options}
        </Select>
      </Popover>
    );
  }

  valuePermitsMultipleSelect = (values, additionals = []) => {
    const valueDontPermitMultipleSelect = [
      CONDITION_TYPES.ANY_COIN,
      CONDITION_TYPES.ANY_MY_COIN,
      CONDITION_TYPES.RULE_IN_PROFIT,
      CONDITION_TYPES.RULE_IN_LOSS,
      CONDITION_TYPES.TRADINGVIEW_SIGNAL,
      CONDITION_TYPES.BEST_MARKET,
      CONDITION_TYPES.WORST_MARKET,
      CONDITION_TYPES.BEST_MINE,
      CONDITION_TYPES.WORST_MINE,
      '---',
      ...additionals,
    ].find((item) => values === item || (Array.isArray(values) && values.includes(item)));

    return !valueDontPermitMultipleSelect;
  };

  handleCoinListChange = ({ sequenceIndex, value, index }) => {
    const { store } = this.props;
    const isDemoExchange = stores.info.demoExchangesIDs.includes(store.rule.ex);

    if (sequenceIndex === 0) {
      // Allow selecting multiple coins
      if (
        this.valuePermitsMultipleSelect(value) &&
        stores.exchangeInfo.exchangeType[this.props.store.rule.ex] === EXCHANGES_TYPE.SPOT
      ) {
        store.setSelectsProps({
          sequenceIndex,
          index,
          updates: { allowSelectingMultipleCoins: true, isOpen: true },
        });
      } else {
        this.updateSelectProps({ sequenceIndex: 0, index: 0 });
      }

      if (!value || !value?.length) {
        this.updateSelectProps({ sequenceIndex: 0, index: 0 });
      }

      this.focusOnCoinListSelectInput();
    }

    if (value === CONDITION_TYPES.TRADINGVIEW_SIGNAL) {
      const isFeaturedEnabled = stores.planStore.isFeatureEnabled('TradingView Integration');
      if (!isFeaturedEnabled && !isDemoExchange) {
        // TradingView integration available for Trader and Pro plans or demo exchange
        this.props.store.backup();

        setDataLocalStorage('should_restore_rule', true);

        stores.planStore.getPlansByFeature('TradingView Integration');
        stores.addRule.setPaymentGateVisible({
          type: 'promo',
          isVisible: true,
          forceShow: true,
        });

        return;
      }
    }

    this.props.store.changeConditionSymbols({
      index: this.props.index,
      symbols: !value || !value?.length ? CONDITION_TYPES.ANY_COIN : value,
      conditionIndex: sequenceIndex,
      isLeverageExchange:
        stores.exchangeInfo.exchangeType[this.props.store.rule.ex] === EXCHANGES_TYPE.LEVERAGE,
    });

    this.renderAmountSymbols(this.props.index, sequenceIndex);

    analytics.track('Condition Symbol selected', {
      Symbol: value,
      isDemo: isDemoExchange,
    });

    if (value === CONDITION_TYPES.TRADINGVIEW_SIGNAL) {
      analytics.track('Indicator selected', {
        'Type of Indicator': value,
        isDemo: isDemoExchange,
      });
    }
  };

  focusOnCoinListSelectInput = (delay = 100) => {
    setTimeout(() => {
      if (this.coinListSelectRef?.rcSelect?.inputRef) {
        this.coinListSelectRef.rcSelect.inputRef.focus();
      }
    }, delay);
  };

  // prepareInstrumentListValue() {
  //   if (
  //     this.props.store.seqInit.sequences[0]?.data.conditions?.[0] &&
  //     this.props.store.seqInit.sequences[0]?.data.conditions?.[0].ift === SYMBOL_TYPES.COIN
  //   ) {
  //     if (
  //       Array.isArray(this.props.store.seqInit.sequences[0].data.conditions[0].ifs) &&
  //       this.props.store.seqInit.sequences[0].data.conditions[0].ifs.length > 0
  //     ) {
  //       return this.props.store.seqInit.sequences[0].data.conditions[0].ifs;
  //     }
  //   } else if (
  //     this.props.store.seqInit.sequences[0]?.data.conditions?.[0] &&
  //     this.props.store.seqInit.sequences[0]?.data.conditions?.[0].ift === SYMBOL_TYPES.SIGNAL
  //   ) {
  //     // If the very first condition contains a signal,
  //     // we need to get symbol from the second condition -
  //     // either index 1 (condition right after the first) or
  //     // index 2 (in case there is WAIT operator in between)
  //     // or very first action (if there is no other condition in between)
  //     const indexesToCheck = [1, 2];

  //     for (const idx in indexesToCheck) {
  //       if (
  //         this.props.store.seqInit.sequences[idx]?.data.conditions?.[0] &&
  //         this.props.store.seqInit.sequences[idx]?.data.conditions?.[0].ift === SYMBOL_TYPES.COIN &&
  //         Array.isArray(this.props.store.seqInit.sequences[idx].data.conditions[0].ifs) &&
  //         this.props.store.seqInit.sequences[idx].data.conditions[0].ifs.length > 0
  //       ) {
  //         return this.props.store.seqInit.sequences[idx].data.conditions[0].ifs;
  //       } else if (this.props.store.seqInit.sequences[idx]?.data.actions?.[0]) {
  //         if (
  //           Array.isArray(this.props.store.seqInit.sequences[idx].data.actions[0].b) &&
  //           this.props.store.seqInit.sequences[idx].data.actions[0].b.length > 0
  //         ) {
  //           return this.props.store.seqInit.sequences[idx].data.actions[0].b;
  //         }
  //       }
  //     }
  //   } else if (
  //     this.props.store.seqInit.sequences[0]?.data.actions?.[0]
  //   ) {
  //     if (
  //       Array.isArray(this.props.store.seqInit.sequences[0].data.actions[0].b) &&
  //       this.props.store.seqInit.sequences[0].data.actions[0].b.length > 0
  //     ) {
  //       return this.props.store.seqInit.sequences[0].data.actions[0].b;
  //     }
  //   }

  //   return '---';
  // }

  renderMarketListSelect(index) {
    if (this.props.item.type !== SYMBOL_TYPES.MARKET) {
      return null;
    }

    return null; // not implemented yet
  }

  renderSignalCommandSelect(index, sequenceIndex) {
    const isDemoExchange = stores.info.demoExchangesIDs.includes(this.props.store.rule.ex);

    return (
      <Popover text='Select Signal'>
        <Select
          title=''
          size={'default'}
          onChange={(value) => {
            analytics.track('Signal selected', {
              'Type of Signal': value,
              isDemo: isDemoExchange,
            });

            this.props.store.changeConditionIndicator(index, value, sequenceIndex);
          }}
          value={this.props.item.in || '---'}
          dropdownClassName={`ruleIndicatorsDropdown`}
          className={!this.props.store.isQuickEdit() && getFieldCssClass(this.props.item.in, true)}
          disabled={this.props.store.isQuickEdit()}>
          <Option title='' value='---'>
            {'---'}
          </Option>
          <Option title='' value={TRADE_TYPES.BUY}>
            Buy
          </Option>
          <Option title='' value={TRADE_TYPES.SELL}>
            Sell
          </Option>
        </Select>
      </Popover>
    );
  }

  renderIndicatorSelect(index, sequenceIndex) {
    let regularIndicators = stores.info.indicators.filter((item) => {
      return !(
        getIndicator(item).search(/([MA (]+)/) !== -1 ||
        getIndicator(item).search(/([RSI]+)/) !== -1
      );
    });
    let advancedIndicators = stores.info.indicators.filter((item) => {
      return (
        getIndicator(item).search(/([MA (]+)/) !== -1 ||
        getIndicator(item).search(/([RSI]+)/) !== -1
      );
    });

    const isDemoExchange = stores.info.demoExchangesIDs.includes(this.props.store.rule.ex);
    const isLeverageExchange =
      stores.exchangeInfo.exchangeType[this.props.store.rule.ex] === EXCHANGES_TYPE.LEVERAGE;

    if (isLeverageExchange) {
      //regularIndicators = regularIndicators.filter(a => (a === 'price' || a === 'volume'));
      regularIndicators = regularIndicators.filter((a) => a === 'price'); // TODO: price only for now (volume must be tested)
    }

    return (
      <Popover text='Select Indicator'>
        <Select
          title=''
          size={'default'}
          onChange={(value) => {
            if (value === 'view_more') {
              stores.addRule.setPaymentGateVisible({ type: 'promo', isVisible: true });
              return;
            }

            if (
              !stores.user.user.plan.isPremium &&
              advancedIndicators.includes(value) &&
              !isDemoExchange
            ) {
              this.props.store.backup();

              setDataLocalStorage('should_restore_rule', true);
              stores.addRule.setPaymentGateVisible({ type: 'promo', isVisible: true });
              return;
            }

            analytics.track('Indicator selected', {
              'Type of Indicator': value,
              isDemo: isDemoExchange,
            });

            this.props.store.changeConditionIndicator(index, value, sequenceIndex);
          }}
          value={
            (this.props.item.in === CONDITION_TYPES.ANY_COIN
              ? isLeverageExchange
                ? 'any instrument'
                : 'any coin'
              : this.props.item.in) || '---'
          }
          dropdownClassName={`ruleIndicatorsDropdown`}
          className={!this.props.store.isQuickEdit() && getFieldCssClass(this.props.item.in, true)}
          disabled={this.props.store.isQuickEdit()}>
          <Option title='' value='---'>
            {'---'}
          </Option>

          {regularIndicators.map((indicator, i) => {
            const name = getIndicator(indicator);
            if (!name) return null;
            if (!canBeRender(indicator)) return false;

            if (this.props.item.maci === indicator) return null;

            return (
              <Option title='' key={indicator} value={indicator}>
                {name}
              </Option>
            );
          })}

          {advancedIndicators.length > 0 && (
            <OptGroup label='Advanced'>
              {advancedIndicators.map((indicator, i) => {
                const name = getIndicator(indicator);
                if (!name) return null;
                if (!canBeRender(indicator)) return false;

                if (this.props.item.maci === indicator) return null;

                return (
                  <Option title='' key={indicator} value={indicator}>
                    {name}
                  </Option>
                );
              })}

              {+stores.user.user.plan.clearance === 1 && (
                <Option title='View more indicators' key={'view more'} value={'view_more'}>
                  View More Indicators
                </Option>
              )}
            </OptGroup>
          )}
        </Select>
      </Popover>
    );
  }

  renderMAIndicatorSelect(index, sequenceIndex) {
    const maIndicator = ['price', 'ma_9', 'ma_50', 'ma_100', 'ma_200'];

    const isLeverageExchange =
      stores.exchangeInfo.exchangeType[this.props.store.rule.ex] === EXCHANGES_TYPE.LEVERAGE;

    return (
      <Popover text='Select Indicator'>
        <Select
          title=''
          size={'default'}
          disabled={this.props.store.isQuickEdit()}
          onChange={(value) =>
            this.props.store.changeConditionMAIndicator(index, value, sequenceIndex)
          }
          value={
            (this.props.item.maci === CONDITION_TYPES.ANY_COIN
              ? isLeverageExchange
                ? 'any instrument'
                : 'any coin'
              : this.props.item.maci) || '---'
          }
          className={
            !this.props.store.isQuickEdit() && getFieldCssClass(this.props.item.maci, true)
          }>
          <Option title='' value='---'>
            {'---'}
          </Option>

          {maIndicator.map((indicator, i) => {
            const name = getIndicator(indicator);

            if (this.props.item.in === indicator) {
              return null;
            }

            return (
              <Option title='' key={i} value={indicator}>
                {name}
              </Option>
            );
          })}
        </Select>
      </Popover>
    );
  }

  renderMAOperatorSelect(index, sequenceIndex) {
    const isIncDec = this.props.item.op === 'inc' || this.props.item.op === 'dec';
    const isLteGte = this.props.item.op === 'lte' || this.props.item.op === 'gte';
    const isXAbXBe = this.props.item.op === 'above' || this.props.item.op === 'below';
    const disableOnQuickEdit = this.props.store.isQuickEdit();
    const OptionsPriceOperators = [];

    OptionsPriceOperators.push(
      <Option key={4} title='' value='inc' disabled={disableOnQuickEdit && !isIncDec}>
        {getOperator('inc')}
      </Option>
    );
    OptionsPriceOperators.push(
      <Option key={5} title='' value='dec' disabled={disableOnQuickEdit && !isIncDec}>
        {getOperator('dec')}
      </Option>
    );
    OptionsPriceOperators.push(
      <Option key={6} title='' value='-sep2-' disabled={true}>
        {' '}
      </Option>
    );

    const isLeverageExchange =
      stores.exchangeInfo.exchangeType[this.props.store.rule.ex] === EXCHANGES_TYPE.LEVERAGE;

    return (
      <Popover text='Define Condition'>
        <Select
          title=''
          size={'default'}
          onChange={(value) =>
            this.props.store.changeConditionOperator(index, value, sequenceIndex)
          }
          // value={this.props.item.op || '---'}
          value={
            (this.props.item.op === CONDITION_TYPES.ANY_COIN
              ? isLeverageExchange
                ? 'any instrument'
                : 'any coin'
              : this.props.item.op) || '---'
          }
          className={getFieldCssClass(this.props.item.op, true)}>
          <Option title='' value='---'>
            {'---'}
          </Option>

          {OptionsPriceOperators}

          <Option key={2} title='' value='lte' disabled={disableOnQuickEdit && !isLteGte}>
            {getOperator('lte')}
          </Option>
          <Option key={3} title='' value='gte' disabled={disableOnQuickEdit && !isLteGte}>
            {getOperator('gte')}
          </Option>

          <Option key={4} title='' value='-sep3-' disabled={true}>
            {' '}
          </Option>

          <Option key={5} title='' value='above' disabled={disableOnQuickEdit && !isXAbXBe}>
            {getOperator('above')}
          </Option>
          <Option key={6} title='' value='below' disabled={disableOnQuickEdit && !isXAbXBe}>
            {getOperator('below')}
          </Option>
        </Select>
      </Popover>
    );
  }

  renderRSIOpertorSelect(index, sequenceIndex) {
    const isLeverageExchange =
      stores.exchangeInfo.exchangeType[this.props.store.rule.ex] === EXCHANGES_TYPE.LEVERAGE;

    return (
      <Popover text='Define Condition'>
        <Select
          title=''
          size={'default'}
          onChange={(value) =>
            this.props.store.changeConditionOperator(index, value, sequenceIndex)
          }
          // value={this.props.item.op || '---'}
          value={
            (this.props.item.op === CONDITION_TYPES.ANY_COIN
              ? isLeverageExchange
                ? 'any instrument'
                : 'any coin'
              : this.props.item.op) || '---'
          }
          className={getFieldCssClass(this.props.item.op, true)}>

          <Option title='' value='---'>
            {'---'}
          </Option>

          <Option key={4} title='' value='inc'>
            {getOperator('inc')}
          </Option>
          <Option key={5} title='' value='dec'>
            {getOperator('dec')}
          </Option>

          <Option title='' value='lte'>
            {getOperator('lte')}
          </Option>
          <Option title='' value='gte'>
            {getOperator('gte')}
          </Option>
        </Select>
      </Popover>
    );
  }

  renderOperatorSelect(index, sequenceIndex) {
    if (this.props.item.in === 'rsi') {
      return this.renderRSIOpertorSelect(index, sequenceIndex);
    } else if (this.props.item.in.indexOf('ma_') > -1 || this.props.item.in === 'price') {
      return this.renderMAOperatorSelect(index, sequenceIndex);
    }

    const isLeverageExchange =
      stores.exchangeInfo.exchangeType[this.props.store.rule.ex] === EXCHANGES_TYPE.LEVERAGE;
    const isIncDec = this.props.item.op === 'inc' || this.props.item.op === 'dec';
    const isLteGte = this.props.item.op === 'lte' || this.props.item.op === 'gte';
    const disableOnQuickEdit = this.props.store.isQuickEdit();

    return (
      <Popover text='Define Condition'>
        <Select
          title=''
          size={'default'}
          onChange={(value) =>
            this.props.store.changeConditionOperator(index, value, sequenceIndex)
          }
          // value={this.props.item.op || '---'}
          value={
            (this.props.item.op === CONDITION_TYPES.ANY_COIN
              ? isLeverageExchange
                ? 'any instrument'
                : 'any coin'
              : this.props.item.op) || '---'
          }
          className={getFieldCssClass(this.props.item.op, true)}>
          <Option key={0} title='' value='---'>
            {'---'}
          </Option>

          <Option key={4} title='' value='inc' disabled={disableOnQuickEdit && !isIncDec}>
            {getOperator('inc')}
          </Option>
          <Option key={5} title='' value='dec' disabled={disableOnQuickEdit && !isIncDec}>
            {getOperator('dec')}
          </Option>

          <Option key={6} title='' value='-sep4-' disabled={true}>
            {' '}
          </Option>

          <Option key={7} title='' value='lte' disabled={disableOnQuickEdit && !isLteGte}>
            {getOperator('lte')}
          </Option>
          <Option key={8} title='' value='gte' disabled={disableOnQuickEdit && !isLteGte}>
            {getOperator('gte')}
          </Option>
        </Select>
      </Popover>
    );
  }

  isRuleProfitCondition() {
    return this.props.item.ift === SYMBOL_TYPES.RULE;
  }

  isTradingViewSignalCondition() {
    return (
      this.props.item.ift === SYMBOL_TYPES.SIGNAL &&
      this.props.item.ifc === CONDITION_TYPES.TRADINGVIEW_SIGNAL
    );
  }

  getSymbolFromPreviousSequences() {
    for (let sequence in this.props.store.seqInit.sequences) {
      if (this.props.store.seqInit.sequences[sequence].data.conditions?.[0].ifs?.[0]) {
        return this.props.store.seqInit.sequences[sequence].data.conditions?.[0].ifs?.[0];
      } else if (this.props.store.seqInit.sequences[sequence].data.actions?.[0].b?.[0]) {
        return this.props.store.seqInit.sequences[sequence].data.actions?.[0].b?.[0];
      }
    }

    return '';
  }

  renderAmountSymbols(index, sequenceIndex) {
    const enablePercentage =
      this.props.item.op === 'inc' || this.props.item.op === 'dec' || this.isRuleProfitCondition();

    let assets = [this.props.store.tempCurrency, PERCENTAGE_SYMBOL, 'BTC'];
    let defaultValue = this.props.item.a.s;

    const isLeverageExchange =
      stores.exchangeInfo.exchangeType[this.props.store.rule.ex] === EXCHANGES_TYPE.LEVERAGE;

    if (isLeverageExchange) {
      const symbol = this.getSymbolFromPreviousSequences();
      const assetObj = stores.exchangeInfo.instruments[this.props.store.rule.ex].find(
        (a) => a.symbol === symbol
      );
      const quote = assetObj?.['quote'];

      if (quote) {
        assets = [quote, PERCENTAGE_SYMBOL];
      } else {
        assets = [PERCENTAGE_SYMBOL];
      }

      if (assets.indexOf(this.props.item.a.s) > -1) {
        defaultValue = this.props.item.a.s;
      } else {
        this.props.store.changeConditionAmountSymbol(index, quote, sequenceIndex);
        defaultValue = this.props.item.a.s;
      }
    } else {
      if (assets.indexOf(this.props.item.a.s) === -1) {
        this.props.store.changeConditionAmountSymbol(
          index,
          this.props.store.tempCurrency,
          sequenceIndex
        );
        defaultValue = this.props.item.a.s;
      }
    }

    return (
      <Popover text={'Select Coin'}>
        <Select
          title=''
          size={'default'}
          style={{ width: 90 }}
          onChange={(value) =>
            this.props.store.changeConditionAmountSymbol(index, value, sequenceIndex)
          }
          value={
            defaultValue === CONDITION_TYPES.ANY_COIN
              ? isLeverageExchange
                ? 'any instrument'
                : 'any coin'
              : defaultValue
          }>
          {assets.map((asset, i) => {
            let isEnabled = asset !== PERCENTAGE_SYMBOL || enablePercentage;

            if (!isEnabled) {
              return null;
            }

            return (
              <Option title='' key={i} value={asset}>
                {asset}
              </Option>
            );
          })}
        </Select>
      </Popover>
    );
  }

  validateRSIInput = (value) => {
    if(isNegativeNumber(+value)) return '0';
    if(value > 100) return '100'
    return value
  }

  renderAmountInput(index, sequenceIndex) {
    const inputClass = getFieldCssClass(this.props.item.a.iv, true);

    const isRSIIncreaseOrDecrease = (this.props.item.in === 'rsi' &&
    ['inc', 'dec'].indexOf(this.props.item.op) > -1);

    return (
      <span className={inputClass}>
        <Tooltip
          templateId={this.props.store.rule.tid}
          typeOfField={'condition-amount'}
          inputClass={inputClass}
          store={this.props.store}>
          <Input
            addonAfter={isRSIIncreaseOrDecrease ? null : this.renderAmountSymbols(index, sequenceIndex)}
            size={'default'}
            value={this.props.item.a.iv}
            placeholder={this.amountInputPlaceholderChange(sequenceIndex, index)}
            onChange={(e) => {
              const value = makeDotDecimals(e.target.value)
              this.props.store.changeConditionAmountValue(
                index,
                isRSIIncreaseOrDecrease ? this.validateRSIInput(value) : value,
                sequenceIndex
              );
            }}
            {
              ...(isRSIIncreaseOrDecrease && {
                style: { width: 80 }
              })
            }
          />
        </Tooltip>
      </span>
    );
  }

  renderRSIInputValue(index, sequenceIndex) {
    const inputClass = getFieldCssClass(this.props.item.rsi_v, true);
    return (
      <span className={inputClass}>
        <Tooltip
          templateId={this.props.store.rule.tid}
          typeOfField={'condition-amount'}
          inputClass={inputClass}
          store={this.props.store}>
          <Input
            min={0}
            max={100}
            size={'default'}
            style={{ width: 80 }}
            value={this.props.item.rsi_v}
            placeholder={this.amountInputPlaceholderChange(sequenceIndex, index)}
            onChange={(e) =>
              this.props.store.changeRSIInputValue(index, e.target.value, sequenceIndex)
            }
          />
        </Tooltip>
      </span>
    );
  }

  amountInputPlaceholderChange(sequenceIndex, index) {
    if (
      this.props.item.a.s === '%' ||
      this.props.store.seqInit.sequences[sequenceIndex].data.conditions[index].in === 'rsi'
    ) {
      return '10';
    }

    if (this.props.store.seqInit.sequences[sequenceIndex].data.conditions[index].in === 'volume') {
      return '10M';
    }

    if (
      this.props.store.seqInit.sequences[sequenceIndex].data.conditions[index].in === 'marketcap'
    ) {
      return '1B';
    }

    return '2.5';
  }

  renderDeltaSelect = (index, sequenceIndex) => {
    const options = [];
    const element = this.props.store.seqInit.sequences[sequenceIndex].data.conditions[index];
    const thenIndex = this.props.store.seqInit.findIndexSequenceBeforeByType(
      OPERATOR_TYPE,
      sequenceIndex,
      OPERATOR_TYPES.THEN
    );
    const parallelIndex = this.props.store.seqInit.findIndexSequenceBeforeByType(
      OPERATOR_TYPE,
      sequenceIndex,
      OPERATOR_TYPES.PARALLEL
    );
    const orIndex = this.props.store.seqInit.findIndexSequenceBeforeByType(
      OPERATOR_TYPE,
      sequenceIndex,
      OPERATOR_TYPES.OR
    );
    const waitIndex = this.props.store.seqInit.findIndexSequenceBeforeByType(
      OPERATOR_TYPE,
      sequenceIndex,
      OPERATOR_TYPES.WAIT
    );
    let deltaValue;
    let disallowOnQuickEdit = this.props.store.isQuickEdit();

    if (this.props.item.wi === 'delta') {
      deltaValue = this.props.item.d;
      disallowOnQuickEdit = false;
    } else if (this.props.item.wi === 'current_price') {
      deltaValue = 'current_price';
    } else if (this.props.item.wi === 'action_price' && this.props.item.ifc === 'that_coin') {
      deltaValue = 'action_price';
    } else if (this.props.item.wi === 'action_price' && this.props.item.ifc === 'from_sequence') {
      if (this.props.store.seqInit.sequences[sequenceIndex].data.conditions[index].in === 'price') {
        deltaValue = 'action_price_' + element.sr;
        disallowOnQuickEdit = false;
      } else {
        deltaValue = 'current_price';
      }
    } else if (this.props.item.wi === 'trailing' && this.props.item.ifc === 'from_sequence') {
      if (this.props.store.seqInit.sequences[sequenceIndex].data.conditions[index].in === 'price') {
        deltaValue = 'trailing_' + element.sr;
        disallowOnQuickEdit = false;
      } else {
        deltaValue = 'current_price';
      }
    } else {
      deltaValue = '';
    }

    // thenIndex > -1 || parallelIndex > -1 || orIndex > -1
    if (
      this.props.item.ifc === 'that_coin' &&
      this.props.store.seqInit.sequences[sequenceIndex].data.conditions[index].in === 'price' &&
      waitIndex > Math.min(thenIndex, parallelIndex, orIndex)
    ) {
      let priceText = 'price';
      if (this.props.store.seqInit.sequences[sequenceIndex].data.conditions[index].in !== 'price') {
        priceText = 'level';
      }

      options.push(
        <Option key={0} value={'action_price'} title='' disabled={this.props.store.isQuickEdit()}>
          from that {priceText}
        </Option>
      );
    } else if (
      // For migration purposes - action_price should not be at this place -> replace with current_price
      this.props.item.ifc === 'that_coin' &&
      this.props.store.seqInit.sequences[sequenceIndex].data.conditions[index].in === 'price' &&
      (deltaValue === 'action_price' || deltaValue === 'trailing')
    ) {
      deltaValue = 'current_price';
    }

    if (
      (thenIndex > -1 || parallelIndex > -1 || orIndex > -1 || waitIndex > -1) &&
      this.props.item.ifc === 'from_sequence'
    ) {
      if (this.props.store.seqInit.sequences[sequenceIndex].data.conditions[index].in === 'price') {
        let text = 'sold';

        if (
          this.props.store.seqInit.sequences[Number(element.sr)] &&
          this.props.store.seqInit.sequences[Number(element.sr)].data.actions &&
          this.props.store.seqInit.sequences[Number(element.sr)].data.actions[0]
        ) {
          const actionType =
            this.props.store.seqInit.sequences[Number(element.sr)].data.actions[0].at;

          if (actionType === TRADE_TYPES.BUY) {
            text = 'bought';
          }
        }

        if (thenIndex > -1 || parallelIndex > -1) {
          // only after THEN or ANY TIME operators
          options.push(
            <Option key={0} value={'trailing_' + element.sr} title='' className='badge-new'>
              trailing
            </Option>
          );
        }

        options.push(
          <Option key={0} value={'action_price_' + element.sr} title=''>
            from price at which you {text}
          </Option>
        );
      }
    }

    const isLeverageExchange =
      stores.exchangeInfo.exchangeType[this.props.store.rule.ex] === EXCHANGES_TYPE.LEVERAGE;
    const exchange = stores.info.exchanges.filter((ex) => ex.id === this.props.store.rule.ex)?.[0];

    const isPremiumPeriod = (period) => {
      const PREMIUM_PERIODS = ['1m', '3D', '1W'];

      const isPaidUser = stores.user.user.plan.clearance > 0;
      const isPremium = !exchange?.isDemo && PREMIUM_PERIODS.includes(period);

      return !isPaidUser && isPremium;
    };

    const getWithinOptions = (exchange) => {
      if (!exchange) return [];

      return exchange.candle_periods.map((period) => (
        <Option
          key={period}
          value={period}
          title=''
          className={isPremiumPeriod(period) ? 'badge-premium' : ''}>
          within {getPeriodName(period)}
        </Option>
      ));
    };

    const handleChange = (value) => {
      if (isPremiumPeriod(value))
        return stores.addRule.setPaymentGateVisible({ type: 'promo', isVisible: true });

      this.props.store.changeConditionDelta(index, value, sequenceIndex);
    };

    const getOptionsForNotCandleBasedIndicators = () => {
      const indicator = this.props.store.seqInit.sequences[sequenceIndex].data.conditions[index].in

      if (indicator === 'rsi' || indicator.includes('ma_')) return []

      return (
        [
          ...options,
          <Option title='' value='current_price' disabled={this.props.store.isQuickEdit()}>
            {indicator ===
              'volume' ||
              indicator ===
              'marketcap'
              ? 'from current level'
              : 'from current live price'}
          </Option>
        ]
      )
    }

    const within_options = getWithinOptions(exchange);

    return (
      <Popover text='Select Timeframe' placement='right'>
        <Select
          title=''
          size={'default'}
          style={{ minWidth: 180 }}
          onChange={handleChange}
          value={
            deltaValue === CONDITION_TYPES.ANY_COIN
              ? isLeverageExchange
                ? 'any instrument'
                : 'any coin'
              : deltaValue
          }
          dropdownStyle={{ width: '200px', position: 'relative' }}
          className={!disallowOnQuickEdit && getFieldCssClass(deltaValue, true)}
          disabled={disallowOnQuickEdit}
        >
          {[
            <Option value='' title='' key='---'>
              {'---'}
            </Option>,
            ...getOptionsForNotCandleBasedIndicators(),
            ...within_options
          ]}
        </Select>
      </Popover>
    );
  };

  renderDeltaSection(index, sequenceIndex) {
    if (this.props.item.op === 'inc' || this.props.item.op === 'dec') {
      return (
        <div
          className={
            'deltas within-area ' +
            this.props.store.seqInit.getErrorClass(sequenceIndex, 'wi', index)
          }>
          <div className='select-grey delta-selection'>
            {this.renderDeltaSelect(index, sequenceIndex)}
          </div>
        </div>
      );
    }

    return null;
  }

  prepareCoinListValue(skipCoinHelper) {
    if (this.props.item.ifc === 'symbol') {
      if (skipCoinHelper && skipCoinHelper[this.props.item?.ifs?.[0]]) {
        return skipCoinHelper[this.props.item.ifs[0]];
      }

      return this.props.item.ifs;
    } else if (this.props.item.ifc === 'from_sequence') {
      return 'from_sequence_' + this.props.item.sr;
    }

    return this.props.item.ifc || '---';
  }

  prepareInstrumentListValue(skipCoinHelper) {
    if (this.props.item.ifc === 'symbol') {
      if (skipCoinHelper && skipCoinHelper[this.props.item?.ifs?.[0]]) {
        return skipCoinHelper[this.props.item.ifs[0]];
      }

      return this.props.item.ifs;
    } else if (this.props.item.ifc === 'from_sequence') {
      return 'from_sequence_' + this.props.item.sr;
    } else if (this.props.item.ifc === 'that_coin') {
      return 'that_coin';
    }

    if (
      this.props.item.ifc === CONDITION_TYPES.ANY_COIN ||
      this.props.item.ifc === CONDITION_TYPES.ANY_MY_COIN
    ) {
      return '---';
    }

    return this.props.item.ifc || '---';
  }

  renderCoinPrice(coins, sequenceIndex) {
    const { item } = this.props;

    if (
      item.in === '---' ||
      coins?.length > 1 ||
      this.isTradingViewSignalCondition() ||
      this.isRuleProfitCondition()
    ) {
      return null;
    }

    return <ShowCoinsValue parentProps={this.props} coins={coins} conditionIndex={sequenceIndex} />;
  }

  showIfText(sequnceIndex) {
    let text = '';
    const previousElement = this.props.store.seqInit.getPreviousElement(sequnceIndex);
    if (previousElement && previousElement.type === OPERATOR_TYPE) {
      text = <label>If</label>;
    }

    return text;
  }

  renderTimeframesOptions() {
    const options = [];
    const exchange = stores.info.exchanges.filter((ex) => ex.id === this.props.store.rule.ex)[0];
    if (exchange) {
      for (let i in exchange.ma_periods) {
        const period = exchange.ma_periods[i];
        options.push(
          <Option key={0} value={period} title=''>
            {getPeriodName(period)}
          </Option>
        );
      }
    }
    return options;
  }

  renderMATimeframeSelect(index, sequenceIndex) {
    const isLeverageExchange =
      stores.exchangeInfo.exchangeType[this.props.store.rule.ex] === EXCHANGES_TYPE.LEVERAGE;

    const handleChange = (value) => {
      this.props.store.changeMADelta(index, value, sequenceIndex);
    };

    return (
      <div className='deltas within-area'>
        <div className='select-grey delta-selection'>
          <Popover text='Select Timeframe' placement='right'>
            <Select
              title=''
              size={'default'}
              style={{ minWidth: 180 }}
              onChange={handleChange}
              // value={this.props.item.map || '---'}
              value={
                (this.props.item.map === CONDITION_TYPES.ANY_COIN
                  ? isLeverageExchange
                    ? 'any instrument'
                    : 'any coin'
                  : this.props.item.map) || '---'
              }
              dropdownStyle={{ width: '200px', position: 'relative' }}
              className={getFieldCssClass(this.props.item.map, true)}>
              {this.renderTimeframesOptions()}
            </Select>
          </Popover>
        </div>
      </div>
    );
  }

  renderIndicatorArea(index, sequenceIndex) {
    let amountInputClass =
      'amounts input-grey' +
      (checkIfShowCoinValue(this.props.item.a.s).length > 0 ? ' abs-coin-value' : '');

    amountInputClass += ' ' + this.props.store.seqInit.getErrorClass(sequenceIndex, 'a', index);

    if (
      this.props.item.in === 'rsi' &&
      ['lte', 'gte'].indexOf(this.props.item.op) > -1
    ) {
      return (
        <React.Fragment>
          <div
            className={
              'input-grey ' + this.props.store.seqInit.getErrorClass(sequenceIndex, 'op', index)
            }>
            {this.renderRSIInputValue(index, sequenceIndex)}
          </div>

          <label>in a timeframe of </label>
          {this.renderMATimeframeSelect(index, sequenceIndex)}
        </React.Fragment>
      );
    } else if (
      (
        this.props.item.in.indexOf('ma_') > -1 &&
        ['above', 'below', 'lte', 'gte'].indexOf(this.props.item.op) > -1
      ) ||
      ['above', 'below'].indexOf(this.props.item.op) > -1
    ) {
      return (
        <React.Fragment>
          <div
            className={
              'operators select-grey ' +
              this.props.store.seqInit.getErrorClass(sequenceIndex, 'op', index)
            }>
            {this.renderMAIndicatorSelect(index, sequenceIndex)}
          </div>

          <label>in a timeframe of </label>
          {this.renderMATimeframeSelect(index, sequenceIndex)}
        </React.Fragment>
      );
    } else {
      this.props.store.changeConditionMAIndicator(index, '---', sequenceIndex);

      return (
        <React.Fragment>
          <div className={amountInputClass}>
            {this.renderAmountInput(index, sequenceIndex)}
            {this.renderCoinPrice(this.props.item.a.s, sequenceIndex)}
          </div>

          {this.renderDeltaSection(index, sequenceIndex)}
        </React.Fragment>
      );
    }
  }

  renderConditionFieldsBasedOnType(index, sequenceIndex) {
    if (this.isRuleProfitCondition()) {
      return (
        <>
          <label>by at least</label>
          {this.renderIndicatorArea(index, sequenceIndex)}
        </>
      );
    }

    if (this.isTradingViewSignalCondition()) {
      return (
        <>
          <label>is</label>
          <div
            className={`indicators select-grey
                        ${this.props.store.seqInit.getErrorClass(sequenceIndex, 'in', index)}`}>
            {this.renderSignalCommandSelect(index, sequenceIndex)}
          </div>
        </>
      );
    }

    return (
      <>
        <label>has</label>

        <div
          className={`indicators select-grey
                      ${this.props.store.seqInit.getErrorClass(sequenceIndex, 'in', index)}`}>
          {this.renderIndicatorSelect(index, sequenceIndex)}
        </div>

        <div
          className={
            'operators select-grey ' +
            this.props.store.seqInit.getErrorClass(sequenceIndex, 'op', index)
          }>
          {this.renderOperatorSelect(index, sequenceIndex)}
        </div>

        {this.renderIndicatorArea(index, sequenceIndex)}
      </>
    );
  }

  render() {
    const { index } = this.props;
    const sequenceIndex = this.props.sequenceIndex;
    let showError = false;
    let errorText = '';

    let symbolsSelectClass =
      'symbols select-grey' +
      (checkIfShowCoinValue(this.props.item.ifs).length === 1 &&
        this.props.item.in !== '---' &&
        !this.isTradingViewSignalCondition() &&
        !this.isRuleProfitCondition()
        ? ' abs-coin-value'
        : '');

    symbolsSelectClass += ' ' + this.props.store.seqInit.getErrorClass(sequenceIndex, 'ifs', index);

    if (
      this.props.store.seqInit.errors[sequenceIndex] &&
      this.props.store.seqInit.errors[sequenceIndex].length > 0
    ) {
      if (Number(this.props.store.seqInit.errors[sequenceIndex][0].subIndex) === Number(index)) {
        showError = true;
        errorText = this.props.store.seqInit.errors[sequenceIndex][0].text;
      }
    } else {
      showError = false;
    }

    return (
      <React.Fragment>
        <div className={this.getCssClass()} id='ConditionItem'>
          {/*{ this.renderRemoveButton(index, sequenceIndex) }*/}
          {this.props.removeBut}
          {this.props.helpIcon}

          {/*{ this.renderLogicalConditionDesc(sequenceIndex) }*/}

          <div className={`event`}>
            {this.showIfText(sequenceIndex)}

            {this.renderLogicalConditionSelect(index, sequenceIndex)}

            <div className={symbolsSelectClass}>
              {this.renderCoinListSelect(index, sequenceIndex)}
              {this.renderMarketListSelect(index)}
              {this.renderCoinPrice(this.props.item.ifs, sequenceIndex)}
            </div>

            {this.renderConditionFieldsBasedOnType(index, sequenceIndex)}
          </div>
        </div>
        <ErrorBlock content={errorText} show={showError} />
      </React.Fragment>
    );
  }
}
export default ConditionItem;
