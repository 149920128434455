import React from 'react';
import { observer } from 'mobx-react';
import { Col } from 'antd';
import { toJS } from 'mobx';

import './style.scss';

import stores from '../../../stores';
import ExchangeProfileStore from '../../../stores/exchangeProfile';
import { errorNotification } from '../../../helpers/notification';
import { isEmptyObject } from '../../../helpers/utils';
import ExchangesAPITutorials from '../ExchangesAPITutorials';
import AddPlanButton from './addPlanButton';
import ExchangePromo from './promo';
import {
  BINANCE,
  BINANCE_FUTURES,
  BITMEX,
  EXCHANGES_TYPE,
  KRAKEN_FUTURES,
  KUCOIN
} from '../../../constants/exchanges';
import { ClickToCopy } from 'components/ClickToCopy';
import { ReactComponent as CopyIcon } from 'assets/images/icons/copy.svg';
import { ReactComponent as InfoIcon } from 'assets/images/icons/info.svg';
import IconLoading from 'components/Loading/IconLoading';
import { withRouter } from 'react-router-dom';
import { ADVANCED_ONBOARDING_TEMPLATE_PAYWALL_NAME, ONBOARDING_7_DAYS_TRAIL_COUPON } from 'pages/RulesDashboardPage/Onboarding/utils';

import { DropdownExchanges, InputOutlined, Box, ButtonLoading, Typography } from 'design-system'

const store = new ExchangeProfileStore();

const getFieldName = function (field, exchange) {
  switch (field) {
    case 'cid':
      if (exchange.toLowerCase() === 'deribit') {
        return 'Client ID';
      }
      return 'Customer ID';
    case 'key':
      return exchange.toLowerCase() === 'liquid' ? 'Token ID' : 'API Key';
    case 'secret':
      if (exchange.toLowerCase() === 'deribit') {
        return 'Client Secret';
      } else if (exchange.toLowerCase() === 'kraken') {
        return 'Private Key';
      } else if (exchange.toLowerCase() === 'liquid') {
        return 'Token Secret';
      }
      return 'Secret Key';
    case 'passphrase':
      return 'Passphrase';
    default:
      return '';
  }
};

@observer
class AddApiExchange extends React.Component {
  numberOfKeys = 0;

  hideModals = () => {
    const { store, closeModals } = this.props;

    if (store) store.setShowRuleLaunchPreview(false);
    if (closeModals) closeModals();
  };

  handleLaunchLiveAfterConnectingExchange = () => {
    const connectedExchangeInfo = stores.userInfo.exchanges.find(
      (exchange) => exchange.uid === stores.exchangeProfile.selectedExchangeUID
    );

    const { shouldLaunchLiveOnAddExchange } = stores.exchangeProfile;
    const { plan } = stores.user.user;

    const { handleLaunchLive, location: { state: { fromOnboarding } } } = this.props;
    const { isAppliedOnboardingTemplateAdvanced } = stores.addRule

    // if function doesn't exist, and rule shouldn't be launched live, return
    if (!handleLaunchLive || !shouldLaunchLiveOnAddExchange) return;

    // if user is from onboarding, onboarding template is advanced, and user is on plan lower than trader, show Paywall and return
    if (fromOnboarding && isAppliedOnboardingTemplateAdvanced() && plan?.clearance < 2) {
      this.showPaywallForAdvancedOnboardingTemplates()
      return;
    }

    handleLaunchLive({ exchangeIdForLaunch: connectedExchangeInfo?.id });
  };

  showPaywallForAdvancedOnboardingTemplates = async () => {
    const hasExistingCoupon = stores.payment.coupon;

    if (!hasExistingCoupon) await stores.payment.fetchCoupon(ONBOARDING_7_DAYS_TRAIL_COUPON)
    stores.addRule.setPaymentGateVisible({ type: hasExistingCoupon ? 'promo' : ADVANCED_ONBOARDING_TEMPLATE_PAYWALL_NAME, isVisible: true });
  }

  connectExchangeCallback = (shouldLaunchLiveOnAddExchange) => {
    this.hideModals()

    if (!shouldLaunchLiveOnAddExchange && !this.props.isDashboardPage) {
      stores.userSurvey.updateSuccessPopupState({
        show: true,
      })
    }
  }

  handleConnectExchange = async (event) => {
    event.preventDefault();

    if (isEmptyObject(store.selectedKeys, this.numberOfKeys)) {
      errorNotification(`You need to provide all API keys.`);
      return;
    }

    try {
      const { shouldLaunchLiveOnAddExchange } = stores.exchangeProfile;
      if (this.props.store && shouldLaunchLiveOnAddExchange) this.props.store.setIsLoading(true);

      stores.exchangeProfile.setSelectedExchangeUID(store.selectedExchange);

      const authorized = await store.addExchange({ exchange_uid: store.selectedExchange, ...store.selectedKeys, }, () => this.connectExchangeCallback(shouldLaunchLiveOnAddExchange))

      stores.app.unauthorized(authorized);

      this.handleLaunchLiveAfterConnectingExchange()

    } catch (error) {
      // error is already handled in store (showing error notification)
    }
  };

  renderExchangeSelect() {
    const handleSelectingOption = (selectedExchangeUID) => {
      const selectedExchange = stores.info.exchanges?.find(
        (exchange) => !exchange.isDemo && exchange.uid === selectedExchangeUID
      );

      // Show paymentModal when user tries to connect leverage exchanges with lower clearance
      if (
        +stores.user.user.plan.clearance === 0 &&
        selectedExchange.type === EXCHANGES_TYPE.LEVERAGE
      ) {
        stores.addRule.setPaymentGateVisible({ type: 'promo', isVisible: true });
        return;
      }
      store.setSelectedExchange(selectedExchangeUID);
    };

    const existingExchangesIds = stores.userInfo.exchanges.map((exchange) => exchange.id);
    const showTextConnector = (stores.userInfo.exchanges.map((exchange) => exchange.uid).includes('binance')) ||
      (stores.userInfo.exchanges.map((exchange) => exchange.uid).includes('binance-futures'))

    const exchanges = stores.info.exchanges
      .filter((exchange) => !exchange.isDemo)
      .filter((exchange) => exchange.isEnabled)
      .filter((exchange) => !existingExchangesIds.includes(exchange.id))
      .map((exchange) => {
        return {
          isNew: [BINANCE_FUTURES, KRAKEN_FUTURES, BITMEX, KUCOIN].includes(exchange.uid),
          ...exchange
        }
      })

    const exchangesSorted = exchanges.sort((a, b) => a.name.localeCompare(b.name))
    const selectedExchange = exchangesSorted.find((exchange) => exchange.uid === store.selectedExchange) || null

    return (
      <>
        <div className='select-exchange-container'>
          <DropdownExchanges
            style={{ width: '100%' }}
            exchanges={exchangesSorted}
            value={selectedExchange}
            onChange={(e, selectedOption) => handleSelectingOption(selectedOption.uid)}
          />

          <InfoIcon
            role='button'
            aria-label='show connect exchange tutorial'
            className='info-icon'
            onClick={() => stores.info.setShowExchangeTutorial(!stores.info.showExchangeTutorial)}
          />
        </div>
        {
          store?.selectedExchange?.includes(BINANCE) || store?.selectedExchange?.includes(BINANCE_FUTURES) || selectedExchange === null ?
            <>
              {
                showTextConnector ?
                  null
                  :
                  <Typography
                    align={'center'}
                    sx={{
                      fontSize: '14px',
                      marginBottom: '15px',
                      color: '#949494'
                    }}
                  >
                    or
                  </Typography>
              }
              {this.props.promoBinance}
            </>
            :
            null
        }
      </>
    );
  }

  renderApiFields() {
    if (!store.selectedExchange) return null;

    const selectedExchange = toJS(
      stores.info.exchanges.find((exchange) => exchange.uid === store.selectedExchange)
    );
    this.numberOfKeys = selectedExchange.fields.length;

    const exchangeInputFields =
      selectedExchange.uid !== 'coinbasepro'
        ? selectedExchange.fields
        : selectedExchange.fields.sort((a, b) => {
          if (a === 'passphrase') return -2;
          if (a === 'key') return -1;
          return 0;
        });

    const submitForm = (e) => this.handleConnectExchange(e)

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >

        {
          exchangeInputFields.map((fieldName) => {
            let placeholder = '';
            const label = getFieldName(fieldName, selectedExchange.uid);

            const type =
              fieldName === 'secret' ||
                fieldName === 'passphrase' ||
                selectedExchange.uid === 'bitpanda'
                ? 'password'
                : 'text';

            switch (fieldName) {
              case 'secret':
                placeholder = 'eg. z3UPj1wmzjCoru4302'
                break
              case 'key':
                placeholder = 'eg. 41769tgf19376g1087'
                break
              case 'passphrase':
                placeholder = '*********'
                break
              case 'cid':
                placeholder = 'eg. ABCD1234'
                break
              default:
                placeholder = ''
            }

            const handleInputChange = (e) => store.setSelectedKey(e.target.name, e.target.value.trim())

            return (
              <InputOutlined
                key={type}
                type={type}
                name={fieldName}
                placeholder={placeholder}
                labelProps={{
                  children: label
                }}
                value={store.selectedKeys[fieldName]}
                onChange={handleInputChange}
              />
            )
          })
        }
        <ButtonLoading
          disabled={store.isAddWorking}
          onClick={submitForm}
        >
          {`Connect ${stores.exchangeProfile.shouldLaunchLiveOnAddExchange ? '& Launch Live' : ''}`}
        </ButtonLoading>
      </Box>
    )
  }

  renderSecurityInfo() {
    if (!store.selectedExchange) {
      return null;
    }

    return (
      <div className='security-info'>
        <div className='icon'>
          <img src={require('../../../assets/images/icon-aes-256.svg')} alt='' />
        </div>
        <div className='text'>
          We store API keys in encrypted form AES-256 with dedicated private keys which are
          generated for each user separately.
        </div>
      </div>
    );
  }

  renderExchangesAPITutorials() {
    return (
      <ExchangesAPITutorials
        store={store}
        type={store.selectedExchange ? store.selectedExchange : 'select_exchange'}
        promoBinance={this.props.promoBinance}
      />
    );
  }

  renderCopyIP = () => {
    if (store.selectedExchange && store.selectedExchange.includes(BINANCE) && stores.info.ips) {
      return (
        <ClickToCopy
          textToCopy={stores.info.ips}
          className='add-api-exchange__copy-ip-button'
          icon=''>
          <span className='truncate'>{stores.info.ips.replaceAll(' ', ', ')}</span>
          <CopyIcon />
        </ClickToCopy>
      );
    }

    return null;
  };

  showSelectForExchange(showButton) {
    if (showButton) return null;

    const {
      title = 'Connect New Exchange',
      subtitle = 'Choose among the top crypto currency exchanges',
    } = this.props;

    const connectedExchanges = stores.userInfo.exchanges;
    const connectedExchangesWithoutDemo = connectedExchanges.filter((exchange) => exchange.uid !== 'demo');
    const userDoNotHaveAnyExchangeConnected = !Array.isArray(connectedExchangesWithoutDemo) || connectedExchangesWithoutDemo.length === 0;

    return (
      <Col lg={24} xl={10} className='exchanges-wrap'>
        <h2 className={'add-api-exchange__title'}>{title}</h2>
        <p className={'add-api-exchange__subtitle'}>{subtitle}</p>

        <div className={`add-api-exchange`}>
          {this.renderExchangesAPITutorials()}

          <div>
            {this.renderExchangeSelect()}
          </div>

          <div className={'add-api-exchange--old-styles'}>
            <div className='add-api-exchange__ip-text-help-container'>
              {store.selectedExchange &&
                store.selectedExchange.includes(BINANCE) &&
                stores.info.ips && (
                  <p className='add-api-exchange__ip-text'>IP Address to Whitelist</p>
                )}

              {store.selectedExchange && !stores.info?.showExchangeTutorial && (
                <div
                  className={`add-api-exchange__helpLink`}
                  onClick={() => stores.info.setShowExchangeTutorial(true, true)}>
                  Need help?
                </div>
              )}
            </div>

            {this.renderCopyIP()}

            {this.renderApiFields()}
            {this.renderSecurityInfo()}

            {
              userDoNotHaveAnyExchangeConnected ?
                <ExchangePromo selectedExchange={store.selectedExchange} />
                :
                null
            }
          </div>
        </div>
      </Col >
    );
  }

  render() {
    const user = stores.user.getUser();

    const { showLoader } = this.props;
    const {
      info: { isLoaded, exchanges },
      userInfo: { exchanges: userExchanges },
      planStore: { plans },
    } = stores;

    if (showLoader && !isLoaded) return <IconLoading style={{ width: '90%', height: '90%' }} />;

    if (stores.userInfo.isLoadingExchanges || !isLoaded) {
      return null;
    }

    if (!user) {
      return null;
    }

    const currentUserPlan = plans.find((plan) => plan.code === user.plan.uid);

    const usedExchangesQuota = userExchanges.length;
    const planExchangesQuota =
      currentUserPlan?.limits?.numberOfExchanges || user.plan.numberOfExchanges;
    const availableExchanges = exchanges.length;
    const exchangesQuota =
      availableExchanges > planExchangesQuota ? planExchangesQuota : availableExchanges;

    const showButton = usedExchangesQuota >= exchangesQuota;

    return (
      <React.Fragment>
        <AddPlanButton show={showButton} />
        {this.showSelectForExchange(showButton)}
      </React.Fragment>
    );
  }
}

export default withRouter(AddApiExchange);
