import React from 'react'
import { Link, useParams } from 'react-router-dom'
import clsx from 'clsx'

import { ErrorBoundary } from 'components/ErrorBoundary'
import GlobalContent from 'components/GlobalContent'
import GlobalSidebar from 'components/GlobalSidebar'
import GlobalWrapper from 'components/GlobalWrapper'
import MobileFooter from 'components/MobileFooter'
import MobileMenu from 'components/MobileMenu'

import { formatCoinAmount, formatNumber } from 'helpers/utils'

import UserStore from 'stores/user'
import storesInjector, { Selector } from 'stores/storesInjector'

import type { ViewAccessSharedRuleProps } from 'v2/ui/accessSharedRule/view/ViewAccessSharedRule'
import {ViewAccessSharedRule, ViewLoginDialogForNotAuthenticatedUsers} from 'v2/ui';

import { setRedirectionBackToSharedRule, removeLocalStorageData } from './redirectionBackToSharedRule'
import { removeInitialRefererCookie, saveInitialRefererCookie } from './saveInitialReferrerCookie'

interface ShareRulePageSelectedStores {
  user: UserStore
}

export interface SharedRulePageProps extends ShareRulePageSelectedStores {}

const SharedRulePage: React.FC<SharedRulePageProps> = ({ user: userStore }) => {
  const { isAuthorized } = userStore || {};
  const { slug } = useParams<{ slug: string }>()

  // @FIXME this should be moved to v2 as the use case when the login logic will be moved there
  React.useEffect(() => {
    if(isAuthorized) {
      // authorized users won't be redirected and if there is a redirect data it should be cleaned up
      removeLocalStorageData()
      // authorized users do not need to have a referrer cookie set
      removeInitialRefererCookie()  
      return
    }

    // set the redirection
    setRedirectionBackToSharedRule({ slug })
    saveInitialRefererCookie({ initialReferrer: 'share-rule' })
  }, [isAuthorized, slug])

  return (
    <GlobalWrapper>
        <MobileMenu />

        <GlobalSidebar loggedOut={!isAuthorized} extraClass={`globalWrapper__sidebar`} />

        <GlobalContent
          // we need to overwrite some default styling as the view layout contains it own spacings
          style={{ 
            padding: '33px 0 33px 0',
            minHeight: '100%'
          }}
          extraClass={
            clsx([
              'globalContent_noBotPadding',
              'globalContent_lightBlueBg',
              'globalWrapper__content',
              'globalContent_fullWidthLeftPanel',
            ])
          }
        >
          <ErrorBoundary name={'boundary_DetailRulePage'}>
            <ViewAccessSharedRule
              ruleSlug={slug}
              formatCoinValue={formatCoinAmount}
              // @FIXME - refactor utils.js file to be proper types
              // the `formatNumber` is wrongly deducted fro .js file 
              // as props are requiring array of 4 values and the deducted type is array of unknown length
              formatNumber={formatNumber as ViewAccessSharedRuleProps['formatNumber']} 
            />
            <ViewLoginDialogForNotAuthenticatedUsers 
              LinkComponent={Link}
            /> 
          </ErrorBoundary>
        </GlobalContent>

        <MobileFooter />
    </GlobalWrapper>
  )
}

const selector: Selector<ShareRulePageSelectedStores, SharedRulePageProps> = ({user}) => ({
  user
})

export default storesInjector<ShareRulePageSelectedStores>(selector)(SharedRulePage)