import React from 'react'

import type { TypographyProps } from '@mui/material'

import { Typography, useTheme } from '@mui/material'

export interface BadgeOutlinedSuccessProps extends TypographyProps {}

const testId = 'BadgeOutlinedSuccess'
export const BadgeOutlinedSuccess = (props: BadgeOutlinedSuccessProps) => {
  const {
    sx,
    children,
    ...otherProps
  } = props

  const theme = useTheme()

  return (
    <Typography
      data-testid={testId}
      className={testId}
      sx={{
        height: '15px',
        padding: '1px 2px 0',
        borderRadius: '3px',
        border: `1px solid ${theme.palette.coinrule.validation.success}`,
        position: 'relative',
        top: '-1px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        fontSize: '11px',
        lineHeight: '10px',
        color: theme.palette.coinrule.validation.success,
        ...sx
      }}
      {...otherProps}
    >
      {children}
    </Typography>
  )
}

export default BadgeOutlinedSuccess
