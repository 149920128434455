import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import stores from '../../../../stores/index';
import { COINS_SYMBOL, PERCENTAGE_SYMBOL, TRADE_TYPES } from '../../../../stores/addRule';
import { localizedNumber, formattedCounter } from '../../../../helpers/utils';
import { observer } from 'mobx-react';

const DetailTextActions = observer((props) => {
  const { path } = props.match;

  useEffect(() => {
    if (
      path === '/rule/:id' &&
      props.isLeverageExchange &&
      stores.userRules.ruleDetails.li &&
      !props.store.instrumentQuote
    ) {
      props.store.setInstrumentQuote(stores.userRules.ruleDetails.li);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    path,
    props.isLeverageExchange,
    props.store.instrumentQuote,
    stores.userRules.ruleDetails.li,
  ]);

  const actionsStore = props.actions;
  const isLeverageExchange = props.isLeverageExchange;
  const coin = isLeverageExchange ? 'instrument' : 'coin';

  if (actionsStore.length < 1) {
    return null;
  }

  return actionsStore.map((a, i) => {
    let topBy;
    let symbols;
    const fromTo = a.at === TRADE_TYPES.BUY ? 'with my' : 'to my';
    const ofMy = isLeverageExchange ? 'contracts of' : 'of';
    const orderType = a.ot;
    let useOwnSr = false;

    const exactPieces = a.v.s === COINS_SYMBOL;
    let bestWorst = 'best';

    if (a.ort === 'best_mine' || a.ort === 'worst_mine') {
      if (a.ort === 'worst_mine') {
        bestWorst = 'worst';
      } else if (a.ort === 'best_mine') {
        bestWorst = 'best';
      }

      symbols = `${bestWorst} performing coin in my portfolio`;
    } else if (a.ort === 'best_market' || a.ort === 'worst_market') {
      if (a.ort === 'worst_market') {
        bestWorst = 'worst';
      } else if (a.ort === 'best_market') {
        bestWorst = 'best';
      }
      symbols = `${bestWorst} performing coin on the market`;
    } else if (a.ort === 'that_coin') {
      symbols = `that ${coin}`; //`coin best matching the condition`;

      if (isLeverageExchange && props.store.instrumentQuote) {
        symbols = props.store.instrumentQuote;
      }
    } else if (a.ort.includes('from_sequence')) {
      const sequenceReference = props.store.seqInit.getSequenceTypeAndPositionByIndex(a.sr);

      if (!!sequenceReference) {
        if (sequenceReference.thatCoinByPass) {
          symbols = `that ${coin}`;
        } else {
          symbols = `${coin} from ${formattedCounter(sequenceReference.position)} ${
            sequenceReference.type
          }`;
        }

        if (isLeverageExchange && props.store.instrumentQuote) {
          symbols = props.store.instrumentQuote;
        }

        useOwnSr = true;
      } else {
        symbols = '---';
      }
    } else {
      symbols = a.b.join(', ');
    }

    const sr =
      (useOwnSr ? a.sr : null) || props.sequences[0].d
        ? props.sequences
            .slice(0, props.sequenceIndex)
            .reverse()
            .find((e) => {
              return e?.t === 'CONDITION';
            })?.d?.sr
        : props.sequences
            .slice(0, props.sequenceIndex)
            .reverse()
            .find((e) => {
              return e?.type === 'CONDITION';
            })?.data?.conditions[0].sr;

    let action =
      (sr === undefined || sr === null)
        ? props.sequences
            .slice(0, props.sequenceIndex)
            .reverse()
            .find((e) => {
              return e?.t === 'ACTION';
            })
        : props.sequences[Number(sr)];

    if (!action) {
      if (
        props.sequences
          .slice(0, props.sequenceIndex)
          .reverse()
          .find((e) => {
            return e?.type === 'ACTION';
          })
      ) {
        action = props.sequences
          .slice(0, props.sequenceIndex)
          .reverse()
          .find((e) => {
            return e?.type === 'ACTION';
          });
      }
    }

    let prevAmountText =
      a.eqt === 'balance' || !a.eqt
        ? a.at === 'sell'
          ? 'total balance of'
          : 'maximum amount of'
        : (action?.data?.actions && action?.data?.actions[0]?.at === 'buy') ||
          action?.d?.ta?.at === 'buy'
        ? 'amount bought of'
        : 'amount sold of';

    if (a.sr !== undefined) {
      if (!action?.d?.ta && !action?.component && !action?.data?.actions) {
        prevAmountText = null;
      }
    }

    return (
      <li
        key={i}
        className={`ruleSummaryText__section
                      ruleSummaryText__section_action
                      ${
                        stores.addRule.sfpPendingSectionId === +props.sequenceIndex &&
                        'ruleSummaryText__section_preloader'
                      }`}
        onClick={() => props.handleClick(props.sequenceIndex)}
        style={{ paddingLeft: `${(props.sequenceIndex - 1) * 12}px` }}
      >
        <strong
          className={`ruleSummaryText__keyWord
                                  ruleSummaryText__keyWord_action`}
        >
          {props.hasPreviousAction ? 'AND' : ''} {a.at.toUpperCase()}
        </strong>
        <strong> {a.v.iv ? localizedNumber(a.v.iv) : '---'} </strong>
        {exactPieces || isLeverageExchange ? null : <strong> {a.v.s} </strong>}
        {exactPieces && !isLeverageExchange ? null : <span> {ofMy} </span>}
        {prevAmountText &&
        a.v.s === PERCENTAGE_SYMBOL &&
        ((action?.data?.actions && action?.data?.actions[0]) || action?.d?.ta) ? (
          <strong> {prevAmountText} </strong>
        ) : null}
        <strong> {symbols} </strong>
        {!isLeverageExchange ? topBy : null}
        {!isLeverageExchange ? <span> {fromTo} </span> : null}
        {!isLeverageExchange ? <strong> {a.q} </strong> : null}
        {!isLeverageExchange ? <span> wallet </span> : null}
        {!isLeverageExchange ? (
          <React.Fragment>
            <span> as </span>
            <strong>{orderType} order</strong>
          </React.Fragment>
        ) : null}
      </li>
    );
  });
});

export default withRouter(DetailTextActions);
