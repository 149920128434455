import React from 'react'

import type { BoxProps } from '@mui/material'

import { useTheme, Box, Typography } from '@mui/material'

export type ChartRuleStatsLegendDataPoint = {
  date: string
  percentageDifference: number
  volumeDifference?: number
} | {
  date: string
  percentageDifference?: number
  volumeDifference: number
}

export type ChartRuleStatsLegendFormatNumber = (
  number: number,
  currency: string,
  signAfter: string,
  usePlus: boolean,
  minimumFractionDigits?: number,
  maximumFractionDigits?: number,
) => [string, boolean, boolean, boolean]

export interface ChartRuleStatsLegendProps extends BoxProps { 
  data?: ChartRuleStatsLegendDataPoint[], 
  baseCurrency: string, 
  formatNumber: ChartRuleStatsLegendFormatNumber 
}

const testId = 'ChartRuleStatsLegend'
export const ChartRuleStatsLegend = (props: ChartRuleStatsLegendProps) => {
  const {
    sx,
    data,
    baseCurrency,
    formatNumber,
    ...otherProps
  } = props

  const theme = useTheme()

  if (!data || data.length === 0) return null

  const firstDataPoint = data[0]

  const { percentageDifference, volumeDifference } = firstDataPoint

  // unused vars are left to be aware of the response array parts meaning
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [priceFormatted, priceIsPositive, priceIsNegative, priceIsZero] = volumeDifference !== undefined ? formatNumber(volumeDifference, baseCurrency, '', false) : ['', false, false, false]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [percentFormatted, percentIsPositive, percentIsNegative, percentIsZero] = percentageDifference !== undefined ? formatNumber(percentageDifference, '', '%', true) : ['', false, false, false]
  
  return (
    <Box
      data-testid={testId}
      className={testId}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        background: '#333',
        color: '#fff',
        fontSize: '14px',
        padding: '5px 10px 3px',
        borderRadius: '5px',
        flexWrap: 'nowrap',
        ...sx
      }}
      {...otherProps}
    >
      {
        priceFormatted ?
          <Box
            sx={{
              marginRight: percentFormatted ? '10px' : '0px'
            }}
          >
            <Typography>
              {priceFormatted}
            </Typography>
          </Box>
          :
          null
      }
      {
        percentFormatted ?
          <Box>
            <Typography
              sx={{
                color: (
                  percentIsPositive ? 
                    theme.palette.coinrule.prices.up
                    :
                    percentIsNegative ? 
                      theme.palette.coinrule.prices.down
                      :
                      theme.palette.coinrule.basic.white
                )
              }}
            >
              {percentFormatted}
            </Typography>
          </Box>
          :
          null
      }
    </Box>
  )
}

export default ChartRuleStatsLegend