import React, { Component } from 'react';
import constants from '../../../constants';
import stores from '../../../stores';
import { PAGES } from '../../../stores/auth';

import './style.scss';

const baseURL = constants.URL;

class SocialMedia extends Component {
  render() {
    return (
      <div className='socialsAuthBlock'>
        <div className='socialsAuthBlock__socialsTitle'>{stores.auth.currentPage === PAGES.REGISTER ? `or sign up with` : `or log in with`}</div>
        <div className='socialsAuthBlock__socialsButtons'>
          <a
            href={baseURL + '/auth/oauth/google/prepare'}
            className='socialsAuthBlock__socialsBut socialsAuthBlock__socialsBut_google'
          >
            Google
          </a>
          <a
            href={baseURL + '/auth/oauth/facebook/prepare'}
            className='socialsAuthBlock__socialsBut socialsAuthBlock__socialsBut_facebook'
          >
            Facebook
          </a>
        </div>
      </div>
    );
  }
}

export default SocialMedia;
