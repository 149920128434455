import React from 'react';
import { toJS } from 'mobx';

import DetailTextActions from './Action';
import DetailTextConditions from './Condition';
import DetailTextOperators from './Operator';
import DetailTextNotifies from './Notify';
import { EXCHANGES_TYPE } from '../../../constants/exchanges';
import {
  ACTIONS,
  ACTION_TYPE,
  CONDITION_TYPE,
  NOTIFY_TYPE,
  OPERATOR_TYPE,
} from '../../../stores/addRule';
import { AddRuleStore, SavedSequence } from '../../../stores/addRuleStore.interface';
import { Stores } from '../../../stores/stores.interface';
import { TRADE } from 'constants/rule';

interface IProps {
  exchange: any;
  handleSectionClick: Function;
  store: AddRuleStore<SavedSequence>;
  stores: Stores;
}

const SequenceDetailText = (props: IProps) => {
  const { exchange, handleSectionClick, store, stores } = props;

  const isLeverageExchange =
    stores.exchangeInfo.exchangeType[store.rule.ex] === EXCHANGES_TYPE.LEVERAGE;
  const sequenceHtml = [];

  for (let i in store.seqInit.sequences) {
    const sequenceIndex = Number(i);
    const sequence = store.seqInit.sequences[i];
    const prevSequence = toJS(store.seqInit.sequences[sequenceIndex - 1]);

    const isBuyOrSellAction =
      sequence.t === ACTION_TYPE && sequence.d.do.toLowerCase() === TRADE.toLowerCase();
    const isNotifyAction =
      sequence.t === ACTION_TYPE && sequence.d.do.toLowerCase() === NOTIFY_TYPE.toLowerCase();

    if (isBuyOrSellAction) {
      let isItFirstAction;

      if (prevSequence) {
        isItFirstAction = prevSequence.t === ACTION_TYPE;
      }

      sequenceHtml.push(
        <DetailTextActions
          store={store}
          actions={[sequence.d.ta]}
          isLeverageExchange={isLeverageExchange}
          hasPreviousAction={isItFirstAction}
          sequenceIndex={i}
          sequences={store.seqInit.sequences}
          handleClick={handleSectionClick}
          key={i}
          exchange={exchange}
        />
      );
    } else if (isNotifyAction) {
      const isFirstNotify = !prevSequence || prevSequence.d.do !== ACTIONS.NOTIFY;
      sequenceHtml.push(
        <DetailTextNotifies
          key={sequenceIndex}
          sequenceIndex={sequenceIndex}
          isFirstNotify={isFirstNotify}
          isLoading={stores.addRule.sfpPendingSectionId === Number(sequenceIndex)}
          onClick={() => handleSectionClick(i)}
          notify={sequence.d.na}
        />
      );
    } else if (sequence.t === CONDITION_TYPE) {
      sequenceHtml.push(
        <DetailTextConditions
          hasPreviousCondition={prevSequence && prevSequence.t === CONDITION_TYPE}
          store={store}
          isLeverageExchange={isLeverageExchange}
          conditionsData={[sequence.d]}
          sequenceIndex={i}
          handleClick={handleSectionClick}
          key={i}
        />
      );
    } else if (sequence.t === OPERATOR_TYPE) {
      sequenceHtml.push(
        <DetailTextOperators
          operators={[sequence.d]}
          sequenceIndex={i}
          isLeverageExchange={isLeverageExchange}
          sequences={store.seqInit.sequences}
          handleClick={handleSectionClick}
          key={i}
        />
      );
    }
  }

  return sequenceHtml;
};

SequenceDetailText.propTypes = {};

export default SequenceDetailText;
