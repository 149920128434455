import { ICoupon } from '../typings/types';
import { isNegativeNumber } from './helpers';

export interface IGetDiscount {
  price: number;
  discount_amount?: number;
  discount_percentage?: number;
}

export const getPercentage = (amount: number, percentage: number): number => {
  return (amount * percentage) / 100;
};

export const getDiscountPrice = ({
  price,
  discount_amount,
  discount_percentage,
}: IGetDiscount): number => {
  let discount = discount_amount || 0;

  if (discount_amount) {
    discount = discount_amount / 100;
  }

  if (discount_percentage) {
    discount += (price * discount_percentage) / 100;
  }

  const discountedPrice = price - discount;

  return isNegativeNumber(discountedPrice) ? 0 : discountedPrice;
};

export const reduceCoupons = (coupons: ICoupon[]): any => {
  if (!coupons?.length) return;

  const constraints = coupons.map((coupon) => coupon.constraints);
  const uniqueConstraints = [...new Set(constraints.flat(Infinity))] as string[];

  const couponObj: Record<string, any> = {};

  uniqueConstraints.forEach((constraint) => {
    const constriantCoupons = coupons.filter((coupon) => coupon?.constraints?.includes(constraint));

    const couponIds: string[] = [];
    const constraintCouponsObj = constriantCoupons.reduce((acc: any, constraintCoupon) => {
      const discount_percentage =
        (acc.discount_percentage || 0) + constraintCoupon.discount_percentage;
      const discount_amount = (acc.discount_amount || 0) + constraintCoupon.discount_amount;
      const period = (acc.period || 0) + constraintCoupon.period;

      if (discount_amount) acc.discount_amount = discount_amount;
      if (discount_percentage) acc.discount_percentage = discount_percentage;
      if (period) acc.period = period;

      acc.constraints = [...(acc.constraints || []), constraint];
      couponIds.push(constraintCoupon.id);
      acc.coupon_ids = couponIds;

      return { ...constraintCoupon, ...acc };
    }, {});

    couponObj[constraint] = constraintCouponsObj;
  });

  return couponObj;
};
