import React from 'react';
import { Popover } from 'antd';

import './style.scss';

export default class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    // Show the tooltip after 2 seconds
    setTimeout(() => {
      this.setState({
        visible: true,
      });
      if (this.props.autoclose) {
        setTimeout(() => {
          this.setState({
            visible: false,
          });
        }, 5000);
      }
    }, this.props.entrydelay || 2000);
  }

  closeTooltip(event) {
    event.stopPropagation();

    this.setState({
      visible: false,
    });
  }

  render() {
    if (this.state.visible) {
      return (
        <Popover
          overlayClassName={`Popover
                                    ${this.props.placement}
                                    ${this.props.bemMixinClass}`}
          autoAdjustOverflow={this.props.autoAdjust}
          title={this.props.title}
          content={
            <div onClick={(event) => this.closeTooltip(event)} className='content-div'>
              {this.props.content}
            </div>
          }
          visible={this.props.visible === undefined ? true : this.props.visible}
          placement={this.props.placement}
        >
          {this.props.children}
        </Popover>
      );
    }
    return this.props.children;
  }
}
