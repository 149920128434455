export const RULE_ERROR_PUBLIC = 'RULE:ERROR:PUBLIC';
export const TRADE_SELL = 'TRADE:SELL';
export const TRADE_WAIT = 'TRADE:WAIT';
export const TRADE_BUY = 'TRADE:BUY';
export const SELL = 'sell';
export const BUY = 'buy';
export const TRADE = 'TRADE';
export const DEMO_EXCHANGE = 'demo exchange';
export const API_KEY = 'apikey';
export const EX_REJECT = 'exreject';
export const BALANCE = 'balance';
export const MARGIN = 'margin';
export const LOW_AMOUNT = 'lowamount';
export const SOURCE_INCORRECT = 'sourceincorrect';
export const ORDER_NOT_EXECUTED = 'order_not_executed';
export const PAIR_NOT_AVAILABLE = 'pair_not_available';
export const SYSTEM_UPGRADE = 'system_upgrade';

export const DEFAULT_LIMIT_ORDER_MARGIN = 0.5;
export const DEFAULT_MARKET_CAP_PROTECTION = 10_000_000;

export const SEQUENCES = {
  CONDITION: 'CONDITION',
  ACTION: 'ACTION',
  OPERATOR: 'OPERATOR',
};

export const RULE_CREATE = 'RULE:CREATE';
export const RULE_UPDATE = 'RULE:UPDATE';
export const RULE_ARCHIVED = 'RULE:ARCHIVED';
export const RULE_DELETE = 'RULE:DELETE';
export const RULE_FINISHED = 'RULE:FINISHED';
export const RULE_PAUSED = 'RULE:PAUSED';
export const RULE_ENABLED = 'RULE:ENABLED';
export const RULE_EXECUTE = 'RULE:EXECUTE';
export const RULE_ALERT = 'RULE:ALERT';
export const CONDITION_MET = 'CONDITION:MET';
export const CONDITIONS_MET = 'CONDITIONS:MET';
export const RULE_NOTIFY = 'RULE:NOTIFY';
export const MIN_LEVERAGE_ORDER_SIZE = 0.0001;
export const STANDARD_LEVERAGE_LOT_SIZE = 1;
