import React from 'react'

export interface IconChevronLeftProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconChevronLeft'
export const IconChevronLeft = (props: IconChevronLeftProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      width={"1em"}
      height={"1em"}
      fill={"none"}
      viewBox={"0 0 13 24"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...otherProps}
    >
      <path
        d={"M0 12c0-.211.04-.42.117-.615.077-.195.19-.372.333-.522L10.379.471C10.667.169 11.057 0 11.464 0c.408 0 .798.17 1.086.47.288.302.45.711.45 1.137 0 .427-.162.836-.45 1.137L3.708 12l8.842 9.256c.143.15.256.326.333.521a1.672 1.672 0 0 1-.333 1.752 1.53 1.53 0 0 1-.498.349 1.476 1.476 0 0 1-1.175 0c-.187-.081-.356-.2-.498-.349L.449 13.137a1.613 1.613 0 0 1-.332-.522A1.672 1.672 0 0 1 0 12Z"}
        fill={"#000"}
      />
    </svg>
  )
}

export default IconChevronLeft
