import React from 'react'

import type { TypographyProps } from '@mui/material'

import { useTheme, Typography } from '@mui/material'

export interface RuleDetailTitleProps extends TypographyProps {}

const testId = 'RuleDetailTitle'
export const RuleDetailTitle = (props: RuleDetailTitleProps) => {
  const {
    sx,
    children,
    ...otherProps
  } = props

  const theme = useTheme()

  return (
    // @ts-ignore there is component prop on Typography
    <Typography
      component={'h1'}
      data-testid={testId}
      wrap={false}
      sx={{
        color: theme.palette.coinrule.basic.inputText,
        ...sx
      }}
      {...otherProps}
    >
      <Typography
        component={'span'}
        sx={{
          fontSize: { xs: '24px', md: '33px', lg: '37px' },
          fontWeight: 300,
        }}
      >
        Rule{' '}
      </Typography>
      <Typography
        component={'span'}
        sx={{
          fontSize: { xs: '24px', md: '33px', lg: '37px' },
          fontWeight: 600,
        }}
      >
        {children}
      </Typography>
    </Typography>
  )
}

export default RuleDetailTitle
