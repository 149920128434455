import type { TReducer } from "v2/types/TReducer"

import type { TDomainActionLoginDialogForNotAuthenticatedUsers } from "./actionsLoginDialogForNotAuthenticatedUsers";
import { EDomainActionsLoginDialogForNotAuthenticatedUsers } from "./actionsLoginDialogForNotAuthenticatedUsers";

export type TDomainStateLoginDialogForNotAuthenticatedUsers = {
  isOpen: boolean
  timeoutId: number | null
}

export const initialStateLoginDialogForNotAuthenticatedUsers: TDomainStateLoginDialogForNotAuthenticatedUsers = {
  isOpen: false,
  timeoutId: null,
}

export const reducerLoginDialogForNotAuthenticatedUsers: TReducer<TDomainStateLoginDialogForNotAuthenticatedUsers, TDomainActionLoginDialogForNotAuthenticatedUsers> = (state = initialStateLoginDialogForNotAuthenticatedUsers, action): TDomainStateLoginDialogForNotAuthenticatedUsers => {
  switch (action.type) {
    case EDomainActionsLoginDialogForNotAuthenticatedUsers.SET_OPEN: {
      return {
        ...state,
        isOpen: action.payload.isOpen
      }
    }
    case EDomainActionsLoginDialogForNotAuthenticatedUsers.SET_TIMEOUT_ID: {
      return {
        ...state,
        timeoutId: action.payload.timeoutId
      }
    }
    case EDomainActionsLoginDialogForNotAuthenticatedUsers.BOUNCED: {
      return {
        ...state,
        ...initialStateLoginDialogForNotAuthenticatedUsers
      }
    }
    default:
      return state
  }
}
