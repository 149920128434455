import React from 'react';

export interface PlanSkeletonProps {
  numberOfFeatures?: number;
}

const PlanSkeleton: React.FC<PlanSkeletonProps> = ({ numberOfFeatures = 5 }) => {
  const count = Array.from(Array(numberOfFeatures).keys());

  return (
    <div className={`plansPreloadSkeleton__item`} data-testid='PlanSkeleton'>
      <div
        className={`plansPreloadSkeleton__textBlock
                          plansPreloadSkeleton__textBlock_title`}>
        &nbsp;
      </div>
      <div
        className={`plansPreloadSkeleton__textBlock
                          plansPreloadSkeleton__textBlock_price`}>
        &nbsp;
      </div>
      <div
        className={`plansPreloadSkeleton__textBlock
                          plansPreloadSkeleton__textBlock_subtitle`}>
        &nbsp;
      </div>
      {count.map((i) => (
        <div
          data-testid={'feature-skeleton'}
          key={i}
          className={`plansPreloadSkeleton__textBlock
                          plansPreloadSkeleton__textBlock_feature`}>
          &nbsp;
        </div>
      ))}
    </div>
  );
};

export default PlanSkeleton;
