import React from 'react';
import { observer } from 'mobx-react';
import stores from '../../../stores/index';
import { DEFAULT_AMOUNT_SYMBOL, COINS_SYMBOL, PERCENTAGE_SYMBOL } from '../../../stores/addRule';
import {
  ANY_COIN,
  COINS_ON_HOLD,
  COIN_MEET_CONDS,
  localizedNumber,
  formatNumber,
} from '../../../helpers/utils';
import Popover from '../../../components/Popover';
import { EXCHANGES_TYPE } from '../../../constants/exchanges';
import { getExchangeRate } from 'helpers/conversion';

export const checkIfShowCoinValue = (coins) => {
  const excluded = [
    stores.addRule.tempCurrency,
    PERCENTAGE_SYMBOL,
    COINS_SYMBOL,
    ANY_COIN,
    COINS_ON_HOLD,
    COIN_MEET_CONDS,
    '---',
  ];
  const selectedCoins = Array.isArray(coins) ? coins : [coins];

  const showCoins = [];
  let i, symbol;

  if (selectedCoins.length > 0) {
    for (i in selectedCoins) {
      symbol = selectedCoins[i];

      if (symbol && excluded.indexOf(symbol) < 0) {
        showCoins.push(symbol);
      }
    }
  }

  return showCoins;
};

export const getCoinValue = (symbol, exchange_id) => {
  const assets = stores.exchangeInfo.assets[exchange_id];

  if (
    !(
      assets &&
      assets[symbol] &&
      stores.info.currencies &&
      stores.info.currencies[DEFAULT_AMOUNT_SYMBOL]
    )
  ) {
    return {
      price: 0,
      volume: 0,
      marketcap: 0,
      growth: 0,
    };
  }

  const asset = assets[symbol];

  return {
    price: asset.price,
    volume: asset.volume,
    marketcap: asset.marketCap,
    growth: asset.growth,
  };
};

export const getCoinLogo = (symbol, exchange_id, fiatValue) => {
  const assets = stores.exchangeInfo.assets[exchange_id];
  const fiats = stores.exchangeInfo.fiats[exchange_id];

  if (fiatValue && assets?.[symbol]) {
    return assets[symbol].logo;
  }

  if (fiats?.[symbol]) {
    return fiats[symbol].logo;
  }

  const helpSymbol = 'Z' + symbol; // Kraken uses Z prefix for currencies
  if (fiats?.[helpSymbol]) {
    return fiats[helpSymbol].logo;
  }

  return null;
};

export const getCoinFullname = (symbol, exchange_id, isFiat) => {
  const assets = stores.exchangeInfo.assets[exchange_id];
  const fiats = stores.exchangeInfo.fiats[exchange_id];

  if (isFiat) {
    if (!(fiats && fiats[symbol])) {
      return null;
    } else {
      return fiats[symbol].name;
    }
  }

  if (!(assets && assets[symbol])) {
    return null;
  } else {
    return assets[symbol].name;
  }
};


@observer
class ShowCoinsValue extends React.Component {
  showPrice() {
    const coinValues = [];
    const exchange_id = this.props.parentProps.store.rule.ex;
    const assets = stores.exchangeInfo.assets[exchange_id];
    const instruments = stores.exchangeInfo.instruments[exchange_id];
    const indicator = this.props.parentProps.item.in || 'price';
    const selected_coins = checkIfShowCoinValue(this.props.coins);

    if (selected_coins.length > 0) {
      for (const i in selected_coins) {
        let symbol = selected_coins[i];
        let coinValue;
        let isFiat;

        if (assets) {
          let cont = true;

          if (stores.exchangeInfo.exchangeType[exchange_id] === EXCHANGES_TYPE.LEVERAGE) {
            if (instruments.some((i) => i.symbol === symbol)) {
              cont = false;
              isFiat = false;
              const firstInstrument = instruments.filter((i) => i.symbol === symbol)[0];
              symbol = firstInstrument.base;

              if (indicator === 'price') {
                coinValue = firstInstrument.indexFiat;
              } else if (indicator === 'volume') {
                coinValue = firstInstrument.volume;
              } else {
                coinValue = false;
              }
            }
          }

          if (cont) {
            if (assets[symbol]) {
              coinValue = getCoinValue(symbol, exchange_id);
              isFiat = false;
            } else {
              coinValue = getExchangeRate({
                inputCurrencySymbol: symbol,
                outputCurrencySymbol: stores.addRule.tempCurrency,
                currencies: stores.info.currencies
              });
              isFiat = true;
            }
          }

          if (coinValue !== false) {
            coinValues.push(
              <CoinValueInformation
                key={i}
                coin={symbol}
                value={coinValue}
                type={indicator}
                isFiat={isFiat}
                isLeverage={
                  stores.exchangeInfo.exchangeType[exchange_id] === EXCHANGES_TYPE.LEVERAGE
                }
              />
            );
          }
        }
      }
    }

    return coinValues;
  }

  render() {
    const html = this.showPrice();

    if (html.length < 1) {
      return null;
    }

    return <div className='coin-value-area'>{html}</div>;
  }
}

@observer
class CoinValueInformation extends React.Component {
  priceShortcut(price) {
    const mld = 1000000000;
    const mil = 1000000;
    const kil = 1000;

    if (price / mld > 1) {
      return (price / mld).toFixed(2) + 'B';
    } else if (price / mil > 1) {
      return (price / mil).toFixed(2) + 'M';
    } else if (price / kil > 1) {
      return (price / kil).toFixed(2) + 'K';
    } else {
      return price.toFixed(2);
    }
  }

  render() {
    let growth_class = 'growth-value red';
    let growth_symbol;
    let coin_symbol = this.props.coin;
    let indicator = this.props.type;
    let isFiat = this.props.isFiat;
    let isLeverage = this.props.isLeverage;
    let type, growth;
    let value =
      typeof this.props.value === 'object' ? this.props.value[indicator] : this.props.value;

    if (isFiat) {
      indicator = 'price';
    }

    if (indicator === 'price') {
      coin_symbol = '1 ' + coin_symbol;
      const baseCurr = stores.addRule.tempCurrency;
      type = null;
      // FYI: the previous code was unstable for specific cases, so if you want to update this, please test properly all possible scenarios
      // ie. select BTC as well as other coins, test on various base currencies incl. BTC
      value = formatNumber(value, baseCurr, '', false, 0, baseCurr === 'BTC' ? 8 : 4)[0];
    } else if (indicator === 'volume') {
      type = indicator.substr(0, 3);
      value = this.priceShortcut(value);
    } else if (indicator === 'marketcap') {
      type = 'MCap';
      value = this.priceShortcut(value);
    }

    if (!isFiat && !isLeverage) {
      growth = Math.floor(this.props.value.growth * 100) / 100;

      if (growth > 0) {
        growth_class = 'growth-value green';
        growth_symbol = '+';
      } else if (growth === 0) {
        growth_class = 'growth-value';
        growth_symbol = '';
      }
    }

    return (
      <div className='coin-area'>
        <span className='coin'>{coin_symbol}</span>
        {type ? <span className='indicator'> {type} </span> : null}
        <span className='symbol'>≈</span>
        <span className='price'>{value}</span>
        {isLeverage || isFiat || type ? null : (
          <Popover text='24h Price Change' placement='right'>
            <span className={growth_class}>
              {growth_symbol}
              {localizedNumber(growth)}%
            </span>
          </Popover>
        )}
      </div>
    );
  }
}

export default ShowCoinsValue;
