import React, { Component } from 'react';
import { observer } from 'mobx-react';

import stores from '../../../stores';

@observer
class EmailField extends Component {
  handleChange(e) {
    stores.auth.setEmail(e.target.value.trim());
  }

  showInvalidMark() {
    return !stores.auth.isValidEmail && stores.auth.showIfValidEmail;
  }

  render() {
    return (
      <div className='authWindow__inputWrap'>
        <input
          type='email'
          name='email'
          autoComplete='username'
          autoFocus
          className={`authWindow__textInput
                        ${stores.auth.email && 'authWindow__textInput_filled'}
                        ${this.showInvalidMark() && 'authWindow__textInput_invalid'}`}
          value={stores.auth.email}
          onChange={this.handleChange}
        />
        <div className='authWindow__inputPlaceholder'>Email</div>

        {this.showInvalidMark() && (
          <div className='authWindow__inputErrorMessage'>{stores.auth.errorMessage('email')}</div>
        )}
      </div>
    );
  }
}

export default EmailField;
