import React, { useState, useEffect } from 'react';
import News from '../News';
import Tabs from '../Tabs';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

import './style.scss';
import TradeFeeds from '../TradeFeeds';
import http from 'helpers/http';
import { errorNotification } from 'helpers/notification';
import { NEWS_FEED_URLs } from 'helpers/newsfeed';
import useNewsFeedData from 'hooks/useNewsFeedData';
import useIsMounted from 'hooks/useIsMounted';

const NewsTradeFeeds = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [tradeFeeds, setTradeFeeds] = useState([]);
  const isMounted = useIsMounted();

  const { data, isLoading } = useNewsFeedData({
    currentIndex,
    url: NEWS_FEED_URLs[currentIndex],
  });

  const { elementRef } = useIntersectionObserver({
    setCounter: setCurrentIndex,
    isLoading,
    hasMore: currentIndex < NEWS_FEED_URLs.length - 1,
  });

  const getTradeFeeds = async () => {
    try {
      const { data } = await http.get('/trade/strategy');

      if (data?.status !== 'OK')
        return errorNotification(`Something has gone wrong. Please try it again later.`);

      if (isMounted()) {
        setTradeFeeds(data.data);
      }
    } catch (error) {
      errorNotification(`Something has gone wrong. Please try it again later.`);
    }
  };

  // Initial tradeFeeds fetch
  useEffect(() => {
    getTradeFeeds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch TradeFeeds every minute
  useEffect(() => {
    const interval = setInterval(() => {
      getTradeFeeds();
    }, 1000 * 60);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tabs className='NewsTradeFeeds__tabs'>
      <div label='News'>
        <News newsItems={data} isLoading={isLoading} elementRef={elementRef} />
      </div>

      <div label='Trade Feed'>
        <TradeFeeds tradeFeeds={tradeFeeds} isLoading={!tradeFeeds.length} />
      </div>
    </Tabs>
  );
};
export default NewsTradeFeeds;
