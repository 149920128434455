import { OPERATOR_TYPE } from '../addRule';

export const getIndexFirstType = (sequences, type) => {
  return sequences.findIndex((item) => item.type === type || item.t === type);
};

export const findIndexSequenceBeforeByType = (sequences, type, actualIndex, subType = null) => {
  let foundIndex = -1;

  for (let i = actualIndex; i >= 0; i--) {
    if (sequences.length > i && sequences[i] && sequences[i].type === type && actualIndex !== i) {
      if (subType && type === OPERATOR_TYPE) {
        if (sequences[i].data.operators.length > 0) {
          if (sequences[i].data.operators[0].t === subType) {
            foundIndex = i;
            break;
          }
        }
      }

      if (!subType) {
        foundIndex = i;
        break;
      }
    }
  }

  return foundIndex;
};

export const findAllIndexesSequenceBeforeByType = (sequences, type, actualIndex, stop = 0) => {
  let foundIndex = [];
  if (sequences.length > 0) {
    for (let i = actualIndex; i >= stop; i--) {
      if (
        sequences[i] &&
        (sequences[i].type === type || sequences[i].t === type) &&
        actualIndex !== i
      ) {
        foundIndex.unshift(i);
      }
    }
  }

  return foundIndex;
};
