import React from 'react';

import type { TypedUseSelectorHook } from 'react-redux';
import clsx from 'clsx'

import type { TDomainUseCasesShareRule, TDomainStateShareRule } from "v2/domains/shareRule";

import type { EFormShareRuleCopyLinkSource } from "design-system";
import { Typography, Center, Overlay, Loader, Dialog, FormShareRule, IconButton, IconShare } from "design-system";
import { EAsyncStatus } from 'v2/types';

const testId='ViewShareRuleShareButton'

export interface ViewShareRuleShareButtonProps extends React.HTMLProps<HTMLDivElement> { 
  ruleId: string
  ruleName: string
  mountOpened?: boolean
  dialogId?: string
  createLinkFromSlug?: ({slug}: {slug: string}) => string
  callbackTextCopiedSuccess?: (source: EFormShareRuleCopyLinkSource) => void,
  callbackTextCopiedError?: ({ error }: { error: unknown }) => void,
}

export const createViewShareRuleShareButton = ({
  useCasesShareRule,
  useSelector
}: {
  useCasesShareRule: TDomainUseCasesShareRule,
  useSelector: TypedUseSelectorHook<TDomainStateShareRule>
}) => {
  const ViewShareRuleShareButton = (props: ViewShareRuleShareButtonProps) => {
    const {
      className,
      ruleId,
      ruleName,
      mountOpened = false,
      dialogId,
      createLinkFromSlug = ({ slug }) => slug,
      callbackTextCopiedSuccess,
      callbackTextCopiedError,
      ...otherProps
    } = props

    const slug = useSelector((state) => state.data?.slug)
    const requestStatus = useSelector((state) => state.status)
    const requestError = useSelector((state) => state.error)

    const [open, setOpen] = React.useState(mountOpened)

    return (
      <div
        className={clsx(testId, className)}
        data-testid={testId}
        {...otherProps}
      >
        <IconButton
          onClick={() => {
            setOpen(true)
            useCasesShareRule.createSlug({ ruleId })
          }}
          label={'Share'}
        >
          <IconShare />
        </IconButton>
        <Dialog
          id={dialogId}
          aria-labelledby={'share-rule-dialog'}
          open={open}
          onClose={() => setOpen(false)}
          contentOverlay={
            requestStatus === EAsyncStatus.REJECTED ?
              <Overlay opacity={1}>
                <Center padding={'20px'}>
                  <Typography
                    variant={'h6'}
                    align={'center'}
                  >
                    {requestError?.message}!
                  </Typography>
                </Center>
              </Overlay>
              :
              requestStatus === EAsyncStatus.PENDING ?
                <Overlay opacity={1}>
                  <Center>
                    <Loader />
                  </Center>
                </Overlay>
                :
                null
          }
        >
          <FormShareRule 
            link={slug ? createLinkFromSlug({ slug }) : '...'}
            ruleName={ruleName}
            callbackShareLinkClicked={(source) => {
              useCasesShareRule.trackShareRuleLinkCopied({ source })
            }}
            callbackTextCopiedSuccess={(source) => {
              useCasesShareRule.trackShareRuleLinkCopied({ source })
              callbackTextCopiedSuccess?.(source)
            }}
            callbackTextCopiedError={callbackTextCopiedError}
          />
        </Dialog>
      </div>
    )
  }
    
  return ViewShareRuleShareButton
}

export default createViewShareRuleShareButton
