import type { TAction } from "v2/types/TAction"

export enum EDomainActionsLoginDialogForNotAuthenticatedUsers {
  SET_OPEN = 'domains/loginDialogForNotAuthenticatedUsers/SET_OPEN',
  SET_TIMEOUT_ID = 'domains/loginDialogForNotAuthenticatedUsers/SET_TIMEOUT_ID',
  BOUNCED = 'domains/loginDialogForNotAuthenticatedUsers/BOUNCED',
}

export type TDomainActionsLoginDialogForNotAuthenticatedUsers = {
  [EDomainActionsLoginDialogForNotAuthenticatedUsers.SET_OPEN]: TAction<EDomainActionsLoginDialogForNotAuthenticatedUsers.SET_OPEN, { isOpen: boolean }>,
  [EDomainActionsLoginDialogForNotAuthenticatedUsers.SET_TIMEOUT_ID]: TAction<EDomainActionsLoginDialogForNotAuthenticatedUsers.SET_TIMEOUT_ID, { timeoutId: number | null }>,
  [EDomainActionsLoginDialogForNotAuthenticatedUsers.BOUNCED]: TAction<EDomainActionsLoginDialogForNotAuthenticatedUsers.BOUNCED>,
}

export type TDomainActionLoginDialogForNotAuthenticatedUsers = TDomainActionsLoginDialogForNotAuthenticatedUsers[keyof TDomainActionsLoginDialogForNotAuthenticatedUsers]

export const createActionLoginDialogForNotAuthenticatedUsersSucceededSetOpen = ({ isOpen }: { isOpen: boolean }): TDomainActionsLoginDialogForNotAuthenticatedUsers[EDomainActionsLoginDialogForNotAuthenticatedUsers.SET_OPEN] => ({
  type: EDomainActionsLoginDialogForNotAuthenticatedUsers.SET_OPEN,
  payload: {
    isOpen
  }
})

export const createActionLoginDialogForNotAuthenticatedUsersSucceededSetTimeoutId = ({ timeoutId }: { timeoutId: number | null }): TDomainActionsLoginDialogForNotAuthenticatedUsers[EDomainActionsLoginDialogForNotAuthenticatedUsers.SET_TIMEOUT_ID] => ({
  type: EDomainActionsLoginDialogForNotAuthenticatedUsers.SET_TIMEOUT_ID,
  payload: {
    timeoutId
  }
})

export const createActionLoginDialogForNotAuthenticatedUsersSucceededBounced = (): TDomainActionsLoginDialogForNotAuthenticatedUsers[EDomainActionsLoginDialogForNotAuthenticatedUsers.BOUNCED] => ({
  type: EDomainActionsLoginDialogForNotAuthenticatedUsers.BOUNCED
})

export const actionCreatorsLoginDialogForNotAuthenticatedUsers = {
  [EDomainActionsLoginDialogForNotAuthenticatedUsers.SET_OPEN]: createActionLoginDialogForNotAuthenticatedUsersSucceededSetOpen,
  [EDomainActionsLoginDialogForNotAuthenticatedUsers.SET_TIMEOUT_ID]: createActionLoginDialogForNotAuthenticatedUsersSucceededSetTimeoutId,
  [EDomainActionsLoginDialogForNotAuthenticatedUsers.BOUNCED]: createActionLoginDialogForNotAuthenticatedUsersSucceededBounced,
}
