import React from 'react'

import type { BoxProps } from '@mui/material'

import { Box, Icon } from '@mui/material'

export interface ImageWithCenteredIconOnTheTopProps extends BoxProps {
  icon: React.ReactNode,
  src: string,
  alt: string,
  onClick: (e: React.MouseEvent) => void,
}

const testId = 'ImageWithCenteredIconOnTheTop'
export const ImageWithCenteredIconOnTheTop = (props: ImageWithCenteredIconOnTheTopProps) => {
  const {
    sx,
    icon,
    src,
    alt,
    onClick,
    ...otherProps
  } = props

  return (
    <Box
      data-testid={testId}
      className={testId}
      sx={{
        position: 'relative',
        ...sx,
      }}
      component={'div'}
      {...otherProps}
    >
      <Box
        component={'img'}
        onClick={onClick}
        sx={{
          display: 'block',
        }}
        src={src}
        alt={alt}
      />
      <Icon
        component={'div'}
        onClick={onClick}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {icon}
      </Icon>
    </Box>
  )
}

export default ImageWithCenteredIconOnTheTop
