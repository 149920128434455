import type { TDataProviderShareRule } from "v2/dataProviders/shareRule/createDataProviderShareRule"
import type { THTTPClientError } from "v2/types"
import type { TDispatchAction } from "v2/types/TDispatchAction"
import type { TGetState } from "v2/types/TGetState"
import type { DTOErrorCreateRuleSlug, DTOTrackShareRuleLinkCopiedSource } from "./DTOs"
import type { TDomainStateShareRule } from "."

import type { TDataProviderAnalytics } from "v2/dataProviders/analytics"
import { EDomainActionsShareRule, actionCreatorsShareRule } from "./actionsShareRule"

export type TDomainUseCasesShareRule = ReturnType<typeof createUseCasesShareRule>

export const createUseCasesShareRule = ({ dataProviderAnalytics, dataProviderShareRule, dispatch, getState }: { dataProviderAnalytics: TDataProviderAnalytics, dataProviderShareRule: TDataProviderShareRule, dispatch: TDispatchAction, getState: TGetState<TDomainStateShareRule> }) => {
  return {
    trackShareRuleLinkCopied: async ({ source }: { source: DTOTrackShareRuleLinkCopiedSource }) => {
      try {
        return dataProviderAnalytics.track('Share rule copied', { source })
      } catch (error) {
        // we want to swallow that error to do not affect users if 3rd party will fail
        console.error('Tracking error for trackShareRuleLinkCopied', error)
      }
    },
    createSlug: async ({ ruleId }: { ruleId: string }) => {
      const shareRuleState = getState()
      const storedRuleId = shareRuleState.data?.ruleId

      // skip fetch if there is already fetched slug for this `ruleId`
      if(storedRuleId && storedRuleId === ruleId){
        dispatch(actionCreatorsShareRule[EDomainActionsShareRule.FETCH_BOUNCED]({
          reason: `Slug fro ruleId: ${ruleId} already fetched!`
        }))
        return
      }

      dispatch(actionCreatorsShareRule[EDomainActionsShareRule.FETCH_STARTED]())
      try {
        const dtoShareRuleSlug = await dataProviderShareRule.createSlug({ ruleId })
        dispatch(actionCreatorsShareRule[EDomainActionsShareRule.FETCH_SUCCEEDED]({ shareRuleSlug: dtoShareRuleSlug }))
      } catch (error) {
        const httpClientError = error as THTTPClientError 
        let domainError: DTOErrorCreateRuleSlug
        if(httpClientError.isAxiosError && httpClientError.response?.status === 404 ) {
          domainError = { 
            code: 404,
            message: 'Rule not found',
            origin: 'domains/shareRule' as const,
          }
        } else {
          domainError = { 
            code: 500,
            message: 'Something went wrong while creating the share rule slug',
            origin: 'domains/shareRule' as const,
          }
        }
        dispatch(actionCreatorsShareRule[EDomainActionsShareRule.FETCH_FAILED]({ error: domainError }))
      }
    }
  }
}
