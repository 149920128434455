import React from 'react';
import { observer } from 'mobx-react';
// @ts-ignore
import ErrorImage from '../../../assets/images/error-404.png';

import './style.scss';
import history from '../../../helpers/history';
import { CoinruleButton } from '../../CoinruleButton';

export const ErrorMessageComponent: React.FC<{ tryAgain: () => void }> = observer(
  ({ tryAgain }) => {
    return (
      <div className='error-component text-center'>
        <div className={'content'}>
          <div className='image-area'>
            <img src={ErrorImage} alt='' />
          </div>

          <div className='error--text-area'>
            <div className={'error--text-blue'}>Oops!</div>
            <div className={'error--text-bold'}>Looks like something went wrong.</div>
            <div className={'error--text-normal'}>
              Refreshing the page or going back to the dashboard should do the trick.
            </div>
          </div>

          <CoinruleButton
            fillType='solid'
            color='lightGreen'
            className='error--dashboard-button'
            onClick={() => {
              if (history.location.pathname !== '/') {
                history.push('/');
              }
            }}
          >
            Go to Dashboard
          </CoinruleButton>
        </div>
      </div>
    );
  }
);
