import React from 'react'

import type { BoxProps } from '@mui/material'

import { useTheme, Box } from '@mui/material'

export interface OverlayProps extends BoxProps {
  width?: React.CSSProperties['width']
  height?: React.CSSProperties['height']
  opacity?: number
  backgroundColor?: React.CSSProperties['backgroundColor']
  zIndex?: React.CSSProperties['zIndex']
}

const testId = 'Overlay'
export const Overlay = (props: OverlayProps) => {
  const theme = useTheme()

  const {
    sx,
    width = '100%',
    height = '100%',
    opacity = 0.8,
    backgroundColor = theme.palette.coinrule.basic.white,
    zIndex = 9999,
    ...otherProps
  } = props

  return (
    <Box
      data-testid={testId}
      className={testId}
      sx={{
        width,
        height,
        opacity,
        backgroundColor,
        zIndex,
        position: 'absolute',
        top: 0, 
        left: 0,
        ...sx
      }}
      {...otherProps}
    />
  )
}

export default Overlay
