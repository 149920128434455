import React, { Component } from 'react';
import uuidv4 from 'uuid/v4';

import stores from '../../stores';

import './style.scss';

const analytics = window.mixpanel;

class Footer extends Component {
  constructor() {
    super();
    this.currentYear = new Date().getFullYear();
  }

  componentDidMount() {
    if (stores.user.tabUUID === null && window.location.href !== stores.user.lastOpenPage) {
      stores.user.tabUUID = uuidv4();
    }

    if (window.location.href !== stores.user.lastOpenPage) {
      stores.user.lastOpenPage = window.location.href;

      analytics?.track &&
        analytics.track('Loaded a Page', {
          path: window.location.href.replace(window.location.origin, ''),
          uuid: stores.user.tabUUID,
        });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={`copyrightText ${this.props.mixinClass}__copyrightText`}>
          &#169; Coinrule Ltd {this.currentYear}
        </div>
        <div className={`serviceText ${this.props.mixinClass}__serviceText`}>
          <a
            href='https://coinrule.com/terms.html'
            target='_blank'
            rel='noopener noreferrer'
            className={`serviceLink ${this.props.mixinClass}__serviceLink`}>
            T&C
          </a>
          &nbsp;|&nbsp;
          <a
            href='https://coinrule.com/privacy.html'
            target='_blank'
            rel='noopener noreferrer'
            className={`serviceLink ${this.props.mixinClass}__serviceLink`}>
            Privacy
          </a>
          &nbsp;|&nbsp;
          <a
            href='https://coinrule.com/cookies.html'
            target='_blank'
            rel='noopener noreferrer'
            className={`serviceLink ${this.props.mixinClass}__serviceLink`}>
            Cookies
          </a>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;
