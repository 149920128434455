import React, { useState } from 'react';
import { Modal, Radio } from 'antd';

import stores from 'stores';
import { InputCol } from 'components/InputColumn/InputColumn';
import { useInputState } from 'hooks/useInputState';
import { errorNotification } from 'helpers/notification';
import AddRuleStore from 'stores/addRule';

interface ICreateTemplateModalProps {
  visible: boolean;
  store: AddRuleStore;
}

interface IInputState {
  category?: string;
  teaserText?: string;
  description?: string;
  complexity?: 1 | 2 | 3;
  coinsImpacted?: 'Multiple Coins' | 'Selected Coins';
  number?: number;
  unlocked?: 'byDefault' | 'byExchange' | 'byClearance';
  requiredClearance?: 1 | 2 | 3;
}

export const CreateTemplateModal = (props: ICreateTemplateModalProps) => {
  const [inputState, setInputState]: [IInputState, any] = useInputState<IInputState>({});
  const [templateCreated, setTemplateCreated] = useState(false);

  const onSubmit = async () => {
    if (checkMandatoryInputs()) {
      const success: boolean = await stores.templatesStore.createNewTemplate(
        inputState,
        props.store
      );
      if (success) {
        setTemplateCreated(true);
        setTimeout(() => window.location.reload(), 1000); // refresh the page after one second
      }
    } else {
      errorNotification('Please provide all mandatory inputs');
    }
  };

  const checkMandatoryInputs = (): boolean => {
    return Object.keys(inputState).length >= 7; // 7 inputs are mandatory
  };

  return (
    <Modal
      visible={props.visible && !templateCreated}
      title='Create New Template With Current Structure'
      onCancel={() => stores.addRule.setShowCreateTemplateModal(false)}
      onOk={onSubmit}
    >
      <InputCol
        span={24}
        name='category'
        placeholder='Template category (can be multiple, if so separate them with commas)'
        onChange={setInputState}
      />

      <InputCol
        span={24}
        name='teaserText'
        type='textArea'
        placeholder='Short (teaser) text'
        onChange={setInputState}
      />

      <InputCol
        span={24}
        name='description'
        type='textArea'
        placeholder='Full description'
        onChange={setInputState}
      />

      <div style={{ marginBottom: '10px' }}>
        <span style={{ marginRight: '10px' }}>Complexity:</span>
        <Radio.Group name='complexity' onChange={setInputState}>
          <Radio value={1}>1</Radio>
          <Radio value={2}>2</Radio>
          <Radio value={3}>3</Radio>
        </Radio.Group>
      </div>

      <div style={{ marginBottom: '10px' }}>
        <span style={{ marginRight: '10px' }}>Coins impacted:</span>
        <Radio.Group name='coinsImpacted' onChange={setInputState}>
          <Radio value='Multiple coins'>Multiple Coins</Radio>
          <Radio value='Selected coins'>Selected coins</Radio>
        </Radio.Group>
      </div>

      <InputCol
        span={24}
        name='number'
        placeholder='Position in list (0 is first)'
        onChange={setInputState}
      />

      <div style={{ marginBottom: '10px' }}>
        <span style={{ marginRight: '10px' }}>Unlocked by:</span>
        <Radio.Group name='unlocked' onChange={setInputState}>
          <Radio value='byDefault'>By Default</Radio>
          <Radio value='byExchange'>By Adding an Exchange</Radio>
          <Radio value='byClearance'>By clearance (plan)</Radio>
        </Radio.Group>
      </div>

      {inputState.unlocked === 'byClearance' && (
        <div style={{ marginBottom: '10px' }}>
          <span style={{ marginRight: '10px' }}>Required Clearance:</span>
          <Radio.Group name='requiredClearance' onChange={setInputState}>
            <Radio value={1}>1</Radio>
            <Radio value={2}>2</Radio>
            <Radio value={3}>3</Radio>
          </Radio.Group>
        </div>
      )}
    </Modal>
  );
};
