import type { THTTPClientError } from 'v2/types'
import type { TGetState } from 'v2/types/TGetState'
import type { TDispatchAction } from 'v2/types/TDispatchAction'

import type { TDataProviderConnectExchangeBinanceOAuthCreateApiKeys } from 'v2/dataProviders/connectExchangeBinanceOAuth/createDataProviderConnectExchangeBinanceOAuthCreateApiKeys'
import type { DTOErrorFetchConnectExchangeBinanceOAuthCreateApiKeys } from './DTOs/DTOErrorFetchConnectExchangeBinanceOAuthCreateApiKeys'
import type { TDomainStateConnectExchangeBinanceOAuthCreateApiKeys } from './stateFetchCreateApiKeys'

import { EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys, actionCreatorsConnectExchangeBinanceOAuthCreateApiKeys } from './actionsFetchCreateApiKeys'

export type TDomainUseCasesConnectExchangeBinanceOAuthCreateApiKeys = ReturnType<typeof createUseCasesConnectExchangeBinanceOAuthCreateApiKeys>

export const createUseCasesConnectExchangeBinanceOAuthCreateApiKeys = ({ dataProviderConnectExchangeBinanceOAuthCreateApiKeys, dispatch, getState }: { dataProviderConnectExchangeBinanceOAuthCreateApiKeys: TDataProviderConnectExchangeBinanceOAuthCreateApiKeys, dispatch: TDispatchAction, getState: TGetState<TDomainStateConnectExchangeBinanceOAuthCreateApiKeys> }) => {
  return {
    createApiKeys: async ({ accessToken }: { accessToken: string }) => {
      dispatch(actionCreatorsConnectExchangeBinanceOAuthCreateApiKeys[EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_STARTED]())
      try {
        const dtoConnectExchangeBinanceOAuthCreateApiKeys = await dataProviderConnectExchangeBinanceOAuthCreateApiKeys.createApiKeys({ accessToken })
        dispatch(actionCreatorsConnectExchangeBinanceOAuthCreateApiKeys[EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_SUCCEEDED]({ dto: dtoConnectExchangeBinanceOAuthCreateApiKeys }))
      } catch (error) {
        const httpClientError = error as THTTPClientError 
        let domainError: DTOErrorFetchConnectExchangeBinanceOAuthCreateApiKeys
        if(httpClientError.isAxiosError && httpClientError.response?.status === 404 ) {
          domainError = { 
            code: 404,
            message: 'Not found',
            origin: 'domains/connectExchangeBinanceOAuth/createApiKeys' as const,
          }
        } else if (httpClientError.isAxiosError) {
          domainError = { 
            code: 400,
            message: httpClientError.message,
            origin: 'domains/connectExchangeBinanceOAuth/createApiKeys' as const,
          }
        } else {
          domainError = { 
            code: 500,
            message: 'Something went wrong while performing createApiKeys request',
            origin: 'domains/connectExchangeBinanceOAuth/createApiKeys' as const,
          }
        }
        dispatch(actionCreatorsConnectExchangeBinanceOAuthCreateApiKeys[EDomainActionsConnectExchangeBinanceOAuthCreateApiKeys.CREATE_API_KEYS_FETCH_FAILED]({ error: domainError }))
        throw domainError
      }
    }
  }
}