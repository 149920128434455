import React from 'react';

import './style.scss';

export interface IProps {
  text?: string;
}

const Loading: React.FC<IProps> = ({ text }) => {
  return (
    <div className='background loading-page'>
      <main className='content'>
        <div className='container'>
          <div className='text'>{text || 'Verifying...'}</div>
        </div>
      </main>
    </div>
  );
};

export default Loading;
