import React from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close.svg'
import { Col } from 'antd';
import './style.scss'

const MiniChart = ({ src, onRemove }) => (
  <React.Fragment>
    <Col xs={{ span: 24 }} md={{ span: 7 }} className="miniChart__item">
      <button onClick={onRemove} className='miniChart__close'>
        <CloseIcon />
      </button>

      <iframe
        id='one'
        title='iframe'
        allowtransparency='true'
        frameBorder='0'
        src={src}
        className='miniChart'
        style={{ boxSizing: 'border-box', height: 'calc(100% - 32px)', width: '100%' }}
      ></iframe>
    </Col>
    <Col xs={{ span: 24 }} md={{ span: 1 }}></Col>
  </React.Fragment>
)

export default MiniChart;