import http from 'helpers/http';
import { Stores } from 'stores';
import { action, observable } from 'mobx';

import { Api, TradeDto } from '__generated__/ts.api.client/Api';
import { errorNotification } from 'helpers/notification';
import { ERROR_MESSAGES } from 'helpers/errors';
import { IS_PROD } from 'constants/isProd';
import { AbstractStore } from './AbstractStore';

export const tradingStatApi = (() => {
  const api = new Api();
  api.instance = http;
  return api.ts;
})();

export default class TradingStatStore extends AbstractStore {
  root: Stores;
  constructor(rootStore: Stores) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  @observable tradingStat: TradeDto | null = null;
  @observable isLoading = false;

  @action setTradingStat = (tradingStat: TradeDto) => (this.tradingStat = tradingStat);

  @action setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

  getTradingStat = async () => {
    this.setIsLoading(true);
    try {
      const response = await tradingStatApi.tradeControllerGetStatsByUserId({
        includeDemo: !IS_PROD,
      });
      this.setTradingStat(response.data);
    } catch (e) {
      console.log({ e });
      errorNotification(ERROR_MESSAGES.TRADING_STAT_ERROR);
    } finally {
      this.setIsLoading(false);
    }
  };

  getTradingVolume = () => this.tradingStat?.trade_volume || 0;
}
