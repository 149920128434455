import { CustomWindow } from 'typings/types';
import { IS_PROD } from 'constants/isProd';

declare let window: CustomWindow;

export const fbqTracking = async (action: 'track' | 'trackCustom', data: any) => {
  if (IS_PROD) {
    try {
      /* eslint-disable no-unused-expressions */
      window.fbq?.(action, data); // Facebook conversion tracking
      /* eslint-enable no-unused-expressions */
    } catch (err) {
      console.error('Error while performing Facebook conversion tracking');
    }
  }
};

export const INITIATE_CHECKOUT = 'InitiateCheckout';
