import { observable, action } from 'mobx';
import http from '../helpers/http';
import { errorNotification } from '../helpers/notification';
import { AbstractStore } from './AbstractStore';
import AddRuleStore from './addRule';

export default class RuleDetailsStore extends AbstractStore {
  constructor(rootStore) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  ruleId;
  @observable activityLog = [];
  @observable openedLogLine = [];
  @observable trades = [];
  @observable totalActivities = 1;
  @observable totalPages = 1;
  @observable showOnlyTrades = false;
  @observable showTemplatesList = false;
  @observable ruleDetailsStores = {};
  @observable isTemplateApplyingPending = false;
  @observable selectedTemplate = '';

  @action
  toggleIsTemplateApplyingPending() {
    this.isTemplateApplyingPending = !this.isTemplateApplyingPending;
  }

  @action
  toggleTemplatesList() {
    this.showTemplatesList = !this.showTemplatesList;
  }

  @action
  setSelectedTemplate(template = '') {
    this.selectedTemplate = template;
  }

  @action
  setTemplatesListVisibility(visibility) {
    this.showTemplatesList = visibility;
  }

  @action
  closeTemplatesList() {
    this.showTemplatesList = false;
  }

  @action
  setOpenedLogLine(lineId) {
    if (this.openedLogLine.indexOf(lineId) > -1) {
      this.openedLogLine.splice(this.openedLogLine.indexOf(lineId), 1);
    } else {
      this.openedLogLine.push(lineId);
    }
  }

  @action
  async toggleOnlyTradesFilter(ruleId, store, page) {
    this.showOnlyTrades = !this.showOnlyTrades;

    this.clearVariables();
    await this.getRuleActivities(ruleId, store, page);
  }

  // This function is needed to toggle "show only trades" filter
  @action
  clearVariables() {
    this.activityLog = [];
    this.openedLogLine = [];
    this.trades = [];
    this.totalActivities = 1;
    this.totalPages = 1;
  }

  @observable ruleActivities = {};
  @observable isLoadingRuleActivities = false;

  @action
  async getRuleActivities(ruleId, store, page) {
    if (this.isLoadingRuleActivities) {
      return;
    }

    try {
      this.isLoadingRuleActivities = true;
      let query = '';

      if (ruleId) {
        query = '?rule_id=' + ruleId + '&page=' + page + '&tradeFilter=' + this.showOnlyTrades; //5c9c989019ecd90820335b71
      }

      const resp = await http.get('/rule/activities' + query);

      if (resp.data) {
        if (resp.data.status === 'OK') {
          if (page === 1) {
            this.activityLog = [];
          }

          this.trades = resp.data.data.trades;
          this.totalPages = resp.data.data.totalPages;
          this.totalActivities = resp.data.data.totalActivities;

          // Sort activity by date and order number
          const sortedActivities = resp.data.data.activities.slice().sort(this.sortActivities);

          for (const i in sortedActivities) {
            const activity = sortedActivities[i];

            this.pushActivity(activity);
          }

          this.isLoadingRuleActivities = false;
        } else {
          errorNotification(resp.data.message);
        }
        return resp.data.authorized;
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoadingRuleActivities = false;
    }
  }

  hasActivityDetail(activity) {
    return Boolean(activity.d?.more?.errorMessage?.detailMessage);
  }

  @observable graphData = [];
  @observable graphCurrentData = [];
  @observable graphAllocatedData = [];
  @observable graphDateData = [];
  @observable growth = 0;
  @observable profit = 0;
  @observable isLoadingGraphData = false;
  @observable isLoadedGraphData = false;
  @observable isLoadingFeesData = false;
  @observable feesPaid = null;

  @action
  async getRuleFees(ruleId, exchangeId, baseCurrency) {
    if (this.isLoadingFeesData) {
      return;
    }

    try {
      this.isLoadingFeesData = true;

      const resp = await http.get(
        `/rules/fees?ruleId=${ruleId}&exchangeId=${exchangeId}&baseCurrency=${baseCurrency}`
      );

      if (resp.data) {
        if (resp.data.status === 'OK') {
          this.feesPaid = resp.data.data;
        } else {
          errorNotification(resp.data.message);
        }

        return resp.data.authorized;
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
    } finally {
      this.isLoadingFeesData = false;
    }
  }

  @action
  async getGraphData(rule_id) {
    if (this.isLoadingGraphData) {
      return;
    }

    this.graphData = [];
    this.growth = 0;
    this.profit = 0;

    try {
      this.isLoadingGraphData = true;

      if (!rule_id) {
        throw new Error('For graph data you have to specify rule id');
      }

      const resp = await http.get(`/rules/stats?rule_id=${rule_id}`);

      if (resp.data) {
        if (resp.data.status === 'OK') {
          if (resp.data.data[rule_id]) {
            this.graphData = resp.data.data[rule_id].chart.map((chart) => ({
              ...chart,
              ruleId: rule_id,
            }));
            this.profit = resp.data.data[rule_id].profit;
            this.growth = resp.data.data[rule_id].growth;
            this.isLoadedGraphData = true;
          }
        } else {
          errorNotification(resp.data.message);
        }

        this.isLoadingGraphData = false;

        return resp.data.authorized;
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoadingGraphData = false;
      this.isLoadingGains = false;
    }
  }

  @action
  pushActivity(activity, withWebSockets) {
    if (this.ruleId === activity.ruleId || this.ruleId === activity.r) {
      if (!this.activityLog.some((act) => act.id === activity.id)) {
        this.activityLog.push({
          id: activity.id || Math.random(),
          type: activity.t,
          status: activity.s,
          date: activity.createdAt,
          time: activity.createdAt,
          hasDetail: this.hasActivityDetail(activity),
          log_info: activity.d,
          additional_info: '',
        });

        this.activityLog = this.activityLog.slice().sort(this.sortActivities);
      }
    }
  }

  sortActivities(action_1, action_2) {
    let date_action_1, date_action_2;

    if (action_1.createdAt && action_2.createdAt) {
      date_action_1 = new Date(action_1.createdAt);
      date_action_2 = new Date(action_2.createdAt);
    } else {
      date_action_1 = new Date(action_1.date);
      date_action_2 = new Date(action_2.date);
    }

    if (date_action_1.getTime() > date_action_2.getTime()) return -1;
    if (date_action_1.getTime() < date_action_2.getTime()) return 1;

    return action_2.or - action_1.or;
  }

  @action
  initRuleDetailsStore(ruleId) {
    this.ruleDetailsStores[ruleId] = new AddRuleStore();
  }

  @action
  setRuleStats(newStats) {
    if (newStats.ruleId === this.ruleId) {
      this.profit = newStats.profit;
      this.growth = newStats.growth;

      this.graphData.push({
        ...newStats.chart,
        ruleId: newStats.ruleId,
      });

      this.isLoadedGraphData = true;
    }
  }

  @action
  setRuleId(ruleId) {
    this.ruleId = ruleId;
  }

  @action
  resetActivities() {
    this.ruleActivities = {};
  }
}
