import React, { Component } from 'react';
import { observer } from 'mobx-react';

import './style.scss';

@observer
class ErrorBlock extends Component {
  render() {
    if (!this.props.show) {
      return null;
    }

    return <div className='error-area'>{this.props.content}</div>;
  }
}

export default ErrorBlock;
