import React from 'react'

import type { StackProps } from '@mui/material'

import { useTheme, Stack } from '@mui/material'

import type { LinkProps} from 'design-system';
import { Link, Spacer, Typography, Button, ImageSingUpToViewRule } from 'design-system'

export interface FormSignUpToViewRuleProps extends StackProps {
  pathSignUp: string
  pathLogIn: string
  pathTerms: string
  pathPrivacyPolicy: string
  LinkComponent?: React.ElementType<{ to: string }>
}

const testId = 'FormSignUpToViewRule'
export const FormSignUpToViewRule = (props: FormSignUpToViewRuleProps) => {
  const {
    sx,
    pathSignUp,
    pathLogIn,
    pathTerms,
    pathPrivacyPolicy,
    LinkComponent = 'a',
    ...otherProps
  } = props

  const theme = useTheme()

  const LinkComposed = (props: LinkProps & { to: string }) => {
    return (
      <Link
        // @ts-ignore `component` prop do exist on `Link`
        component={LinkComponent}
        {...props}
      />
    )
  }

  return (
    <Stack
      data-testid={testId}
      className={testId}
      direction={'column'}
      alignItems={'center'}
      sx={{
        ...sx
      }}
      {...otherProps}
    >
      <ImageSingUpToViewRule />
      <Spacer y={40} />
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: 35,
          lineHeight: '41.02px',
          color: theme.palette.coinrule.basic.black,
          textAlign: 'center',
        }}
      >
        Sign Up to View Rule
      </Typography>
      <Spacer y={30} />
      <LinkComposed
        to={pathSignUp}
        textDecoration={'none'}
      >
        <Button
          colorVariant={'marketing'}
          sx={{ height: 49 }}
        >
          Continue
        </Button>
      </LinkComposed>
      <Spacer y={40} />
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: 15,
          lineHeight: '17.58px',
          color: theme.palette.coinrule.basic.black,
          textAlign: 'center',
        }}
      >
         Already have account?{' '}
        <LinkComposed
          to={pathLogIn}
          colorVariant={'primary'}
        >
          Log in
        </LinkComposed>
      </Typography>
      <Spacer y={30} />
      <Typography
        sx={{
          fontWeight: 300,
          fontSize: 14,
          lineHeight: '20px',
          color: theme.palette.coinrule.basic.greyDark,
          textAlign: 'center',
        }}
      >
        By signing up I agree to the{' '}
        <Link
          href={pathTerms}
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          terms of use{' '}
        </Link>
          and processing of my personal data as stated in{' '}
        <Link
          href={pathPrivacyPolicy}
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          the privacy policy
        </Link>
      </Typography>
    </Stack>
  )
}

export default FormSignUpToViewRule
