import React from 'react'
import clsx from 'clsx'

import type { TypedUseSelectorHook } from 'react-redux';

import type { FormSignUpToViewRuleProps} from 'design-system';
import { Dialog,FormSignUpToViewRule } from 'design-system'
import type { TDomainStateLoginDialogForNotAuthenticatedUsers } from 'v2/domains/loginDialogForNotAuthenticatedUsers/stateLoginDialogForNotAuthenticatedUsers';
import type { TUseCasesLoginDialogForNotAuthenticatedUsers } from 'v2/domains/loginDialogForNotAuthenticatedUsers/useCasesLoginDialogForNotAuthenticatedUsers';
export interface ViewLoginDialogForNotAuthenticatedUsersProps extends React.HTMLProps<HTMLDivElement> {
  dialogId?: string
  LinkComponent?: FormSignUpToViewRuleProps['LinkComponent'],
}

const testId = 'ViewLoginDialogForNotAuthenticatedUsers'
export const createViewLoginDialogForNotAuthenticatedUsers = ({
  useCasesLoginDialogForNotAuthenticatedUsers,
  useSelector,
}: {
  useCasesLoginDialogForNotAuthenticatedUsers: TUseCasesLoginDialogForNotAuthenticatedUsers
  useSelector: TypedUseSelectorHook<TDomainStateLoginDialogForNotAuthenticatedUsers>
}) => {
  const ViewLoginDialogForNotAuthenticatedUsers = (props: ViewLoginDialogForNotAuthenticatedUsersProps) => {
    const {
      className,
      dialogId,
      LinkComponent,
      ...otherProps
    } = props

    const isOpen = useSelector((state) => state.isOpen)

    React.useEffect(() => {
      useCasesLoginDialogForNotAuthenticatedUsers.openDialog()

      return () => useCasesLoginDialogForNotAuthenticatedUsers.cancel()
    }, [])
  
    return (
      <div
        data-testid={testId}
        className={clsx(testId, className)}
        {...otherProps}
      >
        <Dialog
          id={dialogId}
          open={isOpen}
          aria-labelledby={'login-dialog-for-not-authenticated-users'}
        >
          <FormSignUpToViewRule
            pathSignUp={"/register"}
            pathLogIn={"/login"}
            pathTerms={'https://coinrule.com/terms.html'}
            pathPrivacyPolicy={'https://coinrule.com/privacy.html'}
            LinkComponent={LinkComponent}
          />
        </Dialog>
      </div>
    )
  }
  
  return ViewLoginDialogForNotAuthenticatedUsers
}

export default createViewLoginDialogForNotAuthenticatedUsers
