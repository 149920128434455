import React from 'react'

export interface IconInfoProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconInfo'
export const IconInfo = (props: IconInfoProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      width={"1em"}
      height={"1em"}
      viewBox={"0 0 13 13"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...otherProps}
    >
      <g opacity={0.3}>
        <path
          d={"M6.49946 4.75868C6.99805 4.75868 7.40224 4.35449 7.40224 3.8559C7.40224 3.35731 6.99805 2.95312 6.49946 2.95312C6.00087 2.95312 5.59668 3.35731 5.59668 3.8559C5.59668 4.35449 6.00087 4.75868 6.49946 4.75868Z"}
          fill={"black"}
        />
        <path
          d={"M7.25125 6.32924V9.30479C7.25125 9.504 7.17211 9.69505 7.03125 9.83591C6.89039 9.97677 6.69934 10.0559 6.50013 10.0559C6.30093 10.0559 6.10988 9.97677 5.96902 9.83591C5.82816 9.69505 5.74902 9.504 5.74902 9.30479V6.32924C5.74902 6.13003 5.82816 5.93898 5.96902 5.79812C6.10988 5.65726 6.30093 5.57812 6.50013 5.57812C6.69934 5.57812 6.89039 5.65726 7.03125 5.79812C7.17211 5.93898 7.25125 6.13003 7.25125 6.32924Z"}
          fill={"black"}
        />
        <path
          d={"M6.5 0C2.91027 0 0 2.91027 0 6.5C0 10.0897 2.91027 13 6.5 13C10.0897 13 13 10.0897 13 6.5C13 2.91027 10.0897 0 6.5 0ZM10.279 10.279C9.78424 10.7822 9.19476 11.1824 8.54454 11.4567C7.89432 11.7309 7.19623 11.8736 6.49056 11.8766C5.78489 11.8796 5.08561 11.7429 4.43308 11.4742C3.78054 11.2055 3.18768 10.8103 2.68869 10.3113C2.1897 9.81232 1.79447 9.21946 1.52581 8.56692C1.25715 7.91439 1.12038 7.21511 1.12338 6.50944C1.12639 5.80377 1.26912 5.10568 1.54334 4.45546C1.81755 3.80524 2.21782 3.21576 2.72104 2.72104C3.21576 2.21782 3.80524 1.81755 4.45546 1.54334C5.10568 1.26912 5.80377 1.12639 6.50944 1.12338C7.21511 1.12038 7.91439 1.25715 8.56692 1.52581C9.21946 1.79447 9.81232 2.1897 10.3113 2.68869C10.8103 3.18768 11.2055 3.78054 11.4742 4.43308C11.7429 5.08561 11.8796 5.78489 11.8766 6.49056C11.8736 7.19623 11.7309 7.89432 11.4567 8.54454C11.1824 9.19476 10.7822 9.78424 10.279 10.279Z"}
          fill={"black"}
        />
        <path
          d={"M6.5 0C2.91027 0 0 2.91027 0 6.5C0 10.0897 2.91027 13 6.5 13C10.0897 13 13 10.0897 13 6.5C13 2.91027 10.0897 0 6.5 0ZM10.279 10.279C9.78424 10.7822 9.19476 11.1824 8.54454 11.4567C7.89432 11.7309 7.19623 11.8736 6.49056 11.8766C5.78489 11.8796 5.08561 11.7429 4.43308 11.4742C3.78054 11.2055 3.18768 10.8103 2.68869 10.3113C2.1897 9.81232 1.79447 9.21946 1.52581 8.56692C1.25715 7.91439 1.12038 7.21511 1.12338 6.50944C1.12639 5.80377 1.26912 5.10568 1.54334 4.45546C1.81755 3.80524 2.21782 3.21576 2.72104 2.72104C3.21576 2.21782 3.80524 1.81755 4.45546 1.54334C5.10568 1.26912 5.80377 1.12639 6.50944 1.12338C7.21511 1.12038 7.91439 1.25715 8.56692 1.52581C9.21946 1.79447 9.81232 2.1897 10.3113 2.68869C10.8103 3.18768 11.2055 3.78054 11.4742 4.43308C11.7429 5.08561 11.8796 5.78489 11.8766 6.49056C11.8736 7.19623 11.7309 7.89432 11.4567 8.54454C11.1824 9.19476 10.7822 9.78424 10.279 10.279Z"}
          fill={"black"}
        />
      </g>
    </svg>
  )
}

export default IconInfo
