/* eslint-disable react/no-unescaped-entities */
import React from 'react'

import type { BoxProps } from '@mui/material'

import { useTheme, Box, Typography } from '@mui/material'

import exchangesAddApiTutorialData from './exchangesAddApiTutorialData'
import { Timeline } from '@mui/lab'

import type { TExchangesWithTutorials } from 'design-system'
import TimelineItem from 'design-system/molecules/TimelineItem'

import IconPlayCircle from 'design-system/icons/IconPlayCircle'

import videoImage from './thumbnail.svg'

export type TExchangeAddApiTutorial = {
  title: string,
  steps: {
    title: React.ReactNode,
    video: string,
    videoLength: number,
    text: React.ReactNode,
    url?: string,
    shouldReceiveIps?: boolean,
  }[],
}

export interface ExchangesAddApiTutorialProps extends BoxProps {
  market: TExchangesWithTutorials,
  ips?: string,
  onThumbnailClick: (e: React.MouseEvent, videoUrl: string, market: string, index: number) => void,
}

const testId = 'ExchangesAddApiTutorial'
export const ExchangesAddApiTutorial = (props: ExchangesAddApiTutorialProps) => {
  const {
    sx,
    ips,
    market,
    onThumbnailClick,
    ...otherProps
  } = props

  const theme = useTheme()

  const data: TExchangeAddApiTutorial | undefined = exchangesAddApiTutorialData[market]

  return (
    <Box
      data-testid={testId}
      className={testId}
      sx={{
        ...sx
      }}
      {...otherProps}
    >
      <Typography
        component={'h1'}
        sx={{
          fontSize: 24,
          fontWeight: 600,
          lineHeight: 1.35,
          color: theme.palette.coinrule.basic.blackBlue,
          textTransform: 'capitalize',
          marginBottom: '25px'
        }}
      >
        {data?.title}
      </Typography>
      <Timeline
        position={'right'}
        sx={{
          margin: 0,
          padding: 0,
        }}
      >
        {
          data && data.steps.map((step, index, arr) => {
            const isLast = index === (arr.length - 1)
            return (
              <TimelineItem
                key={index}
                showConnector={isLast}
                headerTitle={step.title}
                url={step.url}
                shouldReceiveIps={step.shouldReceiveIps}
                ips={ips}
                imgSrc={videoImage}
                icon={<IconPlayCircle style={{ fill: theme.palette.coinrule.brand.primary }} />}
                videoLength={step.videoLength}
                onThumbnailClick={(e) => onThumbnailClick(e, step.video, market, index)}
                text={step.text}
                dotContent={index + 1}
              />
            )
          })
        }
      </Timeline>
    </Box>
  )
}

export default ExchangesAddApiTutorial
