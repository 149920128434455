import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import React from 'react';
import Popover from '../../../../components/Popover';
import { Input, Select } from 'antd';
import {
  ACTION_TYPE,
  COINS_SYMBOL,
  CONDITION_OPERATORS,
  CONDITION_TYPE,
  DEFAULT_AMOUNT_SYMBOL,
  PERCENTAGE_SYMBOL,
  TRADE_TYPES,
  TRIGGERS,
  CONDITION_TYPES,
  OPERATOR_TYPE,
  OPERATOR_TYPES,
  SYMBOL_TYPES,
  ORDER_TYPES,
} from '../../../../stores/addRule';
import {
  formattedCounter,
  getFieldCssClass,
  retrieveCoinNameFromSequence,
} from '../../../../helpers/utils';

import stores from '../../../../stores';
import ShowCoinsValue, { checkIfShowCoinValue } from '../../ShowCoinsValue';
import { Container } from '../../../../components/Help';
import Help from '../../../../components/Help';
import RemoveSequence from '../../../../components/Sequences/RemoveSequence';
import SimpleSequenceLoader from '../../../../components/Loading/SimpleSequenceLoader';
import { EXCHANGES_TYPE } from '../../../../constants/exchanges';
import { prepareInputValue } from 'helpers/helpers';
import OrderTypeDropdown from './OrderTypeDropdown';
import { AddRuleStore, NewSequence } from 'stores/addRuleStore.interface';
import { Action } from 'components/Rule/DetailTextSequence/action.type';
const Option = Select.Option;

interface IProps {
  actionId: number;
  sequenceIndex: number;
  index: number;
  item: Action;
  parent: any;
  showAndText: boolean;
  store: AddRuleStore<NewSequence>;
}

// TODO potentially duplicate + wrong place for this interface
interface ICoin {
  base: string;
  quote: string;
}

@observer
class ActionSequencePart extends React.Component<IProps> {
  isLeverage() {
    const exchangeType = stores.exchangeInfo.exchangeType[this.props.store.rule.ex];
    return exchangeType && exchangeType === EXCHANGES_TYPE.LEVERAGE;
  }

  checkForPrevAmountOrBalance(sequenceIndex: number) {
    const seqParallelIndex = this.props.store.seqInit.getParallelOperator();
    const seqThenIndex = this.props.store.seqInit.getThenOperator();
    const seqWaitIndex = this.props.store.seqInit.getWaitOperator();

    const allActionIndexes = this.props.store.seqInit.findAllIndexesSequenceBeforeByType(
      ACTION_TYPE,
      sequenceIndex
    );

    const previousCondIndex = this.props.store.seqInit.findIndexSequenceBeforeByType(
      CONDITION_TYPE,
      sequenceIndex
    );

    const prevCond = this.props.store.seqInit.sequences[previousCondIndex]?.data.conditions
      ? this.props.store.seqInit.sequences[previousCondIndex]?.data.conditions[0]
      : null;

    let validSr = false;

    if (this.props.item.ort.indexOf('from_sequence') > -1) {
      const sr = this.props.item.ort.split('_')[2];
      validSr = allActionIndexes.includes(Number(sr));
    } else if (this.props.item.ort === 'that_coin' && this.props.item.sr >= 0) {
      validSr = allActionIndexes.includes(Number(this.props.item.sr));
    }

    return (
      allActionIndexes.length > 0 &&
      ((sequenceIndex > seqThenIndex && seqThenIndex > 0) ||
        (sequenceIndex > seqParallelIndex && seqParallelIndex > 0) ||
        (sequenceIndex > seqWaitIndex && seqWaitIndex > 0)) &&
      this.props.item.v.s === PERCENTAGE_SYMBOL &&
      ((this.props.item.ort === 'that_coin' &&
        ((prevCond?.sr !== null && prevCond.sr >= 0) || allActionIndexes.length === 1)) ||
        validSr)
    );
  }

  checkIfExchangeLoaded() {
    return (
      this.props.store.rule.ex &&
      stores.userInfo.balances[this.props.store.rule.ex] &&
      stores.exchangeInfo.assets[this.props.store.rule.ex] &&
      ((this.isLeverage() && stores.exchangeInfo.instruments[this.props.store.rule.ex]) ||
        stores.exchangeInfo.markets[this.props.store.rule.ex])
    );
  }

  renderAmountSymbols(index: number, sequenceIndex: number) {
    let disallowOnQuickEdit =
      this.props.item.v.s === COINS_SYMBOL ? this.props.store.isQuickEdit() : false;

    return (
      <Popover text={`Select coin`}>
        <Select
          size={'default'}
          style={{ width: 90 }}
          onChange={(value: string) =>
            this.props.store.changeActionAmountSymbol(index, value, sequenceIndex)
          }
          value={this.props.item.v.s}
          disabled={disallowOnQuickEdit}>
          <Option value={this.props.store.tempCurrency}>{this.props.store.tempCurrency}</Option>
          <Option value={PERCENTAGE_SYMBOL}>%</Option>
          <Option value={COINS_SYMBOL} disabled={this.props.store.isQuickEdit()}>
            coins
          </Option>
          {/*{options}*/}
        </Select>
      </Popover>
    );
  }

  amountInputPlaceholderChange(isLeverageExchange: boolean) {
    if (this.props.item.v.s === '%' || isLeverageExchange) {
      return '10';
    }

    return '2.5';
  }

  renderAmountInput(index: number, sequenceIndex: number, isLeverageExchange: boolean) {
    const { item } = this.props;
    const inputClass = getFieldCssClass(item.v.iv, true);

    // Max percentage is 100
    if (+item.v.iv > 100 && item.v.s === '%') {
      this.props.store.changeActionAmountValue(
        index,
        prepareInputValue(item.v.iv, item.v.s),
        sequenceIndex,
        isLeverageExchange
      );
    }

    return (
      <span className={inputClass}>
        <Input
          addonAfter={!isLeverageExchange && this.renderAmountSymbols(index, sequenceIndex)}
          size={'default'}
          value={this.props.item.v.iv}
          placeholder={this.amountInputPlaceholderChange(isLeverageExchange)}
          onChange={(e) =>
            this.props.store.changeActionAmountValue(
              index,
              prepareInputValue(e.target.value, item.v.s),
              sequenceIndex,
              isLeverageExchange
            )
          }
        />
      </span>
    );
  }

  renderThatCoinOrCoinsInCondition(sequenceIndex: number, isLeverageExchange: boolean) {
    let options = [];
    const options2 = [];
    const allSequences = this.props.store.seqInit.sequences;
    const previousCondition = this.props.store.seqInit.findIndexSequenceBeforeByType(
      CONDITION_TYPE,
      sequenceIndex
    );
    const prevCond = allSequences[previousCondition]?.data.conditions
      ? allSequences[previousCondition]?.data.conditions[0]
      : null;
    const conditions = this.props.store.seqInit.findAllSequenceByTypeBefore(
      CONDITION_TYPE,
      sequenceIndex
    );

    const conditionsLeng = conditions.length;
    const conditionSequences = conditions.map((condIndex: number) =>
      toJS(allSequences[condIndex]?.data.conditions?.[0])
    );
    const coinOrInstrument = isLeverageExchange ? 'instrument' : 'coin';
    let addOption = true;
    let thatCoinAdded = false;

    const thenIndex = this.props.store.seqInit.findIndexSequenceBeforeByType(
      OPERATOR_TYPE,
      sequenceIndex,
      OPERATOR_TYPES.THEN
    );
    const parallelIndex = this.props.store.seqInit.findIndexSequenceBeforeByType(
      OPERATOR_TYPE,
      sequenceIndex,
      OPERATOR_TYPES.PARALLEL
    );
    const waitIndex = this.props.store.seqInit.findIndexSequenceBeforeByType(
      OPERATOR_TYPE,
      sequenceIndex,
      OPERATOR_TYPES.WAIT
    );

    if (this.props.store.rule.tr !== TRIGGERS.DIRECT_ORDER) {
      if (
        !thatCoinAdded &&
        ((previousCondition > -1 &&
          !!prevCond &&
          prevCond.ifc !== CONDITION_TYPES.TRADINGVIEW_SIGNAL) ||
          (thenIndex > -1 && previousCondition > thenIndex) ||
          (parallelIndex > -1 && previousCondition > parallelIndex) ||
          (waitIndex > -1 && previousCondition > waitIndex))
      ) {
        options.push(
          <Option key={998} value={'that_coin'}>
            {isLeverageExchange && this.props.store.instrumentQuote
              ? this.props.store.instrumentQuote
              : `that ${coinOrInstrument}`}
          </Option>
        );
        thatCoinAdded = true;
      }

      if (waitIndex > -1 || thenIndex > -1 || parallelIndex > -1) {
        const allActionIndexes = this.props.store.seqInit.findAllIndexesSequenceBeforeByType(
          ACTION_TYPE,
          sequenceIndex
        );

        let conditionAfterOperator =
          (thenIndex > -1 && previousCondition > thenIndex) ||
          (parallelIndex > -1 && previousCondition > parallelIndex) ||
          (waitIndex > -1 && previousCondition > waitIndex);

        if (conditionAfterOperator) {
          // if we only have 1 condition after THEN/ANY TIME operator and that one is of type RULE or SIGNAL, set to false
          if (previousCondition - 1 === (thenIndex || parallelIndex)) {
            if (prevCond.ift === SYMBOL_TYPES.RULE || prevCond.ift === SYMBOL_TYPES.SIGNAL) {
              conditionAfterOperator = false;
            }
          }
        }

        if (allActionIndexes.length > 0) {
          if ((allActionIndexes.length === 1 || conditionAfterOperator) && !thatCoinAdded) {
            options.push(
              <Option title='' value={'that_coin'} key={sequenceIndex}>
                {isLeverageExchange && this.props.store.instrumentQuote
                  ? this.props.store.instrumentQuote
                  : `that ${coinOrInstrument}`}
              </Option>
            );
            thatCoinAdded = true;
          } else if (!conditionAfterOperator) {
            // reset options, we don't want to show that_coin in this case, only from_sequence options
            options = [];
            thatCoinAdded = true;

            for (let i in allActionIndexes) {
              let text = formattedCounter(Number(i) + 1);
              options.push(
                <Option
                  title=''
                  value={'from_sequence_' + allActionIndexes[i]}
                  key={sequenceIndex + ' ' + i}>
                  {allActionIndexes.length === 1
                    ? `that ${coinOrInstrument}`
                    : `${coinOrInstrument} from ${text} action`}
                </Option>
              );
            }
          }
        }
      }
    }

    if (conditionsLeng > 1 && thenIndex < 0 && parallelIndex < 0) {
      //console.log('===================================================================');
      //console.log('condition 1 triggered');

      for (let i = 0; i < conditionsLeng; i++) {
        if (i > 0) {
          const prevCond = allSequences[i - 1]?.data.conditions?.[0];
          if (prevCond?.co !== CONDITION_OPERATORS.AND) {
            addOption = false;
          }
        }

        const condIndex = conditions[i];
        const currentCond = allSequences[condIndex]?.data.conditions?.[0];

        if (
          !(
            currentCond?.ifc === CONDITION_TYPES.RULE_IN_LOSS ||
            currentCond?.ifc === CONDITION_TYPES.RULE_IN_PROFIT ||
            currentCond?.ifc === CONDITION_TYPES.TRADINGVIEW_SIGNAL
          )
        ) {
          if (
            !(
              conditionSequences.some(
                (cond) => cond.ifc === 'from_sequence' || cond.ifc === 'that_coin'
              ) && conditionsLeng > 1
            )
          ) {
            const text = formattedCounter(i + 1);
            const finalText = `${coinOrInstrument} from ${text} condition`;
            options2.push(
              <Option value={'from_sequence_' + previousCondition + '_' + i} key={finalText}>
                {finalText}
              </Option>
            );
          } else if (
            conditionSequences.some((cond) => cond.ifc === 'that_coin') &&
            conditionsLeng > 1 &&
            !thatCoinAdded
          ) {
            options2.push(
              <Option value={'from_sequence_' + previousCondition + '_' + i} key={786}>
                that {coinOrInstrument}
              </Option>
            );
            thatCoinAdded = true;
          }
        }
      }
    } else if (
      this.props.store.rule.tr === TRIGGERS.TIME &&
      previousCondition >= 0 &&
      !thatCoinAdded
    ) {
      // console.log('===================================================================');
      // console.log('condition 2 triggered');
      const previousAction = this.props.store.seqInit.findIndexSequenceBeforeByType(
        ACTION_TYPE,
        sequenceIndex
      );

      options.push(
        <Option key={989} value={'from_sequence_' + (Number(previousAction) - 1)}>
          that {coinOrInstrument}
        </Option>
      );
      thatCoinAdded = true;
    } else if (
      this.props.store.rule.tr === TRIGGERS.EVENT &&
      previousCondition !== -1 &&
      !thatCoinAdded &&
      prevCond?.ifc !== CONDITION_TYPES.TRADINGVIEW_SIGNAL
    ) {
      // console.log('===================================================================');
      // console.log('condition 4 triggered');
      options.push(
        <Option key={997} value={'from_sequence_' + Number(previousCondition)}>
          that {coinOrInstrument}
        </Option>
      );
      thatCoinAdded = true;
    }

    if (!addOption) {
      return options;
    }

    return options.concat(options2);
  }

  renderCoinListSelect(sequenceIndex: number, isLeverageExchange: boolean) {
    let coins: any[] = [];
    let thatCoinOrCoinsInCondition: any[] = [];
    let genericOptions = [];
    let allPosibleCoins: any[] = [];
    let defaultValue;
    let emptyOption: React.ReactNode = (
      <Option title={''} value={'---'}>
        {'---'}
      </Option>
    );

    thatCoinOrCoinsInCondition = this.renderThatCoinOrCoinsInCondition(
      sequenceIndex,
      isLeverageExchange
    );

    defaultValue = this.prepareCoinListValue(
      sequenceIndex,
      thatCoinOrCoinsInCondition.length > 0 && isLeverageExchange
    );

    if (!this.props.store.seqInit.isThatCoinAllowed(sequenceIndex)) {
      thatCoinOrCoinsInCondition = [];
      defaultValue =
        !Array.isArray(defaultValue) &&
        (defaultValue === 'that_coin' || defaultValue.substring(0, 13) === 'from_sequence')
          ? '---'
          : defaultValue;
    }

    if (
      thatCoinOrCoinsInCondition.length === 1 &&
      this.props.store.seqInit.sequences[sequenceIndex].data.actions[0].ort !== '---'
    ) {
      emptyOption = null;
    }

    if (this.props.parent.checkIfExchangeLoaded()) {
      if (isLeverageExchange) {
        const maxIndexForInstruments =
          this.props.store.getDisableListOfLeverageInstrumentsAfterIndex();

        if (maxIndexForInstruments < 0 || maxIndexForInstruments >= sequenceIndex) {
          allPosibleCoins = Object.keys(stores.exchangeInfo.instruments[this.props.store.rule.ex])
            .sort()
            .map((instr, i) => {
              const instrObj = stores.exchangeInfo.instruments[this.props.store.rule.ex][instr];
              const name = instrObj.symbol.toUpperCase();
              const symbol = instrObj.symbol;
              return (
                <Option title='' key={symbol} value={symbol}>
                  {name}
                </Option>
              );
            });
        }
      } else {
        coins = Object.keys(stores.exchangeInfo.assets[this.props.store.rule.ex]);

        if (
          stores.exchangeInfo.markets &&
          stores.exchangeInfo.markets[this.props.store.rule.ex] &&
          stores.exchangeInfo.markets[this.props.store.rule.ex].length > 0
        ) {
          if (this.props.item.q !== '---') {
            const quote =
              this.props.store.seqInit.hasErrorValidationWallet &&
              this.props.store.seqInit.getErrorClass(sequenceIndex, 'ort') === 'hasError'
                ? 'BTC'
                : this.props.item.q;
            coins = [];
            stores.exchangeInfo.markets[this.props.store.rule.ex].filter(
              (coin: ICoin, i: number) => {
                if (coin.quote === quote) {
                  coins.push(coin.base);
                  return true;
                } else if (coin.base === quote) {
                  coins.push(coin.quote);
                  return true;
                }

                return false;
              }
            );
          }
        }

        allPosibleCoins = coins.sort().map((asset, i) => {
          if (stores.exchangeInfo.assets[this.props.store.rule.ex][asset]) {
            const assetObj = stores.exchangeInfo.assets[this.props.store.rule.ex][asset];
            const name =
              assetObj?.name?.toUpperCase() === asset ? asset : `${asset} ${assetObj?.name}`;

            if (asset === this.props.item.q) {
              return null;
            }

            return (
              <Option key={asset} value={asset}>
                {name}
              </Option>
            );
          }

          return null;
        });

        genericOptions.push(
          <Option key={998} value='-sep2-' disabled={true}>
            {' '}
          </Option>
        );
        genericOptions.push(
          <Option key={997} value={'best_market'}>
            best performing coin on the market
          </Option>
        );
        genericOptions.push(
          <Option key={996} value={'worst_market'}>
            worst performing coin on the market
          </Option>
        );
        genericOptions.push(
          <Option key={995} value='-sep3-' disabled={true}>
            {' '}
          </Option>
        );
        genericOptions.push(
          <Option key={994} value={'best_mine'}>
            best performing coin in my portfolio
          </Option>
        );
        genericOptions.push(
          <Option key={993} value={'worst_mine'}>
            worst performing coin in my portfolio
          </Option>
        );
        genericOptions.push(
          <Option key={992} value='-sep4-' disabled={true}>
            {' '}
          </Option>
        );
      }
    } else {
      allPosibleCoins.push(
        <Option value='No exchange' disabled={true} key={0}>
          No exchange selected
        </Option>
      );
    }

    const shouldBeDisabled =
      (thatCoinOrCoinsInCondition.length > 0 && isLeverageExchange) ||
      this.props.store.isQuickEdit();

    return (
      <Popover text='Select Coin'>
        <Select
          showSearch
          size={'default'}
          onChange={this.handleCoinListChange.bind(this, sequenceIndex)}
          disabled={shouldBeDisabled}
          value={defaultValue}
          optionFilterProp='children'
          dropdownClassName='CoinListSelect__dropdown'
          filterOption={(input, option) => {
            if (option.props.children) {
              return (option.props.children as any).toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }
            return false;
          }}
          className={
            !this.props.store.isQuickEdit()
              ? getFieldCssClass(
                  this.prepareCoinListValue(
                    sequenceIndex,
                    thatCoinOrCoinsInCondition.length > 0 && isLeverageExchange
                  ),
                  true,
                  true
                )
              : ''
          }>
          {emptyOption}
          {thatCoinOrCoinsInCondition.length > 0 ? thatCoinOrCoinsInCondition : null}
          {genericOptions}
          {allPosibleCoins}
        </Select>
      </Popover>
    );
  }

  renderExitQtyType(index: number, sequenceIndex: number) {
    const previousCondIndex = this.props.store.seqInit.findIndexSequenceBeforeByType(
      CONDITION_TYPE,
      sequenceIndex
    );

    const prevCond = this.props.store.seqInit.sequences[previousCondIndex]?.data.conditions
      ? this.props.store.seqInit.sequences[previousCondIndex]?.data.conditions[0]
      : null;

    let actionType;
    if (!prevCond?.sr) {
      const allActionIndexes = this.props.store.seqInit.findAllIndexesSequenceBeforeByType(
        ACTION_TYPE,
        sequenceIndex
      );

      if (allActionIndexes.length === 1) {
        actionType =
          this.props.store.seqInit.sequences[Number(allActionIndexes[0])]?.data?.actions[0].at;
      } else {
        const currentSr = this.props.store.seqInit.sequences[sequenceIndex].data.actions[0].sr;

        if (this.props.store.seqInit.sequences[Number(currentSr)]?.data?.actions) {
          actionType = this.props.store.seqInit.sequences[Number(currentSr)].data.actions[0].at;
        }
      }
    } else {
      if (this.props.store.seqInit.sequences[Number(prevCond?.sr)]?.data?.actions) {
        actionType = this.props.store.seqInit.sequences[Number(prevCond?.sr)]?.data?.actions[0].at;
      }
    }

    const prevAmountText = actionType === TRADE_TYPES.BUY ? 'amount bought of' : 'amount sold of';

    if (!actionType) {
      return null;
    }

    return (
      <Popover text={'Select your exit quantity'}>
        <Select
          size={'default'}
          showSearch
          onChange={(value: string) =>
            this.props.store.changeExitQtyType(index, value, sequenceIndex)
          }
          value={this.props.item.eqt ? this.props.item.eqt : 'balance'}
          className={getFieldCssClass(
            this.props.item.eqt ? this.props.item.eqt : 'balance',
            true,
            true
          )}>
          <Option value='amount'>{prevAmountText}</Option>
          <Option value='balance'>
            {this.props.item.at === 'buy' ? 'maximum amount of' : 'total balance of'}
          </Option>
        </Select>
      </Popover>
    );
  }

  renderQuoteSelect(index: number, sequenceIndex: number, returnOnlyOptions = false) {
    let options = null;
    const allSequences = this.props.store.seqInit.sequences;

    const getPreviousCondSymbols = (currentIndex: number) => {
      let blockEndReached = false;

      for (let i = currentIndex - 1; i >= 0; i--) {
        const sq = allSequences[i];
        const prevSq = allSequences[i - 1];

        if (blockEndReached) {
          break;
        } else {
          if (sq.data.conditions?.length > 0) {
            const cond = sq.data.conditions[0];

            if (!(prevSq?.data.conditions?.length > 0)) {
              blockEndReached = true;
            }

            if (
              cond.ifs.length > 0 &&
              (cond.co !== CONDITION_OPERATORS.AND || !(prevSq?.data.conditions?.length > 0))
            ) {
              return cond.ifs;
            }
          }
        }
      }

      return [];
    };

    if (this.checkIfExchangeLoaded() && stores.info.checkLastSelectedExchange()) {
      const assets = toJS(stores.exchangeInfo.assets[this.props.store.rule.ex]);
      const fiats = toJS(stores.exchangeInfo.fiats[this.props.store.rule.ex]);

      const currentSequenceCoin = retrieveCoinNameFromSequence(
        allSequences,
        sequenceIndex,
        this.isLeverage()
      );
      const specialBases = [
        'any_coin',
        'any_my_coin',
        'best_mine',
        'worst_mine',
        'best_market',
        'worst_market',
        'rule_in_profit',
        'rule_in_loss',
        'tradingview_signal',
      ];

      let coins: string[] = stores.info.lastSelectedExchange.quote_coins.filter(
        (coin: string) => assets?.[coin] || fiats?.[coin]
      );

      if (
        stores.exchangeInfo.markets?.[this.props.store.rule.ex]?.length > 0 &&
        (this.props.item.b.length > 0 ||
          (currentSequenceCoin && specialBases.indexOf(currentSequenceCoin) === -1))
      ) {
        const base = this.props.item.b[0] || currentSequenceCoin;

        coins = stores.exchangeInfo.markets[this.props.store.rule.ex]
          .map((coin: ICoin, i: number) => {
            if (base === '---') return coin.quote;
            else if (coin.base === base) return coin.quote;
            else if (coin.quote === base) return undefined;

            if (
              this.props.store.seqInit.hasErrorValidationWallet &&
              this.props.store.seqInit.getErrorClass(sequenceIndex, 'q') === 'hasError'
            ) {
              return coin.quote;
            }

            return undefined;
          })
          .filter((coin: ICoin) => coin !== undefined);
      }

      coins = [...new Set(coins)];

      options = coins.sort().map((coin: string, i: number) => {
        let coinName = coin;

        if (!returnOnlyOptions) {
          coinName += ' wallet';
        }

        if (returnOnlyOptions && coin === DEFAULT_AMOUNT_SYMBOL) {
          return null; // the coin is already in the list
        }

        if (this.props.item.b.length > 0 && this.props.item.b[0] === coin) {
          return null;
        }

        if (this.props.item.ort === CONDITION_TYPES.THAT_COIN) {
          if (getPreviousCondSymbols(sequenceIndex).includes(coin)) {
            return null;
          }
        }

        return (
          <Option key={i} value={coin}>
            {coinName}
          </Option>
        );
      });
    }

    if (!options) {
      options = (
        <Option value='No exchange' disabled={true}>
          No exchange selected
        </Option>
      );
    }

    if (returnOnlyOptions) {
      return options;
    }

    return (
      <Popover
        text={
          this.props.item.at === TRADE_TYPES.BUY ? 'Buy with this Wallet' : 'Sell to this Wallet'
        }>
        <Select
          size={'default'}
          showSearch
          onChange={(value: string) =>
            this.props.store.changeActionQuote(index, value, sequenceIndex)
          }
          value={this.props.item.q}
          className={
            !this.props.store.isQuickEdit() ? getFieldCssClass(this.props.item.q, true) : ''
          }
          disabled={this.props.store.isQuickEdit()}>
          <Option value='---'>---</Option>
          {options}
        </Select>
      </Popover>
    );
  }

  handleCoinListChange = (sequenceIndex: number, values: string[]) => {
    this.props.store.changeActionBase(
      this.props.index,
      values,
      sequenceIndex,
      true,
      stores.exchangeInfo.exchangeType[this.props.store.rule.ex] === EXCHANGES_TYPE.LEVERAGE
    );
  };

  prepareCoinListValue(sequenceIndex: number, alwaysThatCoin = false) {
    if (alwaysThatCoin) {
      return 'that_coin';
    }

    const allSequences = this.props.store.seqInit.sequences;

    if (allSequences[sequenceIndex]) {
      if (allSequences[sequenceIndex].data.actions[0].ort === 'that_coin') {
        const prevCondIndex = this.props.store.seqInit.findIndexSequenceBeforeByType(
          CONDITION_TYPE,
          sequenceIndex
        );

        const prevCond = allSequences[prevCondIndex]?.data.conditions
          ? allSequences[prevCondIndex]?.data.conditions?.[0]
          : null;

        if (
          prevCond?.ifc === CONDITION_TYPES.RULE_IN_PROFIT ||
          prevCond?.ifc === CONDITION_TYPES.RULE_IN_LOSS ||
          prevCond?.ifc === CONDITION_TYPES.TRADINGVIEW_SIGNAL
        ) {
          return '---';
        }
        return 'that_coin';
      } else if (allSequences[sequenceIndex].data.actions[0].ort === 'best_market') {
        return 'best_market';
      } else if (allSequences[sequenceIndex].data.actions[0].ort === 'worst_market') {
        return 'worst_market';
      } else if (allSequences[sequenceIndex].data.actions[0].ort === 'best_mine') {
        return 'best_mine';
      } else if (allSequences[sequenceIndex].data.actions[0].ort === 'worst_mine') {
        return 'worst_mine';
      } else if (allSequences[sequenceIndex].data.actions[0].ort === '---') {
        return '---';
      } else if (allSequences[sequenceIndex].data.actions[0].ort === 'base') {
        return allSequences[sequenceIndex].data.actions[0].b[0];
      } else {
        return allSequences[sequenceIndex].data.actions[0].ort;
      }
    }

    return '';
  }

  decideIfToShowQuoteDropdown() {
    return (
      [PERCENTAGE_SYMBOL, COINS_SYMBOL, stores.user.user.user.baseCurrency].indexOf(
        this.props.item.v.s
      ) >= 0
    );
  }

  renderCoinPrice(coins: string | ICoin[]) {
    // TODO not sure about type
    return <ShowCoinsValue parentProps={this.props} coins={coins} />;
  }

  renderCoinListOrText(coins: string | ICoin[]) {
    // TODO not sure about type
    if (
      ['best_market', 'worst_market', 'best_mine', 'worst_mine', 'from_sequence'].includes(
        this.props.item.ort
      )
    ) {
      return (
        <div className='coin-value-area'>
          <div className='coin-area'>by 24h price change</div>
        </div>
      );
    }

    return this.renderCoinPrice(coins);
  }

  renderOrderTypeDropdown(index: number, sequenceIndex: number, currentExchangeId: number) {
    const exchangesArray = stores.info.exchanges;
    const currentExchange = toJS(
      exchangesArray.find((exchange: any) => exchange.id === currentExchangeId)
    );

    const availableOrderTypes = new Set(currentExchange?.order_types || []);
    const allOrderTypes = [
      ...new Set([ORDER_TYPES.LIMIT, ORDER_TYPES.MARKET, ...availableOrderTypes]),
    ] as string[];
    const dropdownDefaultValue = currentExchange?.order_types?.includes?.(this.props.item.ot)
      ? this.props.item.ot
      : currentExchange?.order_types[0];

    return (
      <OrderTypeDropdown
        orderTypes={allOrderTypes.map((orderType) => ({
          orderType,
          disabled: !availableOrderTypes.has(orderType),
        }))}
        defaultValue={
          currentExchange?.order_types?.includes(this.props.item.ot)
            ? this.props.item.ot
            : currentExchange?.order_types[0]
        }
        handleOrderTypeChange={(value) =>
          this.props.store.changeActionOrderType(index, value, sequenceIndex)
        }
        disabledText={`This order type is not available for ${currentExchange.name}`}
        className={getFieldCssClass(dropdownDefaultValue, true)}
      />
    );
  }

  render() {
    const { index, store } = this.props;
    const sequenceIndex = this.props.sequenceIndex;

    let amountInputClass =
      'input-grey' +
      (checkIfShowCoinValue(this.props.item.v?.s).length > 0 ? ' abs-coin-value' : '');
    amountInputClass += ' ' + this.props.store.seqInit.getErrorClass(sequenceIndex, 'v');
    const isLeverageExchange =
      stores.exchangeInfo.exchangeType[this.props.store.rule.ex] === EXCHANGES_TYPE.LEVERAGE;

    let baseSelectClass = 'symbols select-grey '; //+
    baseSelectClass += ' ' + this.props.store.seqInit.getErrorClass(sequenceIndex, 'ort');

    let quoteSelectClass =
      'select-grey' + (checkIfShowCoinValue(this.props.item.q).length > 0 ? ' abs-coin-value' : '');
    quoteSelectClass += ' ' + this.props.store.seqInit.getErrorClass(sequenceIndex, 'q');

    const help = (
      <Container className={`helpTooltip`}>
        <div className={`helpTooltip__title`}>Select An Action</div>
        <div>
          Choose the amount you want to allocate to this specific trade. You can either choose to
          buy a percentage of your wallet balance (i.e. buy 50% of BTC with my ETH wallet) or a
          fixed amount (i.e. buy 50 coins of NEO with my BTC wallet).
        </div>
        <div>
          You can select to buy/sell not only specific coins but also the best/worst performing coin
          on the market by 24h price increase/decrease, coins that meet your conditions as defined
          in the previous step (‘that coin’) or also limit your trade to coins that you currently
          have in your wallet.
        </div>

        <div className={`helpTooltip__secondaryTitle`}>Select Market or Limit Order</div>
        <div>
          Market orders will be executed immediately at available market prices. The final executed
          price could differ from the price that triggered the order.
        </div>
        <div>
          Limit orders will be executed only if the market conditions allow to trade at the
          specified price or better. A limit order is not guaranteed to execute. To increase the
          likelihood of executions, you can set a Safety Margin Range for Limit Orders in the
          Settings.
        </div>
        <div>
          <a
            href='https://coinrule.com/help/knowledgebase/coinrule-trading-bot-order/'
            target='_blank'
            rel='noreferrer noopener'>
            Read more here
          </a>
        </div>
      </Container>
    );

    if (
      stores.exchangeInfo.markets &&
      stores.exchangeInfo.markets[this.props.store.rule.ex] &&
      stores.exchangeInfo.markets[this.props.store.rule.ex].length > 0
    ) {
      if (this.props.item.b?.length > 0 && this.props.item.q) {
        stores.exchangeInfo.markets[this.props.store.rule.ex].filter(
          (coin: ICoin, i: number) => coin.base
        );
      }
    }

    const symbolsList = (
      <div className={baseSelectClass}>
        {this.renderCoinListSelect(sequenceIndex, isLeverageExchange)}
        {this.renderCoinListOrText(this.props.item.b)}
      </div>
    );

    return (
      <div className={`newRuleFormSection content`}>
        {this.props.store.rule.tr !== TRIGGERS.DIRECT_ORDER ||
        (this.props.store.rule.tr === TRIGGERS.DIRECT_ORDER && sequenceIndex > 0) ? (
          <RemoveSequence
            canBeRemoved={store.canSequenceBeRemoved(sequenceIndex)}
            onClick={() => store.seqInit.removeFromSequence(sequenceIndex)}
          />
        ) : null}

        <div className={`newRuleFormSection__helpIcon`}>{index < 1 && <Help message={help} />}</div>

        <SimpleSequenceLoader store={this.props.store} />

        <div className={amountInputClass}>
          {this.renderAmountInput(index, sequenceIndex, isLeverageExchange)}
          {this.renderCoinPrice(this.props.item.v.s)}
        </div>

        <label>{isLeverageExchange ? 'contracts of' : 'of'}</label>

        {this.checkForPrevAmountOrBalance(sequenceIndex) && (
          <div className='symbols select-grey'>{this.renderExitQtyType(index, sequenceIndex)}</div>
        )}

        {symbolsList}

        {!isLeverageExchange &&
          (this.props.item.at === TRADE_TYPES.BUY ? <label>with my</label> : <label>to my</label>)}

        {!isLeverageExchange && (
          <div className={quoteSelectClass}>
            {this.renderQuoteSelect(index, sequenceIndex)}
            {this.renderCoinPrice(this.props.item.q)}
          </div>
        )}

        <React.Fragment>
          <label>as</label>
          <div className='select-grey'>
            {this.renderOrderTypeDropdown(index, sequenceIndex, this.props.store.rule.ex)}
          </div>
        </React.Fragment>
      </div>
    );
  }
}
export default ActionSequencePart;
