import React from 'react';

export const createUseCasesProvider = <TUseCases,>({ useCases }: { useCases: TUseCases }) => {
  const UseCasesContext = React.createContext<TUseCases>(useCases)

  const useUseCasesContext = () => React.useContext<TUseCases>(UseCasesContext)

  const UseCasesProvider = ({ children }: { children: React.ReactNode }) => {
    return <UseCasesContext.Provider value={useCases}>{children}</UseCasesContext.Provider>
  }

  return {
    UseCasesContext,
    UseCasesProvider,
    useUseCasesContext
  }
}