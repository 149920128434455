import React from 'react'

import type { StackProps, TypographyProps } from '@mui/material';
import type { TExchangeUid } from 'design-system/types';

import { Typography, Stack } from '@mui/material'

import { ExchangeLogoSprite, Spacer } from 'design-system'

export interface ExchangeLogoSpriteWithNameProps extends StackProps {
  exchange: TExchangeUid
  exchangeName: string
  TypographyProps?: TypographyProps
}

const testId = 'ExchangeLogoSpriteWithName'
export const ExchangeLogoSpriteWithName = (props: ExchangeLogoSpriteWithNameProps) => {
  const {
    sx,
    exchange, 
    exchangeName,
    TypographyProps,
    ...otherProps
  } = props

  return (
    <Stack
      data-testid={testId}
      className={testId}
      direction={'row'}
      alignItems={'center'}
      sx={{
        ...sx
      }}
      {...otherProps}
    >
      <ExchangeLogoSprite 
        sx={{
          marginLeft: '-6px',
        }}
        exchange={exchange}
      />
      <Spacer x={5} />
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: 13,
          lineHeight: '15px',
          ...TypographyProps?.sx
        }}
        {...TypographyProps}
      >
        {exchangeName}
      </Typography>
    </Stack>
  )
}

export default ExchangeLogoSpriteWithName
