import { Col, Row } from 'antd';
import { ReactComponent as WhiteCheckedIcon } from 'assets/images/icons/checked-white.svg';
import { ReactComponent as CheckedIcon } from 'assets/images/icons/checked.svg';
import { ReactComponent as WhiteLinkIcon } from 'assets/images/icons/Link-white.svg';
import { ReactComponent as LinkIcon } from 'assets/images/icons/Link.svg';
import { ReactComponent as WhiteTrendingIcon } from 'assets/images/icons/Trending-white.svg';
import { ReactComponent as TrendingIcon } from 'assets/images/icons/Trending.svg';
import { CoinruleButton, HtmlWrapper } from 'components/CoinruleButton';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';

export const FIRST_STEP = 'create a rule';
export const SECOND_STEP = 'link exchange';
export const THIRD_STEP = 'enjoy trading';

export type T3Steps = typeof FIRST_STEP | typeof SECOND_STEP | typeof THIRD_STEP;

const renderTitle = (step: number) => {
  switch (step) {
    case 3:
      return 'One Last Step to Happy Trading';
    case 2:
      return '2 Steps to Happy Trading';
    default:
      return '3 Steps to Happy Trading';
  }
};

export interface IWellcome3StepsTourProps {
  allSteps?: boolean;
  currentStep?: number;
  completedSteps?: T3Steps[];
  buttonProps?: {
    component: HtmlWrapper;
    onClick: () => void;
    children: React.ReactNode;
  };
}

const Welcome3StepsTour: React.FC<IWellcome3StepsTourProps> = ({
  allSteps = false,
  currentStep = 1,
  completedSteps,
  buttonProps,
}) => {
  const mainCls = ['rule-first-steps'];
  const step1Cls = ['step step1'];
  const step2Cls = ['step step2'];
  const step3Cls = ['step step3'];

  if (!allSteps) {
    if (currentStep < 3 && !completedSteps?.includes(THIRD_STEP)) {
      step3Cls.push('no-active');
    }
    if (currentStep < 2 && !completedSteps?.includes(SECOND_STEP)) {
      step2Cls.push('no-active');
    }
  } else {
    mainCls.push('in-modal');
  }

  return (
    <div className={`${mainCls.join(' ')} firstGenericGuide`} data-testid='ruleFirstStep'>
      <h2>
        Welcome to Coinrule
        <strong className='d-block'>{renderTitle(currentStep)}</strong>
      </h2>

      <Row className='steps-area firstGenericGuide__columnsWrap'>
        {/*Column*/}
        <Col md={8} className={`${step1Cls.join(' ')} FGguideColmn firstGenericGuide__column`}>
          <div className={`firstGenericGuide__titleNnumberWrap`}>
            <div className='point'>
              <div
                className={`icon-container ${
                  completedSteps?.includes(FIRST_STEP) ? 'completed' : ''
                }`}
              >
                {completedSteps?.includes(FIRST_STEP) ? <WhiteCheckedIcon /> : <CheckedIcon />}
              </div>
            </div>
            <Link to='/rule' className='title go-create-rule FGguideColmn__title'>
              Create a Rule
            </Link>
          </div>

          <small className={`FGguideColmn__description`}>
            Build your first Demo rule in less than a minute
            <br />
            and test in a <strong>demo</strong> environment
          </small>
        </Col>

        {/*Column*/}
        <Col md={8} className={`${step2Cls.join(' ')} FGguideColmn firstGenericGuide__column`}>
          <div className={`firstGenericGuide__titleNnumberWrap`}>
            <div className='point'>
              <div
                className={`icon-container ${
                  completedSteps?.includes(SECOND_STEP) ? 'completed' : ''
                }`}
              >
                {completedSteps?.includes(SECOND_STEP) ? <WhiteLinkIcon /> : <LinkIcon />}
              </div>
            </div>
            <Link to='/exchanges' className='title go-exchange-page FGguideColmn__title'>
              Link Exchange
            </Link>
          </div>

          <small className={`FGguideColmn__description`}>
            When you are ready to trade live,
            <br />
            <strong>connect</strong> your favorite exchange
          </small>
        </Col>

        {/*Column*/}
        <Col md={8} className={`${step3Cls.join(' ')} FGguideColmn firstGenericGuide__column`}>
          <div className={`firstGenericGuide__titleNnumberWrap`}>
            <div className='point'>
              <div
                className={`icon-container ${
                  completedSteps?.includes(THIRD_STEP) ? 'completed' : ''
                }`}
              >
                {completedSteps?.includes(THIRD_STEP) ? <WhiteTrendingIcon /> : <TrendingIcon />}
              </div>
            </div>
            <span className='title FGguideColmn__title'>Enjoy Trading</span>
          </div>

          <small className={`FGguideColmn__description`}>
            Launch your rules <strong>live</strong> on the market
            <br />
            and observe them running
          </small>
        </Col>
      </Row>

      {buttonProps && <CoinruleButton withGlow size='big' icon='CaretRight' {...buttonProps} />}
    </div>
  );
};

export default observer(Welcome3StepsTour);
