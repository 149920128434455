import React from 'react';
import { observer } from 'mobx-react';
import MobileMenu from '../../MobileMenu';
import GlobalSidebar from '../../GlobalSidebar';
import { GlobalErrorStore } from '../index';
// @ts-ignore
import ErrorImage from '../../../assets/images/error-404.png';

import './style.scss';
import history from '../../../helpers/history';
import { CoinruleButton } from '../../CoinruleButton';

export const ErrorMessageFulscreen: React.FC = observer(() => {
  return (
    <div className={`globalWrapper`}>
      <MobileMenu />
      <GlobalSidebar extraClass={`globalWrapper__sidebar`} forceOpened />

      <div className='error-fullscreen text-center'>
        <div className={'content'}>
          <div className='image-area'>
            <img src={ErrorImage} alt='' />
          </div>

          <div className='error--text-area'>
            <div className={'error--text-blue'}>Oops!</div>
            <div className={'error--text-bold'}>Looks like something went wrong.</div>
            <div className={'error--text-normal'}>
              Refreshing the page or going back to the dashboard should do the trick.
            </div>
          </div>

          <CoinruleButton
            fillType='solid'
            color='lightGreen'
            className='error--dashboard-button'
            onClick={() => {
              if (history.location.pathname !== '/') {
                history.push('/');
              }
              GlobalErrorStore.setErrorFalse();
            }}
          >
            Back to Dashboard
          </CoinruleButton>
        </div>
      </div>
    </div>
  );
});
