import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import stores from '../../stores';
import Loading from '../Loading';
import { ErrorBoundary } from '../ErrorBoundary';

const PrivateRoute = (props) => {
  const { isAuthorized, isCheckingAuthorization, getLastIsAuthorized } = stores.user;
  const { component: Component, ...rest } = props;

  const checkAuthorization = (Component, props) => {
    if (isAuthorized) {
      return (
        <ErrorBoundary name={'boundary_PrivateRoute'} isFullScreen>
          <Component {...props} />
        </ErrorBoundary>
      );
    } else {
      if (isCheckingAuthorization || getLastIsAuthorized()) {
        return <Loading />;
      } else {
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }
    }
  };

  return <Route {...rest} render={(props) => checkAuthorization(Component, props)} />;
};

export default PrivateRoute;
