import React, { Component } from 'react';
import { observer } from 'mobx-react';
import SimpleSequenceLoader from '../../../../components/Loading/SimpleSequenceLoader';
import WaitOptions from './WaitOptions';
import Help from 'components/Help';
import ErrorBlock from 'components/Sequences/ErrorBlock';

@observer
class Wait extends Component {
  changeTimeSelect(value) {
    this.props.store.seqInit.sequences[this.props.sequenceIndex].data.operators[0].pe = value;
  }

  render() {
    let className = '';

    const { store, sequenceIndex } = this.props;
    const error = store.seqInit.errors[sequenceIndex]?.[0];

    const getMessage = (value) => {
      const MESSAGE_MAP = {
        once: {
          title: 'Wait Once',
          description: `After 'Wait Once', an Action block will execute immediately. A condition block will be checked once. If the condition is not met, the rule proceeds with the next rule block.`
        },
        for: {
          title: 'Wait For',
          description: `The rule will 'Wait for' the selected time and check the next condition in a loop. If the condition is not met, the loop restarts. It will run until the condition after the 'wait for' is met.`
        }
      }
  
      const { title, description } = MESSAGE_MAP[value] || {}
  
      return (
        <>
          <div className='helpTooltip__title'>{title}</div>
          <div>
            <p>{description}</p>
          </div>
        </>
      )
    }

    return (
      <>
        <div className={'content ' + className + ' wait-block'}>
          {this.props.removeBut}
          {this.props.helpIcon}
          <SimpleSequenceLoader store={this.props.store} />

          <WaitOptions {...this.props}  />

          <div className='select-grey'>
            {this.props.store.renderPredefinedPeriodSelect(
              this.props.store.seqInit.sequences[this.props.sequenceIndex].data.operators[0].pe,
              this.changeTimeSelect.bind(this),
              false,
              true,
              `Choose Delay Length`
            )}
          </div>

          <Help message={getMessage(this.props.store.seqInit.sequences[this.props.sequenceIndex].data.operators[0].wt)} />
        </div>

        <ErrorBlock content={error?.text} show={!!error} />   
      </>
    );
  }
}

export default Wait;
