import React from 'react'

import type { StackProps } from '@mui/material'

import { useTheme, Box, Typography, Stack } from '@mui/material'

import { ImageLogoRobotGray } from 'design-system'

export interface LoaderProps extends StackProps {
  text?: string
  icon?: React.ReactNode 
}

const spinAnimationKeyframes = {
  '0%': {
    transform: 'rotate(0deg)'
  },
  '25%': {
    transform: 'rotate(90deg)'
  },
  '50%': {
    transform: 'rotate(180deg)'
  },
  '75%': {
    transform: 'rotate(270deg)'
  },
  '100%': {
    transform: 'rotate(360deg)'
  }, 
}

const testId = 'Loader'
export const Loader = (props: LoaderProps) => {
  const {
    sx,
    text,
    icon = <ImageLogoRobotGray />,
    ...otherProps
  } = props

  const theme = useTheme()

  return (
    <Stack 
      data-testid={testId}
      className={testId}
      direction={'column'}
      alignItems={'center'}
      gap={'10px'} 
      sx={{
        ...sx
      }}
      {...otherProps}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100px',
          height: '100px',
          margin: '0',
        }}
      >
        <Box 
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        >
          {icon}
        </Box>
        <Box 
          sx={{
            '@keyframes spin': spinAnimationKeyframes,
            border: '3px solid #f3f3f3',
            borderTop: '3px solid #4d59fb',
            borderRadius: '50%',
            animation: 'spin 0.9s linear infinite',
            width: '100%',
            height: '100%',
            boxSizing: 'border-box',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 0,
          }}
        />
      </Box>
      {
        text ?
          <Typography
            sx={{
              fontSize: '10px',
              fontWeight: 400,
              color: theme.palette.coinrule.brand.primary,
            }}
          >
            {text}
          </Typography>
          :
          null
      }
    </Stack>
  )
}

export default Loader
