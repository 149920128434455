import * as React from 'react';
import { Col, Input, Row } from 'antd';

export interface IInputColProps {
  name: string;
  placeholder: string;
  onChange: (...args: any) => any;
  span?: number;
  suffix?: string;
  prefix?: string;
  type?: string;
  allowClear?: boolean;
  style?: object;
}

export function InputCol(props: IInputColProps) {
  const returnInputByType = (): React.ReactNode => {
    switch (props.type) {
      case 'textArea':
        return <Input.TextArea {...props} />;
      default:
        return <Input {...props} />;
    }
  };

  return (
    <Row>
      <Col span={props.span ?? 8} style={{ textAlign: 'center', marginBottom: '10px' }}>
        {returnInputByType()}
      </Col>
    </Row>
  );
}
