import React from 'react'

import type { ChipProps } from '@mui/material'

import { useTheme, Chip } from '@mui/material'

import { IconExternalLink } from 'design-system'

export interface ExternalLinkProps extends ChipProps {
  url?: string,
  label: string
}

const testId = 'ExternalLink'
export const ExternalLink = (props: ExternalLinkProps) => {
  const {
    sx,
    label,
    url
  } = props

  const theme = useTheme()

  return (
    <Chip
      data-testid={testId}
      className={testId}
      icon={<IconExternalLink />}
      size={'small'}
      target={'_blank'}
      label={label}
      component={'a'}
      href={url}
      sx={{
        flexDirection: 'row-reverse',
        marginTop: '-5px',
        cursor: 'pointer',
        '& .MuiChip-icon': {
          marginLeft: '-4px',
          marginRight: '8px',
          marginTop: '0',
        },
        '& .MuiChip-label': {
          textDecoration: 'underline',
          color: theme.palette.primary.main,
          fontSize: 12
        },
        ...sx,
      }}
    />
  )
}

export default ExternalLink
