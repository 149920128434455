import { observable, action, computed } from 'mobx';
import http from '../helpers/http';
import { errorNotification, successNotification } from '../helpers/notification';
import { AbstractStore } from './AbstractStore';

export default class Notifications extends AbstractStore {
  constructor(rootStore) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  // Notifications
  // Types: "rule execution", "error", "important error", "maintenance", "you earned money",
  // "you lost money", "new event scheduled", "new blog post", "exchange notification"
  @observable notificationsList = [];
  @observable newNotificationsList = [];
  @observable isLoadingNotifications = false;
  @observable notificationPage = 1;
  @observable notificationTotalPage = 2;

  @action getNotifications = async () => {
    if (this.isLoadingNotifications || this.notificationTotalPage < this.notificationPage) {
      return;
    }

    try {
      this.isLoadingNotifications = true;

      const resp = await http.get(`/notifications?page=` + this.notificationPage);

      this.isLoadingNotifications = false;

      if (resp.data) {
        if (resp.data.status === 'OK') {
          this.notificationsList = this.notificationsList.concat(resp.data.data.notifications);
          this.notificationTotalPage = resp.data.data.totalPages;
          this.notificationPage++;
        } else {
          errorNotification(resp.data.message);
        }

        return resp.data.authorized;
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoadingNotifications = false;
    }
  };

  @observable isLoadingReadNotifications = false;
  @action markAllAsRead = async () => {
    if (this.isLoadingReadNotifications) {
      return;
    }

    if (this.newNotificationsList.length < 1) {
      return;
    }

    const lastId = this.newNotificationsList[0]._id;

    for (const i in this.newNotificationsList) {
      this.newNotificationsList[i].v = true;
    }

    this.notificationsList = [...new Set(this.newNotificationsList.concat(this.notificationsList))];
    this.newNotificationsList = [];

    try {
      this.isLoadingReadNotifications = true;

      const resp = await http.post(`/notifications/readNotifications`, { lastId });

      this.isLoadingReadNotifications = false;

      if (resp.data) {
        if (resp.data.status === 'OK') {
          this.newNotificationsList = resp.data.data.notifications;
        } else {
          errorNotification(resp.data.message);
        }

        return resp.data.authorized;
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoadingReadNotifications = false;
    }
  };

  @observable isLoadingNewNotifications = false;

  @action getNewNotifications = async () => {
    if (this.isLoadingNewNotifications) {
      return;
    }

    try {
      this.isLoadingNewNotifications = true;

      const resp = await http.get(`/notifications/newNotifications`);

      this.isLoadingNewNotifications = false;

      if (resp.data) {
        if (resp.data.status === 'OK') {
          this.newNotificationsList = resp.data.data.notifications;
        } else {
          errorNotification(resp.data.message);
        }

        return resp.data.authorized;
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoadingNewNotifications = false;
    }
  };

  @computed get newNotificationsStatus() {
    return this.newNotificationsList.length > 0;
  }

  @observable isSendingNotification = false;

  @action sendNewNotification = async (params) => {
    if (this.isSendingNotification) {
      return;
    }

    try {
      this.isSendingNotification = true;
      const resp = await http.post(`/notifications/sendNotification`, params);
      this.isSendingNotification = false;

      if (resp.data) {
        if (resp.data.status === 'OK') {
          if (resp.data.moreThanOneUser) {
            successNotification('Successfully sent a notification to users');
          } else {
            successNotification('Successfully sent a notification to user');
          }
          return true;
        } else {
          errorNotification(resp.data.message);
        }

        return false;
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoadingNewNotifications = false;
    }
  };

  @action
  setNotifications(newNotifications) {
    this.newNotificationsList = this.newNotificationsList.concat(newNotifications);
  }
}
