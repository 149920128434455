import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import './style.scss';

import { GlobalWrapper } from '../../components/GlobalWrapper';
import { observer } from 'mobx-react';

import DataStudioPageToggler from './DataStudioPageToggler';
import DataStudioContent from './DataStudioContent';
import stores from 'stores';

export const DataStudioContext = React.createContext({});

export interface DataStudioPageProps extends RouteComponentProps {
  isOnAddRulePage?: boolean
}

const DataStudioPage = observer(({ isOnAddRulePage }: DataStudioPageProps) => {
  const [showDataStudio, setShowDataStudio] = useState(false);

  const handleToggle = () => {
    setShowDataStudio(!showDataStudio);
  };

  const contextValue = React.useMemo(() => {
    return { showDataStudio, toggleDataStudio: handleToggle };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDataStudio]);
  
  const isLoading = stores.userInfo.isLoadingExchanges || stores.exchangeInfo.isLoadingAssets;

  // do not show data studio on mobile
  const isMobile = window.innerWidth <= 575;
  if(isMobile) return null;
 
  return (
    <DataStudioContext.Provider value={contextValue}>
      <GlobalWrapper
        {...(isLoading && { style: { height: '100%' } })}
        extraClass={`DataStudioPage__globalWrapper 
          ${isOnAddRulePage ? 'DataStudioPage__globalWrapper--isOnAddRulePage' : ''} 
          ${!showDataStudio ? 'DataStudioPage__globalWrapper--hidden' : ''}
          ${
            !stores.addRule.dataStudioVisible
              ? 'DataStudioPage__globalWrapper--everything-hidden'
              : ''
          }
        `}
      >
        <div className={`DataStudioPage__content`}>
          <DataStudioPageToggler
            isOnAddRulePage={isOnAddRulePage}
            onClick={handleToggle}
            isOpen={showDataStudio}
          />

          {
            showDataStudio && (
              <DataStudioContent 
                isLoading={isLoading} 
                onToggleStudio={handleToggle} 
              />
            )
          }
        </div>
      </GlobalWrapper>
    </DataStudioContext.Provider>
  );
});

export default withRouter(DataStudioPage);
