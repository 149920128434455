import React, { useState } from 'react';
import './style.scss';
import Popover from '../../../../components/Popover';
import { TemplateDeleteButton } from './TemplateDeleteButton';
import { CoinruleButton } from '../../../../components/CoinruleButton';
import VideoThumbnail from 'components/Tutorials/VideoThumbnail';
import TutorialVideo from 'components/Tutorials/TutorialVideo';
import { ReactComponent as ArrowUp } from 'assets/images/icons/arrowup.svg';
import storesInjector from 'stores/storesInjector';
import { isNegativeNumber } from 'helpers/helpers';
import Dots from 'components/Dots'

const TemplateItem = (props) => {
  const [state, setState] = useState({
    graphId: Math.floor(Math.random() * (15 - 1) + 1),
    categories:
      props.templatesStore?.categories?.filter((e) =>
        props.category.some((category) => category.nam === e.nam)
      ) || [],
    showVideoTutorial: false,
  });

  const getPercentageColor = (prefix = '') => {
    const sign = isNegativeNumber(props.percentage) ? 'negative' : 'positive';

    return `${prefix}__percentage_${sign}`;
  };

  const renderTemplateCategories = (isLocked) => {
    return <div className='templateDetails__category-container'>
      {
        state.categories.map((category) => (
          <p
            key={category._id}
            className={`templateDetails__category 
                            templateItem__category_${category.nam
                              .replace(/[^a-zA-Z]/g, '')
                              .toLowerCase()}`}
          >
            {!isLocked && category.nam}
          </p>
        ))
      }
    </div>
  };

  const renderTemplateDetail = (isLocked, isTested) => {
    // Apply template button
    const applyTemplateBut = (
      <Popover text='Choose Template Strategy' placement='bottom'>
        <CoinruleButton
          data-testid='templateItem__applyTemplateButton'
          color='violet'
          fillType='solid'
          icon='CheckWhite'
          className={`templateDetails__applyBut`}
          onClick={() => props.onTemplateApply(props._id)}>
          Select
        </CoinruleButton>
      </Popover>
    );

    return (
      <div
        data-testid='templateDetails'
        className={`templateDetails 
                        ${props.bemParentClassItemDetails} 
                        ${props.openedTemplateID === props._id ? `templateDetails_opened` : ''}`}
        style={
          props.openedTemplateID === props._id
            ? { height: props.detailsBlockHeight }
            : { height: 0 }
        }>
        <div className={`templateDetails__wrap1`} data-idmark={`${props._id}`}>
          <div className={`templateDetails__wrap2`}>
            <div className={`templateDetails__chartImage`}>
              {renderVideoOrChart(props.graphName || state.graphId, {}, isTested)}

              <div className='templateDetails__duration-container'>
                <span className='templateDetails__duration'>{props.duration}</span>
                <span>
                  {props.startDate} - {props.endDate}
                </span>
              </div>
            </div>

            <div className={`templateDetails__data`}>
              <div className={`templateDetails__title`}>
                <div className={`templateDetails__titleText`}>
                  {!isLocked && props.templateName}
                </div>

                <div className='templateDetails__category-label'>
                  {renderTemplateCategories(isLocked)}
                  {renderLabel()}
                </div>
              </div>

              <div className={`templateDetails__indicators`}>
                <div className={`templateDetails__amount`}>{`$${!isLocked && props.amount}`}</div>
                <div
                  data-testid='templateDetails__percentage'
                  className={`templateDetails__percentage ${getPercentageColor(
                    'templateDetails'
                  )}`}>
                  {!isLocked && props.percentage}
                </div>
                <Popover text='Coins impacted'>
                  <div className={`templateDetails__pair`}>{!isLocked && props.pair}</div>
                </Popover>
                <div className={`templateDetails__number`}>{!isLocked && props.number}</div>
                <Popover text='Rule Difficulty'>
                  <div className={`templateDetails__complexity`}>
                    Complexity

                    <Dots count={3} inactiveColor='#ebebeb' activeColor='#8e8e8e' active={props.complexity} />
                  </div>
                </Popover>

                {renderDetailsStatistics(isLocked, isTested)}
              </div>

              <div className={`templateDetails__description`}>
                {!isLocked && props.description}{' '}
                {props.tutorialUrl && (
                  <a href={props.tutorialUrl} target='_blank' rel='noopener noreferrer'>
                    Read more
                  </a>
                )}
              </div>

              {!isLocked && applyTemplateBut}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDetailsStatistics = (isLocked, isTested) => {
    const { trades, profit, returns } = props;

    if (!isTested && !isLocked) return;

    return (
      <>
        <div className={`templateDetails__complexity`}>{trades} Trades</div>
        <div className={`templateDetails__complexity`}>
          Profitable <span>{profit}%</span> <ArrowUp className='templateItem__arrowup' />
        </div>
        <div className={`templateDetails__complexity`}>
          Return <span>{returns}%</span> <ArrowUp className='templateItem__arrowup' />
        </div>
      </>
    );
  };

  const renderVideoOrChart = (graph = state.graphId, videoThumbnailProps = {}, isTested) => {
    if (props.videoUrl)
      return (
        <VideoThumbnail
          onClick={(event) => {
            setState((prev) => ({ ...prev, showVideoTutorial: true }));
            event.stopPropagation();
          }}
          {...videoThumbnailProps}
        />
      );

    if (isTested) return graph;

    return <img data-testid='templateItem__graph' src={`../graphs/${graph}.svg`} alt='chart' />;
  };

  const renderLabel = () => {
    if (props.label) return <p className='templateItem__label'>{props.label}</p>;
  };

  const renderTemplateBox = (isLocked, isTested) => {
    return (
      <div
        data-testid='templateItem'
        className={`templateItem 
                        ${props.bemParentClassItem} 
                        ${
                          (isLocked || props.templatesStore?.showOverlay) && `templateItem_secured`
                        } 
                        ${props.openedTemplateID === props._id && `templateItem_opened`}`}
        onClick={() => {
          if (isLocked) return;
          props.onTemplateChoose(props._id);
        }}>
        <Popover text='View Template Details' hidden={isLocked}>
          <div className={`templateItem__wrapper`}>
            {(props.userStore?.isAdmin || props.userStore?.isSupport) && (
              <TemplateDeleteButton templateName={props.templateName} />
            )}

            <div className='templateItem__category-label'>
              {renderTemplateCategories(isLocked)}
              {renderLabel()}
            </div>

            <div className={`templateItem__name`}>{props.templateName}</div>
            <div
              className={`templateItem__description 
                              ${isLocked ? `templateItem__description_secured` : ''}`}>
              {props.teaserText}{' '}
              {props.tutorialUrl && (
                <a href={props.tutorialUrl} target='_blank' rel='noopener noreferrer'>
                  Read more
                </a>
              )}
            </div>
            <div
              className={`templateItem__bottomBlock
                        ${isLocked ? `templateItem__bottomBlock_secured` : ''}`}>
              <Popover text='Unlock template'>
                <CoinruleButton
                  fillType='solid'
                  icon='Lock'
                  className={`templateItem__buyBut`}
                  onClick={(event) => {
                    props.addRuleStore.setPaymentGateVisible({type:'promo', isVisible:true, forceShow: true});
                    event.stopPropagation();
                  }}>
                  Unlock
                </CoinruleButton>
              </Popover>
              <div
                className={`templateItem__chart 
                                ${isLocked ? `templateItem__chart_secured` : ''}`}
                style={{ ...(props.videoUrl && { width: 'auto' }) }}>
                {renderVideoOrChart(
                  props.graphName || state.graphId,
                  {
                    playButtonStyles: { width: '15px', height: '15px' },
                  },
                  isTested
                )}
              </div>
              <div
                className={`templateItem__indicators 
                                ${isLocked ? `templateItem__indicators_secured` : ''}`}>
                <div className={`templateItem__amount`}>{`$${props.amount}`}</div>
                <div
                  data-testid='templateItem__percentage'
                  className={`templateItem__percentage 
                                  ${getPercentageColor('templateItem')}`}>
                  {props.percentage}
                </div>
                {renderStatistics(isTested)}
              </div>
            </div>
          </div>
        </Popover>
      </div>
    );
  };

  const renderStatistics = (isTested) => {
    const { trades, duration, pair, profit } = props;
    if (isTested)
      return (
        <div className='templateItem__statistics'>
          <div className='templateItem__trade-data'>
            <p>
              {trades} trade{trades > 1 ? 's' : ''}
            </p>
            <p>{duration}</p>
          </div>

          <div className='templateItem__pair-percentage'>
            <p className='templateItem__pair'>{pair}</p>
            <div className='templateItem__profit-percentage'>
              <ArrowUp className='templateItem__arrowup' /> {profit}%
            </div>
          </div>
        </div>
      );

    return (
      <>
        <div className={`templateItem__pair`}>{props.pair}</div>
        <div className={`templateItem__number`}>{props.number}</div>
      </>
    );
  };

  const isTested = props.label?.toLowerCase() === 'tested';

  return (
    <React.Fragment>
      {renderTemplateBox(!props.unlocked, isTested)}
      {renderTemplateDetail(!props.unlocked, isTested)}

      <TutorialVideo
        onCancel={() => setState((prev) => ({ ...prev, showVideoTutorial: false }))}
        visible={state.showVideoTutorial}
        videoUrl={props.videoUrl}
      />
    </React.Fragment>
  );
};

const selector = ({ templatesStore, user: userStore, addRule: addRuleStore }) => ({
  templatesStore,
  userStore,
  addRuleStore,
});

export default storesInjector(selector)(TemplateItem);
