export const NEWS_FEED_BASE_URL: string = 'https://api.rss2json.com/v1/api.json?rss_url=';

export const NEWS_FEED_URLs = [
  `${NEWS_FEED_BASE_URL}https://news.bitcoin.com/feed`,
  `${NEWS_FEED_BASE_URL}http://bravenewcoin.com/news/rss`,
  `${NEWS_FEED_BASE_URL}https://cryptobriefing.com/feed`,
  `${NEWS_FEED_BASE_URL}https://www.newsbtc.com/feed`,
];

export interface IFeed {
  url: string;
  title: string;
  image: string;
}

export interface INewsItem {
  author: string;
  link: string;
  pubDate: string;
  thumbnail: string;
  title: string;
}

export interface INewsFeed {
  feed: IFeed;
  items: INewsItem[];
  status: 'ok' | string;
}

export const newsItemsFormatter = (data: INewsFeed) => {
  const { feed, items } = data;
  const magazine = feed?.title ? feed.title.split('.')[0] : '';

  return items.map((item) => ({
    ...item,
    thumbnail: item.thumbnail || feed.image,
    magazine,
  }));
};
