import { Switch } from 'antd';
import { pluralise } from 'helpers/helpers';
import { observer } from 'mobx-react';
import React from 'react';
import './style.scss';
import CouponData from './CouponData/index';
import ChangePaymentMethod from './ChangePaymentMethod/index';
import { getPlanPriceData } from 'helpers/payment';

export const PlanContext = React.createContext({});

export const PlanFeatures = ({ plan }) => {
  const { features } = plan;

  if (!features?.length) return null;

  return (
    <div className={`list-features`}>
      <ul>
        {features.map((feature) => (
          <li key={feature}>{feature}</li>
        ))}
      </ul>
    </div>
  );
};

const PlanBox = ({
  plan,
  user,
  coupon,
  button,
  yearPeriod,
  showYearSwitch,
  changePeriod,
  period,
  changePaymentFunction,
}) => {
  const { name, code, settings } = plan;
  const { constraints = [code] } = coupon || {};

  const shouldCross = () =>  coupon && constraints.includes(`${code}-${period}`);

  const crossed = shouldCross() ? 'crossed' : '';

  // Hardcoded for now, can be taken from user's preferences in the future
  const symbol = '$';
  const currentPlan = user && (user.plan.uid === code || user.plan.trial > 0);

  const { monthlyPrice, discountMonthlyPrice, annualPrice, amountToPay, quarterlyPrice } = getPlanPriceData({
    coupon,
    plan,
    period
  });

  const contextValue = React.useMemo(
    () => ({
      plan_code: `${code}-${period}`,
      amountToPay: amountToPay,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [yearPeriod]
  );

  const renderCouponOrSettingsTrialText = () => {
    if (coupon && crossed)
      return (
        <CouponData
          coupon={coupon}
          symbol={symbol}
          yearPeriod={yearPeriod}
          monthlyDiscountPrice={discountMonthlyPrice}
        />
      );

    return renderSettingsTrialText();
  };

  const renderSettingsTrialText = () => {
    if (!settings?.trial) return;

    return (
      <div data-testid='trialText' className={`partnershipText`}>{`${settings.trial} ${pluralise(
        settings.trial,
        'Day'
      )} Free Trial`}</div>
    );
  };

  const isFree = coupon?.discount_percentage >= 100 || amountToPay === 0;
  const getDisplayPrice = () => period === 'quarterly' ? quarterlyPrice : annualPrice;

  return (
    <div
      data-testid={'PlanBox'}
      className={`PlanBox
                        PlanBox_${plan.name.toLowerCase()}
                        PlanBox_${currentPlan ? 'currentPlan' : ''}`}>
      <div className='title-plan'>
        <h4 className='name'>
          {name} Plan
          {settings.clearance === 2 && <div className={`label-new`}>New</div>}
        </h4>

        <div data-testid='crossed' className={`cost has-price ${crossed}`}>
          <div className='left-side'>
            <div className='symbol'>{symbol}</div>
            <div className='price'>{monthlyPrice}</div>
          </div>

          <div className='right-side'>
            <div className='price'>.99</div>
            <div className='period'>/month</div>
          </div>
        </div>

        {(period === 'yearly' || period === 'quarterly') && (
          <div className={`annualSubscriptionNotice`}>
            {symbol}{isFree ? plan.annualPrice : getDisplayPrice()} will be billed {period}
          </div>
        )}

        {renderCouponOrSettingsTrialText()}

        {showYearSwitch && (
          <div className={`switch-area`}>
            <Switch
              checkedChildren='Annual'
              unCheckedChildren='Monthly'
              checked={yearPeriod}
              onChange={changePeriod}
            />
          </div>
        )}
      </div>

      <PlanFeatures plan={plan} />

      <PlanContext.Provider value={contextValue}>{button}</PlanContext.Provider>

      <ChangePaymentMethod
        currentPlan={currentPlan}
        changePaymentFunction={changePaymentFunction}
      />
    </div>
  );
};

export default observer(PlanBox);
