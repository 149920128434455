import { useMemo } from 'react';

export interface UsePaginationOptions {
  totalCount: number;
  sizePerPage: number;
}
const usePagination = ({ totalCount, sizePerPage }: UsePaginationOptions) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / sizePerPage);

    return Array.from({ length: totalPageCount }, (_, i) => i + 1);
  }, [totalCount, sizePerPage]);

  return paginationRange;
};

export default usePagination;
