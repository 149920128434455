import type { THTTPClientError } from 'v2/types'
import type { TGetState } from 'v2/types/TGetState'
import type { TDispatchAction } from 'v2/types/TDispatchAction'

import type { TDataProviderConnectExchangeBinanceOAuthPrepare } from 'v2/dataProviders/connectExchangeBinanceOAuth/createDataProviderConnectExchangeBinanceOAuthPrepare'
import type { DTOErrorFetchConnectExchangeBinanceOAuthPrepare } from './DTOs/DTOErrorFetchConnectExchangeBinanceOAuthPrepare'
import type { TDomainStateConnectExchangeBinanceOAuthPrepare } from './stateFetchPrepare'

import { EDomainActionsConnectExchangeBinanceOAuthPrepare, actionCreatorsConnectExchangeBinanceOAuthPrepare } from './actionsFetchPrepare'

export type TDomainUseCasesConnectExchangeBinanceOAuthPrepare = ReturnType<typeof createUseCasesConnectExchangeBinanceOAuthPrepare>

export const createUseCasesConnectExchangeBinanceOAuthPrepare = ({ serviceLocation, dataProviderConnectExchangeBinanceOAuthPrepare, dispatch, getState }: { serviceLocation: { replace: (url: string) => void }, dataProviderConnectExchangeBinanceOAuthPrepare: TDataProviderConnectExchangeBinanceOAuthPrepare, dispatch: TDispatchAction, getState: TGetState<TDomainStateConnectExchangeBinanceOAuthPrepare> }) => {
  return {
    prepare: async () => {
      dispatch(actionCreatorsConnectExchangeBinanceOAuthPrepare[EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_STARTED]())
      try {
        const dtoConnectExchangeBinanceOAuthPrepare = await dataProviderConnectExchangeBinanceOAuthPrepare.prepare()
        dispatch(actionCreatorsConnectExchangeBinanceOAuthPrepare[EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_SUCCEEDED]({ dto: dtoConnectExchangeBinanceOAuthPrepare }))
        
        serviceLocation.replace(dtoConnectExchangeBinanceOAuthPrepare.redirectURL)
      } catch (error) {
        const httpClientError = error as THTTPClientError 
        let domainError: DTOErrorFetchConnectExchangeBinanceOAuthPrepare
        if(httpClientError.isAxiosError && httpClientError.response?.status === 404 ) {
          domainError = { 
            code: 404,
            message: 'Not found',
            origin: 'domains/connectExchangeBinanceOAuth/prepare' as const,
          }
        } else {
          domainError = { 
            code: 500,
            message: 'Something went wrong while performing prepare request',
            origin: 'domains/connectExchangeBinanceOAuth/prepare' as const,
          }
        }
        dispatch(actionCreatorsConnectExchangeBinanceOAuthPrepare[EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_FAILED]({ error: domainError }))
      }
    }
  }
}