import React from 'react'

import type { StackProps } from '@mui/material';
import { useTheme, IconButton as MUIIconButton, Stack, Typography } from '@mui/material'

export interface IconButtonProps extends StackProps {
  label?: React.ReactNode,
  variant?: TIconButtonVariant;
}

export type TIconButtonVariant = 'solid' | 'ghost';

const testId = 'IconButton'
export const IconButton = (props: IconButtonProps) => {
  const {
    sx,
    label,
    children,
    variant = 'solid',
    ...otherProps
  } = props

  const theme = useTheme();

  const variantStyles: Record<TIconButtonVariant, React.CSSProperties> = {
    solid: {
      content: '""',
      width: '36px',
      height: '36px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: 0,
      boxShadow: '0 2px 5px rgb(0 0 0 / 20%)',
      transform: 'translate(-50%, -50%)',
      backgroundColor: theme.palette.coinrule.basic.white,
      borderRadius: '100px',
      transition: 'width 0.2s ease, height 0.2s ease',
    },
    ghost: {
    }
  }

  return (
    <Stack
      sx={{
        '&:hover': { 
          cursor: 'pointer'
        },
        '&:hover p:before': {
          width: 0
        },
        '&:hover button': {
          backgroundColor: 'inherit',
        },
        '&:hover button:after': {
          backgroundColor: theme.palette.coinrule.basic.white,
          width: '40px',
          height: '40px',
        },
        [theme.breakpoints.down('md')]: {
          '&:hover button:after': {
            width: '27px',
            height: '27px',
          }
        },
        ...sx,
      }}
      direction={'row'}
      alignItems={'center'}
      gap={'11px'}
      {...otherProps}
    >
      <MUIIconButton
        data-testid={testId}
        className={testId}
        disableRipple={true}
        sx={{
          transition: 'width 0.2s ease, height 0.2s ease',
          width: '36px',
          height: '36px',
          padding: '10px',
          position: 'relative',
          top: '-1px',
          backgroundColor: 'transparent',
          '&:after': variantStyles[variant],
          '& > *': {
            position: 'relative',
            zIndex: 1,
            width: '16px',
            height: '16px',
          },
          [theme.breakpoints.down('md')]: {
            width: '27px',
            height: '27px',
            padding: '7px',
            '&:after': {
              content: '""',
              width: '27px',
              height: '27px',
            }
          },
        }}
      >
        {children}
      </MUIIconButton>
      {
        label ?
          <Typography
            sx={{
              fontSize: '11px',
              textTransform: 'uppercase',
              fontWeight: 400,
              position: 'relative',
              color: theme.palette.coinrule.basic.black,
              top: '-2px',
              '&:before': {
                content: '""',
                width: '100%',
                height: '0',
                linHheight: '0',
                fontSize: '0',
                position: 'absolute',
                bottom: '1px',
                left: '0',
                borderBottom: '1px #000 solid',
                transition: 'width 0.2s ease',
                opacity: '0.2',
              },
              [theme.breakpoints.down('sm')]: {
                display: 'none'
              },
            }}
          >
            {label}
          </Typography>
          :
          null
      }
    </Stack>
  )
}

export default IconButton
