import React from 'react'
import {Select} from 'antd'
import stores from 'stores';
import { getFieldCssClass } from 'helpers/utils';
import Popover from 'components/Popover';
import { OPERATOR_TYPE, OPERATOR_TYPES, WAIT_OPTIONS } from 'stores/addRule';
import { observer } from 'mobx-react-lite';

const {Option} = Select;

const WaitOptions = ({ store, sequenceIndex }) => {
  const handleChange = (value) => {
    store.seqInit.sequences[sequenceIndex].data.operators[0].wt = value
  }

  const getOptions = () => {
    const previousThenIndex = store.seqInit.findIndexSequenceBeforeByType(OPERATOR_TYPE, sequenceIndex, OPERATOR_TYPES.THEN);
    const previousAnyTimeIndex = store.seqInit.findIndexSequenceBeforeByType(OPERATOR_TYPE, sequenceIndex, OPERATOR_TYPES.PARALLEL);

    if(previousAnyTimeIndex !== -1 || previousThenIndex !== -1) {
      const filteredOptions = stores.info.waitOptions.filter(option => option !== WAIT_OPTIONS.ONCE)
      handleChange(filteredOptions[0]);
      return filteredOptions;
    }

    return stores.info.waitOptions;
  }

  return (
    <div className={`select-grey ${store.seqInit.getErrorClass(sequenceIndex, 'wt')}`}>
      <Popover text='Choose the wait option' placement='left'>
        <Select 
          className={getFieldCssClass(store.seqInit.sequences[sequenceIndex].data.operators[0].wt, true)}
          value={store.seqInit.sequences[sequenceIndex].data.operators[0].wt}
          onChange={handleChange}
        >
          {
            getOptions().map(option => (
              <Option value={option} key={option}>
                {option}
              </Option>
            ))
          }
        </Select>
      </Popover>
    </div>
  )
}

export default observer(WaitOptions)