export const hasUserReachedLimit = (ruleStore: any, stores: any) => {
  const user = stores.user.getUser();

  const canCreateDemoRule = !(stores.userInfo.activeDemoRules >= user.plan.maxDemoRules);
  const canCreateLiveRule = !(stores.userInfo.activeLiveRules >= user.plan.maxRules);
  const isDemoExchange = stores.info.demoExchangesIDs.includes(ruleStore.rule.ex);

  return (
    (((!canCreateLiveRule && !isDemoExchange) || (!canCreateDemoRule && isDemoExchange)) &&
      (!(ruleStore.editMode && ruleStore.editedRuleId) ||
        stores.userRules.lastOpenRuleStatus === 'draft')) ||
    stores.user.user.plan.uid === 'paused'
  );
};

export enum IRuleEditModes {
  Draft = 'draft',
  QuickEdit = 'edit',
  FullEdit = 'restart',
  Clone = 'clone'
}

export const getEditMode = (matchPath: string, ruleStatus: string): IRuleEditModes|null => {
  if (matchPath === '/rule/:id/edit' && (ruleStatus === 'active' || ruleStatus === 'paused')) {
    return IRuleEditModes.QuickEdit;
  }

  if (matchPath === '/rule/:id/draft' && ruleStatus === 'draft') {
    return IRuleEditModes.Draft;
  }

  if (matchPath === '/rule/:id/restart' && ruleStatus !== 'draft') {
    return IRuleEditModes.FullEdit;
  }

  if (matchPath === '/rule/:id/clone' && ruleStatus !== 'draft') {
    return IRuleEditModes.Clone;
  }

  return null;
}