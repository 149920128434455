import React, { ReactNode, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'antd';

import { Stores } from '../../stores';

import './style.scss';
import { setDataLocalStorage } from '../../helpers/utils';
import { ErrorBoundary } from '../ErrorBoundary';
import { paymentGateExchangesLogos } from 'helpers/constants';
import PlansContainer from 'components/PlansContainer';
import { PaymentProviderFactory } from '../../services/payment.providers';
import PlanSkeleton from './PlanSkeleton';
import PaymentGateTitle from './PaymentGateTitle';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import usePlanPeriod from 'hooks/usePlanPeriod';
import { PLAN_PERIODS } from 'helpers/plan';

const modalConfig = {
  footer: null,
  centered: true,
};

export interface IPaymentGateProps {
  title?: ReactNode;
  subtitle?: ReactNode;
  store?: any;
  stores: Stores;
  type?: string;
  visible?: boolean;
  permanent?: boolean;
  styles?: React.CSSProperties
  onCancel?: () => void;
}

const PaymentGate: React.FC<IPaymentGateProps & RouteComponentProps> = ({
  title,
  subtitle,
  store,
  stores,
  visible,
  type,
  styles,
  onCancel,
}) => {
  const [state, setState] = useState({
    plansLoaded: false,
  });

  const updateState = (key: string, value: any) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const isOnQuarterlyPriceGroup = stores.planStore.isOnQuarterlyPriceGroup();
  const isOnScrapHobbyistGroup = stores.planStore.isOnScrapHobbyistGroup();

  const {period, renderSwitchOrDropdown, togglePeriod} = usePlanPeriod({isOnQuarterlyPriceGroup, isOnScrapHobbyistGroup})
  const yearPeriod = period === PLAN_PERIODS.YEARLY;

  const { plansLoaded } = state;
  
  const { clearance, ent: isEnterprise } = stores.user.user.plan;
  const isPro = clearance === 3;

  const paymentProvider = PaymentProviderFactory.getInstance();

  useEffect(() => {
    const loadData = async () => {
      await paymentProvider.fetchCoupons();
      await getPlans();
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(!visible) {
      stores.planStore.setFeaturePlans([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const getPlans = async () => {
    await stores.planStore.getPlans();
    updateState('plansLoaded', true);
  };


  const renderPlans = (yearPeriod: boolean) => {
    const { getUserCurrentPlan, getHigherPlansByPrice, featurePlans } = stores.planStore;
    
    if (!plansLoaded)
      return (
        <div className={`plansPreloadSkeleton`}>
          {[4, 6, 8].map((i) => (
            <PlanSkeleton numberOfFeatures={i} key={i} />
          ))}
        </div>
      );

    const currentPlan = getUserCurrentPlan();

    const getPlansToShow = () => {
      const higherPlans = getHigherPlansByPrice((currentPlan?.price && +currentPlan?.price) || 0);
      
      if(featurePlans?.length) 
        return featurePlans;

      return higherPlans;
    }

    return (
      <PlansContainer
        period={period}
        currentPlan={currentPlan}
        plans={getPlansToShow()}
        user={stores.user.user}
        paymentProvider={paymentProvider}
        yearPeriod={yearPeriod}
        showYearSwitch={false}
        changePeriod={togglePeriod}
      />
    );
  };

  const closeModal = () => {
    onCancel?.();
    stores.addRule.setPaymentGateVisible({ type, isVisible: false });

    if (type === 'execution') {
      store.setShowPlansBanner(false);
    }

    if (type === 'trading_volume_90' || type === 'trading_volume_100') {
      return;
    } else {
      setDataLocalStorage('should_restore_rule', false);
    }

  };

  if (isEnterprise || isPro || !visible) return null;

  return (
    <ErrorBoundary name={'boundary_PaymentGate'}>
      <Modal
        data-testid='PaymentGate'
        visible={visible}
        onCancel={closeModal}
        {...modalConfig}
        style={styles}
        className='PaymentGate'>
        <PaymentGateTitle title={title} subtitle={subtitle} clearance={clearance} type={type} />

        <div className={`PaymentGate__switchNplansWrap`}>
          <div className={`PaymentGate__yearSwitch`}>
            <div
              data-testid='discountNote'
              className={`PaymentGate__discountNote
                                ${!yearPeriod ? 'PaymentGate__discountNote_hidden' : ''}`}>
              Up to&nbsp;<b>{period === 'quarterly' ? '15' : period === 'yearly' ? '25' : ''}%</b>&nbsp;discount
            </div>
            {renderSwitchOrDropdown()}
          </div>

          <div className='plans'>{renderPlans(yearPeriod)}</div>
        </div>

        <h3>Coinrule works with 10+ exchanges, including:</h3>

        <div className='logosContainer'>
          {paymentGateExchangesLogos.map(({ src, alt }) => (
            <img key={alt} className='logo' src={src} alt={alt} />
          ))}
        </div>
      </Modal>
    </ErrorBoundary>
  );
};

export default withRouter(observer(PaymentGate));
