import React from 'react';
import { observable, action } from 'mobx';

import Then from '../pages/AddRulePage/Then';
import {
  ACTION_TYPE,
  CONDITION_TYPE,
  defaultAction,
  defaultCondition,
  defaultOperator,
  defaultOperatorThen,
  OPERATOR_TYPE,
  OPERATOR_TYPES,
  TRIGGERS,
  CONDITION_TYPES,
  MIN_TRADABLE_VALUE,
  SYMBOL_TYPES,
  BLOCK_TYPES,
  NOTIFY_TYPE,
} from './addRule';

import { toJS } from 'mobx';
import Operator from '../pages/AddRulePage/Operators';
import stores from './index';
import { getCoinValue } from '../pages/AddRulePage/ShowCoinsValue';
import Condition from '../pages/AddRulePage/Condition';
import { EXCHANGES_TYPE } from '../constants/exchanges';
import { SERVER_REGIONS } from 'constants/settingsPage';
import { MIN_LEVERAGE_ORDER_SIZE, STANDARD_LEVERAGE_LOT_SIZE, TRADE } from 'constants/rule';
import { handleActionType } from './ruleSequencesHandlers/handleActionType';
import { handleNotifyType } from './ruleSequencesHandlers/handleNotifyType';
import { AbstractStore } from './AbstractStore';
import { ENotifyChannel } from 'components/Rule/DetailTextSequence/notify.type';

export default class RuleSequences extends AbstractStore {
  constructor(rootStore) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  @observable sequences = [];
  @observable sequencesData = [];
  @observable addRuleStore = undefined;
  @observable addRuleParent = undefined;
  @observable idCount = 0;
  @observable preloadSequences = false;
  @observable usedThen = false;
  @observable usedParallel = false;
  @observable usedOr = false;
  @observable errors = {};
  @observable hasErrorValidation = false;
  @observable hasErrorValidationWallet = false;

  @observable sequenceSnapshots = {
    event: [],
    time: [],
    direct_order: [],
  };

  @observable operatorsCount = 0;

  @observable typesCounting = {
    conditions: {
      count: 0,
    },
    actions: {
      count: 0,
    },
    operators: {
      count: 0,
      types: {
        then: 0,
        or: 0,
        do_not: 0,
        wait: 0,
        parallel: 0,
      },
    },
  };

  isLeverage() {
    if (
      this.root.rule.ex &&
      stores.exchangeInfo.exchangeType[this.root.rule.ex] === EXCHANGES_TYPE.LEVERAGE
    ) {
      return true;
    }

    return false;
  }

  @action
  initEdit(store, parent, editSequence) {
    this.addRuleStore = store;
    this.addRuleParent = parent;
    this.sequences = [];
    this.idCount = 0;
    for (let i in editSequence) {
      let includeBlock = '';
      let component;

      const sequence = editSequence[i];
      const type = sequence.t;

      const isBuyOrSellAction =
        sequence.t === ACTION_TYPE && sequence.d.do.toLowerCase() === TRADE.toLowerCase();
      const isNotifyAction =
        sequence.t === ACTION_TYPE && sequence.d.do.toLowerCase() === NOTIFY_TYPE.toLowerCase();

      if (isBuyOrSellAction) {
        let actionData = sequence.d.ta;
        sequence.d.ta.v.v = null;

        if (actionData.sr !== null && actionData.sr >= 0 && actionData.ort !== 'that_coin') {
          sequence.d.ta.ort = 'from_sequence_' + sequence.d.ta.sr;
        }

        component = {
          id: this.idCount,
          errors: [],
          type: ACTION_TYPE,
          data: {
            actions: [sequence.d.ta],
          },
          component: (
            <Then
              key={this.idCount}
              store={this.addRuleStore}
              parent={this.addRuleParent}
              id={this.idCount}
              partIndex={0}
            />
          ),
        };
      } else if (isNotifyAction) {
        component = handleNotifyType(this.addRuleStore, this.addRuleParent, {
          idCount: this.increaseId(),
          data: sequence.d.na
        });
      } else if (type === CONDITION_TYPE) {
        sequence.d.a.v = null;

        let data = sequence.d;

        if (this.sequences[i - 1]) {
          if (
            this.sequences[i - 1].type === OPERATOR_TYPE &&
            this.sequences[i - 1].data.operators[0].t !== OPERATOR_TYPES.WAIT
          ) {
            includeBlock = OPERATOR_TYPE;
          } else if (this.sequences[i - 1].type === CONDITION_TYPE) {
            includeBlock = this.sequences[i - 1].includeBlock || CONDITION_TYPE;
          }
        }

        component = {
          id: this.idCount,
          errors: [],
          type: CONDITION_TYPE,
          includeBlock: includeBlock,
          data: {
            conditions: [data],
          },
          component: (
            <Condition
              key={this.idCount}
              store={this.addRuleStore}
              parent={this.addRuleParent}
              id={this.idCount}
              index={0}
              partIndex={0}
            />
          ),
        };
      } else if (type === OPERATOR_TYPE) {
        component = {
          id: this.idCount,
          errors: [],
          type: OPERATOR_TYPE,
          data: {
            operators: [sequence.d],
          },
          component: (
            <Operator
              key={this.idCount}
              store={this.addRuleStore}
              parent={this.addRuleParent}
              id={this.idCount}
              index={0}
              partIndex={0}
            />
          ),
        };
      }

      if (component) {
        this.sequences.push(component);
        this.idCount++;
        this.preloadSequences = false;
      }
    }

    this.preloadSequences = false;
    this.countingSequence();
    return null;
  }

  @action
  countingSequence() {
    this.usedThen = false;
    this.usedOr = false;
    this.usedParallel = false;

    let typesCounting = {
      conditions: {
        count: 0,
      },
      actions: {
        count: 0,
      },
      notifies: {
        count: 0,
      },
      operators: {
        count: 0,
        types: {
          then: 0,
          or: 0,
          do_not: 0,
          wait: 0,
          parallel: 0,
        },
      },
    };

    for (let i in this.sequences) {
      let data = this.sequences[i];

      if (data.type === CONDITION_TYPE) {
        typesCounting.conditions.count++;
      } else if (data.type === ACTION_TYPE) {
        typesCounting.actions.count++;
      } else if (data.type === NOTIFY_TYPE) {
        typesCounting.notifies.count++;
      } else if (data.type === OPERATOR_TYPE) {
        typesCounting.operators.count++;

        if (data.data.operators[0].t === OPERATOR_TYPES.WAIT) {
          typesCounting.operators.types.wait++;
        } else if (data.data.operators[0].t === OPERATOR_TYPES.DO_NOT) {
          typesCounting.operators.types.do_not++;
        } else if (data.data.operators[0].t === OPERATOR_TYPES.THEN) {
          this.usedThen = true;
          typesCounting.operators.types.then++;
        } else if (data.data.operators[0].t === OPERATOR_TYPES.OR) {
          this.usedOr = true;
          typesCounting.operators.types.or++;
        } else if (data.data.operators[0].t === OPERATOR_TYPES.PARALLEL) {
          this.usedParallel = true;
          typesCounting.operators.types.parallel++;
        }
      }
    }

    this.typesCounting = typesCounting;

    if (typesCounting.operators.types.parallel > 0) {
      // Do not show "in total" when ANY TIME operator is used: https://gitlab.com/coinrule-v2/project-board/-/issues/1791
      this.root.setRepeatNoMore('no_more_than_once');
    }
  }

  getErrorClass(sequenceIndex, type, index = null) {
    if (this.errors[sequenceIndex]) {
      for (let i in this.errors[sequenceIndex]) {
        const error = this.errors[sequenceIndex][i];

        if (index !== null) {
          if (error.type === type && Number(error.subIndex) === Number(index)) {
            return 'hasError';
          }
        } else {
          if (error.type === type) {
            return 'hasError';
          }
        }
      }
    }

    return '';
  }

  checkIfStillError(sequenceIndex, type, index = null) {
    let errors = this.validationFields(sequenceIndex, index, true);

    let errorExist = false;
    if (errors[sequenceIndex]) {
      for (let i in errors[sequenceIndex]) {
        const error = errors[sequenceIndex][i];

        if (index !== null) {
          if (error.type === type && Number(error.subIndex) === Number(index)) {
            errorExist = true;
            break;
          }
        } else {
          if (error.type === type) {
            errorExist = true;
            break;
          }
        }
      }
    }

    if (this.errors[sequenceIndex] && !errorExist) {
      for (let i in this.errors[sequenceIndex]) {
        const error = this.errors[sequenceIndex][i];

        if (index !== null) {
          if (error.type === type && Number(error.subIndex) === Number(index)) {
            this.errors[sequenceIndex].splice(i, 1);
            break;
          }
        } else {
          if (error.type === type) {
            this.errors[sequenceIndex].splice(i, 1);
            break;
          }
        }
      }
    }
  }

  existCoinOnExchange(compareCoin) {
    let coinExistOnExchange = false;

    if (this.isLeverage()) {
      for (let g in stores.exchangeInfo.instruments[this.root.rule.ex]) {
        let coin = stores.exchangeInfo.instruments[this.root.rule.ex][g];
        if (compareCoin && compareCoin.indexOf(coin.symbol) > -1) {
          coinExistOnExchange = true;
          break;
        }
      }
    } else {
      for (let g in stores.exchangeInfo.markets[this.root.rule.ex]) {
        let coin = stores.exchangeInfo.markets[this.root.rule.ex][g];
        if (
          compareCoin &&
          (compareCoin.indexOf(coin.base) > -1 || compareCoin.indexOf(coin.quote) > -1)
        ) {
          coinExistOnExchange = true;
          break;
        }
      }
    }

    return coinExistOnExchange;
  }

  storeSequenceBlockError(sequenceIndex, text, type, subIndex = null, subTypeError = null) {
    if (!this.errors[sequenceIndex]) {
      this.errors[sequenceIndex] = [];
    }

    let error = {
      text: text,
      type: type,
    };

    if (subIndex !== null) {
      error['subIndex'] = subIndex;
    }

    if (subTypeError !== null) {
      error['subTypeError'] = subTypeError;
    }

    this.errors[sequenceIndex].push(error);
    this.hasErrorValidation = true;
  }

  checkIfExistiValidationCoinError() {
    for (let sequenceIndex in this.sequences) {
      if (this.errors[sequenceIndex]) {
        for (let i in this.errors[sequenceIndex]) {
          const error = this.errors[sequenceIndex][i];

          if (error.subTypeError) {
            this.checkIfStillError(sequenceIndex, error.type, error.subIndex || null);
          }
        }
      }
    }
  }

  validationCoinAvailable() {
    const selectedRegion = stores.user.user.user.region || 'eu';
    const selectedRegionNameFiltered = SERVER_REGIONS.filter((r) => r.value === selectedRegion);
    const selectedRegionName =
      selectedRegionNameFiltered.length === 1 ? selectedRegionNameFiltered[0].name : '';

    if (this.sequences.length > 1) {
      if (
        (stores.exchangeInfo.markets &&
          stores.exchangeInfo.markets[this.root.rule.ex] &&
          stores.exchangeInfo.markets[this.root.rule.ex].length > 0) ||
        (this.isLeverage() &&
          stores.exchangeInfo.instruments &&
          stores.exchangeInfo.instruments[this.root.rule.ex] &&
          stores.exchangeInfo.instruments[this.root.rule.ex].length > 0)
      ) {
        // this.hasErrorValidation = false;
        for (let j in this.sequences) {
          let sequence = this.sequences[j];
          let coin;
          let errorText = '';
          let existCoin = null;

          if (sequence.type === CONDITION_TYPE) {
            if (
              this.root.rule.tr === TRIGGERS.EVENT ||
              (this.root.rule.tr !== TRIGGERS.EVENT && j > 0)
            ) {
              for (let g in sequence.data.conditions) {
                const data = sequence.data.conditions[g];

                if (data.ifc === 'symbol') {
                  coin = !!data.ifs.length && data.ifs[0];
                  existCoin = this.existCoinOnExchange(coin);
                  errorText = coin + " doesn't exist on your selected exchange";

                  if (existCoin !== null && !existCoin) {
                    this.storeSequenceBlockError(j, errorText, 'ifs', g, 'coin_error');
                  }
                }
              }
            }
          } else if (sequence.type === ACTION_TYPE) {
            if (sequence.data.actions[0].q !== '---' && !this.isLeverage()) {
              coin = sequence.data.actions[0].q;
              existCoin = this.existCoinOnExchange(sequence.data.actions[0].q);

              const exchangeSelected = stores.info.exchanges.filter(
                (ex) => ex.id === this.root.rule.ex
              );

              if (exchangeSelected.length > 0 && exchangeSelected[0].uid === 'coinbasepro') {
                errorText = `Please check if the selected wallet is available on your Coinbase Pro account. For more info contact us via the Chat`;
              } else {
                errorText = `${coin} wallet doesn't exist on your selected exchange or is not available for your region ${selectedRegionName}`;
              }

              if (existCoin !== null && !existCoin) {
                this.hasErrorValidationWallet = true;
                this.storeSequenceBlockError(j, errorText, 'q', null, 'coin_error');
              }
            }

            if (sequence.data.actions[0].ort === 'base') {
              coin = sequence.data.actions[0].b[0];
              existCoin = this.existCoinOnExchange(sequence.data.actions[0].b[0]);
              errorText = coin + " doesn't exist for your selected exchange";

              if (existCoin !== null && !existCoin) {
                this.storeSequenceBlockError(j, errorText, 'ort', null, 'coin_error');
              }
            }
          }
        }
      }
    }
  }

  isRuleProfitCondition(data) {
    return data.ift === SYMBOL_TYPES.RULE;
  }

  isTradingViewSignalCondition(data) {
    return data.ift === SYMBOL_TYPES.SIGNAL && data.ifc === CONDITION_TYPES.TRADINGVIEW_SIGNAL;
  }

  @action
  validationFields(checkIndex = null, subIndex = null, onlyCheck = false) {
    const foundErrors = {};
    const exchangeDataHelp = stores.info.exchanges.filter((ex) => ex.id === this.root.rule.ex);
    const exchangeData = exchangeDataHelp[0] ? exchangeDataHelp[0] : null;

    this.hasErrorValidation = false;

    const initSequenceErrors = (sequenceIndex) => {
      if (!foundErrors[sequenceIndex]) {
        foundErrors[sequenceIndex] = [];
      }
    };

    for (let sequenceIndex in this.sequences) {
      const sequence = this.sequences[sequenceIndex];

      if (checkIndex !== null) {
        if (Number(checkIndex) < Number(sequenceIndex)) {
          break;
        } else if (Number(checkIndex) > Number(sequenceIndex)) {
          continue;
        }
      }

      if (sequence) {
        if (sequence.type === CONDITION_TYPE) {
          for (let i in sequence.data.conditions) {
            if (subIndex !== null) {
              if (Number(subIndex) < Number(i)) {
                break;
              } else if (Number(subIndex) > Number(i)) {
                continue;
              }
            }

            const data = sequence.data.conditions[i];

            //errors
            if (data.in === '---' && !this.isRuleProfitCondition(data)) {
              initSequenceErrors(sequenceIndex);

              this.hasErrorValidation = true;
              foundErrors[sequenceIndex].push({
                text: this.isTradingViewSignalCondition(data)
                  ? 'No Signal selected. Choose Signal to continue'
                  : 'No Indicator selected. Choose Indicator to continue',
                type: 'in',
                subIndex: i,
              });
            }

            if (data.ifc === '---') {
              initSequenceErrors(sequenceIndex);

              this.hasErrorValidation = true;
              foundErrors[sequenceIndex].push({
                text: 'Choose Input Symbol',
                type: 'ifs',
                subIndex: i,
              });
            }

            if (
              (data.op === 'inc' || data.op === 'dec') &&
              (data.wi === '' || (data.wi === 'delta' && data.d === ''))
            ) {
              initSequenceErrors(sequenceIndex);

              this.hasErrorValidation = true;
              foundErrors[sequenceIndex].push({
                text: 'No Timeframe selected. Choose Timeframe to continue',
                type: 'wi',
                subIndex: i,
              });
            }

            if (data.in === 'rsi') {
              if (!(exchangeData && exchangeData.rsi)) {
                initSequenceErrors(sequenceIndex);

                this.hasErrorValidation = true;
                foundErrors[sequenceIndex].push({
                  text: 'RSI indicator is not currently available on the selected exchange',
                  type: ['rsi'].includes(data.in) ? 'in' : 'op',
                  subIndex: i,
                });
              }
            }

            if (
              (['ma_1', 'ma_5', 'ma_9', 'ma_50', 'ma_100', 'ma_200'].includes(data.in) ||
                ['ma_1', 'ma_5', 'ma_9', 'ma_50', 'ma_100', 'ma_200'].includes(data.maci)) &&
              !(exchangeData && exchangeData.ma_periods && exchangeData.ma_periods.length > 0)
            ) {
              initSequenceErrors(sequenceIndex);

              this.hasErrorValidation = true;
              foundErrors[sequenceIndex].push({
                text: 'MA indicators are not currently available on the selected exchange',
                type: ['ma_1', 'ma_5', 'ma_9', 'ma_50', 'ma_100', 'ma_200'].includes(data.in)
                  ? 'in'
                  : 'op',
                subIndex: i,
              });
            }

            if (
              data.in !== '---' &&
              !this.isTradingViewSignalCondition(data) &&
              ['ma_1', 'ma_5', 'ma_9', 'ma_50', 'ma_100', 'ma_200', 'rsi'].indexOf(data.in) ===
                -1 &&
              ['above', 'below'].indexOf(data.op) === -1
            ) {
              if (data.a && (data.a.iv === '' || data.a.iv === null)) {
                initSequenceErrors(sequenceIndex);

                this.hasErrorValidation = true;
                foundErrors[sequenceIndex].push({
                  text: 'Choose an amount',
                  type: 'a',
                  subIndex: i,
                });
              }

              if (data.ifc === 'symbol') {
                for (let j in data.ifs) {
                  let coin = data.ifs[j];

                  if (coin === data.a.s) {
                    initSequenceErrors(sequenceIndex);

                    this.hasErrorValidation = true;
                    foundErrors[sequenceIndex].push({
                      text: `Cannot compare ${coin} with ${data.a.s}`,
                      type: 'a',
                      subIndex: i,
                    });
                  }
                }
              }
            }

            if (
              data.op === '---' &&
              !this.isRuleProfitCondition(data) &&
              !this.isTradingViewSignalCondition(data)
            ) {
              initSequenceErrors(sequenceIndex);

              this.hasErrorValidation = true;
              foundErrors[sequenceIndex].push({
                text: 'No Condition selected. Choose Condition to continue',
                type: 'op',
                subIndex: i,
              });
            }

            if (this.isLeverage()) {
              // leverage specific validations

              if (data.in !== '---' && ['volume', 'marketcap'].includes(data.in)) {
                initSequenceErrors(sequenceIndex);

                this.hasErrorValidation = true;
                foundErrors[sequenceIndex].push({
                  text: 'Unsupported Indicator selected. Choose another Indicator to continue',
                  type: 'in',
                  subIndex: i,
                });
              }
            }
          }
        } else if (sequence.type === ACTION_TYPE) {
          const data = sequence.data.actions[0];

          if (data.v.iv === null) {
            initSequenceErrors(sequenceIndex);

            this.hasErrorValidation = true;
            foundErrors[sequenceIndex].push({
              text: 'Trade Amount is too low. Select higher amount',
              type: 'v',
            });
          } else if (
            stores.info.baseCurrencies?.includes(data.v.s) &&
            data.v.s !== 'BTC' &&
            data.v.iv < MIN_TRADABLE_VALUE
          ) {
            initSequenceErrors(sequenceIndex);

            this.hasErrorValidation = true;
            foundErrors[sequenceIndex].push({
              text: `The amount in Action is below the minimum required ${MIN_TRADABLE_VALUE} ${data.v.s}. Please increase your trade amount`,
              type: 'v',
            });
          }

          if (
            !this.isLeverage() &&
            stores.exchangeInfo.markets &&
            stores.exchangeInfo.markets[this.root.rule.ex] &&
            stores.exchangeInfo.markets[this.root.rule.ex].length > 0
          ) {
            if (data.b.length > 0 && data.q) {
              const base = data.b[0];
              const quote = data.q;
              const checkValue = stores.exchangeInfo.markets[this.root.rule.ex].filter(
                (coin, i) =>
                  coin.base === base &&
                  (coin.quote === quote || (coin.quote === base && coin.base === quote))
              );

              if (checkValue.length > 0) {
                if (data.v.s === '-') {
                  if (data.v.iv <= checkValue[0].minQty) {
                    initSequenceErrors(sequenceIndex);

                    this.hasErrorValidation = true;
                    foundErrors[sequenceIndex].push({
                      text: `Trade Amount is lower than the minimum set by the exchange. Select a value greater than ${checkValue[0].minQty}`,
                      type: 'v',
                    });
                  }
                } else if (data.v.s !== '%') {
                  const price = getCoinValue(base, this.root.rule.ex).price;
                  // const minPrice = Number(price)*checkValue[0].minQty;
                  if (Number(data.v.iv) / price <= checkValue[0].minQty) {
                    initSequenceErrors(sequenceIndex);

                    this.hasErrorValidation = true;
                    foundErrors[sequenceIndex].push({
                      text: `Trade Amount is lower than the minimum set by the exchange. Select a value greater than ${checkValue[0].minQty}`,
                      type: 'v',
                    });
                  }
                }
              }
            }
          }

          if (!this.isLeverage()) {
            const quoteCoins = stores.info.lastSelectedExchange?.quote_coins
              ?.map((coin) => {
                const assets = toJS(stores.exchangeInfo.assets[this.root.rule.ex]);
                const fiats = toJS(stores.exchangeInfo.fiats[this.root.rule.ex]);

                if (assets && fiats && (assets[coin] || fiats[coin])) {
                  return coin;
                }
                return undefined;
              })
              .filter((element) => element !== undefined);

            if (quoteCoins && !quoteCoins.includes(data.q)) {
              initSequenceErrors(sequenceIndex);

              this.hasErrorValidation = true;
              foundErrors[sequenceIndex].push({
                text: `This wallet is not available on the selected exchange. Please choose another one.`,
                type: 'q',
              });
            }
          }

          if (!isNaN(Number(data.v.iv))) {
            if (Number(data.v.iv) <= 0) {
              initSequenceErrors(sequenceIndex);

              this.hasErrorValidation = true;
              foundErrors[sequenceIndex].push({
                text: 'Trade Amount is too low. Select higher amount',
                type: 'v',
              });
            }

            if (this.isLeverage()) {
              const instrumentSelected =
                this.sequences[0].data.conditions?.[0].ifs?.[0] || // condition
                this.sequences[0].data.actions?.[0].b?.[0]; // action

              const lotSize =
                Number(
                  stores.exchangeInfo.instruments[this.root.rule.ex].find(
                    (a) => a.symbol === instrumentSelected
                  )?.lotSize
                ) || STANDARD_LEVERAGE_LOT_SIZE;

              if (
                data.v.iv < MIN_LEVERAGE_ORDER_SIZE ||
                (data.v.iv >= lotSize && data.v.iv / lotSize < STANDARD_LEVERAGE_LOT_SIZE)
              ) {
                initSequenceErrors(sequenceIndex);

                this.hasErrorValidation = true;
                foundErrors[sequenceIndex].push({
                  text:
                    data.v.iv < MIN_LEVERAGE_ORDER_SIZE
                      ? `Value is too low. Minimum value: ${MIN_LEVERAGE_ORDER_SIZE}`
                      : "Value should be a multiple of selected instrument's lot size: " + lotSize,
                  type: 'v',
                });
              }
            }
          }

          if (data.ort === '---') {
            initSequenceErrors(sequenceIndex);

            this.hasErrorValidation = true;
            foundErrors[sequenceIndex].push({
              text: 'No coin selected. Choose coin to continue',
              type: 'ort',
            });
          }

          if (data.q === '---' && !this.isLeverage()) {
            initSequenceErrors(sequenceIndex);

            this.hasErrorValidation = true;
            foundErrors[sequenceIndex].push({
              text: 'No wallet selected. Choose wallet to continue',
              type: 'q',
            });
          }
        } else if (sequence.type === NOTIFY_TYPE) {
          const data = sequence.data.notifies[0];

          if(data.channel === ENotifyChannel.TELEGRAM && !stores.user.userAccountInfo?.telegramId) {
            initSequenceErrors(sequenceIndex);
            
            this.hasErrorValidation = true;
            foundErrors[sequenceIndex].push({
              text: 'No Telegram account connected',
              type: '',
            });
          }
        } else if (sequence.type === OPERATOR_TYPE) {
          const data = sequence.data.operators[0];

          if (data.t === OPERATOR_TYPES.DO_NOT && this.isLeverage()) {
            initSequenceErrors(sequenceIndex);

            this.hasErrorValidation = true;
            foundErrors[sequenceIndex].push({
              text: 'You cannot use DO NOT operator for leverage trading. Remove the operator to continue',
              type: 'dn',
            });
          } else if (data.t === OPERATOR_TYPES.DO_NOT && data.dn.length === 0) {
            initSequenceErrors(sequenceIndex);

            this.hasErrorValidation = true;
            foundErrors[sequenceIndex].push({
              text: 'No coin selected. Choose coin to continue',
              type: 'dn',
            });
          } else if (data.t === OPERATOR_TYPES.WAIT && !data.wt) {
            initSequenceErrors(sequenceIndex);

            this.hasErrorValidation = true;
            foundErrors[sequenceIndex].push({
              text: 'No "wait type" selected. Choose a wait type to continue',
              type: 'wt',
            });
          }
        }
      }
    }

    if (!onlyCheck) {
      this.errors = foundErrors;
      this.validationCoinAvailable();
    } else {
      return foundErrors;
    }
  }

  @action
  increaseId() {
    return ++this.idCount;
  }

  @action
  addToSequence(index, type, subType) {
    let component = null;
    let addCondition = false;
    this.preloadSequences = true;

    if (index === null) {
      index = this.getNewSequenceIndex();
    }

    if (type === ACTION_TYPE) {
      // Extracted a piece of logic to make sure the code modularity is improved
      // will be extracting CONDITION_TYPE and OPERATION_TYPE as well
      // to make sure that the code is more understandable.
      component = handleActionType(
        index,
        component,
        this.sequences,
        this.addRuleStore,
        this.addRuleParent,
        {
          idCount: this.increaseId(),
        }
      );
    } else if (type === CONDITION_TYPE) {
      let includeBlock = '';
      let stop;
      let allActionIndexes = [];
      let conditionData = { ...defaultCondition };
      let startIndex;
      const previousBlock = this.getPreviousElement(index);

      conditionData.a.s = this.addRuleStore.tempCurrency;

      if (index > 0) {
        const thenIndex = this.findIndexSequenceBeforeByType(
          OPERATOR_TYPE,
          index,
          OPERATOR_TYPES.THEN
        );
        const parallelIndex = this.findIndexSequenceBeforeByType(
          OPERATOR_TYPE,
          index,
          OPERATOR_TYPES.PARALLEL
        );
        const orIndex = this.findIndexSequenceBeforeByType(OPERATOR_TYPE, index, OPERATOR_TYPES.OR);
        const waitIndex = this.findIndexSequenceBeforeByType(
          OPERATOR_TYPE,
          index,
          OPERATOR_TYPES.WAIT
        );
        let conditionIndex = this.findIndexSequenceBeforeByType(CONDITION_TYPE, index);

        if (
          previousBlock &&
          (OPERATOR_TYPE === previousBlock.type || previousBlock.type === CONDITION_TYPE)
        ) {
          if (
            OPERATOR_TYPE === previousBlock.type &&
            [OPERATOR_TYPES.THEN, OPERATOR_TYPES.PARALLEL].indexOf(
              previousBlock.data.operators[0].t
            ) > -1
          ) {
            startIndex = thenIndex;
            stop = 0;
            if (conditionIndex > -1) {
              stop = conditionIndex;
            }

            allActionIndexes = this.findAllIndexesSequenceBeforeByType(
              ACTION_TYPE,
              startIndex,
              stop
            );
          } else if (
            OPERATOR_TYPE === previousBlock.type &&
            OPERATOR_TYPES.WAIT === previousBlock.data.operators[0].t
          ) {
            startIndex = waitIndex;
            stop = 0;

            if (orIndex > -1) {
              stop = orIndex;
            } else if (parallelIndex > -1) {
              stop = parallelIndex;
            } else if (thenIndex > -1) {
              stop = thenIndex;
            }

            allActionIndexes = this.findAllIndexesSequenceBeforeByType(
              ACTION_TYPE,
              startIndex,
              stop
            );
          } else if (
            (OPERATOR_TYPE === previousBlock.type &&
              OPERATOR_TYPES.OR === previousBlock.data.operators[0].t) ||
            previousBlock.type === CONDITION_TYPE
          ) {
            if (parallelIndex > -1) {
              startIndex = parallelIndex;
              stop = this.findIndexSequenceBeforeByType(CONDITION_TYPE, startIndex);
            } else if (thenIndex > -1) {
              startIndex = thenIndex;
              stop = this.findIndexSequenceBeforeByType(CONDITION_TYPE, startIndex);
            } else if (conditionIndex > -1) {
              startIndex = conditionIndex;
            }

            if (stop === -1) {
              stop = 0;
            }

            allActionIndexes = this.findAllIndexesSequenceBeforeByType(
              ACTION_TYPE,
              startIndex,
              stop
            );
          }
        }

        // We assume there is an action defined in the rule already
        if (allActionIndexes.length > 0) {
          conditionData.ifc = 'from_sequence';
          conditionData.sr = allActionIndexes[0];
          conditionData.wi = 'action_price'; // conditionData.wi = 'trailing';
        }
        // Provided this condition is NOT based on TradingView signal
        // and there if there are up to two steps before and at least one is a Condition
        else if (
          (this.sequences[index - 1]?.type === CONDITION_TYPE ||
            this.sequences[index - 2]?.type === CONDITION_TYPE) &&
          !this.root.usingTradingViewSignal[this.root.rule.tr]
        ) {
          conditionData.ifc = 'that_coin';
          conditionData.sr = null;
          conditionData.wi = 'action_price';
        } else if (
          // Provided this condition is based on TradingView signal
          // and there if there are up to two steps before and at least one is a Condition
          this.sequences[index - 1]?.type === CONDITION_TYPE ||
          this.sequences[index - 2]?.type === CONDITION_TYPE
        ) {
          conditionData.ifc = '---';
          conditionData.sr = null;
          conditionData.wi = 'action_price';
        }

        if (this.sequences[index - 1]) {
          if (
            this.sequences[index - 1].type === OPERATOR_TYPE &&
            [OPERATOR_TYPES.THEN, OPERATOR_TYPES.PARALLEL, OPERATOR_TYPES.OR].indexOf(
              this.sequences[index - 1].data.operators[0].t
            ) > -1
          ) {
            includeBlock = OPERATOR_TYPE;
          } else if (this.sequences[index - 1].type === CONDITION_TYPE) {
            includeBlock = this.sequences[index - 1].includeBlock || CONDITION_TYPE;
          }
        }
      }

      if (
        !(
          previousBlock &&
          previousBlock.type === OPERATOR_TYPE &&
          previousBlock.data.operators[0].t === 'do_not'
        )
      ) {
        component = {
          id: ++this.idCount,
          errors: [],
          type: CONDITION_TYPE,
          includeBlock: includeBlock,
          data: {
            conditions: [conditionData],
          },
          component: (
            <Condition
              key={this.idCount}
              store={this.addRuleStore}
              parent={this.addRuleParent}
              id={this.idCount}
              index={index}
              partIndex={0}
            />
          ),
        };
      }
    } else if (type === OPERATOR_TYPE) {
      let operatorData = { ...defaultOperator };

      const previousElement = this.getPreviousElement(index);

      if (this.root.rule.tr !== TRIGGERS.DIRECT_ORDER) {
        if (previousElement.type === CONDITION_TYPE) {
          operatorData.t = 'wait';
        } else if (!this.usedThen && !this.usedParallel) {
          operatorData.t = 'then';
          addCondition = true;
        } else {
          const thenIndex = this.getThenOperator();
          const orIndex = this.getOrOperator();

          if (thenIndex < index && orIndex === -1) {
            operatorData.t = 'or';
            addCondition = true;
          } else if (previousElement.type === ACTION_TYPE && !this.isLeverage()) {
            operatorData.t = 'do_not';
          } else if (this.typesCounting.operators.types.wait < 1) {
            operatorData.t = 'wait';
          }
        }

        if (subType === OPERATOR_TYPES.THEN && !this.usedThen) {
          operatorData.t = 'then';
          addCondition = true;
        } else if (
          subType === OPERATOR_TYPES.DO_NOT &&
          !this.isLeverage() &&
          previousElement.type === ACTION_TYPE
        ) {
          operatorData.t = 'do_not';
        } else if (subType === OPERATOR_TYPES.WAIT && this.typesCounting.operators.types.wait < 1) {
          operatorData.t = 'wait';
        } else if (subType === OPERATOR_TYPES.OR && !this.usedOr) {
          operatorData.t = 'or';
          addCondition = true;
          this.usedOr = true;
        } else if (subType === OPERATOR_TYPES.PARALLEL && !this.usedParallel) {
          operatorData.t = 'parallel';
          addCondition = true;
        } else if (
          !operatorData.t &&
          (previousElement.type !== OPERATOR_TYPE || previousElement.data.operators[0].t !== 'wait')
        ) {
          operatorData.t = 'wait';
        }
      } else if (!this.isLeverage() && previousElement.type === ACTION_TYPE) {
        operatorData.t = 'do_not';
      }

      if (operatorData.t) {
        component = {
          id: ++this.idCount,
          errors: [],
          type: OPERATOR_TYPE,
          data: {
            operators: [operatorData],
          },
          component: (
            <Operator
              key={this.idCount}
              store={this.addRuleStore}
              parent={this.addRuleParent}
              id={this.idCount}
              index={index}
              partIndex={0}
            />
          ),
        };
      }
    } else if (type === NOTIFY_TYPE) {
      component = handleNotifyType(this.addRuleStore, this.addRuleParent, {
        idCount: this.increaseId(),
      });
    }

    if (component) {
      this.sequences.push(component);
      this.preloadSequences = false;

      if (addCondition) {
        this.addToSequence(null, CONDITION_TYPE);
      }

      this.countingSequence();

      this.sequenceSnapshots[this.root.tr] = this.sequences.slice(0);
      this.sequences = this.sequenceSnapshots[this.root.tr].slice(0);

      return index;
    }

    this.preloadSequences = false;

    return null;
  }

  @action
  refreshSequences() {
    this.countingSequence();

    this.sequenceSnapshots[this.root.tr] = this.sequences.slice(0);
    this.sequences = this.sequenceSnapshots[this.root.tr].slice(0);
  }

  getNewSequenceIndex() {
    return this.sequences.length;
  }

  @action
  changeOperatorTypeCount(oldType, newType) {
    if (this.typesCounting.operators.types[oldType] > 0) {
      this.typesCounting.operators.types[oldType]--;
    }

    this.typesCounting.operators.types[newType]++;
  }

  @action
  removeFromSequence(index) {
    if (this.sequences.length > index) {
      if (
        this.sequences[index - 1] &&
        this.sequences[index - 1].type === OPERATOR_TYPE &&
        [OPERATOR_TYPES.PARALLEL, OPERATOR_TYPES.THEN, OPERATOR_TYPES.OR].indexOf(
          this.sequences[index - 1].data.operators[0].t
        ) > -1
      ) {
        this.sequences.splice(index, 1);
        index = index - 1;
      }
      this.sequences.splice(index, 1);
      this.countingSequence();
      this.root.sequenceRemoved(index);
    }
  }

  @action
  removeFromSequenceDirect(index) {
    if (this.sequences.length > index) {
      this.sequences.splice(index, 1);
      this.countingSequence();
      this.root.sequenceRemoved(index);
    }
  }

  @action
  initBaseRule(store, parent) {
    this.addRuleStore = store;
    this.addRuleParent = parent;
    this.addToSequence(null, CONDITION_TYPE);
  }

  @action
  removeDataFromSequence(elementIndex, type) {
    if (type === CONDITION_TYPE) {
      this.sequences[elementIndex].data.conditions = [];
    }
  }

  @action
  initDataFromSequence(index, type) {
    if (type === CONDITION_TYPE) {
      this.sequences[index].data.conditions = [defaultCondition];
    } else if (type === ACTION_TYPE) {
      this.sequences[index].data.actions = [defaultAction];
    } else if (type === OPERATOR_TYPE) {
      this.sequences[index].data.operators = [defaultOperatorThen];
    }
  }

  getLastElement() {
    return this.sequences[this.sequences.length - 1];
  }

  getIndexFirstType(type) {
    return this.sequences.findIndex((item) => item.type === type || item.t === type);
  }

  getThenOperator() {
    return this.sequences.findIndex(
      (item) =>
        item.type === OPERATOR_TYPE &&
        item.data.operators &&
        item.data.operators[0].t === OPERATOR_TYPES.THEN
    );
  }

  getWaitOperator() {
    return this.sequences.findIndex(
      (item) =>
        item.type === OPERATOR_TYPE &&
        item.data.operators &&
        item.data.operators[0].t === OPERATOR_TYPES.WAIT
    );
  }

  getOrOperator() {
    return this.sequences.findIndex(
      (item) =>
        item.type === OPERATOR_TYPE &&
        item.data.operators &&
        item.data.operators[0].t === OPERATOR_TYPES.OR
    );
  }

  getParallelOperator() {
    return this.sequences.findIndex(
      (item) =>
        item.type === OPERATOR_TYPE &&
        item.data.operators &&
        item.data.operators[0].t === OPERATOR_TYPES.PARALLEL
    );
  }

  getAction() {
    return this.sequences.findIndex((item) => item.type === ACTION_TYPE);
  }

  getIndexById(id) {
    return this.sequences.findIndex((item) => item.id === id);
  }

  @action
  findAllSequenceByTypeBefore(type, startIndex) {
    if (startIndex === null) {
      return [];
    }

    let allIndexes = [];
    this.sequences.forEach((item, index) => {
      if ((item.type === type || item.t === type) && startIndex > index) {
        allIndexes.push(index);
      }
    });

    return allIndexes;
  }

  @action
  hasTwoActionsBefore(endIndex) {
    const START_INDEX_FOR_CHECK_ACTION = 5;
    let allIndexes = [];
    let startIndex = endIndex - START_INDEX_FOR_CHECK_ACTION;
    if (startIndex < 0) {
      startIndex = 0;
    }

    this.sequences.forEach((item, index) => {
      if (item.type === ACTION_TYPE && endIndex > index && index > startIndex) {
        allIndexes.unshift(index);
      }
    });

    return allIndexes.length >= 2;
  }

  @action
  setUsedThan(wasUsed) {
    this.usedThen = wasUsed;
  }

  @action
  findAllSequenceByTypeAfter(type, startIndex, endIndex = 999) {
    if (endIndex === -1) {
      endIndex = 999;
    }
    let allIndexes = [];
    this.sequences.forEach((item, index) => {
      if ((item.type === type || item.t === type) && startIndex < index && endIndex > index) {
        allIndexes.push(index);
      }
    });

    return allIndexes;
  }

  @action
  findCloseSequenceIndexByType(type, actualIndex) {
    return this.sequences.findIndex(
      (item, index) => (item.type === type || item.t === type) && actualIndex < index
    );
  }

  findIndexSequenceBeforeByType(type, actualIndex, subType = null) {
    let foundIndex = -1;

    for (let i = actualIndex; i >= 0; i--) {
      if (
        this.sequences.length > i &&
        this.sequences[i] &&
        this.sequences[i].type === type &&
        actualIndex !== i
      ) {
        if (subType && type === OPERATOR_TYPE) {
          if (this.sequences[i].data.operators.length > 0) {
            if (this.sequences[i].data.operators[0].t === subType) {
              foundIndex = i;
              break;
            }
          }
        }

        if (!subType) {
          foundIndex = i;
          break;
        }
      }
    }

    return foundIndex;
  }

  getSequenceTypeAndPositionByIndex(index) {
    const sequence = this.sequences[index];

    if (sequence) {
      const sequencesBefore = this.findAllSequenceByTypeBefore(sequence.type || sequence.t, index);

      return {
        type: BLOCK_TYPES[sequence.type || sequence.t],
        position: sequencesBefore.length + 1,
        thatCoinByPass: sequencesBefore.length === 0,
      };
    } else {
      return null;
    }
  }

  findAllIndexesSequenceBeforeByType(type, actualIndex, stop = 0) {
    let foundIndex = [];
    if (this.sequences.length > 0) {
      for (let i = actualIndex; i >= stop; i--) {
        if (
          this.sequences[i] &&
          (this.sequences[i].type === type || this.sequences[i].t === type) &&
          actualIndex !== i
        ) {
          foundIndex.unshift(i);
        }
      }
    }

    return foundIndex;
  }

  @action
  findIndexSequenceAfterByType(type, actualIndex, subType = null) {
    let foundIndex;
    for (let i = actualIndex; i < this.sequences.length; i++) {
      if (this.sequences.length > i) {
        if (
          this.sequences[i] &&
          (this.sequences[i].type === type || this.sequences[i].t === type) &&
          actualIndex !== i
        ) {
          if (subType && type === OPERATOR_TYPE) {
            if (this.sequences[i].data.operators.length > 0) {
              if (this.sequences[i].data.operators[0].t === subType) {
                foundIndex = i;
                break;
              }
            }
          }

          if (!subType) {
            foundIndex = i;
            break;
          }
        }
      }
    }
    return foundIndex;
  }

  @action
  getCountTypeBetweenTwoIndexes(startIndex, endIndex) {
    let counts = {
      conditions: {
        count: 0,
      },
      actions: {
        count: 0,
      },
      operators: {
        count: 0,
        types: {
          then: 0,
          or: 0,
          wait: 0,
          doNot: 0,
          parallel: 0,
        },
      },
    };

    for (let i = startIndex; i < endIndex; i++) {
      if (this.sequences.length > i) {
        if (this.sequences[i]) {
          if (this.sequences[i].type === CONDITION_TYPE) {
            counts.conditions.count++;
          } else if (this.sequences[i].type === ACTION_TYPE) {
            counts.actions.count++;
          } else if (this.sequences[i].type === OPERATOR_TYPE) {
            counts.operators.count++;
            if (this.sequences[i].data.operators[0].t === OPERATOR_TYPES.THEN) {
              counts.operators.types.then++;
            } else if (this.sequences[i].data.operators[0].t === OPERATOR_TYPES.OR) {
              counts.operators.types.or++;
            } else if (this.sequences[i].data.operators[0].t === OPERATOR_TYPES.WAIT) {
              counts.operators.types.wait++;
            } else if (this.sequences[i].data.operators[0].t === OPERATOR_TYPES.DO_NOT) {
              counts.operators.types.doNot++;
            } else if (this.sequences[i].data.operators[0].t === OPERATOR_TYPES.PARALLEL) {
              counts.operators.types.parallel++;
            }
          }
        }
      }
    }

    return counts;
  }

  @action
  isBeforeElement(elementIndex, typeElement) {
    const typeElementIndex = this.getIndexFirstType(typeElement);
    return typeElementIndex !== -1 && elementIndex > typeElementIndex;
  }

  @action
  getPreviousElement(index) {
    let previousIndex = index - 1;

    if (previousIndex >= 0 && this.sequences[previousIndex]) {
      return this.sequences[previousIndex];
    }

    return null;
  }

  @action
  getNextElement(index) {
    let nextIndex = index + 1;
    if (nextIndex < this.sequences.length) {
      return this.sequences[nextIndex];
    }

    return null;
  }

  canBeTabChange(sequenceIndex) {
    if (
      this.sequences[this.sequences.length - 1] &&
      this.sequences[this.sequences.length - 1].type === CONDITION_TYPE
    ) {
      if (
        this.sequences[this.sequences.length - 2] &&
        this.sequences[this.sequences.length - 2].type === OPERATOR_TYPE
      ) {
        return sequenceIndex === this.sequences.length - 2;
      }
    }

    return sequenceIndex === this.sequences.length - 1;
  }

  @action
  validationBlock(newBlockType, blockIndex, part, tabType = null) {
    const blockType = newBlockType.toLowerCase();
    const previousBlock = this.getPreviousElement(blockIndex);
    const currentBlock = this.getPreviousElement(blockIndex + 1);
    const doNotOperatorNotAllowed =
      this.isLeverage() ||
      !currentBlock ||
      (!!previousBlock &&
        previousBlock.type !== ACTION_TYPE &&
        currentBlock.type !== ACTION_TYPE) ||
      (this.root.rule.tr === TRIGGERS.DIRECT_ORDER && currentBlock.type === OPERATOR_TYPE);

    if (stores.user.sequenceValidationDef[blockType]) {
      let strictRules = true;
      let mandatoryRules = false;

      let rules = [];

      if (part === 'block') {
        rules = stores.user.sequenceValidationDef[blockType].rules;
      } else if (
        part === 'tabs' &&
        tabType !== null &&
        stores.user.sequenceValidationDef[blockType].tabs[tabType]
      ) {
        tabType = tabType.toLowerCase();
        rules = stores.user.sequenceValidationDef[blockType].tabs[tabType].rules;

        if (tabType === 'do_not' && doNotOperatorNotAllowed) {
          // do not allow DO NOT operator for leverage or if it's not after action
          return false;
        }
      } else {
        return false;
      }

      for (let i = 0; i < rules.strict.length; i++) {
        const rule = rules.strict[i];

        if (rule.compare) {
          if (rule.compare === 'element') {
            const checkElementIndex = blockIndex + rule.elementIndex;
            const checkElement = this.sequences[checkElementIndex];

            if (rule.condition === 'isBefore') {
              if (rule.type === 'operator') {
                let found = false;
                for (let j in rule.subType) {
                  let foundIndex = -1;
                  if (rule.subType[j] === 'then') {
                    foundIndex = this.getThenOperator();
                  } else if (rule.subType[j] === 'or') {
                    foundIndex = this.getOrOperator();
                  } else if (rule.subType[j] === 'parallel') {
                    foundIndex = this.getParallelOperator();
                  }

                  if (foundIndex > -1 && foundIndex < blockIndex) {
                    found = true;
                  }
                }

                if (found) {
                  strictRules = false;
                  break;
                }
              } else if (rule.type === 'condition') {
                let conditionIndex = this.findIndexSequenceBeforeByType(CONDITION_TYPE, blockIndex);
                if (rule.value) {
                  if (conditionIndex === -1) {
                    strictRules = false;
                    break;
                  }
                } else {
                  if (conditionIndex > -1) {
                    strictRules = false;
                    break;
                  }
                }
              }
            } else {
              if (checkElement && rule.value !== checkElement.type.toLowerCase()) {
                strictRules = false;
                break;
              }

              if (tabType && checkElement && blockType === 'operator') {
                if (checkElement && blockType === checkElement.type.toLowerCase()) {
                  if (rule.subType && rule.subType.indexOf(tabType) === -1) {
                    strictRules = false;
                    break;
                  }
                }
              }
            }
          } else if (rule.compare === 'maxCount') {
            let countingType;
            let compareValue = 99;
            let countActionAndNotifies;

            if (blockType === 'condition') {
              countingType = 'conditions';
            } else if (blockType === 'action') {
              countActionAndNotifies = true;
              countingType = 'actions';
            } else if (blockType === 'operator') {
              countingType = 'operators';
            }

            if (rule.subType) {
              compareValue = 0;

              if (rule.subType.indexOf(OPERATOR_TYPES.WAIT) > -1) {
                compareValue += this.typesCounting[countingType].types['wait'];
                if (
                  this.sequences[blockIndex].type === OPERATOR_TYPE &&
                  this.sequences[blockIndex].data.operators[0].t === 'wait'
                ) {
                  compareValue -= 1;
                }
              }

              if (rule.subType.indexOf(OPERATOR_TYPES.OR) > -1) {
                compareValue += this.typesCounting[countingType].types['or'];
                if (
                  this.sequences[blockIndex].type === OPERATOR_TYPE &&
                  this.sequences[blockIndex].data.operators[0].t === 'or'
                ) {
                  compareValue -= 1;
                }
              }

              if (rule.subType.indexOf(OPERATOR_TYPES.DO_NOT) > -1) {
                compareValue += this.typesCounting[countingType].types['do_not'];
                if (
                  this.sequences[blockIndex].type === OPERATOR_TYPE &&
                  this.sequences[blockIndex].data.operators[0].t === 'do_not'
                ) {
                  compareValue -= 1;
                }
              }

              if (rule.subType.indexOf(OPERATOR_TYPES.THEN) > -1) {
                compareValue += this.typesCounting[countingType].types['then'];
                if (
                  this.sequences[blockIndex].type === OPERATOR_TYPE &&
                  this.sequences[blockIndex].data.operators[0].t === 'then'
                ) {
                  compareValue -= 1;
                }
              }

              if (rule.subType.indexOf(OPERATOR_TYPES.PARALLEL) > -1) {
                compareValue += this.typesCounting[countingType].types['parallel'];
                if (
                  this.sequences[blockIndex].type === OPERATOR_TYPE &&
                  this.sequences[blockIndex].data.operators[0].t === 'parallel'
                ) {
                  compareValue -= 1;
                }
              }
            } else if (countActionAndNotifies) {
              compareValue = this.typesCounting.actions.count + this.typesCounting.notifies.count;
            } else {
              compareValue = this.typesCounting[countingType].count;
            }

            let ruleValue = rule.value;
            const sr = this.compareCondition(rule.condition, compareValue, ruleValue);

            if (!sr) {
              strictRules = false;
              break;
            }
          } else if (rule.compare === 'subTypeCount') {
            let compareValue = 0;
            let countingSubType;
            let countActionAndNotifies;
            let countingType;

            if (blockType === 'condition') {
              countingType = 'conditions';
            } else if (blockType === 'action') {
              countActionAndNotifies = true;
              countingType = 'actions';
            } else if (blockType === 'operator') {
              countingType = 'operators';
              countingSubType = tabType;
            }

            if (countActionAndNotifies) {
              compareValue = this.typesCounting.actions.count + this.typesCounting.notifies.count;
            } else if (countingSubType) {
              compareValue = this.typesCounting[countingType].types[tabType];
            } else {
              compareValue = this.typesCounting[countingType].count;
            }

            const sr = this.compareCondition(rule.condition, compareValue, rule.value);

            if (!sr) {
              strictRules = false;
              break;
            }
          } else if (rule.compare === 'blockCount') {
            let j = blockIndex - 1;

            if (
              this.sequences[blockIndex] &&
              (blockType === 'action'
                ? ['action', 'notify'].includes(this.sequences[blockIndex].type.toLowerCase())
                : this.sequences[blockIndex].type.toLowerCase() === blockType)
            ) {
              let blockCount = 2; // There is already one sequence of this type and now we would add another one

              while (
                this.sequences[j] &&
                (blockType === 'action'
                  ? ['action', 'notify'].includes(this.sequences[j].type.toLowerCase())
                  : this.sequences[j].type.toLowerCase() === blockType)
              ) {
                blockCount++;
                j--;
              }

              let compareValue = rule.value;

              if (rule.trigger && this.root.rule.tr === rule.trigger) {
                compareValue = rule.trigger_value;
              }

              if (this.isLeverage() && blockType === 'action') {
                // for leverage we can only support 1 action per block
                compareValue = 1; // TODO: solve this for notify on leverage exchange
              }

              if (rule.condition === '<=') {
                if (blockCount > compareValue) {
                  strictRules = false;
                  break;
                }
              } else if (rule.condition === '>=') {
                if (blockCount < compareValue) {
                  strictRules = false;
                  break;
                }
              } else if (rule.condition === '=') {
                if (blockCount !== compareValue) {
                  strictRules = false;
                  break;
                }
              } else if (rule.condition === '<') {
                if (blockCount >= compareValue) {
                  strictRules = false;
                  break;
                }
              } else if (rule.condition === '>') {
                if (blockCount <= compareValue) {
                  strictRules = false;
                  break;
                }
              }
            }
          } else if (rule.compare === 'trigger') {
            const compareValue = toJS(this.root.rule.tr);
            let check = true;

            if (rule.position !== undefined) {
              if (blockIndex !== rule.position) {
                check = false;
              }
            }

            if (check) {
              if (rule.condition === '=') {
                if (compareValue !== rule.value) {
                  strictRules = false;
                  break;
                }
              } else if (rule.condition === '!=') {
                if (compareValue === rule.value) {
                  strictRules = false;
                  break;
                }
              }
            }
          } else if (rule.compare === 'tabs') {
            if (rule.condition === 'isActive') {
              let disable = true;
              let checkDoNot = false;
              let numberOfActiveOperators = 0;
              let doNotOperator = false;

              if (newBlockType.toLowerCase() === 'operator') {
                if (this.validationBlock('operator', blockIndex, 'tabs', 'then')) {
                  disable = false;
                  numberOfActiveOperators++;
                }

                if (this.validationBlock('operator', blockIndex, 'tabs', 'parallel')) {
                  disable = false;
                  numberOfActiveOperators++;
                }

                if (this.validationBlock('operator', blockIndex, 'tabs', 'or')) {
                  disable = false;
                  numberOfActiveOperators++;
                }
                if (
                  (this.sequences[blockIndex - 1] &&
                    this.sequences[blockIndex - 1].type === ACTION_TYPE) ||
                  (this.sequences[blockIndex] && this.sequences[blockIndex].type === ACTION_TYPE)
                ) {
                  checkDoNot = true;
                }

                if (checkDoNot) {
                  if (this.validationBlock('operator', blockIndex, 'tabs', 'do_not')) {
                    disable = false;
                    numberOfActiveOperators++;
                    doNotOperator = true;
                  }
                }

                if (this.validationBlock('operator', blockIndex, 'tabs', 'wait')) {
                  disable = false;
                  numberOfActiveOperators++;
                }

                if (doNotOperatorNotAllowed && doNotOperator && numberOfActiveOperators === 1) {
                  disable = true;
                }
                if (disable) {
                  strictRules = false;
                  break;
                }
              }
            }
          }
        }
      }

      if (rules.mandatory && rules.mandatory.length === 0) {
        mandatoryRules = true;
      }

      for (let i in rules.mandatory) {
        const rule = rules.mandatory[i];
        if (rule.compare === 'element') {
          const checkElementIndex = blockIndex + rule.elementIndex;
          const checkElement = this.sequences[checkElementIndex];
          const type = checkElement && checkElement.type.toLowerCase();
          const typeToCompare = type === 'notify' ? 'action' : type;
          if (checkElement && rule.value === typeToCompare && !rule.subType) {
            mandatoryRules = true;
          }

          if (rule.subType) {
            if (checkElement && checkElement.type === OPERATOR_TYPE) {
              if (rule.subType.indexOf(checkElement.data.operators[0].t) > -1) {
                mandatoryRules = true;
              }
            }
          }
        } else if (rule.compare === 'isBefore') {
        }
      }

      return mandatoryRules && strictRules;
    }

    return false;
  }

  compareCondition(condition, compareValue, value) {
    if (condition === '>') {
      if (compareValue < value) {
        return false;
      }
    } else if (condition === '>=') {
      if (compareValue <= value) {
        return false;
      }
    } else if (condition === '<') {
      if (compareValue > value) {
        return false;
      }
    } else if (condition === '<=') {
      if (compareValue >= value) {
        return false;
      }
    } else if (condition === '=') {
      if (compareValue !== value) {
        return false;
      }
    } else if (condition === '!=') {
      if (compareValue === value) {
        return false;
      }
    }

    return true;
  }

  isThatCoinAllowed(sequenceIndex) {
    // https://gitlab.com/coinrule-v2/project-board/-/issues/3307
    // if the current condition is after a "TradingView signal" condition (on sequence 0) and before THEN/ANY TIME operator
    // we do not show "that coin" unless there was an action already

    if (
      this.sequences[0]?.data.conditions?.[0].ift === SYMBOL_TYPES.SIGNAL &&
      this.sequences[0]?.data.conditions?.[0].ifc === CONDITION_TYPES.TRADINGVIEW_SIGNAL
    ) {
      if (
        sequenceIndex === 1 ||
        (this.sequences[1]?.data.conditions?.[0].ift === SYMBOL_TYPES.COIN &&
          (this.sequences[1]?.data.conditions?.[0].ifc === CONDITION_TYPES.ANY_COIN ||
            this.sequences[1]?.data.conditions?.[0].ifc === CONDITION_TYPES.ANY_MY_COIN))
      ) {
        // if TradingVIew signal and if following condition (at index 1) is either "any coin" or "any of my coins", "that coin" is allowed
        // https://gitlab.com/coinrule-v2/project-board/-/issues/3457
        return true;
      }

      const thenIndex = this.findIndexSequenceBeforeByType(
        OPERATOR_TYPE,
        sequenceIndex,
        OPERATOR_TYPES.THEN
      );
      const parallelIndex = this.findIndexSequenceBeforeByType(
        OPERATOR_TYPE,
        sequenceIndex,
        OPERATOR_TYPES.PARALLEL
      );

      if (thenIndex > -1 || parallelIndex > -1) {
        // after operator, always allow
        return true;
      }

      const waitIndex = this.findIndexSequenceBeforeByType(
        OPERATOR_TYPE,
        sequenceIndex,
        OPERATOR_TYPES.WAIT
      );

      if (waitIndex > -1) {
        // if there is WAIT operator, let's check if there was an action right before
        // if so, we can allow

        const actionBeforeWaitIndex = this.findIndexSequenceBeforeByType(ACTION_TYPE, waitIndex);

        if (actionBeforeWaitIndex > -1) {
          // allow
          return true;
        }
      }

      // do not allow
      return false;
    }

    // no TV signal, no restriction
    return true;
  }

  @action
  findSequenceByIndex = (sequenceIndex) => this.sequences[sequenceIndex]
}
