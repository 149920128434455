import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import rules from '../../assets/images/rules.svg';
import exchanges from '../../assets/images/burger-orange.svg';
import admin from '../../assets/images/admin-yellow.svg';
import settings from '../../assets/images/settings.svg';
import helpCenterBook from '../../assets/images/book-white.svg';
import stores from '../../stores';
import { observer } from 'mobx-react';
import { getDataLocalStorage, setDataLocalStorage } from '../../helpers/utils';

import Tooltip from '../Tooltip';

import './style.scss';
import DeviceStorage from 'react-device-storage';
import { GlobalErrorStore } from '../ErrorBoundary';

let tooltipAdded = false;

const analytics = window.mixpanel;

@observer
class MenuItems extends Component {
  ls = new DeviceStorage({
    cookieFallback: true,
    cookie: {
      secure: window.location.protocol === 'https:',
    },
  }).localStorage();

  prepareItems() {
    const items = [];

    items.push({
      name: 'Rules',
      img: rules,
      to: '/',
      class: 'gsMainMenu__listElement_rules',
      linkClass: 'gsMainMenu__link',
    });

    items.push({
      name: 'Exchanges',
      img: exchanges,
      to: '/exchanges',
      class: 'gsMainMenu__listElement_exchanges',
      linkClass: 'gsMainMenu__link',
    });

    items.push({
      name: 'Settings',
      img: settings,
      to: '/settings',
      class: 'gsMainMenu__listElement_settings',
      linkClass: 'gsMainMenu__link',
    });

    if (stores.user.isAdmin || stores.user.isSupport) {
      items.push({
        name: 'Admin',
        img: admin,
        to: '/admin',
        linkClass: 'gsMainMenu__link',
      });
    }

    return items;
  }

  renderItems() {
    const itemsList = this.prepareItems().map((item, i) => {
      let img = null;
      const cls = ['gsMainMenu__listElement'];

      if (this.props.useImg) {
        img = <img src={item.img} alt={item.name} className={`gsMainMenu__linkIcon`} />;
      }

      if (item.class) {
        cls.push(item.class);
      }

      if (
        item.name.toLowerCase() === 'exchanges' &&
        !tooltipAdded &&
        // && stores.user.connectExPopoverCounter < 1
        !stores.userInfo.hasRealExchange &&
        !getDataLocalStorage('hasSeenExchangeTooltip') &&
        stores.user.onboarding3stepsGuidePassed
      ) {
        tooltipAdded = true;
        setDataLocalStorage('hasSeenExchangeTooltip', true);
        return (
          <li
            key={i}
            className={cls.join(' ')}
            onClick={() => {
              setDataLocalStorage('should_restore_rule', false);
              // this.props.clickHandler();
            }}
          >
            {img}
            <Tooltip
              placement='right'
              content="You haven't connected an exchange yet"
              title='Start Trading Live!'
              autoclose={false}
              autoAdjust={false}
              bemMixinClass='tooltipLeftPanel'
            >
              <NavLink
                to={item.to}
                className={item.linkClass}
                onClick={() => {
                  if (this.props.clickHandler) this.props.clickHandler();
                  GlobalErrorStore.setErrorFalse();
                }}
              >
                {item.name}
              </NavLink>
            </Tooltip>
          </li>
        );
      }

      return (
        <li key={i} className={cls.join(' ')}>
          {img}
          <NavLink
            to={item.to}
            className={item.linkClass}
            onClick={() => {
              if (this.props.clickHandler) this.props.clickHandler();
              GlobalErrorStore.setErrorFalse();
            }}
          >
            {item.name}
          </NavLink>
        </li>
      );
    });

    itemsList.push(this.props.getPlanBut);

    return itemsList;
  }

  renderIcons() {
    if (this.props.noIcons) {
      return this.props.logout;
    }

    return (
      <li className='links'>
        {this.renderSupport()}
        {this.props.logout}
      </li>
    );
  }

  renderSupport() {
    return (
      <a
        href='https://coinrule.com/help/'
        target='_blank'
        rel='noopener noreferrer'
        onClick={() => {
          analytics.track('"Help Center" button clicked', {
            page: 'Sidebar',
          });
        }}
      >
        <img src={helpCenterBook} alt='Help Center' />
      </a>
    );
  }
  render() {
    return (
      <ul className={`gsMainMenu__list`}>
        {this.renderItems()}
        {this.renderIcons()}
      </ul>
    );
  }
}

export default MenuItems;
