import { observable, action, toJS } from 'mobx';
import moment from 'moment';
import DeviceStorage from 'react-device-storage';
import { nanoid } from 'nanoid';

import stores from './index';
import http from '../helpers/http';
import dbg from '../helpers/dbg';
import {
  getFieldCssClass,
  getPeriodName,
  setDataSessionStorage,
  getDataSessionStorage,
  setDataLocalStorage,
} from '../helpers/utils';
import { errorNotification, successNotification } from '../helpers/notification';
import RuleSequences from './ruleSequences';
import Popover from '../components/Popover';
import { Select } from 'antd';
import React from 'react';
import { SEQUENCES } from '../constants/rule';

import Operator from '../pages/AddRulePage/Operators';
import Then from '../pages/AddRulePage/Then';
import { DEFAULT_FIAT } from 'constants/globalCoins';
import Condition from '../pages/AddRulePage/Condition';
import { IRuleEditModes } from 'helpers/rules';
import { STORAGE_ACTIONS } from 'helpers/constants';
import { AbstractStore } from './AbstractStore';
import {
  ADVANCED_ONBOARDING_TEMPLATE_PAYWALL_NAME,
  ADVANCED_TEMPLATES,
} from 'pages/RulesDashboardPage/Onboarding/utils';
import history from 'helpers/history';
import { handleNotifyType } from './ruleSequencesHandlers/handleNotifyType';
import { handleActionType } from './ruleSequencesHandlers/handleActionType';

const analytics = window.mixpanel;
const Decimal = require('decimal.js');
const sha = require('js-sha512').sha512;

export const REPEAT_LIMIT_D = 365; // how many repeats can be set for periods in minutes, hours and days
export const REPEAT_LIMIT_W = 53; // how many repeats can be set for periods in weeks
export const REPEAT_LIMIT_M = 12; // how many repeats can be set for periods in months
export const DEFAULT_TIME_TRIGGER_PERIOD = '5m';
export const DEFAULT_DELTA = '1D';
export const DEFAULT_AMOUNT_SYMBOL = 'USD';
export const PERCENTAGE_SYMBOL = '%';
export const COINS_SYMBOL = '-';
export const DAY = 'D';
export const WEEK = 'W';
export const MONTH = 'M';
const K = '2BKBMaPX9wP7Z6F7ypAP7RQeG7I6EVVUqz8kRlPBruYMLvm35R0QXTt89lWR';
export const MIN_TRADABLE_VALUE = 15; // in base currency
const MULTIPLE_BASE_CURRENCIES_WARNING_TEXT =
  'You have selected more than one Wallet Base Currency. This might prevent your rule from executing if no trading pair is available';

export const NOTIFY_TYPE = 'NOTIFY';
export const ACTION_TYPE = 'ACTION';
export const CONDITION_TYPE = 'CONDITION';
export const OPERATOR_TYPE = 'OPERATOR';

const Option = Select.Option;

export const TRIGGERS = {
  TIME: 'time',
  EVENT: 'event',
  DIRECT_ORDER: 'direct_order',
};

export const DATA_SOURCES = {
  GLOBAL: 'global',
};

export const CONDITION_OPERATORS = {
  AND: 'and',
  OR: 'or',
};

export const SYMBOL_TYPES = {
  COIN: 'coin',
  MARKET: 'market',
  RULE: 'rule',
  SIGNAL: 'signal',
};

export const CONDITION_TYPES = {
  SYMBOL: 'symbol',
  THAT_COIN: 'that_coin',
  ANY_COIN: 'any_coin',
  ANY_MY_COIN: 'any_my_coin',
  FROM_SEQUENCE: 'from_sequence',
  RULE_IN_PROFIT: 'rule_in_profit',
  RULE_IN_LOSS: 'rule_in_loss',
  TRADINGVIEW_SIGNAL: 'tradingview_signal',
  BEST_MARKET: 'best_market',
  WORST_MARKET: 'worst_market',
  BEST_MINE: 'best_mine',
  WORST_MINE: 'worst_mine',
};

export const ACTIONS = {
  TRADE: 'trade',
  NOTIFY: 'notify',
};

export const NOTIFY_CHANNELS = {
  TELEGRAM: 'telegram',
  EMAIL: 'email',
};

export const NOTIFY_TIMEFRAMES = {
  INSTANTLY: 'instantly',
};

export const TRADE_TYPES = {
  BUY: 'buy',
  SELL: 'sell',
};

export const ORDER_TYPES = {
  MARKET: 'market',
  LIMIT: 'limit',
};

export const CALC_TYPES = {
  TOTAL: 'total',
  EACH: 'each',
};

export const RECAL_TYPES = {
  START: 'start',
  EXEC: 'exec',
};

export const OPERATOR_TYPES = {
  THEN: 'then',
  ELSE: 'else',
  DO_NOT: 'do_not',
  WAIT: 'wait',
  ALL: 'all',
  OR: 'or',
  PARALLEL: 'parallel',
};

export const BLOCK_TYPES = {
  OPERATOR: 'operator',
  CONDITION: 'condition',
  ACTION: 'action',
};

export const EMPTY = 'empty';

export const WAIT_OPTIONS = {
  ONCE: 'once',
  FOR: 'for',
};

/*export const INTERSECTION_TYPES = {
 LIMIT: 'limit'
 };*/

const isEmptyValue = (value) => {
  return value === '---';
};

export const defaultRule = {
  rule_id: undefined,
  edit_mode: undefined,
  ex: undefined,
  de: undefined,
  nam: '',
  rpt: {
    l: null,
    t: 'total',
    nmt: DEFAULT_TIME_TRIGGER_PERIOD,
    let: null,
  },
  s: 'draft',
  sd: null,
  ed: null,
  si: true,
  tr: TRIGGERS.EVENT,
  tim: 'every',
  pe: DEFAULT_TIME_TRIGGER_PERIOD,
  tid: null,
  tne: true,
  tvhid: '',
  mop: '',
  wt: WAIT_OPTIONS.FOR,
};

export const defaultCondition = {
  co: CONDITION_OPERATORS.AND,
  ift: SYMBOL_TYPES.COIN,
  ifc: 'any_coin',
  sr: null,
  ifs: [],
  rsi_v: null,
  in: '---',
  op: '---',
  ma: '---',
  map: '---',
  a: {
    v: null,
    iv: null,
    s: DEFAULT_AMOUNT_SYMBOL,
  },
  wi: 'current_price',
  d: '',
  sp: '',
};

export const defaultAction = {
  do: ACTIONS.TRADE,
  at: TRADE_TYPES.BUY,
  ot: ORDER_TYPES.MARKET,
  ort: 'that_coin',
  sr: null,
  b: [],
  q: '---',
  v: {
    v: null,
    iv: null,
    s: DEFAULT_AMOUNT_SYMBOL,
  },
};

export const defaultNotify = {
  do: ACTIONS.NOTIFY,
  channel: NOTIFY_CHANNELS.TELEGRAM,
  timeframe: NOTIFY_TIMEFRAMES.INSTANTLY,
};

export const defaultOperator = {
  t: '',
  dn: [],
  pe: DEFAULT_TIME_TRIGGER_PERIOD,
  wt: WAIT_OPTIONS.FOR,
};

export const defaultOperatorThen = {
  type: OPERATOR_TYPES.THEN,
  data: {},
};

export default class AddRuleStore extends AbstractStore {
  constructor(rootStore) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  @observable seqInit = new RuleSequences(this);
  @observable rule = defaultRule;
  @observable dataStudioVisible = true;
  @observable details;
  @observable backupUsed = false;
  @observable editMode = false;
  @observable cloneMode = false;
  @observable isSending = false;
  @observable editedRuleId;
  @observable actionWasEdited = false;
  @observable guideStage = 0;
  @observable tooltips = [];
  @observable paymentGatePromoVisible = false;
  @observable paymentGateTradingVolume90Visible = false;
  @observable paymentGateTradingVolume100Visible = false;
  @observable openedAddSectionBlock = '';
  @observable showMoreThenCheck = false;
  @observable showEditText = false;
  @observable showPaymentGateForAdvancedOnboardingTemplate = false;
  addRuleParent = null;
  backupSellActionObject = [];
  backupBuyActionObject = [];

  // FIXME: For some reason tempCurrency is always baseCurrency
  // the seem to be a state update happening somewhere
  @observable tempCurrency = stores?.user?.user?.user?.baseCurrency || DEFAULT_FIAT;
  @observable instrumentQuote = '';
  @observable warningTexts = [];
  @observable showCreateTemplateModal = false;
  @observable paymentGateVisible = false;
  @observable showPlansBanner = false;
  @observable usingTradingViewSignal = {};
  @observable disableListOfLeverageInstrumentsAfterIndex = {};

  // ===========================================================
  // Rule Sequence Floating Panel (SFP)
  @observable isSequenceFloatingPanelOpened = false;
  // Show preloader for sections which user edit at the moment
  @observable sfpPendingSectionId = false;
  @observable isSFPexecuteSectionDisabled = true;

  @observable selectsProps = {};

  /*****
   * For showing the preview before launching rule.
   * This is now global because it's also being used in <AddExchangesModal />
   */
  @observable showRuleLaunchPreview = false;

  @observable appliedOnboardingTemplate = null;

  @action setAppliedOnboardingTemplate = (template) => {
    this.appliedOnboardingTemplate = template;
  };

  isAppliedOnboardingTemplateAdvanced = () => {
    return ADVANCED_TEMPLATES.map((name) => name.toLocaleLowerCase()).includes(
      this.appliedOnboardingTemplate?.templateName?.toLocaleLowerCase?.()
    );
  };

  @action
  setShowRuleLaunchPreview = (isVisible) => (this.showRuleLaunchPreview = isVisible);

  getTradingViewHookId() {
    if (!this.rule.tvhid) {
      this.setNewTradingViewHookId();
    }

    return this.rule.tvhid;
  }

  handleLaunchLive = ({
    event,
    callback,
    exchangeIdForLaunch,
    unblockBlockedPage,
    storeInstance = this,
  }) => {
    /* Checking "launchLiveWasClicked" value is needed to know is user already created his first rule or not.
    According to that we will show "checkbox" on 1st step ("Create a rule" step) of the "3 steps guide" */

    setDataLocalStorage('launchLiveWasClicked', true);

    event && event.preventDefault();

    setDataLocalStorage('should_restore_rule', false);

    callback && callback();

    storeInstance.createRule(unblockBlockedPage, false, exchangeIdForLaunch).then((authorized) => {
      stores.app.unauthorized(authorized);

      if (authorized) {
        if (storeInstance.rule.edit_mode === IRuleEditModes.QuickEdit) {
          history.push(`/rule/${storeInstance.rule.rule_id}`);
        } else {
          history.push('/');
        }

        storeInstance.resetRule();
      }
    });

    analytics.track('"Launch Rule" confirmed');
  };
  @action
  setNewTradingViewHookId() {
    this.rule.tvhid = nanoid(24);
  }

  hasReachedRuleLimit = (ruleStore) => {
    const user = stores.user.getUser();

    const canCreateDemoRule = !(stores.userInfo.activeDemoRules >= user.plan.maxDemoRules);
    const canCreateLiveRule = !(stores.userInfo.activeLiveRules >= user.plan.maxRules);
    const isDemoExchange = stores.info.demoExchangesIDs.includes(ruleStore.rule.ex);

    const reachedLimit =
      (((!canCreateLiveRule && !isDemoExchange) || (!canCreateDemoRule && isDemoExchange)) &&
        (!(ruleStore.editMode && ruleStore.editedRuleId) ||
          stores.userRules.lastOpenRuleStatus === 'draft')) ||
      stores.user.user.plan.uid === 'paused';

    return reachedLimit;
  };

  @action
  setSelectsProps = ({ sequenceIndex, index, updates = {} }) => {
    const sequenceObject = this.selectsProps[this.rule.tr + sequenceIndex];
    const indexObject = {
      ...(sequenceObject && sequenceObject[index] && sequenceObject[index]),
      ...updates,
    };

    this.selectsProps[this.rule.tr + sequenceIndex] = {
      ...sequenceObject,
      [index]: indexObject,
    };
  };

  @action setTempCurrency = (currency) => {
    this.tempCurrency = currency;
  };

  @action switchSequenceFloatingPanel = (whatToDo) => {
    if (whatToDo === 'close') this.isSequenceFloatingPanelOpened = false;
    else if (whatToDo === 'open') this.isSequenceFloatingPanelOpened = true;
    this.dataStudioVisible = !this.isSequenceFloatingPanelOpened;
  };

  @action setSFPpendingSection = (sectionId) => {
    this.sfpPendingSectionId = sectionId;

    if (sectionId !== null) {
      this.setSFPpendingSectionTimeout(sectionId);
    }
  };

  @action
  setRuleWithTemplate = (template, exchangeId) => {
    this.rule = template.params;
    this.rule.ex = exchangeId;
    this.setTemplateId(template.templateId);
    this.setTemplateName(template.templateName);
    stores.templatesStore.setIsSelect(true);

    this.rule.tr = template.params.tr;
    this.rule.tim = template.params.tim;
    this.rule.pe = template.params.pe;
    this.rule.tid = template.params.tid;
    this.rule.si = defaultRule.si;
    this.rule.rpt = template.params.rpt;

    this.seqInit.initEdit(this, this, template.params.sq);

    this.baseCurrencyChanged(true);

    this.updateTradingViewSignalUsage();
  };

  // ===========================================================
  // Check is user already interacted with launch section or not
  @observable isSFPlaunchSectionWasClicked = false;
  @action changeIsLaunchSectionWasClickedStatus = () => {
    this.isSFPlaunchSectionWasClicked = true;
  };
  // ===========================================================

  sfpPendingSectionTimeout;

  @action
  setShowCreateTemplateModal(newValue) {
    this.showCreateTemplateModal = newValue;
  }

  setSFPpendingSectionTimeout = (sectionId) => {
    if (this.sfpPendingSectionTimeout) {
      clearTimeout(this.sfpPendingSectionTimeout);
    }

    this.sfpPendingSectionTimeout = setTimeout(() => {
      this.setSFPpendingSection(null);
    }, 2300 * Math.random());
  };

  @action setSFPexecuteSectionStatus = (status) => {
    if (status === 'disabled') this.isSFPexecuteSectionDisabled = true;
    else if (status === 'enabled') this.isSFPexecuteSectionDisabled = false;
  };
  // ===========================================================

  @action switchAddSectionBlock(blockId) {
    if (this.openedAddSectionBlock === blockId) {
      this.openedAddSectionBlock = void 0;
      return;
    }
    this.openedAddSectionBlock = blockId;
  }

  static bkp(data) {
    const json = JSON.stringify(data);
    const ts = Math.floor(Math.random() * 10e16);
    const hash = sha(ts + json + K);

    return hash + '.' + ts + '.' + json;
  }

  static dbkp(data) {
    if (!data) {
      return null;
    }

    data = data.toString();
    const point1 = data.indexOf('.');
    const point2 = data.indexOf('.', point1 + 1);

    if (point1 > 0 && point2 > 0) {
      const hash = data.substring(0, point1);
      const ts = data.substring(point1 + 1, point2);
      const json = data.substring(point2 + 1, data.length);

      if (sha(ts + json + K) === hash) {
        return JSON.parse(json);
      }
    }

    return null;
  }

  getDefaultConditions() {
    if (defaultRule.tr === TRIGGERS.EVENT) {
      return [
        {
          t: SEQUENCES.CONDITION,
          d: {
            ...defaultCondition,
            a: {
              v: '',
              s: this.tempCurrency,
            },
          },
        },
      ];
    } else {
      return [];
    }
  }

  useStorage(key, action = STORAGE_ACTIONS.READ, data) {
    try {
      const ls = new DeviceStorage({
        cookieFallback: false, // set to false as the default value could be true
        cookie: {
          secure: window.location.protocol === 'https:',
        },
      }).localStorage();

      if (action === STORAGE_ACTIONS.SAVE) {
        return ls[action](key, data);
      }

      return ls[action](key);
    } catch (error) {}
  }

  isBackup() {
    return this.useStorage('backup_exists');
  }

  useDefaultRule() {
    const data = this.useStorage('backup_rule');
    const backup = AddRuleStore.dbkp(data);

    if (backup) {
      if (backup.sd) {
        backup.sd = moment(backup.sd);
      }

      if (backup.ed) {
        backup.ed = moment(backup.ed);
      }

      return backup;
    }

    return defaultRule;
  }

  useDefaultConditions() {
    const backup = AddRuleStore.dbkp(this.useStorage('backup_sequences'));

    if (backup) {
      return backup.filter((seq) => seq.t === SEQUENCES.CONDITION);
    }

    return this.getDefaultConditions();
  }

  useDefaultActions() {
    const backup = AddRuleStore.dbkp(this.useStorage('backup_sequences'));

    if (backup) {
      return backup.filter((seq) => seq.t === SEQUENCES.ACTION);
    }

    return [
      {
        t: SEQUENCES.ACTION,
        d: {
          ...defaultAction,
          a: {
            v: '',
            s: this.tempCurrency,
          },
        },
      },
    ];
  }

  isCurrentTooltip(index) {
    return this.guideStage === index;
  }

  @action
  addTooltip(tooltip) {
    this.tooltips.push(tooltip);
  }

  getTooltipIndex() {
    return this.tooltips.length;
  }

  @action
  resetTooltips() {
    this.tooltips = [];
  }

  @action
  increaseGuideStage() {
    this.guideStage++;
  }

  @action
  decreaseGuideStage() {
    this.guideStage--;
  }

  @action
  resetStages() {
    this.guideStage = 0;
  }

  @action
  resetRule() {
    this.rule = defaultRule;
    this.seqInit.sequences = [];
    this.usingTradingViewSignal = {};
    this.disableListOfLeverageInstrumentsAfterIndex = {};
    this.seqInit.addToSequence(null, CONDITION_TYPE);
  }

  @action
  backup() {
    if (this.rule.ex && !this.editMode) {
      this.useStorage('backup_exists', STORAGE_ACTIONS.SAVE, true);
      this.useStorage('backup_rule', STORAGE_ACTIONS.SAVE, AddRuleStore.bkp(this.rule));
      this.useStorage(
        'backup_sequences',
        STORAGE_ACTIONS.SAVE,
        AddRuleStore.bkp(this.seqInit.sequences.map((seq) => seq.data))
      );

      this.backupUsed = true;
    }
  }

  @action
  setAddRuleParent(parent) {
    this.addRuleParent = parent;
  }

  removeBackup() {
    this.useStorage('backup_exists', STORAGE_ACTIONS.DELETE);
    this.useStorage('backup_rule', STORAGE_ACTIONS.DELETE);
    this.useStorage('backup_sequences', STORAGE_ACTIONS.DELETE);
    this.useStorage('backup_sequences_snapshots', STORAGE_ACTIONS.DELETE);
  }

  getDefaultSequences() {
    const backups = AddRuleStore.dbkp(this.useStorage('backup_sequences'));

    if (backups.length > 0) {
      this.seqInit.idCount = -1;
      return backups.map((backup, i) => {
        this.seqInit.idCount++;
        let component, type;
        let includeBlock = '';

        if (backup.hasOwnProperty('conditions')) {
          if (backups[i - 1]) {
            if (
              backups[i - 1].hasOwnProperty('operators') &&
              [OPERATOR_TYPES.THEN, OPERATOR_TYPES.PARALLEL, OPERATOR_TYPES.OR].indexOf(
                backups[i - 1].operators[0].t
              ) > -1
            ) {
              includeBlock = OPERATOR_TYPE;
            } else if (backups[i - 1].hasOwnProperty('conditions')) {
              includeBlock = backups[i - 1].includeBlock || CONDITION_TYPE;
            }
          }
          type = CONDITION_TYPE;
          component = (
            <Condition
              key={i}
              store={this}
              parent={this.addRuleParent}
              index={i}
              id={this.seqInit.idCount}
              partIndex={0}
            />
          );
        } else if (backup.hasOwnProperty('actions')) {
          type = ACTION_TYPE;
          component = (
            <Then
              key={i}
              store={this}
              parent={this.addRuleParent}
              index={i}
              id={this.seqInit.idCount}
              partIndex={0}
            />
          );
        } else if (backup.hasOwnProperty('operators')) {
          type = OPERATOR_TYPE;
          component = (
            <Operator
              key={i}
              store={this}
              parent={this.addRuleParent}
              index={i}
              id={this.seqInit.idCount}
              partIndex={0}
            />
          );
        }

        return {
          id: this.seqInit.idCount,
          errors: [],
          includeBlock: includeBlock,
          type,
          component,
          data: backup,
        };
      });
    }

    return [...this.useDefaultConditions(), ...this.useDefaultActions()];
  }

  @action
  applyBackup() {
    this.seqInit.sequences = this.getDefaultSequences();
    this.rule = this.useDefaultRule();
    this.backupUsed = true;
  }

  @action
  setEditedRule(rule, details) {
    this.resetStages();
    this.rule = rule;
    this.rule.sd = this.rule.sd ? moment(this.rule.sd) : moment();
    this.rule.ed = this.rule.ed ? moment(this.rule.ed) : null;
    this.rule.rpt = rule.rpt;

    this.seqInit.sequences = rule.sq;
    this.details = details;
    this.editMode = true;
  }

  getClosestMATimeframe(periods, originalValue) {
    const splitValue = originalValue.split(/(\d+)/).filter(Boolean);
    const [num, letter] = splitValue;

    const samePeriods = periods
      .filter((p) => p.split(/(\d+)/).filter(Boolean)[1] === letter)
      .map((p) => p.split(/(\d+)/).filter(Boolean)[0]);

    const closestNumber = samePeriods.reduce(function (prev, curr) {
      return Math.abs(curr - num) < Math.abs(prev - num) ? curr : prev;
    });

    const closestPeriod = periods.find(
      (p) =>
        p.split(/(\d+)/).filter(Boolean)[0] === closestNumber &&
        p.split(/(\d+)/).filter(Boolean)[1] === letter
    );

    return closestPeriod;
  }

  @action
  async changeExchange(exchangeId, newCurrency = '') {
    const latestExchangeId = this.rule.ex;
    this.rule.ex = exchangeId;

    if (exchangeId && stores.info.exchanges?.length) {
      const promises = [];

      promises.push(stores.userInfo.getBalances(exchangeId, true, newCurrency));
      promises.push(stores.exchangeInfo.getAssets(exchangeId, true, newCurrency));

      const exchange = stores.info.exchanges.find((ex) => ex.id === exchangeId);
      this.rule.tne = true;

      if (
        [
          ...((stores.info?.MAExchanges?.length && stores.info?.MAExchanges) || []),
          ...((stores.info?.RSIExchanges?.length && stores.info?.RSIExchanges) || []),
        ].includes(exchange?.uid)
      ) {
        // eslint-disable-next-line no-unused-expressions
        this.seqInit.sequences?.forEach((sq, i) => {
          if (sq.data?.conditions) {
            const selectedTimeframe = sq.data.conditions[0]?.map;

            if (
              selectedTimeframe &&
              selectedTimeframe !== '---' &&
              !exchange.ma_periods.includes(selectedTimeframe)
            ) {
              // selected period does not exist on exchange
              this.changeMADelta(
                0,
                this.getClosestMATimeframe(exchange.ma_periods, selectedTimeframe),
                i
              );
            }
          }
        });
      }
      await Promise.all(
        promises.map((p) => p.then((authorized) => stores.app.unauthorized(authorized)))
      );

      this.checkSymbolsOnExchange(latestExchangeId);
    }
  }

  @action
  checkSymbolsOnExchange(latestExchangeId) {
    let changed = false;
    const typeDiffers =
      stores.exchangeInfo.exchangeType[this.rule.ex] !==
      stores.exchangeInfo.exchangeType[latestExchangeId];

    if (!typeDiffers) {
      return;
    }

    for (const i in this.seqInit.sequences) {
      const sequence = this.seqInit.sequences[i];

      if (sequence.type === CONDITION_TYPE) {
        const condition = sequence.data.conditions[0];

        if (condition?.ifc === 'symbol') {
          this.seqInit.sequences[i].data.conditions[0].ifc = '---';
          this.seqInit.sequences[i].data.conditions[0].ifs = [];
          changed = true;
        }
      } else if (sequence.type === ACTION_TYPE) {
        const action = sequence.data.actions[0];

        if (action?.ort === 'symbol') {
          this.seqInit.sequences[i].data.actions[0].ort = 'base';
          this.seqInit.sequences[i].data.actions[0].b = ['---'];
          changed = true;
        }
      }
    }

    if (changed) {
      this.backup();
    }
  }

  @action
  checkOrderTypesOfExistingActions() {
    for (const i in this.seqInit.sequences) {
      if (this.seqInit.sequences[i].type === ACTION_TYPE) {
        this.changeActionOrderType(
          0,
          stores.info.checkOrderTypeOnExchange(this.seqInit.sequences[i].data.actions[0].ot),
          i
        );
      }
    }
  }

  @action
  async baseCurrencyChanged(onlyRerender = false, newCurrency = null) {
    if (newCurrency) {
      this.tempCurrency = newCurrency;

      if (!onlyRerender) {
        if (this.rule.ex) {
          await stores.userInfo.getBalances(this.rule.ex, true, newCurrency);
          await stores.exchangeInfo.getAssets(this.rule.ex, true, newCurrency);
        }

        stores.info.getValueAssets();
      }
    } else {
      this.tempCurrency = stores.user.user.user.baseCurrency;
    }

    for (let i in this.seqInit.sequences) {
      if (
        this.seqInit.sequences[i].type === CONDITION_TYPE &&
        this.seqInit.sequences[i].data.conditions[0].a.s !== PERCENTAGE_SYMBOL &&
        this.seqInit.sequences[i].data.conditions[0].a.s !== COINS_SYMBOL
      ) {
        this.changeConditionAmountSymbol(0, this.tempCurrency, i);
      } else if (
        this.seqInit.sequences[i].type === ACTION_TYPE &&
        this.seqInit.sequences[i].data.actions[0].v.s !== PERCENTAGE_SYMBOL &&
        this.seqInit.sequences[i].data.actions[0].v.s !== COINS_SYMBOL
      ) {
        this.changeActionAmountSymbol(0, this.tempCurrency, i);

        /*
        removed because of https://gitlab.com/coinrule-v2/project-board/-/issues/1924
        const quoteCoins = stores.info.lastSelectedExchange.quote_coins
          ?.map((coin) => {
            const assets = toJS(stores.exchangeInfo.assets[this.rule.ex]);
            const fiats = toJS(stores.exchangeInfo.fiats[this.rule.ex]);

            if (assets && fiats && (assets[coin] || fiats[coin])) {
              return coin;
            }
            return undefined;
          })
          .filter((element) => element !== undefined);

        if (quoteCoins && quoteCoins.includes(this.tempCurrency)) {
          this.changeActionQuote(0, this.tempCurrency, i);
        }
        */
      }
    }

    this.changeSymbolInSequencesSnapshots(this.tempCurrency);
  }

  changeSymbolInSequencesSnapshots() {
    for (let j in this.seqInit.sequenceSnapshots) {
      if (this.seqInit.sequenceSnapshots[j].length > 0) {
        for (let i in this.seqInit.sequenceSnapshots[j]) {
          if (
            this.seqInit.sequenceSnapshots[j][i].type === CONDITION_TYPE &&
            this.seqInit.sequenceSnapshots[j][i].data.conditions[0].a.s !== PERCENTAGE_SYMBOL &&
            this.seqInit.sequenceSnapshots[j][i].data.conditions[0].a.s !== COINS_SYMBOL
          ) {
            this.changeConditionAmountSymbol(
              0,
              this.tempCurrency,
              i,
              this.seqInit.sequenceSnapshots[j]
            );
          } else if (
            this.seqInit.sequenceSnapshots[j][i].type === ACTION_TYPE &&
            this.seqInit.sequenceSnapshots[j][i].data.actions[0].v.s !== PERCENTAGE_SYMBOL &&
            this.seqInit.sequenceSnapshots[j][i].data.actions[0].v.s !== COINS_SYMBOL
          ) {
            this.changeActionAmountSymbol(
              0,
              this.tempCurrency,
              i,
              this.seqInit.sequenceSnapshots[j]
            );
          }
        }
      }
    }
  }

  @action
  setTemplateName(templateName) {
    this.rule.templateName = templateName;
  }

  @action
  setTemplateId(templateId) {
    this.rule.tid = templateId;
  }

  @action
  resetTemplate() {
    this.rule.tid = null;
  }

  @action
  setTrigger(trigger) {
    if (
      trigger === TRIGGERS.TIME ||
      trigger === TRIGGERS.EVENT ||
      trigger === TRIGGERS.DIRECT_ORDER
    ) {
      this.conditions = [];
      this.rule.tr = trigger;
      this.rule.pe = DEFAULT_TIME_TRIGGER_PERIOD;

      if (trigger === TRIGGERS.DIRECT_ORDER) {
        this.rule.rpt.l = 1;
      }

      this.seqInit.errors = {};
      this.actionWasEdited = false;
      this.backup();
    }
  }

  @action
  setTimePeriod(period) {
    this.rule.pe = period;
    this.backup();
  }

  @action
  setTimeRepeatPeriod(period) {
    this.rule.rpt.nmt = period;
    this.backup();
  }

  @action
  changeConditionOrand(index, value, conditionIndex) {
    if (this.seqInit.sequences[conditionIndex].data.conditions[index]) {
      this.seqInit.sequences[conditionIndex].data.conditions[index].co = value;

      if (value === CONDITION_OPERATORS.OR) {
        const operatorIndex = this.seqInit.findIndexSequenceBeforeByType(
          OPERATOR_TYPE,
          conditionIndex
        );

        if (operatorIndex < 0) {
          this.seqInit.sequences[conditionIndex].data.conditions[index].ifc = '---';
          this.seqInit.sequences[conditionIndex].data.conditions[index].ifs = [];
        }
      }

      this.backup();
    }
  }

  @action
  resetNextActionEQT(actionIndex) {
    for (const i in this.seqInit.sequences[actionIndex].data.actions) {
      this.seqInit.sequences[actionIndex].data.actions[i].eqt = null;
      this.backup();
    }
  }

  @action
  changeConditionSymbols({
    index,
    symbols,
    conditionIndex,
    isLeverageExchange,
    subsequentCall = false,
  }) {
    let newSymbol = '---';
    const previousIndicator =
      this.seqInit.sequences[conditionIndex]?.data.conditions?.[index]?.in || '---';
    const newIndicator =
      previousIndicator === TRADE_TYPES.BUY || previousIndicator === TRADE_TYPES.SELL
        ? '---'
        : previousIndicator;
    const isSymbolsArray = Array.isArray(symbols);
    const firstSymbol = isSymbolsArray ? symbols[0] : symbols;

    if (this.seqInit.sequences[conditionIndex]?.data.conditions?.[index]) {
      if (
        firstSymbol === '---' ||
        firstSymbol === 'any_coin' ||
        firstSymbol === 'any_my_coin' ||
        firstSymbol === 'best_market' ||
        firstSymbol === 'worst_market' ||
        firstSymbol === 'best_mine' ||
        firstSymbol === 'worst_mine'
      ) {
        this.seqInit.sequences[conditionIndex].data.conditions[index].ift = SYMBOL_TYPES.COIN;
        this.seqInit.sequences[conditionIndex].data.conditions[index].ifc = firstSymbol;
        this.seqInit.sequences[conditionIndex].data.conditions[index].sr = null;
        this.seqInit.sequences[conditionIndex].data.conditions[index].in = newIndicator;
        this.seqInit.sequences[conditionIndex].data.conditions[index].wi = 'current_price';
        this.seqInit.sequences[conditionIndex].data.conditions[index].ifs = [];

        newSymbol = firstSymbol;

        // if (['inc','dec'].indexOf(this.seqInit.sequences[conditionIndex].data.conditions[index].op) === -1) {
        //   this.seqInit.sequences[conditionIndex].data.conditions[index].wi = 'current_price';
        //   this.seqInit.sequences[conditionIndex].data.conditions[index].d = '';
        // }
      } else if (
        symbols === CONDITION_TYPES.RULE_IN_PROFIT ||
        symbols === CONDITION_TYPES.RULE_IN_LOSS ||
        (isSymbolsArray &&
          (symbols.includes(CONDITION_TYPES.RULE_IN_PROFIT) ||
            symbols.includes(CONDITION_TYPES.RULE_IN_LOSS)))
      ) {
        const inProfit =
          symbols === CONDITION_TYPES.RULE_IN_PROFIT ||
          (isSymbolsArray && symbols.includes(CONDITION_TYPES.RULE_IN_PROFIT));

        this.seqInit.sequences[conditionIndex].data.conditions[index].ift = SYMBOL_TYPES.RULE;
        this.seqInit.sequences[conditionIndex].data.conditions[index].ifc = inProfit
          ? CONDITION_TYPES.RULE_IN_PROFIT
          : CONDITION_TYPES.RULE_IN_LOSS;
        this.seqInit.sequences[conditionIndex].data.conditions[index].ifs = [];
        this.seqInit.sequences[conditionIndex].data.conditions[index].sr = null;
        this.seqInit.sequences[conditionIndex].data.conditions[index].in = '---';
        this.seqInit.sequences[conditionIndex].data.conditions[index].op = '---';
        this.seqInit.sequences[conditionIndex].data.conditions[index].maci = '---';
        newSymbol = inProfit ? CONDITION_TYPES.RULE_IN_PROFIT : CONDITION_TYPES.RULE_IN_LOSS;
      } else if (
        symbols === CONDITION_TYPES.TRADINGVIEW_SIGNAL ||
        (isSymbolsArray && symbols.includes(CONDITION_TYPES.TRADINGVIEW_SIGNAL))
      ) {
        this.seqInit.sequences[conditionIndex].data.conditions[index].ift = SYMBOL_TYPES.SIGNAL;
        this.seqInit.sequences[conditionIndex].data.conditions[index].ifc =
          CONDITION_TYPES.TRADINGVIEW_SIGNAL;
        this.seqInit.sequences[conditionIndex].data.conditions[index].ifs = [];
        this.seqInit.sequences[conditionIndex].data.conditions[index].sr = null;
        this.seqInit.sequences[conditionIndex].data.conditions[index].in = '---';
        this.seqInit.sequences[conditionIndex].data.conditions[index].op = '---';
        this.seqInit.sequences[conditionIndex].data.conditions[index].maci = '---';
        newSymbol = CONDITION_TYPES.TRADINGVIEW_SIGNAL;

        if (index === 0 && conditionIndex === 0) {
          if (this.seqInit.sequences[1]?.data.conditions?.[index]?.ifc === 'that_coin') {
            this.changeConditionSymbols({
              index,
              symbols: '---',
              conditionIndex: 1,
              isLeverageExchange,
              subsequentCall: true,
            }); // next condition
            this.changeConditionOrand(index, CONDITION_OPERATORS.AND, 1);
          } else if (
            this.seqInit.sequences[1]?.data.operators?.[index]?.t === 'wait' &&
            this.seqInit.sequences[2]?.data.conditions?.[index]?.ifc === 'that_coin'
          ) {
            this.changeConditionSymbols({
              index,
              symbols: '---',
              conditionIndex: 2,
              isLeverageExchange,
              subsequentCall: true,
            }); // condition after WAIT
          }
        }
      } else if (symbols.indexOf('from_sequence') > -1) {
        let splitSequenceString = symbols.split('_');
        this.seqInit.sequences[conditionIndex].data.conditions[index].ift = SYMBOL_TYPES.COIN;
        this.seqInit.sequences[conditionIndex].data.conditions[index].ifc = 'from_sequence';
        this.seqInit.sequences[conditionIndex].data.conditions[index].sr = Number(
          splitSequenceString[2]
        );
        this.seqInit.sequences[conditionIndex].data.conditions[index].in = newIndicator;
        this.seqInit.sequences[conditionIndex].data.conditions[index].ifs = [];
        newSymbol = symbols;

        if (
          ['inc', 'dec'].indexOf(this.seqInit.sequences[conditionIndex].data.conditions[index].op) >
          -1
        ) {
          this.seqInit.sequences[conditionIndex].data.conditions[index].wi = 'action_price';
          this.seqInit.sequences[conditionIndex].data.conditions[index].d = '';
        }
      } else if (symbols === 'that_coin' || (isSymbolsArray && symbols.includes('that_coin'))) {
        this.seqInit.sequences[conditionIndex].data.conditions[index].ift = SYMBOL_TYPES.COIN;
        this.seqInit.sequences[conditionIndex].data.conditions[index].wi = 'action_price';
        this.seqInit.sequences[conditionIndex].data.conditions[index].ifc = 'that_coin';
        this.seqInit.sequences[conditionIndex].data.conditions[index].d = '';
        this.seqInit.sequences[conditionIndex].data.conditions[index].ifs = [];
        this.seqInit.sequences[conditionIndex].data.conditions[index].in = newIndicator;

        newSymbol = 'that_coin';
      } else {
        this.seqInit.sequences[conditionIndex].data.conditions[index].ift = SYMBOL_TYPES.COIN;
        this.seqInit.sequences[conditionIndex].data.conditions[index].ifc = 'symbol';
        this.seqInit.sequences[conditionIndex].data.conditions[index].sr = null;
        this.seqInit.sequences[conditionIndex].data.conditions[index].in = newIndicator;

        if (
          ['inc', 'dec'].indexOf(
            this.seqInit.sequences[conditionIndex].data.conditions[index].op
          ) === -1 ||
          ['current_price', 'delta'].indexOf(
            this.seqInit.sequences[conditionIndex].data.conditions[index].wi
          ) === -1
        ) {
          this.seqInit.sequences[conditionIndex].data.conditions[index].wi = 'current_price';
          this.seqInit.sequences[conditionIndex].data.conditions[index].d = '';
        }
        this.seqInit.sequences[conditionIndex].data.conditions[index].ifs = isSymbolsArray
          ? symbols
          : [symbols];

        newSymbol = symbols;
      }
    }

    const followingActions = this.seqInit.findAllSequenceByTypeAfter(ACTION_TYPE, conditionIndex);

    if (followingActions.length > 0) {
      if (
        newSymbol === CONDITION_TYPES.TRADINGVIEW_SIGNAL ||
        newSymbol === CONDITION_TYPES.RULE_IN_PROFIT ||
        newSymbol === CONDITION_TYPES.RULE_IN_LOSS
      ) {
        followingActions.forEach((followingAction) => {
          if (this.seqInit.sequences[followingAction]?.data.actions?.[0]?.ort === 'that_coin') {
            this.seqInit.sequences[followingAction].data.actions[0].b = ['---'];
            this.seqInit.sequences[followingAction].data.actions[0].ort = 'base';
          }
        });
      } else {
        followingActions.forEach((followingAction) => {
          if (
            this.seqInit.sequences[followingAction]?.data.actions?.[0]?.ort.indexOf(
              'from_sequence'
            ) > -1 ||
            this.seqInit.sequences[followingAction]?.data.actions?.[0]?.b[0] === '---'
          ) {
            this.seqInit.sequences[followingAction].data.actions[0].b = [];
            this.seqInit.sequences[followingAction].data.actions[0].ort = 'that_coin';

            if (
              this.seqInit.sequences[conditionIndex].data.conditions[index].ifc === 'that_coin' ||
              this.seqInit.sequences[conditionIndex].data.conditions[index].sr > -1
            ) {
              this.seqInit.sequences[followingAction].data.actions[0].eqt = 'amount';
            } else {
              this.seqInit.sequences[followingAction].data.actions[0].eqt = null;
            }
          }
        });
      }
    }

    if (isLeverageExchange) {
      if (
        this.seqInit.sequences[conditionIndex].data.conditions[index].ift === SYMBOL_TYPES.COIN &&
        this.seqInit.sequences[conditionIndex].data.conditions[index].ifc === 'symbol'
      ) {
        const orIndex = this.seqInit.findIndexSequenceBeforeByType(
          OPERATOR_TYPE,
          conditionIndex,
          OPERATOR_TYPES.OR
        );

        if (orIndex < 0) {
          // if there is no OR operator before
          this.updateLeverageSymbols(newSymbol);
          this.updateDisableListOfLeverageInstrumentsAfterIndex(conditionIndex);
        }
      } else if (
        conditionIndex === this.getDisableListOfLeverageInstrumentsAfterIndex(this.rule.tr)
      ) {
        const increment = this.seqInit.sequences[conditionIndex + 1]?.data.operators ? 2 : 1;
        this.updateDisableListOfLeverageInstrumentsAfterIndex(conditionIndex + increment);
      }
    }

    if (!subsequentCall) {
      this.seqInit.checkIfStillError(conditionIndex, 'ifc', index);
      this.backup();
      this.updateTradingViewSignalUsage();
    }
  }

  getDisableListOfLeverageInstrumentsAfterIndex() {
    if (this.disableListOfLeverageInstrumentsAfterIndex[this.rule.tr] === undefined) {
      if (
        this.seqInit.sequences[0]?.data.conditions?.[0].ifc === CONDITION_TYPES.TRADINGVIEW_SIGNAL
      ) {
        // https://gitlab.com/coinrule-v2/project-board/-/issues/3192
        if (this.seqInit.sequences[1]?.data.operators?.[0].t === OPERATOR_TYPES.WAIT) {
          return 2;
        }

        return 1;
      }

      return 0;
    }

    return this.disableListOfLeverageInstrumentsAfterIndex[this.rule.tr];
  }

  @action
  updateDisableListOfLeverageInstrumentsAfterIndex(index) {
    this.disableListOfLeverageInstrumentsAfterIndex[this.rule.tr] = index;
  }

  @action
  sequenceRemoved(index) {
    const cachedIndex = this.disableListOfLeverageInstrumentsAfterIndex[this.rule.tr];

    if (cachedIndex !== undefined && cachedIndex === index) {
      this.disableListOfLeverageInstrumentsAfterIndex[this.rule.tr] = undefined;
    }
  }

  @action
  updateTradingViewSignalUsage() {
    let usingTradingViewSignal = false;

    const checkIfTradingViewSignal = (condition) => {
      return (
        condition.ift === SYMBOL_TYPES.SIGNAL &&
        condition.ifc === CONDITION_TYPES.TRADINGVIEW_SIGNAL
      );
    };

    this.seqInit.sequences.forEach((sequence) => {
      if (sequence.data.conditions) {
        sequence.data.conditions.forEach((condition) => {
          if (checkIfTradingViewSignal(condition)) {
            usingTradingViewSignal = true;
          }
        });
      }
    });

    this.usingTradingViewSignal[this.rule.tr] = usingTradingViewSignal;
  }

  @action
  updateLeverageSymbols(symbol) {
    this.setInstrumentQuote(symbol);

    for (const seqIdx in this.seqInit.sequences) {
      if (seqIdx > 0) {
        const seq = this.seqInit.sequences[seqIdx];

        if (seq.data.conditions && seq.data.conditions.length > 0 && seq.data.conditions[0]) {
          if (seq.data.conditions[0].ifc === 'symbol') {
            //seq.data.conditions[0].ifs = [symbol];
          }
        } else if (seq.data.actions && seq.data.actions.length > 0 && seq.data.actions[0]) {
          if (seq.data.actions[0].ort === 'base') {
            seq.data.actions[0].b = [symbol];
          }
        }
      }
    }
  }

  @action
  changeConditionIndicator(index, value, conditionIndex) {
    if (this.seqInit.sequences[conditionIndex].data.conditions[index]) {
      this.seqInit.sequences[conditionIndex].data.conditions[index].in = isEmptyValue(value)
        ? '---'
        : value;

      if (
        ['volume', 'marketcap'].indexOf(value) > -1 &&
        ['above', 'below'].indexOf(
          this.seqInit.sequences[conditionIndex].data.conditions[index].op
        ) > -1
      ) {
        this.seqInit.sequences[conditionIndex].data.conditions[index].op = '---';
      }

      if (
        ['price', 'ma_1', 'ma_5', 'ma_9', 'ma_50', 'ma_100', 'ma_200', 'rsi'].indexOf(value) === -1
      ) {
        this.seqInit.sequences[conditionIndex].data.conditions[index].wi = 'current_price';
      }

      if (
        ['ma_1', 'ma_5', 'ma_9', 'ma_50', 'ma_100', 'ma_200'].indexOf(value) > -1 &&
        ['above', 'below'].indexOf(
          this.seqInit.sequences[conditionIndex].data.conditions[index].op
        ) === -1
      ) {
        this.seqInit.sequences[conditionIndex].data.conditions[index].op = 'above';
      } else if (
        value === 'rsi' &&
        ['above', 'below', 'inc', 'dec'].indexOf(
          this.seqInit.sequences[conditionIndex].data.conditions[index].op
        ) > -1
      ) {
        this.seqInit.sequences[conditionIndex].data.conditions[index].op = 'lte';
      } else if (
        ['ma_1', 'ma_5', 'ma_9', 'ma_50', 'ma_100', 'ma_200'].indexOf(value) > -1 &&
        ['above', 'below'].indexOf(
          this.seqInit.sequences[conditionIndex].data.conditions[index].op
        ) > -1
      ) {
        //this.seqInit.sequences[conditionIndex].data.conditions[index].op = 'inc';
      }

      this.backup();
    }
    this.seqInit.checkIfStillError(conditionIndex, 'in', index);
  }

  @action
  changeConditionMAIndicator(index, value, conditionIndex) {
    if (this.seqInit.sequences[conditionIndex].data.conditions[index]) {
      this.seqInit.sequences[conditionIndex].data.conditions[index].maci = isEmptyValue(value)
        ? '---'
        : value;

      this.backup();
    }
    // this.seqInit.checkIfStillError(conditionIndex, 'in', index);
  }

  @action
  changeConditionOperator(index, value, conditionIndex) {
    const indicator = this.seqInit.sequences[conditionIndex].data.conditions[index].in
    
    if (this.seqInit.sequences[conditionIndex].data.conditions[index]) {
      this.seqInit.sequences[conditionIndex].data.conditions[index].op = isEmptyValue(value)
        ? ''
        : value;

      if (
        !(value === 'inc' || value === 'dec') &&
        this.seqInit.sequences[conditionIndex].data.conditions[index].a.s === PERCENTAGE_SYMBOL
      ) {
        this.changeConditionAmountSymbol(index, this.tempCurrency, conditionIndex);
      }
      if (!(value === 'inc' || value === 'dec' || isEmptyValue(value))) {
        this.changeConditionDelta(index, '', conditionIndex);
      }
      if (
        (value === 'inc' || value === 'dec') &&
        this.seqInit.sequences[conditionIndex].data.conditions[index].a.s !== PERCENTAGE_SYMBOL
      ) {
        this.changeConditionAmountSymbol(index, PERCENTAGE_SYMBOL, conditionIndex);
      }
      if(
        (value === 'inc' || value === 'dec') &&
        (indicator === 'rsi' || indicator.includes('ma_'))
      ){
        this.changeConditionDelta(index, '15m', conditionIndex);
      }
      this.backup();
    }

    this.seqInit.checkIfStillError(conditionIndex, 'op', index);
  }

  @action
  changeConditionAmountValue(index, value, conditionIndex) {
    if (
      this.seqInit.sequences[conditionIndex].data.conditions[index] &&
      (value.match(/^(\d+(\.?\d*)?)?$/) ||
        value.match(/^(\d*\.?\d*)+[kmb]$/i) ||
        value.match(/^(?!0\.00)\d{1,3}(,\d{3})*(\.\d\d)?$/))
    ) {
      this.seqInit.sequences[conditionIndex].data.conditions[index].a.iv = value;

      this.backup();
    }

    this.seqInit.checkIfStillError(conditionIndex, 'a', index);
  }

  changeRSIInputValue(index, value, conditionIndex) {
    value = parseInt(value);
    if (isNaN(value)) {
      value = 0;
    }
    if (value > 100) {
      value = 100;
    } else if (value < 0) {
      value = 0;
    }
    this.seqInit.sequences[conditionIndex].data.conditions[index].rsi_v = value;

    this.backup();
  }

  @action
  changeConditionAmountSymbol(index, value, conditionIndex, conditionsBlock = null) {
    if (conditionsBlock) {
      if (index === 0) {
        for (let i = 0; i < conditionsBlock[conditionIndex].data.conditions.length; i++) {
          conditionsBlock[conditionIndex].data.conditions[i].a.s = value;
        }
      } else {
        conditionsBlock[conditionIndex].data.conditions[index].a.s = value;
      }
    } else {
      if (index === 0) {
        for (let i = 0; i < this.seqInit.sequences[conditionIndex].data.conditions.length; i++) {
          this.seqInit.sequences[conditionIndex].data.conditions[i].a.s = value;
        }
      } else {
        this.seqInit.sequences[conditionIndex].data.conditions[index].a.s = value;
      }
    }

    this.backup();
  }

  @action
  changeConditionDelta(index, value, conditionIndex) {
    if (this.seqInit.sequences[conditionIndex].data.conditions[index]) {
      // this.seqInit.sequences[conditionIndex].data.conditions[index].d = isEmptyValue(value) ? '' : value;

      if (value === 'current_price') {
        this.seqInit.sequences[conditionIndex].data.conditions[index].wi = 'current_price';
        this.seqInit.sequences[conditionIndex].data.conditions[index].d = '';
      } else if (value.indexOf('action_price') > -1) {
        this.seqInit.sequences[conditionIndex].data.conditions[index].wi = 'action_price';
        this.seqInit.sequences[conditionIndex].data.conditions[index].d = '';
      } else if (value.indexOf('trailing') > -1) {
        this.seqInit.sequences[conditionIndex].data.conditions[index].wi = 'trailing';
        this.seqInit.sequences[conditionIndex].data.conditions[index].d = '';
      } else {
        this.seqInit.sequences[conditionIndex].data.conditions[index].wi = 'delta';
        this.seqInit.sequences[conditionIndex].data.conditions[index].d = value;
      }
      this.backup();
    }
  }

  @action
  changeMADelta(index, value, conditionIndex) {
    if (this.seqInit.sequences[conditionIndex].data.conditions[index]) {
      this.seqInit.sequences[conditionIndex].data.conditions[index].map = isEmptyValue(value)
        ? ''
        : value;
      this.backup();
    }
  }
  ruleContainsSequenceType(type) {
    return this.seqInit.sequences.some((seq) => seq.type === type);
  }

  @action
  addAction() {
    let sequenceIndex = this.seqInit.addToSequence(null, ACTION_TYPE);
    this.countBaseCoinsInActions();
    this.seqInit.sequences[sequenceIndex].data.actions[0].ot =
      stores.info.checkOrderTypeOnExchange(null); // always set default (first) order_type supported on exchange
    this.seqInit.sequences[sequenceIndex].data.actions[0].v = {
      v: '',
      iv: '',
      s: this.tempCurrency,
    };
    analytics.track('Added action');
    this.backup();
  }

  @action
  setActionType(index, value, actionIndex) {
    // ACTIONS
    if (this.seqInit.sequences[actionIndex].data.actions?.[index]) {
      // set backups
      if (this.seqInit.sequences[actionIndex].data.actions[index].at === 'sell') {
        this.backupSellActionObject[actionIndex] = {
          ...this.seqInit.sequences[actionIndex].data.actions[index],
        };
      } else if (this.seqInit.sequences[actionIndex].data.actions[index].at === 'buy') {
        this.backupBuyActionObject[actionIndex] = {
          ...this.seqInit.sequences[actionIndex].data.actions[index],
        };
      }

      if (value === NOTIFY_TYPE) {
        this.seqInit.sequences[actionIndex] = handleNotifyType(this, this.parent, {
          idCount: this.seqInit.sequences[actionIndex].id,
        });
        this.seqInit.refreshSequences();
      } else if (value === 'sell') {
        this.seqInit.sequences[actionIndex].data.actions[index].at = value;
        this.seqInit.sequences[actionIndex].data.actions[index].v.s = PERCENTAGE_SYMBOL;
        if (this.seqInit.sequences[actionIndex].data.actions[index].ort === 'that_coin') {
          for (const i in this.seqInit.sequences.slice(0, actionIndex)) {
            if (
              this.seqInit.sequences[i].type === OPERATOR_TYPE &&
              (this.seqInit.sequences[i].data.operators[0].t === OPERATOR_TYPES.WAIT ||
                this.seqInit.sequences[i].data.operators[0].t === OPERATOR_TYPES.THEN ||
                this.seqInit.sequences[i].data.operators[0].t === OPERATOR_TYPES.PARALLEL)
            ) {
              //fix for https://gitlab.com/coinrule-v2/project-board/-/issues/2161#note_514685463
              //this.seqInit.sequences[actionIndex].data.actions[index].eqt = 'balance';
              this.seqInit.sequences[actionIndex].data.actions[index].eqt = 'amount';
            }
          }
        }
      } else if (value === 'buy') {
        this.seqInit.sequences[actionIndex].data.actions[index].at = value;
        if (this.seqInit.sequences[actionIndex].data.actions[index].ort === 'that_coin') {
          for (const i in this.seqInit.sequences.slice(0, actionIndex)) {
            if (
              this.seqInit.sequences[i].type === OPERATOR_TYPE &&
              (this.seqInit.sequences[i].data.operators[0].t === OPERATOR_TYPES.WAIT ||
                this.seqInit.sequences[i].data.operators[0].t === OPERATOR_TYPES.THEN ||
                this.seqInit.sequences[i].data.operators[0].t === OPERATOR_TYPES.PARALLEL)
            ) {
              this.seqInit.sequences[actionIndex].data.actions[index].eqt = 'amount';
            }
          }
        }
      }
      this.backup();
      // NOTIFIES
    } else if (this.seqInit.sequences[actionIndex].data.notifies?.[index]) {
      this.seqInit.sequences[actionIndex] = handleActionType(
        actionIndex,
        this.seqInit.sequences[actionIndex].component,
        this.seqInit.sequences,
        this,
        this.parent,
        { idCount: this.seqInit.sequences[actionIndex].id }
      );
      this.seqInit.refreshSequences();

      if (value === 'sell') {
        if (this.backupSellActionObject[actionIndex]) {
          this.seqInit.sequences[actionIndex].data.actions[index] =
            this.backupSellActionObject[actionIndex];
        } else {
          this.seqInit.sequences[actionIndex].data.actions[index].at = 'sell';
        }
      } else if (value === 'buy') {
        if (this.backupBuyActionObject[actionIndex]) {
          this.seqInit.sequences[actionIndex].data.actions[index] =
            this.backupBuyActionObject[actionIndex];
        } else {
          this.seqInit.sequences[actionIndex].data.actions[index].at = 'buy';
        }
      }
    }
  }

  @action
  changeActionAmountValue(index, value, actionIndex, isLeverageExchange) {
    if (
      this.seqInit.sequences[actionIndex].data.actions[index] &&
      (value.match(/^(\d+(\.?\d*)?)?$/) ||
        value.match(/^(\d*\.?\d*)+[kmb]$/i) ||
        value.match(/^(?!0\.00)\d{1,3}(,\d{3})*(\.\d\d)?$/))
    ) {
      this.seqInit.sequences[actionIndex].data.actions[index].v.iv = value;

      if (isLeverageExchange) {
        this.changeActionAmountSymbol(index, COINS_SYMBOL, actionIndex);
      } else {
        this.backup();
      }
    }

    this.seqInit.checkIfStillError(actionIndex, 'v');
  }
  @action
  changeActionAmountSymbol(index, value, actionIndex, actionsBlock = null) {
    if (value === PERCENTAGE_SYMBOL) {
      if (this.seqInit.sequences[actionIndex].data.actions[index].ort === 'that_coin') {
        for (const i in this.seqInit.sequences.slice(0, actionIndex)) {
          if (
            this.seqInit.sequences[i].type === OPERATOR_TYPE &&
            (this.seqInit.sequences[i].data.operators[0].t === OPERATOR_TYPES.WAIT ||
              this.seqInit.sequences[i].data.operators[0].t === OPERATOR_TYPES.THEN ||
              this.seqInit.sequences[i].data.operators[0].t === OPERATOR_TYPES.PARALLEL)
          ) {
            this.seqInit.sequences[actionIndex].data.actions[index].eqt = 'amount';
          } else {
            this.seqInit.sequences[actionIndex].data.actions[index].eqt = 'balance';
          }
        }
      }
    } else {
      this.seqInit.sequences[actionIndex].data.actions[index].eqt = 'balance';
    }
    if (actionsBlock) {
      if (actionsBlock[index]) {
        actionsBlock[actionIndex].data.actions[index].v.s = value;
      }
    } else {
      if (this.seqInit.sequences[actionIndex].data.actions[index]) {
        this.seqInit.sequences[actionIndex].data.actions[index].v.s = value;
      }
      this.backup();
    }
  }

  @action
  changeActionTopBy(index, value, actionIndex) {
    if (this.seqInit.sequences[actionIndex].data.actions[index]) {
      //this.seqInit.sequences[actionIndex].data.actions[index].topby = value;
      //this.seqInit.sequences[actionIndex].data.actions[index].topbydir = value === 'price' ? 'inc' : '';
      this.backup();
    }
  }

  canSequenceBeRemoved(sequenceIndex) {
    const isLastSequence = sequenceIndex === this.seqInit.sequences.length - 1;
    const isOnlySequenceWithEventTriggeringRule =
      sequenceIndex === 0 && this.rule.tr === TRIGGERS.EVENT;

    return !(!isLastSequence || isOnlySequenceWithEventTriggeringRule || this.isQuickEdit());
  }

  @action
  changeNotificationAddress(sequenceIndex, newAddress) {
    this.seqInit.sequences[sequenceIndex].data.notifies[0].address = newAddress;
    console.log(
      'change sequence ',
      this.seqInit.sequences[sequenceIndex],
      'address to ',
      newAddress
    );
    this.backup(); // not sure if this is needed
  }

  @action
  changeNotificationChannel(sequenceIndex, newChannel) {
    this.seqInit.sequences[sequenceIndex].data.notifies[0].channel = newChannel;

    this.backup(); // not sure if this is needed
  }

  @action
  changeNotificationTimeframe(sequenceIndex, newTimeframe) {
    this.seqInit.sequences[sequenceIndex].data.notifies[0].timeframe = newTimeframe;
    console.log(
      'change sequence ',
      this.seqInit.sequences[sequenceIndex],
      'timeframe to ',
      newTimeframe
    );
    this.backup(); // not sure if this is needed
  }

  getActions() {
    return this.seqInit.sequences.filter((seq) => seq.type === ACTION_TYPE);
  }

  @action
  countBaseCoinsInActions() {
    const actions = this.getActions();
    const baseCoins = [];

    for (const action of actions) {
      baseCoins.push(action.data.actions[0].b[0] || action.data.actions[0].ort);
    }

    const uniqueBaseCoins = [...new Set(baseCoins)];

    if (uniqueBaseCoins.length > 1) {
      this.warningTexts.push(MULTIPLE_BASE_CURRENCIES_WARNING_TEXT);
    } else {
      this.warningTexts = this.warningTexts.filter(
        (txt) => txt !== MULTIPLE_BASE_CURRENCIES_WARNING_TEXT
      );
    }
  }

  @action
  changeActionBase(index, symbols, actionIndex, userEdited = true, isLeverageExchange = false) {
    if (index === 0 && userEdited) {
      this.actionWasEdited = true;
    }

    let newSymbol = '---';

    if (symbols === 'that_coin') {
      //fix for https://gitlab.com/coinrule-v2/project-board/-/issues/2182
      const previousCondIndex = this.seqInit.findIndexSequenceBeforeByType(CONDITION_TYPE, index);
      const prevCond = this.seqInit.sequences[previousCondIndex]?.data.conditions
        ? this.seqInit.sequences[previousCondIndex]?.data.conditions[0]
        : null;

      if (prevCond?.sr) {
        this.seqInit.sequences[actionIndex].data.actions[index].eqt = 'amount';
      }
    }

    if (this.seqInit.sequences[actionIndex]?.data.actions[index]) {
      if (
        ['that_coin', 'best_market', 'worst_market', 'best_mine', 'worst_mine'].indexOf(symbols) >
        -1
      ) {
        this.seqInit.sequences[actionIndex].data.actions[index].b = [];
        this.seqInit.sequences[actionIndex].data.actions[index].ort = symbols;
      } else if (symbols.indexOf('from_sequence') > -1) {
        this.seqInit.sequences[actionIndex].data.actions[index].b = [];
        this.seqInit.sequences[actionIndex].data.actions[index].ort = symbols;
        let positions = symbols.split('_');
        this.seqInit.sequences[actionIndex].data.actions[index].sr = Number(positions[2]);
      } else {
        this.seqInit.sequences[actionIndex].data.actions[index].ort = 'base';
        this.seqInit.sequences[actionIndex].data.actions[index].b = [symbols];
        newSymbol = symbols;
      }

      if (isLeverageExchange) {
        this.updateLeverageSymbols(newSymbol);
      } else if (!isLeverageExchange) {
        this.countBaseCoinsInActions();
      }

      this.seqInit.checkIfStillError(actionIndex, 'ort');
      this.backup();
    }
  }

  @action
  changeExitQtyType(index, value, actionIndex) {
    if (this.seqInit.sequences[actionIndex].data.actions[index]) {
      this.seqInit.sequences[actionIndex].data.actions[index].eqt = value;
      this.backup();
    }
    this.seqInit.checkIfStillError(actionIndex, 'eqt');
  }

  @action
  changeActionQuote(index, value, actionIndex) {
    if (this.seqInit.sequences[actionIndex].data.actions[index]) {
      this.seqInit.sequences[actionIndex].data.actions[index].q = value;
      this.backup();
    }
    this.seqInit.checkIfStillError(actionIndex, 'q');
  }

  @action
  changeActionOrderType(index, value, actionIndex) {
    if (this.seqInit.sequences[actionIndex].data.actions[index]) {
      this.seqInit.sequences[actionIndex].data.actions[index].ot = value;
      this.backup();
    }
  }

  @action
  removeAction(index, actionIndex) {
    if (index > 0) {
      const newActions = [];
      const condLen = this.seqInit.sequences[actionIndex].data.actions.length;

      for (let i = 0; i < condLen; i++) {
        if (i !== index) {
          newActions.push(this.seqInit.sequences[actionIndex].data.actions[i]);
        }
      }

      this.seqInit.sequences[actionIndex].data.actions = newActions;
      analytics.track('Removed action', {
        Index: index,
      });
      this.backup();
    }
  }

  @action
  setRuleName(name) {
    this.rule.nam = name;
    this.backup();
  }

  @action
  setMaxOpenPositions(max) {
    max = parseInt(max, 10);

    if (max) {
      this.rule.mop = max;
    } else {
      this.rule.mop = '';
    }

    this.backup();
  }

  @action
  checkIfShowMoreThen() {
    if (!this.showMoreThenCheck) {
      if (this.rule.rpt.l > 1) {
        this.rule.rpt.t = 'no_more_than_once';
        this.showMoreThenCheck = true;
      } else {
        this.rule.rpt.t = 'total';
      }
    }
  }

  @action
  setRepeats(repeats) {
    const isNotAfterAnyTime = !(this.seqInit.typesCounting.operators.types.parallel > 0);

    if (repeats) {
      repeats = parseInt(repeats, 10);

      if (repeats >= 0) {
        this.rule.rpt.l = repeats;
        this.setMaxOpenPositions(repeats);

        if (repeats > 0) {
          this.rule.ed = null;
        }

        if (repeats <= 1 && isNotAfterAnyTime) {
          this.rule.rpt.t = 'total';
        }

        this.backup();
      }
    } else {
      this.rule.rpt.l = 0;
      this.setMaxOpenPositions(0);

      if (isNotAfterAnyTime) {
        this.rule.rpt.t = 'total';
      }

      this.backup();
    }
  }

  @action
  setRepeatNoMore(newValue) {
    this.rule.rpt.t = newValue;

    if (newValue === 'total') {
      this.rule.rpt.nmt = '1m';
      return;
    }

    if (!this.rule.edit_mode) {
      this.rule.rpt.nmt = '5m';
    }
  }

  @action
  setStartDate(date) {
    this.rule.sd = date;
    this.rule.si = date === null;

    this.backup();
  }

  @action
  resetEditedId() {
    this.editedRuleId = null;
    this.editMode = false;
  }

  @action
  setOperatorType(sequenceIndex, type, isLeverage) {
    this.seqInit.sequences[sequenceIndex].data.operators[0].t = type;

    if (type === 'or') {
      if (isLeverage) {
        let conditionData = { ...this.seqInit.sequences?.[0].data.conditions?.[0] };

        this.seqInit.sequences[sequenceIndex + 1].data.conditions[0].ifs = conditionData.ifs;
        this.seqInit.sequences[sequenceIndex + 1].data.conditions[0].ifc = conditionData.ifc;
        this.seqInit.sequences[sequenceIndex + 1].data.conditions[0].sr = conditionData.sr;
        this.seqInit.sequences[sequenceIndex + 1].data.conditions[0].wi = conditionData.wi;
      } else {
        let conditionData = { ...defaultCondition };

        this.seqInit.sequences[sequenceIndex + 1].data.conditions[0].ifc = conditionData.ifc;
        this.seqInit.sequences[sequenceIndex + 1].data.conditions[0].sr = conditionData.sr;
        this.seqInit.sequences[sequenceIndex + 1].data.conditions[0].wi = conditionData.wi;
      }
    }
  }

  @action
  getRuleData(asDraft) {
    this.rule.s = asDraft ? 'draft' : 'active';
    if (this.editMode && this.editedRuleId) {
      this.rule.rule_id = this.editedRuleId;
    }

    if (asDraft && !this.rule.nam) {
      this.rule.nam = 'Unnamed';
    }

    let sequences = [];
    for (let i in this.seqInit.sequences) {
      if (this.seqInit.sequences[i].type === CONDITION_TYPE) {
        for (let j in this.seqInit.sequences[i].data.conditions) {
          let conditionData = { ...this.seqInit.sequences[i].data.conditions[j] };

          sequences.push({
            t: CONDITION_TYPE,
            d: conditionData,
          });
        }
      } else if (this.seqInit.sequences[i].type === NOTIFY_TYPE) {
        const notifiesDataWithoutDo = { ...this.seqInit.sequences[i].data.notifies[0] };
        delete notifiesDataWithoutDo.do;

        const data = {
          do: ACTIONS.NOTIFY,
          na: notifiesDataWithoutDo,
        };

        sequences.push({
          t: ACTION_TYPE,
          d: data,
        });
      } else if (this.seqInit.sequences[i].type === ACTION_TYPE) {
        const data = {
          do: ACTIONS.TRADE,
          ta: this.seqInit.sequences[i].data.actions[0],
        };
        let clone = { ...this.seqInit.sequences[i].data.actions[0] };

        delete clone.do;

        if (clone.ort?.indexOf('from_sequence') > -1) {
          clone.ort = 'from_sequence';
        }

        data.ta = clone;
        sequences.push({
          t: ACTION_TYPE,
          d: data,
        });
      } else if (this.seqInit.sequences[i].type === OPERATOR_TYPE) {
        sequences.push({
          t: OPERATOR_TYPE,
          d: this.seqInit.sequences[i].data.operators[0],
        });
      }
    }

    const data = {
      ...this.rule,
      sq: sequences,
    };

    if (['week', 'day', 'month', 'hour'].indexOf(data.pe) > -1) {
      if (data.pe === 'week') {
        data.pe = '1W';
      } else if (data.pe === 'day') {
        data.pe = '1D';
      } else if (data.pe === 'month') {
        data.pe = '1M';
      } else if (data.pe === 'hour') {
        data.pe = '1h';
      }
    }

    if (!data.pe) {
      data.pe = DEFAULT_TIME_TRIGGER_PERIOD;
    }

    if (data.tim === 'on') {
      data.rpt.l = 1;
    }

    return data;
  }

  @observable isLoading = false;

  @action
  setIsLoading = (value) => (this.isLoading = value);

  @action
  setIsSending = (value) => (this.isSending = value);

  handleShowSuccessPopup = () => {
    /****
     * if user has no rules at this point (ie user.hasRules = false), show rule item guide
     */
    if (!stores.user.hasRule) {
      stores.userSurvey.updateSuccessPopupState({
        show: true,
        index: 0,
      });

      stores.user.setHasRule(true);
    } else {
      stores.userSurvey.updateSuccessPopupState({
        show: false,
        index: undefined,
      });
    }
  };

  @action
  async createRule(unblockBlockedPage, asDraft, exchangeId) {
    if (!this.isSending) {
      this.setIsSending(true);
      let isAuthorized, userError;

      try {
        let data = this.getRuleData(asDraft);
        /***
         * Use the exchangeId here when provided. Eg when user clicks on 'Launch Demo'
         * But has another exchange connected. Also when creating rule immediately a
         * user connects an exchange, exchangeId is the id of the new exchange created
         */
        data = { ...data, ex: exchangeId || data.ex };

        const request = this.rule.rule_id ? http.put : http.post;

        const resp = await request('/rule', data);

        if (resp.data) {
          isAuthorized = resp.data.authorized;

          if (resp.data.status === 'OK') {
            let textNotification = '';
            let showWellDone = false;

            if (this.editMode === IRuleEditModes.QuickEdit) {
              textNotification = `Rule edited`;
            } else if (this.editMode === IRuleEditModes.FullEdit) {
              textNotification = `Rule edited. Please note that performance data/logs have been reset`;
            } else if (asDraft) {
              textNotification = `Draft saved`;
            } else {
              textNotification = `Amazing! Your rule has been created!`;
            }

            successNotification(textNotification);

            this.removeBackup();

            if (showWellDone) {
              // Let the dashboard know that "Well done" tooltip should be show
              stores.user.newRule = resp.data.ruleId;
            } else if (resp.data.ruleId) {
              this.rule.rule_id = resp.data.ruleId;

              if (this.editedRuleId) {
                this.resetEditedId();
              }
            }

            this.setNewTradingViewHookId(); // reset TV hook ID

            if (data.exchange_id) {
              const ruleExchange = stores.info.exchanges.find((ex) => ex.id === data.exchange_id);

              if (ruleExchange.uid !== 'demo') {
                stores.user.hasLiveRule = true;
              }
            }

            unblockBlockedPage();
            stores.userInfo.resetFilter();

            await stores.userInfo
              .getRules(true)
              .then((authorized) => stores.app.unauthorized(authorized));

            this.handleShowSuccessPopup();
          } else {
            if (isAuthorized) {
              isAuthorized = null;
            }

            userError = resp.data.message;
            if (resp.data.errorType === 'MAX_EXEC_LIMIT') {
              this.setShowPlansBanner();
            }
            throw new Error(userError);
          }
        } else {
          dbg('Unknown response', resp);
          throw new Error('Unknown response');
        }
      } catch (err) {
        errorNotification(userError || `Something has gone wrong! Please try to reload the page`);
      }

      this.setIsSending(false);
      this.setIsLoading(false);

      return isAuthorized;
    }
  }

  @action
  setShowPlansBanner(show = true) {
    this.showPlansBanner = show;
  }

  validateRule() {
    if (this.rule.rpt.l < 1) {
      return `Please select how many times you want this rule to be executed in the Timeframe field`;
    }

    if (!this.rule.nam) {
      return `Please give this rule a name before launching it`;
    }

    return null;
  }

  changeQtyByStepSize(coinQty, stepSize) {
    if (stepSize > 0) {
      let dec = new Decimal(coinQty);
      dec = dec.div(stepSize);
      dec = dec.floor();
      coinQty = Number(dec.times(stepSize));
    }
    return coinQty;
  }

  getParsedSufix(every) {
    every = every + '';

    return every.substring(every.length - 1, every.length);
  }

  checkMarketMinimum(action, market, rule_ex_id, coinSymbol, amountValue) {
    let per, pomTotalPrice;
    let coinQty = 0;

    const l_getSymbolPrice = (exId, symbol) => {
      if (stores.exchangeInfo.assets[exId][symbol]) {
        return stores.exchangeInfo.assets[exId][symbol].price;
      } else if (stores.info.currencies[symbol]) {
        return stores.info.currencies[symbol].btcPrice;
      } else {
        return 0;
      }
    };
    const l_getTotalPrice = (coinPrice, coinQty) => {
      return coinPrice * coinQty;
    };
    const l_divValues = (v1, v2) => {
      return v2 > 0 ? v1 / v2 : 0;
    };

    const quotePrice = l_getSymbolPrice(rule_ex_id, action.quote);
    const coinPrice = l_getSymbolPrice(rule_ex_id, coinSymbol);
    const realPrice = l_divValues(coinPrice, quotePrice);

    if (action.v.s === PERCENTAGE_SYMBOL) {
      const user_coins = stores.userInfo.balances[rule_ex_id]?.data;
      let percAsset = action.quote;

      if (action.type === TRADE_TYPES.SELL) {
        percAsset = coinSymbol;
      }

      if (Array.isArray(user_coins)) {
        for (const i in user_coins) {
          if (user_coins[i].asset === percAsset) {
            per = user_coins[i].balance * realPrice * (amountValue / 100);
            coinQty = this.changeQtyByStepSize(l_divValues(per, realPrice), market.stepSize);
            break;
          }
        }
      }
    } else if (action.v.s === COINS_SYMBOL) {
      coinQty = this.changeQtyByStepSize(amountValue, market.stepSize);
    } else if (action.v.s === action.quote) {
      coinQty = this.changeQtyByStepSize(l_divValues(amountValue, realPrice), market.stepSize);
    } else {
      pomTotalPrice =
        stores.info.currencies && stores.info.currencies[action.v.s]
          ? l_divValues(amountValue, stores.info.currencies[action.v.s].orgPrice)
          : 0;
      coinQty = this.changeQtyByStepSize(l_divValues(pomTotalPrice, coinPrice), market.stepSize);
    }

    if (coinQty < market.minQty) {
      return {
        symbol: coinSymbol,
        type: 'quantity',
      };
    }

    const totalPrice = l_getTotalPrice(realPrice, coinQty);

    if (totalPrice < market.minNotional) {
      return {
        symbol: coinSymbol,
        type: 'notional',
      };
    }

    return null;
  }

  pairsExist(action) {
    const coins = toJS(action.base);
    const rule_ex_id = this.rule.ex;
    const ex_markets = stores.exchangeInfo.markets[rule_ex_id];

    let minimum_market;
    const find_markets = [];
    const error_notional = [];
    const error_quantity = [];

    if (coins.length > 0) {
      const amountValue =
        action.calc === CALC_TYPES.EACH ? action.v.iv / coins.length : action.v.iv;

      ex_markets.map((market) => {
        const symbol = market.base + '/' + market.quote;

        for (let j = 0; j < coins.length; j++) {
          if (
            symbol === coins[j] + '/' + action.quote ||
            symbol === action.quote + '/' + coins[j]
          ) {
            minimum_market = this.checkMarketMinimum(
              action,
              market,
              rule_ex_id,
              coins[j],
              amountValue
            );

            if (minimum_market) {
              if (minimum_market.type === 'notional') {
                error_notional.push(minimum_market.symbol);
              } else if (minimum_market.type === 'quantity') {
                error_quantity.push(minimum_market.symbol);
              }
            }

            find_markets.push(coins[j]);
            coins.splice(j, 1);
          }
        }
        return true;
      });
    }

    return {
      unauthorizedCoins: coins,
      markets: find_markets,
      notional: error_notional,
      quantity: error_quantity,
    };
  }

  coinExistOnExchange(coin) {
    const rule_ex_id = this.rule.ex;
    const ex_assets =
      stores.exchangeInfo.assets && stores.exchangeInfo.assets[rule_ex_id]
        ? stores.exchangeInfo.assets[rule_ex_id]
        : null;

    if (ex_assets && ex_assets[coin]) {
      return true;
    }

    return false;
  }

  @action
  setPaymentGateVisible = ({ type, isVisible, forceShow = false }) => {
    if (+stores.user.user.plan.clearance === 3 && isVisible) return;

    if (type === 'promo') {
      this.paymentGatePromoVisible = isVisible;
    } else if (type === 'trading_volume_90') {
      if (isVisible === true && !getDataSessionStorage('trading_volume_90_paywall_wasShowed')) {
        this.paymentGateTradingVolume90Visible = isVisible;
      } else if (isVisible === false) {
        setDataSessionStorage('trading_volume_90_paywall_wasShowed', true);
        this.paymentGateTradingVolume90Visible = isVisible;
      }
    } else if (type === 'trading_volume_100') {
      if (isVisible === true && !getDataSessionStorage('trading_volume_100_paywall_wasShowed')) {
        this.paymentGateTradingVolume100Visible = isVisible;
      } else if (isVisible === true && forceShow === true) {
        setDataSessionStorage('trading_volume_100_paywall_wasShowed', true);
        this.paymentGateTradingVolume100Visible = isVisible;
      } else if (isVisible === false) {
        setDataSessionStorage('trading_volume_100_paywall_wasShowed', true);
        this.paymentGateTradingVolume100Visible = isVisible;
      }
    } else if (type === ADVANCED_ONBOARDING_TEMPLATE_PAYWALL_NAME) {
      this.showPaymentGateForAdvancedOnboardingTemplate = isVisible;
    } else {
      this.paymentGateVisible = isVisible;
    }
  }

  renderPredefinedPeriodSelect(
    period,
    changeFunction = this.setTimePeriod.bind(this),
    disableQuickEdit = false,
    isWait = false,
    popoverText = 'Choose Execution Interval'
  ) {
    const shouldBeDisabled = disableQuickEdit && this.isQuickEdit();

    const periods = !isWait ? stores.info.timePeriods : stores.info.waitTimePeriods;

    return (
      <Popover text={popoverText} placement='right'>
        <Select
          title=''
          size={'default'}
          onChange={(value) => changeFunction(value)}
          value={period}
          className={!shouldBeDisabled && getFieldCssClass(period, true)}
          disabled={shouldBeDisabled}>
          {periods.map((p) => {
            return (
              <Option title='' key={p} value={p}>
                {getPeriodName(p)}
              </Option>
            );
          })}
        </Select>
      </Popover>
    );
  }

  setInstrumentQuote(quote) {
    this.instrumentQuote = quote;
  }

  increaseExecutions() {
    this.details.executed++;
  }

  setRuleStatus(newStatus) {
    this.rule.s = newStatus;
  }

  @action
  setDataInEditMode(t, r, m) {
    this.rule.ex = r.ex;
    this.rule.rule_id = r.rule_id;
    this.rule.nam = r.nam;
    this.rule.tr = r.tr;
    this.rule.tim = r.tim;
    this.rule.pe = r.pe;
    this.rule.tid = r.tid;
    this.rule.s = r.s;
    this.rule.rpt = r.rpt;
    this.rule.tne = defaultRule.tne;
    this.rule.si = defaultRule.si;
    this.rule.edit_mode = m;
    this.editMode = m;
    this.editedRuleId = r._id || r.rule_id || null;
    this.showEditText = t.props.showEditText || false;
    this.seqInit.initEdit(this, t, r.sq);
    this.updateTradingViewSignalUsage();
  }

  @action
  setDataInCloneMode(t, r) {
    this.rule.ex = r.ex;
    this.rule.nam = r.nam;
    this.rule.tr = r.tr;
    this.rule.tim = r.tim;
    this.rule.pe = r.pe;
    this.rule.tid = r.tid;
    this.rule.rpt = r.rpt;
    this.rule.tne = defaultRule.tne;
    this.rule.si = defaultRule.si;
    this.rule.sd = null;
    this.rule.rule_id = null;
    this.rule.de = undefined;
    this.rule.edit_mode = 'clone';
    this.rule.tvhid = '';
    this.cloneMode = true;
    this.seqInit.initEdit(this, t, r.sq);
    this.updateTradingViewSignalUsage();
  }

  isQuickEdit() {
    return this.editMode === IRuleEditModes.QuickEdit;
  }

  @observable isTogglingRuleTelegramNotification = false;

  @action
  toggleRuleTelegramNotification = async () => {
    if (this.isTogglingRuleTelegramNotification) {
      return;
    }

    try {
      this.isTogglingRuleTelegramNotification = true;

      const resp = await http.put('/rule/notifications/updateTelegram', {
        rule_id: this.rule.rule_id,
        enabled: !this.rule.tne,
      });

      this.isTogglingRuleTelegramNotification = false;

      if (resp.data) {
        if (resp.data.status === 'OK') {
          this.rule.tne = resp.data.telegram_status;
          analytics.track(
            `Rule "telegram" notifications ${this.rule.tne ? 'enabled' : 'disabled'}`
          );
        } else {
          errorNotification(resp.data.message);
        }

        return resp.data.authorized;
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isTogglingRuleTelegramNotification = false;
    }
  };

  isAnyTimeOperator = () => {
    if (this.rule.sq) {
      return this.rule.sq.some((sequence) => {
        return sequence.t === OPERATOR_TYPE && sequence.d.t === OPERATOR_TYPES.PARALLEL;
      });
    } else {
      return this.seqInit.sequences.some((sequence) => {
        return (
          sequence.type === OPERATOR_TYPE &&
          sequence.data.operators[0].t === OPERATOR_TYPES.PARALLEL
        );
      });
    }
  };

  checkTrailingAction = () => {
    let trailingFound = false;
    let trailingScope = false;
    let trailingDirection;
    let trailingAction;

    if (this.rule.sq) {
      this.rule.sq.forEach((sequence) => {
        if (sequence.t === CONDITION_TYPE) {
          if (sequence.d.wi === 'trailing') {
            trailingScope = true;
            trailingDirection = sequence.d.op;
          }
        }
        if (sequence.t === ACTION_TYPE && trailingScope) {
          trailingFound = true;
          trailingScope = false;
          trailingAction = sequence.d.ta.at;
        }
      });
    } else {
      this.seqInit.sequences.forEach((sequence) => {
        if (sequence.type === CONDITION_TYPE) {
          if (sequence.data.conditions[0].wi === 'trailing') {
            trailingScope = true;
            trailingDirection = sequence.data.conditions[0].op;
          }
        }
        if (sequence.type === ACTION_TYPE && trailingScope) {
          trailingFound = true;
          trailingScope = false;
          trailingAction = sequence.data.actions[0].at;
        }
      });
    }

    return {
      trailingFound,
      trailingDirection,
      trailingAction,
    };
  };
}
