import Noty from 'noty';

const messageCache = [];

const notification = (type, text) => {
  if (!text) {
    return;
  }

  if (messageCache.indexOf(text) >= 0) {
    return;
  }

  messageCache.push(text);

  new Noty({
    type: type,
    theme: 'bootstrap-v4',
    layout: 'topCenter',
    timeout: 6000,
    text: text,
    callbacks: {
      afterClose: () => {
        messageCache.shift();
      },
    },
  }).show();
};

export function errorNotification(text) {
  if (text) {
    if (text.includes('Something has gone wrong' || 'Something went wrong')) {
      text = '';
    }

    if (text.includes('. ')) {
      text = text.slice(0, text.indexOf('. ') + 1) + '<br>' + text.slice(text.indexOf('. ') + 1);
    }
    if (text.includes('! ')) {
      text = text.slice(0, text.indexOf('! ') + 1) + '<br>' + text.slice(text.indexOf('! ') + 1);
    }
    if (text.includes('? ')) {
      text = text.slice(0, text.indexOf('? ') + 1) + '<br>' + text.slice(text.indexOf('? ') + 1);
    }

    if (text.includes('You have been logged out')) {
      infoNotification(text);
    } else {
      notification('error', text);
    }
  }
}

export function successNotification(text) {
  notification('success', text);
}

export function alertNotification(text) {
  notification('alert', text);
}

export function warningNotification(text) {
  notification('warning', text);
}

export function infoNotification(text) {
  notification('info', text);
}
