import React from 'react'

import type { BoxProps } from '@mui/material'

import { useTheme, Box, CircularProgress } from '@mui/material'

export interface RuleStatusProgressBarProps extends BoxProps {
  value: number
  width?: number
  height?: number
}

const testId = 'RuleStatusProgressBar'
export const RuleStatusProgressBar = (props: RuleStatusProgressBarProps) => {
  const {
    sx,
    value,
    children,
    width = 64,
    height = 64,
    ...otherProps
  } = props

  const theme = useTheme()

  const parsedValue = value > 100 ? 100 : value < 0 ? 0 : value

  return (
    <Box
      data-testid={testId}
      className={testId}
      sx={{
        position: 'relative',
        width: `${width}px !important`,
        height: `${height}px !important`,
        ...sx
      }}
      {...otherProps}
    >
      <CircularProgress 
        variant={'determinate'}
        value={parsedValue}
        thickness={6}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
          width: `${width}px !important`,
          height: `${height}px !important`,
          // mirror flip to start progress not clockwise
          transform: 'rotate(-90deg) scale(1, -1) !important',
          color: theme.palette.coinrule.navigation.icon1
        }}
      />
      <CircularProgress 
        variant={'determinate'}
        value={100}
        thickness={6}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 0,
          width: `${width}px !important`,
          height: `${height}px !important`,
          // mirror flip to start progress not clockwise
          transform: 'rotate(-90deg) scale(1, -1) !important',
          color: theme.palette.coinrule.basic.greySuperLight
        }}
      />
      {
        children ?
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center', 
              justifyContent: 'center',
            }}
          >
            {children}
          </Box>
          :
          null
      }
    </Box>
  )
}

export default RuleStatusProgressBar
