import React from 'react'

import { OutlinedInput, InputLabel, useTheme } from '@mui/material'
import type { OutlinedInputProps, InputLabelProps } from '@mui/material'

export interface InputOutlinedProps extends OutlinedInputProps {
  labelProps: InputLabelProps,
}

const testId = 'InputOutlined'
export const InputOutlined = (props: InputOutlinedProps) => {
  const {
    sx,
    labelProps,
    inputProps,
    value,
    ...otherProps
  } = props

  const theme = useTheme()

  return (
    <>
      <InputLabel
        sx={{
          marginTop: '15px',
          color: theme.palette.coinrule.basic.greyLightColor,
          alignSelf: 'flex-start',
          ...labelProps.sx
        }}
        {...labelProps}
      />
      <OutlinedInput
        data-testid={testId}
        className={testId}
        value={value || ''}
        sx={{
          margin: '7px 0 14px',
          borderRadius: '4px',
          '& legend': { display: 'none' },
          '& fieldset': { top: '1px' },
          ...sx
        }}
        inputProps={{
          sx: { padding: '14px', ...inputProps?.sx },
          ...inputProps
        }}
        fullWidth={true}
        {...otherProps}
      />
    </>
  )
}

export default InputOutlined
