import React, { Component } from 'react';
import { observer } from 'mobx-react';

import stores from '../../../stores';
import './style.scss'

@observer
class TwoFaField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNumeric: true,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  showInvalidMark() {
    return !stores.auth.isValid2faToken && !this.state.isNumeric;
  }

  isValidNumber(input) {
    const isInvalid = Number.isNaN(input * 1);
    return !isInvalid;
  }

  handleChange(e) {
    this.setState({ isNumeric: this.isValidNumber(e.target.value) });
    stores.auth.setTwoFaToken(e.target.value);
  }

  render() {
    return (
      <div className='authWindow__inputWrap'>
        <div className='description-text'>
          Use your authentication app to generate your security code
        </div>
        <div className='input-block'>
          <input
            type='tel'
            autoFocus
            name='twofa-code'
            className={`authWindow__textInput 
                                  authWindow__textInput_password 
                                  ${stores.auth.twoFaToken && 'authWindow__textInput_filled'} 
                                  ${this.showInvalidMark() && 'authWindow__textInput_invalid'}`}
            onChange={this.handleChange}
            data-private={true}
            maxLength={6}
          />
          <div className='authWindow__inputPlaceholder twofaField-placeholder'>Authentication Code</div>
          <div className='authWindow__inputPasswordPlaceholder'>......</div>
          {this.showInvalidMark() && (
            <div className='authWindow__inputErrorMessage'>{stores.auth.errorMessage('token')}</div>
          )}
        </div>
      </div>
    );
  }
}

export default TwoFaField;
