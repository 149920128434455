import React from 'react'

import type { ButtonProps as MUIButtonProps } from '@mui/material'
import type { CoinruleColorPalette } from 'design-system'

import { useTheme, Button as MUIButton, Box } from '@mui/material'

const buttonBackgroundStyles = {
  content: '""',
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: '0',
  left: '50%',
  transform: 'translate(-50%, 0)',
  borderRadius: '100px',
  transition: 'width 0.5s ease',
}

export type ButtonColorVariants = keyof CoinruleColorPalette['buttons']
export type ButtonSizeVariants = 'big' | 'small'

export interface ButtonProps extends MUIButtonProps {
  colorVariant?: ButtonColorVariants
  sizeVariant?: ButtonSizeVariants
  zIndex?: number
}

const testId = 'Button'
export const Button = (props: ButtonProps) => {
  const {
    sx,
    children,
    colorVariant = 'primary',
    sizeVariant = 'big',
    zIndex = 20,
    disabled,
    ...otherProps
  } = props

  const theme = useTheme()

  const bgColor = disabled ? theme.palette.coinrule.basic.greyLight : theme.palette.coinrule.buttons[colorVariant]

  return (
    <MUIButton
      data-testid={testId}
      className={testId}
      disabled={disabled}
      sx={{
        width: 291,
        height: 43,
        color: theme.palette.coinrule.basic.white,
        backgroundColor: bgColor,
        boxShadow: 'none',
        // @HACK always bigger than element to have circular button sides
        borderRadius: 999999,
        zIndex: zIndex,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: bgColor,
        },
        '&:before': {
          ...buttonBackgroundStyles,
          boxShadow: '0px -3px 0px 0px #00000040 inset',
          zIndex: zIndex,
        },
        '&:after': {
          ...buttonBackgroundStyles,
          backgroundColor: bgColor,
          zIndex: zIndex - 2,
        },
        '&:hover:before': {
          width: 'calc(100% + 6px)'
        },
        '&:hover:after': {
          width: 'calc(100% + 6px)'
        },
        '& .MuiTouchRipple-root': {
          width: 'calc(100% + 6px)',
          transform: 'translate(-3px, 0)',
        },
        ...(sizeVariant === 'big' ? theme.typography.buttonBig : theme.typography.buttonSmall),
        ...sx,
      }}
      {...otherProps}
    >
      <Box
        sx={{ 
          zIndex: zIndex - 1,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '24px',
          fontWeight: 700,
          textTransform: 'none',
        }}
        component={'span'}
      >
        {children}
      </Box>
    </MUIButton>
  )
}

export default Button
