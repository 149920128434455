import React from 'react';
import { ReactComponent as SearchIcon } from '../../../assets/images/icons/searchIcon.svg';
import { Select } from 'antd';
import './style.scss';

const { Option } = Select;

const CustomSelect = ({
  handleChange,
  options,
  className = '',
  dropdownClassName = '',
  optionProps = {},
  suffixIcon = <SearchIcon width='1.3rem' />,
  ...otherProps
}) => {
  return (
    <Select
      showSearch
      autoFocus
      defaultOpen
      style={{ width: 200 }}
      className={`custom-select ${className}`}
      dropdownClassName={`custom-select__dropdown ${dropdownClassName}`}
      suffixIcon={suffixIcon}
      onChange={handleChange}
      autoClearSearchValue
      {...otherProps}
    >
      {options.map(({ value, label }) => (
        <Option key={value} value={value} className='custom-select__option' {...optionProps}>
          {label}
        </Option>
      ))}
    </Select>
  );
};

export default CustomSelect;
