import React from 'react';

import type { TableCellProps, TableRowProps} from '@mui/material';
import { TableCell, TableRow, useTheme, Box, useMediaQuery } from '@mui/material';

import { 
  IconRuleCreated, 
  IconRulePaused, 
  CollapseableRuleActivityLog, 
  RuleActivityLogData, 
  IconWithLabel, 
  DateFormatted, 
  IconArrowDownOutline,
  IconRuleEnabled,
  IconTradeBuy,
  IconTradeSell,
  IconTradeWait,
  IconTradeConditionMet,
  IconRuleUpdated,
  IconRuleDeleted,
  IconRuleFinished,
  IconRuleAlert,
} from 'design-system';
import type { IRuleActivityLog, RuleActivityLogDataProps } from 'design-system';
import { EActivityLogTradeTypes } from 'design-system/types/EActivityLogTradeTypes';

const DEFAULT_FONT_SIZE = '14px';
const DEFAULT_PADDING_X = '14px';
const DEFAULT_PADDING_Y = '8px';

interface ITableDataCellProps extends TableCellProps {
}

const TableDataCell = ({sx, children, ...otherProps}: ITableDataCellProps) => {
  const theme = useTheme();
  return (
    <TableCell
      sx={{
        paddingX: DEFAULT_PADDING_X,
        paddingY: DEFAULT_PADDING_Y,
        fontSize: DEFAULT_FONT_SIZE,
        color: theme.palette.coinrule.basic.greyDark,
        border: 'none',
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </TableCell>
  )}


const mapTypeToIcon = (type: string) => {
  switch (type) {
    case EActivityLogTradeTypes.RULE_CREATE:
      return IconRuleCreated;

    case EActivityLogTradeTypes.RULE_PAUSED:
      return IconRulePaused;

    case EActivityLogTradeTypes.RULE_ENABLED:
      return IconRuleEnabled;

    case EActivityLogTradeTypes.RULE_UPDATE:
      return IconRuleUpdated;

    case EActivityLogTradeTypes.RULE_DELETE:
    case EActivityLogTradeTypes.RULE_ARCHIVED:
      return IconRuleDeleted;

    case EActivityLogTradeTypes.RULE_FINISHED:
      return IconRuleFinished;

    case EActivityLogTradeTypes.TRADE_BUY:
      return IconTradeBuy;

    case EActivityLogTradeTypes.TRADE_SELL:
      return IconTradeSell;

    case EActivityLogTradeTypes.TRADE_WAIT:
      return IconTradeWait;

    case EActivityLogTradeTypes.CONDITIONS_MET:
    case EActivityLogTradeTypes.CONDITION_MET:
      return IconTradeConditionMet;


      //TODO: include icon for different types of error
    default:
      return IconRuleAlert;
  }
}
export interface TableRowRuleActivityLogProps extends TableRowProps {
  data: IRuleActivityLog
  isOpen: boolean,
  setOpenedActivity: (id: string) => void,
  formatCoinValue: RuleActivityLogDataProps['formatCoinValue'],
}

const testId = 'TableRowRuleActivityLog'
export const TableRowRuleActivityLog = (props: TableRowRuleActivityLogProps) => {

  const theme = useTheme();

  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { greyDark, greyBorderLight } = theme.palette.coinrule.basic;
  
  const { sx, data, isOpen, setOpenedActivity, formatCoinValue, ...otherProps } = props;
  const { id, name = 'Action', type, description, date, tradedCoinPrice, tradedCoinQuantity, } = data;

  const tradeDataExists = !!(tradedCoinPrice && tradedCoinQuantity);

  const Icon = mapTypeToIcon(type);

  return (
    <>
      <TableRow
        data-testid={testId}
        className={testId}
        sx={{
          color: greyDark,
          borderBottom: `1px solid ${greyBorderLight}`,
          width: '100%',
          ...sx,
        }}
        {...otherProps}
      >
        <TableDataCell>
          <IconWithLabel
            label={name}
            labelProps={{ fontSize: '14px' }}
          >
            {
              Icon && (
                <Icon
                  width={"1.3rem"}
                  height={'1.3rem'}
                />
              )
            }
          </IconWithLabel>
        </TableDataCell>

        <TableDataCell>
          <Box
            sx={{
              width: 'fit-content',
              [theme.breakpoints.up('lg')]: {
                marginLeft: '100px'
              }           
            }}
          >
            {description}
          </Box>
        </TableDataCell>

        <TableDataCell>
          <RuleActivityLogData
            formatCoinValue={formatCoinValue}
            {...data}
            sx={{
              display: 'none',
              width: 'fit-content',
              [theme.breakpoints.up('md')]: {
                display: 'flex',
              },
              [theme.breakpoints.up('lg')]: {
                marginLeft: '100px'
              }
            }}
          />
        </TableDataCell>

        <TableDataCell
          align={'right'}
        >
          <Box
            sx={{
              display: 'flex', 
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <DateFormatted
              format={"dd/MM/yy HH:mm"}
              date={date}
              sx={{
                fontSize: DEFAULT_FONT_SIZE,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'none',
                [theme.breakpoints.up('sm')]: {
                  display: 'block',
                }
              }}
            />

            <Box
              role={"button"}
              onClick={() => setOpenedActivity(id)}
              sx={{
                ml: '15px',
                alignItems: 'center', 
                justifyContent: 'center',
                marginTop: '6px',
                cursor: 'pointer',
                display: (isSmDown || tradeDataExists) ? 'flex' : 'none',
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                }
              }}
            >
              <IconArrowDownOutline
                width={"13px"}
                height={'13px'}
              />
            </Box>
          </Box>
        </TableDataCell>
      </TableRow>

      <CollapseableRuleActivityLog
        formatCoinValue={formatCoinValue}
        in={isOpen}
        tradeDataExists={tradeDataExists}
        data={data}
      />
    </>

  )
}

export default TableRowRuleActivityLog
