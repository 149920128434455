import React from 'react'

export interface IconEyeOffProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconEyeOff'
export const IconEyeOff = (props: IconEyeOffProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      width={"1em"}
      height={"1em"}
      viewBox={"0 0 50 50"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...otherProps}
    >
      <g clipPath={"url(#clip0_7293_45675)"}>
        <path
          d={"M25 43.75C9.375 43.75 0.625 26.6667 0.208333 25.8333C-3.10441e-08 25.2083 -3.10441e-08 24.5833 0.208333 23.9583C2.91667 18.9583 6.66667 14.5833 11.4583 11.0417C12.2917 10.2083 13.5417 10.4167 14.375 11.4583C15 12.2917 15 13.75 13.9583 14.375C10 17.2917 6.875 20.8333 4.375 25C6.25 28.3333 13.75 39.5833 25 39.5833C28.9583 39.5833 32.9167 38.125 36.0417 35.625C36.875 35 38.3333 35.2083 38.9583 36.0417C39.5833 36.875 39.375 38.3333 38.5417 38.9583C34.7917 42.0833 30 43.75 25 43.75ZM43.3333 33.75C42.9167 33.75 42.2917 33.5417 42.0833 33.3333C41.25 32.5 41.0417 31.25 41.875 30.4167C43.3333 28.75 44.5833 27.0833 45.625 25.2083C43.75 21.875 36.25 10.625 25 10.625C23.75 10.625 22.2917 10.8333 21.0417 11.0417C20 11.0417 18.9583 10.4167 18.5417 9.375C18.125 8.33333 18.9583 7.08333 20 6.875C21.6667 6.45833 23.3333 6.25 25 6.25C40.625 6.25 49.375 23.3333 49.7917 24.1667C50 24.7917 50 25.4167 49.7917 26.0417C48.5417 28.5417 46.875 30.8333 45 33.125C44.5833 33.5417 43.9583 33.75 43.3333 33.75ZM24.7917 33.5417C22.7083 33.5417 20.625 32.7083 19.1667 31.25C17.5 29.7917 16.6667 27.7083 16.4583 25.4167C16.4583 23.125 17.0833 21.0417 18.75 19.375C18.9583 19.1667 18.9583 19.1667 19.1667 18.9583C20 18.125 21.25 18.125 22.0833 18.9583C22.9167 19.7917 22.9167 21.0417 22.0833 21.875L21.875 22.0833C21.0417 22.9167 20.625 23.9583 20.8333 25C20.8333 26.0417 21.25 27.0833 22.0833 27.9167C23.75 29.5833 26.4583 29.375 27.9167 27.7083C28.75 26.875 30 26.875 30.8333 27.7083C31.6667 28.5417 31.6667 29.7917 30.8333 30.625C29.375 32.5 27.0833 33.5417 24.7917 33.5417Z"}
          fill={"black"}
        />
        <path
          d={"M47.9167 50C47.2917 50 46.875 49.7917 46.4583 49.375L0.625 3.54167C-0.208333 2.70833 -0.208333 1.45833 0.625 0.625C1.45833 -0.208333 2.70833 -0.208333 3.54167 0.625L49.375 46.4583C50.2083 47.2917 50.2083 48.5417 49.375 49.375C48.9583 49.7917 48.5417 50 47.9167 50Z"}
          fill={"black"}
        />
      </g>
      <defs>
        <clipPath id={"clip0_7293_45675"}>
          <rect
            width={50}
            height={50}
            fill={"white"}
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconEyeOff
