import { errorNotification } from './notification';

export const ERROR_MESSAGES = {
  EXISTING_SUBSCRIPTION: 'You already have an existing subscription',
  GENERIC: 'Oops. Something has gone wrong. Please try it again in few seconds.',
  RULE_SNAPSHOT:
    'Oops. Something has gone wrong while getting your snapshots. Please try it again in few seconds.',
  ACCOUNT_UPDATE: 'Oops. Something went wrong while updating your account. Please try again',
  COUPON_ERROR: 'Incompatible coupons loaded. Please logoff and login to clear coupons.',
  TRADING_STAT_ERROR: 'Oops. Something went wrong while getting your trading stats. Please try again',
};

export const getErrorMessage = (error: any) => {
  return error?.response?.data?.message || error?.message || ERROR_MESSAGES.GENERIC;
};

export const handleError = (
  error: any,
  message = 'Something went wrong. Please try again later'
) => {
  console.error(error);

  if (error?.response?.status !== 404) {
    errorNotification(message);
  }

  return error.response;
};
