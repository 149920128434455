import React from 'react';
import {
  ACTION_TYPE,
  CONDITION_TYPE,
  defaultAction,
  OPERATOR_TYPE,
  OPERATOR_TYPES,
  SYMBOL_TYPES,
  TRIGGERS,
} from '../addRule';
import stores from '../index';
import Then from '../../pages/AddRulePage/Then';
import {
  getIndexFirstType,
  findAllIndexesSequenceBeforeByType,
  findIndexSequenceBeforeByType,
} from './utils';

export const handleActionType = (
  index,
  component,
  sequences,
  addRuleStore,
  addRuleParent,
  { idCount }
) => {
  defaultAction.v.s = addRuleStore.tempCurrency;
  const firstActionIndex = getIndexFirstType(sequences, ACTION_TYPE);
  const firstOperatorIndex = getIndexFirstType(sequences, OPERATOR_TYPE);

  const thenIndex = findIndexSequenceBeforeByType(
    sequences,
    OPERATOR_TYPE,
    index,
    OPERATOR_TYPES.THEN
  );
  const parallelIndex = findIndexSequenceBeforeByType(
    sequences,
    OPERATOR_TYPE,
    index,
    OPERATOR_TYPES.PARALLEL
  );
  const waitIndex = findIndexSequenceBeforeByType(
    sequences,
    OPERATOR_TYPE,
    index,
    OPERATOR_TYPES.WAIT
  );
  const previousCondition = findIndexSequenceBeforeByType(sequences, CONDITION_TYPE, index);

  const conditionAfterOperator =
    (thenIndex > -1 && previousCondition > thenIndex) ||
    (parallelIndex > -1 && previousCondition > parallelIndex) ||
    (waitIndex > -1 && previousCondition > waitIndex);

  let defaultNewAction = Object.assign({}, defaultAction);
  if (
    firstActionIndex > 0 &&
    firstOperatorIndex > 0 &&
    firstActionIndex < index &&
    firstOperatorIndex < index &&
    sequences[firstActionIndex] &&
    sequences[firstActionIndex].data.actions[0].at.toLowerCase() === 'buy'
  ) {
    defaultNewAction.at = 'sell';
    defaultNewAction.v.s = '%';
    defaultNewAction.sr = firstActionIndex;
    defaultNewAction.eqt = 'amount';
  }

  if (waitIndex > -1 || thenIndex > -1 || parallelIndex > -1) {
    const allActionIndexes = findAllIndexesSequenceBeforeByType(sequences, ACTION_TYPE, index);

    if (
      allActionIndexes.length > 1 &&
      (!conditionAfterOperator ||
        [SYMBOL_TYPES.RULE, SYMBOL_TYPES.SIGNAL].includes(
          sequences[previousCondition].data.conditions[0].ift
        ))
    ) {
      defaultNewAction.ort = '---';
    }
  }

  component = {
    id: idCount,
    errors: [],
    type: ACTION_TYPE,
    data: {
      actions: [
        {
          ...defaultNewAction,
          ot: stores.info.checkOrderTypeOnExchange(null),
        },
      ],
    },
    component: (
      <Then key={idCount} store={addRuleStore} parent={addRuleParent} id={idCount} partIndex={0} />
    ),
  };

  if (addRuleStore.rule.tr !== TRIGGERS.EVENT) {
    const firstConditionIndex = getIndexFirstType(sequences, CONDITION_TYPE);

    if (
      (addRuleStore.rule.tr === TRIGGERS.EVENT && firstConditionIndex !== -1) ||
      (addRuleStore.rule.tr !== TRIGGERS.EVENT && firstConditionIndex > 0)
    ) {
      component.data.actions[0].ort = 'that_coin';
    } else {
      component.data.actions[0].ort = 'base';
      component.data.actions[0].b = ['---'];
    }

    if (addRuleStore.rule.tr === TRIGGERS.DIRECT_ORDER) {
      component.data.actions[0].ort = 'base';
      component.data.actions[0].b = ['---'];
    }
  }

  return component;
};
