import React from 'react'

export interface ImageExchangesLogosInvertedSpriteProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'ImageExchangesLogosInvertedSprite'
export const ImageExchangesLogosInvertedSprite = (props: ImageExchangesLogosInvertedSpriteProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      xmlns={"http://www.w3.org/2000/svg"}
      x={0}
      y={0}
      viewBox={"0 0 629 37"}
      xmlSpace={"preserve"}
      {...otherProps}
    >
      <path
        d={"M279.4 26.3c1.3 0 2.7-.2 3.8-.6 1.3-.5 2.2-1.4 2.8-2.5.9-1.6.9-3.8.2-5.3-.6-1.3-1.7-2-3-2.5.8-.5 1.6-.9 2-1.7.8-1.1.9-2.5.8-3.8-.2-1.1-.8-2-1.6-2.8-1.3-1.1-2.8-1.7-4.4-1.7-4-.3-7.9-.3-12-.3v2.8c.5 0 1.1.2 1.6.3.9.5 1.6 1.4 1.6 2.5 0 .8-.3 1.7-.8 2.4-.6.6-1.4 1.1-2.4.9v2.5c.6 0 1.3.2 1.7.5.9.8 1.4 1.9 1.4 3 0 .6 0 1.1-.3 1.6-.5 1.1-1.6 1.9-2.8 1.9v2.8h11.4Zm3.2-5.5c-.2.5-.3.9-.6 1.4-.3.3-.8.6-1.3.8-.8.3-1.4.3-2.2.3h-4.2v-6.4h3.6c.8 0 1.6 0 2.4.2.8.2 1.6.6 2 1.4.4.4.4 1.3.3 2.3Zm-8.5-12.7h4.5c.9 0 1.9.3 2.7 1.1.6.6.8 1.6.6 2.5-.2 1.1-1.1 1.9-2 2.2-.6.2-1.3.2-1.9.2h-3.9v-6Z"}
        fill={"#fff"}
      />
      <path
        d={"M279.6 30.8H268v3h18.8v-3h-7.2Z"}
        fill={"#139A47"}
      />
      <path
        d={"m161.4 11.1-1.4 5.6-10 1.3 11.4-6.9Z"}
        fill={"#57BAE5"}
      />
      <path
        d={"m164.1 33.9 2.5-10.2-3.3-1.4.8 11.6Z"}
        fill={"#005472"}
      />
      <path
        d={"m160 16.7-2.7 5.3-7.4-4.1 10.1-1.2Z"}
        fill={"#34AFE4"}
      />
      <path
        d={"m164.1 33.9-.6-11.6-6.1-.3 6.7 11.9Z"}
        fill={"#0076A7"}
      />
      <path
        d={"m160 16.7 3.4 5.5-6.1-.2 2.7-5.3Z"}
        fill={"#009DE2"}
      />
      <path
        d={"m161.4 11.1 8.3.9-9.7 4.7 1.4-5.6Zm17.3-2.6-5.2 6 .3-5.5 4.9-.5Zm3-3.4-2.8 3.4 2.2.3.6-3.7Z"}
        fill={"#7ACDF2"}
      />
      <path
        d={"m175.9 11.7 5-2.8-2.4-.3-2.6 3.1Z"}
        fill={"#00ABE7"}
      />
      <path
        d={"m166.6 23.6.3-6.9-3.4 5.6 3.1 1.3Z"}
        fill={"#0076A7"}
      />
      <path
        d={"m166.9 16.7-3.4 5.6-3.4-5.6h6.8Z"}
        fill={"#008DBE"}
      />
      <path
        d={"m169.8 12.2-2.8 4.5h-7l9.8-4.5Z"}
        fill={"#57BAE5"}
      />
      <path
        d={"m166.9 16.7-.3 6.9 6.3 6.4-6-13.3Z"}
        fill={"#00698D"}
      />
      <path
        d={"m176.3 26.4-3.3-8.3V30l3.3-3.6Zm-2.8-11.9-.6 3.6-6.1-1.4 6.7-2.2Z"}
        fill={"#09A1CB"}
      />
      <path
        d={"m176.7 19.5-3.8-1.3.6-3.8 3.2 5.1Z"}
        fill={"#8BDAFF"}
      />
      <path
        d={"m177.9 26.3-4.1-6.4h2.4l1.7 6.4Z"}
        fill={"#7ACDF2"}
      />
      <path
        d={"m170.5 10.7 3 3.8.3-5.5-3.3 1.7Z"}
        fill={"#57BAE5"}
      />
      <path
        d={"m172.9 18.3-6.1-1.6 6.1 13.3V18.3Z"}
        fill={"#008DBE"}
      />
      <path
        d={"m176.3 26.4 1.3.8-4.5 2.8 3.2-3.6Z"}
        fill={"#0076A7"}
      />
      <path
        d={"m177.9 26.1-5-8 3.3 8.3 1.7-.3Z"}
        fill={"#57BAE5"}
      />
      <path
        d={"m170.5 10.7-3.6 6 6.6-2.2-3-3.8Z"}
        fill={"#7ACDF2"}
      />
      <path
        d={"m127.8 16-2.2 2.2-.3.3-.6.6 3.4 3.4 2.7-2.7c-1.3-1-2.4-2.3-3-3.8Z"}
        fill={"#F3BA2F"}
      />
      <path
        d={"M137.6 19.1c4.4 0 8-3.6 8-8s-3.6-8-8-8-8 3.6-8 8c.1 4.4 3.6 8 8 8Z"}
        fill={"#F0F0F0"}
      />
      <path
        d={"M137.2 11.1h8.3c0-.8-.2-1.4-.3-2h-8v2Zm0-4.1h7.2c-.5-.8-1.1-1.6-1.9-2h-5.3v2Zm.4 12.1c1.9 0 3.6-.6 4.9-1.7h-9.9c1.4 1.1 3.2 1.7 5 1.7Zm-6.8-3.8h13.6c.3-.6.6-1.4.8-2.2h-15.4c.3.8.6 1.6 1 2.2Z"}
        fill={"#D80027"}
      />
      <path
        d={"M133.3 4.5h.8l-.6.5.3.8-.8-.5-.6.5.2-.6c-.6.5-1.1 1.1-1.6 1.7h.2l-.5.3c0 .2-.2.2-.2.3l.2.6-.3-.3c0 .2 0 .3-.2.6l.2.6h.8l-.6.5.3.8-.6-.5-.5.3v.9h8V3c-1.7.3-3.3.7-4.5 1.5Zm.3 5.8-.6-.5-.6.5.3-.8-.9-.4h.8l.3-.8.3.8h.8l-.6.5.2.7Zm-.2-3.1.3.8-.6-.5-.6.5.3-.8-.9-.5h.8l.3-.8.3.8h.8l-.7.5Zm3.2 3.1-.6-.5-.6.5.3-.8-.7-.4h.8l.3-.8.3.8h.8l-.6.5v.7Zm-.4-3.1.3.9-.6-.5-.6.5.3-.8-.6-.4h.8l.3-.8.3.8h.8l-1 .3Zm0-2.2.3.8-.8-.5-.6.5.3-.8-.6-.5h.8l.2-.8.3.8h.8l-.7.5Z"}
        fill={"#0052B4"}
      />
      <path
        d={"M126.7 12.4v-1.1c0-2.4.6-4.4 1.9-6.3l-.6-.6-9.1 9.1 3.3 3.4 4.5-4.5Zm-10.4 3.5-3.3 3.3 3.3 3.3 3.3-3.3-3.3-3.3Zm22.6 6.2.5.5.6-.6c-.3.1-.6.1-1.1.1Zm-11 5.3-5.8-5.8-3.3 3.4 9.1 9.1 9.2-9.3-3.4-3.3-5.8 5.9Z"}
        fill={"#F3BA2F"}
      />
      <path
        d={"M67 23.63V8H51.385v4.62h2.846v-1.77h9.902v9.91h-3.538v-6.39H45V30h15.615v-4.62H57.77v1.77h-9.922v-9.91h3.538v6.39H67Zm-12.769-6.39h3.538v3.54H54.23v-3.54Z"}
        fill={"#1CBCFF"}
      />
      <path
        d={"M312.3 8.1h-5.7L301 30.7h5.6l3.5-14.2h5.6l2.1-8.5-5.5.1Z"}
        fill={"#FF0201"}
      />
      <path
        d={"m323.6 8.1-2.1 8.5-1.4 5.7h-5.6l-2.1 8.5h11.3l3.5-14.2 2.1-8.5h-5.7Z"}
        fill={"#125BFF"}
      />
      <path
        d={"M348.4 7c-.2 0-.5.2-.6.3-.2.2-.2.3-.2.5v3.4c0 .3.2.6.3.8.2.2.5.3 1 .3h2.4c.3 0 .6.2 1 .3.2.2.3.5.3.8v3.7c0 .3.2.6.3 1 .2.2.5.3 1 .3h3.6c.6 0 1.3.5 1.3 1.3v2.4c0 .3.2.6.3 1 .2.2.5.3 1 .3h3.3c.2 0 .5-.2.6-.2.2-.2.2-.3.2-.6V7.8c0-.2-.2-.5-.2-.6-.2-.2-.4-.2-.7-.2h-14.9Zm-1 15.8V15c0-.3-.2-.6-.3-.8-.2-.3-.5-.5-1-.5h-4.3c-.2 0-.5.2-.6.3-.2.2-.2.3-.2.5v14.6c0 .5.3.8.8.8h14.9c.2 0 .5-.2.6-.2.2-.2.2-.3.2-.6v-4c0-.3-.2-.6-.3-.8-.2-.2-.5-.3-1-.3h-7.6c-.5 0-1.2-.4-1.2-1.2Z"}
        fill={"#fff"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={"m384.4 16.5 5.3-5.3 5.3 5.3 3.1-3.1-8.4-8.4-8.4 8.4 3.1 3.1Zm-2.2 2.2-3.1-3.1-3.1 3.1 3.1 3.1 3.1-3.1Zm7.5 7.5-5.3-5.3-3.1 3.1 8.4 8.4 8.4-8.4-3.1-3.1-5.3 5.3Zm10.6-4.4 3.1-3.1-3.1-3.1-3.1 3.1 3.1 3.1Zm-7.5-3.1-3.1 3.1-3.1-3.1.5-.5.3-.3 2.3-2.3 3.1 3.1Z"}
        fill={"#F3BA2F"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={"M422.9 31.8H419V5.4l3.9-2.4v11.6c1.1-1.3 3-2.2 5.2-2.2 5.3 0 8.8 3.8 8.8 9.8 0 6-3.5 9.8-8.8 9.8-2.4 0-4.1-1-5.2-2.2v2Zm-.1-9.6c0 3.3 2 5.7 5.1 5.7 3.1 0 5.2-2.4 5.2-5.7 0-3.3-2.1-5.7-5.2-5.7-3 0-5.1 2.4-5.1 5.7Z"}
        fill={"#fff"}
      />
      <path
        d={"M465.3 14.7c0-.6.4-.9 1-.9.5 0 .9.4.9.9 0 .6-.4.9-.9.9-.6 0-1-.4-1-.9Zm-3-8.2v1.3h1.2V6.5c.4-.2.7-.7.7-1.2 0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3 0 .6.3 1 .7 1.2Zm-2.7 9.1c.6 0 1-.4 1-.9 0-.6-.5-.9-1-.9s-.9.4-.9.9.4.9.9.9Zm3.4 15c1.4 0 2.6-1 3.1-2.2h-6.3c.5 1.3 1.7 2.2 3.2 2.2Zm-10-3.9c0-.5.3-1 .7-1.2v-5.4c0-1.1.8-2 1.9-2.1v-2.6s.3-4.8 4.2-6.3c1.2-.5 2.2-.6 3.2-.7 1 .1 2 .2 3.2.7 3.9 1.5 4.2 6.3 4.2 6.3V18c1.1.1 1.9 1 1.9 2v5.5c.4.2.7.6.7 1.1 0 .7-.5 1.3-1.3 1.3s-1.3-.6-1.3-1.3c0-.5.3-.9.7-1.1v-3.6c-.3.1-.5.2-.8.2v2c-.1 2.1-1.8 3.8-3.9 4v.1h-6.9v-.1c-2.1-.3-3.7-1.9-3.9-4v-2c-.3 0-.5-.1-.8-.2v3.6c.5.2.7.6.7 1.1 0 .7-.6 1.3-1.3 1.3-.7.1-1.2-.5-1.2-1.2Zm12.1-6.7c0-.4-.3-.6-.6-.6s-.6.3-.6.6c0 .4.3.6.6.6s.6-.3.6-.6Zm1.5 0c0-.4-.3-.6-.6-.6s-.6.3-.6.6c0 .4.3.6.6.6.4 0 .6-.3.6-.6Zm1.5 0c0-.4-.3-.6-.6-.6s-.6.3-.6.6c0 .4.3.6.6.6.4 0 .6-.3.6-.6Zm-10.2-5.1c0 1.6 1.3 1.7 1.3 1.7.4.1 1.4.1 1.8 0 .4 0 1.8-.2 2.1-.2.3 0 1.7.1 2.1.2.4.1 1.4.1 1.8 0 0 0 1.3-.1 1.3-1.7 0 0 0-1.8-1.3-2.9-1.3-1.1-3.1-1.3-3.8-1.3h-.3c-.7 0-2.6.3-3.8 1.3-1.2 1-1.2 2.9-1.2 2.9Z"}
        fill={"#fff"}
      />
      <path
        d={"M488.9 28.308c1.1 1.2 7.2 6.8 16.7.3 6.9-5.4 6.7-17 6.1-20.5-.2.5-8.2 18-22.8 20.2Zm22.8-20.2c-.1 0-9-1.2-17.7 4.4-5.5 3.5-6.2 8.6-5.9 12.1 12.7-1.5 23.3-16.1 23.6-16.5Z"}
        fill={"#97C554"}
      />
      <path
        d={"M511.7 8.108c-.1 0-9-1.2-17.7 4.4-5.5 3.5-6.2 8.6-5.9 12.1 12.7-1.5 23.3-16.1 23.6-16.5Z"}
        fill={"#709B30"}
      />
      <path
        d={"M203.9 7.043c2.3-.2 4.7.3 6.9 1.4 3.1 1.5 5.6 4.3 6.8 7.6.5 1.4.8 3 .8 4.5v5.7c0 .2 0 .5-.1.7-.1.6-.5 1.1-1.1 1.3-.4.2-.8.2-1.2.1-.9-.2-1.5-1-1.5-1.8v-5.3c0-.5 0-1-.3-1.5-.4-.8-1.5-1.2-2.4-.9-.7.3-1.3 1-1.3 1.8v5.4c0 .3 0 .7-.1 1-.2.6-.7 1.1-1.3 1.3-.8.2-1.8-.1-2.2-.9-.3-.5-.3-1-.3-1.5v-5.3c0-.9-.8-1.7-1.7-1.8-.6-.1-1.2.1-1.6.5-.4.4-.6.9-.6 1.4v5.2c0 .4 0 .9-.1 1.3-.3.7-1 1.3-1.8 1.3s-1.6-.5-1.8-1.3c-.1-.4-.1-.8-.1-1.2v-5.4c0-.9-.7-1.7-1.6-1.8-.8-.2-1.7.2-2.1.9-.2.3-.3.6-.3 1v5.8c0 .6-.3 1.1-.7 1.5-.7.6-1.7.6-2.4.1-.5-.3-.8-.9-.8-1.5v-5.9c0-2.2.6-4.4 1.6-6.4.9-1.6 2.1-3 3.5-4.2 2.4-1.9 5.1-3 7.8-3.1Z"}
        fill={"#fff"}
      />
      <path
        d={"M549.9 18.6c-.1-3.6-1.8-5.7-3.5-6.8-1.4-.9-3-1.4-4.6-1.3h-.4v-4h-3.9v3.9h-4.2V6.5h-3.9v3.9h-4.1v4.1h4v8h-4v3.9h4.1v4.1h3.9v-4.1h4.2v4.1h3.9v-4.1h.7c1.4 0 2.7-.3 3.9-1 4-2.2 3.9-6.8 3.9-6.8Zm-4.4 1.8c-.3.5-.6.9-1 1.3-.7.5-1.5.8-2.4.8h-8.8v-8h8.8c.4 0 .8.1 1.2.2.9.3 1.7.9 2.2 1.8.6 1.2.6 2.7 0 3.9Z"}
        fill={"#2DAE9A"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={"m571.023 19 7.108 7.127 4.454-4.465c.77-.687 2.055-.687 2.826.086.771.773.771 1.975.085 2.833l-5.995 5.84a2.012 2.012 0 0 1-2.826 0l-8.564-8.587v5.152c0 1.116-.942 2.06-2.056 2.06-1.113 0-2.055-.944-2.055-2.06V11.1c0-1.116.942-2.06 2.055-2.06 1.114 0 2.056.944 2.056 2.06v5.066l8.564-8.586a2.012 2.012 0 0 1 2.826 0l5.91 5.925c.685.772.685 2.06-.086 2.833-.771.773-1.97.773-2.826.086l-4.454-4.465L571.023 19Zm7.108-1.975c-.856 0-1.541.515-1.884 1.288-.343.773-.171 1.632.428 2.233.6.6 1.456.772 2.227.429.771-.344 1.285-1.03 1.285-1.89 0-.514-.172-1.03-.6-1.459a2.003 2.003 0 0 0-1.456-.601Z"}
        fill={"#fff"}
      />
      <path
        d={"M83 9.466c0-.255.208-.463.463-.463h5.955c.255 0 .464.208.464.463v5.955a.465.465 0 0 1-.464.466h-5.955a.465.465 0 0 1-.463-.466V9.466Zm6.882 19.566a.465.465 0 0 1-.464.463h-5.955a.465.465 0 0 1-.463-.463v-5.955c0-.258.208-.466.463-.466h5.955c.255 0 .464.208.464.466v5.955Zm6.412-6.342h-5.955a.468.468 0 0 1-.466-.466v-5.955c0-.255.208-.467.466-.467h5.955c.255 0 .466.209.466.467v5.955a.468.468 0 0 1-.466.466Zm7.348 6.342a.465.465 0 0 1-.467.463h-5.954a.465.465 0 0 1-.464-.463v-5.955c0-.258.209-.466.464-.466h5.954c.256 0 .467.208.467.466v5.955Zm0-13.614a.466.466 0 0 1-.467.466h-5.954a.465.465 0 0 1-.464-.466V9.463c0-.255.209-.463.464-.463h5.954c.256 0 .467.208.467.463v5.955Z"}
        fill={"#fff"}
      />
      <path
        d={"M27.963 13.489v5.466l-4.736-2.736-4.73-2.742 4.744-2.725 4.736-2.73-.014 5.467Z"}
        fill={"#072B38"}
      />
      <path
        d={"M18.486 18.944v5.467l-4.736-2.737-4.73-2.742 4.736-2.725 4.735-2.73-.005 5.467Z"}
        fill={"#144556"}
      />
      <path
        d={"M9.024 13.466V8l4.736 2.737 4.731 2.735-4.736 2.735-4.736 2.725.005-5.466Z"}
        fill={"#41D9BF"}
      />
      <path
        d={"m9.009 24.4.012-5.468 4.729 2.742 4.73 2.737-4.735 2.73L9 29.866l.009-5.467Z"}
        fill={"#072B38"}
      />
      <path
        d={"m23.222 16.219-4.731-2.742v10.934l4.72 2.74 4.73 2.736V24.42l.012-5.467-4.731-2.735Z"}
        fill={"#41D9BF"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={"M242.054 5h-1.209c-2.253.14-4.445.786-6.372 1.971a13.576 13.576 0 0 0-5.281 5.97c-.72 1.578-1.096 3.279-1.192 5.007v1.206c.116 1.582.423 3.128 1.058 4.587a13.55 13.55 0 0 0 5.889 6.567c1.837 1.03 3.902 1.582 6.004 1.692h1.199c1.584-.116 3.13-.423 4.59-1.058a13.541 13.541 0 0 0 6.567-5.889c1.032-1.837 1.584-3.9 1.693-6.001v-1.207c-.115-1.581-.423-3.126-1.057-4.586a13.544 13.544 0 0 0-5.889-6.566c-1.836-1.031-3.9-1.582-6-1.693Zm5.115 5.886a9.431 9.431 0 0 0-5.366-1.873 9.443 9.443 0 0 0-6.055 1.94c-1.413 1.064-2.507 2.56-3.135 4.21a9.533 9.533 0 0 0-.291 5.749 9.464 9.464 0 0 0 3.778 5.391 9.419 9.419 0 0 0 5.4 1.688 9.424 9.424 0 0 0 6.003-2.136c1.986-1.607 3.248-4.022 3.465-6.566a1706.946 1706.946 0 0 0-4.77-.002c-.222.974-.616 1.865-1.348 2.563-1.287 1.339-3.345 1.755-5.066 1.073-1.975-.746-3.255-2.852-2.998-4.952.196-1.979 1.741-3.683 3.678-4.103 1.561-.353 3.271.123 4.387 1.282.713.692 1.155 1.592 1.338 2.564a730.66 730.66 0 0 1 2.387-.002c.796 0 1.592.001 2.388-.001a9.49 9.49 0 0 0-3.795-6.825Z"}
        fill={"#fff"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={"m605.4 17.5 5.3-5.3 5.3 5.3 3.1-3.1-8.4-8.4-8.4 8.4 3.1 3.1Zm-2.2 2.2-3.1-3.1-3.1 3.1 3.1 3.1 3.1-3.1Zm7.5 7.5-5.3-5.3-3.1 3.1 8.4 8.4 8.4-8.4-3.1-3.1-5.3 5.3Zm10.6-4.4 3.1-3.1-3.1-3.1-3.1 3.1 3.1 3.1Zm-7.5-3.1-3.1 3.1-3.1-3.1.5-.5.3-.3 2.3-2.3 3.1 3.1Z"}
        fill={"#F3BA2F"}
      />
    </svg>
  )
}

export default ImageExchangesLogosInvertedSprite
