import React from 'react';
import { observer } from 'mobx-react';

import stores from '../../../stores/index';
import Help, { Container } from '../../../components/Help/index';
import { TRIGGERS, OPERATOR_TYPES, CONDITION_TYPE, OPERATOR_TYPE } from '../../../stores/addRule';

import './style.scss';
import lightning from '../../../assets/images/lightning.svg';
import Popover from '../../../components/Popover';
import RemoveSequence from '../../../components/Sequences/RemoveSequence';
import AddButtonsBlock from '../AddButtonsBlock';
import ConditionItem from './ConditionItem';
import { EXCHANGES_TYPE } from '../../../constants/exchanges';

@observer
class Condition extends React.Component {
  isLeverage() {
    const exchangeType = stores.exchangeInfo.exchangeType[this.props.store.rule.ex];
    return exchangeType && exchangeType === EXCHANGES_TYPE.LEVERAGE;
  }

  checkIfExchangeLoaded() {
    return (
      this.props.store.rule.ex &&
      stores.userInfo.balances[this.props.store.rule.ex] &&
      stores.exchangeInfo.assets[this.props.store.rule.ex] &&
      ((this.isLeverage() && stores.exchangeInfo.instruments[this.props.store.rule.ex]) ||
        stores.exchangeInfo.markets[this.props.store.rule.ex])
    );
  }

  renderTriggerTypeSelect() {
    let textEvent = 'Select Condition';
    const cls = 'newRuleTitleTabs';

    return (
      <Popover placement='top' text={textEvent}>
        <div
          className={`${cls}__tab
        									${cls}__tab_iconLightning
        									${cls}__tab_active
                          ${
                            this.props.store.isQuickEdit() &&
                            'disabled-button disabled-button_radius'
                          }`}>
          {TRIGGERS.EVENT}
        </div>
      </Popover>
    );
  }

  renderConditionsWrapper(sequenceIndex, typeInclude, removeBut) {
    if (!this.props.store.seqInit.sequences[sequenceIndex]) {
      return null;
    }

    return (
      <div className='add-content-wrapper'>
        {this.props.store.seqInit.sequences[sequenceIndex].data.conditions?.map((condition, i) => {
          return (
            <ConditionItem
              key={i}
              sequenceIndex={sequenceIndex}
              conditionId={this.props.id}
              index={i}
              item={condition}
              store={this.props.store}
              parent={this}
              helpIcon={this.getHelpIcon(sequenceIndex, typeInclude)}
              removeBut={removeBut}
            />
          );
        })}
      </div>
    );
  }

  renderTriggerTitle(index) {
    let triggerTitle = 'If';

    let previousElement = this.props.store.seqInit.getPreviousElement(index);
    if (
      previousElement &&
      previousElement.type === OPERATOR_TYPES &&
      previousElement.data.operators[0].t === OPERATOR_TYPES.WAIT
    ) {
      triggerTitle = 'And Then';
    }

    return triggerTitle;
  }

  getHelpIcon(sequenceIndex, typeInclude) {
    let help = '';
    if (typeInclude === OPERATOR_TYPE) {
      help = (
        <Container className={`helpTooltip`}>
          <div className={`helpTooltip__title`}>Select An Operator</div>
          <div>
            <p>Use an operator to connect blocks. </p>
          </div>
          <div>
            <p>
              Conditions and Actions that follow "THEN" and "WAIT" operators will trigger in
              sequence after the previous action has been executed.
            </p>
          </div>
          <div>
            <p>
              "Any Time" sets following actions and conditions to execute as soon as triggered in
              parallel to any other condition or action in the rule.
            </p>
          </div>
          <div>
            <p>"Do Not" will exclude certain coins from being sold or bought.</p>
          </div>
          <div>
            <p>
              The "Else" trigger lets you set up what will happen if your condition has not been
              met.
            </p>
          </div>
        </Container>
      );
    } else if (this.props.store.rule.tr === TRIGGERS.EVENT && sequenceIndex === 0) {
      help = (
        <Container className={`helpTooltip`}>
          <div className={`helpTooltip__title`}>Choose a Trigger</div>
          <div>
            <p>
              Choose "Event" to build a rule based on a condition such as price decreasing /
              increasing or a certain price being reached.
            </p>
          </div>
          <div>
            <p>
              The "Time" option allows you to schedule your trades around a specific time interval.
              Eg. if you select 'Every 12 Hours', the same order will be sent every 12 hours to the
              market.
            </p>
          </div>
          <div>“Direct Orders” will be executed on the market immediately.</div>
        </Container>
      );
    } else {
      help = (
        <Container className={`helpTooltip`}>
          <div className={`helpTooltip__title`}>Define A Condition</div>
          <div>
            <p>
              "That coin" refers to the coin from the previous block. You can also select a specific
              coin, any coin or any coin in your portfolio.
            </p>
          </div>
          <div>
            <p>
              Set up a condition that will trigger your rule. You can abbreviate numbers using 1K
              for 1,000, 1M for 1,000,000 and 1B for 1,000,000,000.
            </p>
          </div>
        </Container>
      );
    }

    return (
      <div className={`newRuleFormSection__helpIcon`}>
        <Help message={help} />
      </div>
    );
  }

  render() {
    const { store } = this.props;
    const sequenceIndex = store.seqInit.getIndexById(this.props.id);
    let lineColorClass = '_orange';
    let typeInclude = '';
    let otherCss = '';

    const removeBut = (
      <RemoveSequence
        canBeRemoved={store.canSequenceBeRemoved(sequenceIndex)}
        onClick={() => store.seqInit.removeFromSequence(sequenceIndex)}
      />
    );

    let showFullConditionContext = sequenceIndex > 0;

    if (sequenceIndex > 0) {
      const previousElement = this.props.store.seqInit.getPreviousElement(sequenceIndex);

      if (this.props.store.seqInit.sequences[sequenceIndex].includeBlock) {
        if (this.props.store.seqInit.sequences[sequenceIndex].includeBlock === CONDITION_TYPE) {
          otherCss += ' connect-next-block';
          showFullConditionContext = false;
        } else if (
          this.props.store.seqInit.sequences[sequenceIndex].includeBlock === OPERATOR_TYPE
        ) {
          otherCss += ' connect-next-block';
          typeInclude = OPERATOR_TYPE;
          lineColorClass = '_purple';
          showFullConditionContext = false;
        }
      } else if (previousElement.type === CONDITION_TYPE) {
        otherCss += ' connect-next-block';
        showFullConditionContext = false;
      }
    } else if (this.props.store.rule.tr !== TRIGGERS.EVENT) {
      showFullConditionContext = true;
    } else if (sequenceIndex === 0) {
      otherCss += ' connect-next-block';
    }

    return (
      <div
        className={`trigger
			                  show-section
			                  show-section-end
			                  ${!this.props.store.rule.ex && 'show-section_unavailable'}
			                  ${otherCss}`}
        data-sequence={this.props.id}
        id={`sequence${this.props.id === 0 ? '' : this.props.id}`}
        onClick={() => stores.addRule.setSFPpendingSection(this.props.id)}>
        <div className='rule-settings-block'>
          <div className={'rsb-line rsb-line' + lineColorClass}> </div>
          <div className='rule-settings'>
            {showFullConditionContext ? (
              <Popover text='Select Event or Time Trigger'>
                <div className='title-wrapper'>
                  <img src={lightning} alt='Trigger' />
                  <div className='part-title title-orange'>
                    {this.renderTriggerTitle(sequenceIndex)}
                  </div>
                </div>
              </Popover>
            ) : null}
            <div className='settings'>
              {showFullConditionContext ? (
                <div className={`newRuleTitleTabs newRuleTitleTabs_yellow`}>
                  {this.renderTriggerTypeSelect(sequenceIndex)}
                </div>
              ) : null}
              {this.renderConditionsWrapper(sequenceIndex, typeInclude, removeBut)}
            </div>
          </div>
        </div>

        <AddButtonsBlock
          store={this.props.store}
          id={this.props.id}
          open={sequenceIndex === 0}
          sequenceIndex={sequenceIndex}
        />
      </div>
    );
  }
}

export default Condition;
