import React from 'react'

import { Box, useTheme } from '@mui/material'

import type { TExchangeUid, AutocompleteProps } from 'design-system';

import { BadgeOutlinedSuccess, Autocomplete, TextField, ExchangeLogoSpriteWithName, IconChevronDropdown, ExchangeLogoSprite, Spacer } from 'design-system'

export type ExchangeOption = {
  uid: TExchangeUid
  name: string,
  isNew?: boolean
}

export interface DropdownExchangesProps extends AutocompleteProps<ExchangeOption, false, true, false, 'div'> {
  label?: string,
  exchanges: ExchangeOption[]
}

const testId = 'DropdownExchanges'
export const DropdownExchanges = (props: DropdownExchangesProps) => {
  const {
    sx,
    label = 'Choose your exchange',
    exchanges,
    value,
    onBlur,
    onFocus,
    ...otherProps
  } = props

  // we need to keep track of the focus state to display exchange logo in the input when user is not typing
  const [isFocused, setFocused] = React.useState<boolean>(false)

  const theme = useTheme()

  return (
    <Autocomplete<ExchangeOption, false, true, false>
      data-testid={testId}
      className={testId}
      sx={{
        ...sx
      }}

      // these props can be overwritten by props from parent
      popupIcon={
        <IconChevronDropdown
          width={'12px'}
          height={'12px'}
          fill={theme.palette.coinrule.basic.grey}
        />
      }
      getOptionLabel={(option) => option.name}
      openOnFocus={true}
      renderOption={(props, option) => (
        <Box
          component={'li'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '5px 12px',
            transition: 'background 0.3s ease',
            cursor: 'pointer',
            backgroundColor: option === value ? `${theme.palette.coinrule.basic.greyBlueSuperLight} !important` : 'transparent',
            ':hover': {
              backgroundColor: `${theme.palette.coinrule.highlights.optionBkg} !important`
            },
            '&.Mui-focusVisible': {
              backgroundColor: `${theme.palette.coinrule.highlights.optionBkg} !important`
            },
          }}
          {...props}
        >
          <ExchangeLogoSpriteWithName 
            exchange={option.uid}
            exchangeName={option.name}
            TypographyProps={{
              sx: {
                fontWeight: 400,
                fontSize: '14px'
              }
            }}
          />
          {
            option.isNew ?
              <>
                <Spacer x={10} />
                <BadgeOutlinedSuccess>
                  NEW
                </BadgeOutlinedSuccess>
              </>
              :
              null
          }
        </Box>
      )}
      ListboxProps={{
        style: {
          boxShadow: '0 2px 8px rgb(0 0 0 / 15%) !important'
        }
      }}
      disableClearable={true}

      {...otherProps}

      // these props should not be overwritten entirely
      value={value}
      options={exchanges}
      onBlur={(...params) => {
        setFocused(false)
        onBlur?.(...params)
      }}
      onFocus={(...params) => {
        setFocused(true)
        onFocus?.(...params)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size={'small'}
          sx={{
            '& .MuiInputBase-input': {
              height: '29px',
              color: theme.palette.coinrule.basic.black,
            },
            '& .MuiAutocomplete-endAdornment': {
              top: 'calc(50% - 12px)',
              right: '15px !important'
            },
            '& .MuiAutocomplete-endAdornment button': {
              transition: '0.2s ease',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderRadius: '4px',
                borderWidth: '1px',
                borderColor: theme.palette.coinrule.basic.greyBorder,
              },
              '&:hover fieldset': {
                borderColor: theme.palette.coinrule.highlights.selectBorder,
                boxShadow: `0 0 0 2px ${theme.palette.coinrule.highlights.selectShadow}`,
              },
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.coinrule.highlights.selectBorder,
                boxShadow: `0 0 0 2px ${theme.palette.coinrule.highlights.selectShadow}`,
              },
            },
            // @FIXME this style is provided only to overwrite antd3 global legend styling that was borking the UI
            '& .MuiOutlinedInput-notchedOutline legend': {
              width: 'auto'
            }
          }}
          placeholder={label}
          InputProps={{
            ...params.InputProps,
            startAdornment: !isFocused && value && (
              <ExchangeLogoSprite 
                exchange={value.uid}
              />
            ),
          }}
        />
      )}
    />
  )
}

export default DropdownExchanges
