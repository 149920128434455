import React from 'react'

export interface IconRuleDeletedProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconRuleDeleted'
export const IconRuleDeleted = (props: IconRuleDeletedProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      xmlns={"http://www.w3.org/2000/svg"}
      x={0}
      y={0}
      viewBox={"0 0 23 23"}
      enableBackground= {"new 0 0 23 23"}
      xmlSpace={"preserve"}
      width={"1em"}
      height={"1em"}
      {...otherProps}
    >
      <path
        fill={"#05b4bc"}
        d={"M8.9 14.7h5.6c.5 0 .8-.4.8-.8s-.4-.9-.8-.9H8.9c-.5 0-.9.4-.9.8s.4.9.9.9z"}
      />
      <path
        fill={"#05b4bc"}
        d={"M19.1 3.7H4.2c-1 0-1.8.8-1.8 1.8v2.8c0 .6.3 1.2.9 1.5v7.7c0 1 .8 1.8 1.8 1.8h13c1 0 1.8-.8 1.8-1.8V9.8c.5-.3.9-.9.9-1.5V5.5c.1-1-.7-1.8-1.7-1.8zm-.9 6.3v7.6H5.1V10h13.1zm1-4.6v2.9h-15V5.4h15z"}
      />
    </svg>
  )
}

export default IconRuleDeleted
