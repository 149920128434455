import { Row, Col } from 'antd';
import React, { useState, useEffect } from 'react';
import CustomSelect from '../CustomSelect';
import { formatDropdownOptions } from '../CustomSelect/utils';
import './style.scss';
import {
  getDataLocalStorage,
  setDataLocalStorage,
  initialWatchList,
  filterWatchListFromOptions,
} from 'helpers/utils';
import { WATCH_LIST } from 'helpers/constants';
import MiniChart from '../MiniChart';
import { ReactComponent as DropdownIcon } from '../../../assets/images/icons/dropdown.svg';

const WatchList = ({ exchangeCoins }) => {
  const [watchList, setWatchList] = useState([]);

  useEffect(() => {
    const updateWatchList = () => {
      // Get watchList from localStorage
      // and setWatchList with if it's existing
      let watchListFromStorage = getDataLocalStorage(WATCH_LIST);

      if (watchListFromStorage?.length) {
        return setWatchList(watchListFromStorage);
      }

      // if there is no watchList in localStorage,
      // save dummy watchList to localStorag
      setDataLocalStorage(WATCH_LIST, initialWatchList);

      // Update watchList state with it
      watchListFromStorage = getDataLocalStorage(WATCH_LIST);
      setWatchList(watchListFromStorage);
    };

    updateWatchList();
  }, []);

  const handleChange = (value) => {
    const [from, to, exchangeName] = value.split('_');
    const pair = `${from}${to}`.toUpperCase();

    let localStorageWatchList = getDataLocalStorage(WATCH_LIST) || [];
    localStorageWatchList = [
      ...localStorageWatchList,
      {
        value,
        src: `https://s.tradingview.com/embed-widget/mini-symbol-overview/?locale=en#%7B%22symbol%22%3A%22${exchangeName}%3A${pair}%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A%22100%25%22%2C%22dateRange%22%3A%2212M%22%2C%22colorTheme%22%3A%22dark%22%2C%22trendLineColor%22%3A%22%2337a6ef%22%2C%22underLineColor%22%3A%22rgba(55%2C%20166%2C%20239%2C%200.15)%22%2C%22isTransparent%22%3Atrue%2C%22autosize%22%3Atrue%2C%22largeChartUrl%22%3A%22%22%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22mini-symbol-overview%22%7D`,
      },
    ];

    setDataLocalStorage(WATCH_LIST, localStorageWatchList);
    setWatchList(localStorageWatchList);
  };

  const removeItem = (value) => {
    const modifiedWatchList = watchList.filter((coin) => coin.value !== value);

    setWatchList(modifiedWatchList);
    setDataLocalStorage(WATCH_LIST, modifiedWatchList);
  };

  const options = formatDropdownOptions(exchangeCoins || []);
  const filteredOptions = filterWatchListFromOptions(options, watchList);

  return (
    <div className='WatchList margin-bottom-20'>
      <h1 className={'sub-heading'}>Watchlist</h1>

      <Row>
        {watchList.map((item) => (
          <MiniChart key={item.src} {...item} onRemove={() => removeItem(item.value)} />
        ))}

        {watchList?.length < 6 && (
          <Col xs={{ span: 24 }} md={{ span: 7 }} className='miniChart__empty'>
            <p className='miniChart__empty-text'>Add a Coin</p>

            <CustomSelect
              bordered={true}
              defaultOpen={false}
              options={filteredOptions}
              handleChange={handleChange}
              style={{ width: 160 }}
              value={''}
              placeholder='Select'
              optionFilterProp='children'
              suffixIcon={<DropdownIcon />}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default WatchList;
