import React from 'react'

import type { BoxProps} from '@mui/material';

import { Box, Stack, Typography } from '@mui/material'

import { Spacer, PaletteColor } from 'design-system'

export interface PaletteColorsRowProps extends BoxProps {
  title: string
  colors: {
    color: React.CSSProperties['backgroundColor']
    colorName: string
  }[]
}

const testId = 'PaletteColorsRow'
export const PaletteColorsRow = (props: PaletteColorsRowProps) => {
  const {
    sx,
    title,
    colors,
    ...otherProps
  } = props

  return (
    <Box
      data-testid={testId}
      className={testId}
      sx={{
        ...sx
      }}
      {...otherProps}
    >
      <Typography
        sx={{
          fontSize: 18,
          fontWeight: 'bold',
        }}
      >
        {title}
      </Typography>
      <Spacer y={20} />
      <Stack
        direction={'row'}
        gap={2}
      >
        {
          colors.map(({color, colorName}) => {
            return (
              <PaletteColor
                key={colorName}
                color={color}
                colorName={colorName}
              />
            )
          })
        }
      </Stack>
    </Box>
  )
}

export default PaletteColorsRow
