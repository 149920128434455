import FooterStepper from 'components/Stepper/FooterStepper';
import React from 'react';
import { Step, Styles } from 'react-joyride';

export const baseCls = `SmartGuideToolTip`;

export type TStepTarget =
  | '.exchange'
  | '.ruleActionsLinks__but_templates'
  | '.addButtonsShowHide__button_action'
  | '.then'
  | '.trigger'
  | '.operators.show-section'
  | '.addButtonsShowHide__button_operator'
  | '.launch'
  | 'body'
  | '.ruleActionsLinks__but_smartGuide';

export const exchangeTourContent = (
  userHasExchanges: boolean,
  showAddExchangeModal: () => void
) => {
  if (userHasExchanges)
    return (
      <div className='SmartGuideContent'>
        <h2>
          <span className='bold'>Select Exchange To Start</span>
        </h2>

        <p>Choose one of your exchanges or simply use the risk-free demo wallet.</p>
        <p>
          The demo wallet is a way to test your trading rules and see them performing in a safe
          environment without trading.
        </p>

        <FooterStepper currentStepIndex={0} steps={smartGuideFooterSteps} />
      </div>
    );

  return (
    <div className='SmartGuideContent'>
      <h2>
        <span className='bold'>Test with Demo Exchange</span>
      </h2>

      <p>
        Simply use the risk-free demo wallet or{' '}
        <span
          role='button'
          style={{
            background: 'inherit',
            border: 'none',
            color: 'var(--color-active-blue)',
            cursor: 'pointer',
            display: 'inline',
            textDecoration: 'underline',
            marginRight: '0.3rem',
          }}
          onClick={showAddExchangeModal}
        >
          Connect
        </span>
        your favorite exchange to trade live.
      </p>
      <p>
        The demo wallet is a way to test your trading rules and see them perform in a safe
        environment.
      </p>

      <FooterStepper currentStepIndex={0} steps={smartGuideFooterSteps} />
    </div>
  );
};

export const templateTourContent = () => {
  return (
    <div className='SmartGuideContent'>
      <h2>
        Choose a <span className='bold'>Template</span> or{' '}
        <span className='bold'>Build Your Own Rule</span>
      </h2>
      <p>
        Select from a list of pre-built template rules or start with your own rule from scratch.
      </p>
      <p>
        On each template, see an indication of how each rule has performed over the past few months.
      </p>

      <FooterStepper currentStepIndex={1} steps={smartGuideFooterSteps} />
    </div>
  );
};

export const triggerTourContent = () => {
  return (
    <div className='SmartGuideContent'>
      <h2>
        <span className='bold'>Select a Trigger</span>
      </h2>
      <p>
        Choose an Event trigger (eg. if BTC goes up 3%), Time trigger (eg. every hour) or Direct
        Order (eg. Buy $100 of BTC) to define when the rule will be executed.
      </p>

      <FooterStepper currentStepIndex={1} steps={smartGuideFooterSteps} />
    </div>
  );
};

export const operatorTourContent = () => {
  return (
    <div className='SmartGuideContent'>
      <h2 className='bold'>Operators Connect Blocks</h2>
      <p>Use Operators to add additional condition and action steps to your rule.</p>

      <FooterStepper currentStepIndex={1} steps={smartGuideFooterSteps} />
    </div>
  );
};

export const actionTourContent = () => {
  return (
    <div className='SmartGuideContent'>
      <h2 className='bold'>Now Define an Action</h2>
      <p>Select what action a rule should perform: Buy or Sell.</p>
      <p>The action can be related to the previous trigger or to different coin prices.</p>

      <FooterStepper currentStepIndex={1} steps={smartGuideFooterSteps} />
    </div>
  );
};

export const timeframeTourContent = () => {
  return (
    <div className='SmartGuideContent'>
      <h2 className='bold'>Now See Everything in Action!</h2>
      <p>
        Decide how often or how long you want the rule to run, launch it on a demo wallet or on the
        live market.
      </p>

      <FooterStepper currentStepIndex={2} steps={smartGuideFooterSteps} />
    </div>
  );
};

export const smartGuideButtonTourContent = () => {
  return (
    <div className='SmartGuideContent'>
      <h2>
        <span className='bold'>Smart Guide</span> -{' '}
        <span className='light-text'>Start tutorials</span>
      </h2>

      <p>Reactivate the tutorial whenever you need it</p>
    </div>
  );
};

export interface ICommonStepProps {
  disableBeacon: Step['disableBeacon'];
  placement: Step['placement'];
  spotlightClicks: Step['spotlightClicks'];
  hideBackButton: Step['hideBackButton'];
}

export const commonStepsProps: ICommonStepProps = {
  disableBeacon: true,
  placement: 'bottom-start',
  spotlightClicks: true,
  hideBackButton: false,
};

export const getCommonStyles = (): Styles => {
  return {
    tooltip: {
      display: 'flex',
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },

    tooltipContainer: {
      textAlign: 'left',
    },

    tooltipFooter: {
      width: '260px',
      alignItems: 'flex-start',
      marginLeft: 'auto',
      marginRight: '0.5rem',
      marginTop: 0,
    },

    buttonNext: {
      maxWidth: '155px',
      width: '100%',
      height: '45px',
      borderRadius: '40px',
      backgroundColor: '#4d5aff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 1.1rem',
      boxShadow: '0 3px 0 rgb(32, 45, 210)',
      marginTop: 'auto',
      marginBottom: '1rem',
      fontWeight: 600,
    },

    buttonBack: {
      position: 'absolute',
      left: '1rem',
      padding: 0,
      color: '#6b7280',
      marginTop: '1rem',
      fontWeight: 'normal',
      top: '1rem',
    },
  };
};

export const smartGuideFooterSteps = [{ name: 'EXCHANGE' }, { name: 'BUILD' }, { name: 'LAUNCH' }];

export const getStepsTargets = (store: any, removedStep?: string) => {
  const stepsTargets = ['.exchange', '.ruleActionsLinks__but_templates', '.trigger', '.launch'];

  // OPERATOR
  if (store.ruleContainsSequenceType('OPERATOR')) {
    if (stepsTargets.includes('.addButtonsShowHide__button_operator')) {
      stepsTargets.splice(stepsTargets.indexOf('.addButtonsShowHide__button_operator'), 1);
    }

    stepsTargets.push('.operators.show-section');
  } else {
    if (stepsTargets.includes('.operators.show-section')) {
      stepsTargets.splice(stepsTargets.indexOf('.operators.show-section'), 1);
    }

    stepsTargets.push('.addButtonsShowHide__button_operator');
  }

  // ACTION
  if (store.ruleContainsSequenceType('ACTION')) {
    if (stepsTargets.includes('.addButtonsShowHide__button_action')) {
      stepsTargets.splice(stepsTargets.indexOf('.addButtonsShowHide__button_action'), 1);
    }

    stepsTargets.push('.then');
  } else {
    if (stepsTargets.includes('.then')) {
      stepsTargets.splice(stepsTargets.indexOf('.then'), 1);
    }

    stepsTargets.push('.addButtonsShowHide__button_action');
  }

  // Remove any step not needed for the smartGuide (eg '.ruleActionsLinks__but_templates is not needed on onboarding)
  if (removedStep && stepsTargets.includes(removedStep)) {
    stepsTargets.splice(stepsTargets.indexOf(removedStep), 1);
  }

  return stepsTargets;
};
