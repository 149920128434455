import { Scrollbars } from 'react-custom-scrollbars';
import './styles.scss'
import React from 'react'

const ScrollBar = ({_ref, scrollHidden, ...otherProps}) => <Scrollbars
  ref={_ref}
  renderTrackVertical={props => <div {...props} className={`ScrollBar__track-vertical ${scrollHidden ? 'hidden' : ''}`}/>}
  renderThumbVertical={props => <div {...props} className={`ScrollBar__thumb-vertical ${scrollHidden ? 'hidden' : ''}`} />}
  {...otherProps} 
/>

export default ScrollBar;