import { CoinruleButton } from 'components/CoinruleButton';
import Popover from 'components/Popover';
import { EXCHANGE_UNLOCKS_TEMPLATES } from 'constants/templates';
import React from 'react';
import { Link } from 'react-router-dom';
import storesInjector from 'stores/storesInjector';

import { Analytics } from 'typings/types';

export interface UpgradeOverlayMessageProps {
  hasRealExchange: boolean;
  onClose: () => void;
  analytics: Analytics;
  addRule: any;
}
const UpgradeOverlayMessage: React.FC<UpgradeOverlayMessageProps> = ({
  hasRealExchange,
  onClose,
  analytics,
  addRule,
}) => (
  <div className='getPlanMsg'>
    <div className='getPlanMsg__text'>
      {!hasRealExchange
        ? 'Connect an Exchange to Unlock ' + EXCHANGE_UNLOCKS_TEMPLATES + ' Strategies'
        : null}
      <div>
        {!hasRealExchange ? 'or ' : null}
        Get Unlimited Access with Paid Plan
      </div>
    </div>

    {!hasRealExchange ? (
      <Popover text='Add Exchange Now' placement='left'>
        <CoinruleButton
          fillType='solid'
          icon='Connect'
          className='getPlanMsg__but'
          component={Link}
          to='/exchanges'>
          Connect Exchange
        </CoinruleButton>
      </Popover>
    ) : null}

    <Popover text='Get Coinrule Premium' placement='right'>
      <CoinruleButton
        color='violet'
        fillType='solid'
        icon='Check'
        className='getPlanMsg__but'
        onClick={() => {
          addRule.setPaymentGateVisible({type:'promo', isVisible: true, forceShow:true});

          analytics.track('"Get Plan" button clicked', {
            page: 'Rule page',
          });
        }}>
        Get Plan
      </CoinruleButton>
    </Popover>

    <div
      onClick={(event) => {
        event.preventDefault();
        onClose();
      }}
      className='closeButton'>
      <span role='button'>close</span>
    </div>
  </div>
);

const selector = ({ addRule }: any) => ({
  addRuleStore: addRule,
});

export default storesInjector(selector)(UpgradeOverlayMessage);
