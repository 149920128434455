import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import './style.scss';
import { observer } from 'mobx-react';
import stores from '../../../stores';
import { STEPS } from '../../../stores/auth';

@observer
class AuthHeader extends Component {
  render() {
    return (
      <React.Fragment>
        {/*Mobile side menu*/}
        <div
          className={`mobileSideMenu
                          ${stores.auth.isSideMenuOpened && 'mobileSideMenu_opened'}`}
        >
          <nav className='mobileSideMenu__linksList'>
            {/*            <a href="https://coinrule.com/about.html"
               className="mobileSideMenu__link" target="_blank" rel="noopener noreferrer">About</a>*/}

            <a
              href='https://coinrule.com/pricing.html'
              className='mobileSideMenu__link'
              target='_blank'
              rel='noopener noreferrer'
            >
              Pricing
            </a>

            {/*            <a href="https://coinrule.com/help/"
               className="mobileSideMenu__link" target="_blank" rel="noopener noreferrer">Learn</a>*/}

            {/*            <a href="https://coinrule.com/blog/"
               className="mobileSideMenu__link" target="_blank" rel="noopener noreferrer">Blog</a>*/}

            <div className='mobileSideMenu__divider'>&nbsp;</div>

            <NavLink
              to={{ pathname: '/login', state: { step: STEPS.EMAIL_PASSWORD } }}
              className={`mobileSideMenu__link`}
            >
              Log In
            </NavLink>

            <div className='mainMenu__authSection'>
              <NavLink
                to={{ pathname: '/register', state: { step: STEPS.EMAIL_PASSWORD } }}
                className={`mobileSideMenu__link
                                    mobileSideMenu__link_authBut`}
              >
                Get Started
              </NavLink>
            </div>
          </nav>
        </div>

        {/*Screen dim layer*/}
        <div
          className={`screenDimLayer
                        ${stores.auth.isSideMenuOpened && 'screenDimLayer_visible'}`}
          onClick={stores.auth.handleSideMenuOpening}
        >
          &nbsp;
        </div>

        {/*Main header*/}
        <header
          className={`mainHeader
                            ${stores.auth.isSideMenuOpened && 'mainHeader_shifted'}`}
        >
          {/*Main logo*/}
          <a
            className='mainLogo
                            mainHeader__logo'
            href='https://coinrule.com'
          >
            <span className='mainLogo__emblem'>&nbsp;</span>
            <span className='mainLogo__textPart'>Coinrule</span>
          </a>

          {/*Main menu*/}
          <nav
            className='mainMenu
                              mainHeader__mainMenu'
          >
            {/*            <a href="https://coinrule.com/about.html"
               className="mainMenu__link" target="_blank" rel="noopener noreferrer">About</a>*/}

            <a
              href='https://coinrule.com/pricing.html'
              className='mainMenu__link'
              target='_blank'
              rel='noopener noreferrer'
            >
              Pricing
            </a>

            {/*            <a href="https://coinrule.com/help/"
               className="mainMenu__link" target="_blank" rel="noopener noreferrer">Learn</a>*/}

            {/*            <a href="https://coinrule.com/blog/"
               className="mainMenu__link" target="_blank" rel="noopener noreferrer">Blog</a>*/}
            <NavLink
              to={{ pathname: '/login', state: { step: STEPS.EMAIL_PASSWORD } }}
              className={`mainMenu__link `}
            >
              Log In
            </NavLink>

            <div className='mainMenu__authSection'>
              <NavLink
                to={{ pathname: '/register', state: { step: STEPS.EMAIL_PASSWORD } }}
                className={`mainMenu__link
                                    mainMenu__link_authBut`}
              >
                Get Started
              </NavLink>
            </div>
          </nav>

          {/*Burger menu but*/}
          <div
            className={`burgerMenuBut
                            ${stores.auth.isSideMenuOpened && 'burgerMenuBut_opened'}`}
            onClick={stores.auth.handleSideMenuOpening}
          >
            &nbsp;
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default AuthHeader;
