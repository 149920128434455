import React from 'react'

export interface IconShareViaTelegramProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconShareViaTelegram'
export const IconShareViaTelegram = (props: IconShareViaTelegramProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-id={testId}
      width={'1em'}
      height={'1em'}
      viewBox={"0 0 59 59"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...otherProps}
    >
      <path
        d={"M38.948 21.4585C38.9698 21.3273 38.9569 21.1927 38.9107 21.0679C38.8645 20.9432 38.7866 20.8327 38.6846 20.7473C38.5825 20.662 38.46 20.6048 38.3291 20.5813C38.1982 20.5579 38.0634 20.569 37.9381 20.6136L16.5489 28.2526C16.408 28.3029 16.2848 28.3935 16.1949 28.5131C16.105 28.6328 16.0522 28.7762 16.043 28.9256C16.0339 29.075 16.0689 29.2238 16.1436 29.3535C16.2184 29.4832 16.3296 29.5881 16.4634 29.6551L22.5746 32.7107C22.695 32.771 22.8292 32.7984 22.9637 32.79C23.0981 32.7817 23.2279 32.738 23.34 32.6633L28.4123 29.2808L25.3765 33.0789C25.3105 33.1616 25.2623 33.2571 25.2351 33.3593C25.2079 33.4616 25.2022 33.5684 25.2184 33.673C25.2347 33.7775 25.2725 33.8776 25.3294 33.9668C25.3863 34.056 25.4612 34.1324 25.5492 34.1911L34.716 40.3023C34.8221 40.3729 34.9442 40.4158 35.0712 40.4271C35.1982 40.4384 35.3259 40.4177 35.4429 40.367C35.5598 40.3163 35.6622 40.2371 35.7407 40.1367C35.8192 40.0362 35.8714 39.9178 35.8924 39.792L38.948 21.4585Z"}
        fill={"#CCCCCC"}
      />
      <circle
        cx={29.5}
        cy={29.5}
        r={28}
        stroke={"#CCCCCC"}
        strokeWidth={3}
      />
    </svg>
  )
}

export default IconShareViaTelegram
