import React, { useRef, useEffect } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import stores from 'stores';
import { TRADING_VIEW_SCREENER_WIDGET_URL } from '../CustomSelect/utils';

const CryptoWidget = observer(() => {
  const widgetRef = useRef(null);
  const { baseCurrency } = toJS(stores.user.user?.user) || {};

  useEffect(() => {
    const script = document.createElement('script');

    script.src = TRADING_VIEW_SCREENER_WIDGET_URL;
    script.async = true;
    script.innerHTML = JSON.stringify({
      width: '100%',
      height: 530,
      defaultColumn: 'overview',
      screener_type: 'crypto_mkt',
      displayCurrency: baseCurrency,
      colorTheme: 'dark',
      locale: 'uk',
      isTransparent: false,
    });

    document.getElementById('myContainer5').appendChild(script);

    if (widgetRef.current) {
      widgetRef.current.appendChild(script);
    }
  }, [baseCurrency]);

  return (
    <div id='myContainer5'>
      <div
        data-testid='tradingview-widget-container'
        className='tradingview-widget-container'
        ref={widgetRef}
      ></div>
    </div>
  );
});

export default CryptoWidget;
