import { getDataLocalStorage, setDataLocalStorage } from 'helpers/utils';
import React, { Fragment, useEffect, useState } from 'react';
import stores from 'stores';
import { ReactComponent as RightArrow } from 'assets/images/icons/right-arrow.svg';

import ReactJoyride, { CallBackProps, Step } from 'react-joyride';
import { ReactComponent as CaretRight } from 'assets/images/icons/caretRight.svg';

import './style.scss';

import {
  actionTourContent,
  commonStepsProps,
  getCommonStyles,
  exchangeTourContent,
  operatorTourContent,
  smartGuideButtonTourContent,
  templateTourContent,
  timeframeTourContent,
  triggerTourContent,
  getStepsTargets,
  TStepTarget,
} from './utils';
import useResize from 'hooks/useResize';
import { SMART_GUIDE_TOUR_BUTTON_SEEN } from 'helpers/constants';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { getAnalyticsEvent, handleMixpanelEventAnalytics } from 'helpers/analytics';
import { TOnboardingVariant, TStepName } from 'pages/RulesDashboardPage/Onboarding/utils';

export interface ISetWithOptions {
  maxWidth?: string;
  isFullScreen?: boolean;
  width?: number;
  toolTipContainer: HTMLElement;
}

const Button: React.FC<React.HTMLProps<HTMLButtonElement>> = ({
  children = 'Next',
  ...otherProps
}) => (
  <Fragment {...otherProps}>
    {children === 'Back' && <RightArrow style={{ marginRight: '0.7rem' }} />} {children}{' '}
    {(children === 'Next' || children === 'Finish' || children === 'Launch Rule') && (
      <CaretRight fill='#3240EC' width='20px' height='20px' />
    )}
  </Fragment>
);

export interface ISmartGuideProps {
  store: any;
  removeFlag: (data: CallBackProps) => void;
  userHasExchanges?: boolean;
  show: boolean;
  run: boolean;
  setConnectExchangeClicked: (connectExchangeClicked: boolean) => void;
}

export interface ILocation {
  fromOnboarding: boolean;
}

const SmartGuide: React.FC<ISmartGuideProps> = ({
  store,
  removeFlag,
  userHasExchanges = stores.userInfo.exchanges.length > 1,
  show,
  run,
  setConnectExchangeClicked,
}) => {
  const location = useLocation<ILocation>();
  const { fromOnboarding } = location.state || {};

  const target = document.querySelector('.exchange') as HTMLElement;
  const { width } = useResize(target);

  const commonStepStyles = getCommonStyles();

  const smartGuideButtonSeen = getDataLocalStorage(SMART_GUIDE_TOUR_BUTTON_SEEN);

  const handleShowAddExchangeModal = () => {
    stores.exchangeProfile.toggleShowAddExchangeModal(true);

    setConnectExchangeClicked(true);
  };

  const initialSteps: Step[] = [
    //Exchange select
    {
      ...commonStepsProps,
      target: '.exchange',
      content: exchangeTourContent(userHasExchanges, handleShowAddExchangeModal),
      locale: {
        next: <Button />,
      },
      isFixed: true,
      styles: {
        ...commonStepStyles,
        options: {
          arrowColor: 'none',
        },
      },
    },

    //Templates
    {
      ...commonStepsProps,
      target: '.ruleActionsLinks__but_templates',
      content: templateTourContent(),
      locale: {
        next: <Button />,
        back: <Button>Back</Button>,
      },
      styles: {
        ...commonStepStyles,
        tooltip: {
          ...commonStepStyles.tooltip,
          width: '680px',
        },
      },
    },

    //Conditions
    {
      target: '.trigger',
      ...commonStepsProps,
      content: triggerTourContent(),
      locale: {
        next: <Button />,
        back: <Button>Back</Button>,
      },
      styles: {
        ...commonStepStyles,
        options: { arrowColor: 'none' },
      },
    },

    //Actions
    {
      ...commonStepsProps,
      target: store.ruleContainsSequenceType('ACTION')
        ? '.then'
        : '.addButtonsShowHide__button_action',
      content: actionTourContent(),
      locale: {
        next: <Button />,
        back: <Button>Back</Button>,
      },
      styles: {
        ...commonStepStyles,
        tooltip: {
          ...commonStepStyles.tooltip,
          width: '630px',
        },
      },
    },

    // Operator
    {
      ...commonStepsProps,
      target: store.ruleContainsSequenceType('OPERATOR')
        ? '.operators.show-section'
        : '.addButtonsShowHide__button_operator',
      content: operatorTourContent(),
      locale: {
        next: <Button />,
        back: <Button>Back</Button>,
      },
      styles: {
        ...commonStepStyles,
        tooltip: {
          ...commonStepStyles.tooltip,
          width: '600px',
        },
      },
    },

    //launch
    {
      ...commonStepsProps,
      target: '.launch',
      content: timeframeTourContent(),
      styles: {
        ...commonStepStyles,
        options: { arrowColor: 'none' },
        buttonNext: {
          ...commonStepStyles.buttonNext,
          display: fromOnboarding ? 'none' : 'flex',
        },
      },
      locale: {
        next: <Button>Finish</Button>,
        back: <Button>Back</Button>,
      },
    },

    // SmartGuide Button
    {
      target: '.ruleActionsLinks__but_smartGuide',
      content: smartGuideButtonTourContent(),
      disableOverlay: true,
      locale: { last: <Button>Finish</Button>, next: <Button>Finish</Button>, back: 'Back' },
      placement: 'bottom-start',
      styles: {
        tooltip: {
          width: '480px',
        },
        tooltipContainer: {
          textAlign: 'left',
        },
        buttonNext: {
          ...commonStepStyles.buttonNext,
          boxShadow: 'none',
          width: '130px',
        },
        buttonBack: {
          ...commonStepStyles.buttonNext,
          boxShadow: 'none',
          backgroundColor: 'white',
          border: '1px solid #9698ab',
          color: '#9698ab',
          justifyContent: 'center',
          width: '130px',
          marginRight: '10px',
        },
      },
    },
  ];

  const [steps] = useState(initialSteps);

  // Slide where width should fit the width of the target
  const fitTargetSlides: string[] = ['.exchange', '.trigger', '.launch'];

  const handleResponsiveness = (width: number, stepsTargets: string[]) => {
    if (width <= 700) {
      stepsTargets.forEach((slide) => {
        const index = steps.findIndex((step) => step.target === slide);
        const styles = steps[index].styles;

        steps[index].styles = {
          ...styles,
          tooltip: {
            ...styles?.tooltip,
            flexDirection: 'column',
          },
          tooltipContainer: {
            ...styles?.tooltipContainer,
            marginLeft: slide === '.launch' ? '1rem' : '2rem',
            marginTop: '0.5rem',
          },
          buttonBack: {
            ...styles?.buttonBack,
            margin: '0',
          },
          buttonNext: {
            ...styles?.buttonNext,
            maxWidth: slide === '.launch' ? '155px' : '130px',
          },
        };
      });
    } else {
      stepsTargets.forEach((slide) => {
        const index = steps.findIndex((step) => step.target === slide);
        const styles = steps[index].styles;

        const isLarge = fitTargetSlides.includes(slide);

        steps[index].styles = {
          ...styles,
          tooltip: {
            ...styles?.tooltip,
            flexDirection: 'row',
          },
          tooltipContainer: {
            ...styles?.tooltipContainer,
            marginLeft: isLarge ? '11rem' : '6rem',
            marginTop: 0,
          },
          buttonBack: {
            ...styles?.buttonBack,
            margin: '1rem',
          },
          buttonNext: {
            ...styles?.buttonNext,
            maxWidth: '155px',
          },
        };
      });
    }
  };

  useEffect(() => {
    if (fromOnboarding) steps.splice(1, 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (store.ruleContainsSequenceType('OPERATOR')) {
      fitTargetSlides.push('.operators.show-section');
    }

    if (store.ruleContainsSequenceType('ACTION')) {
      fitTargetSlides.push('.then');
    }

    fitTargetSlides.forEach((slide) => {
      const index = steps.findIndex((step) => step.target === slide);

      return (steps[index].styles = {
        ...steps[index].styles,
        tooltip: {
          width: width || '580px',
          display: 'flex',
        },
      });
    });

    const stepsTargets = getStepsTargets(
      store,
      fromOnboarding ? '.ruleActionsLinks__but_templates' : undefined
    );

    handleResponsiveness(width, stepsTargets);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const sendAnalytics = (data: CallBackProps, variant: TOnboardingVariant) => {
    const { step, action, lifecycle } = data;

    if (action === 'start' && lifecycle === 'init') {
      handleMixpanelEventAnalytics({
        event: 'Smart Guide Start',
      });
    }

    if (lifecycle === 'complete' && (action === 'next' || action === 'prev')) {
      const options = {
        event: getAnalyticsEvent(
          fromOnboarding ? variant : '',
          step.target as TStepName | TStepTarget,
          action === 'next' ? 'Next' : 'Back'
        ),
      };

      handleMixpanelEventAnalytics(options);
    }
  };

  const handleCallback = (data: CallBackProps) => {
    removeFlag(data);

    const { status, step } = data;
    const { onboardingVariant } = stores.userSurvey;

    sendAnalytics(data, onboardingVariant);

    const atSmartGuideButton = step.target === '.ruleActionsLinks__but_smartGuide';

    if (atSmartGuideButton) {
      if (status === 'running') setDataLocalStorage(SMART_GUIDE_TOUR_BUTTON_SEEN, true);

      if (smartGuideButtonSeen || fromOnboarding) stores.tourGuide.toggleRuleTour('close');

      if (fromOnboarding) {
        if (store.hasReachedRuleLimit(store)) {
          store.backup();
          setDataLocalStorage('should_restore_rule', true);
          stores.addRule.setPaymentGateVisible({type:'promo', isVisible:true});

          return;
        }

        store.setShowRuleLaunchPreview(true);
        setConnectExchangeClicked(false);
        stores.exchangeProfile.handleShouldLaunchLiveOnAddExchange(store);
      }
    }
  };

  if (show) {
    setDataLocalStorage('hasSeenRuleDetailTooltip', true);

    return (
      <ReactJoyride
        steps={steps}
        disableCloseOnEsc={false}
        disableOverlayClose={false}
        run={run}
        continuous={true}
        callback={handleCallback}
      />
    );
  }

  return null;
};

export default observer(SmartGuide);
