import { PLAN_EXPERIMENTS } from 'constants/experiments';
import { sortPlans } from 'helpers/plan';
import { action, observable } from 'mobx';
import stores from 'stores';

import { errorNotification } from '../helpers/notification';
import { AbstractStore } from './AbstractStore';
import { paymentsApi } from './payment';

export default class PlanStore extends AbstractStore {
  constructor(rootStore) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  @observable plans = [];

  @observable featurePlans = [];

  @observable isLoading = false;
  @observable isLoaded = false;

  @action setFeaturePlans = (featurePlans) => (this.featurePlans = featurePlans);

  /*** TODO:
   * for scrap hobbyist experiment
   * - check if plan contains couponID in reference
   * - if yes, fetch coupon.
   * - then check experimentID still in reference
   * - if experimentID is `scrap-hobbyist-plan`, user is on scrap hobbyist plan experiment
   * - if experimentID is `quarterly-plan`, user is on quarterly plan experiment
   */

  confirmAndFetchCouponInPlan = async (plans) => {
      // check if any of the plans has couponId in reference
      const planWithCoupon = plans.find((plan) => plan.references && plan.references.couponId);

      if(!planWithCoupon) return;
      
      // fetch coupon
      await stores.payment.fetchCoupon(planWithCoupon.references.couponId);
  }

  @action
  async getPlans(force) {
    try {
      if (!this.isLoading && (!this.isLoaded || force)) {
        this.isLoading = true;
        const resp = await paymentsApi.planControllerFindActive();

        if (resp.data) {
          const sortedPlans = sortPlans(this.formatPlan(resp.data || []))
          this.plans = sortedPlans;
          
          await this.confirmAndFetchCouponInPlan(sortedPlans);
          
          this.isLoaded = true;

          return resp.data.authorized;
        } else {
          throw new Error('Invalid response ' + resp.toString());
        }
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoading = false;
    }
  }

  // plan prices are mostly returned as strings, so we need to convert them to numbers
  formatPlan = (plans) => {
    return plans.map(({quarterlyPrice, price, annualPrice, ...rest}) => ({
      ...rest,
      ...(quarterlyPrice && {quarterlyPrice: Number(quarterlyPrice)}),
      price: Number(price),
      annualPrice: Number(annualPrice)
    }))
  }

  getExperimentType = () => this.plans?.[0]?.references?.experimentId
  
  isOnQuarterlyPriceGroup = () => this.getExperimentType() === PLAN_EXPERIMENTS.QUARTERLY;
  isOnScrapHobbyistGroup = () => this.getExperimentType() === PLAN_EXPERIMENTS.SCRAP_HOBBYIST
  
  getDefaultPlan() {
    return this.plans.find((plan) => plan.settings.isDefault);
  }

  getPlanByCode = async (code) => {
    if (!this.plans?.length) await this.getPlans(true);
    return this.plans.find((plan) => plan.code === code);
  };

  getPlanByClearance(clearance) {
    return this.plans.find((plan) => plan.settings.clearance === clearance);
  }

  getPlanProperty(clearance, property) {
    const plan = this.getPlanByClearance(clearance);

    return plan && plan[property];
  }

  getUserCurrentPlan = () => {
    return this.plans.find((plan) => plan.code === stores.user.user?.plan?.uid);
  };

  isFeatureEnabled = (feature) => {
    const plan = this.getUserCurrentPlan();
    return plan && plan.features.includes(feature);
  };

  getPlansByFeature = (feature) => {
    const featurePlans = this.plans.filter((plan) => plan.features.includes(feature));
    this.setFeaturePlans(featurePlans);
  };

  getHigherPlansByPrice = (price) => {
    return this.plans.filter((p) => +p.price > +price);
  };

  getHigherPlan = () => {
    const currentPlan = this.getUserCurrentPlan();
    const price = (currentPlan?.price && +currentPlan.price) || 0;

    return this.plans.find((plan) => +plan.price > price);
  };

  getIsProUser = () => {
    const userCurrentPlan = this.getUserCurrentPlan();
    const proPlan = this.plans.reduce(
      (prev, current) => (+prev.price > +current.price ? prev : current),
      {}
    );

    return userCurrentPlan && userCurrentPlan?.code === proPlan?.code;
  };

  getCurrentPlanMaxTradingVolume = () => {
    const currentPlan = this.getUserCurrentPlan();
    if (!currentPlan) return 0;

    return currentPlan.limits.maxTradingVolume;
  };
}
