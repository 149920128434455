import { PASSWORD_MAX, PASSWORD_MIN } from 'constants/auth';
import { IS_PROD } from 'constants/isProd';
import { handleUserLoginCount } from 'helpers/tour';
import { sendAnalytics } from 'helpers/analytics';
import { action, observable } from 'mobx';
import { errorNotification, infoNotification } from '../helpers/notification';
import { sleep } from '../helpers/utils';
import services from '../services/index';
import stores from './index';
import { AbstractStore } from './AbstractStore';

export const PAGES = {
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgotpassword',
  GOOGLE: '/auth/google/callback',
  FACEBOOK: '/auth/facebook/callback',
};

export const STEPS = {
  EMAIL: 'email',
  EMAIL_PASSWORD: 'email_password',
  CODE: 'code',
  CODE_PASSWORD: 'code_password',
  CODE_PASSWORD_2: 'code_password_2',
  PASSWORD: 'password',
  PASSWORD_2: 'password_2',
  TWO_FA: 'two_fa',
  FINAL: 'final',
};

export default class AuthStore extends AbstractStore {
  @observable currentPage = PAGES.LOGIN;
  @observable currentStep = STEPS.EMAIL_PASSWORD;
  @observable history;

  @observable securityHash;
  @observable recaptchaHash;
  @observable suspicious = false;
  @observable alwaysCaptcha = false;
  @observable loopHashTimeout;

  @observable title;
  @observable submitText;
  @observable email = '';
  @observable password = '';
  @observable twoFaToken = '';
  @observable code = '';
  @observable pin = '';

  @observable isValidPassword = false;
  @observable isValid2faToken = false;
  @observable isValidEmail = false;
  @observable isValidCode = false;
  @observable isValidPin = false;
  @observable showIfValidPassword = false;
  @observable showIfValidEmail = false;
  @observable showIfValidCode = false;
  @observable showIfValidPin = false;
  @observable isSideMenuOpened = false;

  @observable showLoadingPage = false;
  @observable loadingPageText = '';

  @observable code1 = '';
  @observable code2 = '';
  @observable code3 = '';
  @observable code4 = '';
  @observable code5 = '';
  @observable code6 = '';

  @observable pin1 = '';
  @observable pin2 = '';
  @observable pin3 = '';
  @observable pin4 = '';

  @observable showSpinnerOnCTAbut = false;

  constructor(rootStore) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  @action
  setHistory(history) {
    this.history = history;
  }

  @action
  setPage(page) {
    this.currentPage = page;
    this.alwaysCaptcha = false;

    switch (this.currentPage) {
      default:
      case PAGES.LOGIN:
        this.currentStep = STEPS.EMAIL_PASSWORD;
        this.title = 'Account Login';
        this.submitText = 'Log In';
        break;

      case PAGES.REGISTER:
        this.currentStep = STEPS.EMAIL_PASSWORD;
        this.title = 'Create New Account';
        this.submitText = 'Create Account';
        break;

      case PAGES.FORGOT_PASSWORD:
        this.currentStep = STEPS.EMAIL;
        this.title = 'Confirm Your Email';
        this.submitText = 'Next';
        break;
    }
  }

  @action
  setStep(step) {
    if (
      step === STEPS.EMAIL ||
      step === STEPS.EMAIL_PASSWORD ||
      step === STEPS.CODE ||
      step === STEPS.PASSWORD ||
      step === STEPS.PASSWORD_2 ||
      step === STEPS.CODE_PASSWORD ||
      step === STEPS.CODE_PASSWORD_2 ||
      step === STEPS.TWO_FA ||
      step === STEPS.FINAL
    ) {
      this.currentStep = step;

      if (this.currentPage === PAGES.LOGIN && step === STEPS.EMAIL_PASSWORD) {
        this.title = 'Account Login';
        this.submitText = 'Log In';
      } else if (this.currentPage === PAGES.REGISTER && step === STEPS.EMAIL_PASSWORD) {
        this.title = 'Create New Account';
        this.submitText = 'Create Account';
      } else if (this.currentPage === PAGES.FORGOT_PASSWORD && step === STEPS.EMAIL) {
        this.title = 'Confirm Your Email';
        this.submitText = 'Next';
      } else if (step === STEPS.TWO_FA) {
        this.title = 'Enter Authentication Code';
        this.submitText = 'Next';
      }
    }
  }

  @action
  resetState() {
    this.email = '';
    this.password = '';
    this.code = '';
    this.pin = '';
    this.isValidEmail = false;
    this.isValidPassword = false;
    this.isValid2faToken = false;
    this.isValidCode = false;
    this.isValidPin = false;
    this.showIfValidEmail = false;
    this.showIfValidPassword = false;
    this.showIfValidCode = false;
    this.showIfValidPin = false;
    this.suspicious = false;
    this.alwaysCaptcha = false;
    this.code1 = '';
    this.code2 = '';
    this.code3 = '';
    this.code4 = '';
    this.code5 = '';
    this.code6 = '';
    this.showLoadingPage = false;
    this.loadingPageText = '';
    this.pin1 = '';
    this.pin2 = '';
    this.pin3 = '';
    this.pin4 = '';
    this.recaptchaHash = null;
  }

  @action
  setEmail(email) {
    this.email = email;
    this.validateEmail();
  }

  @action
  setPassword(password) {
    this.password = password;
    this.validatePassword();
  }

  @action
  setTwoFaToken(token) {
    this.twoFaToken = token;
  }

  @action
  setCode(number, codeData) {
    if (codeData.length > 1) {
      for (let i = 0; i < codeData.length; i++) {
        this[`code${i + 1}`] = codeData[i];
      }
    } else {
      this[`code${number}`] = codeData[0];
    }

    this.code = this.code1 + this.code2 + this.code3 + this.code4 + this.code5 + this.code6;
    this.validateCode();
  }

  @action
  setPin(number, codeData) {
    if (codeData.length > 1) {
      for (let i = 0; i < codeData.length; i++) {
        this[`pin${i + 1}`] = codeData[i];
      }
    } else {
      this[`pin${number}`] = codeData[0];
    }

    this.pin = this.pin1 + this.pin2 + this.pin3 + this.pin4;
    this.validatePin();
  }

  @action
  handleCaptchaChange(hash) {
    this.recaptchaHash = hash;
  }

  errorMessage(inputType) {
    let message;

    switch (inputType) {
      default:
      case 'email':
        message = 'Invalid email address';
        break;
      case 'password':
        message = `Invalid password size. Password must be between ${PASSWORD_MIN} and ${PASSWORD_MAX} characters`;
        break;
      case 'token':
        message = 'Invalid code. Kindly try a new one';
        break;
    }

    return message;
  }

  @action
  handleSideMenuOpening = () => {
    this.isSideMenuOpened = !this.isSideMenuOpened;
  };

  @action
  validateEmail() {
    const valid =
      this.email &&
      this.email.length > 0 &&
      this.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

    this.isValidEmail = valid;

    if (valid) {
      this.showIfValidEmail = false;
    }

    return valid;
  }

  @action
  validatePassword() {
    const valid =
      this.password && this.password.length >= PASSWORD_MIN && this.password.length <= PASSWORD_MAX;

    this.isValidPassword = valid;

    if (valid) {
      this.showIfValidPassword = false;
    }

    return valid;
  }

  @action
  validate2FAToken() {
    const valid = this.twoFaToken && this.twoFaToken.toString().length === 6;
    this.isValid2faToken = valid;
    if (valid) {
      this.showIfValidCode = false;
    }
    return valid;
  }

  @action
  validateCode() {
    const valid = this.code && this.code.length === 6;

    this.isValidCode = valid;

    if (valid) {
      this.showIfValidCode = false;
    }

    return valid;
  }

  @action
  validatePin() {
    const valid = this.pin && this.pin.length === 4;

    this.isValidPin = valid;

    if (valid) {
      this.showIfValidPin = false;
    }

    return valid;
  }

  @action
  setSuspicious(suspicious) {
    if (!this.alwaysCaptcha) {
      if (this.suspicious && !suspicious) {
        this.recaptchaHash = null;
      }

      this.suspicious = suspicious;
    }
  }

  @action
  createSecurityHashLoop = () => {
    // hash expiration is 5 minutes, so loop every 4 minutes
    this.loopHashTimeout = setTimeout(this.securityHashLoop, 1000 * 60 * 4);
  };

  securityHashLoop = async () => {
    const result = await services.auth.hash();

    if (result) {
      this.setSecurityHash(result.securityHash);
      this.setSuspicious(result.suspicious);
    }
  };

  resendCode = async (e) => {
    e.preventDefault();

    if (!stores.auth.securityHash) {
      await stores.auth.securityHashLoop();
    }

    infoNotification('Verification code sent. Please check your inbox.');

    await services.auth.sendEmail(this.email, this.securityHash, this.recaptchaHash);
  };

  @action
  setSecurityHash(hash) {
    this.securityHash = hash;
  }

  async getSecurityHash() {
    return await services.auth.hash();
  }

  @action
  startSession(res) {
    this.showLoadingView(true, 'Preparing your dashboard...');

    if (this.loopHashTimeout) {
      clearTimeout(this.loopHashTimeout);
      this.loopHashTimeout = null;
    }

    stores.user.resetJustLoggedOut();
    stores.user.saveXsrfToken(res.xsrfToken);

    stores.user.checkUser(true, true).then(async () => {
      if (res.userTour) {
        if (res.userTour === 'welcome' && IS_PROD) {
          try {
            sendAnalytics('adroll', {
              adroll_segments: 'e7e1dffa'
            });

            /* eslint-disable no-unused-expressions */
            window.rga?.event({
              category: 'signup',
              action: 'signup_user',
            });

            /* eslint-disable no-unused-expressions */
            window.fbq?.('track', 'Lead'); // Facebook conversion tracking
            /**
             * Note: DO NOT DELETE. Code will be revisited in near future.
             *
            window.rdt?.('track', 'SignUp'); // Reddit conversion tracking
            window.twttr?.conversion.trackPid('o4v8x'); // Twitter conversion tracking
            /* eslint-enable no-unused-expressions

            http.get('https://px.ads.linkedin.com/collect/?pid=2525658&conversionId=3088042&fmt=gif')
              .catch((err) => console.error('Error while performing tracking: ', err)); // LinkedIn conversion tracking
            */
            // window.$FPROM.trackSignup({
            //   email: stores.user.user.user.email,
            // });
          } catch (err) {
            console.error('Error while performing tracking: ', err);
          }

          await sleep(1500);
        }
      }

      handleUserLoginCount(stores.user.getUserId());
      this.resetState();
      this.setSecurityHash(null);
      this.history.push('/');
    });
  }

  @action
  async facebookVerify(params) {
    if (!stores.auth.securityHash) {
      await stores.auth.securityHashLoop();
    }

    const result = await services.auth.oauthFacebookVerify(params);

    if (result && result.authorized) {
      if (result.requires2FA) {
        this.setPage(PAGES.FACEBOOK);
        this.setStep(STEPS.TWO_FA);
      } else {
        this.startSession(result);
      }
    } else {
      this.history.push('/login');
    }

    return { currentStep: this.currentStep, currentPage: this.currentPage };
  }

  @action
  async googleVerify(params) {
    if (!stores.auth.securityHash) {
      await stores.auth.securityHashLoop();
    }

    const result = await services.auth.oauthGoogleVerify(params);

    if (result && result.authorized) {
      if (result.requires2FA) {
        this.setPage(PAGES.GOOGLE);
        this.setStep(STEPS.TWO_FA);
      } else {
        this.startSession(result);
      }
    } else {
      this.history.push('/login');
    }

    return { currentStep: this.currentStep, currentPage: this.currentPage };
  }

  @action
  switchSpinnerOnCTAbut = (spinnerShowed) => {
    this.showSpinnerOnCTAbut = spinnerShowed;
  };

  showLoadingView(show, text) {
    this.showLoadingPage = show;
    this.loadingPageText = show ? text : '';
  }

  @action
  async submitLogin() {
    if (this.currentStep === STEPS.TWO_FA) {
      this.showLoadingView(true, 'Verifying...');
    } else {
      this.switchSpinnerOnCTAbut(true);

      if (!stores.auth.securityHash) {
        await stores.auth.securityHashLoop();
      }
    }

    if (this.currentStep === STEPS.EMAIL_PASSWORD) {
      let valid = true;

      if (!this.validateEmail()) {
        this.showIfValidEmail = true;
        valid = false;
      }

      if (!this.validatePassword()) {
        this.showIfValidPassword = true;
        valid = false;
      }

      if (!valid || ((this.suspicious || this.alwaysCaptcha) && !this.recaptchaHash)) {
        this.switchSpinnerOnCTAbut(false);
        return;
      }

      const result = await services.auth.login(
        this.email,
        this.password,
        this.securityHash,
        this.recaptchaHash
      );

      this.switchSpinnerOnCTAbut(false);

      if (result) {
        if (result.authorized && result.requires2FA) {
          this.suspicious = false;
          this.alwaysCaptcha = false;
          this.setStep(STEPS.TWO_FA);
        } else if (result.verificationRequired) {
          services.auth.sendEmail2(this.email, this.securityHash, this.recaptchaHash);

          this.title = 'Enter code from email';
          this.submitText = 'Log in';
          this.suspicious = true;
          this.alwaysCaptcha = true;
          this.setStep(STEPS.CODE);
        } else if (result.authorized) {
          this.startSession(result);
        } else {
          this.resetState();
          this.setStep(STEPS.EMAIL_PASSWORD);
          this.suspicious = true;
        }
      } else {
        this.resetState();
        this.setStep(STEPS.EMAIL_PASSWORD);
        this.suspicious = true;
      }
    } else if (this.currentStep === STEPS.CODE) {
      if (!this.validateCode()) {
        this.showIfValidCode = true;
        this.switchSpinnerOnCTAbut(false);
        return;
      }

      const result = await services.auth.login(
        this.email,
        this.password,
        this.securityHash,
        this.recaptchaHash,
        this.code
      );

      this.switchSpinnerOnCTAbut(false);

      if (result) {
        if (result.authorized && result.requires2FA) {
          this.setStep(STEPS.TWO_FA);
        } else {
          this.startSession(result);
        }
      } else {
        this.resetState();
      }
    } else if (this.currentStep === STEPS.TWO_FA) {
      this.switchSpinnerOnCTAbut(true);

      if (!this.validate2FAToken()) {
        this.switchSpinnerOnCTAbut(false);
        return;
      }

      const result = await services.auth.verify2FAToken(this.twoFaToken);

      this.switchSpinnerOnCTAbut(false);

      if (result && result.authorized && result.data.authorized) {
        this.startSession(result);
      } else {
        const errorMessage = result?.authorized ? 'Incorrect 2FA Code' : null;
        errorNotification(errorMessage || 'Error while logging in with 2FA');

        this.showLoadingView(false);
        this.setStep(STEPS.TWO_FA);
        return false;
      }
    }
  }

  @action
  async submitRegister() {
    this.switchSpinnerOnCTAbut(true);
    this.alwaysCaptcha = false;

    if (!stores.auth.securityHash) {
      await stores.auth.securityHashLoop();
    }

    if (this.currentStep === STEPS.EMAIL_PASSWORD) {
      let valid = true;

      if (!this.validateEmail()) {
        this.showIfValidEmail = true;
        valid = false;
      }

      if (!this.validatePassword()) {
        this.showIfValidPassword = true;
        valid = false;
      }

      if (!valid) {
        this.switchSpinnerOnCTAbut(false);
        return;
      }

      const result = await services.auth.lookup(this.email, this.securityHash, this.recaptchaHash);

      this.switchSpinnerOnCTAbut(false);

      if (result) {
        this.setSuspicious(result.suspicious);

        services.auth.sendEmail(this.email, this.securityHash, this.recaptchaHash);

        this.title = 'Enter code from email';

        this.setStep(STEPS.CODE);
      }
    } else if (this.currentStep === STEPS.CODE) {
      if (!this.validateCode()) {
        this.showIfValidCode = true;
        this.switchSpinnerOnCTAbut(false);
        return;
      }

      const result = await services.auth.register(
        this.email,
        this.password,
        this.securityHash,
        this.recaptchaHash,
        this.code
      );

      this.switchSpinnerOnCTAbut(false);

      if (result) {
        this.setStep(STEPS.FINAL);
        this.title = 'Thank you';
        this.submitText = '';

        setTimeout(() => {
          this.resetState();
          this.startSession(result);
        }, 2000);
      }
    }
  }

  @action
  async submitForgotPassword() {
    this.switchSpinnerOnCTAbut(true);
    // this.alwaysCaptcha = false;

    if (this.currentStep === STEPS.EMAIL) {
      if (!this.validateEmail()) {
        this.showIfValidEmail = true;
        this.switchSpinnerOnCTAbut(false);
        return;
      }

      if (!this.validatePin()) {
        this.showIfValidPin = true;
        this.switchSpinnerOnCTAbut(false);
        return;
      }

      this.switchSpinnerOnCTAbut(false);
      this.setSuspicious(true);
      this.recaptchaHash = null;
      this.alwaysCaptcha = true;

      this.title = 'Create a new secure password';
      this.submitText = 'Reset password';

      this.setStep(STEPS.PASSWORD);
    } else if (this.currentStep === STEPS.PASSWORD) {
      if (!this.validateEmail()) {
        this.showIfValidEmail = true;
        this.switchSpinnerOnCTAbut(false);
        return;
      }

      if (!this.validatePin()) {
        this.showIfValidPin = true;
        this.switchSpinnerOnCTAbut(false);
        return;
      }

      if (!this.validatePassword()) {
        this.showIfValidPassword = true;
        this.switchSpinnerOnCTAbut(false);
        return;
      }

      if (!this.recaptchaHash) {
        this.switchSpinnerOnCTAbut(false);
        return;
      }

      const result = await services.auth.login(
        this.email,
        this.password,
        null,
        this.recaptchaHash,
        null,
        this.pin
      );

      this.switchSpinnerOnCTAbut(false);

      if (result) {
        this.setStep(STEPS.FINAL);
        this.title = 'Password changed';
        this.submitText = '';

        setTimeout(() => {
          this.resetState();
          this.startSession(result);
        }, 2000);
      }
    }
  }
}
