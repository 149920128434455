import React from 'react'

import { Typography, useTheme } from '@mui/material'
import { ExchangesGrid, Box } from 'design-system'
import type { TExchange, TExpandedExchange } from 'design-system'

export interface ExchangeSelectorProps {
  exchangesSpot: TExpandedExchange[],
  exchangeLeverage: TExpandedExchange[],
  handleChange: (event: React.MouseEvent, isClickable: boolean, exchange: TExchange) => void,
  isFreeUser: boolean,
  promoBinance: React.ReactNode
}

const testId = 'ExchangeSelector'
export const ExchangeSelector = (props: ExchangeSelectorProps) => {
  const {
    handleChange,
    exchangesSpot,
    exchangeLeverage,
    isFreeUser,
    promoBinance,
    ...otherProps
  } = props

  const theme = useTheme()

  return (
    <>
      <Typography
        data-testid={testId}
        className={testId}
        variant={'h4'}
        sx={{
          fontSize: 20,
          color: theme.palette.primary.main,
          fontWeight: 700,
          lineHeight: '1.35',
          marginTop: '15px',
        }}
        {...otherProps}
      >
        Start By Selecting Your Favorite Exchange
      </Typography>
      <Typography
        variant={'h4'}
        sx={{
          fontSize: 13,
          lineHeight: '27px',
          color: theme.palette.grey[700],
          fontWeight: 600,
          marginTop: '25px',
        }}
      >
        Spot Trading:
      </Typography>
      {
        promoBinance &&
        <Box
          sx={{
            marginTop: 10,
            marginBottom: 5,
            paddingLeft: 25,
            paddingRight: 25,
            [theme.breakpoints.down('sm')]: {
              padding: 0,
              margin: 0,
            }
          }}
        >
          {promoBinance}
          <Typography
            align={'center'}
            sx={{
              color: '#949494',
              fontSize: '14px',
            }}
          >
            or
          </Typography>
        </Box>
      }
      <ExchangesGrid
        handleChange={handleChange}
        exchanges={exchangesSpot}
      />
      {
        !isFreeUser ?
          <>
            <Typography
              variant={'h4'}
              sx={{
                fontSize: 13,
                lineHeight: '27px',
                color: theme.palette.grey[700],
                fontWeight: 600,
                marginTop: '25px',
              }}
            >
              Leverage Trading:
            </Typography>

            <ExchangesGrid
              handleChange={handleChange}
              exchanges={exchangeLeverage}
            />
          </>
          :
          null
      }
    </>
  )
}

export default ExchangeSelector
