import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';

import './style.scss';
import { ErrorBoundary } from '../ErrorBoundary';

// The named export is here for when we want to use this component WITHOUT router (so for example in our lazy loading loading fallback). Do not remove this export!
export const GlobalContent = observer((props) => {
  // change content side BG to light blue for billing
  let blueContentBg = false;

  if (window.location.href.indexOf('/settings') !== -1) {
    blueContentBg = true;
  }

  return (
    <div
      className={`globalContent 
												${blueContentBg && 'globalContent_lightBlueBg'}
												${props.extraClass}`}
      style={props.style}
    >
      <ErrorBoundary name={"boundary_GlobalContent"}>{props.children}</ErrorBoundary>
    </div>
  );
});

export default withRouter(GlobalContent);
