import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import stores from 'stores';
import ExchangeTutorial from './ExchangeTutorial';

import './style.scss';
import { Icon } from 'antd';

export type TExchangeTutorialType =
  | 'select_exchange'
  | 'binance-futures'
  | 'binance'
  | 'binance-us'
  | 'hitbtc'
  | 'bitstamp'
  | 'coinbasepro'
  | 'kraken'
  | 'bittrex'
  | 'okex'
  | 'bitpanda'
  | 'poloniex'
  | 'liquid';

interface IExchangesAPITutorialsProps {
  type: TExchangeTutorialType;
  store: any;
  promoBinance?: React.ReactNode
}

const ExchangesAPITutorials: React.FC<IExchangesAPITutorialsProps> = ({ type, store, promoBinance }) => {
  const tutorialFlyout = useRef();

  useEffect(() => {
    const isMobile = window.innerWidth < 768;
    const shouldShow = (isMobile || stores.userInfo.hasRealExchange) ? false : true;

    stores.info.setShowExchangeTutorial(shouldShow);
  }, []);

  if (!stores.info.showExchangeTutorial) {
    return null;
  }

  return (
    <div className='exchange-tutorial' ref={tutorialFlyout as any}>
      <Icon
        type='close'
        className='close-tutorial'
        role='button'
        onClick={() => stores.info.setShowExchangeTutorial(false)}
      />

      <div className='arrow-left'>&nbsp;</div>
      <ExchangeTutorial store={store} type={type} promoBinance={promoBinance} />
    </div>
  );
};

export default observer(ExchangesAPITutorials);
