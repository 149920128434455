import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { getPeriodName } from '../../../../helpers/utils';
import stores from '../../../../stores/index';

@observer
class DetailTextOperators extends Component {
  render() {
    const operatorsStore = this.props.operators;

    if (operatorsStore.length < 1) {
      return null;
    }

    return operatorsStore.map((o, i) => {
      let text = '';

      if (o.t === 'then') {
        text = (
          <strong
            className={`ruleSummaryText__keyWord
                                    ruleSummaryText__keyWord_operator`}
          >
            And THEN
          </strong>
        );
      } else if (o.t === 'or') {
        text = (
          <strong
            className={`ruleSummaryText__keyWord
                                    ruleSummaryText__keyWord_operator`}
          >
            OR
          </strong>
        );
      } else if (o.t === 'wait') {
        text = (
          <React.Fragment>
            <strong
              className={`ruleSummaryText__keyWord
                                  ruleSummaryText__keyWord_operator`}
            >
              And WAIT{' '}
            </strong>
            <strong
              className={`ruleSummaryText__keyWord
                                  ruleSummaryText__keyWord_operator`}
           >{o.wt}</strong>
            <span>{getPeriodName(o.pe)}</span>
          </React.Fragment>
        );
      } else if (o.t === 'parallel') {
        text = (
          <strong
            className={`ruleSummaryText__keyWord
                                    ruleSummaryText__keyWord_operator`}
          >
            {' '}
            And at ANY TIME
          </strong>
        );
      } else if (o.t === 'do_not') {
        let previousElement;

        if (this.props.sequences?.[this.props.sequenceIndex - 1]?.t) {
          previousElement = this.props.sequences?.[this.props.sequenceIndex - 1]?.d?.ta;
        } else {
          previousElement = this.props.sequences?.[this.props.sequenceIndex - 1]?.data
            ?.actions?.[0];
        }

        const doNotMapping = {
          _anycoins: 'any of my coins',
          _samecoins: 'same coin twice',
          _stablecoins: 'stable coins',
        };
        let doNotSymbol = o.dn.map((s) => doNotMapping[s] || s).join(', ');

        text = (
          <React.Fragment>
            <strong
              className={`ruleSummaryText__keyWord
                                        ruleSummaryText__keyWord_operator`}
            >
              BUT do not
            </strong>

            <strong>&nbsp;{previousElement?.at?.toUpperCase()}&nbsp;</strong>

            {doNotSymbol}
          </React.Fragment>
        );
      }

      return (
        <li
          key={i}
          className={`ruleSummaryText__section
                                ruleSummaryText__section_operator
                                ${
                                  stores.addRule.sfpPendingSectionId ===
                                    +this.props.sequenceIndex &&
                                  'ruleSummaryText__section_preloader'
                                }`}
          onClick={() => this.props.handleClick(this.props.sequenceIndex)}
          style={{ paddingLeft: `${this.props.sequenceIndex * 12}px` }}
        >
          {text}
        </li>
      );
    });
  }
}

export default DetailTextOperators;
