export type TCurrencySymbol = string;
export type TCurrencies = Record<
  TCurrencySymbol,
  {
    // value of 1 btc in this currency
    orgPrice: string;
    // value of 1 unit of this currency in BTC
    btcPrice: number;
  }
>;

export type TBalance = {
  asset: string;
  balance: number;
}

export type TAssetSpot = {
  base: string
  crs: string
  growth: number
  logo: string
  marketCap: number
  name: string
  parsed: Record<string, unknown>
  price: number
  uid: string
  volume: number;
}

export type TAssetLeverage = {
  base: string
  cs: number
  index: string
  indexFiat: number
  last: string
  lastFiat: number
  lotSize: number
  quote: string
  symbol: string
  tickSize: number
  type: null // it probably can be smth else than null too
}

export enum EExchangeType {
  SPOT = 'spot',
  LEVERAGE = 'leverage',
}

export type SortAndCalculatePriceWalletsParamsBase = {
  balances: TBalance[];
  exchangeId: string;
  currencies: TCurrencies,
  baseCurrency: TCurrencySymbol,
  tempCurrency: TCurrencySymbol,
}

export type SortAndCalculatePriceWalletsParamsSpot = SortAndCalculatePriceWalletsParamsBase & {
  assets: Record<string, TAssetSpot>;
  exchangeType: EExchangeType.SPOT;
}

export type SortAndCalculatePriceWalletsParamsLeverage = SortAndCalculatePriceWalletsParamsBase & {
  assets: TAssetLeverage[];
  exchangeType: EExchangeType.LEVERAGE;
}

export type SortAndCalculatePriceWalletsParams = SortAndCalculatePriceWalletsParamsSpot | SortAndCalculatePriceWalletsParamsLeverage

export type TCalculateWalletBalanceExchangeParams = Omit<SortAndCalculatePriceWalletsParams, 'balances'> & {
  walletBalance: TBalance,
  assetRounding: number,
  fiatRounding: number;
}

export type TCalculateWalletBalanceExchangeReturn = TBalance & TCalculateWalletBalanceExchangeForSpotOrLeverageReturn & {
  roundedBalance: number,
}

export type TCalculateWalletBalanceExchangeForSpotOrLeverageParams = {
  assets: TCalculateWalletBalanceExchangeParams['assets'], 
  walletBalance: TCalculateWalletBalanceExchangeParams['walletBalance'], 
  exchangeId: TCalculateWalletBalanceExchangeParams['exchangeId'],
  currencies: TCalculateWalletBalanceExchangeParams['currencies'],
  tempCurrency: TCalculateWalletBalanceExchangeParams['tempCurrency'],
}

export type TCalculateWalletBalanceExchangeForSpotOrLeverageReturn = {
  fiatValue: number;
  showFiatValue: boolean;
  baseSymbol: string;
}

export type TWallet = {
  roundedBalance: number;
  fiatValue: number | false;
  showFiatValue: boolean;
  baseSymbol: string;
  asset: string;
  balance: number;
}

export type TCalculateTotalAmountByExchangeParams = {
  exchangeId: string;
  force: boolean;
  wallets: TBalance[];
  assets: Record<string, TAssetSpot>;
  instruments: TAssetLeverage[];
  exchangeType: EExchangeType;
  totalBalance: number;
  currencies: TCurrencies;
  baseCurrency: TCurrencySymbol;
  tempCurrency: TCurrencySymbol;
}