import { SubscriptionType } from 'components/SubscriptionModal';
import { COUPON_ID } from 'helpers/constants';
import { ERROR_MESSAGES, getErrorMessage, handleError } from 'helpers/errors';
import { retryRequest } from 'helpers/helpers';
import history from 'helpers/history';
import http from 'helpers/http';
import { errorNotification, infoNotification } from 'helpers/notification';
import { PAYMENT_STATE } from 'helpers/payment';
import { fbqTracking } from 'helpers/trackings';
import { getDataCookies, setDataCookies } from 'helpers/utils';
import { action, observable } from 'mobx';
import { unblockBlockedPage } from 'pages/AddRulePage';
import { ADVANCED_ONBOARDING_TEMPLATE_PAYWALL_NAME } from 'pages/RulesDashboardPage/Onboarding/utils';
import stores, { Stores } from 'stores';
import {
  Api,
  ChargeBeeClientRequestDto,
  CouponDTO,
  SubscriptionDto,
} from '__generated__/ps.api.client/Api';
import { AbstractStore } from './AbstractStore';
import AddRuleStore from './addRule';

export interface IHandlePaymentAnalytics {
  label?: string;
  value: string | number;
  currency?: string;
}

export const paymentsApi = (() => {
  const api = new Api();
  api.instance = http;
  return api.ps;
})();

export default class PaymentStore extends AbstractStore {
  root: Stores;

  constructor(rootStore: Stores) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  @observable isSubscriptionModalVisible = false;
  @observable subscriptionType: SubscriptionType = '';
  @observable isProcessingSubscription = false;
  @observable userSubscription: SubscriptionDto | undefined;
  @observable coupon: CouponDTO | undefined;
  @observable isFetchingSubscription: boolean = false;
  @observable addRuleStoreInstance: AddRuleStore | null = null

  @action
  setAddRuleStoreInstance(addRuleStoreInstance: AddRuleStore | null) {
    this.addRuleStoreInstance = addRuleStoreInstance;
  }

  @action
  setIsFetchingSubscription(isFetchingSubscription: boolean) {
    this.isFetchingSubscription = isFetchingSubscription;
  }

  @action
  setIsProcessingSubscription(isProcessingSubscription: boolean) {
    this.isProcessingSubscription = isProcessingSubscription;
  }

  @action setUserSubscription = (userSubscription: any) => {
    this.userSubscription = userSubscription;
  };

  @action
  showSubscriptionModal = (subscriptionType: SubscriptionType) => {
    this.subscriptionType = subscriptionType;
    this.isSubscriptionModalVisible = true;
  };

  @action
  hideSubscriptionModal = () => {
    this.isSubscriptionModalVisible = false;
  };

  @action
  getPaymentHostedPage = async (selectedPlan: string, quantity?: number, coupons?: string[]) => {
    const body: ChargeBeeClientRequestDto = {
      item_price_id: selectedPlan,
      quantity: quantity || 1,
      cf_affiliate_id: stores.user.user.user.affiliate_id,
    };

    if (coupons?.length) body.coupons = coupons;

    try {
      const response = await paymentsApi.hostedPagesControllerCreate(body);
      return response?.data;
    } catch (error) {
      const reason: any = error;
      let message = getErrorMessage(error);

      message = message.includes(stores.user.getUserId())
        ? ERROR_MESSAGES.EXISTING_SUBSCRIPTION
        : message;

      message = message.toLowerCase().includes('coupon') ? ERROR_MESSAGES.COUPON_ERROR : message;

      errorNotification(reason?.response?.data?.message || message);
    }
  };

  @action
  getChangePaymentMethodHostedPage = async () => {
    try {
      const response = await paymentsApi.updatePaymentMethodControllerUpdate();
      return response?.data;
    } catch (error) {
      const reason: any = error;
      let message = getErrorMessage(error);

      errorNotification(reason?.response?.data?.message || message);
    }
  };

  @action
  onSuccessfulPaymentMethodChange = async (chargebeeInstance: Record<string, any>) => {
    try {
      chargebeeInstance.closeAll();
    } catch (error) {
      handleError(
        error,
        'Something went wrong while updating your account. Please reload the page'
      );
    }
  };

  getPaymentMessage = (paymentState: string) => {
    let message;
    switch (paymentState) {
      case PAYMENT_STATE.SUCCEEDED:
      case PAYMENT_STATE.SUCCESS:
        message = 'Thank you for your payment. Your account will be updated shortly.';
        break;

      case PAYMENT_STATE.CANCELLED:
        message = 'Your payment was cancelled, please try again or contact product support';
        break;

      default:
        message = 'There has been an issue verifying your payment. Please contact product support.';
        break;
    }

    return message;
  };

  cancelSubscription = async () => {
    try {
      this.setIsProcessingSubscription(true);

      const response = await paymentsApi.subscriptionControllerCancel();
      if (response.data) {
        const stopUserUpdate = () =>
          this.userSubscription?.status === 'cancelled' ||
          this.userSubscription?.status === 'non_renewing';
        await this.updatePaymentUser(stopUserUpdate);

        this.showSubscriptionModal('cancel');
        fbqTracking('trackCustom', 'User cancels plan via Coinrule');
      }
      this.setIsProcessingSubscription(false);

      return response.data;
    } catch (error) {
      errorNotification('Something went wrong. Please try again later');
    }
  };

  keepSubscription = async () => {
    try {
      this.setIsProcessingSubscription(true);

      const response = await paymentsApi.subscriptionControllerKeep();
      if (response.data) {
        const stopUserUpdate = () => this.userSubscription?.status === 'active';
        await this.updatePaymentUser(stopUserUpdate);

        infoNotification('Your subscription has been reactivated');
        fbqTracking('trackCustom', 'User reactivates cancelled subscription plan via Coinrule');
      }
      this.setIsProcessingSubscription(false);

      return response.data;
    } catch (error) {
      errorNotification('Something went wrong. Please try again later');
    }
  };

  @action
  onSuccessfulChargebeeSubscription = async (
    chargebeeInstance: Record<string, any>,
    subscriptionType: SubscriptionType
  ) => {
    try {
      this.setIsProcessingSubscription(true);
      chargebeeInstance.closeAll();

      const {isAppliedOnboardingTemplateAdvanced} = stores.addRule

      // change type to 'onboarding' or promo paywall
      if(isAppliedOnboardingTemplateAdvanced() || stores.addRule.paymentGatePromoVisible) {
        stores.addRule.setPaymentGateVisible({ type: ADVANCED_ONBOARDING_TEMPLATE_PAYWALL_NAME, isVisible: false });
        stores.addRule.setPaymentGateVisible({ type: 'promo', isVisible: false });
      }

      const oldSubscription = this.userSubscription;

      const stopUserUpdate = () => {
        let shouldStop = false;
        const { plan } = stores.user.user;

        if (oldSubscription) {
          shouldStop = this.getSubscriptionPlan(oldSubscription) !== plan.uid;
        } else {
          shouldStop = !plan.uid.includes('starter');
        }

        return shouldStop;
      };

      await this.updatePaymentUser(stopUserUpdate);

      this.setIsProcessingSubscription(false);
      
      setDataCookies(COUPON_ID, '');
      this.setCoupon(undefined);
      

      if(!isAppliedOnboardingTemplateAdvanced()) {
        this.showSubscriptionModal(subscriptionType);
        history.push('/');
        return;
      }
      
      const connectedExchangeInfo = stores.userInfo.exchanges.find((exchange: Record<string, any>) => exchange.uid === stores.exchangeProfile.selectedExchangeUID);
      await stores.addRule.handleLaunchLive({
        exchangeIdForLaunch: connectedExchangeInfo?.id,
        unblockBlockedPage: unblockBlockedPage,
        // we need that instance as the instance not the global store is keeping the data from add rule page (sequence etc)
        storeInstance: this.addRuleStoreInstance
      })

      // after rule creation it is safe to clear it
      this.setAddRuleStoreInstance(null)
      

    } catch (error) {
      handleError(
        error,
        'Something went wrong while updating your account. Please reload the page'
      );
    }
  };

  getSubscriptionPlan = (subscription: SubscriptionDto | undefined) => {
    const { subscription_code } = subscription || {};

    const subscripionCodeArray = subscription_code?.split('-');
    subscripionCodeArray?.pop();

    return subscripionCodeArray?.join('-');
  };

  getSubscriptionAmount = (subscription: SubscriptionDto | undefined) => {
    const { amount_in_cents } = subscription || {};

    if (amount_in_cents) {
      return amount_in_cents / 100;
    }
  };

  @action
  updatePaymentUser = async (shouldStop: (response: any) => boolean) => {
    try {
      const request = () => stores.user.checkUser(true, true);

      const callback = async (response: any) => {
        await this.getUserSubscription();
        return shouldStop(response);
      };

      await retryRequest({ request, retryCount: 13, callback, retryDelay: 1000 });
    } catch (error) {
      handleError(
        error,
        'Something went wrong while updating your account. Please reload the page'
      );
    }
  };

  getUserSubscription = async (): Promise<SubscriptionDto | undefined> => {
    this.setIsFetchingSubscription(true);
    try {
      const response = await paymentsApi.subscriptionControllerGetSubscriptionByCustomerId();

      this.setUserSubscription(response.data);
      this.setIsFetchingSubscription(false);

      return response.data;
    } catch (error) {
      this.setIsFetchingSubscription(false);
    }
  };

  getSubscriptionCancelled = () => {
    const { status } = this.userSubscription || {};

    return status === 'non_renewing' || status === 'cancelled';
  };

  getNextBillingDate = () => {
    const { next_billing_at, current_term_end } = this.userSubscription || {};

    return next_billing_at || current_term_end;
  };

  @action setCoupon = (coupon: CouponDTO | undefined) => {
    this.coupon = coupon;
  };

  handleFetchCoupon = async () => {
    const couponId = getDataCookies(COUPON_ID);

    const message = await this.fetchCoupon(couponId);

    if (message) {
      errorNotification(message);
    }
  };

  fetchCoupon = async (couponId: string) => {
    if (!couponId) return;

    let message;
    try {
      const { data } = await paymentsApi.couponControllerGetCouponById(
        encodeURIComponent(couponId)
      );

      if (data?.status !== 'active') return (message = this.getCouponMessage(404));

      this.setCoupon(data);
    } catch (error) {
      const reason: any = error;
      message = this.getCouponMessage(reason?.response?.status);
      setDataCookies(COUPON_ID, '');
    }

    return message;
  };

  getCouponMessage = (status?: number) => {
    switch (status) {
      case 404:
        return 'Your coupon code is invalid or expired';

      default:
        return 'Something went wrong while verifying coupon. Please try again later';
    }
  };
}
