import type { TypedUseSelectorHook } from 'react-redux';

import { Provider, useSelector as useSelectorFromReactRedux } from 'react-redux';

// for now it it simple react-redux passthrough
export const createStoreProvider = <TState, >() => {
  const StoreProvider = Provider

  const useSelector: TypedUseSelectorHook<TState> = useSelectorFromReactRedux

  return {
    useSelector,
    StoreProvider
  }
}
