import React from 'react'

import type { StackProps, TypographyProps } from '@mui/material'
import type { ExchangeLogoSpriteWithNameProps, RuleStatusBadgeProps } from 'design-system'

import { useTheme, useMediaQuery, Stack, Typography, Divider } from '@mui/material'

import { DateFormatted, ExchangeLogoSpriteWithName, IconHeader, IconPlay, IconStop, Spacer } from 'design-system'

const TypographyStyled = (props: TypographyProps & { bold?: boolean }) => {
  return (
    <Typography
      sx={{
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: props.bold ? 600 : 400,
      }}
      {...props}
    />
  )
}

export interface RuleDescriptionProps extends StackProps {
  iconVariant: 'play' | 'pause'
  statusText: string
  startDate: Date,
  exchange: ExchangeLogoSpriteWithNameProps['exchange']
  exchangeName: ExchangeLogoSpriteWithNameProps['exchangeName']
  executionTimesCounter: RuleStatusBadgeProps['executionTimesCounter']
  executionTimesLimit: number
}

const testId = 'RuleDescription'
export const RuleDescription = (props: RuleDescriptionProps) => {
  const {
    sx,
    iconVariant,
    statusText,
    startDate,
    exchange,
    exchangeName,
    executionTimesCounter,
    executionTimesLimit,
    ...otherProps
  } = props

  const theme = useTheme()

  const isLgAndUp = useMediaQuery(theme.breakpoints.up('lg'))

  const statusTextSplit = statusText.split(' ')
  const statusTextFirstWord = (statusText && statusTextSplit.length > 1) ? `${statusTextSplit[0]} ` : ''
  const statusTextRestWords = (statusText && statusTextSplit.length > 1) ? statusTextSplit.slice(1).join(' ') : statusText

  return (
    <Stack
      data-testid={testId}
      className={testId}
      sx={{
        ...sx
      }}
      {...otherProps}
    >
      <IconHeader
        icon={iconVariant === 'play' ? <IconPlay style={{ transform: 'scale(0.6)' }} /> : <IconStop style={{ transform: 'scale(0.6)' }} />}
      >
        <Typography
          component={'span'}
          sx={{
            fontSize: 'inherit',
            fontWeight: 300,
          }}
        >
          Rule{' '}{statusTextFirstWord}
        </Typography>
        {statusTextRestWords}
      </IconHeader>
      <Spacer y={20} />
      <Stack
        direction={'row'}
      >
        <Spacer x={isLgAndUp ? 43 : 28} />
        <Stack
          direction={isLgAndUp ? 'column' : 'row'}
          flexWrap={'wrap'}
          flexGrow={1}
          gap={isLgAndUp ? 0 : '10px'}
          divider={
            isLgAndUp ?
              <>
                <Spacer y={10} />
                <Divider
                  orientation={'horizontal'}
                />
                <Spacer y={10} />
              </>
              :
              null
          }
        >
          <Stack direction={'row'}>
            <TypographyStyled>
            Exchange:
            </TypographyStyled>
            <Spacer x={10} />
            <ExchangeLogoSpriteWithName 
              exchange={exchange}
              exchangeName={exchangeName}
              TypographyProps={{ sx: { fontWeight: 600, fontSize: '16px', lineHeight: '24px' }}}
            />
          </Stack>
          <Stack direction={'row'}>
            <TypographyStyled>
              Start: 
            </TypographyStyled>
            <Spacer x={10} />
            <TypographyStyled bold={true}>
              <DateFormatted 
                component={React.Fragment}
                date={startDate}
                format={'MM/dd/yyyy'}
              />
            </TypographyStyled>
          </Stack>
          <Stack direction={'row'}>
            <TypographyStyled>
              Actions triggered:
            </TypographyStyled>
            <Spacer x={10} />
            <TypographyStyled bold={true}>
              {executionTimesCounter}/{executionTimesLimit} times
            </TypographyStyled>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default RuleDescription
