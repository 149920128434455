import Spinner, { SPINNER_TYPES } from 'components/Loading/Spinner';
import { DEFAULT_FIAT } from 'constants/globalCoins';
import { getEditMode, IRuleEditModes } from 'helpers/rules';
import { observer } from 'mobx-react';
import DataStudioPage from 'pages/DataStudioPage';
import AddExchangesModal from 'pages/ExchangesPage/AddExchangeModal';
import React from 'react';
import { withRouter } from 'react-router-dom';
import BackHeader from '../../components/BackHeader';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import Disclaimer from '../../components/Footer/Disclaimer';
import GlobalContent from '../../components/GlobalContent';
import GlobalSidebar from '../../components/GlobalSidebar';
import GlobalWrapper from '../../components/GlobalWrapper';
import MobileFooter from '../../components/MobileFooter';
import MobileMenu from '../../components/MobileMenu';
import PaymentGate from '../../components/PaymentGate';
import history from '../../helpers/history';
import { selectTour } from '../../helpers/tour';
import { getDataLocalStorage, setDataLocalStorage } from '../../helpers/utils';
import stores from '../../stores';
import AddRuleStore from '../../stores/addRule';
import RuleTour from '../RulesDashboardPage/RuleTour';
import { CreateTemplateModal } from './CreateTemplateModal';
import Exchange from './Exchange';
import Launch from './Launch';
import RuleSummaryFloating from './RuleSummaryFloatBlock';
import './style.scss';
import Trigger from './Trigger';

let unblock;

export function blockPage() {
  unblock = history.block("Your Rule hasn't been saved. Are you sure you want to leave?");
}

export function unblockBlockedPage() {
  if (unblock) {
    unblock();
  }
}

@observer
class AddRulePage extends React.Component {
  constructor(props) {
    super(props);

    const store = new AddRuleStore();

    this.state = {
      store,
      currency: stores.user.user?.user?.baseCurrency || DEFAULT_FIAT,
      connectExchangeClicked: false,
      editedRuleName: '',
    };

    stores.addRule.setAddRuleParent(this);
  }

  changeCurrency(newCurrency) {
    this.setState({
      currency: newCurrency,
    });
  }

  setConnectExchangeClicked = (connectExchangeClicked) => {
    this.setState({ connectExchangeClicked });
  };

  loadDataAddRule() {
    this.state.store.seqInit.initBaseRule(this.state.store, this);

    const listsPromise = stores.info.getLists();
    const userExchangesPromise = stores.userInfo.getExchanges();

    userExchangesPromise
      .then((authorized) => stores.app.unauthorized(authorized))
      .then(() => {
        listsPromise.then(() => {
          if (getDataLocalStorage('should_restore_rule')) {
            setDataLocalStorage('should_restore_rule', false);
            this.state.store.applyBackup();
          }
        });
      });

    stores.userInfo.getRules(true);
  }

  loadDataEditRule(ruleID) {
    const listsPromise = stores.info.getLists();
    const userExchangesPromise = stores.userInfo.getExchanges();
    const ruleDetailsPromise = stores.userRules.getRuleDetails(ruleID, this.state.store);

    ruleDetailsPromise
      .then((authorized) => stores.app.unauthorized(authorized))
      .then(() => {
        userExchangesPromise.then(() => {
          listsPromise.then(() => {
            const editMode = this.getEditMode(stores.userRules.ruleDetails.s);

            stores.info.updateLastSelectedExchange(this.state.store.rule.ex);
            this.state.store.changeExchange(this.state.store.rule.ex);

            setTimeout(() =>
              this.setState({
                editedRuleName: this.state.store.rule.nam,
              })
            );

            if (editMode === IRuleEditModes.QuickEdit) {
              stores.templatesStore.setIsSelect(true);
            }

            if (
              editMode === IRuleEditModes.QuickEdit ||
              editMode === IRuleEditModes.FullEdit ||
              editMode === IRuleEditModes.Draft
            ) {
              this.state.store.setDataInEditMode(this, this.state.store.rule, editMode);
            } else if (editMode === IRuleEditModes.Clone) {
              this.state.store.setDataInCloneMode(this, this.state.store.rule);
            } else {
              history.push('/');
            }
          });
        });
      });

    stores.userInfo.getRules(true);
  }

  async componentDidMount() {
    const ruleID = this.props.match.params?.id;

    if (!getDataLocalStorage('finished_tour_addRule')) {
      stores.tourGuide.toggleRuleTour('show', true);
      setDataLocalStorage('finished_tour_addRule', true);
    }

    const setLimits = () => {
      let limit = 10;

      if (window.innerWidth <= 500) limit = 3;
      else if (window.innerWidth <= 900) limit = 6;

      stores.templatesStore.setItemsPerPage(limit);
    };

    setLimits();

    await stores.templatesStore.fetchTemplates({ offset: 0 });

    stores.templatesStore.setShowOverlay(false);
    stores.templatesStore.setIsSelect(false);

    if (ruleID) {
      this.loadDataEditRule(ruleID);
    } else {
      this.loadDataAddRule();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(!stores.planStore.plans?.length) {
      console.log('no plans');
      stores.planStore.getPlans();
    }
  }

  blockPage() {
    blockPage();
  }

  componentWillUnmount() {
    unblockBlockedPage();
  }

  renderSequences() {
    return this.state.store.seqInit.sequences.map((sequence) => sequence.component);
  }

  renderSpinner() {
    const { isSending, isLoading } = this.state.store;

    if (isSending || isLoading || stores.ruleDetailsStore.isTemplateApplyingPending)
      return <Spinner type={SPINNER_TYPES.WHOLE_PAGE} />;

    return null;
  }

  handleLaunchLive = ({ event, callback, exchangeIdForLaunch }) => {
    /* Checking "launchLiveWasClicked" value is needed to know is user already created his first rule or not.
    According to that we will show "checkbox" on 1st step ("Create a rule" step) of the "3 steps guide" */

    stores.addRule.handleLaunchLive({
      event,
      callback,
      exchangeIdForLaunch,
      unblockBlockedPage,
      storeInstance: this.state.store 
    });
  };

  getEditMode(ruleStatus = '') {
    if (!ruleStatus) {
      ruleStatus = this.state.store.rule.s;
    }

    return getEditMode(this.props.match.path, ruleStatus);
  }

  renderContent() {
    let headerBack = 'New Rule';
    let headerBack2 = this.state.editedRuleName;

    if (this.state.store.editMode === IRuleEditModes.QuickEdit) {
      headerBack = `Edit Rule`;
    } else if (this.state.store.editMode === IRuleEditModes.FullEdit) {
      headerBack = `Restart Rule`;
    } else {
      headerBack2 = '';
    }

    const showDarkenContentLayer =
      stores.ruleDetailsStore.showTemplatesList && !stores.templatesStore.isSelect;

    return (
      <>
        {this.renderSpinner()}

        <BackHeader
          title1={headerBack}
          title2={headerBack2}
          to={
            this.state.store.editMode === IRuleEditModes.QuickEdit ||
            this.state.store.editMode === IRuleEditModes.FullEdit
              ? `/rule/${this.props.match.params.id}`
              : '/'
          }
        />

        <div className='add-rule-page'>
          <div
            className={`add-rule-page__darkenContent
                            ${showDarkenContentLayer && 'add-rule-page__darkenContent_visible'}`}
            onClick={() => {
              stores.ruleDetailsStore.toggleTemplatesList();
            }}>
            &nbsp;
          </div>

          <Exchange
            store={this.state.store}
            currency={this.state.currency}
            changeCurrency={(newCurrency) => this.changeCurrency(newCurrency)}
            parent={this}
            editMode={this.state.store.editMode}
          />

          <Trigger store={this.state.store} parent={this} currency={this.state.currency} />

          {this.renderSequences()}

          <Launch
            handleLaunchLive={this.handleLaunchLive}
            store={this.state.store}
            parent={this}
            unblockBlockedPage={unblockBlockedPage}
            setConnectExchangeClicked={this.setConnectExchangeClicked}
          />

          <Disclaimer />
        </div>
      </>
    );
  }

  render() {
    const user = stores.user.getUser();
    if (!user) {
      return null;
    }

    const {
      exchangeProfile: { toggleShowAddExchangeModal },
      tourGuide: { toggleRuleTour },
    } = stores;

    const handleCloseModals = () => {
      if (this.state.connectExchangeClicked) toggleRuleTour('show', true);
      toggleShowAddExchangeModal(false);
    };

    return (
      <GlobalWrapper>
        <MobileMenu />

        <RuleTour
          isShowed={stores.tourGuide.isAddRuleTourShowed}
          isRuleNotNew={!!this.state.store.editMode}
          index={stores.tourGuide.showTourIndex}
          typeTour={selectTour()}
          targetTour='addRule'
          store={this.state.store}
          setConnectExchangeClicked={this.setConnectExchangeClicked}
        />

        <AddExchangesModal
          visible={stores.exchangeProfile.showAddExchangeModal}
          store={this.state.store}
          handleLaunchLive={this.handleLaunchLive}
          closeModals={handleCloseModals}
          title={stores.exchangeProfile.addExchangesModalTitle}
        />

        <GlobalSidebar extraClass={`globalWrapper__sidebar`} />
        <GlobalContent extraClass={`globalWrapper__content globalContent_addRulePage`}>
          <PaymentGate
            stores={stores}
            type='trading_volume_100'
            permanent={false}
            visible={stores.addRule.paymentGateTradingVolume100Visible}
            title={
              <>
                {
                  <img
                    src={require('../../assets/images/icons/iconWarning_red.svg')}
                    alt='warning'
                  />
                }
                <br />
                You have reached your monthly allowance
              </>
            }
            subtitle={
              <>
                You are reached 100% of your monthly trading volume.
                <br />
                Upgrade your plan to increase your volume limit.
              </>
            }
          />

          <ErrorBoundary name={'boundary_AddRulePage'}>{this.renderContent()}</ErrorBoundary>

          <RuleSummaryFloating store={this.state.store} isRuleNew={!this.state.store.editMode} />
        </GlobalContent>
        <MobileFooter />

        <CreateTemplateModal
          visible={stores.addRule.showCreateTemplateModal}
          store={this.state.store}
        />

        <DataStudioPage isOnAddRulePage />
      </GlobalWrapper>
    );
  }
}

export default withRouter(AddRulePage);
