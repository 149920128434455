import stores from 'stores'
import type UserStore from 'stores/user'

// @TODO this should be reimplemented to not use legacy mobx store 
const userStore = stores.user as UserStore

export type TDataProviderUser = ReturnType<typeof createDataProviderUser>

export const createDataProviderUser = () => {
  return {
    checkIfUserIsAuthenticated: async (): Promise<boolean> => {
      return userStore.isAuthorized
    },
  }
}
