import React from 'react'
import clsx from 'clsx'

import type { TypedUseSelectorHook } from 'react-redux';

import type { TDomainStateConnectExchangeBinanceOAuth } from 'v2/domains/connectExchangeBinanceOAuth/stateConnectExchangeBinanceOAuth';
import type { TDomainUseCasesConnectExchangeBinanceOAuth } from 'v2/domains/connectExchangeBinanceOAuth/createUseCasesConnectExchangeBinanceOAuth';
import { EAsyncStatus } from 'v2/types';

import { Center, Typography, Loader } from 'design-system'

export interface ViewConnectExchangeBinanceOAuthGetAccessTokenAndCreateApiKeysProps extends React.HTMLProps<HTMLDivElement> {
  code: string,
  callbackSuccess?: () => void,
  callbackError?: () => void,
}

const testId = 'ViewConnectExchangeBinanceOAuthGetAccessTokenAndCreateApiKeys'
export const createViewConnectExchangeBinanceOAuthGetAccessTokenAndCreateApiKeys = ({
  useCases,
  useSelector,
}: {
  useCases: Pick<TDomainUseCasesConnectExchangeBinanceOAuth, 'getAccessTokenAndCreateApiKeys'>,
  useSelector: TypedUseSelectorHook<Pick<TDomainStateConnectExchangeBinanceOAuth, 'getAccessToken' | 'createApiKeys'>>
}) => {
  const ViewConnectExchangeBinanceOAuthGetAccessTokenAndCreateApiKeys = (props: ViewConnectExchangeBinanceOAuthGetAccessTokenAndCreateApiKeysProps) => {
    const {
      code,
      callbackSuccess,
      callbackError,
      ...otherProps
    } = props

    const requestStatusGetAccessToken = useSelector((state) => state.getAccessToken.status)
    const requestStatusCreateApiKeys = useSelector((state) => state.createApiKeys.status)
    const requestErrorGetAccessToken  = useSelector((state) => state.getAccessToken.error)
    const requestErrorCreateApiKeys  = useSelector((state) => state.createApiKeys.error)

    React.useEffect(() => {
      (async () => {
        try {
          await useCases.getAccessTokenAndCreateApiKeys({ code })
          callbackSuccess?.()
        } catch (error) {
          callbackError?.()
        }
      })()
    }, [callbackError, callbackSuccess, code])
  
    return (
      <div
        data-testid={testId}
        className={clsx(testId)}
        style={{ width: '100%', height: '100%' }}
        {...otherProps}
      >
        {
          requestStatusGetAccessToken === EAsyncStatus.REJECTED ?
            <Center padding={'20px'}>
              <Typography
                variant={'h6'}
                align={'center'}
              >
                {requestErrorGetAccessToken?.message}!
              </Typography>
            </Center>
            :
            requestStatusCreateApiKeys === EAsyncStatus.REJECTED ?
              <Center padding={'20px'}>
                <Typography
                  variant={'h6'}
                  align={'center'}
                >
                  {requestErrorCreateApiKeys?.message}!
                </Typography>
              </Center>
              :
              requestStatusGetAccessToken === EAsyncStatus.PENDING ?
                <Center>
                  <Loader />
                </Center>
                :
                requestStatusCreateApiKeys === EAsyncStatus.PENDING ?
                  <Center>
                    <Loader />
                  </Center>
                  :
                  null
        }
      </div>
    )
  }
  
  return ViewConnectExchangeBinanceOAuthGetAccessTokenAndCreateApiKeys
}

export default createViewConnectExchangeBinanceOAuthGetAccessTokenAndCreateApiKeys
