import React, { createRef } from "react";
import { observer } from 'mobx-react';
import ShadowScrollbars from '../../../components/ShadowScrollbars';
import DetailSequenceTexts from '../../../components/Rule/DetailTextSequence';
import stores from '../../../stores';

import './style.scss';
import MediaQuery from 'react-responsive';

@observer
class RuleSummaryFloating extends React.Component {
  state = {
    markerPosition: 0,
    floatingBlockTopOffset: 0,
    contentRef: undefined,
    panelRef: undefined,
  };

  moveMarkerOnScroll = () => {
    let newMarkerPosition;

    if (
      !(this.props.isRuleNew === false) &&
      !this.props.store.isSFPlaunchSectionWasClicked &&
      !stores.templatesStore.isSelect
    ) {
      newMarkerPosition = Math.round((window.scrollY / document.body.scrollHeight) * 100);
    } else {
      newMarkerPosition = Math.round(
        (window.scrollY / (document.body.scrollHeight - document.body.clientHeight)) * 100
      );
    }

    this.setState(() => {
      return { markerPosition: newMarkerPosition };
    });
  };

  previousMoveBlockIteration;
  previousScrollPosition = 0;

  moveFloatingBlockOnScroll = () => {
    if (!document.querySelector('.ruleSummarySequence')) return;

    // ====================================================
    // Throttling
    const throttlingPeriod = 10;
    if (this.previousMoveBlockIteration > Date.now() - throttlingPeriod) return;
    // ====================================================

    const viewPortHeight = document.body.clientHeight;

    // ====================================================
    // Floating block
    const floatingBlock = document.querySelector('.ruleSummarySequence');

    const fbHeight = floatingBlock.getBoundingClientRect().height;
    const minTopOffset = -(fbHeight - viewPortHeight);
    const maxTopOffset = 0;
    let fbTargetTopOffset;

    // If block fit into viewport height than do nothing
    if (fbHeight - viewPortHeight <= 0 && this.state.floatingBlockTopOffset === 0) return;

    // ====================================================
    // Scroll handling
    let scrollDelta = 0;
    const scrollPosition = window.scrollY;

    // Calculate scroll delta
    if (this.previousScrollPosition === 0) scrollDelta = 1;
    else if (this.previousScrollPosition > scrollPosition) scrollDelta = 1;
    else if (this.previousScrollPosition < scrollPosition) scrollDelta = -1;

    let scrollShift = this.previousScrollPosition - scrollPosition;
    scrollShift = scrollShift < 0 ? -scrollShift : scrollShift;

    this.previousScrollPosition = scrollPosition;

    // ====================================================
    // Moving floating block
    fbTargetTopOffset = this.state.floatingBlockTopOffset + scrollShift;

    if (scrollDelta < 0) {
      fbTargetTopOffset = this.state.floatingBlockTopOffset - scrollShift;

      if (fbTargetTopOffset < minTopOffset) return;
    } else if (scrollDelta > 0) {
      fbTargetTopOffset = this.state.floatingBlockTopOffset + scrollShift;

      if (fbTargetTopOffset > maxTopOffset) return;
    }

    this.setState(() => {
      return { floatingBlockTopOffset: fbTargetTopOffset };
    });

    // ====================================================
    // Throttling
    this.previousMoveBlockIteration = Date.now();
    // ====================================================
  };

  handleScroll = () => {
    this.moveMarkerOnScroll();
    this.moveFloatingBlockOnScroll();
  };

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
    stores.addRule.dataStudioVisible = true;
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll);
    this.setState(() => {
      return {
        contentRef: createRef(),
        panelRef: createRef(),
      }
    })
  }

  renderSequenceBlock = (showSmallIndicatorsLines) => {
    const CLS = 'ruleSummaryFlyout';

    return (
      /* Rule Summary Sequence */
      <div
        className={`ruleSummarySequence
																	${CLS}__sequence`}
        style={{ top: `${this.state.floatingBlockTopOffset}px` }}
      >
        <div className={`ruleSummarySequence__wrapper1`}>
          <div className={`ruleSummarySequence__wrapper2`}>
            {/*<div className={`${CLS2}__marker`}>&nbsp;</div>*/}

            <DetailSequenceTexts
              show={true}
              type={'base'}
              store={this.props.store}
              showTriggerText={true}
              showExchange={true}
              markerPosition={this.state.markerPosition}
              isRuleNew={this.props.isRuleNew}
              showMarker={true}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const CLS = 'ruleSummaryFlyout';
    const contentHeight = this.state.contentRef?.current?.offsetHeight + 135;
    const panelHeight = this.state.panelRef?.current?.offsetHeight;
    const move = ((this.state.markerPosition / 100) * Math.max(0, contentHeight - panelHeight)) || 0;

    return (
      // Rule Summary Flyout
      <div
        className={`ruleSummaryFlyout
											${!stores.addRule.isSequenceFloatingPanelOpened && 'ruleSummaryFlyout_closed'}`}
      >
        <div
          className={`${CLS}__screenDim`}
          onClick={() => stores.addRule.switchSequenceFloatingPanel('close')}
        >
          &nbsp;
        </div>

        <div className={`${CLS}__flyoutPanel`}>
          <div
            className={`${CLS}__smallIndicators
													${stores.addRule.isSequenceFloatingPanelOpened && `${CLS}__smallIndicators_hidden`}`}
            ref={this.state.panelRef}
          >
            <div style={{
              position: "absolute",
              height: `calc(100% - 190px + ${move}px)`,
              top: `calc(85px - ${move}px)`
            }}>
              <DetailSequenceTexts
                show={true}
                type={'base'}
                store={this.props.store}
                showTriggerText={true}
                showExchange={true}
                markerPosition={this.state.markerPosition}
                isRuleNew={this.props.isRuleNew}
                showMarker={true}
                contentRef={this.state.contentRef}
              />
            </div>
          </div>

          <MediaQuery maxDeviceWidth={767}>
            <ShadowScrollbars className={`${CLS}__flyoutPanelWrap1`}>
              <div className={`${CLS}__flyoutPanelWrap2`}>{this.renderSequenceBlock(true)}</div>
            </ShadowScrollbars>
          </MediaQuery>

          <MediaQuery minDeviceWidth={768}>
            <div className={`${CLS}__flyoutPanelWrap1`}>
              <div className={`${CLS}__flyoutPanelWrap2`}>{this.renderSequenceBlock()}</div>
            </div>
          </MediaQuery>

          {!stores.addRule.isSequenceFloatingPanelOpened && (
            <div
              className={`${CLS}__openBut`}
              onClick={() => stores.addRule.switchSequenceFloatingPanel('open')}
            >
              <div className={`${CLS}__openButText`}>Summary</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default RuleSummaryFloating;
