export enum EActivityLogTradeTypes {
  RULE_ERROR_PUBLIC = 'RULE:ERROR:PUBLIC',
  TRADE_SELL = 'TRADE:SELL',
  TRADE_WAIT = 'TRADE:WAIT',
  TRADE_BUY = 'TRADE:BUY',
  SELL = 'sell',
  BUY = 'buy',
  TRADE = 'TRADE',
  DEMO_EXCHANGE = 'demo exchange',
  API_KEY = 'apikey',
  EX_REJECT = 'exreject',
  BALANCE = 'balance',
  MARGIN = 'margin',
  LOW_AMOUNT = 'lowamount',
  SOURCE_INCORRECT = 'sourceincorrect',
  ORDER_NOT_EXECUTED = 'order_not_executed',
  PAIR_NOT_AVAILABLE = 'pair_not_available',
  SYSTEM_UPGRADE = 'system_upgrade',
  RULE_CREATE = 'RULE:CREATE',
  RULE_UPDATE = 'RULE:UPDATE',
  RULE_ARCHIVED = 'RULE:ARCHIVED',
  RULE_DELETE = 'RULE:DELETE',
  RULE_FINISHED = 'RULE:FINISHED',
  RULE_PAUSED = 'RULE:PAUSED',
  RULE_ENABLED = 'RULE:ENABLED',
  RULE_EXECUTE = 'RULE:EXECUTE',
  RULE_ALERT = 'RULE:ALERT',
  CONDITION_MET = 'CONDITION:MET',
  CONDITIONS_MET = 'CONDITIONS:MET',
  MIN_LEVERAGE_ORDER_SIZE = 0.0001,
  STANDARD_LEVERAGE_LOT_SIZE = 1,
}