import React from 'react'

import type { BoxProps} from '@mui/material'

import { useTheme, Stack, Box, Typography } from '@mui/material'

import type { EShareButtonsClickedSource } from 'design-system'
import { IconGlobe, IconEye, DropdownItemThreeLine, ShareButtons, ImageShareRule, CopyLinkArea, Spacer, Button } from 'design-system'

export enum EFormShareRuleCopyLinkSource {
  CopyLinkButton = 'copy-link-button',
  CopyLinkArea = 'copy-link-area',
}

export interface FormShareRuleProps extends BoxProps {
  link: string,
  ruleName: string,
  callbackTextCopiedSuccess?: (source: EFormShareRuleCopyLinkSource) => void,
  callbackTextCopiedError?: ({ error }: { error: unknown }) => void,
  callbackShareLinkClicked?: (source: EShareButtonsClickedSource) => void,
}

const testId = 'FormShareRule'
export const FormShareRule = (props: FormShareRuleProps) => {
  const {
    sx,
    link,
    ruleName,
    callbackTextCopiedSuccess,
    callbackTextCopiedError,
    callbackShareLinkClicked,
    ...otherProps
  } = props

  const theme = useTheme()

  const copyLinkToClipboard = (source: EFormShareRuleCopyLinkSource) => {
    try {
      navigator.clipboard.writeText(link)
      callbackTextCopiedSuccess?.(source)
    } catch(error){
      callbackTextCopiedError?.({ error })
    }
  }

  return (
    <Box
      data-testid={testId}
      className={testId}
      sx={{
        ...sx
      }}
      {...otherProps}
    >
      <Stack alignItems={'center'}>
        <ImageShareRule />
        <Spacer y={30} />
        <Typography variant={'h5'}>
          Share Rule With Friends
        </Typography>
        <Spacer y={30} />
        <CopyLinkArea
          onClick={() => copyLinkToClipboard(EFormShareRuleCopyLinkSource.CopyLinkArea)}
          scrollX={true}
        >
          {link}
        </CopyLinkArea>
        <Spacer y={30} />
        <DropdownItemThreeLine 
          primary={
            <Stack
              direction={'row'}
              gap={'10px'}
              alignItems={'center'}
            >
              Viewer 
            </Stack>
          }
          secondary={'Restricted view  |  Rule logic hidden'}
          tertiary={'Any users with this link can only see the chart and the trading history'}
          icons={
            <Stack
              direction={'row'}
              gap={'10px'}
              sx={{ fontSize: '24px' }}
            >
              <IconGlobe />
              <IconEye />
            </Stack>
          }
        />
        <Spacer y={30} />
        <Button 
          colorVariant={'marketing'}
          sx={{ height: 53 }}
          onClick={() => copyLinkToClipboard(EFormShareRuleCopyLinkSource.CopyLinkButton)}
        >
          Copy Link
        </Button>
        <Spacer y={20} />
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: 18,
            lineHeight: '22px',
            color: theme.palette.coinrule.basic.grey
          }}
        >
          Or share via
        </Typography>
        <Spacer y={20} />
        <ShareButtons 
          link={link}
          shareTitle={ruleName}
          onClickCallback={callbackShareLinkClicked}
        />
      </Stack>
    </Box>
  )
}

export default FormShareRule
