import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button } from 'antd';
import './style.scss';

import stores from 'stores';
import { PAGES, STEPS } from 'stores/auth';
import AuthWindow from 'components/Auth';
import EmailField from 'components/Auth/EmailField';
import PasswordField from 'components/Auth/PasswordField';
import TwoFaField from 'components/Auth/TwoFaField';
import AdditionalLinks from 'components/Auth/AdditionalLinks';
import SocialMedia from 'components/Auth/SocialMedia';
import AuthCode from 'components/Auth/Code';
import PinCode from 'components/Auth/Pin';
import Loading from 'components/Loading';
import { CoinruleButton } from '../../components/CoinruleButton';
import { ErrorBoundary } from '../../components/ErrorBoundary';

@observer
class AuthPage extends Component {
  interval;

  constructor(props) {
    super(props);

    stores.auth.setHistory(props.history);
    if (stores.auth.currentPage === PAGES.GOOGLE || stores.auth.currentPage === PAGES.FACEBOOK) {
      stores.auth.setStep(STEPS.TWO_FA);
    } else {
      stores.auth.setPage(this.props.location.pathname || PAGES.LOGIN);
    }
  }

  componentDidMount() {
    //stores.auth.securityHashLoop();
  }

  componentDidUpdate() {
    if (this.props.location.pathname !== stores.auth.currentPage) {
      stores.auth.setPage(this.props.location.pathname);
    }

    if (this.props.location.state && this.props.location.state.step) {
      if (this.props.location.state.step !== stores.auth.currentStep) {
        stores.auth.setStep(this.props.location.state.step);
      }

      this.props.location.state.step = null;
      this.props.history.replace(this.props.location.pathname, this.props.location.state);
    }
  }

  openIntercom() {
    window.Intercom('show');
  }

  isButtonDisabled() {
    let disabled = false;

    if (
      stores.auth.currentStep === STEPS.EMAIL ||
      stores.auth.currentStep === STEPS.EMAIL_PASSWORD
    ) {
      if (!stores.auth.isValidEmail) {
        disabled = true;
      }
    }

    if (
      stores.auth.currentPage === PAGES.FORGOT_PASSWORD &&
      stores.auth.currentStep === STEPS.EMAIL
    ) {
      if (!stores.auth.isValidPin) {
        disabled = true;
      }
    }

    if (
      stores.auth.currentStep === STEPS.PASSWORD ||
      stores.auth.currentStep === STEPS.EMAIL_PASSWORD ||
      stores.auth.currentStep === STEPS.CODE_PASSWORD ||
      stores.auth.currentStep === STEPS.PASSWORD_2 ||
      stores.auth.currentStep === STEPS.CODE_PASSWORD_2
    ) {
      if (!stores.auth.isValidPassword) {
        disabled = true;
      }
    }

    if (
      stores.auth.currentStep === STEPS.CODE ||
      stores.auth.currentStep === STEPS.CODE_PASSWORD ||
      stores.auth.currentStep === STEPS.CODE_PASSWORD_2
    ) {
      if (!stores.auth.isValidCode) {
        disabled = true;
      }
    }

    return disabled;
  }

  showLoadingPage() {
    return <Loading text={stores.auth.loadingPageText} />;
  }

  handleSubmitLogin(e) {
    e.preventDefault();
    stores.auth.submitLogin();
  }

  handleSubmitRegister(e) {
    e.preventDefault();
    stores.auth.submitRegister();
  }

  handleSubmitForgotPassword(e) {
    e.preventDefault();
    stores.auth.submitForgotPassword();
  }

  renderEmailField() {
    if (
      stores.auth.currentStep === STEPS.EMAIL ||
      stores.auth.currentStep === STEPS.EMAIL_PASSWORD
    ) {
      return <EmailField />;
    }
  }

  renderPasswordField() {
    if (
      stores.auth.currentStep === STEPS.PASSWORD ||
      stores.auth.currentStep === STEPS.PASSWORD_2 ||
      stores.auth.currentStep === STEPS.EMAIL_PASSWORD ||
      stores.auth.currentStep === STEPS.CODE_PASSWORD
    ) {
      return <PasswordField />;
    }
  }

  renderTwoFABlock() {
    if (stores.auth.currentStep === STEPS.TWO_FA) {
      return <TwoFaField />;
    }
  }

  renderPinFieldDescription() {
    if (stores.auth.currentStep === STEPS.EMAIL) {
      return (
        <div className='authWindow__textMessage authWindow__textMessage_fullWidth'>
          <div>
            Please provide <b>PIN</b> from our Support Team
          </div>
          <div>
            <Button type='link' onClick={this.openIntercom} className='authWindow__link'>
              Get the PIN now
            </Button>
          </div>
        </div>
      );
    }
  }

  renderPinField() {
    if (stores.auth.currentStep === STEPS.EMAIL) {
      return <PinCode />;
    }
  }

  renderRecaptcha() {
    if (stores.auth.suspicious && stores.auth.currentStep !== STEPS.FINAL) {
      return (
        <div className='captchaContainer'>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={(hash) => stores.auth.handleCaptchaChange(hash)}
          />
        </div>
      );
    }
  }

  renderSubmitButton(handler) {
    if (stores.auth.submitText) {
      const tfaCondition = stores.auth.currentStep === STEPS.TWO_FA;
      return (
        <CoinruleButton
          fillType='solid'
          icon={stores.auth.showSpinnerOnCTAbut ? 'Spinner' : 'CaretRight'}
          disabled={this.isButtonDisabled() || stores.auth.showSpinnerOnCTAbut}
          tabIndex='7'
          className={`authWindow__submitBut${tfaCondition ? '_twofa' : ''}`}
          component={'button'}
          onClick={handler}
        >
          {stores.auth.submitText}
        </CoinruleButton>
      );
    }
  }

  renderSocialMedia() {
    if (
      stores.auth.currentStep === STEPS.EMAIL ||
      stores.auth.currentStep === STEPS.EMAIL_PASSWORD
    ) {
      return <SocialMedia />;
    }
  }

  renderConditionsBlock() {
    if (stores.auth.currentStep === STEPS.EMAIL_PASSWORD) {
      return (
        <div
          className='authWindow__textMessage
                          authWindow__textMessage_fullWidth
                          authWindow__textMessage_termsNconditions'
        >
          <p>
            By signing up I agree to the{' '}
            <a href='https://coinrule.com/terms.html' target='_blank' rel='noopener noreferrer'>
              terms of use
            </a>{' '}
            and processing
            <br />
            of my personal data as stated in the{' '}
            <a href='https://coinrule.com/privacy.html' target='_blank' rel='noopener noreferrer'>
              privacy policy
            </a>
          </p>
        </div>
      );
    }
  }

  renderAccountCreatedBlock() {
    if (stores.auth.currentStep === STEPS.FINAL) {
      return (
        <React.Fragment>
          <div className='authWindow__textMessage'>
            <p>Your account has been created</p>
          </div>

          <div className='authWindow__checkIcon'>&nbsp;</div>
        </React.Fragment>
      );
    }
  }

  renderEmailNotificationBlock() {
    if (stores.auth.currentStep === STEPS.CODE || stores.auth.currentStep === STEPS.CODE_PASSWORD) {
      return (
        <div className='authWindow__textMessage authWindow__textMessage_fullWidth'>
          <p>
            We've sent a verification code to your email <b>{stores.auth.email}</b>
          </p>
        </div>
      );
    }
  }

  renderEnterPasswordBlock() {
    if (stores.auth.currentStep === STEPS.CODE_PASSWORD) {
      return (
        <div className='authWindow__textMessage authWindow__textMessage_fullWidth'>
          <p>Enter your new password</p>
        </div>
      );
    }
  }

  renderResendCodeLink() {
    if (stores.auth.currentStep === STEPS.CODE || stores.auth.currentStep === STEPS.CODE_PASSWORD) {
      return (
        <div
          className='authWindow__textMessage
                        authWindow__textMessage_fullWidth
                        authWindow__textMessage_additionalFormLink'
        >
          <p>
            {stores.auth.currentPage === PAGES.REGISTER && (
              <>
                Haven't got the code? Your account may already <NavLink to={{ pathname: '/login', state: { step: STEPS.EMAIL_PASSWORD } }}>exist</NavLink>. 
              </>
            )}
            
            <Button type='link' onClick={stores.auth.resendCode} className='authWindow__link'>
              Resend code
            </Button>
          </p>
        </div>
      );
    }
  }

  renderCode() {
    if (
      stores.auth.currentStep === STEPS.CODE ||
      stores.auth.currentStep === STEPS.CODE_PASSWORD ||
      stores.auth.currentStep === STEPS.CODE_PASSWORD_2
    ) {
      return <AuthCode />;
    }
  }

  renderPasswordResetBlock() {
    if (stores.auth.currentStep === STEPS.FINAL) {
      return (
        <React.Fragment>
          <div className='authWindow__checkIcon'>&nbsp;</div>
        </React.Fragment>
      );
    }
  }

  renderLoginPage() {
    return (
      <React.Fragment>
        {this.renderPasswordResetBlock()}
        {this.renderEmailField()}
        {this.renderEmailNotificationBlock()}
        {this.renderCode()}
        {this.renderResendCodeLink()}
        {this.renderEnterPasswordBlock()}
        {this.renderPasswordField()}
        {this.renderRecaptcha()}
        {this.renderTwoFABlock()}
        {this.renderSubmitButton(this.handleSubmitLogin)}
        {this.renderSocialMedia()}
      </React.Fragment>
    );
  }

  renderRegisterPage() {
    return (
      <React.Fragment>
        {this.renderEmailField()}
        {this.renderPasswordField()}
        {this.renderConditionsBlock()}
        {this.renderEmailNotificationBlock()}
        {this.renderCode()}
        {this.renderRecaptcha()}
        {this.renderAccountCreatedBlock()}
        {this.renderSubmitButton(this.handleSubmitRegister)}
        {this.renderResendCodeLink()}
        {this.renderSocialMedia()}
      </React.Fragment>
    );
  }

  renderForgotPasswordPage() {
    return (
      <React.Fragment>
        {this.renderPasswordField()}
        {this.renderPasswordResetBlock()}
        {this.renderEmailField()}
        {this.renderPinFieldDescription()}
        {this.renderPinField()}
        {this.renderEmailNotificationBlock()}
        {this.renderCode()}
        {this.renderRecaptcha()}
        {this.renderSubmitButton(this.handleSubmitForgotPassword)}
        {this.renderResendCodeLink()}
      </React.Fragment>
    );
  }

  renderPage(page) {
    switch (page) {
      default:
      case PAGES.LOGIN:
        return this.renderLoginPage();

      case PAGES.REGISTER:
        return this.renderRegisterPage();

      case PAGES.FORGOT_PASSWORD:
        return this.renderForgotPasswordPage();
    }
  }

  render() {
    if (stores.auth.showLoadingPage) {
      return this.showLoadingPage();
    }
    return (
      <AuthWindow title={stores.auth.title}>
        <ErrorBoundary name={'boundary_AuthPage'}>
          {this.renderPage(stores.auth.currentPage)}
        </ErrorBoundary>
        <AdditionalLinks />
      </AuthWindow>
    );
  }
}

export default withRouter(AuthPage);
