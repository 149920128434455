import BinanceLogo from 'assets/images/exchanges/binance_logo.svg';
import CoinbaseLogo from 'assets/images/exchanges/coinbase-pro.svg';
import HitBtcLogo from 'assets/images/exchanges/hitbtc.svg';
import BitstampLogo from 'assets/images/exchanges/bitstamp.svg';
import KrakenLogo from 'assets/images/exchanges/kraken.svg';
import BitpandaLogo from 'assets/images/exchanges/bitpanda.svg';

export const WATCH_LIST = 'WATCH_LIST';

export const ONBOARDING_CLOSED = 'ONBOARDING_CLOSED';
export const USER_ONBOARDING_TEMPLATE = 'USER_ONBOARDING_TEMPLATE';

export const LAST_IS_AUTHORIZED = 'LAST_IS_AUTHORIZED';

export const getOnboardingClosedKey = (userId: string) => {
  return `${ONBOARDING_CLOSED}_${userId}`;
};

export const TEMPLATE_BUTTON_CLICKED = 'TEMPLATE_BUTTON_CLICKED';

export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

export const SMART_GUIDE_TOUR_BUTTON_SEEN = 'SMART_GUIDE_TOUR_BUTTON_SEEN';

export const paymentGateExchangesLogos = [
  {
    alt: 'Binance logo',
    src: BinanceLogo,
  },
  {
    alt: 'Coinbase logo',
    src: CoinbaseLogo,
  },
  {
    alt: 'HitBTC logo',
    src: HitBtcLogo,
  },
  {
    alt: 'Bitstamp logo',
    src: BitstampLogo,
  },
  {
    alt: 'Kraken logo',
    src: KrakenLogo,
  },
  {
    alt: 'Bitpanda logo',
    src: BitpandaLogo,
  },
];

export const exchangesUIDs = {
  BINANCE: 'binance',
  KRAKEN: 'kraken',
  DEMO: 'demo',
};

export const COUPON_URL_KEY = 'coupon_url';
export const COUPON_ID = 'coupon_id';

export const STORAGE_ACTIONS = {
  READ: 'read',
  SAVE: 'save',
  DELETE: 'delete',
};
