export type TDataProviderAnalytics = ReturnType<typeof createDataProviderAnalytics>

export const createDataProviderAnalytics = ({
  mixpanel
}: {
  mixpanel: Mixpanel
}) => {
  return {
    track: (eventName: string, properties?: Record<string, unknown>): Promise<void> => {
      return new Promise((resolve) => {
        mixpanel.track(eventName, properties, () => resolve())
      })
    }
  }
}
