import React from 'react'

import type { GridProps } from '@mui/material'

import { useMediaQuery, useTheme, Box, Grid, Divider } from '@mui/material'

import { Center, Paper, Spacer } from 'design-system'

const MAIN_PADDING_LG_AND_UP = 100
const MAIN_PADDING_BETWEEN_SM_AND_LG = 33
const MAIN_PADDING_UNDER_SM = 16
const MAIN_SPACING = 30
const MAIN_SPACINGG_UNDER_SM = 16

export interface LayoutRuleDetailsProps extends GridProps {
  contentBackButton?: React.ReactNode
  headerTitle: React.ReactNode
  contentActions?: React.ReactNode
  headerPerformance: React.ReactNode
  contentStatus: React.ReactNode
  contentChart: React.ReactNode
  contentDescription: React.ReactNode
  contentSequences: React.ReactNode
  headerHistory?: React.ReactNode
  contentHistory?: React.ReactNode
  actionHistory?: React.ReactNode
  footerHistory?: React.ReactNode
}

const testId = 'LayoutRuleDetails'
export const LayoutRuleDetails = (props: LayoutRuleDetailsProps) => {
  const {
    sx,
    contentBackButton,
    headerTitle,
    contentActions,
    headerPerformance,
    contentStatus,
    contentChart,
    contentDescription,
    contentSequences,
    headerHistory,
    contentHistory,
    actionHistory,
    footerHistory,
    ...otherProps
  } = props

  const theme = useTheme()

  const isXlAndUp = useMediaQuery(theme.breakpoints.up('xl'))
  const isLgAndUp = useMediaQuery(theme.breakpoints.up('lg'))
  const isSmAndDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      container={true}
      sx={{
        ...sx,
      }}
      {...otherProps}
    >
      <Grid
        data-testid={testId}
        className={testId}
        container={true}
        item={true}
        spacing={isSmAndDown ? `${MAIN_SPACINGG_UNDER_SM}px` : `${MAIN_SPACING}px`}
        sx={{
          padding: isLgAndUp ? 
            `0px ${MAIN_PADDING_LG_AND_UP}px` 
            :
            isSmAndDown ? 
              `0px ${MAIN_PADDING_UNDER_SM}px` 
              :
              `0px ${MAIN_PADDING_BETWEEN_SM_AND_LG}px`,
        }}
      >
        {/* BACK BTN, RULE NAME AND LARGE DESKTOP ACTIONS */}
        <Grid
          item={true}
          container={true}
          sx={{
            position: 'relative',
          }}
        >
          {
            contentBackButton ?
              <Grid
                xs={2}
                sm={1}
                item={true}
                container={true}
                justifyContent={isLgAndUp ? 'center' : 'flex-start'}
                alignItems={'center'}
                sx={ 
                  isLgAndUp ? 
                    {
                      position: 'absolute',
                      left: `-${MAIN_PADDING_LG_AND_UP-MAIN_SPACING}px`,
                      width: `${MAIN_PADDING_LG_AND_UP}px`,
                      maxWidth: `${MAIN_PADDING_LG_AND_UP}px`,
                      height: `calc(100% - ${MAIN_SPACING}px)`
                    }
                    :
                    {}
                }
              >
                {contentBackButton}
              </Grid>
              : 
              null
          }
          <Grid
            item={true}
            xs={contentBackButton ? 10 : 12}
            sm={contentBackButton ? 11 : 11}
            xl={6}
            lg={12}
          >
            {headerTitle}
          </Grid>
          { 
            contentActions ?
              <Grid
                item={true}
                xl={6}
                sx={{
                // show only on XL 
                  display: {
                    xs: 'none',
                    xl: 'flex',
                  }
                }}
              >
                {contentActions}
              </Grid>
              :
              <Spacer y={20} />
          }
        </Grid>
      
        {/* STATUS, HALF SCREEN CHART AND SMALLER SCREENS ACTIONS */}
        <Grid
          item={true}
          container={true}
          style={isXlAndUp ? {} : { paddingTop: 0}}
          spacing={isXlAndUp ? `${MAIN_SPACING}px` : 0}
        >
          <Grid
            item={true}
            xs={12}
            lg={6}
            sx={{
            // hide on bigger screens
              display: {
                xs: 'flex',
                xl: 'none',
              },
            }}
          >
            {
              contentActions ?
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    '&:after': isLgAndUp ? {} : {
                      content: '""',
                      width: 'calc(100% + 200px)',
                      height: '1px',
                      position: 'absolute',
                      top: '0',
                      left: -100,
                      background: 'rgba(0, 0, 0, 0.08)',
                    },
                    '&:before': isLgAndUp ? {} : {
                      content: '""',
                      width: 'calc(100% + 200px)',
                      height: '1px',
                      position: 'absolute',
                      bottom: '0',
                      left: -100,
                      background: 'rgba(0, 0, 0, 0.08)',
                    }
                  }}
                >
                  {contentActions}
                </Box>
                :
                <Spacer y={20} />
            }
          </Grid>
          <Grid
            item={true}
            xs={12}
            // if there are no content actions ti will still be full width
            lg={contentActions ? 6 : 12}
            xl={6}
          >
            {
              isXlAndUp ?
                <Paper
                  sx={{
                    padding: '20px 30px',
                  }}
                >
                  <Center>
                    {contentStatus}
                  </Center>
                </Paper>
                :
                contentStatus
            }
          </Grid>
          <Grid
            item={true}
            container={true}
            sx={{
            // hide on smaller screens
              display: {
                xs: 'none',
                xl: 'flex',
              }
            }}
            xl={6}
          >
            <Grid
              container={true}
              item={true}
            >
              <Paper
                sx={{
                  padding: '20px 10px 0px 10px'
                }}
              >
                <Center>
                  {contentChart}
                </Center>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      
        {/* FULL WIDTH CHART (MOBILE AND TABLET)  */}
        <Grid
          item={true}
          container={true}
          sx={{
          // hide on bigger screens
            display: {
              xs: 'flex',
              xl: 'none',
            }
          }}
          xs={12}
          xl={6}
        >
          <Grid
            item={true}
          >
            {headerPerformance}
            <Spacer y={10} />
          </Grid>
          <Grid
            container={true}
            item={true}
          >
            <Paper
              sx={{
                padding: '20px 10px 0px 10px'
              }}
            >
              {contentChart}
            </Paper>
          </Grid>
        </Grid>

        {/* DESCRIPTION AND SEQUENCES */}
        <Grid
          item={true}
          container={true}
        >
          <Paper
            sx={{
              padding: { md: '32px 0px 32px 0px', xs: '16px 0px 16px 0px' }
            }}
          >
            <Grid
              item={true}
              container={true}
            >
              <Grid
                item={true}
                sx={{ padding: { xs: '0px 20px', md: '0px 42px'} }}
                xs={12}
                lg={5}
                xl={4}
              >
                {contentDescription}
              </Grid>
              <Grid
                item={true}
                container={true}
                direction={'column'}
                alignItems={'center'}
                sx={{ display: { xs: 'flex', lg: 'none' } }}
                xs={12}
              >
                <Spacer y={20}/>
                <Divider
                  orientation={'horizontal'}
                  sx={{ width: 'calc(100% - 84px)' }}
                />
                <Spacer y={20}/>
              </Grid>
              <Grid
                item={true}
                sx={{ 
                  padding: { xs: '0px 20px', md: '0px 42px'},
                  // divider
                  position: 'relative',
                  '&:before': isLgAndUp ? {
                    content: '""',
                    position: 'absolute',
                    left: '0',
                    top: '42px',
                    height: 'calc(100% - 68px)',
                    borderLeft: `1px ${theme.palette.coinrule.basic.greyBorder} solid`,
                  } : {}
                }}
                xs={12}
                lg={7}
                xl={8}
              >
                {contentSequences}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* HISTORY */}
      {
        (headerHistory || contentHistory) ?
          <Grid
            item={true}
            container={true}
            direction={'column'}
            sx={{
              marginTop: isSmAndDown ? `${MAIN_SPACINGG_UNDER_SM}px` : `${MAIN_SPACING}px`,
              padding: isLgAndUp ? 
                `0px ${MAIN_PADDING_LG_AND_UP}px` 
                :
                isSmAndDown ? 
                  `0px` 
                  :
                  `0px ${MAIN_PADDING_BETWEEN_SM_AND_LG}px`,
            }}
          >
            {
              headerHistory ?
                <Grid
                  item={true}
                  sx={{
                    marginBottom: '20px',
                    paddingLeft: isSmAndDown ? `${MAIN_SPACINGG_UNDER_SM}px` : 0,
                  }}
                >
                  {headerHistory}
                </Grid>
                :
                null
            }
            <Grid
              item={true}
              container={true}
            >
              {
                contentHistory ?
                  <Paper
                    sx={{
                      padding: '18px 30px 28px 30px',
                      flexDirection: 'column',
                      [theme.breakpoints.down('md')]: {
                        padding: '18px 15px 28px 15px',
                      },
                      [theme.breakpoints.down('sm')]: {
                        borderRadius: 0
                      }
                    }}
                  >
                    <Box sx={{marginBottom: '10px'}}>
                      {actionHistory}
                    </Box>

                    {contentHistory}


                    <Box sx={{marginTop: '30px'}}>
                      {footerHistory}
                    </Box>
                  </Paper>
                  :
                  null
              }
            </Grid>
          </Grid>
          :
          null
      }
    </Box>
  )
}

export default LayoutRuleDetails
