import React, { useState, useRef, useEffect } from 'react';
import './style.scss';
import { ReactComponent as SearchIcon } from '../../../../assets/images/icons/searchIcon.svg';
import { decideIconClassName } from 'helpers/utils';
import ScrollBar from 'components/ScrollBar';

const SelectDropdown = ({
  options = [],
  onChange,
  className = '',
  value,
  placeholder = '',
  isSelecting,
}) => {
  const [query, setQuery] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (value) setQuery(value?.pair);
  }, [value]);

  const filter = (options) => {
    return options.filter((option) => option.pair.toLowerCase().indexOf(query.toLowerCase()) > -1);
  };

  const filteredOptions = filter(options);

  return (
    <div
      className={`SelectDropdown ${className}`}
      style={{ zIndex: isSelecting ? 10 : -1 }}
      role='combobox'
      aria-expanded={isSelecting}
      aria-controls='SelectDropdown__control'
    >
      <div className='SelectDropdown__control' id='SelectDropdown__control'>
        <div className='SelectDropdown__selected-value'>
          <input
            autoFocus={true}
            className='SelectDropdown__input'
            type='text'
            ref={inputRef}
            placeholder={value?.pair || placeholder}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            value={query}
          />
        </div>

        <SearchIcon />
      </div>

      <div className={`SelectDropdown__options open`} role='list'>
        <ScrollBar style={{ height: 250 }}>
          {filteredOptions.length ? (
            filteredOptions.map((option) => {
              const { from, exchangeName, to, exchangeId } = option || {};
              const key = `${from}_${to}_${exchangeName}_${exchangeId}`;
              const fromValue = value
                ? `${value.from}_${value.to}_${exchangeName}_${exchangeId}`
                : '';

              return (
                <div
                  className={`SelectDropdown__option ${key === fromValue ? 'selected' : ''}`}
                  onClick={() => {
                    onChange(option);
                  }}
                  key={key}
                  role='option'
                  aria-selected={key === fromValue}
                >
                  <p
                    className='SelectDropdown__option-pair'
                    data-testid='SelectDropdown__option-pair'
                  >
                    {from}
                    {to}
                  </p>
                  <p className='SelectDropdown__option-names'>
                    {from}/{to}
                  </p>
                  <div className='SelectDropdown__option-coin'>
                    {exchangeName}

                    <div className={decideIconClassName('ExchangePairDropdown', exchangeName)} />
                  </div>
                </div>
              );
            })
          ) : (
            <div className='SelectDropdown__empty'>Option not found</div>
          )}
        </ScrollBar>
      </div>
    </div>
  );
};

export default SelectDropdown;
