import React from 'react'
import { useTheme, Box, Typography } from '@mui/material'
import type { TimelineItemProps as MUITimelineItemProps } from '@mui/lab'
import { TimelineItem as MUITimelineItem, TimelineSeparator, TimelineContent, TimelineConnector } from '@mui/lab'
import { IconCopy, TimelineDot, ExternalLink, ImageWithCenteredIconOnTheTop, CopyLinkArea } from 'design-system'

export interface TimelineItemProps extends MUITimelineItemProps {
  showConnector: boolean,
  headerTitle: React.ReactNode,
  url?: string,
  ips?: string,
  shouldReceiveIps?: boolean,
  imgSrc: string,
  icon: React.ReactNode,
  videoLength: number,
  text: React.ReactNode,
  dotContent: React.ReactNode,
  onThumbnailClick: (e: React.MouseEvent) => void,
}

const testId = 'TimelineItem'
export const TimelineItem = (props: TimelineItemProps) => {
  const {
    sx,
    showConnector,
    headerTitle,
    url,
    imgSrc,
    ips,
    shouldReceiveIps,
    icon,
    videoLength,
    text,
    dotContent,
    onThumbnailClick,
  } = props

  const theme = useTheme()

  return (
    <MUITimelineItem
      data-testid={testId}
      sx={{
        ':before': {
          display: 'none',
        },
        [theme.breakpoints.down('md')]: {
          marginLeft: '-15px',
        },
        ...sx
      }}
    >
      <TimelineSeparator>
        <TimelineDot>
          <Typography
            sx={{
              fontSize: 20,
              color: theme.palette.primary.main,
            }}
            component={'span'}
          >
            {dotContent}
          </Typography>
        </TimelineDot>
        {
          showConnector ?
            null
            :
            <TimelineConnector
              sx={{
                width: 3,
                marginTop: '-9px',
                marginBottom: '-9px',
                backgroundColor: theme.palette.primary.main,
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              }}
            />
        }
      </TimelineSeparator>
      <TimelineContent
        sx={{
          paddingBottom: '50px',
          paddingLeft: '30px',
          [theme.breakpoints.down('md')]: {
            marginTop: '20px',
            paddingLeft: '10px',
            paddingRight: 0,
          },
        }}
      >
        <Typography
          component={'h3'}
          sx={{
            fontSize: '1.25rem',
            lineHeight: '1.2',
            fontWeight: 500,
            marginBottom: '0.5em',
            [theme.breakpoints.down('md')]: {
              fontSize: '1rem',
              marginBottom: '15px'
            },
            '& strong': {
              color: theme.palette.coinrule.brand.primary,
            }
          }}
        >
          {headerTitle}{' '}
          {
            url ?
              <ExternalLink
                url={url}
                label={'Open'}
              />
              :
              null
          }
        </Typography>
        <Box
          component={'div'}
          sx={{
            display: 'flex',
            [theme.breakpoints.down('md')]: {
              marginLeft: '-60px',
            },
          }}
        >
          <Box
            sx={{
              margin: 0,
              paddingRight: '5px',
              paddingTop: '5px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <ImageWithCenteredIconOnTheTop
              src={imgSrc}
              alt={'image'}
              onClick={onThumbnailClick}
              sx={{
                marginRight: '15px',
                cursor: 'pointer',
              }}
              icon={icon}
            />
            <Typography
              component={'span'}
              alignItems={'center'}
              sx={{
                fontSize: '13px',
                color: theme.palette.coinrule.basic.grey,
              }}
            >
              {videoLength} sec
            </Typography>
          </Box>
          <Typography
            component={'div'}
            sx={{
              fontSize: '15.3px',
              color: theme.palette.coinrule.basic.black,
              marginRight: '-25px',
              [theme.breakpoints.down('md')]: {
                fontSize: '14px',
              },
            }}
          >
            {text}
            {
              shouldReceiveIps && ips ?
                <CopyLinkArea
                  icon={<IconCopy />}
                  variant={"small"}
                  colorVariant={"grey"}
                  sx={{
                    marginTop: '16px',
                    maxWidth: '200px',
                    paddingLeft: '11px',
                    paddingRight: '11px',
                    height: '27px',
                    backgroundColor: theme.palette.coinrule.basic.greyBackgroundColor,
                    fontSize: '14.5px',
                    fontWeight: 900,
                    [theme.breakpoints.down('md')]: {
                      maxWidth: '150px',
                    },
                  }}
                >
                  {ips.replaceAll(' ', ', ')}
                </CopyLinkArea>
                :
                null
            }
          </Typography>

        </Box>
      </TimelineContent>
    </MUITimelineItem>
  )
}

export default TimelineItem
