import React, { Component } from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';

import stores from '../../stores';
import Loading from '../Loading';
import { ErrorBoundary } from '../ErrorBoundary';

class PublicRoute extends Component {
  checkAuthorization(Component, props) {
    if (stores.user.isCheckingAuthorization) {
      return <Loading />;
    } else {
      if (!stores.user.isAuthorized || stores.user.isInputVerification) {
        return <ErrorBoundary name={"boundary_PublicRoute"} isFullScreen><Component {...props} /></ErrorBoundary>;
      } else {
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        );
      }
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;

    return <Route {...rest} render={(props) => this.checkAuthorization(Component, props)} />;
  }
}

export default withRouter(PublicRoute);
