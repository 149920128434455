import React from 'react'

import type { PaperProps as MUIPaperProps } from '@mui/material'

import { useTheme, Paper as MUIPaper } from '@mui/material'

export interface PaperProps extends MUIPaperProps {}

const testId = 'Paper'
export const Paper = (props: PaperProps) => {
  const {
    sx,
    ...otherProps
  } = props

  const theme = useTheme()

  return (
    <MUIPaper
      data-testid={testId}
      className={testId}
      elevation={0}
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        borderRadius: '8px',
        backgroundColor: theme.palette.coinrule.basic.white,
        boxShadow: '0 4px 4px rgb(0 0 0 / 4%)',
        boxSizing: 'border-box',
        ...sx
      }}
      {...otherProps}
    />
  )
}

export default Paper
