import React from 'react'

import { Collapse, Box, Typography, TableRow, TableCell, useTheme } from '@mui/material'
import type { CollapseProps } from '@mui/material';

import type { IRuleActivityLog, RuleActivityLogDataProps } from 'design-system';
import { RuleActivityLogData, DateFormatted } from 'design-system';

const DEFAULT_FONT_SIZE = '0.875rem';
const DEFAULT_PADDING_X = '14px';
const DEFAULT_MARGIN_LEFT = '30px';

export interface CollapseableRuleActivityLogProps extends CollapseProps {
  data: IRuleActivityLog,
  tradeDataExists: boolean,
  formatCoinValue: RuleActivityLogDataProps['formatCoinValue']
}

const testId = 'CollapseableRuleActivityLog'
export const CollapseableRuleActivityLog = (props: CollapseableRuleActivityLogProps) => {
  const {
    sx,
    data,
    tradeDataExists,
    formatCoinValue,
    in: isOpen,
    ...otherProps
  } = props

  const {date, description} = data;

  const theme = useTheme();
  const { greyLightColor, greyBorderLight } = theme.palette.coinrule.basic;
  
  const sharedStyles = {marginLeft: DEFAULT_MARGIN_LEFT, marginBottom: '20px'};

  const DESCRIPTION_MAP: Record<string, string> = {
    Buy: "Bought",
    Sell: "Sold",
  }

  return (
    <TableRow>
      <TableCell
        sx={{ padding: 0, border: 'none', ...(isOpen && { borderBottom: `1px solid ${greyBorderLight}` }) }}
        colSpan={6}
      >
        <Collapse
          data-testid={testId}
          className={testId}
          unmountOnExit
          in={isOpen}
          sx={{
            ...sx,
            fontWeight: 400,
            color: greyLightColor,
          }}
          {...otherProps}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              fontSize: DEFAULT_FONT_SIZE, 
              padding: DEFAULT_PADDING_X,
              [theme.breakpoints.up('md')]: {
                paddingY: DEFAULT_MARGIN_LEFT
              }
            }}
          >

            {
              tradeDataExists && (
                <>
                  <Box
                    sx={{
                      ...sharedStyles, 
                      [theme.breakpoints.up('sm')]: {
                        display: 'none'
                      }}}
                  >
                    <TableRowCollapseableTitle>Description</TableRowCollapseableTitle>
                    <Typography fontSize={DEFAULT_FONT_SIZE}>{DESCRIPTION_MAP[description as string]}</Typography>
                  </Box>

                  <Box sx={{...sharedStyles}}>
                    <TableRowCollapseableTitle>Additional info</TableRowCollapseableTitle>
                    <RuleActivityLogData
                      formatCoinValue={formatCoinValue}
                      {...data}
                    />
                  </Box>
                </>

              )
            }

            <Box
              sx={{
                ...sharedStyles, 
                [theme.breakpoints.up('sm')]: {
                  display: 'none'
                }}}
            >
              <TableRowCollapseableTitle>Date & Time</TableRowCollapseableTitle>
              <DateFormatted
                format={"dd/MM/yy h:mm"}
                date={date}
                sx={{
                  fontSize: DEFAULT_FONT_SIZE,
                }}
              />          
            </Box>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

const TableRowCollapseableTitle = ({children}: {children: React.ReactNode}) => (
  <Typography sx={{fontWeight: 600, fontSize: DEFAULT_FONT_SIZE}}>
    {children}
  </Typography>
)

export default CollapseableRuleActivityLog
