import React, {useEffect} from 'react';
import { Row, Col } from 'antd';
import stores from '../../../stores';
import { observer } from 'mobx-react';
import TechnicalWidget from '../TechnicalWidget/index';
import ExchangePairDropdown from './ExchangePairDropdown';
import "./style.scss";
import ExchangeChart from './ExchangeChart';

const SymbolWidget = observer((props) => {
  const {
    from,
    to,
    exchangeName,
    updatePairNames,
    exchanges
  } = props;

  useEffect(() => {
    // @FIXME this is probably unnecessary call as we already should have lists loaded
    stores.info.getLists().then((authorized) => stores.app.unauthorized(authorized));
    stores.userInfo.getExchanges().then((authorized) => stores.app.unauthorized(authorized));
  }, [])

  return (
    <Row className='SymbolWidget'>
      <Col className='SymbolWidget__ExchangePair-container' xs={{ span: 24 }} lg={{ span: 17 }}>
        <ExchangePairDropdown
          updatePairNames={updatePairNames} 
          exchanges={exchanges} 
          to={to}
          from={from}
          exchangeName={exchangeName}
        />

        <div className='top-margin'>
          <ExchangeChart 
            exchangeName={exchangeName} 
            from={from} 
            to={to} 
          />
        </div>
      </Col>

      <Col xs={{ span: 24 }} lg={{ span: 6 }} className='SymbolWidget__TechnicalWidget-container'>
        <TechnicalWidget from={from} to={to} exchangeName={exchangeName} />
      </Col>
    </Row>
  )
})

export default SymbolWidget;
