import cookie from 'react-cookies';

export const INITIAL_REFERRER_COOKIE_NAME = 'initialReferrer'

export type CookieSerializeOptions = Parameters<typeof cookie.save>['2']

export const loadInitialRefererCookie = (): string | undefined => {
  return cookie.load(INITIAL_REFERRER_COOKIE_NAME)
}

export const removeInitialRefererCookie = () => {
  cookie.remove(INITIAL_REFERRER_COOKIE_NAME)
}

export const saveInitialRefererCookie = ({ initialReferrer, options }: { initialReferrer: string, options?: CookieSerializeOptions }) => {
  const initialRefererCookie: string | undefined = loadInitialRefererCookie()

  // it there is already a referrer set do not overwrite
  if(initialRefererCookie) return

  // set default expiry day to 1 day in future
  const expires = new Date()   
  expires.setDate(expires.getDate() + 1)

  cookie.save(
    INITIAL_REFERRER_COOKIE_NAME,
    initialReferrer,
    { 
      expires,
      domain: '.coinrule.com',
      sameSite: false,
      secure: true,
      path: '/',
      ...options
    }
  )
}
