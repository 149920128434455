import React from 'react'

import type { TooltipProps as MUITooltipProps } from '@mui/material'

import { useTheme, Tooltip as MUITooltip, tooltipClasses } from '@mui/material'

export interface TooltipProps extends MUITooltipProps { }

const testId = 'Tooltip'
export const Tooltip = (props: TooltipProps) => {
  const {
    sx,
    PopperProps,
    ...otherProps
  } = props

  const theme = useTheme()

  console.log(`&.${tooltipClasses.popper} .${tooltipClasses.arrow}`)

  return (
    <MUITooltip
      data-testid={testId}
      className={testId}
      PopperProps={{
        // @ts-ignore according to docs and runtime sx is a valid prop on Popper component https://mui.com/api/popper/
        sx: {
          [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.coinrule.basic.black,
          },
          [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.coinrule.basic.black,
          },
          // @ts-ignore according to docs and runtime sx is a valid prop on Popper component https://mui.com/api/popper/
          ...(PopperProps ? PopperProps.sx : {}),
        },
        ...PopperProps
      }}
      arrow={true}
      sx={{ ...sx }}
      {...otherProps}
    />
  )
}

export default Tooltip
