import type { DTOShareRuleSlug } from "v2/domains/shareRule/DTOs/DTOShareRuleSlug"
import type { Api, ShareRuleSuccess } from '__generated__/backend.api.client/Api'

import { converterShareRule } from "./converterShareRule"

export type TDataProviderShareRule = ReturnType<typeof createDataProviderShareRule>

export const createDataProviderShareRule = <SecurityDataType extends unknown = unknown>({ httpClient }: { httpClient: Api<SecurityDataType> }) => {
  return {
    createSlug: async ({ ruleId }: { ruleId: string }): Promise<DTOShareRuleSlug> => {
      const response = await httpClient.rule.shareRule({ rule_id: ruleId })
      // we can enforce the type as the non error response should be a success response
      const shareRuleSlug = response.data as ShareRuleSuccess
      const dtoShareRuleSlug = converterShareRule.toDTO({ shareRuleSlug, ruleId })

      return dtoShareRuleSlug
    }
  }
}