import React from 'react'

export interface IconGlobeProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconGlobe'
export const IconGlobe = (props: IconGlobeProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      width={"1em"}
      height={"1em"}
      viewBox={"0 0 25 24"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...otherProps}
    >
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={"M12.4141 3C7.4435 3 3.41406 7.02944 3.41406 12C3.41406 16.9706 7.4435 21 12.4141 21C17.3846 21 21.4141 16.9706 21.4141 12C21.4141 7.02944 17.3846 3 12.4141 3ZM1.41406 12C1.41406 5.92487 6.33893 1 12.4141 1C18.4892 1 23.4141 5.92487 23.4141 12C23.4141 18.0751 18.4892 23 12.4141 23C6.33893 23 1.41406 18.0751 1.41406 12Z"}
        fill={"#9B9B9B"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={"M1.41406 12C1.41406 11.4477 1.86178 11 2.41406 11H22.4141C22.9663 11 23.4141 11.4477 23.4141 12C23.4141 12.5523 22.9663 13 22.4141 13H2.41406C1.86178 13 1.41406 12.5523 1.41406 12Z"}
        fill={"#9B9B9B"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={"M9.4143 12C9.48283 15.0748 10.5403 18.0352 12.4141 20.4492C14.2878 18.0352 15.3453 15.0748 15.4138 12C15.3453 8.92516 14.2878 5.96485 12.4141 3.5508C10.5403 5.96485 9.48283 8.92516 9.4143 12ZM12.4141 2L11.6757 1.32558C9.01095 4.24291 7.49658 8.02885 7.41428 11.9792C7.41399 11.9931 7.41399 12.0069 7.41428 12.0208C7.49658 15.9711 9.01095 19.7571 11.6757 22.6744C11.8652 22.8818 12.1331 23 12.4141 23C12.695 23 12.9629 22.8818 13.1524 22.6744C15.8172 19.7571 17.3315 15.9711 17.4138 12.0208C17.4141 12.0069 17.4141 11.9931 17.4138 11.9792C17.3315 8.02885 15.8172 4.24291 13.1524 1.32558L12.4141 2Z"}
        fill={"#9B9B9B"}
      />
    </svg>
  )
}

export default IconGlobe
