import React from 'react';
import { PLAN_PERIODS } from "helpers/plan"
import { useState } from "react"
import { Select, Switch } from 'antd';
import { capitalize } from 'helpers/utils';

const { Option } = Select;

export interface IUsePlanPeriodOptions {
  isOnQuarterlyPriceGroup: boolean
  isOnScrapHobbyistGroup: boolean
}
const usePlanPeriod = ({isOnQuarterlyPriceGroup, isOnScrapHobbyistGroup}: IUsePlanPeriodOptions) => {
  const [period, setPeriod] = useState(PLAN_PERIODS.YEARLY);

  const unselectedOption = isOnQuarterlyPriceGroup ? PLAN_PERIODS.QUARTERLY : PLAN_PERIODS.MONTHLY

  const togglePeriod = () => {
    if(period === 'yearly') 
      return setPeriod(unselectedOption)

    setPeriod('yearly')
  };

  const handleChange = (value: string) => setPeriod(value)

  const renderSwitchOrDropdown = () => {
    if(isOnScrapHobbyistGroup) {
      const options = [PLAN_PERIODS.YEARLY, PLAN_PERIODS.QUARTERLY, PLAN_PERIODS.MONTHLY];
      return (
        <Select value={period} style={{ width: 110 }} onChange={handleChange}>
          {options.map((period) => (
          <Option key={period} value={period}>{capitalize(period)}</Option>))}
        </Select>
      )
    }
        
    return (
      <Switch
        checkedChildren='Annual'
        unCheckedChildren={capitalize(unselectedOption)}
        checked={period === PLAN_PERIODS.YEARLY}
        onChange={togglePeriod}
      />
    )
  }

  return {
    period,
    renderSwitchOrDropdown,
    togglePeriod
  }
}

export default usePlanPeriod;