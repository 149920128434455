import React from 'react'

import type { BoxProps } from '@mui/material'

import { Box } from '@mui/material'

export interface OverlayParentProps extends BoxProps {}

const testId = 'OverlayParent'
export const OverlayParent = (props: OverlayParentProps) => {
  const {
    sx,
    ...otherProps
  } = props

  return (
    <Box
      data-testid={testId}
      className={testId}
      sx={{
        position: 'relative',
        width: '100%',
        ...sx
      }}
      {...otherProps}
    />
  )
}

export default OverlayParent
