import dbg from '../helpers/dbg';
import UserStore from './user';
import UserSurveyStore from './userSurvey';
import UserInfoStore from './userInfo';
import UserRulesStore from './userRules';
import InfoStore from './info';
import ExchangeInfoStore from './exchangeInfo';
import AppStore from './app';
import TmpStore from './tmp';
import AdminStore from './admin';
import TemplatesStore from './templates';
import Wallets from './wallets';
import RuleDetailsStore from './ruleDetails';
import Notifications from './notifications';
import TopPanel from './topPanel';
import PlanStore from './plan';
import ExchangeProfileStore from './exchangeProfile';
import TourGuide from './tourGuide';
import AddRuleStore from './addRule';
import AuthStore from './auth';
import RuleSequences from './ruleSequences';
import PaymentStore from './payment';
import TradingStatStore from './tradingStat';

let initialized = false;
let userStore;
let userSurveyStore;
let userInfoStore;
let userRulesStore;
let infoStore;
let exchangeInfoStore;
let appStore;
let tmpStore;
let adminStore;
let templatesStore;
let walletsStores;
let ruleDetailsStore;
let notificationsStore;
let topPanelStore;
let planStore;
let exchangeProfile;
let tourGuide;
let addRuleStore;
let authStore;
let ruleSequences;
let paymentStore;
let tradingStatStore;

const initializeInstances = (thisObj) => {
  if (!initialized) {
    dbg('Initializing store instances');

    initialized = true;
    userStore = new UserStore(thisObj);
    userSurveyStore = new UserSurveyStore(thisObj);
    userInfoStore = new UserInfoStore(thisObj);
    userRulesStore = new UserRulesStore(thisObj);
    infoStore = new InfoStore(thisObj);
    exchangeInfoStore = new ExchangeInfoStore(thisObj);
    appStore = new AppStore(thisObj);
    tmpStore = new TmpStore(thisObj);
    adminStore = new AdminStore(thisObj);
    templatesStore = new TemplatesStore(thisObj);
    ruleDetailsStore = new RuleDetailsStore(thisObj);
    walletsStores = new Wallets(thisObj);
    notificationsStore = new Notifications(thisObj);
    topPanelStore = new TopPanel(thisObj);
    planStore = new PlanStore(thisObj);
    exchangeProfile = new ExchangeProfileStore(thisObj);
    tourGuide = new TourGuide(thisObj);
    addRuleStore = new AddRuleStore(thisObj);
    ruleSequences = new RuleSequences(thisObj);
    authStore = new AuthStore(thisObj);
    paymentStore = new PaymentStore(thisObj);
    tradingStatStore = new TradingStatStore(thisObj)
  } else {
    dbg('Using already initialized store instances');
  }
};

export class Stores {
  constructor() {
    initializeInstances(this);

    this.user = userStore;
    this.userInfo = userInfoStore;
    this.userRules = userRulesStore;
    this.userSurvey = userSurveyStore;
    this.info = infoStore;
    this.exchangeInfo = exchangeInfoStore;
    this.app = appStore;
    this.tmp = tmpStore;
    this.admin = adminStore;
    this.templatesStore = templatesStore;
    this.ruleDetailsStore = ruleDetailsStore;
    this.planStore = planStore;
    this.walletsStores = walletsStores;
    this.notificationStore = notificationsStore;
    this.topPanelStore = topPanelStore;
    this.exchangeProfile = exchangeProfile;
    this.tourGuide = tourGuide;
    this.addRule = addRuleStore;
    this.auth = authStore;
    this.ruleSequences = ruleSequences;
    this.payment = paymentStore;
    this.tradingStatStore = tradingStatStore;
  }

  reset() {
    this.addRule.resetStore();
    this.admin.resetStore();
    this.auth.resetStore();
    this.exchangeInfo.resetStore();
    this.exchangeProfile.resetStore();
    this.info.resetStore();
    this.notificationStore.resetStore();
    this.payment.resetStore();
    this.planStore.resetStore();
    this.ruleDetailsStore.resetStore();
    this.ruleSequences.resetStore();
    this.templatesStore.resetStore();
    this.topPanelStore.resetStore();
    this.tourGuide.resetStore();
    this.tradingStatStore.resetStore();
    this.userInfo.resetStore();
    this.userRules.resetStore();
    this.userSurvey.resetStore();
    this.walletsStores.resetStore();
  }
}

const stores = new Stores();

export default stores;
