import React from 'react'

import type { StackProps, TypographyProps} from '@mui/material';
import { Typography, useTheme, Stack } from '@mui/material'

export interface IconWithLabelProps extends StackProps {
  label?: React.ReactNode,
  labelProps?: TypographyProps
}

const testId = 'IconWithLabel'
export const IconWithLabel = (props: IconWithLabelProps) => {
  const {
    sx,
    children,
    label,
    labelProps,
    ...otherProps
  } = props

  const theme = useTheme()

  return (
    <Stack
      data-testid={testId} 
      className={testId}
      direction={'row'}
      gap={'13px'}
      sx={{
        ...sx
      }}
      {...otherProps}
    >

      {children}

      {
        label && (
          <Typography
            color={theme.palette.coinrule.basic.greyDark}
            fontWeight={600}
            {...labelProps}
          >
            {label}
          </Typography>
        )
      }

    </Stack>
  )
}

export default IconWithLabel
