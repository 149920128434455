import type { THTTPClientError } from 'v2/types'
import type { TGetState } from 'v2/types/TGetState'
import type { TDispatchAction } from 'v2/types/TDispatchAction'

import type { TDataProviderConnectExchangeBinanceOAuthGetAccessToken } from 'v2/dataProviders/connectExchangeBinanceOAuth/createDataProviderConnectExchangeBinanceOAuthGetAccessToken'
import type { DTOErrorFetchConnectExchangeBinanceOAuthGetAccessToken } from './DTOs/DTOErrorFetchConnectExchangeBinanceOAuthGetAccessToken'
import type { TDomainStateConnectExchangeBinanceOAuthGetAccessToken } from './stateFetchGetAccessToken'

import { EDomainActionsConnectExchangeBinanceOAuthGetAccessToken, actionCreatorsConnectExchangeBinanceOAuthGetAccessToken } from './actionsFetchGetAccessToken'

export type TDomainUseCasesConnectExchangeBinanceOAuthGetAccessToken = ReturnType<typeof createUseCasesConnectExchangeBinanceOAuthGetAccessToken>

export const createUseCasesConnectExchangeBinanceOAuthGetAccessToken = ({ dataProviderConnectExchangeBinanceOAuthGetAccessToken, dispatch, getState }: { dataProviderConnectExchangeBinanceOAuthGetAccessToken: TDataProviderConnectExchangeBinanceOAuthGetAccessToken, dispatch: TDispatchAction, getState: TGetState<TDomainStateConnectExchangeBinanceOAuthGetAccessToken> }) => {
  return {
    getAccessToken: async ({ code }: { code: string }) => {
      dispatch(actionCreatorsConnectExchangeBinanceOAuthGetAccessToken[EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_STARTED]())
      try {
        const dtoConnectExchangeBinanceOAuthGetAccessToken = await dataProviderConnectExchangeBinanceOAuthGetAccessToken.getAccessToken({ code })
        dispatch(actionCreatorsConnectExchangeBinanceOAuthGetAccessToken[EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_SUCCEEDED]({ dto: dtoConnectExchangeBinanceOAuthGetAccessToken }))
      } catch (error) {
        const httpClientError = error as THTTPClientError 
        let domainError: DTOErrorFetchConnectExchangeBinanceOAuthGetAccessToken
        if(httpClientError.isAxiosError && httpClientError.response?.status === 404 ) {
          domainError = { 
            code: 404,
            message: 'Not found',
            origin: 'domains/connectExchangeBinanceOAuth/getAccessToken' as const,
          }
        } else {
          domainError = { 
            code: 500,
            message: 'Something went wrong while performing getAccessToken requestt',
            origin: 'domains/connectExchangeBinanceOAuth/getAccessToken' as const,
          }
        }
        dispatch(actionCreatorsConnectExchangeBinanceOAuthGetAccessToken[EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_FAILED]({ error: domainError }))
        throw domainError
      }
    }
  }
}