import React from 'react'

export interface IconShareProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconShare'
export const IconShare = (props: IconShareProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      width={'1em'}
      height={'1em'}
      viewBox={"0 0 30 30"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...otherProps}
    >
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={"M16.033 2.668A8 8 0 0 1 27.345 13.98l-.016.016-4 4a8.002 8.002 0 0 1-12.064-.864 1.333 1.333 0 1 1 2.136-1.597 5.332 5.332 0 0 0 8.042.576l3.992-3.992a5.333 5.333 0 0 0-7.543-7.54l-2.286 2.273a1.333 1.333 0 1 1-1.88-1.89l2.294-2.28.013-.014Z"}
        fill={"#4D5AFF"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={"M9.532 10.164a8 8 0 0 1 9.202 2.704 1.333 1.333 0 0 1-2.135 1.597 5.332 5.332 0 0 0-8.043-.576l-3.992 3.992a5.333 5.333 0 0 0 7.541 7.54l2.272-2.27a1.333 1.333 0 1 1 1.885 1.885l-2.28 2.28-.016.016A8 8 0 0 1 2.654 16.02l.016-.016 4-4a8.001 8.001 0 0 1 2.862-1.84Z"}
        fill={"#4D5AFF"}
      />
    </svg>
  )
}

export default IconShare
