import React from 'react'

import type { ChartRuleStatsLegendProps } from 'design-system';

import { useTheme } from '@mui/material'

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import { ChartRuleStatsLegend } from 'design-system'

type ResponsiveContainerProps = React.ComponentProps<typeof ResponsiveContainer>

export type ChartRuleStatsDataPoint = {
  date: string
  percentageDifference: number
  volumeDifference?: number
} | {
  date: string
  percentageDifference?: number
  volumeDifference: number
}

export interface ChartRuleStatsProps extends Omit<ResponsiveContainerProps, 'children'> {
  data: ChartRuleStatsDataPoint[]
  baseCurrency: string
  formatNumber: ChartRuleStatsLegendProps['formatNumber']
}

const testId = 'ChartRuleStats'
export const ChartRuleStats = (props: ChartRuleStatsProps) => {
  const {
    data,
    baseCurrency,
    formatNumber,
    ...otherProps
  } = props

  const theme = useTheme()
  const isVolumeAvailable = data[0]?.volumeDifference === undefined ? false : true

  return (
    <ResponsiveContainer
      data-testid={testId}
      {...otherProps}
    >
      <AreaChart
        data={data}
        margin={{ top: 40 }}
        key={data ? data.length : 0}
      >
        <CartesianGrid
          strokeDasharray={'3 3'} 
          stroke={theme.palette.coinrule.basic.greyBorder}
        />

        <XAxis
          dataKey={'date'}
          stroke={theme.palette.coinrule.basic.greyBorder}
          hide={true}
        />

        <YAxis
          stroke={theme.palette.coinrule.basic.white}
          hide={true}
        />

        <Tooltip
          content={(external) => {
            const data = external.payload?.map(({ payload }) => payload) as ChartRuleStatsDataPoint[]
            return (
              <ChartRuleStatsLegend
                data={data}
                baseCurrency={baseCurrency}
                formatNumber={formatNumber}
              />
            )
          }}
        />

        <Area
          isAnimationActive={false}
          type={'monotone'}
          strokeWidth={2}
          dataKey={isVolumeAvailable ? 'volumeDifference' : 'percentageDifference'}
          stroke={theme.palette.coinrule.brand.primary}
          fill={theme.palette.coinrule.basic.greyAreaChartFill}
          activeDot={{ 
            stroke: theme.palette.coinrule.basic.white, 
            fill: theme.palette.coinrule.rule.execution,
            strokeWidth: 2
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default ChartRuleStats
