import React, { Component } from 'react';

import AuthFooter from './Footer';
import AuthHeader from './Header';
import { observer } from 'mobx-react';
import stores from '../../stores';

import './style.scss';

@observer
class AuthWindow extends Component {
  render() {
    return (
      <div
        className={`loginJourney
                        ${stores.auth.isSideMenuOpened && 'loginJourney_shifted'}`}
      >
        <AuthHeader />

        <main
          className={`authWindow
                          loginJourney__content
                          ${stores.auth.isSideMenuOpened && 'authWindow_shifted'}`}
        >
          <h1>{this.props.title}</h1>

          <form className='authWindow__formBlock'>{this.props.children}</form>
        </main>

        <AuthFooter />
      </div>
    );
  }
}

export default AuthWindow;
