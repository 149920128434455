import React from 'react';

import '../style.scss';
import logo from '../../../assets/images/logo-robot-gray.svg';
import GlobalSidebar from '../../GlobalSiderbarWithoutRouter';
import { GlobalWrapper } from '../../GlobalWrapper';
import { GlobalContent } from '../../GlobalContent';

import './style.scss';

export const LazyLoading = (props) => {
  return (
    <GlobalWrapper>
      <GlobalSidebar />

      <GlobalContent extraClass='lazyLoadContent'>
        <div className='loading-page icon-loader lazyLoadInner'>
          <div className='cover'>
            <img src={logo} alt='' className='icon-logo' />
            <div className='loader' />
          </div>
        </div>
      </GlobalContent>
    </GlobalWrapper>
  );
};

export default LazyLoading;
