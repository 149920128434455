import React from 'react'

export interface IconShareViaTwitterProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconShareViaTwitter'
export const IconShareViaTwitter = (props: IconShareViaTwitterProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-id={testId}
      width={"1em"}
      height={"1em"}
      viewBox={"0 0 59 59"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...otherProps}
    >
      <path
        d={"M24.4368 40.8333C26.3467 40.8972 28.2501 40.5776 30.0345 39.8933C31.8189 39.2091 33.4479 38.1741 34.8255 36.8495C36.203 35.525 37.3011 33.9377 38.0548 32.1815C38.8084 30.4254 39.2024 28.536 39.2134 26.6249C40.0887 25.5418 40.7387 24.2946 41.1251 22.9566C41.1539 22.8509 41.1516 22.7391 41.1184 22.6347C41.0852 22.5303 41.0225 22.4377 40.9378 22.3681C40.8532 22.2985 40.7503 22.2548 40.6414 22.2424C40.5326 22.2299 40.4224 22.2492 40.3242 22.2979C39.8663 22.5183 39.3508 22.5895 38.8503 22.5014C38.3497 22.4134 37.8894 22.1705 37.5342 21.807C37.0808 21.3105 36.5323 20.91 35.9213 20.6293C35.3102 20.3486 34.6491 20.1935 33.9769 20.173C33.3048 20.1525 32.6354 20.2671 32.0084 20.5101C31.3814 20.7531 30.8096 21.1194 30.3267 21.5874C29.6657 22.2277 29.1815 23.028 28.9212 23.9107C28.6609 24.7934 28.6334 25.7285 28.8413 26.6249C24.5142 26.8833 21.5434 24.8295 19.1668 22.0137C19.0954 21.9328 19.002 21.8745 18.898 21.8459C18.794 21.8172 18.6839 21.8195 18.5811 21.8523C18.4784 21.8852 18.3875 21.9473 18.3194 22.031C18.2514 22.1147 18.2092 22.2164 18.198 22.3237C17.7452 24.8352 18.0718 27.4252 19.134 29.7457C20.1961 32.0662 21.9429 34.0061 24.1397 35.3049C22.6667 36.9942 20.5982 38.0493 18.3659 38.2499C18.2463 38.2698 18.1359 38.3265 18.0502 38.4122C17.9644 38.4979 17.9076 38.6082 17.8877 38.7278C17.8677 38.8474 17.8857 38.9702 17.939 39.0791C17.9924 39.1879 18.0784 39.2774 18.1851 39.3349C20.1271 40.3055 22.2658 40.818 24.4368 40.8333Z"}
        fill={"#CCCCCC"}
      />
      <circle
        cx={29.5}
        cy={29.5}
        r={28}
        stroke={"#CCCCCC"}
        strokeWidth={3}
      />
    </svg>
  )
}

export default IconShareViaTwitter
