import axios from 'axios';
import constants from '../constants';
import { ERROR_MESSAGES } from './errors';
import { errorNotification } from './notification';

export const baseURL = constants.URL;

export const getHttp = (options) =>
  axios.create({
    baseURL: baseURL,
    withCredentials: true,
    xsrfCookieName: 'rds_react_xsrf_token',
    xsrfHeaderName: 'X-XSRF-Token',
    ...options,
  });

export default axios.create({
  baseURL: baseURL,
  withCredentials: true,
  xsrfCookieName: 'rds_react_xsrf_token',
  xsrfHeaderName: 'X-XSRF-Token',
});

export function checkResponse(res) {
  const result = {
    ok: false,
    data: null,
  };

  try {
    if (res.data) {
      if (res.data.status === 'OK') {
        result.ok = true;
      } else {
        if (res.data.message) {
          errorNotification(res.data.message);
        } else {
          throw new Error('Error with unknown message');
        }
      }

      result.data = res.data;
    } else {
      throw new Error('Invalid response');
    }
  } catch (err) {
    console.error(err);
    errorNotification(ERROR_MESSAGES.GENERIC);
  }

  return result;
}
