import { getCoinValue } from "pages/AddRulePage/ShowCoinsValue";

import { 
  EExchangeType, 
  SortAndCalculatePriceWalletsParams, 
  TCalculateTotalAmountByExchangeParams, 
  TCalculateWalletBalanceExchangeForSpotOrLeverageParams, 
  TCalculateWalletBalanceExchangeForSpotOrLeverageReturn, 
  TCalculateWalletBalanceExchangeParams, 
  TCurrencies, 
  TCurrencySymbol 
} from "./";

export const getExchangeRate = ({
  inputCurrencySymbol,
  outputCurrencySymbol,
  currencies,
  roundingValue = 10000,
}: {
  inputCurrencySymbol: TCurrencySymbol;
  outputCurrencySymbol: TCurrencySymbol;
  currencies?: TCurrencies;
  roundingValue?: number;
}): number | false => {
  if (inputCurrencySymbol === outputCurrencySymbol)
  // there is no conversion if the inputCurrencySymbol is the same as target
  return 1;
  
  // FIXME: this is left so for consistency. Basically this should thrown
  // an error and this function should always return a number.
  if (!currencies || !currencies[inputCurrencySymbol]) return false;
  
  if (currencies[outputCurrencySymbol])
    // since there is no direct conversion for inputCurrencySymbol to baseCurrency/outputCurrencySymbol we use the btcPrice value for conversion
    return (
      Math.floor(
        currencies[inputCurrencySymbol].btcPrice * Number(currencies[outputCurrencySymbol].orgPrice) * roundingValue
      ) / roundingValue
    );

  // This only works if base currency is BTC
  return currencies[inputCurrencySymbol].btcPrice;
};

const balanceCoinsSort = (a: { fiatValue: number | false }, b: { fiatValue: number | false }) => {
  if (a.fiatValue < b.fiatValue) return 1;

  if (a.fiatValue > b.fiatValue) return -1;

  return 0;
};

export function sortAndCalculatePriceWallets(params: SortAndCalculatePriceWalletsParams) {
  const {
    balances, 
    assets, 
    exchangeId,
    exchangeType,
    currencies,
    baseCurrency,
    tempCurrency,
  } = params;

  const balancesLength = balances ? balances.length : 0;
  const wallets = [];
  let totalFiatValue = 0;

  for (let i = 0; i < balancesLength; i++) {
    const {fiatValue, ...rest} = calculateWalletBalanceExchange({
      walletBalance: balances[i], 
      exchangeType, 
      assets, 
      exchangeId, 
      currencies, 
      baseCurrency, 
      tempCurrency, 
      assetRounding: 10000, 
      fiatRounding: 100,
    })

    wallets.push({...rest, fiatValue});
    totalFiatValue = fiatValue ? totalFiatValue + fiatValue : totalFiatValue;
  }

  return {
    totalBalance: totalFiatValue,
    balances: wallets.sort(balanceCoinsSort)
  }
}

export const calculateWalletBalanceExchange = ({
  walletBalance, 
  assetRounding,
  fiatRounding,
  exchangeType, 
  assets, 
  exchangeId, 
  baseCurrency, 
  tempCurrency,
  currencies,
}: TCalculateWalletBalanceExchangeParams) => {
  const isAssetBaseCurrency = baseCurrency === walletBalance.asset;
  const isBaseCurrencyBTC = baseCurrency === 'BTC';

  const getRoundedFiatValue = (fiatValue: number) => roundUpNumber({
    value: isAssetBaseCurrency ? walletBalance.balance : fiatValue, 
    roundingNumber: isBaseCurrencyBTC ? assetRounding : fiatRounding
  });

  const commonParams = {
    walletBalance,
    exchangeId,
    currencies,
    tempCurrency,
    assets
  }

  const commonResults = {
    ...walletBalance,
    roundedBalance: Math.floor(walletBalance.balance * assetRounding) / assetRounding,
  }

  if (exchangeType === EExchangeType.LEVERAGE) {
    const {fiatValue, ...rest} = calculateWalletBalanceExchangeForLeverage(commonParams);
    return {
      ...commonResults,
      ...rest,
      fiatValue: getRoundedFiatValue(fiatValue),
    }
  } 
    
  const {fiatValue, ...rest} = calculateWalletBalanceExchangeForSpot(commonParams);
  return {
    ...commonResults,
    ...rest,
    fiatValue: getRoundedFiatValue(fiatValue),
  }
}


export const roundUpNumber = ({
  value, 
  roundingNumber
}: {
  value: number, 
  roundingNumber: number
}) =>  Math.floor(value * roundingNumber) / roundingNumber;

export const calculateWalletBalanceExchangeForSpot = ({
  assets, 
  walletBalance, 
  exchangeId,
  currencies,
  tempCurrency,
}: TCalculateWalletBalanceExchangeForSpotOrLeverageParams): TCalculateWalletBalanceExchangeForSpotOrLeverageReturn  => {
  if(!assets)  return {
    fiatValue: 0,
    showFiatValue: false,
    baseSymbol: '',
  }

  // @ts-ignore webpack compiler is not able to infer the type properly
  if(!assets[walletBalance.asset]) {
    // calculate without asset
    return {
      baseSymbol: walletBalance.asset,
      ...calculateWalletBalanceExchangeWithFiatExchangeRate({walletBalance, currencies, tempCurrency})
    }
  }

  return {
    fiatValue: getCoinValue(walletBalance.asset, exchangeId).price * walletBalance.balance,
    showFiatValue: true,
    baseSymbol: walletBalance.asset
  };
}

export const calculateWalletBalanceExchangeForLeverage = ({
  assets, 
  walletBalance,
  exchangeId,
  currencies,
  tempCurrency,
}: TCalculateWalletBalanceExchangeForSpotOrLeverageParams): TCalculateWalletBalanceExchangeForSpotOrLeverageReturn => {

  if(!assets)  return {
    fiatValue: 0,
    showFiatValue: false,
    baseSymbol: '',
  }

  // @ts-ignore webpack compiler is not able to infer the type properly
  const existingWalletInAssets = assets.find((asset) => asset.symbol === walletBalance.asset);

  if(!existingWalletInAssets) {
    // assets here are leverage instruments so if it wallet doesn't exist in leverage instruments
    // then maybe it's a base coin?
    const foundPrice = getCoinValue(walletBalance.asset, exchangeId).price;
    const baseSymbol = walletBalance.asset;

    if (foundPrice > 0) 
      return {
        fiatValue: foundPrice * walletBalance.balance,
        showFiatValue: true,
        baseSymbol
      }
    
    return {
      baseSymbol,
      ...calculateWalletBalanceExchangeWithFiatExchangeRate({walletBalance, currencies, tempCurrency})
    }
  }

  return {
    fiatValue: (walletBalance.balance / Number(existingWalletInAssets.last)) * existingWalletInAssets.lastFiat,
    baseSymbol: existingWalletInAssets.quote,
    showFiatValue: true,
  }
}

export const calculateWalletBalanceExchangeWithFiatExchangeRate = ({
  walletBalance,
  tempCurrency,
  currencies
}: {
  tempCurrency: TCalculateWalletBalanceExchangeParams['tempCurrency'], 
  walletBalance: TCalculateWalletBalanceExchangeParams['walletBalance'], 
  currencies: TCalculateWalletBalanceExchangeParams['currencies']
}) => {
  const result = getExchangeRate({
    inputCurrencySymbol: walletBalance.asset, 
    outputCurrencySymbol: tempCurrency,
    currencies: currencies
  });

  return {
    fiatValue: result ? result * walletBalance.balance : 0,
    showFiatValue: result ? true : false,
  }
}

export function calculateTotalAmountByExchange({
  exchangeId,
  force = false,
  wallets,
  assets,
  instruments,
  exchangeType,
  totalBalance,
  currencies,
  baseCurrency,
  tempCurrency,
}: TCalculateTotalAmountByExchangeParams) {
  if (!exchangeId || wallets === undefined) return null;

  const noInstrumentsForLeverage = exchangeType === EExchangeType.LEVERAGE && instruments === undefined;
  const noAssetsForSpot = exchangeType === EExchangeType.SPOT && assets === undefined;

  if (noInstrumentsForLeverage || noAssetsForSpot)  return null;
  if(totalBalance && !force) return null;

  const commonParams = {
    exchangeId,
    balances: wallets,
    currencies,
    baseCurrency,
    tempCurrency,
  }
  
  return sortAndCalculatePriceWallets(
    exchangeType === EExchangeType.LEVERAGE ?
      {
        ...commonParams,
        exchangeType: EExchangeType.LEVERAGE,
        assets: instruments, 
      }
      : 
      {
        ...commonParams,
        exchangeType: EExchangeType.SPOT,
        assets: assets, 
      }
  );
}
