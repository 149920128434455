import React from 'react'

export interface IconRulePausedProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconRulePaused'
export const IconRulePaused = (props: IconRulePausedProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      id={"Layer_1"}
      xmlns={"http://www.w3.org/2000/svg"}
      x={0}
      y={0}
      width={"1rem"}
      height={"1rem"}
      viewBox={"0 0 23 23"}
      xmlSpace={"preserve"}
      {...otherProps}
    >
      <style>{".st0{fill:#05b4bc}"}</style>
      <path
        className={"st0"}
        d={"M11.5 2.2c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm0 16.3c-4.1 0-7.4-3.3-7.4-7.4 0-4.1 3.3-7.4 7.4-7.4 4.1 0 7.4 3.3 7.4 7.4 0 4.2-3.3 7.4-7.4 7.4z"}
      />
      <path
        className={"st0"}
        d={"M10.2 7.4H8.8c-.2 0-.4.2-.4.4v6.8c0 .2.2.4.4.4h1.5c.2 0 .4-.2.4-.4V7.9c-.1-.3-.2-.5-.5-.5zM14.1 7.4h-1.5c-.2 0-.4.2-.4.4v6.8c0 .2.2.4.4.4h1.5c.2 0 .4-.2.4-.4V7.9c0-.3-.1-.5-.4-.5z"}
      />
    </svg>
  )
}

export default IconRulePaused
