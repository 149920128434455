import React from 'react'

export interface ImageSingUpToViewRuleProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'ImageSingUpToViewRule'
export const ImageSingUpToViewRule = (props: ImageSingUpToViewRuleProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      width={260}
      height={168}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...otherProps}
    >
      <path
        opacity={0.31}
        d={"M54.985 17.866a5.796 5.796 0 0 1-2.65-2.002s-.218 2.927 2.642 6.191l.008-4.189Z"}
        fill={"#CE8172"}
      />
      <path
        d={"M59.788 10.422s-.437 4.878-1.808 7.923a2.448 2.448 0 0 1-3.264 1.22 5.417 5.417 0 0 1-3.465-4.76l-.698-4.467a4.392 4.392 0 0 1 2.733-4.282c3.062-1.472 6.914 1.397 6.502 4.366Z"}
        fill={"#F4A28C"}
      />
      <path
        d={"m59.359 13.483 1.01 2.095a.783.783 0 0 1-.699 1.119h-1.901l1.59-3.214Z"}
        fill={"#F4A28C"}
      />
      <path
        d={"m49.35 15.099 2.985-.723 1.817-.892s1.304-.084.841-3.255c0 0 6.41 2.212 7.25-1.59.926-4.147-3.86-5.989-7.25-5.635a5.442 5.442 0 0 0-4.441 3.482s-5.333-.85-1.203 8.613Z"}
        fill={"#24285B"}
      />
      <path
        d={"M53.934 13.636s.294-1.86-1.128-1.96c-1.421-.101-1.858 2.582 0 3.162l1.128-1.202Z"}
        fill={"#F4A28C"}
      />
      <path
        opacity={0.3}
        d={"M131.419 168c70.778 0 128.156-4.692 128.156-10.48 0-5.789-57.378-10.481-128.156-10.481S3.264 151.731 3.264 157.52c0 5.788 57.377 10.48 128.155 10.48Z"}
        fill={"#E6E6E6"}
      />
      <path
        d={"M236.67 151.286H115.892V61.991a7.78 7.78 0 0 1 7.78-7.78H236.67a10.8 10.8 0 0 1 10.8 10.808v75.45a10.786 10.786 0 0 1-3.157 7.648 10.793 10.793 0 0 1-7.643 3.169Z"}
        fill={"#3642E1"}
      />
      <path
        opacity={0.08}
        d={"M236.67 151.286H115.892V61.991a7.78 7.78 0 0 1 7.78-7.78H236.67a10.8 10.8 0 0 1 10.8 10.808v75.45a10.786 10.786 0 0 1-3.157 7.648 10.793 10.793 0 0 1-7.643 3.169Z"}
        fill={"#000"}
      />
      <path
        opacity={0.08}
        d={"M154.475 79.9c12.714 0 23.021-10.307 23.021-23.022 0-12.714-10.307-23.022-23.021-23.022-12.715 0-23.022 10.308-23.022 23.022 0 12.715 10.307 23.022 23.022 23.022Z"}
        fill={"#000"}
      />
      <path
        d={"M154.475 79.9c12.714 0 23.021-10.307 23.021-23.022 0-12.714-10.307-23.022-23.021-23.022-12.715 0-23.022 10.308-23.022 23.022 0 12.715 10.307 23.022 23.022 23.022Z"}
        fill={"#FFD200"}
      />
      <path
        opacity={0.08}
        d={"M155.593 72.969c12.715 0 23.022-10.308 23.022-23.022 0-12.715-10.307-23.022-23.022-23.022-12.714 0-23.022 10.307-23.022 23.022 0 12.714 10.308 23.022 23.022 23.022Z"}
        fill={"#000"}
      />
      <path
        opacity={0.08}
        d={"M154.475 79.9c12.714 0 23.021-10.307 23.021-23.022 0-12.714-10.307-23.022-23.021-23.022-12.715 0-23.022 10.308-23.022 23.022 0 12.715 10.307 23.022 23.022 23.022Z"}
        fill={"#000"}
      />
      <path
        d={"M155.593 72.969c12.715 0 23.022-10.308 23.022-23.022 0-12.715-10.307-23.022-23.022-23.022-12.714 0-23.022 10.307-23.022 23.022 0 12.714 10.308 23.022 23.022 23.022Z"}
        fill={"#FFD200"}
      />
      <path
        d={"M156.342 39.643c.648 0 .841.227.841.84v1.456a6.344 6.344 0 0 1 5.207 3.936c.336.682.193 1.052-.564 1.388l-1.388.623c-.656.31-.967.176-1.337-.505a3.531 3.531 0 0 0-3.617-2.178c-2.414 0-3.516.706-3.516 2.321a2.146 2.146 0 0 0 1.952 1.893c.673.15 1.356.254 2.044.31 1.285.062 2.555.311 3.768.741a5.155 5.155 0 0 1 1.682.9 5.483 5.483 0 0 1 1.506 3.97c0 3.087-2.069 5.182-5.72 5.72v1.472c0 .648-.202.841-.841.841h-1.565c-.647 0-.841-.227-.841-.841v-1.447a6.44 6.44 0 0 1-5.661-4.357.928.928 0 0 1 .271-1.13.924.924 0 0 1 .377-.174l1.388-.513c.707-.252 1.018-.084 1.329.623.622 1.592 1.898 2.386 3.827 2.38 2.608 0 3.903-.841 3.903-2.523a1.732 1.732 0 0 0-1.211-1.758 9.843 9.843 0 0 0-2.81-.539 12.54 12.54 0 0 1-3.793-.84 5.335 5.335 0 0 1-1.682-.934 5.142 5.142 0 0 1-1.531-3.844c0-3.062 1.926-5.098 5.551-5.586v-1.362c0-.656.227-.841.841-.841l1.59-.042Z"}
        fill={"#fff"}
      />
      <path
        opacity={0.08}
        d={"M208.156 91.583c12.715 0 23.022-10.307 23.022-23.022 0-12.715-10.307-23.022-23.022-23.022-12.714 0-23.022 10.307-23.022 23.022 0 12.715 10.308 23.022 23.022 23.022Z"}
        fill={"#000"}
      />
      <path
        d={"M208.156 91.583c12.715 0 23.022-10.307 23.022-23.022 0-12.715-10.307-23.022-23.022-23.022-12.714 0-23.022 10.307-23.022 23.022 0 12.715 10.308 23.022 23.022 23.022Z"}
        fill={"#FFD200"}
      />
      <path
        opacity={0.08}
        d={"M205.322 85.158c12.714 0 23.022-10.307 23.022-23.022 0-12.715-10.308-23.022-23.022-23.022-12.715 0-23.022 10.308-23.022 23.022 0 12.715 10.307 23.022 23.022 23.022Z"}
        fill={"#000"}
      />
      <path
        opacity={0.08}
        d={"M208.156 91.583c12.715 0 23.022-10.307 23.022-23.022 0-12.715-10.307-23.022-23.022-23.022-12.714 0-23.022 10.307-23.022 23.022 0 12.715 10.308 23.022 23.022 23.022Z"}
        fill={"#000"}
      />
      <path
        d={"M205.321 85.157c12.715 0 23.022-10.307 23.022-23.022 0-12.715-10.307-23.022-23.022-23.022-12.714 0-23.022 10.307-23.022 23.022 0 12.715 10.308 23.022 23.022 23.022Z"}
        fill={"#FFD200"}
      />
      <path
        d={"M200.342 53.093c.546-.353.841-.277 1.186.27l.757 1.169a6.323 6.323 0 0 1 6.51.462c.656.387.74.774.286 1.472l-.841 1.279c-.379.614-.715.664-1.396.294a3.57 3.57 0 0 0-4.206.143c-2.019 1.312-2.524 2.524-1.682 3.861a2.16 2.16 0 0 0 2.674.53 17.418 17.418 0 0 0 1.885-.841 12.82 12.82 0 0 1 3.558-1.43 5.044 5.044 0 0 1 1.892-.152 5.423 5.423 0 0 1 3.415 2.524c1.682 2.59 1.085 5.476-1.682 7.915l.799 1.228c.353.547.311.841-.236 1.203l-1.303.84c-.547.354-.841.287-1.212-.26l-.79-1.211a6.46 6.46 0 0 1-7.125-.572.923.923 0 0 1-.437-.684.924.924 0 0 1 .278-.763l.883-1.186c.454-.597.841-.63 1.455-.202 1.388.993 2.885.96 4.5-.084 2.187-1.421 2.835-2.818 1.926-4.206a1.743 1.743 0 0 0-1.976-.84 9.627 9.627 0 0 0-2.642 1.076 12.832 12.832 0 0 1-3.633 1.38 5.473 5.473 0 0 1-1.885.109 5.202 5.202 0 0 1-3.364-2.397c-1.682-2.524-1.161-5.325 1.623-7.705l-.757-1.16c-.353-.548-.286-.842.253-1.22l1.287-.842Z"}
        fill={"#fff"}
      />
      <path
        d={"M115.892 61.992h111.644a11.33 11.33 0 0 1 11.33 11.33v74.407a11.33 11.33 0 0 1-11.33 11.33H115.892V61.992Z"}
        fill={"#3642E1"}
      />
      <path
        d={"M207.946 100.39h40.517v20.271h-40.517a10.127 10.127 0 0 1-7.167-2.969 10.126 10.126 0 0 1-2.969-7.167 10.136 10.136 0 0 1 10.136-10.135Z"}
        fill={"#24285B"}
      />
      <path
        d={"M210.133 115.681a5.156 5.156 0 1 0 0-10.312 5.156 5.156 0 0 0 0 10.312Z"}
        fill={"#FFD200"}
      />
      <path
        opacity={0.58}
        d={"M99.65 150.816s-6.503-1.775-7.916-7.831c0 0 10.094-2.035 10.363 8.412l-2.447-.581Z"}
        fill={"#3642E1"}
      />
      <path
        opacity={0.73}
        d={"M100.448 150.168s-4.542-7.183-.546-13.896c0 0 7.662 4.862 4.256 13.913l-3.71-.017Z"}
        fill={"#3642E1"}
      />
      <path
        d={"M101.618 150.176s2.405-7.57 9.656-9.026c0 0 1.363 4.929-4.694 9.043l-4.962-.017Z"}
        fill={"#3642E1"}
      />
      <path
        d={"m96.899 150.008 1.32 9.017 8.303.033 1.228-9.008-10.851-.042Z"}
        fill={"#24285B"}
      />
      <path
        d={"M77.342 151.354s2.524 2.363 5.258 1.244c2.733-1.118 4.735 1.094 2.716 2.961-2.018 1.868-9.639 1.96-9.639 1.96l-2.246-4.912 3.911-1.253ZM34.832 150.252s-1.237 3.23.84 5.341c2.078 2.112.775 4.795-1.681 3.634-2.457-1.161-5.443-8.193-5.443-8.193l3.668-3.953 2.616 3.171Z"}
        fill={"#3642E1"}
      />
      <path
        d={"M28.06 89.54s-2.666 10.539 1.969 15.729c4.634 5.19 11.776 29.76 0 42.057l4.643 4.366s20.339-10.372 14.535-47.777l14.518-14.367-35.664-.008Z"}
        fill={"#24285B"}
      />
      <path
        d={"M63.724 29.507s14.367 3.659 14.468 15.552c.1 11.894-6.367 32.712 2.868 34.411 9.236 1.7 14.762-24.006 14.762-24.006l6.586 2.658s-7.006 43.908-31.223 36.665c-24.216-7.242-7.46-65.28-7.46-65.28Z"}
        fill={"#3642E1"}
      />
      <path
        opacity={0.44}
        d={"M63.724 29.507s14.367 3.659 14.468 15.552c.1 11.894-6.367 32.712 2.868 34.411 9.236 1.7 14.762-24.006 14.762-24.006l6.586 2.658s-7.006 43.908-31.223 36.665c-24.216-7.242-7.46-65.28-7.46-65.28Z"}
        fill={"#fff"}
      />
      <path
        d={"M50.805 9.977 46.43 25.403l8.672 1.8-.295-11.944-4.003-5.282Z"}
        fill={"#F4A28C"}
      />
      <path
        d={"m46.43 25.403 5.132 1.068s23.551 2.524 24.502 15.822c.95 13.298-12.348 47.247-12.348 47.247H28.06S-.522 21.886 46.43 25.403Z"}
        fill={"#3642E1"}
      />
      <path
        opacity={0.09}
        d={"M43.352 37.733s2.877 8.294-4.668 13.71c-7.545 5.418-3.86 25.824 4.39 29.087 8.252 3.264-5.155 10.338-10.858 7.453-5.703-2.885-6.536-4.828-6.536-4.828s-5.308-15.343-5.888-25.133c-.58-9.791 23.56-20.289 23.56-20.289Z"}
        fill={"#000"}
      />
      <path
        d={"M43.352 37.733c0-7.31-7.402-12.297-14.35-10.018-6.039 1.96-13.407 5.636-17.882 12.348-8.597 12.903-28.254 50.662 21.642 52.075l1.464-5.552S4.273 78.301 17.496 60.78c10.287-13.635 25.881-12.718 25.856-23.047Z"}
        fill={"#3642E1"}
      />
      <path
        opacity={0.44}
        d={"M43.352 37.733c0-7.31-7.402-12.297-14.35-10.018-6.039 1.96-13.407 5.636-17.882 12.348-8.597 12.903-28.254 50.662 21.642 52.075l1.464-5.552S4.273 78.301 17.496 60.78c10.287-13.635 25.881-12.718 25.856-23.047Z"}
        fill={"#fff"}
      />
      <path
        d={"M97.361 56.087s.379-6.359 2.927-8.159c2.549-1.8 2.12 2.76 2.12 2.76s2.524-2.978 4.677-1.59c2.153 1.388-4.677 9.025-4.677 9.025l-5.047-2.035Z"}
        fill={"#F4A28C"}
      />
      <path
        d={"m63.724 89.54 14.914 62.143-7.453 1.094s-14.913-42.595-24.754-51.31L63.724 89.54Z"}
        fill={"#24285B"}
      />
      <path
        d={"M34.024 87.36s5.324-.84 7.1 2.835c1.774 3.676-8.362 1.951-8.362 1.951l1.262-4.786Z"}
        fill={"#F4A28C"}
      />
      <path
        opacity={0.08}
        d={"M107.144 52.975c12.715 0 23.022-10.307 23.022-23.022 0-12.714-10.307-23.021-23.022-23.021-12.715 0-23.022 10.307-23.022 23.021 0 12.715 10.307 23.022 23.022 23.022Z"}
        fill={"#000"}
      />
      <path
        d={"M107.144 52.975c12.715 0 23.022-10.307 23.022-23.022 0-12.714-10.307-23.021-23.022-23.021-12.715 0-23.022 10.307-23.022 23.021 0 12.715 10.307 23.022 23.022 23.022Z"}
        fill={"#FFD200"}
      />
      <path
        opacity={0.08}
        d={"M108.271 46.044c12.715 0 23.022-10.307 23.022-23.022C131.293 10.307 120.986 0 108.271 0 95.556 0 85.249 10.307 85.249 23.022c0 12.715 10.307 23.022 23.022 23.022Z"}
        fill={"#000"}
      />
      <path
        opacity={0.08}
        d={"M107.144 52.975c12.715 0 23.022-10.307 23.022-23.022 0-12.714-10.307-23.021-23.022-23.021-12.715 0-23.022 10.307-23.022 23.021 0 12.715 10.307 23.022 23.022 23.022Z"}
        fill={"#000"}
      />
      <path
        d={"M108.271 46.044c12.715 0 23.022-10.307 23.022-23.022C131.293 10.307 120.986 0 108.271 0 95.556 0 85.249 10.307 85.249 23.022c0 12.715 10.307 23.022 23.022 23.022Z"}
        fill={"#FFD200"}
      />
      <path
        d={"M109.011 12.718c.648 0 .841.227.841.84v1.465a6.321 6.321 0 0 1 5.207 3.928c.345.68.202 1.051-.564 1.387l-1.387.623c-.657.311-.968.177-1.329-.505a3.551 3.551 0 0 0-3.626-2.178c-2.405 0-3.516.706-3.516 2.321a2.148 2.148 0 0 0 1.96 1.893c.671.15 1.351.254 2.036.311 1.285.064 2.554.314 3.768.74a5.155 5.155 0 0 1 1.682.9 5.457 5.457 0 0 1 1.506 3.97c0 3.087-2.069 5.182-5.72 5.72v1.472c0 .648-.202.841-.841.841h-1.556c-.656 0-.841-.227-.841-.84v-1.481a6.447 6.447 0 0 1-5.661-4.357.924.924 0 0 1 .656-1.304l1.388-.513c.706-.253 1.018-.084 1.329.622.622 1.587 1.895 2.38 3.819 2.38 2.607 0 3.911-.84 3.911-2.523a1.728 1.728 0 0 0-1.22-1.758 9.827 9.827 0 0 0-2.801-.538 12.738 12.738 0 0 1-3.793-.841 5.468 5.468 0 0 1-1.682-.942 5.145 5.145 0 0 1-1.523-3.844c0-3.062 1.926-5.098 5.543-5.577v-1.329c0-.656.227-.841.841-.841l1.573-.042Z"}
        fill={"#fff"}
      />
    </svg>
  )
}

export default ImageSingUpToViewRule
