import React from 'react'

export interface IconRuleUpdatedProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconRuleUpdated'
export const IconRuleUpdated = (props: IconRuleUpdatedProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      id={"Layer_1"}
      xmlns={"http://www.w3.org/2000/svg"}
      x={0}
      y={0}
      viewBox={"0 0 23 23"}
      enableBackground={"new 0 0 23 23"}
      xmlSpace={"preserve"}
      width={"1em"}
      height={"1em"}
      {...otherProps}
    >
      <path
        fill={'#05b4bc'}
        d={"M20.2 8c-.1-.2-.2-.4-.3-.5l-4.1-4.1-.1-.1c-.2-.2-.4-.3-.5-.3-.2-.1-.4-.1-.6-.1H8.7c-.2 0-.4 0-.6.1-.1 0-.2.1-.3.2-.1 0-.2.1-.2.1L3.5 7.4c-.1.1-.2.2-.2.3-.1.1-.1.2-.2.3-.1.2-.1.4-.1.6v5.8c0 .2 0 .4.1.6.1.2.2.4.4.5l4.1 4.1c.1.1.3.3.5.3.2.1.4.1.6.1h6.1c.1 0 .2-.1.3-.1.2-.1.4-.2.5-.4l4.1-4.1c.2-.2.3-.3.3-.5.1-.2.1-.4.1-.6V8.6c.2-.2.2-.4.1-.6zm-1.5 6.4-4.1 4.1H8.7l-4.1-4.1V8.6v5.8l4.1 4.1h5.8l4.2-4.1zm-14 0V8.6l4.1-4.1h5.7l4.1 4.1v5.7l-4.1 4.1H8.8l-4.1-4zm15.5 0v.3-6.1c0-.2 0-.4-.1-.6-.1-.2-.2-.4-.3-.5l-4.1-4.1-.1-.1c-.1-.1-.2-.2-.4-.2-.2-.1-.4-.2-.6-.2H8.7c-.2 0-.4 0-.6.1-.1.1-.2.2-.3.2-.1 0-.1.1-.2.2L3.5 7.5c0 .1-.1.2-.2.3.1-.1.1-.2.2-.3l4.1-4.1.2-.2c.1 0 .2-.1.4-.1.1-.1.3-.2.5-.2h5.8c.2 0 .4 0 .6.1.1.1.3.1.4.2l.1.1 4.1 4.1c.2.3.3.4.4.6.1.2.1.4.1.6v5.8z"}
      />
      <path
        fill={'#05b4bc'}
        d={"M10.6 16h2.2v-3.4h3.4v-2.2h-3.4V7h-2.2v3.4H7.2v2.2h3.4V16zm2-.1v-3.4 3.4zm-1.9-8.8h2v3.4H16v2-2h-3.4V7.1h-1.9zm-3.4 3.4h3.4-3.4z"}
      />
    </svg>
  )
}

export default IconRuleUpdated
