import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Modal, Select } from 'antd';
import Popover from '../../../../components/Popover';

import RemoveSequence from '../../../../components/Sequences/RemoveSequence';
import { AddRuleStore, NewSequence } from 'stores/addRuleStore.interface';
import {
  Notify,
  ENotifyChannel,
  ENotifyTimeframe,
} from 'components/Rule/DetailTextSequence/notify.type';
import ConnectTelegram from 'components/ConnectTelegram';
import { CoinruleButton } from 'components/CoinruleButton';
import '../../../SettingsPage/Trading/style.scss';
import { successNotification } from 'helpers/notification';

interface IProps {
  telegramId: string;
  userEmail: string;
  actionId: string;
  canBeRemoved: boolean;
  item: Notify;
  sequenceIndex: number;
  store: AddRuleStore<NewSequence>;
  updateTelegramId: (telegramId: string) => void;
}
@observer
class NotifySequencePart extends React.Component<IProps> {
  static propTypes = {
    telegramId: PropTypes.string.isRequired,
    userEmail: PropTypes.string.isRequired,
    actionId: PropTypes.string.isRequired,
    canBeRemoved: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    sequenceIndex: PropTypes.number.isRequired,
    updateTelegramId: PropTypes.func.isRequired,
  };

  state = {
    isModalOpen: false,
    isSubmittingTelegramId: false,
  };

  renderNotificationChannelSelect() {
    const { item, sequenceIndex, store } = this.props;

    return (
      <Popover text={'Select Notification Channel'}>
        <Select
          size={'default'}
          style={{
            width: 200,
            textTransform: item.channel === ENotifyChannel.TELEGRAM ? 'capitalize' : 'none' 
          }}
          onChange={(value: ENotifyChannel) =>
            store.changeNotificationChannel(sequenceIndex, value)
          }
          value={item.channel}>
          {Object.values(ENotifyChannel).map((channel: ENotifyChannel, i) => {
            return (
              <Select.Option
                key={i}
                value={channel}
                style={{
                  textTransform: channel === ENotifyChannel.TELEGRAM ? 'capitalize' : 'none' 
                }}
              >
                {channel}
              </Select.Option>
            );
          })}
        </Select>
      </Popover>
    );
  }

  renderConnectTelegram() {
    const { telegramId } = this.props;

    return (
      <>
        <span>
          {telegramId ? (
            null
          ) : (
            <div
              style={{ whiteSpace: 'nowrap', cursor: 'pointer', textDecoration: 'underline', color: '#4157F0' }}
              role='button'
              onClick={() => this.setState({ isModalOpen: true })}>
              setup telegram integration
            </div>
          )}
        </span>

        {this.renderConnectTelegramModal()}
      </>
    );
  }

  renderNotificationTimeframeSelect() {
    const { item, sequenceIndex, store } = this.props;

    return (
      <Popover text={'Select Notification Timeframe'}>
        <Select
          size={'default'}
          style={{ width: 200 }}
          onChange={(value: ENotifyTimeframe) =>
            store.changeNotificationTimeframe(sequenceIndex, value)
          }
          value={item.timeframe}>
          {Object.values(ENotifyTimeframe).map((timeframe: ENotifyTimeframe, i) => {
            return (
              <Select.Option
                key={i}
                value={timeframe}
                disabled={timeframe !== ENotifyTimeframe.INSTANTLY}>
                {timeframe}
              </Select.Option>
            );
          })}
        </Select>
      </Popover>
    );
  }

  onSubmitTelegramId = async (telegramId: string) => {
    try {  
      this.setState({ isSubmittingTelegramId: true });
      await this.props.updateTelegramId(telegramId);
  
      this.setState({ isSubmittingTelegramId: false, isModalOpen: false });
      successNotification('Telegram has been set up successfully!')
    } catch (error) {
      
    }
  };

  renderConnectTelegramModal() {
    const { telegramId } = this.props;
    const { isModalOpen, isSubmittingTelegramId } = this.state;

    return (
      <Modal
        visible={isModalOpen}
        footer={null}
        onCancel={() => this.setState({ isModalOpen: false })}
        centered={true}>
        <ConnectTelegram telegramId={telegramId || ''} isLoading={false}>
          {(telegramId) => (
            <CoinruleButton
              color='green'
              loading={isSubmittingTelegramId}
              onClick={() => this.onSubmitTelegramId(telegramId)}
              style={{ marginLeft: '5px' }}>
              Submit
            </CoinruleButton>
          )}
        </ConnectTelegram>
      </Modal>
    );
  }

  render() {
    const { item, sequenceIndex, store } = this.props;

    return (
      <>
        <div className={`newRuleFormSection content`}>
          <RemoveSequence
            canBeRemoved={store.canSequenceBeRemoved(sequenceIndex)}
            onClick={() => store.seqInit.removeFromSequence(sequenceIndex)}
          />
          <div
            className={`select-grey input-grey`}
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '10px',
            }}>
            <div style={{ whiteSpace: 'nowrap' }}>
              Send a notification {item.channel === ENotifyChannel.EMAIL ? 'by' : 'to'}
            </div>
            {this.renderNotificationChannelSelect()}
            {item.channel === ENotifyChannel.TELEGRAM ? (
              this.renderConnectTelegram()
            ) : (
              <div>to <span style={{fontWeight: 500}}>{this.props.userEmail}</span></div>
            )}
            {/* this could still be used in the future */}
            {/* {this.renderNotificationTimeframeSelect()} */}
          </div>
        </div>
      </>
    );
  }
}

export default NotifySequencePart;
