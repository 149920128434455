import Popover from 'components/Popover';
import React from 'react';

import usePagination, { UsePaginationOptions } from '../../hooks/usePagination';

export interface PaginationProps extends UsePaginationOptions {
  currentPage: number;
  classname?: string;
  getPageClassname: (page: number) => string;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalCount,
  sizePerPage,
  currentPage,
  classname = '',
  getPageClassname,
  onPageChange,
}) => {
  const paginationRange = usePagination({ totalCount, sizePerPage });

  if (!paginationRange?.length) return null;

  const getArrowCls = () => {
    const totalRange = paginationRange.length;

    const baseCls = 'rulesTemplatesList__paginationArr';
    const inactiveCls = `${baseCls}_inactive`;
    const invisibleCls = `${baseCls}_invisible`;

    const removeCls = (array: string[], clsToRemove: string) =>
      array.filter((cls) => cls !== clsToRemove);

    const arrowLeftCls = [`${baseCls} ${baseCls}_left`];
    const arrowRightCls = [`${baseCls} ${baseCls}_right`];

    if (totalRange > 1) {
      if (currentPage === 1) {
        arrowLeftCls.push(inactiveCls);
      } else if (currentPage !== 1 && currentPage !== totalRange) {
        removeCls(arrowLeftCls, inactiveCls);
        removeCls(arrowRightCls, inactiveCls);
      } else if (currentPage === totalRange) {
        arrowRightCls.push(inactiveCls);
        removeCls(arrowLeftCls, inactiveCls);
      }
    } else if (totalRange === 1) {
      arrowLeftCls.push(invisibleCls);
      arrowRightCls.push(invisibleCls);
    }

    return {
      arrowLeftCls: arrowLeftCls.join(' '),
      arrowRightCls: arrowRightCls.join(' '),
    };
  };

  const { arrowLeftCls, arrowRightCls } = getArrowCls();

  return (
    <div className={`custom-pagination ${classname}`}>
      {paginationRange.map((page) => (
        <div
          role='button'
          data-testid='pagination-navigation'
          className={getPageClassname(page)}
          key={page}
          onClick={() => onPageChange(page)}>
          {page}
        </div>
      ))}

      <Popover text='See more Templates' placement='bottom'>
        <div className={`rulesTemplatesList__paginationArrows`}>
          <div
            role='button'
            data-testid='pagination-back'
            className={arrowLeftCls}
            onClick={() => onPageChange(currentPage - 1)}>
            &nbsp;
          </div>
          <div
            data-testid='pagination-next'
            role='button'
            className={arrowRightCls}
            onClick={() => onPageChange(currentPage + 1)}>
            &nbsp;
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default Pagination;
