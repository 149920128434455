import { observable, action, toJS } from 'mobx';
import stores from './index';
import { errorNotification, successNotification } from 'helpers/notification';
import http from '../helpers/http';
import { handleMixpanelAnalytics, sendAnalytics } from 'helpers/analytics';
import { IS_PROD } from 'constants/isProd';
import { AbstractStore } from './AbstractStore';

export default class TemplatesStore extends AbstractStore {
  constructor(rootStore) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  @observable showOverlay = false;
  @observable availableTemplatesIds = [];
  @observable isSelect = false;
  @observable templates = [];
  @observable categories = [];
  @observable branches = [
    // why hardcored _ids?
    { _id: 10012020084409571, name: 'Strategies' },
    { _id: 10012020084414881, name: 'Coins' },
  ];

  blankRule = {
    id: 0,
    category: { nam: 'Maximize' },
    templateName: 'Start Blank',
    description: 'Start Blank',
    teaserText: 'Start Blank',
    complexity: 0,
    amount: 0,
    pair: '',
    percentage: 0,
    number: 0,
    graph: '',
    secured: false,
    params: {
      rule: {},
      conditions: [],
      actions: [],
    },
  };

  @observable isFetchingTemplates = false;
  @observable isFetchingTemplate = false;
  @observable totalTemplates = 0;
  @observable itemsPerPage = 10;
  @observable resultCount = 0;

  @action setItemsPerPage = (itemsPerPage) => (this.itemsPerPage = itemsPerPage);

  @action
  setTemplates(templates = []) {
    this.templates = templates;
  }

  @action setResultCount(resultCount) {
    this.resultCount = resultCount;
  }

  @action
  setTemplatesCategories(templateCategories) {
    this.categories = templateCategories;
  }

  @action
  setTotalTemplates = (totalTemplates = 0) => (this.totalTemplates = totalTemplates);

  getUnlockedTemplateIds = (templates) =>
    templates.reduce((acc, template) => {
      if (template.unlocked && !acc.includes(template._id)) {
        acc.push(template._id);
      }

      return acc;
    }, []);

  @action
  setAvailableTemplateIds(templates) {
    const unlockedTemplateIds = this.getUnlockedTemplateIds(templates);

    this.availableTemplatesIds = unlockedTemplateIds;
  }

  @action setIsFetchingTemplate = (isFetching) => (this.isFetchingTemplate = isFetching);

  fetchTemplate = async (templateId) => {
    this.setIsFetchingTemplate(true);
    try {
      const response = await http.get(`/templates/${templateId}`);
      this.setIsFetchingTemplate(false);
      return response.data;
    } catch (e) {
      console.log(e);
      this.setIsFetchingTemplate(false);
    }
  };

  @action
  fetchTemplates = async (params = {}) => {
    this.isFetchingTemplates = true;

    const getQuery = (params = {}) => {
      const entries = Object.entries(params);

      if (entries?.length) {
        return `?${entries.map(([key, value]) => `${key}=${value}`).join('&')}`;
      }

      return '';
    };

    try {
      const query = getQuery({ ...params, limit: this.itemsPerPage });
      const response = await http.get(`/templates${query}`);
      const { templateCategories, templates, totalCount, resultCount } = response.data;

      this.setTemplates(templates);
      this.setTemplatesCategories(templateCategories);
      this.setAvailableTemplateIds(templates);
      this.setTotalTemplates(totalCount);
      this.setResultCount(resultCount);

      this.isFetchingTemplates = false;

      return templates;
    } catch (error) {
      console.log(error);
    }
  };

  @action
  getAvailableTemplatesIds() {
    return this.availableTemplatesIds;
  }

  @action
  findTemplate = async (templateName) => {
    if (!this.templates?.length) {
      await stores.info.getLists(true);
    }

    return this.templates.find(
      (template) => template.templateName.toLowerCase() === templateName.toLowerCase()
    );
  };

  @action
  setIsSelect(value) {
    this.isSelect = value;
  }

  handleTemplateApply = async ({
    template,
    action = 'withExchangePreselection',
    ruleStore,
    callback,
  }) => {
    // Preselect Binance if connected
    // otherwise preselect any other exchange except Demo if connected
    if (action === 'withExchangePreselection') {
      stores.ruleDetailsStore.setTemplatesListVisibility(false);

      const totalConnectedExchanges = toJS(stores.userInfo?.exchanges);

      const selectedExchange = ruleStore.rule.ex;

      if (!selectedExchange) {
        if (totalConnectedExchanges?.length > 0) {
          stores.ruleDetailsStore.toggleIsTemplateApplyingPending();
          const exchangeId = totalConnectedExchanges.find(
            (exchange) => exchange.uid === 'binance' || exchange.uid === 'demo'
          )?.id;
          await ruleStore.changeExchange(exchangeId);
          stores.ruleDetailsStore.toggleIsTemplateApplyingPending();
        }
      }
    }

    if (action === 'reset') {
      ruleStore.resetRule();
      ruleStore.resetTemplate();
      stores.templatesStore.setIsSelect(false);
      stores.ruleDetailsStore.setTemplatesListVisibility(false);

      return;
    }

    ruleStore.setRuleWithTemplate(template, ruleStore.rule.ex);

    typeof callback === 'function' && callback();

    try {
      handleMixpanelAnalytics({
        event: 'Template selected',
        data: { 'Template Name': template.templateName, 'Template ID': template._id },
      });

      if (IS_PROD) {
        sendAnalytics('facebook', {
          event: ['trackCustom', 'UserUseTemplate'],
          data: { template: template.templateName },
        });
      }
    } catch (error) { }
  };

  @action
  setShowOverlay(newStatus) {
    this.showOverlay = newStatus;
  }

  async deleteTemplate(templateName) {
    try {
      const resp = await http.delete('/templates', {
        data: { templateName },
      });

      if (resp.data) {
        if (resp.data.status === 'OK') {
          successNotification('Template has been deleted');
        } else {
          throw new Error(resp.data.message);
        }
      } else {
        throw new Error('Unknown response');
      }
    } catch (err) {
      errorNotification('Error while deleting a template. Error: ', err);
    }
  }

  async createNewTemplate(inputs, addRuleStore) {
    const rule = addRuleStore.getRuleData();

    if (!rule.nam) {
      errorNotification('Please provide rule name (rule template name)');
      return false;
    }

    rule.rpt = toJS(rule.rpt);
    rule.ex = null; // we don't want templates to be tied to a specific exchange

    const data = {
      ...inputs,
      templateName: rule.nam.trim(),
      params: JSON.stringify(rule),
    };

    try {
      const resp = await http.post('/templates', data);

      if (resp.data) {
        if (resp.data.status === 'OK') {
          successNotification('New template has been created');
        } else {
          throw new Error(resp.data.message);
        }
      } else {
        throw new Error('Unknown response');
      }

      return true;
    } catch (err) {
      errorNotification('Error while creating a new template. Error: ', err);
      return false;
    }
  }
}
