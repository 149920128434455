import React from 'react';

import videoImage from 'assets/images/base_video_image.svg';
import { ReactComponent as PlayIcon } from 'assets/images/icons/play.svg';

import './style.scss';

export interface IVideoThumbnailProps {
  onClick: () => void;
  imageSrc?: string;
  videoLength?: number;
  showPlayButton?: boolean;
  playButtonStyles?: object;
}

const VideoThumbnail: React.FC<IVideoThumbnailProps> = ({
  onClick,
  imageSrc,
  videoLength,
  showPlayButton = true,
  playButtonStyles,
}) => {
  return (
    <>
      <div
        // for a11y since this is a clickable div
        role='button'
        className='image-video-area'
        onClick={onClick}
        data-testid='video-thumbnail-container'>
        <img src={imageSrc || videoImage} alt='Video tutorial' className='video-image' />

        {showPlayButton && (
          <PlayIcon
            {...(playButtonStyles && { style: playButtonStyles })}
            className='play-button'
          />
        )}
      </div>
      {videoLength && <em>{videoLength} sec</em>}
    </>
  );
};

export default VideoThumbnail;
