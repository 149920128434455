import React from 'react';
import { MobXProviderContext, useObserver } from 'mobx-react';

export type Selector<S, T> = (stores: S, ownProps: T) => S;

const storesInjector =
  <S, T = any>(selector: Selector<S, T>) =>
  (baseComponent: React.FC<T>) => {
    const Component: React.FC<T> = (ownProps: T) => {
      const stores = React.useContext(MobXProviderContext) as S;
      const selectedStores = selector(stores, ownProps);
      return useObserver(() => baseComponent({ ...selectedStores, ...ownProps }));
    };

    Component.displayName = baseComponent.displayName || baseComponent.name;

    return Component;
  };

// usage: export default storesInjector(selector)(Index);

export default storesInjector;
