import React from 'react'
import { format as formatDate } from 'date-fns'

import type { TypographyProps } from '@mui/material'

import { Typography } from '@mui/material'

export interface DateFormattedProps extends TypographyProps {
  date: number | Date
  format: string
  component?: React.ElementType
}

const testId = 'DateFormatted'
export const DateFormatted = (props: DateFormattedProps) => {
  const {
    sx,
    date,
    format,
    component = 'p',
    ...otherProps
  } = props

  return (
    <Typography
      data-testid={testId}
      className={testId}
      component={component}
      sx={{
        ...sx
      }}
      {...otherProps}
    >
      {formatDate(date, format)}
    </Typography>
  )
}

export default DateFormatted
