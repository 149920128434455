import { observer } from 'mobx-react';
import React from 'react';
import Popover from '../../../../components/Popover';
import { Select } from 'antd';
import { 
  CONDITION_OPERATORS, 
  CONDITION_TYPES 
} from '../../../../stores/addRule';
import {
  getFieldCssClass,
} from '../../../../helpers/utils';

const Option = Select.Option;

@observer
class Orand extends React.Component {
  render() {
    // const { index, store, conditionIndex, conditionId } = this.props;
    const { index, store, conditionIndex } = this.props;
    const value = store.seqInit.sequences[conditionIndex].data.conditions[index].co;

    const orOption = (
      (
        conditionIndex === 1 && 
        store.seqInit.sequences[conditionIndex-1].data.conditions[index]?.ifc === CONDITION_TYPES.TRADINGVIEW_SIGNAL
      ) ? 
      null : 
      (
        <Option title='' value={CONDITION_OPERATORS.OR}>
          or
        </Option>
      )
    );

    return (
      <div className='orand'>
        <div className='select-grey' style={{ minWidth: 90 }}>
          <Popover number='6' text={'Select Operator'}>
            <Select
              title=''
              value={value}
              onChange={(value) => store.changeConditionOrand(index, value, conditionIndex)}
              size={'default'}
              style={{ minWidth: 90 }}
              className={`${!this.props.store.isQuickEdit() && getFieldCssClass(value)}`}
              disabled={this.props.store.isQuickEdit()}
            >
              <Option title='' value={CONDITION_OPERATORS.AND}>
                and
              </Option>
              {orOption}
            </Select>
          </Popover>
        </div>
      </div>
    );
  }
}

export default Orand;
