import React from 'react';
import { toJS } from 'mobx';

import DetailTextActions from './Action';
import DetailTextConditions from './Condition';
import DetailTextOperators from './Operator';
import DetailTextNotifies from './Notify';
import { EXCHANGES_TYPE } from '../../../constants/exchanges';
import { ACTION_TYPE, CONDITION_TYPE, NOTIFY_TYPE, OPERATOR_TYPE } from '../../../stores/addRule';
import { AddRuleStore, NewSequence } from '../../../stores/addRuleStore.interface';
import { Stores } from '../../../stores/stores.interface';

interface IProps {
  exchange: any;
  handleSectionClick: Function;
  store: AddRuleStore<NewSequence>;
  stores: Stores;
}

const SequenceBaseText = (props: IProps) => {
  const { exchange, handleSectionClick, store, stores } = props;

  const isLeverageExchange =
    stores.exchangeInfo.exchangeType[store.rule.ex] === EXCHANGES_TYPE.LEVERAGE;
  const sequenceHtml = [];

  for (let i in store.seqInit.sequences) {
    const sequenceIndex = Number(i);
    const sequence = store.seqInit.sequences[i];
    const prevSequence = toJS(store.seqInit.sequences[sequenceIndex - 1]);

    if (sequence.type === ACTION_TYPE) {
      let isItFirstAction;

      if (prevSequence) {
        isItFirstAction = prevSequence.type === ACTION_TYPE;
      }

      sequenceHtml.push(
        <DetailTextActions
          key={i}
          store={store}
          isLeverageExchange={isLeverageExchange}
          actions={sequence.data.actions}
          hasPreviousAction={isItFirstAction}
          sequenceIndex={i}
          handleClick={handleSectionClick}
          sequences={store.seqInit.sequences}
          exchange={exchange}
        />
      );
    } else if (sequence.type === CONDITION_TYPE) {
      const isItFirstCondition = +i !== 0 ? !(prevSequence.type === CONDITION_TYPE) : true;

      sequenceHtml.push(
        <DetailTextConditions
          store={store}
          conditionsData={sequence.data.conditions}
          key={i}
          isLeverageExchange={isLeverageExchange}
          entireSequence={store.seqInit.sequences}
          hasPreviousCondition={!isItFirstCondition}
          handleClick={handleSectionClick}
          sequenceIndex={i}
        />
      );
    } else if (sequence.type === OPERATOR_TYPE) {
      sequenceHtml.push(
        <DetailTextOperators
          key={i}
          isLeverageExchange={isLeverageExchange}
          operators={sequence.data.operators}
          sequenceIndex={i}
          handleClick={handleSectionClick}
          sequences={store.seqInit.sequences}
        />
      );
    } else if (sequence.type === NOTIFY_TYPE) {
      const isFirstNotify = !prevSequence || prevSequence.type !== NOTIFY_TYPE;
      sequenceHtml.push(
        <DetailTextNotifies
          key={sequenceIndex}
          sequenceIndex={sequenceIndex}
          isFirstNotify={isFirstNotify}
          isLoading={stores.addRule.sfpPendingSectionId === Number(sequenceIndex)}
          onClick={() => handleSectionClick(i)}
          notify={sequence.data.notifies[0]}
        />
      );
    }
  }

  return sequenceHtml;
};

SequenceBaseText.propTypes = {};

export default SequenceBaseText;
