import React from 'react'

import type { BoxProps } from '@mui/material'

import { Box } from '@mui/material'

import type { TExchangeUid } from 'design-system'

import { ImageExchangesLogosSprite, ImageExchangesLogosInvertedSprite } from 'design-system'

const SIDE_LENGTH_IN_PERCENT = 100 / 17

const exchangesSpritesPositions: Record<TExchangeUid, { transform: React.CSSProperties['transform'] }> = {
  poloniex: {
    transform: `translateX(${-SIDE_LENGTH_IN_PERCENT * 0}%)`,
  },
  bittrex: {
    transform: `translateX(${-SIDE_LENGTH_IN_PERCENT * 1}%)`,
  },
  okex: {
    transform: `translateX(${-SIDE_LENGTH_IN_PERCENT * 2}%)`,
  },
  'binance-us': {
    transform: `translateX(${-SIDE_LENGTH_IN_PERCENT * 3}%)`,
  },
  hitbtc: {
    transform: `translateX(${-SIDE_LENGTH_IN_PERCENT * 4}%)`,
  },
  kraken: {
    transform: `translateX(${-SIDE_LENGTH_IN_PERCENT * 5}%)`,
  },
  coinbasepro: {
    transform: `translateX(${-SIDE_LENGTH_IN_PERCENT * 6}%)`,
  },
  bitstamp: {
    transform: `translateX(${-SIDE_LENGTH_IN_PERCENT * 7}%)`,
  },
  bitmex: {
    transform: `translateX(${-SIDE_LENGTH_IN_PERCENT * 8}%)`,
  },
  liquid: {
    transform: `translateX(${-SIDE_LENGTH_IN_PERCENT * 9}%)`,
  },
  binance: {
    transform: `translateX(${-SIDE_LENGTH_IN_PERCENT * 10}%)`,
  },
  'binance-futures': {
    transform: `translateX(${-SIDE_LENGTH_IN_PERCENT * 16}%)`,
  },
  bitpanda: {
    transform: `translateX(${-SIDE_LENGTH_IN_PERCENT * 11}%)`,
  },
  demo: {
    transform: `translateX(${-SIDE_LENGTH_IN_PERCENT * 12}%)`,
  },
  bitfinex: {
    transform: `translateX(${-SIDE_LENGTH_IN_PERCENT * 13}%)`,
  },
  deribit: {
    transform: `translateX(${-SIDE_LENGTH_IN_PERCENT * 14}%)`,
  },
  kucoin: {
    transform: `translateX(${-SIDE_LENGTH_IN_PERCENT * 15}%)`,
  }
}

export interface ExchangeLogoSpriteProps extends BoxProps {
  exchange: TExchangeUid
  size?: number
  variant?: 'default' | 'inverted'
}

const testId = 'ExchangeLogoSprite'
export const ExchangeLogoSprite = (props: ExchangeLogoSpriteProps) => {
  const {
    sx,
    exchange,
    size = 24,
    variant = 'default',
    ...otherProps
  } = props

  const ImageComponent = variant === 'default' ? ImageExchangesLogosSprite : ImageExchangesLogosInvertedSprite

  return (
    <Box
      data-testid={testId}
      className={testId}
      sx={{
        width: `${size}px`,
        height: `${size}px`,
        display: 'inline-block',
        overflow: 'hidden',
        position: 'relative',
        ...sx
      }}
      {...otherProps}
    >
      {
        // do not render first logo when exchange is not passed
        exchange ?
          <ImageComponent
            style={{
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              ...exchangesSpritesPositions[exchange]
            }}
          />
          :
          null
      }
    </Box>
  )
}

export default ExchangeLogoSprite
