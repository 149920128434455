import React from 'react';
import PropTypes from 'prop-types';

import Popover from '../../Popover';

interface IProps {
  canBeRemoved: boolean;
  onClick: () => void;
}

const RemoveSequence: React.FC<IProps> = (props) => {
  const { canBeRemoved, onClick } = props;

  if (!canBeRemoved) {
    return null;
  }

  return (
    <Popover text={'Remove Block'}>
      <div className={`newRuleTitleTabs__deleteSectionBut`} onClick={onClick}>
        &nbsp;
      </div>
    </Popover>
  );
};

RemoveSequence.propTypes = {
  canBeRemoved: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RemoveSequence;
