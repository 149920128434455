import { observable, action, toJS } from 'mobx';
import http from '../helpers/http';
import { errorNotification } from '../helpers/notification';
import stores from './index';
import { ORDER_TYPES } from '../stores/addRule';
import { sortAndCalculatePriceWallets } from '../helpers/conversion';
import { EXCHANGES_TYPE } from '../constants/exchanges';
import { AbstractStore } from './AbstractStore';

export default class InfoStore extends AbstractStore {
  constructor(rootStore) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  @observable exchanges = [];
  @observable indicators = [];
  @observable operators = [];
  @observable tops = [];
  @observable topbys = [];
  @observable topbydirs = [];
  @observable valueAssets = [];
  @observable timeDeltas = [];
  @observable timePeriods = [];
  @observable waitTimePeriods = [];
  @observable currencies = null;
  @observable plans = [];
  @observable baseCurrencies = [];
  @observable demoExchangesIDs = [];
  @observable demoExchanges = [];
  @observable ips = '';
  @observable waitOptions = [];

  @observable isLoading = false;
  @observable isLoaded = false;

  @observable showExchangeTutorial = true;
  @observable lastSelectedExchange = false;

  @action
  setShowExchangeTutorial(value) {
    this.showExchangeTutorial = value;
  }

  @action
  async getLists(force = false) {
    if (this.isLoading) {
      return;
    }

    try {
      this.isLoading = true;

      if (!this.isLoaded || force) {
        const resp = await http.get('/lists');

        this.isLoading = false;

        if (resp.data) {
          if (resp.data.status === 'OK') {
            stores.userSurvey.loadOnboardingOnStart(
              resp.data.surveyFilled,
              resp.data.surveyAnswers
            );

            this.exchanges = resp.data.exchanges;
            this.MAExchanges = this.exchanges
              .filter((ex) => ex.ma_periods?.length)
              .map((ex) => ex.uid);
            this.RSIExchanges = this.exchanges.filter((ex) => ex.rsi).map((ex) => ex.uid);
            this.indicators = resp.data.indicators;
            this.operators = resp.data.operators;
            this.tops = resp.data.tops;
            this.topbys = resp.data.topbys;
            this.topbydirs = resp.data.topbydirs;
            this.valueAssetsOriginal = resp.data.value_assets;
            this.valueAssets = [
              ...new Set([
                stores?.user?.user?.user?.baseCurrency,
                ...resp.data.value_assets.filter((asset) => {
                  if (resp.data.mainFiats.includes(stores?.user?.user?.user?.baseCurrency)) {
                    return !resp.data.mainFiats.includes(asset);
                  }
                  return true;
                }),
              ]),
            ];
            this.timeDeltas = resp.data.time_deltas;
            this.timePeriods = resp.data.time_trigger_periods;
            this.waitTimePeriods = resp.data.wait_time_trigger_periods;
            this.waitOptions = resp.data.wait_options;
            this.isLoaded = true;
            this.currencies = resp.data.currencies;
            this.plans = resp.data.plans;
            this.baseCurrencies = resp.data.baseCurrencies;
            this.mainFiats = resp.data.mainFiats;

            this.demoExchanges = resp.data.exchanges.filter((ex) => ex.isDemo);
            this.demoExchangesIDs = this.demoExchanges.map((ex) => ex.id);

            this.ips = resp.data.ips || '';
          } else {
            errorNotification(resp.data.message);
          }

          return resp.data.authorized;
        } else {
          throw new Error('Invalid response ' + resp.toString());
        }
      }
    } catch (error) {
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoading = false;
    }
  }

  @action
  getValueAssets() {
    this.valueAssets = [
      ...new Set([stores.user.user.user.baseCurrency, ...this.valueAssetsOriginal]),
    ];
  }

  @action
  updateLastSelectedExchange(exchangeId) {
    if (!exchangeId) {
      this.lastSelectedExchange = false;
    }

    this.lastSelectedExchange = toJS(this.exchanges.find((ex) => ex.id === exchangeId));
  }

  checkLastSelectedExchange() {
    return !!this.lastSelectedExchange;
  }

  getQuoteCoinsOnExchange(isBuy) {
    if (this.checkLastSelectedExchange()) {
      let myCoins, i, coin;
      const found = [];
      const lastSelectedExchange = toJS(this.lastSelectedExchange);
      const userBalances = toJS(stores.userInfo.balances);
      const loadedBalances = toJS(stores.userInfo.isLoadedBalances);
      const len = lastSelectedExchange.quote_coins.length;

      if (loadedBalances.indexOf(lastSelectedExchange.id) >= 0) {
        if (isBuy && userBalances && userBalances[lastSelectedExchange.id]) {
          myCoins = userBalances[lastSelectedExchange.id].data.map((wallet) => wallet.asset);
        }

        for (i = 0; i < len; i++) {
          coin = lastSelectedExchange.quote_coins[i];

          if (!(isBuy && myCoins && myCoins.indexOf(coin) < 0)) {
            found.push(coin);
          }
        }
      }

      if (found.length > 0) {
        return found;
      }
    }

    return [''];
  }

  checkOrderTypeOnExchange(type) {
    if (this.checkLastSelectedExchange()) {
      const lastSelectedExchange = toJS(this.lastSelectedExchange);
      const len = lastSelectedExchange.order_types.length;

      if (len > 0) {
        if (type && lastSelectedExchange.order_types.indexOf(type) >= 0) {
          return type;
        } else {
          return lastSelectedExchange.order_types[0];
        }
      }
    }

    return ORDER_TYPES.MARKET;
  }

  @action
  setCurrenciesData(newCurrencies) {
    this.currencies = newCurrencies;

    for (const i in stores.userInfo.exchanges) {
      const exchangeId = stores.userInfo.exchanges[i].id;
      const exchangeType = stores.exchangeInfo.exchangeType[exchangeId];

      if (exchangeId && stores.userInfo.balances[exchangeId] !== undefined) {
        // sortAndCalculatePriceWallets(
        //   stores.userInfo.balances[exchangeId].data,
        //   exchangeType === EXCHANGES_TYPE.LEVERAGE
        //     ? stores.exchangeInfo.instruments[exchangeId]
        //     : stores.exchangeInfo.assets[exchangeId],
        //   exchangeId
        // );

        const results = sortAndCalculatePriceWallets({
          balances: stores.userInfo.balances[exchangeId]?.data,
          assets:exchangeType === EXCHANGES_TYPE.LEVERAGE ?  stores.exchangeInfo.instruments[exchangeId] : stores.exchangeInfo.assets[exchangeId],
          exchangeId: exchangeId,
          currencies: stores.info.currencies,
          baseCurrency: stores.user.user.user.baseCurrency,
          tempCurrency: stores.addRule.tempCurrency
        });

        if(results) {
          const {balances, totalBalance} = results;
          stores.userInfo.setTotalBalance(exchangeId, totalBalance);
          stores.userInfo.setBalances(exchangeId, balances);
        }
      }
    }
  }

  @action
  reset() {
    this.isLoading = false;
    this.isLoaded = false;
  }
}
