import React from 'react'

import type { StackProps } from '@mui/material'

import { useTheme, useMediaQuery, Stack, Typography } from '@mui/material'

import { Center, OverlayParent, Overlay, Spacer, IconMessage ,IconEyeOff, IconHeader, IconListWithBullets } from 'design-system'

export interface RuleDetailSequencesRestrictedProps extends StackProps {}

const testId = 'RuleDetailSequencesRestricted'
export const RuleDetailSequencesRestricted = (props: RuleDetailSequencesRestrictedProps) => {
  const {
    sx,
    ...otherProps
  } = props

  const theme = useTheme()

  const isLgAndUp = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <Stack
      data-testid={testId}
      className={testId}
      sx={{
        ...sx
      }}
      {...otherProps}
    >
      <IconHeader
        icon={<IconListWithBullets />}
      >
        Sequences
      </IconHeader>
      <Spacer y={20} />
      <Stack
        direction={'row'}
      >
        <Spacer x={isLgAndUp ? 43: 28} />
        <OverlayParent>
          <Overlay opacity={0.85}>
            <Center>
              <IconMessage
                icon={<IconEyeOff />}
                message={'Restricted View'}
                subMessage={'This user has hidden the rule logic'}
              />
              <Spacer y={40} />
            </Center>
          </Overlay>
          <Typography
            sx={{ filter: 'blur(2px)', padding: '0px 0px 50px 5px' }}
          >
            {/* THIS IS MOCK DATA TO BE CSS BLURRED */}
            <Typography
              component={'p'}
            >
              <Typography
                component={'span'}
                color={theme.palette.coinrule.rule.condition}
              >
                IF
              </Typography> <b>any coin</b> has <b>volume increase by 0.0001 %</b> from current level
            </Typography>
            <Typography component={'p'}>
              <Typography
                component={'span'}
                color={theme.palette.coinrule.rule.action}
              >
                BUY
              </Typography> <b>20 USD</b> of that coin with my <b>BNB</b> wallet as <b>limit order</b>
            </Typography>
            <Typography component={'p'}>
              <Typography
                component={'span'}
                color={theme.palette.coinrule.rule.execution}
              >
                EXECUTE
              </Typography> Run <b>30</b> times, start <b>on 4.4.2022 13:26</b>
            </Typography>
          </Typography>
        </OverlayParent>
      </Stack>
    </Stack>
  )
}

export default RuleDetailSequencesRestricted
