import React from 'react'

export interface ImageRuleStatusesSpriteProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'ImageRuleStatusesSprite'
export const ImageRuleStatusesSprite = (props: ImageRuleStatusesSpriteProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      xmlns={"http://www.w3.org/2000/svg"}
      x={0}
      y={0}
      viewBox={"0 0 252 42"}
      xmlSpace={"preserve"}
      {...otherProps}
    >
      <style>{".st3{fill:#787878}.st5{fill:#de3636}"}</style>
      <path
        d={"M31.8 15.7v-4.3h-3.3v1.1c0 .6-.5 1.1-1.1 1.1-.6 0-1.1-.5-1.1-1.1v-1.1H15.5v1.1c0 .6-.5 1.1-1.1 1.1-.6 0-1.1-.5-1.1-1.1v-1.1h-3.2v4.3h21.7zm0 2.2H10.2v11.9h21.7c-.1 0-.1-6.6-.1-11.9zm-3.2-8.7h3.2c1.2 0 2.2 1 2.2 2.2v18.4c0 1.2-1 2.2-2.2 2.2H10.2C9 32 8 31 8 29.8V11.4c0-1.2 1-2.2 2.2-2.2h3.2V7.1c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1v2.2h10.8V7.1c0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1v2.1z"}
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#4d5aff",
        }}
      />
      <path
        d={"M56.1 8.8h.2c.9 0 1.6.7 1.6 1.6v20.3c0 .9-.7 1.6-1.6 1.6h-.2c-.9 0-1.6-.7-1.6-1.6V10.4c0-.8.7-1.6 1.6-1.6zm14.1 0h.2c.9 0 1.6.7 1.6 1.6v20.3c0 .9-.7 1.6-1.6 1.6h-.2c-.9 0-1.6-.7-1.6-1.6V10.4c0-.8.7-1.6 1.6-1.6z"}
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#787878",
        }}
      />
      <path
        d={"M102.1 30.8c-.4 0-.7-.1-1-.3L93.6 24c-.7-.6-.8-1.5-.3-2.2.3-.4.8-.6 1.3-.6.4 0 .7.1 1 .3l6.3 5.6.6-.6c2.7-2.8 3.2-3.4 5.9-6.2 3.3-3.4 4.3-4.7 7.8-8.1.3-.3.7-.5 1.1-.5h.1c.4 0 .8.2 1.2.5.6.6.6 1.7-.1 2.2-3.4 3.4-4.5 4.6-7.6 7.9-3.2 3.4-4.3 4.6-7.7 8-.3.3-.7.5-1.1.5z"}
        style={{
          fill: "#14bd62",
        }}
      />
      <path
        className={"st3"}
        d={"M150.1 19.8h-6.9c-.2 0-.5.2-.7.3-.2.2-.3.5-.3.7 0 .5.5 1 1 1h6.8c.5 0 1-.5 1-1 .1-.5-.3-1-.9-1z"}
      />
      <path
        className={"st3"}
        d={"M159.8 11h-26.2c-.5.1-.9.5-.9 1v5c0 .5.5 1 1 1h.8v13.9c0 .5.5 1 1 1h22.4c.5 0 1-.5 1-1V18h.8c.5 0 1-.5 1-1v-5c.1-.5-.4-1-.9-1zm-23.3 19.9V18h20.4v12.9h-20.4zm22.2-17.8V16h-24.1v-2.9h24.1z"}
      />
      <path
        d={"M240.9 31.8h-1.5v-4.5c0-2.6-1.5-5.1-3.9-6.4 2.4-1.3 3.9-3.7 3.9-6.4V10h1.5c.6 0 1.1-.5 1.1-1s-.6-1.1-1.1-1.1H222c-.6 0-1 .5-1 1s.5 1.1 1 1.1h1.5v4.5c0 2.6 1.5 5.1 3.9 6.4-2.4 1.3-3.9 3.7-3.9 6.4v4.5H222c-.6 0-1 .5-1 1.1s.5 1.1 1 1.1h18.8c.6 0 1.1-.5 1.1-1.1s-.5-1.1-1-1.1zm-15.2-21.7h11.4v4.5c-.1 2.9-2.5 5.4-5.6 5.4h-.2c-3 0-5.6-2.4-5.7-5.4l.1-4.5zm5.7 11.9h.2c3 0 5.6 2.4 5.7 5.4v4.5h-11.5v-4.5c0-3 2.5-5.4 5.6-5.4z"}
        style={{
          fill: "#4d5aff",
        }}
      />
      <path
        className={"st5"}
        d={"M178.8 30.7c-1.7 0-3.2-1.4-3.2-3.2 0-.5.1-1.2.5-1.7L187 7.6c.5-.9 1.6-1.6 2.8-1.6h.3c1.1 0 2.1.7 2.8 1.6l10.9 18.3c.9 1.4.4 3.4-1.1 4.3-.5.3-1.1.5-1.7.5h-22.2zm10.9-22.3c-.3 0-.7.1-.8.4L178 27c-.1.1-.1.3-.1.5 0 .5.4.9.9.9H201c.5 0 .9-.4.9-.9 0-.3 0-.4-.1-.5l-11-18.3c-.1-.3-.4-.4-.8-.4h-.3z"}
      />
      <path
        className={"st5"}
        d={"M189.8 22.8c-.7 0-1.2-.5-1.2-1.1v-6.6c0-.7.5-1.2 1.2-1.2s1.2.5 1.2 1.2v6.6c0 .6-.5 1.1-1.2 1.1zM189.8 26.6c.6 0 1.1-.5 1.1-1.1 0-.6-.5-1.1-1.1-1.1-.6 0-1.1.5-1.1 1.1.1.7.6 1.1 1.1 1.1z"}
      />
    </svg>
  )
}

export default ImageRuleStatusesSprite
