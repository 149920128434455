import type { DTOSharedRule } from "v2/domains/accessSharedRule"

// @TODO this client may be different in real not mocked implementation
import type { Api } from "__generated__/backend.api.client/Api"

import type { TDataProviderDate } from "../date/createDataProviderDate"
import type { TModelRuleActivityLogs, TModelSharedRule } from "./externalDataModels"

import { converterAccessSharedRule } from "./converterAccessSharedRule"

export type TDataProviderAccessSharedRule = ReturnType<typeof createDataProviderAccessSharedRule>;

export const createDataProviderAccessSharedRule = <SecurityDataType extends unknown = unknown>({ httpClient, dataProviderDate }: { httpClient: Api<SecurityDataType>, dataProviderDate: TDataProviderDate }) => {
  return {
    getSharedRule: async ({ ruleSlug, offset = 0, showOnlyTrades = false, limit = 10 }: { ruleSlug: string, showOnlyTrades?: boolean, offset?: number, limit?: number }): Promise<DTOSharedRule> => {
      const currentDate = await dataProviderDate.getCurrentDate()

      const responseRuleDetails = await httpClient.view.getSharedRuleBySlug(ruleSlug);
      const sharedRule = (responseRuleDetails.data as unknown as { data: TModelSharedRule }).data

      // when showOnlyTrades is passed as false, it would still show only trades
      const responseActivityLog = await httpClient.view.getActivitiesBySlug(ruleSlug, { offset, limit, ...(showOnlyTrades && { trades: showOnlyTrades }) });
      const ruleActivityLogs = (responseActivityLog.data as unknown as { data: TModelRuleActivityLogs }).data

      const dtoShareRule = converterAccessSharedRule.toDTO({ 
        sharedRule, 
        currentDate,
        ruleActivityLogs,
      })
      
      return dtoShareRule
    }
  }
}