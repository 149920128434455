import React from 'react'

import type { LinkProps as MUILinkProps } from '@mui/material'

import { useTheme, Link as MUILink } from '@mui/material'

import type { CoinruleColorPalette } from 'design-system'

export interface LinkProps extends MUILinkProps {
  colorVariant?: keyof CoinruleColorPalette['brand']
  textDecoration?: React.CSSProperties['textDecoration']
}

const testId = 'Link'
export const Link = (props: LinkProps) => {
  const {
    sx,
    colorVariant,
    textDecoration = 'underline',
    ...otherProps
  } = props

  const theme = useTheme()

  const color = colorVariant ? theme.palette.coinrule.brand[colorVariant] : 'inherit'

  return (
    <MUILink
      data-testid={testId}
      className={testId}
      sx={{
        cursor: 'pointer',
        color: color,
        textDecorationColor: color,
        textDecoration,
        ...theme.typography,
        ...sx
      }}
      {...otherProps}
    />
  )
}

export default Link
