import React, { Component } from 'react';
import { Tooltip } from 'antd';

import './style.scss';
import qmIcon from '../../assets/images/tooltip-qm.svg';

const Help = ({ message, imgSrc = qmIcon, extraCls = '', ...otherProps }) => {
  return (
    <div className='question-tooltip'>
      <Tooltip
        placement='rightTop'
        overlayClassName={`tooltip-light ${extraCls}`}
        title={message}
        {...otherProps}>
        <img src={imgSrc} alt='help' />
      </Tooltip>
    </div>
  );
};

export class Container extends Component {
  render() {
    return <div className='tooltip-cnt'>{this.props.children}</div>;
  }
}

export default Help;
