import { observable, action } from 'mobx';
import { showTour } from '../helpers/tour';
import stores from '../stores';
import { AbstractStore } from './AbstractStore';

export default class TourGuide extends AbstractStore {
  constructor(rootStore) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  @observable isRuleTourShowed = true;
  @observable isVolume100reachedTourShowed = true;
  @observable isAddRuleTourShowed = false;
  @observable forceShow = false;

  /***
   * index of the tour slide, In a case where we need to skip a tour
   * eg: skipping the first slide of welcome tour when user has a live rule;
   *  */
  @observable welcomeTourSlideToSkip;

  @observable isWelcomeTourShowed = true;

  // Needed to force Tour component re-render
  @observable showTourIndex = 1;

  @action
  setWelcomeTourSlideToSkip = (index) => (this.welcomeTourSlideToSkip = index);

  @action
  toggleRuleTour = (whatToDo, forceShow) => {
    if (whatToDo === 'show') {
      this.isRuleTourShowed = true;
      if (forceShow) this.forceShow = true;
      if (forceShow) this.isAddRuleTourShowed = true;
      showTour('addRule');
      stores.user.addRuleTour();
      this.showTourIndex += 1;
    } else if (whatToDo === 'close') {
      this.isRuleTourShowed = false;
      this.isAddRuleTourShowed = false;
      this.isVolume100reachedTourShowed = false;
      this.forceShow = false;
      this.isVolume100reachedTourShowed = false;
    }
  };

  @action
  setForceShow = (forceShow) => {
    this.forceShow = forceShow;
  };

  @action
  toggleIsWelcomeTourShowed = (whatToDo) => {
    if (whatToDo === 'show') {
      this.isWelcomeTourShowed = true;
    } else if (whatToDo === 'close') {
      this.isWelcomeTourShowed = false;
    }
  };
}
