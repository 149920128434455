import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Input, DatePicker, Modal, Select } from 'antd';
import { getFieldCssClass, setDataLocalStorage } from '../../../helpers/utils';
import stores from '../../../stores/index';
import './style.scss';
import { errorNotification } from '../../../helpers/notification';

import Popover from '../../../components/Popover';

import PaymentGate from '../../../components/PaymentGate';
import dbg from '../../../helpers/dbg';
import AddButtonsBlock from '../AddButtonsBlock';
import {
  DEFAULT_TIME_TRIGGER_PERIOD,
  TRIGGERS,
  ACTION_TYPE,
  OPERATOR_TYPE,
  OPERATOR_TYPES,
  CONDITION_TYPE,
  CONDITION_TYPES,
  NOTIFY_TYPE,
} from '../../../stores/addRule';
import DetailSequenceTexts from '../../../components/Rule/DetailTextSequence';
import { EXCHANGES_TYPE } from '../../../constants/exchanges';
import { CreateTemplateButton } from './CreateTemplateButton';
import { CoinruleButton } from '../../../components/CoinruleButton';
import { withRouter } from 'react-router-dom';
import { ADVANCED_ONBOARDING_TEMPLATE_PAYWALL_NAME } from 'pages/RulesDashboardPage/Onboarding/utils';

const analytics = window.mixpanel;

const Option = Select.Option;

export const LAUNCH_DEMO_TEXT = 'Launch Demo';
export const LAUNCH_TEXT = 'Launch';
export const SAVE = 'Save Changes';
export const ADD_EXCHANGE_LAUNCH_TEXT = 'Add Exchange & Launch';

@observer
class Launch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visiblePreview: false,
      finalLaunchModalWidth: '100%',
      time: DEFAULT_TIME_TRIGGER_PERIOD,
    };
  }

  componentDidMount() {
    this.handleChangeRepetition(this.props.store.rule.rpt.t);
  }

  changeTimeSelect(value) {
    this.props.store.rule.rpt.nmt = value;
  }

  showPreview = () => {
    this.props.store.setShowRuleLaunchPreview(true);
  };

  hidePreviewModal = () => {
    this.props.store.setShowRuleLaunchPreview(false);
  };

  isLeverage() {
    const exchangeType = stores.exchangeInfo.exchangeType[this.props.store.rule.ex];
    return exchangeType && exchangeType === EXCHANGES_TYPE.LEVERAGE;
  }

  checkIfExchangeLoaded() {
    return (
      this.props.store.rule.ex &&
      stores.userInfo.balances[this.props.store.rule.ex] &&
      stores.exchangeInfo.assets[this.props.store.rule.ex] &&
      ((this.isLeverage() && stores.exchangeInfo.instruments[this.props.store.rule.ex]) ||
        stores.exchangeInfo.markets[this.props.store.rule.ex])
    );
  }

  handleSubmit = async (event, showPaywall) => {
    this.props.store.seqInit.validationFields();

    this.props.setConnectExchangeClicked(false);

    event.preventDefault();

    let userError;

    stores.exchangeProfile.handleShouldLaunchLiveOnAddExchange(this.props.store);

    try {
      const lastElement = this.props.store.seqInit.getLastElement();

      if (!lastElement || [ACTION_TYPE, OPERATOR_TYPE, NOTIFY_TYPE].indexOf(lastElement.type) === -1) {
        userError = 'Rule is not complete. Rules have to end with an Action or "Do Not" Operator';
        throw new Error('Validation error: ' + userError);
      } else if (lastElement.type === OPERATOR_TYPE) {
        if ([OPERATOR_TYPES.DO_NOT].indexOf(lastElement.data.operators[0].t) === -1) {
          userError = 'Rule is not complete. Rules have to end with an Action or "Do Not" Operator';
          throw new Error('Validation error: ' + userError);
        }
      }

      if (this.props.store.seqInit.hasErrorValidation) {
        userError = 'Your rule includes errors. Please check.';
        throw new Error('Validation error: ' + userError);
      }

      if ((userError = this.props.store.validateRule()) !== null) {
        throw new Error('Validation error: ' + userError);
      }

      // HOTFIX #1443
      const sequences = this.props.store.seqInit.sequences;
      if (sequences) {
        for (let i in sequences) {
          let s = sequences[i];
          if (
            s.data?.conditions?.length &&
            s.data.conditions[0].sr === null &&
            (s.data.conditions[0].wi === 'action_price' || s.data.conditions[0].wi === 'trailing')
          ) {
            s.data.conditions[0].wi = 'current_price';
          }
        }
      }

      if (showPaywall) {
        this.props.store.backup();

        setDataLocalStorage('should_restore_rule', true);
        stores.addRule.setPaymentGateVisible({ type:'promo', isVisible:true });
      } else {
        this.showPreview();
      }
    } catch (err) {
      dbg('Error', err);
      errorNotification(userError || `Something has gone wrong! Please try to reload the page.`);
    }

    analytics.track('"Launch Rule" dialog open');
  };

  isQuickEdit = () => {
    return this.props.store.rule.edit_mode === 'edit';
  };

  getStartButtonText = () => {
    const { exchanges } = stores.userInfo;
    const { fromOnboarding } = this.props.location.state || {};

    if (this.isQuickEdit()) {
      return 'Update';
    }

    if ((exchanges?.length && exchanges?.length > 1) || fromOnboarding) {
      return LAUNCH_TEXT;
    }

    return LAUNCH_DEMO_TEXT;
  };

  renderStartButton() {
    const user = stores.user.getUser();

    const canCreateDemoRule = !(stores.userInfo.activeDemoRules >= user.plan.maxDemoRules);
    const canCreateLiveRule = !(stores.userInfo.activeLiveRules >= user.plan.maxRules);
    const isDemoExchange = stores.info.demoExchangesIDs.includes(this.props.store.rule.ex);

    const { sq } = this.props.store.getRuleData() || {};
    const { fromOnboarding } = this.props.location.state || {};

    const isUsingTradingViewSignal =
      sq?.[0]?.t === CONDITION_TYPE &&
      sq?.[0]?.d.ifc === CONDITION_TYPES.TRADINGVIEW_SIGNAL &&
      sq?.[0]?.d.ift === 'signal';

    const reachedLimit =
      (((!canCreateLiveRule && !isDemoExchange) || (!canCreateDemoRule && isDemoExchange)) &&
        (!(this.props.store.editMode && this.props.store.editedRuleId) ||
          stores.userRules.lastOpenRuleStatus === 'draft')) ||
      stores.user.user.plan.uid === 'paused' ||
      (isUsingTradingViewSignal && stores.user.user.plan.clearance < 2 && !isDemoExchange);

    const isDisabled = !this.checkIfExchangeLoaded();

    return (
      <>
        <Popover
          text={
            reachedLimit
              ? `You have reached your ${isDemoExchange ? 'demo' : ''} rules limit`
              : !this.checkIfExchangeLoaded()
              ? 'You must select an exchange'
              : this.isQuickEdit()
              ? 'Save Changes'
              : 'Start Rule'
          }>
          <CoinruleButton
            component='button'
            size='big'
            color={fromOnboarding ? 'violet' : 'crimson'}
            icon='CaretRight'
            disabled={isDisabled}
            className='launch-button'
            onClick={(event) => this.handleSubmit(event, reachedLimit)}>
            {this.getStartButtonText()}
          </CoinruleButton>
        </Popover>

        {this.renderPreviewModal()}
        {this.renderPlanBanner()}
      </>
    );
  }

  handleChangeRepetition(newValue) {
    this.props.store.setRepeatNoMore(newValue);
  }

  handleChangeRepetiCount(newValue) {
    this.props.store.rule.rpt.l = newValue;
  }

  handleChangeRepetiType(newValue) {
    this.props.store.rule.rpt.nmt = newValue;
  }

  setFinalLaunchModalWidth = (event) => {
    const winWidth = event.target.innerWidth;

    if (winWidth >= 1280 && this.state.finalLaunchModalWidth !== '45%') {
      this.setState(() => {
        return { finalLaunchModalWidth: '45%' };
      });
    } else if (winWidth < 1280 && this.state.finalLaunchModalWidth !== '75%') {
      this.setState(() => {
        return { finalLaunchModalWidth: '75%' };
      });
    }
  };

  handleLauchButtonClick = (event, shouldLaunchLiveOnAddExchange, exchangeIdForLaunch) => {
    if (shouldLaunchLiveOnAddExchange) {
      stores.exchangeProfile.toggleShowAddExchangeModal(true);
      stores.payment.setAddRuleStoreInstance(this.props.store)
      this.hidePreviewModal();
    } else {
      this.props.handleLaunchLive({ event, callback: this.hidePreviewModal, exchangeIdForLaunch });
    }

    if (stores.user.volumeLimitUsed >= 100) {
      setTimeout(() => {
        stores.addRule.setPaymentGateVisible({ type: 'trading_volume_100', isVisible: true, forceShow: true });
      }, 3500);
    }
  };

  renderLaunchButtons = () => {
    const {
      exchangeProfile: { shouldLaunchLiveOnAddExchange },
      userInfo: { exchanges },
    } = stores;

    const demoExchange = exchanges?.length && exchanges.find((exchange) => exchange.uid === 'demo');

    let buttonTexts = [];

    if (exchanges?.length === 1 || this.props.store.rule.ex === demoExchange?.id) {
      buttonTexts.unshift(LAUNCH_DEMO_TEXT);
    }

    if (
      shouldLaunchLiveOnAddExchange ||
      (exchanges?.length > 1 && this.props.store.rule.ex !== demoExchange?.id)
    ) {
      buttonTexts.push(LAUNCH_TEXT);
    }

    return (
      <div className={`previewRuleModal-launch-buttons`}>
        {buttonTexts.map((text) => (
          <div className='launch-live' key={text}>
            <Popover text={this.isQuickEdit() ? SAVE : text}>
              <CoinruleButton
                component='button'
                size='big'
                icon={'CaretRight'}
                color={text === LAUNCH_DEMO_TEXT ? 'violet' : 'crimson'}
                disabled={!this.checkIfExchangeLoaded()}
                loading={this.props.store.isSending}
                className='launch-button'
                onClick={(event) =>
                  this.handleLauchButtonClick(
                    event,
                    text === LAUNCH_TEXT && shouldLaunchLiveOnAddExchange,
                    text === LAUNCH_DEMO_TEXT && demoExchange?.id
                  )
                }>
                {this.isQuickEdit() ? SAVE : text}
              </CoinruleButton>
            </Popover>
          </div>
        ))}
      </div>
    );
  };

  stopLoading = () => {
    stores.ruleDetailsStore.isTemplateApplyingPending = false;
    this.props.store.setIsSending(false)
    this.props.store.setIsLoading(false)
  }

  closeAdvancedTemplatePaywall = () => {
    stores.addRule.showPaymentGateForAdvancedOnboardingTemplate = false;
    this.stopLoading()
  }

  renderPreviewModal() {
    return (
      <React.Fragment>
        <PaymentGate
          stores={stores}
          type='promo'
          permanent={false}
          visible={stores.addRule.paymentGatePromoVisible}
          onCancel={this.stopLoading}
        />

        <PaymentGate
          stores={stores}
          type={ADVANCED_ONBOARDING_TEMPLATE_PAYWALL_NAME}
          permanent={false}
          visible={stores.addRule.showPaymentGateForAdvancedOnboardingTemplate}
          onCancel={this.closeAdvancedTemplatePaywall}
          {
            ...(stores.payment.coupon && {
              title:'Start Free Trial',
              subtitle:(
                <>
                  <span>Run this Advanced Rule for Free for 7 days</span>
                  <br />
                  <span className='subtitle--light'>or try a simpler <span className='subtitle__action' onClick={this.closeAdvancedTemplatePaywall}>template</span> on the free plan</span>
                </>
              )
            })
          }
        />

        <Modal
          centered
          title={`Well Done!`}
          visible={this.props.store.showRuleLaunchPreview}
          footer={null}
          width={this.state.finalLaunchModalWidth}
          onCancel={() => {
            setDataLocalStorage('should_restore_rule', false);
            this.hidePreviewModal();
          }}
          className='previewRuleModal'>
          <div>
            <div className='sub-title'>One Last Read Before Launching</div>
            <div className='ruleContent'>
              <div className='title-area'>
                <div className='label'>Name</div>
                <h3>{this.props.store.rule.nam}</h3>
              </div>
              <div className='text'>
                <div className='label'>Rule</div>
                <DetailSequenceTexts
                  show={this.props.store.showRuleLaunchPreview}
                  type={'base'}
                  store={this.props.store}
                  showTriggerText={true}
                  preLaunchReviewModal={true}
                />
              </div>
            </div>
          </div>
          <div
            className='footer-buttons previewRuleModal-footer-buttons'
            style={{
              justifyContent:
                stores.exchangeProfile.shouldLaunchLiveOnAddExchange || this.isQuickEdit()
                  ? 'flex-end'
                  : 'space-between',
            }}>
            {!stores.exchangeProfile.shouldLaunchLiveOnAddExchange && this.renderDraftButton()}
            {this.renderLaunchButtons()}
          </div>
        </Modal>
      </React.Fragment>
    );
  }

  renderPlanBanner() {
    return (
      <React.Fragment>
        <PaymentGate
          stores={stores}
          store={this.props.store}
          type='execution'
          permanent={false}
          visible={this.props.store.showPlansBanner}
        />
      </React.Fragment>
    );
  }

  renderCreateTemplateButton() {
    if (stores.user.isAdmin || stores.user.isSupport) {
      return <CreateTemplateButton />;
    }
  }

  renderDraftButton() {
    if (this.isQuickEdit()) {
      return null;
    }

    const handleSaveDraft = (event) => {
      event.preventDefault();
      this.hidePreviewModal();
      this.props.store
        .createRule(this.props.unblockBlockedPage, true)
        .then((authorized) => stores.app.unauthorized(authorized));
    };

    return (
      <Popover text='Save Rule as Draft'>
        <CoinruleButton
          component='button'
          size='big'
          color='gray'
          fillType='outline'
          icon='Edit'
          loading={this.props.store.isSending}
          className='save-button'
          onClick={(event) => handleSaveDraft(event)}>
          Save Draft
        </CoinruleButton>
      </Popover>
    );
  }

  renderRepetitionArea() {
    if (this.props.store.rule.rpt.l <= 1 && this.props.store.rule.tim !== 'now') {
      return null;
    }

    if (this.props.store.rule.tim === 'on') {
      return null;
    }

    const isNotMore = this.props.store.rule.rpt.t === 'no_more_than_once';
    // Do not show "in total" when ANY TIME operator is used: https://gitlab.com/coinrule-v2/project-board/-/issues/1791
    const isNotAfterAnyTime = !(
      this.props.store.seqInit.typesCounting.operators.types.parallel > 0
    );

    return (
      <React.Fragment>
        <div className='repetitions select-grey martphoneView65width'>
          <Popover text='Select Frequency'>
            <Select
              size={'default'}
              style={{ minWidth: 150 }}
              value={this.props.store.rule.rpt.t}
              onChange={this.handleChangeRepetition.bind(this)}
              className={`${getFieldCssClass(this.props.store.rule.rpt.t)}`}>
              {isNotAfterAnyTime ? <Option value='total'>in total</Option> : null}
              <Option value='no_more_than_once'>but not more than</Option>
            </Select>
          </Popover>
        </div>

        {isNotMore ? <label className='smartphoneView30width'>once every</label> : null}

        {isNotMore ? (
          <div className='repetitions-type'>
            <div className='select-grey'>
              {this.props.store.renderPredefinedPeriodSelect(
                this.props.store.rule.rpt.nmt,
                this.changeTimeSelect.bind(this)
              )}
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }

  renderMaxOpenPositionsLimit() {
    const isNotAfterAnyTime = !(
      this.props.store.seqInit.typesCounting.operators.types.parallel > 0
    );
    const repeatLimitIsNotGreaterThan0 = this.props.store.rule.rpt.l < 2;
    const defaultValue = this.props.store.rule.mop || '';

    if (isNotAfterAnyTime || repeatLimitIsNotGreaterThan0) {
      return null;
    }

    return (
      <>
        <label className='smartphoneView30width'>with a limit of</label>

        <div className='input-grey smartphoneView65width'>
          <span className={getFieldCssClass(this.props.store.rule.rpt.l, true)}>
            <Input
              placeholder='Eg. 10'
              value={defaultValue}
              onChange={(e) => this.props.store.setMaxOpenPositions(e.target.value)}
              style={{ width: 80 }}
            />
          </span>
        </div>

        <label className='smartphoneView30width'>open positions at the same time</label>
      </>
    );
  }

  renderTimeSelectArea() {
    if (this.props.store.rule.tr === TRIGGERS.TIME) {
      //|| this.props.store.rule.tr === TRIGGERS.DIRECT_ORDER
      return null;
    }

    return (
      <React.Fragment>
        {this.props.store.rule.sd ? (
          <label className='smartphoneView30width'>Start on</label>
        ) : (
          <label className='smartphoneView30width'>Start</label>
        )}

        <div className='data-picker-grey smartphoneView65width'>
          <DatePicker
            showTime
            disabled={this.props.store.isQuickEdit()}
            format='DD/MM/YYYY HH:mm'
            placeholder='immediately'
            showToday={false}
            value={this.props.store.rule.sd}
            onChange={(value) => this.props.store.setStartDate(value)}
            // className={getFieldCssClass(this.props.store.rule.sd, false)}
            style={{ width: 188 }}
            disabledDate={(current) => {
              return current && current < moment().subtract(1, 'days');
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  renderTimeFrameArea() {
    if (this.props.store.rule.tr === TRIGGERS.DIRECT_ORDER) {
      //(this.props.store.rule.tr === TRIGGERS.TIME && (this.props.store.rule.tim === 'now')) ||
      return null;
    }

    let defaultValue = this.props.store.rule.rpt.l || '';
    let tooltipText = '# of Executions';

    if (this.props.store.rule.tim === 'on' && this.props.store.rule.tr === TRIGGERS.TIME) {
      defaultValue = 1;
      tooltipText = 'This rule will execute once';
    }

    return (
      <div className='rule-settings-block'>
        <div className='rsb-line rsb-line_crimson'>&nbsp;</div>
        <div className='rule-settings'>
          <Popover text={tooltipText}>
            <div className='title-wrapper tw1'>
              <div className='part-title title-crimson'>Execute</div>
            </div>
          </Popover>

          <div className='settings'>
            <div className='content'>
              {this.renderTimeSelectArea()}

              {this.props.store.rule.tr === TRIGGERS.TIME ? (
                <label className='smartphoneView30width'>Execute </label>
              ) : (
                <label className='smartphoneView30width'>and execute </label>
              )}

              <div className='input-grey smartphoneView65width'>
                <span className={getFieldCssClass(this.props.store.rule.rpt.l, true)}>
                  <Input
                    placeholder='Eg. 3'
                    value={defaultValue}
                    type='number'
                    inputMode="numeric"
                    onChange={(e) => this.props.store.setRepeats(e.target.value)}
                    style={{ width: 80 }}
                    disabled={
                      this.props.store.rule.tim === 'on' &&
                      this.props.store.rule.tr === TRIGGERS.TIME
                    }
                  />
                </span>
              </div>

              {this.props.store.rule.tim !== 'on' ? (
                <label className='smartphoneView30width'>times</label>
              ) : null}

              {this.renderRepetitionArea()}
              {this.renderMaxOpenPositionsLimit()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { store } = this.props;

    return (
      <div
        className={`launch
												${!this.props.store.rule.ex && 'show-section_unavailable'}`}
        data-sequence={9999}
        id={`sequence${9999}`}
        onClick={() => {
          stores.addRule.setSFPpendingSection(9999);
          this.props.store.changeIsLaunchSectionWasClickedStatus();
        }}>
        {this.renderTimeFrameArea()}

        {this.props.store.rule.tr !== TRIGGERS.DIRECT_ORDER ? (
          <AddButtonsBlock type='icon-play' store={this.props.store} />
        ) : null}

        <div className='rule-settings-block'>
          <div
            className='rsb-line
                          rsb-line_crimson'>
            &nbsp;
          </div>
          <div className='rule-settings final-launch'>
            <Popover text={this.isQuickEdit() ? 'Save Changes' : 'Start Rule'}>
              <div className='title-wrapper tw2'>
                <div className='part-title title-crimson'>Go</div>
              </div>
            </Popover>

            <div className='settings'>
              <div className='content final-content'>
                <div className='input-grey'>
                  <label>Now name this rule</label>
                  <span
                    className={`${getFieldCssClass(store.rule.nam, true)}
                                    ruleNameInput`}>
                    <Input
                      placeholder='Eg. Divide and conquer'
                      value={store.rule.nam}
                      onChange={(e) => store.setRuleName(e.target.value)}
                      maxLength={60}
                    />
                  </span>
                </div>

                <div className={`rule-buttons`}>
                  {this.renderDraftButton()}
                  {this.renderCreateTemplateButton()}
                  {this.renderStartButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Launch);
