import { INTERCOM_APP_ID } from 'helpers/constants';
import { errorNotification } from 'helpers/notification';
import { observer, Provider } from 'mobx-react';
import moment from 'moment';
import SharedRulePage from 'pages/ShareRulePage/SharedRulePage';
import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import GeneralRoute from '../../components/GeneralRoute';
import PrivateRoute from '../../components/PrivateRoute';
import PublicRoute from '../../components/PublicRoute';
import history from '../../helpers/history';
import {
  getDataLocalStorage,
  handleIntercomVisibility,
  setDataLocalStorage,
  setIntercomVisibility,
} from '../../helpers/utils';
import stores from '../../stores';
import FacebookCallback from '../AuthPage/FacebookCallback';
import GoogleCallback from '../AuthPage/GoogleCallback';
import './style.scss';

const AuthPage = React.lazy(() => import('../AuthPage'));
const RulesDashboardPage = React.lazy(() => import('../RulesDashboardPage'));
const SettingsPage = React.lazy(() => import('../SettingsPage'));
const ExchangesPage = React.lazy(() => import('../ExchangesPage'));
const AdminPage = React.lazy(() => import('../AdminPage'));
const UserDetailPage = React.lazy(() => import('../AdminPage/Users/UserDetail'));
const AddRulePage = React.lazy(() => import('../AddRulePage'));
const DetailRulePage = React.lazy(() => import('../DetailRulePage'));
const UnsubscribePage = React.lazy(() => import('../UnsubscribePage'));
const BillingUnsubscribePage = React.lazy(() => import('../SettingsPage/Billing/UnsubscribePage'));
const BillingDowngradePage = React.lazy(() => import('../SettingsPage/Billing/DowngradePage'));
const CouponPage = React.lazy(() => import('../CouponPage'));
const BinanceOAuthCallbackPage = React.lazy(() => import('../BinanceOAuthCallbackPage'));

@observer
class App extends Component {
  state = {
    isAuthorized: false,
    interval: null,
    isMobile: window.innerWidth <= 991,
  };

  async componentDidMount() {

    if (window.location.hash.substring(0, 2) === '#/') {
      history.push(window.location.hash.substring(1));
    } else {
      try {
        stores.app.setApp(this);
        this.setState({
          isAuthorized: await stores.user.checkUser(false),
        });
      } catch (error) {
        errorNotification(error.message);
      }

      const directLink = getDataLocalStorage('directLink');

      if (directLink !== '/') {
        setDataLocalStorage('directLink', '/');

        // Redirect only when is editing rule
        if (this.props.match.path === '/rule/:id/edit') history.push(directLink);
      }
    }

    this.showIntercom();

    if (!this.state.interval) {
      this.state.interval = setInterval(() => {
        if (stores.user.user) {
          const expDate = moment.unix(stores.user.user.exp);

          if (expDate.diff(moment(), 'minutes') < 10) {
            stores.user.checkUser(true, true);
          }
        }
      }, 1000 * 60 * 3); // every 3 minutes
    }

    /**
    * Note: DO NOT DELETE. Code will be revisited in near future.
    * 
    * 
    this.unlisten = this.props.history.listen((location, action) => {
      try {
        /* eslint-disable no-unused-expressions
        window.rdt?.('track', 'PageVisit'); // Reddit conversion tracking
        /* eslint-enable no-unused-expressions
      } catch (err) {
        console.error('Error while performing Reddit conversion tracking');
      }
    });
    */
  }

  showIntercom = () => {
    window.Intercom('boot', {
      app_id: INTERCOM_APP_ID,
    });

    window.Intercom('onShow', () => {
      setTimeout(() => {
        setIntercomVisibility(window.location); // show/hide Intercom based on page
      }, 5000);
    });
  };

  componentWillUnmount() {
    if (this.unlisten) this.unlisten();
  }

  handleIntercomVisibilityOnMobile = () => {
    const { location } = this.props;
    const { isMobile } = this.state;

    if (isMobile && location.pathname === '/') {
      if (stores.tourGuide.isWelcomeTourShowed) {
        handleIntercomVisibility({ hideIntercom: true });
      } else {
        handleIntercomVisibility({ hideIntercom: false });
      }
    }
  };

  render() {
    this.handleIntercomVisibilityOnMobile();

    return (
      <div className='app'>
        <Provider {...stores}>
          <Switch>
            <GeneralRoute path='/unsubscribe/:hash?' component={UnsubscribePage} />
            <GeneralRoute path='/coupon/:url' component={CouponPage} />

            <PublicRoute path='/login' exact component={AuthPage} />
            <PublicRoute path='/register' exact component={AuthPage} />
            <PublicRoute path='/forgotpassword' exact component={AuthPage} />

            <PublicRoute path='/oauth/google/callback' exact component={GoogleCallback} />
            <PublicRoute path='/oauth/facebook/callback' exact component={FacebookCallback} />

            <PrivateRoute path='/exchanges' exact component={ExchangesPage} />
            <PrivateRoute path='/rule' exact component={AddRulePage} />
            <PrivateRoute path='/rule/:id' exact component={DetailRulePage} />
            <PrivateRoute path='/rule/:id/edit' exact component={AddRulePage} />
            <PrivateRoute path='/rule/:id/draft' exact component={AddRulePage} />
            <PrivateRoute path='/rule/:id/clone' exact component={AddRulePage} />
            <PrivateRoute path='/rule/:id/restart' exact component={AddRulePage} />

            <GeneralRoute path='/share-rule/:slug' exact component={SharedRulePage} />

            <PrivateRoute path='/admin/:userId?' exact component={AdminPage} />
            <PrivateRoute path='/admin/user/:id' exact component={UserDetailPage} />

            <PrivateRoute path='/oauth/binance/callback' exact component={BinanceOAuthCallbackPage} />

            <PrivateRoute path='/settings' exact component={SettingsPage} />
            <PrivateRoute path='/settings/profile' exact component={SettingsPage} />
            <PrivateRoute path='/settings/billing' exact component={SettingsPage} />
            <PrivateRoute
              path='/settings/billing/unsubscribe'
              exact
              component={BillingUnsubscribePage}
            />
            <PrivateRoute
              path='/settings/billing/downgrade/:selected_plan'
              exact
              component={BillingDowngradePage}
            />

            <PrivateRoute path='/' component={RulesDashboardPage} />
          </Switch>
        </Provider>
      </div>
    );
  }
}

export default withRouter(App);
