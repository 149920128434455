import { infoNotification, successNotification } from './notification';
import stores from '../stores';
import { EMPTY } from '../stores/addRule';
import DeviceStorage from 'react-device-storage';
import numeral from 'numeral';
import { toJS } from 'mobx';
import { Decimal } from 'decimal.js';
import moment from 'moment';

const COINS_IN_ARRAY = false;

export const ANY_COIN = '__ANY';
export const COINS_ON_HOLD = '__ONHOLD';
export const FROM_THAT_PRICE = '__THAT_PRICE';
export const FROM_LAST_TRADE_PRICE = '__LAST_TRADE_PRICE';
export const COIN_MEET_CONDS = '__CONDS';

export const TOP1 = '_T1';
export const BOTTOM1 = '_B1';

const TOP1_VAL = 't1';
const BOTTOM1_VAL = 'b1';

export const STATUSES = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  PAUSED: 'paused',
  ARCHIVED: 'archived',
  FINISHED: 'finished',
  EASY_ERROR: 'easy_error',
  FATAL_ERROR: 'fatal_error',
};

export const floorToMultiplesOf10 = (num) => {
  return Math.round(num / 10) * 10;
};

export function sortByDate(arr, property = null) {
  if (property) {
    return arr.sort(
      (item1, item2) => Number(new Date(item1[property])) - Number(new Date(item2[property]))
    );
  } else {
    return arr.sort((item1, item2) => Number(new Date(item1)) - Number(new Date(item2)));
  }
}

export function getReasonableTimeDifferenceFromNow(time) {
  const possibleUnitsOfTime = ['seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years'];
  let result = 0,
    i = 0;
  let unitOfTime;

  do {
    unitOfTime = possibleUnitsOfTime[i];
    result = moment().diff(moment(time), unitOfTime);
    i++;
  } while (result > 60);

  return [result, unitOfTime];
}

export function prepareCoinListValue(anySymbol, holdSymbols, symbols, top = '') {
  if (top) {
    if (top === BOTTOM1_VAL) {
      top = BOTTOM1;
    } else {
      top = TOP1;
    }
  }

  const len = symbols.length;

  if (holdSymbols) {
    return COINS_IN_ARRAY ? [COINS_ON_HOLD + top] : COINS_ON_HOLD + top;
  } else if (anySymbol) {
    return COINS_IN_ARRAY ? [ANY_COIN + top] : ANY_COIN + top;
  } else if (len < 1) {
    return COINS_IN_ARRAY ? [COIN_MEET_CONDS] : COIN_MEET_CONDS;
  } else if (len > 0) {
    if (symbols.indexOf(EMPTY) !== -1 && stores.templatesStore.isSelect) {
      return EMPTY;
    }
    return COINS_IN_ARRAY ? symbols : symbols[0];
  } else {
    return COINS_IN_ARRAY ? [] : '';
  }
}

export function handleCoinListChange(values, limit = 0, useTop = false) {
  let newValues = [];
  let lastValue;

  if (Array.isArray(values)) {
    if (values.length > 0) {
      lastValue = values[values.length - 1];
    } else {
      lastValue = ANY_COIN + (useTop ? TOP1 : '');
    }
  } else {
    lastValue = values;
  }

  if (lastValue === ANY_COIN || lastValue === ANY_COIN + TOP1 || lastValue === ANY_COIN + BOTTOM1) {
    return {
      any: true,
      hold: false,
      symbols: [],
      top: useTop ? (lastValue === ANY_COIN + BOTTOM1 ? BOTTOM1_VAL : TOP1_VAL) : '',
    };
  } else if (
    lastValue === COINS_ON_HOLD ||
    lastValue === COINS_ON_HOLD + TOP1 ||
    lastValue === COINS_ON_HOLD + BOTTOM1
  ) {
    return {
      any: false,
      hold: true,
      symbols: [],
      top: useTop ? (lastValue === COINS_ON_HOLD + BOTTOM1 ? BOTTOM1_VAL : TOP1_VAL) : '',
    };
  } else {
    if (COINS_IN_ARRAY) {
      values.forEach((v) => {
        if (
          v !== ANY_COIN &&
          v !== ANY_COIN + TOP1 &&
          v !== ANY_COIN + BOTTOM1 &&
          v !== COINS_ON_HOLD &&
          v !== COINS_ON_HOLD + TOP1 &&
          v !== COINS_ON_HOLD + BOTTOM1 &&
          v !== COIN_MEET_CONDS
        ) {
          if (limit && newValues.length >= limit) {
            infoNotification(`You can select maximum of ${limit} coins here.`);
          } else {
            newValues.push(v);
          }
        }
      });
    } else if (lastValue !== COIN_MEET_CONDS) {
      newValues = [lastValue];
    }

    return {
      any: false,
      hold: false,
      symbols: newValues,
      top: useTop ? TOP1_VAL : '',
    };
  }
}

export function isEmptyObject(o, exact) {
  let found = 0;
  exact = exact || 0;

  for (const i in o) {
    if (o.hasOwnProperty(i)) {
      found++;
    }
  }

  if (found === exact) {
    return false;
  }

  return true;
}

export function getIndicator(indicator) {
  const INDICATORS = {
    price: 'price',
    volume: 'volume',
    marketcap: 'marketcap',
    // ma_1: 'MA (1)',
    // ma_5: 'MA (5)',
    ma_9: 'MA (9)',
    ma_50: 'MA (50)',
    ma_100: 'MA (100)',
    ma_200: 'MA (200)',
    rsi: 'RSI',
  };

  if (INDICATORS.hasOwnProperty(indicator)) {
    return INDICATORS[indicator];
  }

  return null;
}

export function convertStringToClassName(string) {
  if (!string) {
    string = 'undefined';
  }

  if (string === 'XBT') {
    string = 'BTC';
  }

  let newPhrase = '';

  newPhrase = string
    .toLowerCase()
    .replace(/[^a-zA-Z]+/g, '')
    .split(' ')
    .map((item, index, arr) => {
      if (arr.length === 1 || index === 0) return item;
      return item[0].toUpperCase() + item.slice(1);
    })
    .join('');

  return newPhrase;
}

export function getMAIndicator(indicator) {
  const INDICATORS = {
    ma_9: 'MA (9)',
    ma_50: 'MA (50)',
    ma_100: 'MA (100)',
    ma_200: 'MA (100)',
  };

  if (INDICATORS.hasOwnProperty(indicator)) {
    return INDICATORS[indicator];
  }

  return null;
}

export function getOperator(operator) {
  const OPERATORS = {
    inc: 'increase by',
    dec: 'decrease by',
    lte: 'lower than',
    gte: 'greater than',
    above: 'crossing above',
    below: 'crossing below',
  };

  if (OPERATORS.hasOwnProperty(operator)) {
    return OPERATORS[operator];
  }

  return null;
}

export function getMAOperator(operator) {
  const OPERATORS = {
    above: 'crossing above',
    below: 'crossing below',
  };

  if (OPERATORS.hasOwnProperty(operator)) {
    return OPERATORS[operator];
  }

  return null;
}

export function getTopBy(by) {
  const TOPBYS = {
    price: 'by daily price change',
    volume: 'by daily volume',
    marketcap: 'by current marketcap',
  };

  if (TOPBYS.hasOwnProperty(by)) {
    return TOPBYS[by];
  }

  return null;
}

export function formattedCounter(i) {
  i = parseInt(i, 10);

  switch (i) {
    case 1:
      return 'first';
    case 2:
      return 'second';
    case 3:
      return 'third';
    case 4:
      return 'fourth';
    case 5:
      return 'fifth';
    case 6:
      return 'sixth';
    default:
      return i + 'th';
  }
}

export function getPeriodName(period) {
  if (period === 'week') {
    period = '1W';
  } else if (period === 'day') {
    period = '1D';
  } else if (period === 'month') {
    period = '1M';
  } else if (period === 'hour') {
    period = '1h';
  }

  return (
    parsePeriod(period, 's', 'second') ||
    parsePeriod(period, 'm', 'minute') ||
    parsePeriod(period, 'h', 'hour') ||
    parsePeriod(period, 'D', 'day') ||
    parsePeriod(period, 'W', 'week') ||
    parsePeriod(period, 'M', 'month')
  );
}

const parsePeriod = (period, symbol, name) => {
  if (period && period.indexOf(symbol) > -1) {
    const num = period.replace(symbol, '');
    return `${num} ${name}` + (parseInt(num, 10) > 1 ? 's' : '');
  }

  return null;
};

export function localizedNumber(num, options) {
  if (!options) {
    options = {};
  }

  if (num === null) {
    num = 0;
  }

  return num.toLocaleString('en-GB', options);
}

export function renderDecimalNumber(num) {
  if (num && typeof num === 'object' && !!num['$numberDecimal']) {
    return num['$numberDecimal'];
  } else {
    return num;
  }
}

export function shortcutFormatNumber(number, minimal = false) {
  let formatedNumber = '0';

  if (number >= 1000) {
    formatedNumber = numeral(number).format('0.[00]a');
  } else if (number.toString().indexOf('e') > 0) {
    // numeral.js bugfix
    formatedNumber = number.toFixed(8);
  } else if (number >= 0.01) {
    if (minimal) {
      formatedNumber = numeral(number).format('0.[0000]');
    } else {
      formatedNumber = numeral(number).format('0.[000]');
    }
  } else if (number >= 0.001) {
    formatedNumber = numeral(number).format('0.[0000]');
  } else if (number >= 0.0001) {
    formatedNumber = numeral(number).format('0.[00000]');
  } else if (number >= 0.00001) {
    formatedNumber = numeral(number).format('0.[000000]');
  } else if (number >= 0.000001) {
    formatedNumber = numeral(number).format('0.[0000000]');
  } else if (number >= 0.0000001) {
    formatedNumber = numeral(number).format('0.[00000000]');
  } else {
    if (minimal) {
      formatedNumber = numeral(number).format('0.[0000]');
    } else {
      formatedNumber = numeral(number).format('0.[00]');
    }
  }

  return formatedNumber;
}

export function formatNumber(
  num,
  currency,
  signAfter,
  usePlus,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2
) {
  if (minimumFractionDigits > maximumFractionDigits) {
    maximumFractionDigits = minimumFractionDigits;
  }

  let options = { maximumFractionDigits: maximumFractionDigits };

  if (currency) {
    options = {
      ...options,
      style: 'currency',
      currency: currency,
      minimumFractionDigits: minimumFractionDigits,
    };
  }

  let absoluteNum = localizedNumber(Math.abs(num), options);
  const absoluteNumBackup = absoluteNum;
  let formattedNum = '';
  let isPositive = false;
  let isNegative = false;
  let isZero = false;

  if (!signAfter) {
    signAfter = '';
  }

  if (!usePlus) {
    usePlus = '';
  }

  // Exception - do not show US$, just $
  if (absoluteNum.substring(0, 3) === 'US$') {
    absoluteNum = absoluteNum.substring(2, absoluteNum.length);
  }

  if (currency === 'BTC') {
    absoluteNum = absoluteNum.replace('BTC', '₿');
  }

  if (absoluteNumBackup + '' === localizedNumber(0, options) + '') {
    // FYI: need to compare as strings
    isZero = true;
    formattedNum = absoluteNum + signAfter;
  } else if (num > 0) {
    isPositive = true;

    if (usePlus) {
      formattedNum += '+';
    }
    formattedNum += absoluteNum + signAfter;
  } else if (num < 0) {
    isNegative = true;
    formattedNum = '-' + absoluteNum + signAfter;
  }

  return [formattedNum, isPositive, isNegative, isZero];
}

export function isDemoExchange(ex) {
  return ex === 'demo';
}

export function getFieldCssClass(value, mandatory = true, isThisFormElementSelect = false) {
  if (value && value !== '---') {
    if (stores.templatesStore.isSelect && isThisFormElementSelect) {
      return 'green-shadow';
    } else if (stores.templatesStore.isSelect) {
      return 'green-shadow template-pre-fill';
    }

    return 'green-shadow';
  } else if (mandatory) {
    if (stores.templatesStore.isSelect && isThisFormElementSelect) {
      return 'red-shadow';
    } else if (stores.templatesStore.isSelect) {
      return 'red-shadow template-pre-fill';
    }

    return 'red-shadow';
  }

  return 'optional-field';
}

export function setDataSessionStorage(label, data) {
  try {
    const ss = new DeviceStorage({
      cookieFallback: true,
      cookie: {
        secure: window.location.protocol === 'https:',
      },
    }).sessionStorage();

    ss.save(label, data);
  } catch (error) {}
}

export function getDataSessionStorage(label) {
  try {
    const ss = new DeviceStorage({
      cookieFallback: true,
      cookie: {
        secure: window.location.protocol === 'https:',
      },
    }).sessionStorage();

    return ss.read(label);
  } catch (error) {}
}

export function setDataLocalStorage(label, data) {
  try {
    const ls = new DeviceStorage({
      cookieFallback: true,
      cookie: {
        secure: window.location.protocol === 'https:',
      },
    }).localStorage();

    ls.save(label, data);
  } catch (error) {
    console.log(error);
  }
}

export function getDataLocalStorage(label) {
  try {
    const ls = new DeviceStorage({
      cookieFallback: true,
      cookie: {
        secure: window.location.protocol === 'https:',
      },
    }).localStorage();
    return ls.read(label);
  } catch (error) {
    console.log(error);
  }
}

export function setDataCookies(label, data) {
  try {
    const ls = new DeviceStorage({
      cookie: {
        secure: window.location.protocol === 'https:',
      },
    }).cookies();

    ls.save(label, data);
  } catch (error) {}
}

export function getDataCookies(label) {
  try {
    const ls = new DeviceStorage({
      cookie: {
        secure: window.location.protocol === 'https:',
      },
    }).cookies();

    return ls.read(label);
  } catch (error) {}
}

export const calculateTotalWalletsAmount = () => {
  let prices = 0;
  let demoExchangesIDs = [];
  const exchangeLength = stores.info.exchanges.length;
  const exchanges = stores.info.exchanges;
  const exchangeIds = exchanges.map((ex) => ex.id);
  let demoExchanges;

  if (exchangeLength > 0) {
    demoExchanges = stores.info.exchanges.filter((item) => item.isDemo === true);
    if (demoExchanges.length > 0) {
      demoExchangesIDs = demoExchanges.map((item) => item.id);
    }
  }

  for (const i in stores.userInfo.totalBalances) {
    if (!demoExchangesIDs.includes(i) && exchangeIds.includes(i)) {
      prices += stores.userInfo.totalBalances[i];
    }
  }

  const totalAmount =
    stores.info.currencies && stores.info.currencies[stores.user.user.user.baseCurrency]
      ? formatNumber(prices, stores.user.user.user.baseCurrency)[0]
      : formatNumber(prices, stores.user.user.user.baseCurrency, '', '', 4)[0];

  stores.walletsStores.setTotalWalletsValue(prices);

  return totalAmount;
};

export function sortAndGetAllUserExchanges() {
  const sortedExList = stores.userInfo.exchanges.slice().sort((ex1, ex2) => {
    if (ex2.uid === 'demo') {
      return -1;
    } else if (ex1.uid === 'demo') {
      return 1;
    }
    if (ex1.uid < ex2.uid) {
      return -1;
    }
    if (ex1.uid > ex2.uid) {
      return 1;
    }
    return 0;
  });

  const fullExchangeList = [];

  for (const i in sortedExList) {
    const item = sortedExList[i];

    const exchange = stores.info.exchanges.find((exchange) => {
      return exchange.id === item.id;
    });
    fullExchangeList.push(exchange);
  }

  return fullExchangeList;
}

export function canBeRender(value) {
  if (
    ['ma_1', 'ma_5', 'ma_9', 'ma_50', 'ma_100', 'ma_200', 'rsi', 'above', 'below'].indexOf(value) >
    -1
  ) {
    if (stores.info.lastSelectedExchange) {
      if (
        [...stores.info.MAExchanges, ...stores.info.RSIExchanges].indexOf(
          stores.info.lastSelectedExchange.uid
        ) === -1
      ) {
        return false;
      }
    } else {
      return false;
    }
  }

  return true;
}

export function formatSum(valueObject) {
  let { sum, currency, decimalsAmount, spaceAfterCurrencySign, showPlusSign, showPercentageSign } =
    valueObject;

  if (typeof sum !== 'number') {
    console.error('formatSum function: sum should be a number');
    return '';
  }

  if (typeof decimalsAmount !== 'number' && typeof decimalsAmount !== 'undefined') {
    console.error('formatSum function: decimalsAmount should be a number or undefined');
    return '';
  }

  let newSum;
  let currencySign;

  if (currency) currency = currency.toLowerCase();

  if (currency === 'btc') currencySign = String.fromCharCode(8383);
  else if (currency === 'eur') currencySign = String.fromCharCode(8364);
  else if (currency === 'usd') currencySign = String.fromCharCode(36);
  else if (currency === 'gbp') currencySign = String.fromCharCode(163);

  if (!currencySign) spaceAfterCurrencySign = false;

  let decimalsMask = '';
  for (let i = 0; i < decimalsAmount; i++) decimalsMask = decimalsMask + '0';

  // Convert sum number to string with comas (e.g. 1000.00100 to 1,000.001)
  let decimalsTruncateRegexp = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimalsMask.length}})?`);
  let truncatedSumNumber = sum.toString().match(decimalsTruncateRegexp)[0];
  let formattedSum = numeral(truncatedSumNumber).format(`0,0.${decimalsMask}`);
  if (formattedSum === `0.${decimalsMask}`) formattedSum = '0';

  let dotPosition = formattedSum.indexOf('.');

  // Trim all Zeros from the end of the decimals row
  if (dotPosition !== -1) {
    let decimalsRow = formattedSum.substring(dotPosition + 1);

    for (let i = decimalsRow.length - 1; i >= 0; i--) {
      if (decimalsRow.substring(i, i + 1) !== '0') break;
      decimalsRow = decimalsRow.substring(0, i);
    }

    formattedSum =
      formattedSum.substring(0, dotPosition) + `${decimalsRow.length > 0 ? '.' : ''}` + decimalsRow;
  }

  // Check is formattedSum negative
  let isSumNegative;

  if (formattedSum[0] === '-') {
    formattedSum = formattedSum.slice(1);
    isSumNegative = true;
  }

  showPlusSign = showPlusSign && formattedSum.substring(0, 1) !== '-';

  newSum =
    (showPlusSign && !isSumNegative && formattedSum !== '0' ? '+' : '') +
    (isSumNegative ? '-' : '') +
    (currencySign ? currencySign : '') +
    (spaceAfterCurrencySign ? ' ' : '') +
    formattedSum +
    (showPercentageSign ? '%' : '');

  return newSum;
}

export function retrieveCoinNameFromSequence(
  entireSequence,
  currentSequenceIndex,
  isLeverage = false
) {
  // Retrieve action types
  const sequencesNamesList = [...entireSequence].map((currentValue) => {
    return currentValue.type;
  });

  // For leverages we use the same instrument in all steps
  if (isLeverage) {
    if (sequencesNamesList[0] === 'CONDITION') {
      return toJS(entireSequence[0]).data.conditions[0].ifs[0];
    }
    if (sequencesNamesList[0] === 'ACTION') {
      return toJS(entireSequence[0]).data.actions[0].b[0];
    }
  }

  // Retrieve coins names
  const coinsNamesList = sequencesNamesList.reduce((accumulator, currentValue, currentIndex) => {
    let currentCoin;

    // ========================================
    // Retrieve coin from CONDITION
    if (currentValue === 'CONDITION') {
      currentCoin = toJS(entireSequence[currentIndex]).data.conditions[0].ifc;

      if (currentCoin === 'symbol') {
        currentCoin = toJS(entireSequence[currentIndex]).data.conditions[0].ifs[0];
      } else if (currentCoin === 'from_sequence') {
        const targetSequenceIndex = toJS(entireSequence[currentIndex]).data.conditions[0].sr;
        currentCoin = accumulator[targetSequenceIndex];
      } else if (currentCoin === 'that_coin') {
        currentCoin =
          accumulator[currentIndex - 1] ||
          accumulator[currentIndex - 2] ||
          accumulator[currentIndex - 3] ||
          accumulator[currentIndex - 4] ||
          accumulator[currentIndex - 5] ||
          accumulator[currentIndex - 6] ||
          accumulator[currentIndex - 7];
      }
    }

    // ========================================
    // Retrieve coin from ACTION
    if (currentValue === 'ACTION') {
      currentCoin = toJS(entireSequence[currentIndex]).data.actions?.[0].ort;

      if (currentCoin === 'that_coin') {
        currentCoin =
          accumulator[currentIndex - 1] ||
          accumulator[currentIndex - 2] ||
          accumulator[currentIndex - 3] ||
          accumulator[currentIndex - 4] ||
          accumulator[currentIndex - 5] ||
          accumulator[currentIndex - 6] ||
          accumulator[currentIndex - 7];
      } else if (currentCoin.indexOf('from_sequence') === 0) {
        const targetSequenceIndex = toJS(entireSequence[currentIndex]).data.actions[0].sr;
        currentCoin = accumulator[targetSequenceIndex];
      } else if (currentCoin === 'base') {
        currentCoin = toJS(entireSequence[currentIndex]).data.actions[0].b[0];
      }
    }

    // ========================================
    // Retrieve coin from OPERATOR
    if (currentValue === 'OPERATOR') {
      currentCoin = undefined;
    }

    return [...accumulator, currentCoin];
  }, []);

  return coinsNamesList[currentSequenceIndex];
}

export function formatCoinAmount(number) {
  Decimal.set({ toExpPos: 40, toExpNeg: -40 });

  number = new Decimal(number);
  number = number.toString();

  // Add decimals part if it's number if number doesn't have it
  if (number.search(/\./g) === -1) number = number + '.0';

  let formattedNumber;

  const integerPart = number.match(/\d+\b/)[0];
  let decimalPart =
    number.match(/\.(0*[1-9]{1}\d{0,3})/) && number.match(/\.(0*[1-9]{1}\d{0,3})/)[1];

  // Truncate zeros at the end of the decimals part
  if (decimalPart) decimalPart = decimalPart.replace(/(0*$)/, '');

  // If decimals are all zeros than return integer part
  if (decimalPart && decimalPart.match(/^0+$/)) return integerPart;

  formattedNumber = `${integerPart}${decimalPart ? '.' : ''}${decimalPart ? decimalPart : ''}`;

  return formattedNumber;
}

const intercomVisibilityStyle = document.createElement('style');
document.head.appendChild(intercomVisibilityStyle)
export const handleIntercomVisibility = ({ hideIntercom }) => {
  intercomVisibilityStyle.innerHTML = `
    .intercom-lightweight-app {
      display: ${hideIntercom ? 'none' : 'block'} !important;
    }
    #intercom-container {
      display: ${hideIntercom ? 'none' : 'block'} !important;
    }
  `
};

export function setIntercomVisibility(location) {
  const pathsToNotShowIntercom = ['/register', '/login', '/rule']

  const pathIsMatched = Boolean(pathsToNotShowIntercom.find((pathPart) => location.pathname.includes(pathPart)))

  handleIntercomVisibility({ hideIntercom: pathIsMatched });
}

export const findItem = (key, value, items) => items.find((item) => item[key] === value);

// Exchange is valid if isEnabled and type is 'spot'
const validateExchange = (exchangeId, exchanges) => {
  return exchanges.find(
    ({ id, isEnabled, type }) => id === exchangeId && isEnabled && type === 'spot'
  );
};

export const formatExchangePairsData = (markets, exchanges = []) => {
  const marketExchangeIds = Object.keys(markets);

  if (!marketExchangeIds?.length || !exchanges?.length) return;

  const formattedExchangePairs = [];
  const validExchangeUids = {};

  marketExchangeIds.forEach((key) => {
    // validate the exchange based on stipulated conditons
    const validExchange = validateExchange(key, exchanges);

    if (!validExchange) return;

    validExchangeUids[validExchange.uid] = true;

    // Prevent adding Binance twice as Demo Exchange
    // is basically a mirror of Binance
    if (validExchange.uid === 'binance' && validExchangeUids['demo']) return;

    const exchangePair = markets[key].map(({ base, quote }) => {
      const name = validExchange.uid === 'demo' ? 'binance' : validExchange.uid;

      return {
        from: base,
        to: quote,
        exchangeName: name.toUpperCase(),
        exchangeId: key,
        pair: `${base}${quote}`,
      };
    });

    formattedExchangePairs.push(exchangePair);
  });

  return formattedExchangePairs.flat(1);
};

export const TRADING_VIEW_MINI_WIDGET_PREFIX = `https://s.tradingview.com/embed-widget/mini-symbol-overview/?locale=en#%7B%22symbol%22%3A%22BINANCE%3A`;
export const TRADING_VIEW_MINI_WIDGET_SUFFIX = `%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A%22100%25%22%2C%22dateRange%22%3A%2212M%22%2C%22colorTheme%22%3A%22dark%22%2C%22trendLineColor%22%3A%22%2337a6ef%22%2C%22underLineColor%22%3A%22rgba(55%2C%20166%2C%20239%2C%200.15)%22%2C%22isTransparent%22%3Atrue%2C%22autosize%22%3Atrue%2C%22largeChartUrl%22%3A%22%22%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22mini-symbol-overview%22%7D`;

export const initialWatchList = [
  {
    value: 'BTC_USD_BINANCE',
    src: `${TRADING_VIEW_MINI_WIDGET_PREFIX}BTCUSD${TRADING_VIEW_MINI_WIDGET_SUFFIX}`,
  },
  {
    value: 'ETH_USD_BINANCE',
    src: `${TRADING_VIEW_MINI_WIDGET_PREFIX}ETHUSDT${TRADING_VIEW_MINI_WIDGET_SUFFIX}`,
  },
];

export const getCoinData = ({ assets, exchangeId, coin }) => {
  let coinAsset;

  if (!exchangeId) {
    coinAsset = Object.values(assets)[0];
  } else {
    coinAsset = assets[exchangeId];
  }

  return coinAsset[coin];
};

export const numberWithCommas = (n) => {
  const rounded = Math.round(n * 100 + Number.EPSILON) / 100;
  var parts = rounded.toString().split('.');
  let stringate = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts[1] ? '.' + parts[1] : '');
  return stringate;
};

export const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const filterWatchListFromOptions = (options, watchList) =>
  options.filter((option) => {
    return !watchList.find((item) => item.value === option.value);
  });

export const getUrlSearhQueryValue = (search, name) => new URLSearchParams(search).get(name);

export const specialCharacters = [
  '+',
  `'`,
  `"`,
  '#',
  '$',
  '%',
  '&',
  '(',
  ')',
  '*',
  ',',
  ';',
  '!',
  '-',
];

export const removeSpecialCharacters = (string, characters = specialCharacters) => {
  let result = string;

  characters.forEach((character) => {
    result.replace(character, '');
  });

  return result.replace(/\s+/g, ' ').trim();
};

export const formatDate = (d) => {
  let result = '';

  if (!d) return result;

  const date = new Date(d);
  const today = new Date();

  const diffTime = Math.abs(today - date);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
  const diffMins = Math.floor(diffTime / (1000 * 60));
  const diffSecs = Math.round(diffTime / 1000);

  if (diffDays <= 30) {
    if (diffDays > 0) {
      result = `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
    } else if (diffHours > 0) {
      result = `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
    } else if (diffMins > 0) {
      result = `${diffMins} minute${diffMins > 1 ? 's' : ''} ago`;
    } else {
      if (diffSecs === 0) {
        result = 'Now';
      } else {
        result = `${diffSecs} second${diffSecs > 1 ? 's' : ''} ago`;
      }
    }
  } else if (today.getFullYear() === date.getFullYear()) {
    result = date.toLocaleDateString(undefined, {
      weekday: 'short',
      month: 'long',
      day: 'numeric',
    });
  } else {
    result = date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
  }

  return result;
};

export const decideIconClassName = (prefix, name) => {
  let iconClassName = `${prefix}__exchangeIcon`;

  if (!name) return iconClassName;

  const convertedName = convertStringToClassName(name);

  if (convertedName === 'bitstamp') {
    iconClassName += ` ${prefix}__exchangeIcon_custom-bitstamp`;
  } else {
    iconClassName += ` ${prefix}__exchangeIcon_${convertedName}`;
  }

  return iconClassName;
};

export const checkItemInParent = (parent, item) => {
  if (!parent) return;

  return parent === item || parent.contains(item);
};

export const replaceSpacesInString = (string = '', character = '') =>
  string.replace(/\s/g, character).trim();

export const handleCopy = (textToCopy, itemName) => {
  navigator.clipboard.writeText(textToCopy).then(() => {
    successNotification((itemName || '') + ' Copied to clipboard');
  });
};

export const sleep = (millis) => {
  return new Promise((resolve) => setTimeout(resolve, millis));
};

export const checkIfOnRuleDetailPageFromLocation = () => {
  const href = window.location.href;

  if (href.indexOf('/rule/') !== -1) {
    return !new RegExp('/rule/[a-z0-9]+/(edit|draft|clone|restart)').test(href);
  }

  return false;
};

export const checkIfOnRuleEditPageFromLocation = () => {
  const href = window.location.href;

  if (href.indexOf('/rule/') !== -1) {
    return new RegExp('/rule/[a-z0-9]+/(edit|draft|clone|restart)').test(href);
  }

  return false;
};
