import React from 'react'

import { Button } from 'design-system'
import { CircularProgress, Slide, Typography, useTheme } from '@mui/material'

import type { ButtonProps } from 'design-system'

export interface ButtonLoadingProps extends ButtonProps { }

const testId = 'ButtonLoading'
export const ButtonLoading = (props: ButtonLoadingProps) => {
  const {
    sx,
    disabled,
    children,
    ...otherProps
  } = props

  const theme = useTheme()

  return (
    <Button
      data-testid={testId}
      className={testId}
      fullWidth={true}
      disableRipple={true}
      colorVariant={'marketing'}
      sx={{
        height: '50px',
        marginTop: '20px',
        width: '100%',
        '&:disabled': {
          color: theme.palette.coinrule.basic.white,
          backgroundColor: theme.palette.coinrule.validation.success,
          '&:after': {
            backgroundColor: theme.palette.coinrule.validation.success,
          }
        },
        opacity: disabled ? '0.4' : ''
      }}
      zIndex={0}
      {...otherProps}
    >
      {disabled ?
        <Slide
          direction={'up'}
          in={disabled}
          timeout={500}
        >
          <div >
            <CircularProgress
              sx={{
                marginRight: '10px',
                color: theme.palette.coinrule.basic.white,
              }}
              size={15}
            />
          </div>
        </Slide>
        : null}
      <Typography
        component={'span'}
        sx={{
          fontSize: '16px',
          fontWeight: 400,
        }}
      >
        {children}
      </Typography>
    </Button>
  )
}

export default ButtonLoading
