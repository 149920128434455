// FIXME: https://gitlab.com/coinrule-v2/dev/webapp/coinrule.com/-/merge_requests/497#note_941124187

import React from 'react'

import type { BoxProps, FormControlLabelProps} from '@mui/material';
import { Box, Checkbox as MUICheckbox, FormControlLabel, useTheme } from '@mui/material'
export interface CheckboxProps extends Omit<FormControlLabelProps, 'control'> {
  labelProps?: BoxProps,
  control?: FormControlLabelProps['control'],
}

interface LabelProps extends BoxProps {
  label: CheckboxProps['label'],
}

const Label = ({label, sx, ...otherProps}: LabelProps) => {
  const theme = useTheme();
  
  return (
    <Box
      component={'span'}
      sx={{
        fontSize: '13px',
        color: theme.palette.coinrule.basic.greyDark,
        ...sx,
      }}
      {...otherProps}
    >
      {label}
    </Box>
  )
}


const testId = 'Checkbox'
export const Checkbox = (props: CheckboxProps) => {
  const theme = useTheme();

  const defaultCheckbox = (
    <MUICheckbox
      disableRipple={true}
      icon={
        <Box
          component={'span'}
          sx={{
            width: 16,
            height: 16,
            border: `1px solid ${theme.palette.coinrule.basic.greySuperLight}`,
            borderRadius: '5px'
          }}
        />}
    />
  );

  const {
    sx,
    label,
    labelProps,
    control= defaultCheckbox,
    ...otherProps
  } = props

  return (
    <FormControlLabel
      control={control}
      data-testid={testId}
      className={testId}
      label={
        <Label
          label={label}
          {...labelProps}
        />
      }
      sx={{
        ...sx
      }}
      {...otherProps}
    />
  )
}

export default Checkbox
