import React from 'react';

import { observer } from 'mobx-react';
import { Notify, ENotifyChannel, ENotifyTimeframe } from '../notify.type';

export interface DetailTextNotifiesProps {
  isFirstNotify: boolean;
  isLoading: boolean;
  sequenceIndex: number;
  notify?: Notify;
  onClick: () => any;
}

const DetailTextNotifies = observer((props: DetailTextNotifiesProps) => {
  const { isFirstNotify, isLoading, sequenceIndex, notify, ...otherProps } = props;

  return (
    <li
      className={`
        ruleSummaryText__section
        ruleSummaryText__section_action
        ${isLoading && 'ruleSummaryText__section_preloader'}        
      `}
      style={{ paddingLeft: `${(sequenceIndex - 1) * 12}px` }}
      {...otherProps}>
      <strong
        className={`
          ruleSummaryText__keyWord
          ruleSummaryText__keyWord_action
        `}>
        {isFirstNotify ? '' : 'AND '}NOTIFY
      </strong>{' '}
      <strong>{notify?.timeframe === ENotifyTimeframe.INSTANTLY ? 'instantly' : null}</strong>
      <span>{' on '}</span>
      <strong>
        {notify?.channel === ENotifyChannel.TELEGRAM
          ? 'Telegram'
          : notify?.channel === ENotifyChannel.EMAIL
          ? 'email'
          : null}
      </strong>
    </li>
  );
});

export default DetailTextNotifies;
