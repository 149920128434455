import stores from '../stores';
import { getDataCookies, setDataCookies } from './utils';

export const FINISHED_TOUR_PREFIX = `finished_tour_`;
export const ONBOARDING_CLOSED = 'onboardingClosed';

export function selectTour() {
  let userTour = stores.user.userTour;
  let userTourCheckKey = FINISHED_TOUR_PREFIX + userTour;

  if (
    userTour &&
    getDataCookies(userTourCheckKey) !== '1' &&
    !stores.userSurvey.showOnboardingModal
  ) {
    return userTour;
  }

  return '';
}

export const clearTourFromCookies = (tour) => {
  setDataCookies(FINISHED_TOUR_PREFIX + tour, 0);
};

export function tourWasActivated() {
  let userTour = stores.user.userTour;
  let userTourCheckKey = FINISHED_TOUR_PREFIX + userTour;

  if (userTour && getDataCookies(userTourCheckKey) !== '1') {
    setDataCookies(userTourCheckKey, '1');
  }
}

export const showTour = (userTour) => {
  const userTourCheckKey = FINISHED_TOUR_PREFIX + userTour;

  setDataCookies(userTourCheckKey, '0');
};

export const LOGIN_COUNT = 'LOGIN_COUNT';

export const handleUserLoginCount = (userId) => {
  const label = `${LOGIN_COUNT}_${userId}`;
  let loginCount = getDataCookies(label);

  if (loginCount === undefined || loginCount === null) setDataCookies(label, 1);

  if (loginCount === 1) setDataCookies(label, 2);
};
