import React from 'react'

import type { StackProps } from '@mui/material'

import { useTheme, Box, Typography, Stack } from '@mui/material'

const scrollXStyles: Record<string, React.CSSProperties> = {
  typography: {
    overflowX: 'auto',
    overflowY: 'hidden',
    // @ts-ignore this is custom sx MUI prop
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  box: {
    // table cells preserve width of text inside
    display: 'table-cell',
    marginRight: '14px'
  },
}

export interface CopyLinkAreaProps extends StackProps {
  icon?: React.ReactNode,
  scrollX?: boolean,
  variant?: 'regular' | 'small',
  colorVariant?: 'default' | 'grey'
}

const testId = 'CopyLinkArea'
export const CopyLinkArea = (props: CopyLinkAreaProps) => {
  const {
    sx,
    children,
    icon,
    scrollX = false,
    variant = 'regular',
    colorVariant = 'default',
    ...otherProps
  } = props

  const theme = useTheme()

  const variantStackSx = variant === 'regular' ? {
    height: 52,
    borderRadius: '10px',
    paddingLeft: '14px',
    paddingRight: '14px',
  } : {
    height: 27,
    borderRadius: '5px',
    paddingLeft: '11px',
    paddingRight: '11px',
  } 
  const variantTypographySx = variant === 'regular' ? {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '52px',
  } : {
    fontSize: 14.4,
    fontWeight: 600,
    lineHeight: '27px',
  } 
  const variantIconWrapperSx = variant === 'regular' ? {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '52px',
    paddingLeft: '14px',
  } : {
    fontSize: 14.4,
    fontWeight: 600,
    lineHeight: '27px',
    paddingLeft: '11px',
  } 
  
  const colorVariantSx = colorVariant === 'default' ? {
    backgroundColor: theme.palette.coinrule.highlights.linkBkg,
  } : {
    backgroundColor: theme.palette.coinrule.basic.greySuperLight,
  } 
  const colorVariantTypographySx = colorVariant === 'default' ? {
    color: theme.palette.coinrule.text.linkText,
  } : {
    color: theme.palette.coinrule.text.grey,
  } 
  const colorVariantIconWrapperSx = colorVariant === 'default' ? {
    fill: theme.palette.coinrule.text.linkText,
  } : {
    fill: theme.palette.coinrule.text.grey,
  } 

  return (
    <Stack 
      data-testid={testId}
      className={testId}
      justifyContent={'space-between'}
      alignItems={'center'}
      direction={'row'}
      sx={{
        boxSizing: 'border-box',
        width: '100%',
        cursor: 'pointer',
        ...variantStackSx,
        ...colorVariantSx,
        ...sx,
      }}
      {...otherProps}
    >
      <Typography
        noWrap={scrollX ? false : true}
        sx={{
          whiteSpace: 'nowrap',
          ...variantTypographySx,
          ...colorVariantTypographySx,
          ...(scrollX ? scrollXStyles.typography : {}),
        }}
      >
        <Box 
          component={'span'}
          sx={scrollX ? scrollXStyles.box : {}}
        >
          {children}
        </Box>
      </Typography>
      {
        icon ? 
          <Typography
            className={testId}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ...variantIconWrapperSx,
              ...colorVariantIconWrapperSx,
            }}
          >
            {icon}
          </Typography>
          :
          null
      }
    </Stack>
  )
}

export default CopyLinkArea
