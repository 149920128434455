import React from 'react'

export interface IconTradeBuyProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconTradeBuy'
export const IconTradeBuy = (props: IconTradeBuyProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      xmlns={"http://www.w3.org/2000/svg"}
      x={0}
      y={0}
      viewBox={"0 0 23 23"}
      xmlSpace={"preserve"}
      width={"1rem"}
      height={"1rem"}
      {...otherProps}
    >
      <path
        fill={"#3642e1"}
        d={"M11.8 20.5c4.9 0 9-4 9-9 0-4.9-4.1-9-9-9s-9 4-9 9c0 4.9 4 9 9 9zm0-16.5c4.1 0 7.5 3.4 7.5 7.5S15.9 19 11.8 19s-7.5-3.4-7.5-7.5S7.7 4 11.8 4z"}
      />
      <path
        fill={"#3642e1"}
        d={"m11.5 16.3 4.4-4.9-4.4-4.9v3.4H8.1v3h3.4v3.4z"}
      />
    </svg>
  )
}

export default IconTradeBuy
