import {
  ONBOARDING_EXPERIMENT_NAME,
  TOnboardingVariant,
  TStepName,
} from 'pages/RulesDashboardPage/Onboarding/utils';
import { TStepTarget } from 'pages/RulesDashboardPage/RuleTour/SmartGuide/utils';
import { CustomWindow, IAnalyticsOption, TAnatyticsTypes, IAdrollTrackingOptions } from 'typings/types';

declare let window: CustomWindow;

export const mixpanel = window.mixpanel;

export const ANALYTICS_EVENTS = {
  PAYMENT_BUTTON_CLICKED: 'User Clicked Upgrade Button',
};

export const handleMixpanelEventAnalytics = ({ event, data }: IAnalyticsOption) => {
  if (data) mixpanel?.track(event, data);
  else mixpanel?.track(event);
};

export const handleMixpanelAnalytics = (options: IAnalyticsOption) => {
  const { data } = options;

  handleMixpanelEventAnalytics(options);

  if (data) mixpanel?.people?.set(data);
};

export const handleAdrollTracking = (options: IAdrollTrackingOptions) => {
  try {
    // @ts-ignore
    __adroll.record_user(options);
  } catch(err) {}
};

export const getAnalyticsEventScreen = (name: TStepName | TStepTarget) => {
  let screen;
  let count;

  switch (name) {
    case 'Welcome':
      screen = 'Welcome Screen';
      count = 1;
      break;

    case 'ReasonJoined':
      screen = 'Reason to Join Screen';
      count = 2;
      break;

    case 'Trading Experience':
      screen = 'Trading Experience Screen';
      count = 3;
      break;

    case 'FavoriteCoin':
      screen = 'Favorite Coin Screen';
      count = 4;
      break;

    case 'FavoriteExchanges':
      screen = 'Favorite Exchanges Screen';
      count = 4;
      break;

    case 'OnboardingQuizFinish':
      screen = 'Template Loading Screen';
      break;

    case 'onboarding_template':
      screen = 'Template List Screen';
      count = 5;
      break;

    case 'onboarding_template_selection':
      screen = 'Template Details Screen';
      count = 6;
      break;

    case '.exchange':
      screen = 'Smart Guide Exchange';
      count = 7;
      break;

    case '.ruleActionsLinks__but_templates':
      screen = 'Smart Guide Template';
      break;

    case '.trigger':
      screen = 'Smart Guide Order Type';
      count = 8;
      break;

    case '.addButtonsShowHide__button_action':
    case '.then':
      screen = 'Smart Guide Action';
      count = 9;
      break;

    case '.addButtonsShowHide__button_operator':
    case '.operators.show-section':
      screen = 'Smart Guide Operator';
      count = 10;
      break;

    case '.launch':
      screen = 'Smart Guide Launch';
      count = 11;
      break;

    default:
      screen = '';
      count = '';
      break;
  }

  return { screen, count };
};

export const getABCTesting = (variant: TOnboardingVariant) => {
  let ABCTest = 'A';

  switch (variant) {
    case 'Onboarding New Coins':
      ABCTest = 'A';
      break;

    case 'Onboarding Exit Disabled':
      ABCTest = 'D';
      break;

    case 'Old Onboarding':
      ABCTest = 'C';
      break;

    default:
      break;
  }

  return ABCTest;
};

export const getAnalyticsEvent = (
  variant: TOnboardingVariant,
  name: TStepName | TStepTarget,
  buttonType: 'Next' | 'Cancel' | 'Back'
) => {
  let { screen, count } = getAnalyticsEventScreen(name);
  let test = `${getABCTesting(variant)} ${count}) `;

  return `${variant ? test : ''}${buttonType} Button clicked on ${screen}`;
};

export const getABTestingEvent = (
  action: 'start' | 'completed' | 'drop-off',
  experiment = ONBOARDING_EXPERIMENT_NAME
) => `${experiment} ${action}`;

export const sendAnalytics = (type: TAnatyticsTypes, properties?: any) => {
  switch (type) {
    case 'facebook':
      window.fbq?.(...properties?.events, properties.data);
      break;

    case 'google':
      window.rga?.event(properties);
      break;

    case 'mixpanel':
      handleMixpanelAnalytics(properties);
      break;

    case 'adroll':
      handleAdrollTracking(properties);
      break;

    default:
      window.rga?.event(properties);
      break;
  }
};
