import { observer } from 'mobx-react';
import React from 'react';
import stores from '../../../../stores';
import { DEFAULT_TIME_TRIGGER_PERIOD } from '../../../../stores/addRule';
import { Select, DatePicker } from 'antd';
import moment from 'moment';
import SimpleSequenceLoader from '../../../../components/Loading/SimpleSequenceLoader';
import { getPeriodName } from '../../../../helpers/utils';

const Option = Select.Option;
@observer
class Time extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timerType: 'every',
      timerOften: 'hour',
    };
  }

  handleChangePeriod(value) {
    const { store } = this.props;
    store.setTimePeriod(value);
  }

  changeTimerType(value) {
    this.props.store.rule.tim = value;

    if (this.props.store.rule.tim === 'now') {
      this.props.store.rule.rpt.l = 1;
      this.props.store.rule.rpt.t = 'no_more_than_once';
    }
  }

  renderFieldsForEvery() {
    let connectText;

    if (this.props.store.rule.tim !== 'now') {
      connectText = <label className='smartphoneView30width'>starting</label>;

      if (this.props.store.rule.tim === 'on') {
        connectText = <label className='smartphoneView30width'>the</label>;
      }
    }

    return (
      <React.Fragment>
        {this.props.store.rule.tim === 'every' ? (
          <div className='select-grey' style={{ minWidth: 120 }}>
            <Select
              disabled={this.props.store.isQuickEdit()}
              value={this.props.store.rule.pe || DEFAULT_TIME_TRIGGER_PERIOD}
              onChange={this.handleChangePeriod.bind(this)}
              size={'default'}
              style={{ minWidth: 120 }}
            >
              <Option value='hour'>Hour</Option>
              <Option value='day'>Day</Option>
              <Option value='week'>Week</Option>
              <Option value='month'>Month</Option>
              <Option value={null} disabled={true}></Option>
              {stores.info.timePeriods.map((p) => {
                return (
                  <Option title='' key={p} value={p}>
                    {getPeriodName(p)}
                  </Option>
                );
              })}
            </Select>
          </div>
        ) : null}

        {connectText}

        {this.props.store.rule.tim !== 'now' ? (
          <div className='data-picker-grey smartphoneView65width'>
            <DatePicker
              showTime
              disabled={this.props.store.isQuickEdit()}
              inputReadOnly
              showToday={false}
              format='DD/MM/YYYY HH:mm'
              placeholder='immediately'
              value={this.props.store.rule.sd}
              onChange={(value) => this.props.store.setStartDate(value)}
              style={{ width: 188 }}
              disabledDate={(current) => {
                return current && current < moment().subtract(1, 'days');
              }}
            />
          </div>
        ) : null}
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className='content'>
        {this.props.removeBut}
        {this.props.helpIcon}
        <SimpleSequenceLoader store={this.props.store} />
        <div className='select-grey' style={{ minWidth: 90 }}>
          <Select
            disabled={this.props.store.isQuickEdit()}
            value={this.props.store.rule.tim}
            onChange={this.changeTimerType.bind(this)}
            size={'default'}
            style={{ minWidth: 90 }}
          >
            <Option value='every'>Every</Option>
            <Option value='on'>On</Option>
            <Option value='now'>Right Now</Option>
          </Select>
        </div>

        {this.renderFieldsForEvery()}
      </div>
    );
  }
}

export default Time;
