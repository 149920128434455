import React from 'react'

import type { StackProps } from '@mui/material'
import type { ChartRuleStatsProps, RuleProfitIndicatorProps } from 'design-system';

import { useTheme, Box, Stack } from '@mui/material'

import { Overlay, Typography, Center, Tooltip, ChartRuleStats, RuleProfitIndicator, IconInfo } from 'design-system'

import exampleData from './exampleData.fixture.json'

const examplePercentage = exampleData[exampleData.length - 1].percentageDifference
const exampleVolume = exampleData[exampleData.length - 1].volumeDifference
const examplePriceTrend = exampleVolume >=0 ? 'up' : 'down'

export interface RulePerformanceChartProps extends StackProps {
  profit: RuleProfitIndicatorProps['profit']
  growth: RuleProfitIndicatorProps['growth']
  priceTrend: RuleProfitIndicatorProps['priceTrend']
  data?: ChartRuleStatsProps['data']
  baseCurrency: ChartRuleStatsProps['baseCurrency']
  formatNumber: ChartRuleStatsProps['formatNumber']
  infoTooltipText?: string
  chartHeight?: number
}

const testId = 'RulePerformanceChart'
export const RulePerformanceChart = (props: RulePerformanceChartProps) => {
  const {
    sx,
    profit,
    growth,
    priceTrend,
    data,
    baseCurrency,
    formatNumber,
    infoTooltipText = 'Profit calculation excludes exchange fees',
    chartHeight = 200,
    ...otherProps
  } = props

  const theme = useTheme()
  const isEmpty = !data || data.length === 0

  return (
    <Stack
      data-testid={testId}
      className={testId}
      sx={{
        width: '100%',
        position: 'relative',
        ...sx
      }}
      {...otherProps}
    >
      {
        isEmpty  ?
          <Overlay opacity={0.95}>
            <Center>
              <Typography
                sx={{
                  color: theme.palette.coinrule.basic.greyLight,
                  fontSize: 16,
                  fontWeight: 600,  
                }}
              >
                No Data to Show Yet
              </Typography>
            </Center>
          </Overlay> 
          :
          null
      }
      <Stack
        direction={'row'}
        justifyContent={'flex-end'}
        alignItems={'flex-start'}
        gap={'5px'}
      >
        <RuleProfitIndicator 
          profit={isEmpty ? `${exampleVolume.toFixed(2)} ${baseCurrency}` : profit}
          growth={isEmpty ? `${examplePercentage.toFixed(2)} %` : growth}
          priceTrend={isEmpty ? examplePriceTrend : priceTrend}
        />
        <Tooltip
          title={infoTooltipText}
          placement={'top'}
        >
          <Box 
            style={{ 
              fontSize: '13px',
              position: 'relative',
              top: '9px',
            }}
            component={'span'}
          >
            <IconInfo />
          </Box>
        </Tooltip>
      </Stack>
      <Box
        sx={{
          width: '100%',
          height: chartHeight
        }}
      >
        <ChartRuleStats 
          // @FIXME without forcing type the webpack compiler fail, to be deleted after upgrading to newer CRA version
          data={(isEmpty ? exampleData : data) as ChartRuleStatsProps['data']}
          baseCurrency={baseCurrency}
          formatNumber={formatNumber}
        />
      </Box>
    </Stack>
  )
}

export default RulePerformanceChart
