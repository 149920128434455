import { useState, useEffect } from 'react';
import axios from 'axios';
import { errorNotification } from 'helpers/notification';
import { INewsItem, newsItemsFormatter } from 'helpers/newsfeed';
import { ERROR_MESSAGES } from 'helpers/errors';

interface IUseNewsFeedDataOptions {
  currentIndex: number;
  url: string;
}
const useNewsFeedData = ({ currentIndex, url }: IUseNewsFeedDataOptions) => {
  const [data, setData] = useState<INewsItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let cancel: Function;
    let isMounted = true;

    const getData = async () => {
      setIsLoading(true);

      try {
        const { data } = await axios({
          url,
          method: 'GET',
          cancelToken: new axios.CancelToken((c: Function) => (cancel = c)),
        });

        if (data) {
          setIsLoading(false);

          setData((items) => [...items, ...newsItemsFormatter(data)]);
        }
      } catch (error) {
        if (axios.isCancel(error)) return;
        errorNotification(ERROR_MESSAGES.GENERIC);
      }
    };

    if (isMounted) getData();

    return () => {
      isMounted = false;
      cancel && cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return {
    data,
    isLoading,
  };
};

export default useNewsFeedData;
