import dbg from '../helpers/dbg';
import AuthService from './auth';

let initialized = false;
let authService;

const initializeInstances = (thisObj) => {
  if (!initialized) {
    dbg('Initializing store instances');

    initialized = true;
    authService = new AuthService(thisObj);
  } else {
    dbg('Using already initialized service instances');
  }
};

class Services {
  constructor() {
    initializeInstances(this);

    this.auth = authService;
  }
}

export default new Services();
