import React from 'react';

import type { BoxProps} from '@mui/material';
import { } from '@mui/material';
import { Box, Table, useTheme,TableBody } from '@mui/material';

import type { IRuleActivityLog, TableRowRuleActivityLogProps } from 'design-system';
import { TableRowRuleActivityLog } from 'design-system';

export interface RuleActivityLogTableProps extends BoxProps {
  data: IRuleActivityLog[],
  formatCoinValue: TableRowRuleActivityLogProps['formatCoinValue']
}

const testId = 'RuleActivityLogTable'
export const RuleActivityLogTable = (props: RuleActivityLogTableProps) => {
  const {
    sx,
    data,
    formatCoinValue,
    ...otherProps
  } = props

  const [openedActivity, setOpenedActivity] = React.useState('');

  const theme = useTheme();

  const { greyBorderLight, greyLightBackgroundColor, beije, white } = theme.palette.coinrule.basic;

  const handleOpenActivity = (activityId: string) => {
    if(openedActivity === activityId) return setOpenedActivity('');
    setOpenedActivity(activityId);
  }

  return (
    <Box
      data-testid={testId}
      className={testId}
      sx={{
        width: '100%',
        ...sx,
      }}
      {...otherProps}
    >
      <Table
        sx={{
          borderTop: `1px solid ${greyBorderLight}`,
          ...sx,
        }}
      >
        <TableBody>
          {data.map((item, index) => {
            const getBackgroundColor = (index: number, name: string): string => {
              const isEven = (index) % 2 === 0;

              if((name === "Trade" && isEven) || (name === 'Trade' && !isEven)) return beije;
              if(isEven) return greyLightBackgroundColor;
              return white;
            }
            return (
              <TableRowRuleActivityLog
                formatCoinValue={formatCoinValue}
                sx={{
                  backgroundColor: getBackgroundColor(index, item.name),
                }}
                data={item}
                key={item.id}
                isOpen={openedActivity === item.id}
                setOpenedActivity={handleOpenActivity}
              />
            )})}
        </TableBody>
      </Table>
    </Box>
  )
}

export default RuleActivityLogTable
