import type { TReducer } from 'v2/types/TReducer'

import type { TDomainActionConnectExchangeBinanceOAuthPrepare } from './actionsFetchPrepare';
import type { DTOFetchConnectExchangeBinanceOAuthPrepare } from './DTOs/DTOFetchConnectExchangeBinanceOAuthPrepare';
import type { DTOErrorFetchConnectExchangeBinanceOAuthPrepare } from './DTOs/DTOErrorFetchConnectExchangeBinanceOAuthPrepare';

import { EAsyncStatus } from 'v2/types/EAsyncStatus';
import { EDomainActionsConnectExchangeBinanceOAuthPrepare } from './actionsFetchPrepare'

export type TDomainStateConnectExchangeBinanceOAuthPrepare = {
  data: DTOFetchConnectExchangeBinanceOAuthPrepare | null,
  error: DTOErrorFetchConnectExchangeBinanceOAuthPrepare | null
  status: EAsyncStatus
}

export const initialStateConnectExchangeBinanceOAuthPrepare: TDomainStateConnectExchangeBinanceOAuthPrepare = {
  data: null,
  error: null,
  status: EAsyncStatus.IDLE
}

export const reducerConnectExchangeBinanceOAuthPrepare: TReducer<TDomainStateConnectExchangeBinanceOAuthPrepare, TDomainActionConnectExchangeBinanceOAuthPrepare> = (state = initialStateConnectExchangeBinanceOAuthPrepare, action): TDomainStateConnectExchangeBinanceOAuthPrepare => {
  switch (action.type) {
    case EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_STARTED: {
      return {
        data: null,
        error: null,
        status: EAsyncStatus.PENDING
      }
    }
    case EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_SUCCEEDED: {
      return {
        data: action.payload.dto,
        error: null,
        status: EAsyncStatus.RESOLVED
      }
    }
    case EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_FAILED: {
      return {
        data: null,
        error: action.payload.error,
        status: EAsyncStatus.REJECTED
      }
    }
    default:
      return state
  }
}