import React from 'react'

import type { StackProps} from '@mui/material';

import { Stack, Typography } from '@mui/material'

export interface IconMessageProps extends StackProps {
  icon: React.ReactNode
  message: React.ReactNode
  subMessage?: React.ReactNode
}

const testId = 'IconMessage'
export const IconMessage = (props: IconMessageProps) => {
  const {
    sx,
    icon,
    message,
    subMessage,
    ...otherProps
  } = props

  return (
    <Stack
      data-testid={testId}
      className={testId}
      sx={{
        ...sx
      }}
      {...otherProps}
    >
      <Typography
        textAlign={"center"}
        sx={{
          fontSize: '50px',
        }}
      >
        {icon}
      </Typography>
      <Typography>
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: '24px',
            lineHeight: '28px',
            fontWeight: 700,
          }}
        >
          {message}
        </Typography>
        {
          subMessage ?
            <Typography
              textAlign={"center"}
              sx={{
                fontSize: '18px',
                lineHeight: '28px',
                fontWeight: 400,
              }}
            >
              {subMessage}
            </Typography>
            :
            null
        }
      </Typography>
    </Stack>
  )
}

export default IconMessage
