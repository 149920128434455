import type { CoinruleColorPalette } from '../../design-system';

import { createTheme } from '@mui/material';

const coinruleColorPalette: CoinruleColorPalette = {
  brand: {
    primary: '#4157F0',
    primaryDark: '#363fb3',
    secondary: '#FFD835',
  },
  basic: {
    blackBlue: '#1C2237',
    black: '#192A3E',
    inputText: '#192A3E',
    greyBlueDark: '#65749F',
    greyBlue: '#90A0B7',
    greyBlueLight: '#F2F5F7',
    greyBlueSuperLight: '#F7F9FA',
    greyBorder: '#CCCCCC',
    greyBorderLight: '#e6e6e6',
    greyAreaChartFill: '#E5E8EF',
    grey: '#9B9B9B',
    greyDark: '#4e4e4e',
    greyLight: '#B9B9B9',
    greySuperLight: '#DDDDDD',
    beije: '#FCF7E1',
    greyBeije: '#F5F4F0',
    white: '#FFFFFF',
    greyBackgroundColor: '#efefef',
    greyLightBackgroundColor: '#f6f8f9',
    greySuperLightBackgroundColor: '#00000014',
    greyLightColor: '#000000a6',
  },
  buttons: {
    CTA: '#FF165E',
    primary: '#4157F0',
    secondary: '#B9B9B9',
    marketing: '#74D20A',
    binancePromo: '#2C2A2A',
  },
  navigation: {
    icon1: '#00F4FE',
    icon2: '#FF6B4A',
    icon3: '#74D20E',
  },
  validation: {
    success: '#74D20E',
    error: '#FF0000',
    alert: '#FFB946',
    alertBkg: '#FFF1BD',
  },
  highlights: {
    ok: '#30D126',
    default: '#F8FE02',
    fieldBkg: '#FFF9AA',
    linkBkg: '#E7E9FF',
    optionBkg: '#e6f7ff',
    selectBorder: '#40a9ff',
    selectShadow: 'rgb(24 144 255 / 20%)',
  },
  prices: {
    up: '#6CC110',
    down: '#FA6B41',
  },
  rule: {
    action: '#4157F0',
    condition: '#F5A623',
    operator: '#F545FF',
    execution: '#FF165E',
  },
  text: {
    linkText: '#5F6BFF',
    grey: '#808080'
  }
}

export const theme = createTheme({
  spacing: (value: number | string) => value,
  shape: {
    borderRadius: 1,
  },
  typography: {
    h1: {
      fontFamily: '"Poppins","Helvetica","Arial",sans-serif',
      fontSize: 56,
      fontWeight: 600,
      lineHeight: '64px',
      letterSpacing: '0.01em',
      textAlign: 'left',
    },
    h4: {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontWeight: 700,
      fontSize: 40,
      lineHeight: '68px',
    },
    h5: {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontWeight: 700,
      fontSize: 32,
      lineHeight: '54px',
    },
    h6: {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '41px',
    },
    buttonBig: {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '29px',
    },
    buttonSmall: {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '24px',
    },
  },
  palette: {
    primary: {
      main: coinruleColorPalette.brand.primary,
    },
    secondary: {
      main: coinruleColorPalette.brand.secondary,
    },
    text: {
      primary: coinruleColorPalette.basic.black,
      secondary: coinruleColorPalette.basic.grey,
      disabled: coinruleColorPalette.basic.greySuperLight,
    },
    coinrule: coinruleColorPalette,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1200,
      xl: 1536,
    },
  },
});
