import React from 'react';
import { observer } from 'mobx-react';

import stores from '../../../stores/index';
import Help, { Container } from '../../../components/Help/index';
import { TRIGGERS, ACTION_TYPE, CONDITION_TYPE } from '../../../stores/addRule';
import './style.scss';
import lightning from '../../../assets/images/lightning.svg';
import Popover from '../../../components/Popover';

import AddButtonsBlock from '../AddButtonsBlock';
import DirectOrder from './DirectOrder';

import Time from './TimeTrigger';
import { EXCHANGES_TYPE } from '../../../constants/exchanges';

@observer
class Trigger extends React.Component {
  isLeverage() {
    const exchangeType = stores.exchangeInfo.exchangeType[this.props.store.rule.ex];
    return exchangeType && exchangeType === EXCHANGES_TYPE.LEVERAGE;
  }

  checkIfExchangeLoaded() {
    return (
      this.props.store.rule.ex &&
      stores.userInfo.balances[this.props.store.rule.ex] &&
      stores.exchangeInfo.assets[this.props.store.rule.ex] &&
      ((this.isLeverage() && stores.exchangeInfo.instruments[this.props.store.rule.ex]) ||
        stores.exchangeInfo.markets[this.props.store.rule.ex])
    );
  }

  handleChangeTriggerType(type) {
    if (this.props.store.isQuickEdit()) {
      return;
    }

    this.props.store.seqInit.preloadSequences = true;
    let addAction = false;

    const newTrigger = type;
    const oldTrigger = this.props.store.rule.tr;
    const actualSequence = this.props.store.seqInit.sequences;

    this.props.store.seqInit.sequenceSnapshots[oldTrigger] = actualSequence.slice(0);
    const sequenceSnapshot = this.props.store.seqInit.sequenceSnapshots[newTrigger].slice(0);

    if (sequenceSnapshot.length > 0) {
      this.props.store.seqInit.sequences = sequenceSnapshot;
    } else {
      this.props.store.seqInit.sequences = sequenceSnapshot;

      if (type === TRIGGERS.DIRECT_ORDER) {
        if (this.props.store.seqInit.getAction() === -1) {
          addAction = true;
        }
      }

      if (this.props.store.seqInit.sequences.length === 0 && type === TRIGGERS.EVENT) {
        this.props.store.seqInit.addToSequence(null, CONDITION_TYPE);
      }
    }

    this.props.store.setTrigger(type);
    if (addAction) {
      this.props.store.seqInit.addToSequence(null, ACTION_TYPE);
    }

    this.props.store.seqInit.countingSequence();
    this.props.store.seqInit.preloadSequences = false;
  }

  renderTriggerTypeSelect() {
    let textTime = 'Schedule Trades';
    let textEvent = 'Select Condition';
    let textDirectOrder = 'Trade Immediately';

    return (
      <React.Fragment>
        <Popover placement='top' text={textEvent}>
          <div
            onClick={this.handleChangeTriggerType.bind(this, TRIGGERS.EVENT)}
            className={`newRuleTitleTabs__tab
                            newRuleTitleTabs__tab_iconLightning
                            ${
                              this.props.store.rule.tr === 'event' && `newRuleTitleTabs__tab_active`
                            }
                            ${
                              this.props.store.isQuickEdit() && 
                              'disabled-button disabled-button_radius'
                            }`}
          >
            {TRIGGERS.EVENT}
          </div>
        </Popover>

        <Popover placement='top' text={textTime}>
          <div
            onClick={this.handleChangeTriggerType.bind(this, TRIGGERS.TIME)}
            className={`newRuleTitleTabs__tab
														newRuleTitleTabs__tab_iconClock
														${
                              this.props.store.rule.tr === 'time' && 
                              `newRuleTitleTabs__tab_active`
                            }
                            ${
                              this.props.store.isQuickEdit() && 
                              'disabled-button disabled-button_radius'
                            }`}
          >
            {TRIGGERS.TIME.toLowerCase() === 'time' ? 'Timer' : TRIGGERS.TIME}
          </div>
        </Popover>

        <Popover placement='top' text={textDirectOrder}>
          <div
            className={`newRuleTitleTabs__tab
														newRuleTitleTabs__tab_iconDownload
														${
                              this.props.store.rule.tr === 'direct_order' && 
                              `newRuleTitleTabs__tab_active`
                            }
                            ${
                              this.props.store.isQuickEdit() && 
                              'disabled-button disabled-button_radius'
                            }`}
            onClick={this.handleChangeTriggerType.bind(this, TRIGGERS.DIRECT_ORDER)}
          >
            {TRIGGERS.DIRECT_ORDER.toLowerCase() === 'direct_order'
              ? 'Direct Order'
              : TRIGGERS.DIRECT_ORDER}
          </div>
        </Popover>
      </React.Fragment>
    );
  }

  renderDirectOrder(index, helpIcon, removeBut) {
    if (this.props.store.rule.tr === TRIGGERS.DIRECT_ORDER) {
      return (
        <DirectOrder
          store={this.props.store}
          id={this.props.id}
          parent={this}
          index={0}
          sequenceIndex={index}
          removeBut={removeBut}
          condition_index={0}
          helpIcon={helpIcon}
        />
      );
    }

    return null;
  }

  renderTimeTriggerPeriod(index, helpIcon, removeBut) {
    if (this.props.store.rule.tr === TRIGGERS.TIME) {
      return (
        <Popover text='Choose Execution Interval'>
          <Time store={this.props.store} helpIcon={helpIcon} removeBut={removeBut} />
        </Popover>
      );
    }

    return null;
  }

  renderTriggerTitle() {
    let triggerTitle = 'If';

    if (this.props.store.rule.tr === TRIGGERS.TIME) {
      triggerTitle = 'Schedule';
    } else if (this.props.store.rule.tr === TRIGGERS.DIRECT_ORDER) {
      triggerTitle = 'Place';
    }

    return triggerTitle;
  }

  render() {
    const help = (
      <Container className={`helpTooltip`}>
        <div className={`helpTooltip__title`}>Choose a Trigger</div>
        <div>
          <p>
            Choose "Event" to build a rule based on a condition such as price decreasing /
            increasing or a certain price being reached.
          </p>
        </div>
        <div>
          <p>
            The "Time" option allows you to schedule your trades around a specific time interval.
            Eg. if you select 'Every 12 Hours', the same order will be sent every 12 hours to the
            market.
          </p>
        </div>
        <div>“Direct Orders” will be executed on the market immediately.</div>
      </Container>
    );

    const helpIcon = (
      <div className={`newRuleFormSection__helpIcon`}>
        <Help message={help} />
      </div>
    );

    return (
      <div
        className={`trigger
                        show-section
                        show-section-end
                        ${!this.props.store.rule.ex && 'show-section_unavailable'}
                        ${this.props.store.rule.tr === TRIGGERS.EVENT && 'trigger-event-block'}`}
        data-sequence={0}
        id={`sequence0`}
        onClick={() => stores.addRule.setSFPpendingSection(0)}
      >
        <div className='rule-settings-block'>
          <div className='rsb-line rsb-line_orange'> </div>
          <div className='rule-settings'>
            <Popover text='Select Event or Time Trigger'>
              <div className='title-wrapper'>
                <img src={lightning} alt='Trigger' />
                <div className='part-title title-orange'>{this.renderTriggerTitle()}</div>
              </div>
            </Popover>
            <div className='settings'>
              <div className={`newRuleTitleTabs newRuleTitleTabs_yellow`}>
                {this.renderTriggerTypeSelect()}
              </div>

              {this.renderTimeTriggerPeriod(helpIcon)}
              {this.renderDirectOrder(helpIcon)}
            </div>
          </div>
        </div>

        {this.props.store.rule.tr !== TRIGGERS.EVENT ? (
          <AddButtonsBlock
            store={this.props.store}
            id={this.props.id}
            open={true}
            type={'first'}
            sequenceIndex={null}
          />
        ) : null}
      </div>
    );
  }
}

export default Trigger;
