import { setDataLocalStorage } from 'helpers/utils';

export const getRulesStorageLabel = (userId: string) => `RULES_POSITIONS_${userId}`;

export const saveRulesPositions = (rules: Record<string, any>, userId: string) => {
  const items = rules.reduce(
    (acc: Record<string, object>, rule: Record<string, any>, index: number) => {
      acc[rule.id] = { position: index };

      return acc;
    },
    {}
  );

  setDataLocalStorage(getRulesStorageLabel(userId), items);
};
