import React from 'react';
import { Popconfirm, Button } from 'antd';

import stores from 'stores';

interface ITemplateDeleteButtonProps {
  templateName: string;
}

export const TemplateDeleteButton = (props: ITemplateDeleteButtonProps) => {
  const deleteTemplate = async () => {
    await stores.templatesStore.deleteTemplate(props.templateName);
    setTimeout(() => window.location.reload(), 500); // refresh the page after one second
  };

  return (
    <Popconfirm
      title='Are you sure you want to delete this template for all users?'
      onConfirm={deleteTemplate}
    >
      <Button type='danger'>Delete</Button>
    </Popconfirm>
  );
};
