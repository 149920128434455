import React, { useState } from 'react';
import ClosingIcon from '../../../../assets/images/datastudio/close.png';
import NewsShareIcons from '../NewsShareIcons/index';
import { Modal, Divider } from 'antd';
import { formatDate } from 'helpers/utils';

const NewsItem = ({ newsItem, elementRef }) => {
  const { thumbnail, title, author, pubDate, link, magazine } = newsItem || {};

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className='newsStyling' ref={elementRef}>
      <div className={'news-cover'}>
        <img src={thumbnail} className='newsImg' alt={title} />
      </div>
      <div style={{ paddingLeft: '5px', paddingRight: '5px' }}>
        <div role='button' onClick={showModal} className='news__title'>
          {title}
        </div>

        <div style={{ margin: '4px 0' }}>
          <span className='news__magazine'>{magazine}</span>
          <span className='news__time'>{formatDate(pubDate)}</span>
        </div>

        <p className='news__author'>{author}</p>
      </div>

      <Divider style={{ backgroundColor: '#40434a', marginTop: '0px' }} />

      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        centered={true}
        closeIcon={<img src={ClosingIcon} alt='Close Icon' />}
        className='PaymentGate news-modal'
        bodyStyle={{
          backgroundColor: '#1C2237',
          height: '100%',
          marginTop: '50px',
          borderRadius: '10px',
        }}
        maskStyle={{
          background: 'rgba(255, 255, 255, 0.2)',
        }}
      >
        <h1 style={{ float: 'left', color: 'white', fontWeight: '600', fontSize: '18px' }}>
          Coinrule News
        </h1>
        <iframe
          src={link}
          key={title}
          title='iframe'
          style={{ width: '100%', height: '600px' }}
        ></iframe>

        <NewsShareIcons {...newsItem} />
      </Modal>
    </div>
  );
};

export default NewsItem;
