import type { TAction } from 'v2/types/TAction'
import type { DTOFetchConnectExchangeBinanceOAuthPrepare } from './DTOs/DTOFetchConnectExchangeBinanceOAuthPrepare'
import type { DTOErrorFetchConnectExchangeBinanceOAuthPrepare } from './DTOs/DTOErrorFetchConnectExchangeBinanceOAuthPrepare'

export enum EDomainActionsConnectExchangeBinanceOAuthPrepare {
  PREPARE_FETCH_STARTED = 'domains/connectExchangeBinanceOAuth/prepare/FETCH_STARTED',
  PREPARE_FETCH_SUCCEEDED = 'domains/connectExchangeBinanceOAuth/prepareFETCH_SUCCEEDED',
  PREPARE_FETCH_FAILED = 'domains/connectExchangeBinanceOAuth/prepare/FETCH_FAILED',
  PREPARE_FETCH_BOUNCED = 'domains/connectExchangeBinanceOAuth/prepare/FETCH_BOUNCED',
}

export type TDomainActionsConnectExchangeBinanceOAuthPrepare = {
  [EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_STARTED]: TAction<EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_STARTED>,
  [EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_SUCCEEDED]: TAction<EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_SUCCEEDED, { dto: DTOFetchConnectExchangeBinanceOAuthPrepare }>,
  [EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_FAILED]: TAction<EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_FAILED, { error: DTOErrorFetchConnectExchangeBinanceOAuthPrepare }>,
  [EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_BOUNCED]: TAction<EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_BOUNCED, { reason: string }>,
}

export type TDomainActionConnectExchangeBinanceOAuthPrepare = TDomainActionsConnectExchangeBinanceOAuthPrepare[keyof TDomainActionsConnectExchangeBinanceOAuthPrepare]

export const createActionFetchConnectExchangeBinanceOAuthPrepareStated = (): TDomainActionsConnectExchangeBinanceOAuthPrepare[EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_STARTED] => ({
  type: EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_STARTED
})

export const createActionFetchConnectExchangeBinanceOAuthPrepareSucceeded = ({ dto }: { dto: DTOFetchConnectExchangeBinanceOAuthPrepare }): TDomainActionsConnectExchangeBinanceOAuthPrepare[EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_SUCCEEDED] => ({
  type: EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_SUCCEEDED,
  payload: {
    dto
  }
})

export const createActionFetchConnectExchangeBinanceOAuthPrepareFailed = ({ error }: { error: DTOErrorFetchConnectExchangeBinanceOAuthPrepare }): TDomainActionsConnectExchangeBinanceOAuthPrepare[EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_FAILED] => ({
  type: EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_FAILED,
  payload: {
    error
  }
})

export const createActionFetchConnectExchangeBinanceOAuthPrepareBounced = ({ reason }:  { reason: string }): TDomainActionsConnectExchangeBinanceOAuthPrepare[EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_BOUNCED] => ({
  type: EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_BOUNCED,
  payload: {
    reason
  }
})

export const actionCreatorsConnectExchangeBinanceOAuthPrepare = {
  [EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_STARTED]: createActionFetchConnectExchangeBinanceOAuthPrepareStated,
  [EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_SUCCEEDED]: createActionFetchConnectExchangeBinanceOAuthPrepareSucceeded,
  [EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_FAILED]: createActionFetchConnectExchangeBinanceOAuthPrepareFailed,
  [EDomainActionsConnectExchangeBinanceOAuthPrepare.PREPARE_FETCH_BOUNCED]: createActionFetchConnectExchangeBinanceOAuthPrepareBounced,
}
