import React from 'react'
import clsx from 'clsx'

import type { TypedUseSelectorHook } from 'react-redux';
import binanceCardBg from '../../../../assets/images/ExchangePage/binanceCardBg.svg'

import type { TDomainStateConnectExchangeBinanceOAuthPrepare } from 'v2/domains/connectExchangeBinanceOAuth/stateFetchPrepare';
import type { TDomainUseCasesConnectExchangeBinanceOAuthPrepare } from 'v2/domains/connectExchangeBinanceOAuth/createUseCasesFetchPrepare';
import { PromoCard } from 'design-system';

export interface ViewConnectExchangeBinanceOAuthPrepareProps extends React.HTMLProps<HTMLDivElement> { }

const testId = 'ViewConnectExchangeBinanceOAuthPrepare'
export const createViewConnectExchangeBinanceOAuthPrepare = ({
  useCasesConnectExchangeBinanceOAuth,
  useSelector,
}: {
  useCasesConnectExchangeBinanceOAuth: TDomainUseCasesConnectExchangeBinanceOAuthPrepare
  useSelector: TypedUseSelectorHook<TDomainStateConnectExchangeBinanceOAuthPrepare>
}) => {
  const ViewConnectExchangeBinanceOAuthPrepare = (props: ViewConnectExchangeBinanceOAuthPrepareProps) => {
    const {
      ...otherProps
    } = props

    return (
      <div
        data-testid={testId}
        className={clsx(testId)}
        {...otherProps}
      >
        <PromoCard
          onClick={() => useCasesConnectExchangeBinanceOAuth.prepare()}
          buttonLabel={'Connect'}
          bgImage={binanceCardBg}
          showInfoTooltip={true}
          tooltipText={'"Binance One-Click" allows you to connect your binance account quickly and safely. Please notice: If you use the "Binance One-Click" connect and at some point delete the exchange from Coinrule, you won’t be able to connect it again, unless you delete and create a new API key on Binance.'}
        />
      </div>
    )
  }

  return ViewConnectExchangeBinanceOAuthPrepare
}

export default createViewConnectExchangeBinanceOAuthPrepare
