import { getOnboardingClosedKey, USER_ONBOARDING_TEMPLATE } from 'helpers/constants';
import { action, observable } from 'mobx';
import {
  getOnboardingModalLabel,
  getOnboardingStep,
  oldOnboardingData,
  ONBOARDING_TEMPLATES_DATA,
} from 'pages/RulesDashboardPage/Onboarding/utils';
import stores from 'stores';
import http from '../helpers/http';
import { errorNotification } from '../helpers/notification';
import { getDataLocalStorage, setDataLocalStorage } from '../helpers/utils';
import { AbstractStore } from './AbstractStore';
export default class UserSurveyStore extends AbstractStore {
  constructor(rootStore) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  @observable quizData = [];
  @observable quizStep = 0;

  @observable onboardingCompleted = false;

  @observable showOnboardingModal = false;
  @observable quizSpinner = false;
  @observable isLoading = false;
  @observable isExchangesBannersShowed = false;
  @observable successPopupState = {
    show: false,
    index: undefined,
  };

  @action
  updateSuccessPopupState = (updateObj = {}) => {
    this.successPopupState = {
      ...this.successPopupState,
      ...updateObj,
    };
  };
  @observable onboardingVariant = '';
  @observable onboardngTemplates = ONBOARDING_TEMPLATES_DATA;

  @action
  setOnboardingVariant = (variant) => {
    this.onboardingVariant = variant;
  };

  @action
  setOnboardingModalVisibility = (visible) => {
    const { getUserId } = stores.user;
    const onboardingClosed = getDataLocalStorage(getOnboardingClosedKey(getUserId()));

    if (visible === false) {
      this.showOnboardingModal = visible;
      setDataLocalStorage(getOnboardingClosedKey(getUserId()), true);

      return;
    }

    if (!onboardingClosed) this.showOnboardingModal = true;
  };

  @action
  toggleExchangeBanner(whatToDo) {
    if (whatToDo === 'show') {
      this.isExchangesBannersShowed = true;
    } else if (whatToDo === 'hide') {
      this.isExchangesBannersShowed = false;
    }
  }

  @action
  setQuizData = (data) => {
    this.quizData = data;
  };

  shouldLoadOnboardingOnStart = (loginCount, userId) => {
    const label = getOnboardingModalLabel(userId, loginCount);
    const hasSeenOnboardingOnThisLogin = getDataLocalStorage(label);

    if (!hasSeenOnboardingOnThisLogin && loginCount <= 2) {
      setDataLocalStorage(label, (hasSeenOnboardingOnThisLogin || 0) + 1);
      return true;
    }

    return false;
  };

  @action
  loadOnboardingOnStart = (onboardingCompleted, quizData) => {
    this.onboardingCompleted = onboardingCompleted;

    if (
      onboardingCompleted ||
      !this.shouldLoadOnboardingOnStart(stores.user.loginCount, stores.user.getUserId())
    )
      return;

    if (quizData && quizData.length) {
      const { s: currentStep } = quizData[quizData.length - 1];

      this.setQuizData(quizData);
      this.setQuizStep(currentStep);
    }

    this.setOnboardingModalVisibility(true);
  };

  getQuizDataIndex = (step = this.quizData) => {
    if (!this.quizData.length) return;

    return this.quizData.findIndex((quiz) => quiz.s === step);
  };

  getQuizStepData = (step = this.quizStep) => {
    if (!this.quizData.length) return;

    const currentIndex = this.getQuizDataIndex(step);
    return this.quizData[currentIndex];
  };

  @action
  deleteQuizDataAnswer = (step, variant = this.onboardingVariant) => {
    const quizStepData = this.getQuizStepData(step);
    const onboardingData =
      variant === 'old onboarding'
        ? oldOnboardingData.steps[step]
        : getOnboardingStep(variant)[step];

    const { s, a } = quizStepData || {};

    if (!a?.question || onboardingData?.name === a?.question) return;

    /**
     * Delete existing answer for the step if the question is not the same
     */
    this.quizData[this.getQuizDataIndex(step)] = { s };
  };

  @action
  addQuizData = (step, answer) => {
    const existingQuizItem =
      this.quizData.length && this.quizData.find((quizItem) => quizItem.s === step);

    if (existingQuizItem) {
      this.quizData[this.getQuizDataIndex(step)] = { s: step, a: answer };
    } else {
      this.quizData.push({ s: step, a: answer });
    }
  };

  @action
  setQuizStep = (step) => {
    return (this.quizStep = step);
  };

  @action
  onForward = (variant = this.onboardingVariant) => {
    if (this.quizStep === getOnboardingStep(variant).length - 1)
      return this.setOnboardingModalVisibility(false);

    const nextStep = this.quizStep + 1;

    this.setQuizStep(nextStep);
  };

  @action
  onPrevious = (variant = this.onboardingVariant) => {
    const prevStep = this.quizStep - 1;
    this.setQuizStep(prevStep);
  };

  @action
  showQuizSpinner(show) {
    this.quizSpinner = show;
  }

  saveSelectedTemplate = (template) => {
    if (template) setDataLocalStorage(USER_ONBOARDING_TEMPLATE, template);
  };

  @action
  changeUserSurvey = async (filled) => {
    if (this.isLoading) {
      return;
    }

    try {
      this.isLoading = true;

      const resp = await http.post('/user/survey', { filled, answers: this.quizData });

      this.isLoading = false;

      if (resp.data) {
        if (resp.data.status !== 'OK') {
          errorNotification(resp.data.message);
          throw new Error(resp.data.message);
        }
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Unable to submit your answer at the moment. Please try it again later.`);
      this.isLoading = false;
      throw new Error(error);
    }
  };

  getOnboardingTemplatesBasedOnExperience = (experience = 'beginner') => {
    return this.onboardngTemplates[experience];
  };

  getTemplateDetails = (experience, templateName) => {
    if (!templateName) return;

    return this.onboardngTemplates[experience].find(
      (template) => templateName === template.templateName
    );
  };
}
