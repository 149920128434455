import type { TAction } from "v2/types/TAction";
import type { DTOErrorCreateRuleSlug } from "./DTOs/DTOErrorCreateRuleSlug";
import type { DTOShareRuleSlug } from "./DTOs/DTOShareRuleSlug";

export enum EDomainActionsShareRule {
  FETCH_STARTED = 'domains/shareRule/FETCH_STARTED',
  FETCH_SUCCEEDED = 'domains/shareRule/FETCH_SUCCEEDED',
  FETCH_FAILED = 'domains/shareRule/FETCH_FAILED',
  FETCH_BOUNCED = 'domains/shareRule/FETCH_BOUNCED',
}

export type TDomainActionsShareRule = {
  [EDomainActionsShareRule.FETCH_STARTED]: TAction<EDomainActionsShareRule.FETCH_STARTED>,
  [EDomainActionsShareRule.FETCH_SUCCEEDED]: TAction<EDomainActionsShareRule.FETCH_SUCCEEDED, { shareRuleSlug: DTOShareRuleSlug }>,
  [EDomainActionsShareRule.FETCH_FAILED]: TAction<EDomainActionsShareRule.FETCH_FAILED, { error: DTOErrorCreateRuleSlug }>,
  [EDomainActionsShareRule.FETCH_BOUNCED]: TAction<EDomainActionsShareRule.FETCH_BOUNCED, { reason: string }>,
}

export type TDomainActionShareRule = TDomainActionsShareRule[keyof TDomainActionsShareRule]

export const createActionFetchShareRuleStated = (): TDomainActionsShareRule[EDomainActionsShareRule.FETCH_STARTED] => ({
  type: EDomainActionsShareRule.FETCH_STARTED
})

export const createActionFetchShareRuleSucceeded = ({ shareRuleSlug }: { shareRuleSlug: DTOShareRuleSlug }): TDomainActionsShareRule[EDomainActionsShareRule.FETCH_SUCCEEDED] => ({
  type: EDomainActionsShareRule.FETCH_SUCCEEDED,
  payload: {
    shareRuleSlug
  }
})

export const createActionFetchShareRuleFailed = ({ error }: { error: DTOErrorCreateRuleSlug }): TDomainActionsShareRule[EDomainActionsShareRule.FETCH_FAILED] => ({
  type: EDomainActionsShareRule.FETCH_FAILED,
  payload: {
    error
  }
})

export const createActionFetchShareRuleBounced = ({ reason }:  { reason: string }): TDomainActionsShareRule[EDomainActionsShareRule.FETCH_BOUNCED] => ({
  type: EDomainActionsShareRule.FETCH_BOUNCED,
  payload: {
    reason
  }
})

export const actionCreatorsShareRule = {
  [EDomainActionsShareRule.FETCH_STARTED]: createActionFetchShareRuleStated,
  [EDomainActionsShareRule.FETCH_SUCCEEDED]: createActionFetchShareRuleSucceeded,
  [EDomainActionsShareRule.FETCH_FAILED]: createActionFetchShareRuleFailed,
  [EDomainActionsShareRule.FETCH_BOUNCED]: createActionFetchShareRuleBounced,
}