// @FIXME this file should be moved to v2 as the use case when the login logic will be moved there

import type { History } from 'history'

const SHARE_RULE_SLUG_TO_REDIRECT_LOCAL_STORAGE_KEY = 'shareRuleSlugToRedirect'

export const removeLocalStorageData = () => localStorage.removeItem(SHARE_RULE_SLUG_TO_REDIRECT_LOCAL_STORAGE_KEY)

export const setRedirectionBackToSharedRule = ({ slug }: { slug: string }) => {
  // remember the shared rule slug to allow redirect back here after login
  localStorage.setItem(SHARE_RULE_SLUG_TO_REDIRECT_LOCAL_STORAGE_KEY, slug)

  // remove the redirection if user close the window/tab so if the site will be revisited in other place no redirection will take place
  // and keep only one listener registered
  window.removeEventListener('beforeunload', removeLocalStorageData)
  window.addEventListener('beforeunload', removeLocalStorageData)
}

export const getShareRuleSlug = (): string | null => {
  const slug = localStorage.getItem(SHARE_RULE_SLUG_TO_REDIRECT_LOCAL_STORAGE_KEY)
  return slug || null
}

export const redirectionBackToSharedRule = ({ history, makePath }: { history: History, makePath: (slug: string) => string }) => {
  const slug = getShareRuleSlug()
  if(!slug) return
  history.push(makePath(slug))
}