import { action, observable } from 'mobx';
import http from '../helpers/http';
import { errorNotification } from '../helpers/notification';
import stores from './index';
import {
  
  getDataLocalStorage,
  
  STATUSES,
} from '../helpers/utils';
import {calculateTotalAmountByExchange, sortAndCalculatePriceWallets} from 'helpers/conversion'
import { EXCHANGES_TYPE } from '../constants/exchanges';
import { getRulesStorageLabel } from 'pages/RulesDashboardPage/RuleList/Utils';
import { AbstractStore } from './AbstractStore';
export default class UserInfoStore extends AbstractStore {

  constructor(rootStore) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  @observable exchanges = [];
  @observable isLoadingExchanges = false;
  @observable isLoadedExchanges = false;
  @observable hasRealExchange = false;

  @action
  async getExchanges(force = false) {
    try {
      if (!this.isLoadingExchanges && (!this.isLoadedExchanges || force)) {
        this.isLoadingExchanges = true;
        const resp = await http.get('/user/exchanges');
        this.isLoadingExchanges = false;

        if (resp.data) {
          if (resp.data.status === 'OK') {
            this.exchanges = resp.data.data;
            const exLen = this.exchanges.length;

            if (exLen > 1) {
              stores.walletsStores.setVisibilityDemoExchange(false);
            }

            for (let i = 0; i < exLen; i++) {
              if (this.exchanges[i].uid !== 'demo') {
                this.hasRealExchange = true;
              }
            }

            this.isLoadedExchanges = true;
          } else {
            errorNotification(resp.data.message);
          }

          return resp.data.authorized;
        } else {
          throw new Error('Invalid response ' + resp.toString());
        }
      } else {
        return;
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoadingExchanges = false;
    }
  }

  getDemoExchange = () => {
    return this.exchanges?.find((exchange) => exchange?.uid === 'demo');
  };

  @action
  getExchangeByUID = (uid) =>
    this.exchanges?.length && this.exchanges.find((exchange) => exchange.uid === uid);

  @observable previousBalances;
  @observable balances = {};
  @observable openPositions = {};
  @observable totalBalances = {};
  @observable isLoadingBalances = false;
  @observable isLoadedBalances = [];
  @observable sortedBalances = {};

  @action
  setTotalBalance(exchangeId, fiatValue) {
    this.totalBalances[exchangeId] = fiatValue;
  }

  @action
  setBalances(exchangeId, sortedBalances) {
    this.sortedBalances[exchangeId] = sortedBalances;
  }

  getRulesBasedOnStatus = (status) => {
    switch (status) {
      case STATUSES.ACTIVE:
        return this.rules?.filter((rule) => rule.s === STATUSES.ACTIVE);

      default:
        return this.rules;
    }
  };

  @action
  async getBalances(exchangeId, force = false, newCurrency = '') {
    if (newCurrency === stores.user?.user?.user?.baseCurrency) {
      newCurrency = '';
    }

    this.previousBalances = this.balances;

    if (!force) {
      if (this.isLoadingBalances) {
        return;
      }
    }

    try {
      if (this.isLoadedBalances.indexOf(exchangeId + newCurrency) < 0 || force) {
        this.isLoadingBalances = true;

        const resp = await http.get(`/user/balances?exchange_id=${exchangeId}`);

        this.isLoadingBalances = false;
        const walletStores = stores.walletsStores;

        if (resp.data) {
          if (resp.data.status === 'OK') {
            if (
              !walletStores.syncExchangeBalancesDone.includes(exchangeId + newCurrency) &&
              walletStores.syncUpdateRunning
            ) {
              walletStores.addToSyncExchangeBalancesDone(exchangeId + newCurrency);

              if (
                (walletStores.syncExchangeBalancesDone.length ===
                  stores.userInfo.exchanges.length) ===
                walletStores.syncExchangeAssetsDone.length
              ) {
                walletStores.changeSyncUpdateStatus(false);
              }
            }

            if (resp.data.data.isLeverage) {
              this.balances[exchangeId + newCurrency] = resp.data.data.futures;
              this.openPositions[exchangeId + newCurrency] = resp.data.data.positions;
            } else {
              this.balances[exchangeId + newCurrency] = resp.data.data.spot;
            }

            const exchangeIdCurrency = exchangeId + newCurrency;
            this.isLoadedBalances.push(exchangeIdCurrency);

            const results = calculateTotalAmountByExchange({
              exchangeId: exchangeIdCurrency,
              force: true,
              wallets: stores.userInfo.balances[exchangeIdCurrency]?.data,
              assets: stores.exchangeInfo.assets[exchangeIdCurrency],
              instruments: stores.exchangeInfo.instruments[exchangeIdCurrency],
              exchangeType: stores.exchangeInfo.exchangeType[exchangeIdCurrency],
              totalBalance: stores.userInfo.totalBalances[exchangeIdCurrency],
              currencies: stores.info.currencies,
              baseCurrency: stores.user.user.user.baseCurrency,
              tempCurrency: newCurrency || stores.addRule.tempCurrency,
            });

          if(results) {
            const {balances, totalBalance} = results;
            stores.userInfo.setTotalBalance(exchangeIdCurrency, totalBalance);
            stores.userInfo.setBalances(exchangeIdCurrency, balances);

          }
          } else {
            errorNotification(resp.data.message);
          }

          return resp.data.authorized;
        } else {
          throw new Error('Invalid response ' + resp.toString());
        }
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoadingBalances = false;
    }
  }

  @observable rules = [];
  @observable loadingRules = {};
  @observable isLoadingRules = false;
  @observable isLoadedRules = false;
  @observable activeFilter = 'active';
  @observable isLoadingFilterRules = false;
  @observable isLoadingFilterRulesNextPage = false;
  @observable activeFilterPage = 1;
  @observable totalRules = 0;
  @observable totalFilterPages = 0;
  @observable totalActiveRules = 0;
  @observable rulesSync = [];
  @observable activeLiveRules = 0;
  @observable activeDemoRules = 0;
  numberOfAutocreatedRules = 0;

  @action
  ruleUpdated(id) {
    this.loadingRules[id] = true;
  }

  @action
  resetFilter(resetTo = 'active') {
    this.activeFilter = resetTo;
    this.activeFilterPage = 1;
    this.totalFilterPages = 0;
    this.totalRules = 0;
    this.totalActiveRules = 0;
    this.totalActiveDemoRules = 0;
  }

  @action
  setRules = (rules) => (this.rules = rules);

  @action
  reOrderRules = ({ source, destination, ruleId }) => {
    if (!this.rules.length) return;

    let newRules = [...this.rules];

    const rule = newRules.find((rule) => rule.id === ruleId);

    newRules.splice(source, 1);
    newRules.splice(destination, 0, rule);

    this.setRules(newRules);

    return newRules;
  };

  @action
  sortRules = (rules = this.rules) => {
    const rulesPositions = getDataLocalStorage(getRulesStorageLabel(stores.user.getUserId()));

    let sortedRules = rules;

    if (rulesPositions)
      sortedRules = sortedRules.sort((a, b) => {
        if (rulesPositions[a.id]?.position < rulesPositions[b.id]?.position) return -1;
        if (rulesPositions[a.id]?.position > rulesPositions[b.id]?.position) return 1;
        return 0;
      });

    return sortedRules;
  };

  @observable activeLiveRules = [];

  @action
  getActiveLiveRules = async () => {
    try {
      const response = await http.get(`/rules?type=active&paginate=false`);

      if (!response.data) throw new Error('Invalid response ' + response.toString());
      if (response.data.status !== 'OK') return errorNotification(response.data.message);

      this.activeLiveRules = this.getLiveRules(response.data.data);
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
    }
  };

  getLiveRules = (rules = []) => {
    if (!rules?.length) return [];

    return rules.filter((rule) => !rule.de);
  };

  @action
  async getRules(force = false, filtering = null, nextPage = 0) {
    if (this.isLoadingRules) {
      return;
    }

    if (filtering) {
      if (this.activeFilter !== filtering) {
        this.activeFilterPage = 1;
      }

      this.isLoadingFilterRules = true;
      this.activeFilter = filtering;
    }

    if (nextPage) {
      this.activeFilterPage++;
      this.isLoadingFilterRulesNextPage = true;
    }

    try {
      if (!this.isLoadedRules || force) {
        this.isLoadingRules = !this.isLoadedRules;

        const rulesLink = '/rules?type=' + this.activeFilter + '&page=' + this.activeFilterPage;

        const resp = await http.get(rulesLink);

        this.isLoadingRules = false;
        this.isLoadingFilterRules = false;
        this.isLoadingFilterRulesNextPage = false;

        if (resp.data) {
          if (resp.data.status === 'OK') {
            this.rules = this.sortRules(
              this.activeFilterPage > 1 ? this.rules.concat(resp.data.data) : resp.data.data
            );

            this.totalFilterPages = resp.data.totalPages;

            this.totalRules = this.rules.length;

            this.totalActiveRules = resp.data.totalActiveRules;
            this.totalActiveDemoRules = resp.data.totalActiveDemoRules;

            if (this.activeFilter === 'all') {
              this.numberOfAutocreatedRules = this.rules.filter((rule) => rule.au).length;

              if (this.rules.length > 0) {
                const rulesSync = [];

                for (const i in this.rules) {
                  if (this.rules[i].ia) {
                    rulesSync.push(this.rules[i].id);
                  }
                }

                this.rulesSync = rulesSync;
              }
            }

            if (!stores.user.hasRule && this.totalActiveRules > 0) {
              stores.user.setHasRule(true);
            }

            const growth = (resp.data.profit / stores.walletsStores.totalWalletsValue) * 100;

            stores.userRules.setNetProfit(resp.data.profit);
            stores.userRules.setNetGrowth(Math.abs(growth) === Infinity ? 0 : growth);
            stores.userRules.setSince(resp.data.since);

            this.isLoadedRules = true;
          } else {
            errorNotification(resp.data.message);
          }

          return resp.data.authorized;
        } else {
          throw new Error('Invalid response ' + resp.toString());
        }
      }
      this.activeLiveRules = this.rules.filter(
        (rule) => rule.s === STATUSES.ACTIVE && !rule.de
      ).length;
      this.activeDemoRules = this.rules.filter(
        (rule) => rule.s === STATUSES.ACTIVE && rule.de
      ).length;
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoadingRules = false;
    }
  }

  @action
  setBalance(exchangeId, asset, amount) {
    if (exchangeId && this.balances[exchangeId]) {
      const exchangeType = stores.exchangeInfo.exchangeType[exchangeId];
      const i = this.balances[exchangeId].data.findIndex((obj) => obj.asset === asset);

      if (i === -1 || !this.balances[exchangeId].data[i]) {
        this.balances[exchangeId].data.push({
          asset,
          balance: amount,
        });
      } else {
        this.balances[exchangeId].data[i].balance += amount;
      }

      // sortAndCalculatePriceWallets(
      //   this.balances[exchangeId].data,
      //   exchangeType === EXCHANGES_TYPE.LEVERAGE
      //     ? stores.exchangeInfo.instruments[exchangeId]
      //     : stores.exchangeInfo.assets[exchangeId],
      //   exchangeId
      // );

        const results = sortAndCalculatePriceWallets({
          balances: stores.userInfo.balances[exchangeId]?.data,
          assets:exchangeType === EXCHANGES_TYPE.LEVERAGE ?  stores.exchangeInfo.instruments[exchangeId] : stores.exchangeInfo.assets[exchangeId],
          exchangeId: exchangeId,
          currencies: stores.info.currencies,
          baseCurrency: stores.user.user.user.baseCurrency,
          tempCurrency: stores.addRule.tempCurrency
        });

        if(results) {
          const {balances, totalBalance} = results;
          stores.userInfo.setTotalBalance(exchangeId, totalBalance);
          stores.userInfo.setBalances(exchangeId, balances);
        }
    }
  }

  @action
  resetExchanges() {
    this.exchanges = [];
    this.isLoadingExchanges = false;
    this.isLoadedExchanges = false;
    this.hasRealExchange = false;
  }
}
