import React, { MouseEventHandler, ReactNode } from 'react';
import './style.scss';

import { ReactComponent as ArrowUp } from 'assets/images/Onboarding/ArrowUp.svg';

export interface FooterStep {
  icon?: ReactNode;
  name: string;
}

export interface IFooterStepperProps {
  currentStepIndex: number;
  title?: string;
  steps: FooterStep[];
  handleArrowClick?: MouseEventHandler<SVGSVGElement>;
}

const FooterStepper: React.FC<IFooterStepperProps> = ({
  title,
  currentStepIndex,
  steps,
  handleArrowClick,
}) => {
  const cls = 'FooterStepper';

  const isActive = (index: number) => currentStepIndex === index || currentStepIndex > index;

  return (
    <footer className={cls}>
      {title && <p className={`${cls}__title`}>{title}</p>}

      <div className={`${cls}__steps`}>
        {steps.map(({ icon, name }, index) => (
          <div
            key={name}
            className={`${cls}__step ${isActive(index) ? `${cls}__step--active` : ''}`}
          >
            {isActive(index) && icon} {name}
          </div>
        ))}
      </div>

      {handleArrowClick && (
        <ArrowUp role='button' className={`${cls}__arrow-up`} onClick={handleArrowClick} />
      )}
    </footer>
  );
};

export default FooterStepper;
