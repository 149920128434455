import React, { Component } from 'react';
import { observer } from 'mobx-react';

import './style.scss';
import stores from '../../../stores';

const MAX_NUMBERS = 4; // characters allowed

@observer
class Pin extends Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputKeyPress = this.handleInputKeyPress.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);

    this.pin1 = React.createRef();
    this.pin2 = React.createRef();
    this.pin3 = React.createRef();
    this.pin4 = React.createRef();
  }

  handleInputChange(e) {
    const num = parseInt(e.target.getAttribute('data-num'), 32);
    const value = e.target.value.trim();
    let preparedValue = [];

    if (num >= 1 && num <= MAX_NUMBERS) {
      for (let i = 0; i < value.length; i++) {
        if (/^[0-9A-Za-z]$/.test(value[i]) && preparedValue.length < MAX_NUMBERS) {
          preparedValue.push(value[i] + '');
        }
      }

      if (preparedValue.length === MAX_NUMBERS && num === 1) {
        // insert whole code
        this.handleInputFocus(this.pin4.current);
        stores.auth.setPin(num, preparedValue);
      } else {
        // insert only first number
        const newValue = preparedValue.length > 0 ? preparedValue[0] : '';
        let nextInput;

        if (newValue) {
          nextInput = num < MAX_NUMBERS ? num + 1 : MAX_NUMBERS;
        } else if (!stores.auth[`pin${num + 1}`]) {
          nextInput = num > 1 ? num - 1 : 1;
        }

        if (nextInput) {
          this.handleInputFocus(this[`pin${nextInput}`].current);
        }

        stores.auth.setPin(num, [newValue]);
      }
    }
  }

  handleInputKeyPress(e) {
    const num = parseInt(e.target.getAttribute('data-num'), 32);

    if (e.keyCode === 37) {
      // left
      if (num > 1) {
        this.handleInputFocus(this[`pin${num - 1}`].current);
      }
    } else if (e.keyCode === 39) {
      // right
      if (num < MAX_NUMBERS) {
        this.handleInputFocus(this[`pin${num + 1}`].current);
      }
    } else if (e.keyCode === 8) {
      // backward
      if (!e.target.value && num > 1) {
        this.handleInputFocus(this[`pin${num - 1}`].current);
      }
    }
  }

  handleInputFocus(target) {
    target.focus();
    //target.setSelectionRange(0, target.value.length);
  }

  componentDidMount() {
    this.handleInputFocus(this.pin1.current);
  }

  render() {
    return (
      <div
        className='authWindow__inputWrap authWindow__inputWrap_submitPin'
      >
        <input
          type='text'
          pattern='[A-Za-z0-9]'
          data-type='submitPinInput'
          data-num='1'
          tabIndex='1'
          name='pin1'
          onChange={this.handleInputChange}
          onKeyDown={this.handleInputKeyPress}
          value={stores.auth.pin1}
          ref={this.pin1}
          autoComplete='off'
          className='authWindow__textInput
                          authWindow__textInput_submitPin'
        />

        <input
          type='text'
          pattern='[A-Za-z0-9]'
          data-type='submitPinInput'
          data-num='2'
          tabIndex='2'
          name='pin2'
          onChange={this.handleInputChange}
          onKeyDown={this.handleInputKeyPress}
          value={stores.auth.pin2}
          ref={this.pin2}
          autoComplete='off'
          className='authWindow__textInput
                          authWindow__textInput_submitPin'
        />

        <input
          type='text'
          pattern='[A-Za-z0-9]'
          data-type='submitPinInput'
          data-num='3'
          tabIndex='3'
          name='pin3'
          onChange={this.handleInputChange}
          onKeyDown={this.handleInputKeyPress}
          value={stores.auth.pin3}
          ref={this.pin3}
          autoComplete='off'
          className='authWindow__textInput
                          authWindow__textInput_submitPin'
        />

        <input
          type='text'
          pattern='[A-Za-z0-9]'
          data-type='submitPinInput'
          data-num='4'
          tabIndex='4'
          name='pin4'
          onChange={this.handleInputChange}
          onKeyDown={this.handleInputKeyPress}
          value={stores.auth.pin4}
          ref={this.pin4}
          autoComplete='off'
          className='authWindow__textInput
                          authWindow__textInput_submitPin'
        />
      </div>
    );
  }
}

export default Pin;
