export const EXCHANGES_NAME_MAP = {
  okex: 'OKX',
};

export const EXCHANGES_TYPE = {
  SPOT: 'spot',
  LEVERAGE: 'leverage',
};

export const BINANCE = 'binance';
export const BINANCE_FUTURES = 'binance-futures';
export const KRAKEN_FUTURES = 'kraken-futures';
export const BITMEX = 'bitmex';
export const OKEX = 'okex';
export const KUCOIN = 'kucoin';
