import DonwgradeBgImage from 'assets/images/downgrade.svg';
import { ReactComponent as DowngradeIcon } from 'assets/images/downgradeIcon.svg';
import { ReactComponent as SuccessIcon } from 'assets/images/success.svg';
import SuccessBgImage from 'assets/images/upgrade_success.png';
import { HtmlWrapper, TCoinruleButtonProps } from 'components/CoinruleButton';
import { SubscriptionType } from 'components/SubscriptionModal';
import { MONTH_NAMES } from 'constants/billingPage';
import { IBillingActivity } from 'pages/SettingsPage/Billing/BillingActivities';
import React from 'react';
import { Link } from 'react-router-dom';
import { CouponDTO, PlanDto } from '__generated__/ps.api.client/Api';
import { getDiscountPrice } from './coupon';
import history from './history';

export const PAYMENT_STATE = {
  SUCCEEDED: 'succeeded',
  SUCCESS: 'success',
  CANCELLED: 'cancelled',
  CREATED: 'created',
  REQUESTED: 'requested',
  ACKNOWLEDGED: 'acknowledged',
  ERROR: 'error',
};

export const CHARGEBEE_SITE_NAME = {
  TEST: 'coinrule-test',
  PROD: 'coinrule',
};

export const SELECTED_PLAN_DATA = 'SELECTED_PLAN_FOR_PAYMENT';

export const PAYMENT_GATEWAY: Record<string, TPaymentGateway> = {
  STRIPE: 'stripe',
  CHARGEBEE: 'chargebee',
};

export type TPaymentGateway = 'stripe' | 'chargebee';

export const PAYMENT_TEST_ROUTE = '/settings/ps';

export const getSubscriptionModalProps = (subscriptionType: SubscriptionType) => {
  if (subscriptionType === 'upgrade') {
    return {
      title: 'Plan Upgraded',
      description: (
        <>
          Enjoy trading with <br /> advanced <Link to='/rule'>Strategies</Link> and Indicators
        </>
      ),
      image: SuccessBgImage,
      icon: SuccessIcon,
      buttonProps: getSubscriptionModalButtonProps(subscriptionType),
    };
  }

  return {
    title: 'Plan Downgraded',
    description: (
      <>
        You will loose access to some <br /> advanced features
      </>
    ),
    image: DonwgradeBgImage,
    icon: DowngradeIcon,
    buttonProps: getSubscriptionModalButtonProps(subscriptionType),
  };
};

export const getSubscriptionModalButtonProps = (
  subscriptionType: SubscriptionType
): TCoinruleButtonProps<HtmlWrapper> => {
  const handleClick = (path: string) => {
    history.push(path);
    // setTimeout(reload);
  };

  if (subscriptionType === 'upgrade')
    return {
      children: 'Create Rule',
      onClick: () => handleClick('/rule'),
    };

  return {
    children: 'Keep My Plan',
    onClick: () => handleClick('/settings/billing'),
  };
};

export const formatDate = (date: Date) => {
  return `${date.getDate()} ${MONTH_NAMES[date.getMonth()]}, ${date.getFullYear()}`;
};

export interface IGetBillingActivities {
  user: Record<string, any>;
  rules: { active: number; total: number };
  monthlyTraded?: { value: number; total: number };
  upgradePlan: () => void;
  cancelPlan: () => void;
  keepPlan: () => void;
}

export const getBillingActivities = (props: IGetBillingActivities): IBillingActivity[] => {
  const { user, rules, monthlyTraded, upgradePlan, cancelPlan, keepPlan } = props || {};
  const { plan, planEnds: billingDate, cancelledPlan } = user;

  const shouldUpgrade = plan?.clearance < 3;
  const shouldCancel = plan?.clearance > 0;

  const getPlanName = (plan: Record<string, any>) => {
    if (plan?.clearance === 0) return 'Free';

    return plan?.name;
  };

  const getBillingDateObj = (billingDate: string) => {
    const date = billingDate && formatDate(new Date(billingDate));

    const billingDateMap: Record<string, IBillingActivity> = {
      'keep plan': {
        title: 'Expiring on',
        cls: 'cancelled',
        value: date,
        buttons: [{ text: 'Keep Plan', onClick: keepPlan, bemCls: 'active' }],
      },

      'cancel plan': {
        title: 'Billing Date',
        value: date,
        buttons: [{ text: 'Cancel', onClick: cancelPlan, bemCls: 'cancel' }],
      },

      'do nothing': {
        title: '',
        value: '',
        buttons: [{ text: '', onClick: cancelPlan, bemCls: 'hidden' }],
      },
    };

    let action = 'do nothing';
    if (shouldCancel && !cancelledPlan) action = 'cancel plan';
    if (cancelledPlan) action = 'keep plan';
    if (!date) action = 'do nothing';

    return billingDateMap[action];
  };

  return [
    {
      title: 'Current Plan',
      value: getPlanName(plan),
      buttons: [
        ...(shouldUpgrade ? [{ text: 'Upgrade', onClick: upgradePlan, bemCls: 'active' }] : []),
        ...(shouldCancel && !billingDate
          ? [{ text: 'Cancel', onClick: cancelPlan, bemCls: 'cancel' }]
          : []),
      ],
    },

    {
      title: 'Active Rules',
      value: (
        <>
          <span className='bold'>{rules?.active || 0}</span>
          <span className='light'>/{rules?.total || 0}</span>
        </>
      ),
    },

    ...(monthlyTraded
      ? [
        {
          title: 'Monthly Traded',
          value: (
            <>
              <span>${monthlyTraded.value}</span>
              <span className='light'>/${monthlyTraded.total}</span>
            </>
          ),
        },
      ]
      : []),

    getBillingDateObj(billingDate),
  ];
};


export interface IPlan extends Omit<PlanDto, 'price' | 'annualPrice'> {
  price: number;
  quarterlyPrice: number;
  annualPrice: number;
}
interface IGetCouponProps {
  coupon?: CouponDTO;
  plan: IPlan;
  period: 'yearly' | 'monthly' | 'quarterly';
}

export const getPlanPriceData = ({ coupon, plan, period }: IGetCouponProps) => {
  const { annualPrice, price: monthlyPrice, quarterlyPrice } = plan;
  const { discount_percentage, discount_amount } = coupon || {};

  const isYearly = period === 'yearly';
  const isQuarterly = period === 'quarterly' && quarterlyPrice;

  const getMonthlyPrice = () => {
    let price = monthlyPrice;
    if (isYearly)  price = annualPrice / 12;
    if(isQuarterly) price = quarterlyPrice / 3;
  
    return Math.floor(Number(price.toString().split('.')[0]));
  };

  const discountQuarterlyPrice = getDiscountPrice({
    price: quarterlyPrice,
    discount_amount,
    discount_percentage,
  });

  const getMonthlyDiscountPrice = () => {
    let monthlyDiscountPrice = getDiscountPrice({
      price: monthlyPrice,
      discount_percentage,
      discount_amount,
    });

    if (isYearly) monthlyDiscountPrice = discountAnnualPrice / 12;
    if(isQuarterly) monthlyDiscountPrice = discountQuarterlyPrice / 3;

    return Math.floor(monthlyDiscountPrice);
  };

  const discountAnnualPrice = getDiscountPrice({
    price: annualPrice,
    discount_amount,
    discount_percentage,
  });

  const getAnnualPrice = () => {
    const amount = coupon ? discountAnnualPrice : annualPrice;

    return Math.floor(amount);
  };

  const getQuarterlyPrice = () => {
    const amount = coupon ? discountQuarterlyPrice : quarterlyPrice;

    return Math.floor(amount);
  }

  const getAmountToPay = () => {
    let amountToPay = getAnnualPrice();

    if (!isYearly) {
      if (coupon) amountToPay = getMonthlyDiscountPrice();
      else amountToPay = getMonthlyPrice();
    }

    return amountToPay;
  };

  return {
    monthlyPrice: getMonthlyPrice(),
    discountMonthlyPrice: getMonthlyDiscountPrice(),
    discountAnnualPrice,
    annualPrice: getAnnualPrice(),
    quarterlyPrice: getQuarterlyPrice(),
    // Final amount paid based on discount/no discount
    amountToPay: getAmountToPay(),
  };
};
