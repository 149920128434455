export enum ENotifyChannel {
  TELEGRAM = 'telegram',
  EMAIL = 'email',
}

export enum ENotifyTimeframe {
  INSTANTLY = 'instantly',
  HOURLY = 'hourly',
  DAILY = 'daily',
}

export type Notify = {
  address: string;
  do: 'NOTIFY';
  channel: ENotifyChannel;
  timeframe: ENotifyTimeframe;
};
