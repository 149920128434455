import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { observer } from 'mobx-react';

import {
  ACTION_TYPE,
  CONDITION_TYPE,
  OPERATOR_TYPE,
  TRIGGERS,
  WAIT_OPTIONS,
} from '../../../stores/addRule';
import stores from '../../../stores';
import Popover from '../../../components/Popover';
import './style.scss';

@observer
class AddButtonsBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isButtonBlockShown: true,
      showActionButtons: true,
    };

    if (this.props.open) {
      stores.addRule.switchAddSectionBlock(this.props.id);
    }
  }

  static propTypes = {
    id: PropTypes.number.isRequired,
    open: PropTypes.bool,
    sequenceIndex: PropTypes.number.isRequired,
    store: PropTypes.object.isRequired,
    type: PropTypes.oneOfType(['first', 'icon-play', 'icon-exchange']),
  };

  showHideAddButton = () => {
    this.setState((state) => {
      return {
        isButtonBlockShown: !state.isButtonBlockShown,
      };
    });
  };

  closeButtonsBlock() {
    this.setState({
      isButtonBlockShown: false,
    });
  }

  handleAddCondition(canClick, sequenceIndex) {
    if (!canClick) {
      return false;
    }

    if (this.props.store.seqInit.sequences.length === 0) {
      this.props.store.seqInit.addToSequence(null, CONDITION_TYPE);
    } else {
      this.props.store.seqInit.addToSequence(sequenceIndex + 1, CONDITION_TYPE);
    }

    this.closeButtonsBlock();
  }

  renderConditionButton(sequenceIndex, conditionsButton) {
    let extraClass = '';
    let canClick = true;
    let text = '';

    if (!conditionsButton) {
      canClick = false;
      extraClass = 'button-disabled';
    }

    return (
      <React.Fragment>
        {!canClick ? (
          <Tooltip placement='top' title={text}>
            <div
              className={`addButtonsShowHide__button
                                addButtonsShowHide__button_disabled
                                addButtonsShowHide__button_condition
                                ${extraClass}`}>
              <span
                className={`addButtonsShowHide__buttonTextLabel
                                    addButtonsShowHide__buttonTextLabel_yellowText`}>
                If
              </span>
            </div>
          </Tooltip>
        ) : (
          <Popover text={'Condition'}>
            <div
              className={`addButtonsShowHide__button
                                addButtonsShowHide__button_condition
                                ${extraClass}`}
              onClick={this.handleAddCondition.bind(this, canClick, sequenceIndex)}>
              <span
                className={`addButtonsShowHide__buttonTextLabel
                                  addButtonsShowHide__buttonTextLabel_yellowText`}>
                If
              </span>
            </div>
          </Popover>
        )}
      </React.Fragment>
    );
  }

  renderActionButton(sequenceIndex, actionButton) {
    let canClick = true;
    let extraClass = '';
    let text = '';

    if (!actionButton) {
      canClick = false;
      extraClass = 'button-disabled';
    }

    // const isSmartGuideTour = stores.user.userTour === 'addRule' && stores.tourGuide.forceShow;

    return (
      <React.Fragment>
        {!canClick ? (
          <Tooltip placement='top' title={text}>
            <div
              className={`addButtonsShowHide__button
                                addButtonsShowHide__button_disabled
                                addButtonsShowHide__button_action
                                ${extraClass}`}>
              <span
                className={`addButtonsShowHide__buttonTextLabel
                                    addButtonsShowHide__buttonTextLabel_blueText`}>
                Action
              </span>
            </div>
          </Tooltip>
        ) : (
          <Popover text={'Trade Action'}>
            <div
              className={`addButtonsShowHide__button
                                addButtonsShowHide__button_action
                                ${extraClass}`}
              onClick={() => {
                if (!canClick) {
                  return false;
                }
                if (
                  this.props.store.rule.tr === TRIGGERS.TIME &&
                  this.props.store.seqInit.sequences.length
                ) {
                  this.props.store.seqInit.addToSequence(null, ACTION_TYPE);
                } else {
                  this.props.store.seqInit.addToSequence(sequenceIndex + 1, ACTION_TYPE);
                }
                this.closeButtonsBlock();
              }}>
              <span
                className={`addButtonsShowHide__buttonTextLabel
                                  addButtonsShowHide__buttonTextLabel_blueText`}>
                Action
              </span>
            </div>
          </Popover>
        )}
      </React.Fragment>
    );
  }

  renderOperatorButton(sequenceIndex, operatorButton) {
    let canClick = true;
    let extraClass = '';
    let text = '';

    if (!operatorButton) {
      canClick = false;
      extraClass = 'button-disabled';
    }

    // const isSmartGuideTour = stores.user.userTour === 'addRule' && stores.tourGuide.forceShow;

    return (
      <React.Fragment>
        {!canClick ? (
          <Tooltip placement='top' title={text}>
            <div
              className={`addButtonsShowHide__button
                                addButtonsShowHide__button_disabled
                                addButtonsShowHide__button_operator
                                ${extraClass}`}>
              <span
                className={`addButtonsShowHide__buttonTextLabel
                                    addButtonsShowHide__buttonTextLabel_cyanText`}>
                Operator
              </span>
            </div>
          </Tooltip>
        ) : (
          <Popover text={'Operator Block'}>
            <div
              className={`addButtonsShowHide__button
                              addButtonsShowHide__button_operator
                              ${extraClass}`}
              onClick={() => {
                if (!canClick) {
                  return false;
                }

                this.props.store.seqInit.addToSequence(sequenceIndex + 1, OPERATOR_TYPE);
                this.closeButtonsBlock();
              }}>
              <span
                className={`addButtonsShowHide__buttonTextLabel
                                  addButtonsShowHide__buttonTextLabel_cyanText`}>
                Operator
              </span>
            </div>
          </Popover>
        )}
      </React.Fragment>
    );
  }

  renderAddButtons(sequenceIndex) {
    let conditionsButton = true;
    let actionButton = true;
    let operatorButton = true;

    //Only for change check typo o operator
    if (sequenceIndex !== null && this.props.store.seqInit.sequences.length > 0) {
      const waitOptionValue =
        this.props.store.seqInit.sequences[sequenceIndex]?.data?.operators?.[0]?.wt;

      if (
        !this.props.store.seqInit.validationBlock('condition', sequenceIndex, 'block') ||
        this.props.store.rule.tr === TRIGGERS.DIRECT_ORDER
      ) {
        conditionsButton = false;
      }

      if (!this.props.store.seqInit.validationBlock('action', sequenceIndex, 'block')) {
        actionButton = false;
      }

      if (!this.props.store.seqInit.validationBlock('operator', sequenceIndex, 'block')) {
        operatorButton = false;
      }

      if (!conditionsButton && !actionButton && !operatorButton) {
        return null;
      }

      if (waitOptionValue === WAIT_OPTIONS.FOR) {
        actionButton = false;
      }
    }
    if (this.props.store.seqInit.sequences.length === 0) {
      operatorButton = false;
    }

    return (
      <div
        className={`addButtonsShowHide
                      ${!this.props.store.rule.ex && 'addButtonsShowHide_unavailable'}
                      ${this.state.showActionButtons && 'addButtonsShowHide_opened'}`}>
        <span className={`addButtonsShowHide__addLabel`}>Add</span>
        <div className='addButtonsShowHide__addButtons'>
          {this.renderConditionButton(sequenceIndex, conditionsButton)}
          {this.renderOperatorButton(sequenceIndex, operatorButton)}
          {this.renderActionButton(sequenceIndex, actionButton)}
        </div>
        <Popover text={'Add Block'}>
          <div
            className={`addButtonsShowHide__squareOpenBut`}
            onClick={() => {
              stores.addRule.switchAddSectionBlock(this.props.id);
              this.setState((state) => ({ showActionButtons: !state.showActionButtons }));
            }}>
            &nbsp;
          </div>
        </Popover>
      </div>
    );
  }

  renderIconPlay() {
    return null;
  }

  renderIconExchange() {
    return (
      <div
        className={`addButtonsShowHide
                        ${!this.props.store.rule.ex && 'addButtonsShowHide_unavailable'}
								        ${this.state.isButtonBlockShown && 'addButtonsShowHide_opened'}`}>
        <div
          className='addButtonsShowHide__squareIcon
										    addButtonsShowHide__squareIcon_exchange'
          onClick={this.showHideAddButton}>
          &nbsp;
        </div>
      </div>
    );
  }

  render() {
    if (this.props.store.isQuickEdit()) {
      return null;
    }

    const sequenceIndex = this.props.sequenceIndex;

    if (this.props.type === 'icon-play') {
      return this.renderIconPlay();
    } else if (this.props.type === 'icon-exchange') {
      return this.renderIconExchange();
    } else {
      if (
        this.props.store.rule.tr === TRIGGERS.TIME &&
        this.props.store.seqInit.sequences.length === 0
      ) {
        return this.renderAddButtons(null);
      }

      if (sequenceIndex !== this.props.store.seqInit.sequences.length - 1) {
        return null;
      }

      return this.renderAddButtons(sequenceIndex);
    }
  }
}

export default AddButtonsBlock;
