import React from 'react'

import type { DialogProps as MUIDialogProps} from '@mui/material'

import { useTheme, useMediaQuery, Dialog as MUIDialog, DialogContent } from '@mui/material'

export interface DialogProps extends MUIDialogProps {
  contentOverlay?: React.ReactNode
}

const testId = 'Dialog'
export const Dialog = (props: DialogProps) => {
  const {
    sx,
    children,
    contentOverlay,
    ...otherProps
  } = props

  const theme = useTheme()
  const withoutMargin = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MUIDialog
      data-testid={testId}
      className={testId}
      sx={{
        ...sx
      }}
      PaperProps={{
        sx: {
          borderRadius: 15,
          ...(withoutMargin ? { margin: 0 } : {})
        }
      }}
      {...otherProps}
    >
      {contentOverlay}
      <DialogContent
        sx={{
          padding: '56px',
        }}
      >
        {children}
      </DialogContent>
    </MUIDialog>
  )
}

export default Dialog
