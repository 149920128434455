import React from 'react'

export interface IconTradeConditionMetProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconTradeConditionMet'
export const IconTradeConditionMet = (props: IconTradeConditionMetProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 23 23"}
      enableBackground={"new 0 0 23 23"}
      xmlSpace={"preserve"}
      width={"1em"}
      height={"1em"}
      data-testid={testId}
      {...otherProps}
    >
      <path
        d={"M12.4 1.1c-.3-.1-.6-.1-.8 0-.3.1-.5.3-.7.5L3.2 12.8c-.1.1-.2.3-.2.4 0 .2 0 .3.1.5s.2.3.3.3.3.1.5.1h5.7v6.6c0 .3.1.5.3.7.2.2.4.4.7.4.3.1.6.1.8 0 .3-.1.5-.3.7-.5l7.8-11.2c.1-.1.1-.3.1-.4 0-.2 0-.3-.1-.5-.1-.1-.2-.3-.3-.3-.1-.1-.3-.1-.5-.1h-5.7V2.2c0-.3-.1-.5-.3-.7s-.4-.4-.7-.4zm4.9 9.5L11.5 19v-5.8c0-.2-.1-.5-.3-.6-.2-.2-.4-.3-.7-.3H5.7L11.5 4v5.8c0 .2.1.5.3.6.2.2.4.3.7.3h4.8z"}
        fill={"#e69b1f"}
      />
    </svg>

  )
}

export default IconTradeConditionMet
