import React, { ErrorInfo } from 'react';
import LogRocket from 'logrocket';
import http from '../../helpers/http';
import stores from '../../stores/index';
import { withRouter } from 'react-router-dom';
import IconLoading from '../Loading/IconLoading/index';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { ErrorMessageFulscreen } from './ErrorMessageFullscreen';
import history from '../../helpers/history';
import { ErrorMessageComponent } from './ErrorMessageComponent';

class globalErrorStoreClass {
  @observable error: boolean = false;

  setErrorTrue = () =>
    setTimeout(() => {
      this.error = true;
      history.listen(this.setErrorFalse);
    }, 10);

  setErrorFalse = () => {
    console.log('setErrorFalse');
    setTimeout(() => (this.error = false), 10);
  };
}

export const GlobalErrorStore = new globalErrorStoreClass();

export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// Compose boundary name as follows: `boundary_{name}`
@observer
class ErrorBoundaryWithoutRouter extends React.Component<
  { name: string; isFullScreen: boolean } | any
> {
  state = { hasError: false, loadingPlaceholder: false };

  async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(`ErrorBoundary '${this.props.name}' catch error: `, error, errorInfo);

    if (this.props.isFullScreen) {
      GlobalErrorStore.setErrorTrue();
    } else {
      this.setState({ hasError: true });
    }
    LogRocket.captureException(error, {
      extra: {
        errorInfo: errorInfo.toString(),
        errorBoundaryName: this.props.name,
        isFullScreen: this.props.isFullScreen,
      },
    });

    while (true) {
      const res = await http.post('/frontendError', {
        userId: stores?.user?.user?.sub,
        name: this.props.name,
        error: { error, errorInfo },
        isFullScreen: this.props.isFullScreen,
      });
      if (res.status === 200) {
        break;
      }

      await sleep(5_000); // Keep trying every 5 seconds until successfully sent error to server.
    }
  }

  tryAgain = () => {
    // If the error comes back immediately, this will ensure user sees some loading and is not confused.
    this.setState({ loadingPlaceholder: true });
    setTimeout(() => {
      if (this.props.isFullScreen) {
        GlobalErrorStore.setErrorFalse();
      } else {
        this.setState({ hasError: false });
      }
      this.setState({ loadingPlaceholder: false });
    }, 300 + Math.random() * 500);
  };

  render() {
    if (this.state.loadingPlaceholder) {
      return (
        <div style={{ height: '14rem' }}>
          <IconLoading />
        </div>
      ); // TODO Nicer loading
    }

    if (this.props.isFullScreen && GlobalErrorStore.error) {
      return <ErrorMessageFulscreen />;
    }

    if (!this.props.isFullScreen && this.state.hasError) {
      return <ErrorMessageComponent tryAgain={this.tryAgain} />;
    }

    return this.props.children;
  }
}

export const ErrorBoundary = withRouter(ErrorBoundaryWithoutRouter);
