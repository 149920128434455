import React from 'react'

export interface IconTradeWaitProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconTradeWait'
export const IconTradeWait = (props: IconTradeWaitProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      xmlns={"http://www.w3.org/2000/svg"}
      x={0}
      y={0}
      xmlSpace={"preserve"}
      {...otherProps}
    >
      <path
        fill={"#3642e1"}
        d={"M14.9 8.3h.5v-.8H7.7v.8h.5c.3 1.5 1.7 2.7 2.6 3.2-.9.6-2.3 1.8-2.6 3.2h-.5v.8h7.7v-.8h-.5c-.3-1.5-1.7-2.7-2.6-3.2.8-.6 2.3-1.8 2.6-3.2z"}
      />
      <path
        fill={"#3642e1"}
        d={"M11.5 2.5c-4.9 0-9 4.1-9 9s4.1 9 9 9 9-4 9-9-4-9-9-9zm0 16.5C7.4 19 4 15.6 4 11.5S7.4 4 11.5 4 19 7.4 19 11.5 15.6 19 11.5 19z"}
      />
    </svg>
  )
}

export default IconTradeWait
