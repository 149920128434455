import type { TAction } from 'v2/types/TAction'
import type { DTOFetchConnectExchangeBinanceOAuthGetAccessToken } from './DTOs/DTOFetchConnectExchangeBinanceOAuthGetAccessToken'
import type { DTOErrorFetchConnectExchangeBinanceOAuthGetAccessToken } from './DTOs/DTOErrorFetchConnectExchangeBinanceOAuthGetAccessToken'

export enum EDomainActionsConnectExchangeBinanceOAuthGetAccessToken {
  GET_ACCESS_TOKEN_FETCH_STARTED = 'domains/connectExchangeBinanceOAuth/get_access_token/FETCH_STARTED',
  GET_ACCESS_TOKEN_FETCH_SUCCEEDED = 'domains/connectExchangeBinanceOAuth/get_access_tokenFETCH_SUCCEEDED',
  GET_ACCESS_TOKEN_FETCH_FAILED = 'domains/connectExchangeBinanceOAuth/get_access_token/FETCH_FAILED',
  GET_ACCESS_TOKEN_FETCH_BOUNCED = 'domains/connectExchangeBinanceOAuth/get_access_token/FETCH_BOUNCED',
}

export type TDomainActionsConnectExchangeBinanceOAuthGetAccessToken = {
  [EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_STARTED]: TAction<EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_STARTED>,
  [EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_SUCCEEDED]: TAction<EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_SUCCEEDED, { dto: DTOFetchConnectExchangeBinanceOAuthGetAccessToken }>,
  [EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_FAILED]: TAction<EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_FAILED, { error: DTOErrorFetchConnectExchangeBinanceOAuthGetAccessToken }>,
  [EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_BOUNCED]: TAction<EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_BOUNCED, { reason: string }>,
}

export type TDomainActionConnectExchangeBinanceOAuthGetAccessToken = TDomainActionsConnectExchangeBinanceOAuthGetAccessToken[keyof TDomainActionsConnectExchangeBinanceOAuthGetAccessToken]

export const createActionFetchConnectExchangeBinanceOAuthGetAccessTokenStated = (): TDomainActionsConnectExchangeBinanceOAuthGetAccessToken[EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_STARTED] => ({
  type: EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_STARTED
})

export const createActionFetchConnectExchangeBinanceOAuthGetAccessTokenSucceeded = ({ dto }: { dto: DTOFetchConnectExchangeBinanceOAuthGetAccessToken }): TDomainActionsConnectExchangeBinanceOAuthGetAccessToken[EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_SUCCEEDED] => ({
  type: EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_SUCCEEDED,
  payload: {
    dto
  }
})

export const createActionFetchConnectExchangeBinanceOAuthGetAccessTokenFailed = ({ error }: { error: DTOErrorFetchConnectExchangeBinanceOAuthGetAccessToken }): TDomainActionsConnectExchangeBinanceOAuthGetAccessToken[EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_FAILED] => ({
  type: EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_FAILED,
  payload: {
    error
  }
})

export const createActionFetchConnectExchangeBinanceOAuthGetAccessTokenBounced = ({ reason }:  { reason: string }): TDomainActionsConnectExchangeBinanceOAuthGetAccessToken[EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_BOUNCED] => ({
  type: EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_BOUNCED,
  payload: {
    reason
  }
})

export const actionCreatorsConnectExchangeBinanceOAuthGetAccessToken = {
  [EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_STARTED]: createActionFetchConnectExchangeBinanceOAuthGetAccessTokenStated,
  [EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_SUCCEEDED]: createActionFetchConnectExchangeBinanceOAuthGetAccessTokenSucceeded,
  [EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_FAILED]: createActionFetchConnectExchangeBinanceOAuthGetAccessTokenFailed,
  [EDomainActionsConnectExchangeBinanceOAuthGetAccessToken.GET_ACCESS_TOKEN_FETCH_BOUNCED]: createActionFetchConnectExchangeBinanceOAuthGetAccessTokenBounced,
}
