import { IListItem } from 'components/List';
import React, { ReactNode } from 'react';
import CreditCard from 'assets/images/Onboarding/CreditCard.svg';
import Stats from 'assets/images/Onboarding/Stat.svg';
import Strategy from 'assets/images/Onboarding/Strategy.svg';
import Beginner from 'assets/images/Onboarding/Beginner.svg';
import Intermidiate from 'assets/images/Onboarding/Intermidiate.svg';
import Experts from 'assets/images/Onboarding/Experts.svg';
import { StepProps } from 'antd/lib/steps';
import coins from 'helpers/coins';
import { TagProps } from 'antd/lib/tag';
import stores from 'stores';
import { ReactComponent as BuyTheDipGraph } from 'assets/images/Onboarding/templates/BuyTheDip.svg';
import { ReactComponent as BuyTheDipGraphLarge } from 'assets/images/Onboarding/templates/BuyTheDipLarge.svg';
import { ReactComponent as CatchThePriceSwingGraph } from 'assets/images/Onboarding/templates/CatchThePriceSwing.svg';
import { ReactComponent as CatchThePriceSwingGraphLarge } from 'assets/images/Onboarding/templates/CatchThePriceSwingLarge.svg';
import { ReactComponent as BuyTheFlashGraph } from 'assets/images/Onboarding/templates/BuyTheFlash.svg';
import { ReactComponent as BuyTheFlashCrashHigh } from 'assets/images/Onboarding/templates/BuyTheFlashCrashHigh.svg';
import { ReactComponent as BuyTheFlashCrashLow } from 'assets/images/Onboarding/templates/BuyTheFlashCrashLow.svg';
import { ReactComponent as BuyTheFlashLargeGraph } from 'assets/images/Onboarding/templates/CatchTheFlashCrashLarge.svg';
import { ReactComponent as MAScalperLargeGraph } from 'assets/images/Onboarding/templates/MAScalperLarge.svg';
import { ReactComponent as MAScalperGraph } from 'assets/images/Onboarding/templates/MAScalper.svg';
import { ReactComponent as MAScalperGraphLow } from 'assets/images/Onboarding/templates/MAScalperIndicatorLow.svg';
import { ReactComponent as MAScalperGraphHigh } from 'assets/images/Onboarding/templates/MAScalperIndicatorHigh.svg';
import { ReactComponent as MaximizedRSIGraph } from 'assets/images/Onboarding/templates/MaximizedRSI.svg';
import { ReactComponent as MaximizedRSILargeGraph } from 'assets/images/Onboarding/templates/MaximizedRSILarge.svg';
import { ReactComponent as MaximizedRSIIndicatorLow } from 'assets/images/Onboarding/templates/MaximizedRSIInidicatorLow.svg';
import { ReactComponent as MaximizedRSIIndicatorHigh } from 'assets/images/Onboarding/templates/MaximizedRSIIndicatorHigh.svg';
import { ReactComponent as MaximizedScalpingGraph } from 'assets/images/Onboarding/templates/MaximizedScalping.svg';
import { ReactComponent as MaximizedScalpingLargeGraph } from 'assets/images/Onboarding/templates/MaximisedScalpingLarge.svg';
import { ReactComponent as GoldenGridTradingGraph } from 'assets/images/Onboarding/templates/GoldenGridTrading.svg';
import { ReactComponent as GoldenGridTradingLow } from 'assets/images/Onboarding/templates/GoldenCrossTradingIndicatorLow.svg';
import { ReactComponent as GoldenGridTradingLargeGraph } from 'assets/images/Onboarding/templates/GoldenCrossTradingLarge.svg';
import { ReactComponent as LowValalityBuySellGraph } from 'assets/images/Onboarding/templates/LowVolalityBuySell.svg';
import { ReactComponent as LowValalityBuySellLargeGraph } from 'assets/images/Onboarding/templates/LowVolalityBuySellLarge.svg';
import { ReactComponent as GridTradingGraph } from 'assets/images/Onboarding/templates/GridTrading.svg';
import { ReactComponent as GraphPointer } from 'assets/images/Onboarding/templates/Pointer.svg';
import { ReactComponent as CatchThePriceSwingIndicator } from 'assets/images/Onboarding/templates/CatchThePriceSwingIndicator.svg';
import { ReactComponent as GridTradingLargeGraph } from 'assets/images/Onboarding/templates/GridTradingLarge.svg';
import { ReactComponent as GridTradingIndicatorLow } from 'assets/images/Onboarding/templates/GridTradingIndicatorLow.svg';
import { ReactComponent as GridTradingIndicatorHigh } from 'assets/images/Onboarding/templates/GridTradingIndicatorHigh.svg';
import { ReactComponent as MaximizedScalpingLow } from 'assets/images/Onboarding/templates/MaximizedScalpingLow.svg';
import {
  IDetailGraphIndicator,
  IIndicator,
  tGraphCls,
} from '../OnboardingTemplatesSetup/TemplateOption/TemplateGraph';
import { IS_PROD } from 'constants/isProd';
import { ACTION_TYPE, CONDITION_TYPE, CONDITION_TYPES } from 'stores/addRule';
export interface IOnboardingTemplate {
  displayName: string;
  fullName?: string;
  shortDesc: string;
  longDesc: string;
  purpose: string;
  duration: string;
  percentage: number;
  trade: number;
  profit: number;
  graph?: React.ReactNode;
  largeGraph: React.ReactNode;
  indicators?: IIndicator[];
  templateName: TOnboardingTemplateNames | 'uptrend flash crash' | 'catch the price swing';
  lowIndicator?: IDetailGraphIndicator;
  highIndicator?: IDetailGraphIndicator;
  allowGraphSwap?: boolean;
  months?: number;
  pair?: string;
}

export type TExperience = 'beginner' | 'intermediate' | 'expert';

export type TOnboardingTemplateNames =
  | 'buy the dips'
  | 'rsi classic strategy'
  | 'grid trading'
  | 'maximized rsi strategy'
  | 'golden cross trading'
  | 'low volatility buy and sell'
  | 'moving average scalper'
  | 'maximized moving average crossing'
  | 'maximized scalping on trend';

export interface IOnboardingTemplateOption extends IOnboardingTemplate {
  bemCls?: string;
}

export const ONBOARDING_TEMPLATES_MAP: Record<TOnboardingTemplateNames, IOnboardingTemplateOption> =
  {
    'buy the dips': {
      displayName: 'Buy The Dip',
      templateName: 'buy the dips',
      shortDesc: 'Buy the dips of a coin and sell with a profit. A stop-loss protects every trade.',
      longDesc:
        'This is a long-term trading strategy to buy the dips of a coin and sell with a profit. A stop-loss protects every trade.',
      purpose: 'Maximizing',
      duration: 'Dec 2020 - May 2021',
      months: 6,
      percentage: 59.6,
      trade: 120,
      profit: 61,
      graph: <BuyTheDipGraph className={tGraphCls} />,
      largeGraph: <BuyTheDipGraphLarge className={tGraphCls} />,
      bemCls: 'ByTheDip',
      indicators: [
        {
          point: 'low',
          value: '-21.5%',
        },
        {
          point: 'high',
          value: '+59.6%',
        },
      ],
    },
    'rsi classic strategy': {
      templateName: IS_PROD ? 'catch the price swing' : 'rsi classic strategy',
      displayName: 'Catch The Price Swing',
      bemCls: 'CatchThePriceSwing',
      shortDesc:
        'Buying and selling based on RSI in times of sideways markets with high volatility.',
      longDesc:
        'Buying and selling based on signals from the RSI is one of the most efficient strategies in times of sideways markets with high volatility.',
      purpose: 'Leverage',
      duration: 'Jun 2020 - May 2021',
      percentage: 55.4,
      trade: 94,
      profit: 69,
      largeGraph: <CatchThePriceSwingGraphLarge className={tGraphCls} />,
      graph: <CatchThePriceSwingGraph className={tGraphCls} />,
      lowIndicator: {
        pointer: <GraphPointer className={`${tGraphCls}-detailsPointer`} />,
        indicator: <CatchThePriceSwingIndicator className={`${tGraphCls}-detailsIndicator`} />,
      },
      highIndicator: {
        indicator: <GridTradingIndicatorHigh className={`${tGraphCls}-detailsIndicator--high`} />,
      },
      allowGraphSwap: true,
      indicators: [
        {
          point: 'low',
          value: '-17.7%',
        },
        {
          point: 'high',
          value: '+77.5%',
        },
      ],
    },
    'maximized moving average crossing': {
      templateName: IS_PROD ? 'uptrend flash crash' : 'maximized moving average crossing',
      displayName: 'Uptrend Flash Crash',
      shortDesc: 'Based on a multi time frame buy-the-dip approach to optimize each trade.',
      pair: 'ETH/USDT',
      longDesc:
        'The strategy catches sudden price drops on a 1-hr time frame when the price is up in the last 6 hours. During uptrends, profit-taking price actions result in flash crashes that provide great opportunities.',
      purpose: 'Buying Dips',
      duration: 'Jun 2020 - May 2021',
      percentage: 197.6,
      trade: 122,
      bemCls: 'BuyTheFlashCrash',
      profit: 57,
      graph: <BuyTheFlashGraph className={tGraphCls} />,
      largeGraph: <BuyTheFlashLargeGraph className={tGraphCls} />,
      allowGraphSwap: true,
      lowIndicator: {
        pointer: <GraphPointer className={`${tGraphCls}-detailsPointer--low`} />,
        indicator: <BuyTheFlashCrashLow className={`${tGraphCls}-detailsIndicator--low`} />,
      },
      highIndicator: {
        indicator: <BuyTheFlashCrashHigh className={`${tGraphCls}-detailsIndicator--high`} />,
      },
      indicators: [
        {
          point: 'low',
          value: '-24.9%',
        },
        {
          point: 'high',
          value: '+199.7%',
        },
      ],
    },

    // INTERMEDIATE
    'low volatility buy and sell': {
      templateName: 'low volatility buy and sell',
      displayName: 'Low Volatility Buy & Sell',
      longDesc:
        'One way to catch a coin trading in an accumulation area is to spot three moving averages with values close to each other. The strategy uses a combination of Moving Averages to spot the best time to buy a coin before its breakout.',
      shortDesc: 'A long term strategy to buy coins before potential breakouts. ',
      purpose: 'Advanced',
      duration: 'Jun 2020 - May 2021',
      percentage: 97.8,
      trade: 24,
      profit: 58,
      graph: <LowValalityBuySellGraph className={tGraphCls} />,
      largeGraph: <LowValalityBuySellLargeGraph className={tGraphCls} />,
      bemCls: 'LowVolatilityBuySell',
      pair: 'ETH/USDT',
      indicators: [
        {
          point: 'low',
          value: '-9.5%',
        },
        {
          point: 'high',
          value: '+97.8%',
        },
      ],
    },
    'golden cross trading': {
      templateName: 'golden cross trading',
      displayName: 'Golden Cross Trading',
      bemCls: 'GoldenCrossTrading',
      shortDesc: 'The interaction of two MAs could signal a shift in the trend direction.',
      longDesc:
        'The interaction of two moving averages could signal a shift in the trend direction. Apply this rule to all the coins availble on the market to increase the opportunities with this rule.',
      purpose: 'Maximizing',
      duration: 'Jun 2020 - May 2021',
      percentage: 542,
      trade: 32,
      profit: 53,
      allowGraphSwap: true,
      pair: 'ETH/USDT',
      lowIndicator: {
        pointer: <GraphPointer className={`${tGraphCls}-detailsPointer--low`} />,
        indicator: <GoldenGridTradingLow className={`${tGraphCls}-detailsIndicator--low`} />,
      },
      highIndicator: {
        pointer: <GraphPointer className={`${tGraphCls}-detailsPointer--high`} />,
        indicator: <MaximizedRSIIndicatorHigh className={`${tGraphCls}-detailsIndicator--high`} />,
      },
      graph: <GoldenGridTradingGraph className={tGraphCls} />,
      largeGraph: <GoldenGridTradingLargeGraph className={tGraphCls} />,
      indicators: [
        {
          point: 'low',
          value: '-25.4%',
        },
        {
          point: 'high',
          value: '+542%',
        },
      ],
    },
    'maximized scalping on trend': {
      templateName: 'maximized scalping on trend',
      displayName: 'Maximized Scalping',
      bemCls: 'MaximizedScalping',
      shortDesc: 'This strategy uses MAs and the RSI to scalp trades on coins during uptrends.',
      longDesc: `The purpose of this strategy is to spot when it's the most suitable time to buy an asset profiting from a potential short-term price increase.`,
      purpose: 'Scalping',
      duration: 'Jun 2020 - May 2021',
      percentage: 552.8,
      trade: 259,
      profit: 70,
      allowGraphSwap: true,
      pair: 'ETH/USDT',
      graph: <MaximizedScalpingGraph className={tGraphCls} />,
      largeGraph: <MaximizedScalpingLargeGraph className={tGraphCls} />,
      lowIndicator: {
        indicator: <MaximizedScalpingLow className={`${tGraphCls}-detailsIndicator--low`} />,
      },
      highIndicator: {
        pointer: <GraphPointer className={`${tGraphCls}-detailsPointer--high`} />,
        indicator: <MAScalperGraphHigh className={`${tGraphCls}-detailsIndicator--high`} />,
      },
      indicators: [
        {
          point: 'low',
          value: '-34.1%',
        },
        {
          point: 'high',
          value: '+837.1%',
        },
      ],
    },

    // EXPERT
    'moving average scalper': {
      templateName: 'moving average scalper',
      displayName: 'MA Scalper',
      fullName: 'Moving Average Scalper',
      shortDesc: 'Trades frequently, profiting from small gains and limiting the downside risk.',
      longDesc:
        'The setup of this strategy uses four moving averages combined with an unconventional approach. The strategy aims to catch assets trading in a downtrend but on the verge of a possible reversal.',
      purpose: 'Moving Average',
      duration: 'Dec 2020 - Jun 2021',
      months: 6,
      percentage: 23.2,
      trade: 44,
      bemCls: 'MAScalper',
      profit: 70,
      graph: <MAScalperGraph className={tGraphCls} />,
      largeGraph: <MAScalperLargeGraph className={tGraphCls} />,
      allowGraphSwap: true,
      lowIndicator: {
        indicator: <MAScalperGraphLow className={`${tGraphCls}-detailsIndicator--low`} />,
      },
      highIndicator: {
        pointer: <GraphPointer className={`${tGraphCls}-detailsPointer--high`} />,
        indicator: <MAScalperGraphHigh className={`${tGraphCls}-detailsIndicator--high`} />,
      },
      indicators: [
        {
          point: 'low',
          value: '-14.7%',
        },
        {
          point: 'high',
          value: '+26.9%',
        },
      ],
    },
    'maximized rsi strategy': {
      templateName: 'maximized rsi strategy',
      displayName: 'Maximised RSI',
      bemCls: 'MaximisedRSI',
      shortDesc: 'Buy low and sell high using the Relative Strength Index indicator.',
      longDesc:
        'Catching the right time to buy the dip may be tricky. This automated trading strategy is the maximized version of a standard RSI trading system.',
      purpose: 'RSI',
      fullName: 'Maximized RSI Strategy',
      pair: 'ETH/USDT',
      duration: 'Dec 2020 - May 2021',
      percentage: 116.6,
      trade: 107,
      profit: 71,
      graph: <MaximizedRSIGraph className={tGraphCls} />,
      largeGraph: <MaximizedRSILargeGraph className={tGraphCls} />,
      lowIndicator: {
        pointer: <GraphPointer className={`${tGraphCls}-detailsPointer--low`} />,
        indicator: <MaximizedRSIIndicatorLow className={`${tGraphCls}-detailsIndicator--low`} />,
      },
      highIndicator: {
        pointer: <GraphPointer className={`${tGraphCls}-detailsPointer--high`} />,
        indicator: <MaximizedRSIIndicatorHigh className={`${tGraphCls}-detailsIndicator--high`} />,
      },
      allowGraphSwap: true,
      indicators: [
        {
          point: 'low',
          value: '-35.8%',
        },
        {
          point: 'high',
          value: '+194.6%',
        },
      ],
    },
    'grid trading': {
      templateName: 'grid trading',
      displayName: 'Grid Trading',
      bemCls: 'GridTrading',
      shortDesc: 'Buying and selling at set intervals to take advantage of the price volatility.',
      longDesc:
        'Grid trading is a trading system where within a determined price range, a certain number of sell and buy orders are placed at regular intervals above and below the current price of the trading asset.',
      purpose: 'Consolidating',
      duration: 'Jun 2021 - Jul 2021',
      percentage: 9.2,
      trade: 153,
      profit: 89,
      allowGraphSwap: true,
      months: 1,
      graph: <GridTradingGraph className={tGraphCls} />,
      largeGraph: <GridTradingLargeGraph className={tGraphCls} />,
      lowIndicator: {
        pointer: <GraphPointer className={`${tGraphCls}-detailsPointer--low`} />,
        indicator: <GridTradingIndicatorLow className={`${tGraphCls}-detailsIndicator--low`} />,
      },
      highIndicator: {
        pointer: <GraphPointer className={`${tGraphCls}-detailsPointer--high`} />,
        indicator: <GridTradingIndicatorHigh className={`${tGraphCls}-detailsIndicator--high`} />,
      },
      indicators: [
        {
          point: 'low',
          value: '-3.4%',
        },
        {
          point: 'high',
          value: '+9.2%',
        },
      ],
    },
  };

export const ONBOARDING_TEMPLATES_DATA: Record<TExperience, IOnboardingTemplateOption[]> = {
  beginner: [
    ONBOARDING_TEMPLATES_MAP['buy the dips'],
    ONBOARDING_TEMPLATES_MAP['rsi classic strategy'],
    ONBOARDING_TEMPLATES_MAP['maximized moving average crossing'],
  ],
  intermediate: [
    ONBOARDING_TEMPLATES_MAP['low volatility buy and sell'],
    ONBOARDING_TEMPLATES_MAP['golden cross trading'],
    ONBOARDING_TEMPLATES_MAP['maximized scalping on trend'],
  ],
  expert: [
    ONBOARDING_TEMPLATES_MAP['moving average scalper'],
    ONBOARDING_TEMPLATES_MAP['maximized rsi strategy'],
    ONBOARDING_TEMPLATES_MAP['grid trading'],
  ],
};

export const baseCls = `OnboardingTour`;

export type TOnboardingVariant =
  | 'Old Onboarding'
  | 'Onboarding New Coins'
  | 'Onboarding Exit Disabled'
  | '';

export interface IOptions {
  name: TStepName;
  options: IOnboardingOption[];
  userAnswers: string[] | undefined;
  maxAnswers?: number;
}
export interface IOnboardingAnswer {
  question: IOnboardingStep['title'];
  userAnswers: string[];
}

export type THandleChange = (value: string, name?: string, step?: number) => void;

export interface IOnboardingOption {
  label: ReactNode;
  subLabel?: ReactNode;
  className?: string;
  value?: string;
  imgSrc?: ReactNode;
  selected?: boolean;
  handleChange?: THandleChange;
  tag?: TagProps;
}

export type TStepName =
  | 'Welcome'
  | 'ReasonJoined'
  | 'Trading Experience'
  | 'FavoriteCoin'
  | 'FavoriteExchanges'
  | 'OnboardingQuizFinish'
  | 'onboarding_template'
  | 'onboarding_template_selection';

export interface IOnboardingStep {
  name?: TStepName;
  title: ReactNode;
  subTitle?: string;
  maxAnswers?: number;
  options?: IOnboardingOption[];
  onButtonClick?: Function;
  buttonText?: string;
}

export interface ITemplateCategory {
  _id: string;
  nam: string;
}

export interface ITemplate {
  _id: string;
  templateName: string;
  complexity: 1 | 2 | 3;
  description: string;
  pair: string;
  category: ITemplateCategory[];
  teaserText: string;
  params: Record<string, any>;
}

/***
 * Old onboarding
 * Onboarding Exchanges
 * Onboarding Coins
 */

export const tradingGoalsOptions: IOnboardingOption[] = [
  {
    imgSrc: CreditCard,
    label: 'Make Profit',
    subLabel: (
      <>
        A unique trading experience to
        <br /> beat the market
      </>
    ),
  },

  {
    imgSrc: Stats,
    label: 'Trade Better',
    subLabel: (
      <>
        100+ trading strategies to <br /> be a better trader
      </>
    ),
  },

  {
    imgSrc: Strategy,
    label: 'Run A Trade Strategy',
    subLabel: (
      <>
        Automated trading strategies <br /> without coding
      </>
    ),
  },
];

export const reasonsJoined = tradingGoalsOptions.map((option) => option.label);

export const tradingExperienceOptions: IOnboardingOption[] = [
  {
    imgSrc: Beginner,
    label: 'Beginner',
    subLabel: `Knows little about trading but excited to learn more`,
  },

  {
    imgSrc: Intermidiate,
    label: 'Intermediate',
    subLabel: `Understands the basics of trading and can make a cryptocurrency trade`,
  },

  {
    imgSrc: Experts,
    label: 'Expert',
    subLabel: `Can design different trading strategies and uses advanced indicators`,
  },
];

export const getOnboardingStep = (): IOnboardingStep[] => [
  {
    name: 'Welcome',
    title: (
      <>
        Welcome to <span className={`${baseCls}__title--bolder`}>Coinrule</span>
      </>
    ),
  },

  {
    name: 'ReasonJoined',
    title: 'What brings you to Coinrule?',
    options: tradingGoalsOptions,
  },
  {
    name: 'Trading Experience',
    title: 'How much trading experience do you have?',
    options: tradingExperienceOptions,
  },
  {
    name: 'FavoriteCoin',
    title: `Select your favorite coins`,
    maxAnswers: 3,
    options: coins,
  },

  {
    name: 'OnboardingQuizFinish',
    title: (
      <h1 className={`${baseCls}__title`}>
        <span className='active'>Thank you</span> for <br /> completing your registration!
      </h1>
    ),
  },

  {
    name: 'onboarding_template',
    title: (
      <>
        Here are 3 optimized templates for you <br />
        <span className='active'>Select one to start with</span>
      </>
    ),
  },

  {
    title: (
      <h1 className={`${baseCls}__title`}>
        Here are 3 optimized templates for you. <br />
        <span className='active'>Select one to start with</span>
      </h1>
    ),
    name: 'onboarding_template_selection',
  },
];

export const onboardingFinishChecklistItems: IListItem[] = [
  { text: 'Customizing templates', count: 2 },
  { text: 'Creating your learning experience', count: 3 },
  { text: 'Preparing for building first rule', count: 4 },
];

export const getOnboardingNavigationSteps = (): StepProps[] => [
  { title: 'Trading Goals' },
  { title: 'Trading Experience' },
  { title: `Favorite Coins` },
];

export const handleChange: THandleChange = (value, name, step = stores.userSurvey.quizStep) => {
  const { addQuizData } = stores.userSurvey;

  const answer: IOnboardingAnswer = {
    question: name,
    userAnswers: [value],
  };

  addQuizData(step, answer);
};

export interface IOnboardingHeading {
  title: string;
  subTitle: string;
}
export interface IOldOnboaringStep extends IOnboardingHeading {
  name: TStepName;
  answers: {
    type: 'texts' | 'exchanges';
    multipleSelect: boolean;
    variants: string[];
  };
}

export interface IOldOnboardingData {
  step: number;
  steps: IOldOnboaringStep[];
  final: IOnboardingHeading;
}

export const oldOnboardingData: IOldOnboardingData = {
  step: 0,

  steps: [
    {
      title: 'Welcome, Before We Start',
      subTitle: 'How Much Trading Experience Do You Have?',
      name: 'Trading Experience',
      answers: {
        type: 'texts',
        multipleSelect: false,
        variants: ['A Little', 'Some Knowledge', 'Significant'],
      },
    },
    {
      title: 'And, By The Way',
      subTitle: 'Which Exchange Do You Use The Most?',
      name: 'FavoriteExchanges',
      answers: {
        type: 'exchanges',
        multipleSelect: false,
        variants: [
          'binance',
          'coinbasePro',
          'hitBtc',
          'kraken',
          'bitstamp',
          'bitpanda',
          'binanceus',
          'bitfinex ',
          'okex',
          'bitmex',
          'liquid',
          'poloniex',
          'bittrex',
          'otherExchange',
          'none',
        ],
      },
    },
  ],

  final: {
    title: 'Thanks!',
    subTitle: 'Enjoy Using Coinrule!',
  },
};

export const ONBOARDING_EXPERIMENT_NAME = 'Onboarding Journey';

export const ONBOARDING_VARIANTS: TOnboardingVariant[] = [
  'Onboarding New Coins',
];

export type TFinishOnboarding = (closeModalWithDelay: boolean, isOnboardingPassed: boolean) => void;

export const updateOnboardingTemplate = (template: Record<string, any>) => {
  const sequencesData: any[] = template.params.sq;

  sequencesData.forEach((sequence) => {
    // CONDITION
    if (
      sequence.t === CONDITION_TYPE &&
      (!sequence.d.ifc || sequence.d.ifc === CONDITION_TYPES.SYMBOL || sequence.d.ifc === '---')
    ) {
      sequence.d.ifs = ['any_coin'];
      sequence.d.ifc = 'any_coin';
    }

    // ACTION
    if (sequence.t === ACTION_TYPE) {
      // set 100 as amount
      if (!sequence.d.ta.v.iv) {
        sequence.d.ta.v.iv = 100;
      }
      // choose BTC as wallet
      if (!sequence.d.ta.q || sequence.d.ta.q === '---') {
        sequence.d.ta.q = 'BTC';
      }
    }
  });

  return {
    ...template,
    params: { ...template.params, sq: sequencesData },
  };
};

export const ADVANCED_TEMPLATES = [
  ONBOARDING_TEMPLATES_MAP['golden cross trading']['templateName'],
  ONBOARDING_TEMPLATES_MAP['maximized scalping on trend']['templateName'],
  ONBOARDING_TEMPLATES_MAP['low volatility buy and sell']['templateName'],
  ONBOARDING_TEMPLATES_MAP['rsi classic strategy']['templateName'],
  ONBOARDING_TEMPLATES_MAP['moving average scalper']['templateName'],
  ONBOARDING_TEMPLATES_MAP['maximized rsi strategy']['templateName'],
]

export const ADVANCED_ONBOARDING_TEMPLATE_PAYWALL_NAME = 'advanced-onboarding-template'

export const ONBOARDING_7_DAYS_TRAIL_COUPON = process.env.REACT_APP_ONBOARDING_TRIAL_COUPON;

export const getOnboardingModalLabel = (userId: string, loginCount: number) => `timesOnboardingSeen${userId}-${loginCount || 0}`