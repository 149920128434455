import type { DTOFetchConnectExchangeBinanceOAuthGetAccessToken } from 'v2/domains/connectExchangeBinanceOAuth/DTOs/DTOFetchConnectExchangeBinanceOAuthGetAccessToken'
import type { Api } from '__generated__/backend.api.client/Api'

export type TDataProviderConnectExchangeBinanceOAuthGetAccessToken = ReturnType<typeof createDataProviderConnectExchangeBinanceOAuthGetAccessToken>

export const createDataProviderConnectExchangeBinanceOAuthGetAccessToken = <SecurityDataType extends unknown = unknown>({ httpClient }: { httpClient: Api<SecurityDataType> }) => {
  return {
    getAccessToken: async ({ code }: { code: string }): Promise<DTOFetchConnectExchangeBinanceOAuthGetAccessToken> => {
      const response = await (
        httpClient.instance.get<DTOFetchConnectExchangeBinanceOAuthGetAccessToken>(
          process.env.REACT_APP_API_URL + '/auth/oauth/binance/token?code=' + code,
        )
      )

      const dtoConnectExchangeBinanceOAuthGetAccessToken: DTOFetchConnectExchangeBinanceOAuthGetAccessToken = response.data

      return dtoConnectExchangeBinanceOAuthGetAccessToken
    }
  }
}