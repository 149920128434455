import stores from '../stores';

export function userHasPaidPlan() {
  if (stores.user.user.plan.isPremium) {
    return true;
  }

  return false;
}

export const sortPredicate = (a, b) => {
  return +a.price - +b.price;
};

export const sortPlans = (plans = []) => plans.sort(sortPredicate);

export const formatUserCurrentPlan = (plan) => {
  const { uid, clearance, isPremium, trial, numberOfExchanges, numberOfTemplates } = plan;
  return {
    ...plan,
    code: uid,
    settings: {
      clearance,
      isPremium,
      trial,
    },
    limits: {
      numberOfExchanges,
      numberOfTemplates,
    },
  };
};

export const PLAN_PERIODS = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
  QUARTERLY: 'quarterly',
}