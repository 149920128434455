import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import stores from '../../../../stores/index';

import {
  ACTION_TYPE,
  TRADE_TYPES,
  TRIGGERS,
  CONDITION_TYPES,
  SYMBOL_TYPES,
} from '../../../../stores/addRule';
import {
  getIndicator,
  getOperator,
  getPeriodName,
  localizedNumber,
  formattedCounter,
} from '../../../../helpers/utils';
import { DEFAULT_FIAT } from '../../../../constants/globalCoins';
import { formatNumber, formatCoinAmount } from '../../../../helpers/utils';

@observer
class DetailTextConditions extends Component {
  renderStartPrice(cond) {
    if (cond.wi === 'current_price' && cond.startPriceQuote && cond.startPriceUSD) {
      if (cond.reversed) {
        return (
          <span>
            (
            <b>
              1 {cond.quote} ≃{' '}
              {/*{formatNumber(cond.startPriceQuote, '', false, false, 4, 4)[0] + (!!cond.ifs.length && cond.ifs[0])}*/}
              {formatCoinAmount(cond.startPriceQuote) + (!!cond.ifs.length && cond.ifs[0])}{' '}
            </b>{' '}
            ≃ {formatNumber(cond.startPriceUSD, DEFAULT_FIAT)[0]})
          </span>
        );
      }

      return (
        <span>
          (
          <b>
            {/*{formatNumber(cond.startPriceQuote, '', false, false, 4, 4)[0] + ' ' + cond.quote}*/}
            {formatCoinAmount(cond.startPriceQuote) + ' ' + cond.quote}
          </b>{' '}
          ≃ {formatNumber(cond.startPriceUSD, DEFAULT_FIAT)[0]})
        </span>
      );
    }
  }

  getPriceArea(c) {
    const periodName = getPeriodName(c.map) || '---';
    let indicator = getIndicator(c.maci) || '---';

    if (['above', 'below', 'lte', 'gte'].indexOf(c.op) > -1 && c.maci && c.in.indexOf('ma_') > -1) {
      if (periodName) {
        return (
          <span>
            {indicator} <span style={{ fontWeight: 'normal' }}>in a timeframe of</span> {periodName}
          </span>
        );
      } else {
        return `${indicator}`;
      }
    }

    if (c.in === 'rsi' && ['lte', 'gte'].indexOf(c.op) > -1) {
      if (periodName) {
        return (
          <span>
            {c.rsi_v || '---'} <span style={{ fontWeight: 'normal' }}>in a timeframe of</span>{' '}
            {periodName}
          </span>
        );
      } else {
        return `${c.rsi_v || '---'}`;
      }
    }

    if (c.a.iv && ['above', 'below'].indexOf(c.op) === -1) {
      return `${localizedNumber(c.a.iv)} ${c.in === 'rsi' ? '' : c.a.s}`;
    } else if (['above', 'below'].indexOf(c.op) > -1) {
      return (
        <span>
          {indicator} <span style={{ fontWeight: 'normal' }}>in a timeframe of</span> {periodName}
        </span>
      );
    }

    return <span className={`ruleSummaryText__nowrapSentence`}>---</span>;
  }

  isRuleProfitCondition(condition) {
    return (
      condition.ift === SYMBOL_TYPES.RULE
    );
  }

  isTradingViewSignalCondition(condition) {
    return (
      condition.ift === SYMBOL_TYPES.SIGNAL &&
      condition.ifc === CONDITION_TYPES.TRADINGVIEW_SIGNAL
    );
  }

  renderConditionFieldsBasedOnType(condition, within, delta) {
    if (this.isTradingViewSignalCondition(condition)) {
      return (
        <>
          <span>is</span>
          <strong> {condition.in.charAt(0).toUpperCase() + condition.in.slice(1)}</strong>
        </>
      );
    }

    return (
      <>
        <span>{this.isRuleProfitCondition(condition) ? 'by at least' : 'has'}</span>
        <strong> {getIndicator(condition.in)} </strong>
        <strong> {getOperator(condition.op)} </strong>
        <strong> {this.getPriceArea(condition)} </strong>
        {within}
        {this.renderStartPrice(condition)}
        <strong>{delta}</strong>
      </>
    );
  }

  render() {
    let {
      store,
      sequenceIndex,
      conditionsData,
      hasPreviousCondition,
      isLeverageExchange,
    } = this.props;
    const triggerData = store.rule.tr;

    let conditions;
    const coin = isLeverageExchange ? 'instrument' : 'coin';

    const actionsIndexes = store.seqInit.findAllSequenceByTypeBefore(ACTION_TYPE, sequenceIndex);

    if ([TRIGGERS.DIRECT_ORDER, TRIGGERS.TIME].indexOf(triggerData) > -1 && sequenceIndex === 0) {
      return null;
    }

    if (conditionsData.length > 0) {
      // if (false) {
      conditions = conditionsData.map((c, i) => {
        let bestWorst;
        let symbols = '---';
        let within;
        let delta;

        if (c.ifc === CONDITION_TYPES.ANY_MY_COIN) symbols = 'any of my coins';
        else if (c.ifc === CONDITION_TYPES.ANY_COIN) symbols = isLeverageExchange ? '---' : 'any coin';
        else if (c.ifc === CONDITION_TYPES.BEST_MINE || c.ifc === CONDITION_TYPES.WORST_MINE) {
          if (c.ifc === CONDITION_TYPES.WORST_MINE) {
            bestWorst = 'worst';
          } else if (c.ifc === CONDITION_TYPES.BEST_MINE) {
            bestWorst = 'best';
          }
    
          symbols = `${bestWorst} performing coin in my portfolio`;
        } else if (c.ifc === CONDITION_TYPES.BEST_MARKET || c.ifc === CONDITION_TYPES.WORST_MARKET) {
          if (c.ifc === CONDITION_TYPES.WORST_MARKET) {
            bestWorst = 'worst';
          } else if (c.ifc === CONDITION_TYPES.BEST_MARKET) {
            bestWorst = 'best';
          }
          symbols = `${bestWorst} performing coin on the market`;
        } else if (c.ifc === CONDITION_TYPES.THAT_COIN) {
          symbols = `that ${coin}`;

          if (isLeverageExchange && store.instrumentQuote) {
            symbols = store.instrumentQuote;
          }
        } else if (c.ifc === CONDITION_TYPES.TRADINGVIEW_SIGNAL) symbols = `TradingView Signal`;
        else if (c.ifc === CONDITION_TYPES.FROM_SEQUENCE) {
          let name = '---';
          const actionSequence = c.sr;
          const sequenceReference = store.seqInit.getSequenceTypeAndPositionByIndex(actionSequence);

          if (!!sequenceReference) {
            if (sequenceReference.thatCoinByPass || actionsIndexes.length === 1) {
              name = `that ${coin}`;
            } else {
              name = `${coin} from ${formattedCounter(sequenceReference.position)} ${sequenceReference.type}`;
            }
          }

          const actionIndex = store.seqInit.findIndexSequenceBeforeByType(
            ACTION_TYPE,
            sequenceIndex
          );

          if (name === `that ${coin}`) {
            if (actionIndex) {
              const coin = store.seqInit.sequences?.[actionIndex]?.data?.actions
                ? store.seqInit.sequences?.[actionIndex].data.actions.length > 0 &&
                  store.seqInit.sequences?.[actionIndex].data.actions[0].b.length > 0 &&
                  store.seqInit.sequences?.[actionIndex].data.actions[0].b[0]
                : null;

              if (coin && coin !== '---') {
                name = coin;
              }
            }
          } else {
            const currentSequence = store.seqInit.sequences?.[actionSequence];

            const coin =
              (!!currentSequence?.data?.actions?.length &&
                !!currentSequence?.data?.actions?.[0]?.b?.length &&
                currentSequence?.data?.actions?.[0]?.b?.[0]) ||
              (!!currentSequence?.d?.ta?.b?.length && currentSequence?.d?.ta?.b?.[0]);

            if (coin && coin !== '---') {
              name = coin;
            }
          }

          symbols = actionSequence >= 0 ? name : `that ${coin}`;

          if (isLeverageExchange && store.instrumentQuote) {
            symbols = store.instrumentQuote;
          }
        } else if (c.ifc === CONDITION_TYPES.SYMBOL) {
          if (c.ifs.length > 1) {
            symbols = c.ifs.join(', ');
          } else {
            if (c.ifc === CONDITION_TYPES.RULE_IN_PROFIT)
              symbols = 'this rule is in profit';
            else if (c.ifc === CONDITION_TYPES.RULE_IN_LOSS)
              symbols = 'this rule is in loss';
            else if (c.ifc === CONDITION_TYPES.TRADINGVIEW_SIGNAL)
              symbols = 'TradingView Signal';
            else symbols = !!c.ifs.length && c.ifs[0];
          }
        } else if (
          c.ifc === CONDITION_TYPES.RULE_IN_PROFIT ||
          c.ifc === CONDITION_TYPES.RULE_IN_LOSS
        ) {
          if (c.ifc === CONDITION_TYPES.RULE_IN_PROFIT) symbols = 'this rule is in profit';
          else symbols = 'this rule is in loss';
        }

        if (['inc', 'dec'].indexOf(c.op) > -1) {
          if (c.wi === 'current_price') {
            if (c.in === 'price') {
              within = <span> from current live price </span>;
            } else if (c.in === 'volume' || c.in === 'marketcap') {
              within = <span> from current level </span>;
            }
          } else if (c.wi === 'delta') {
            delta = (
              <span>
                {' '}
                within <strong> {getPeriodName(c.d) || '---'} </strong>
              </span>
            );
          } else if (c.wi === 'action_price') {
            if (c.ifc === 'that_coin') {
              within = <span> from that price </span>;
            } else {
              let actionOrand = null;

              if (this.props.store.rule.sq?.[c.sr]) {
                if (this.props.store.rule.sq[c.sr].t === ACTION_TYPE) {
                  actionOrand = this.props.store.rule.sq[c.sr].d.ta.at;
                }
              } else if (store.seqInit.sequences?.[c.sr]) {
                if (store.seqInit.sequences[c.sr].type === ACTION_TYPE) {
                  actionOrand =
                    !!store.seqInit.sequences[c.sr].data.actions?.length &&
                    store.seqInit.sequences[c.sr].data.actions?.[0].at;
                }
              }

              if (actionOrand) {
                let text = 'sold';
                if (actionOrand === TRADE_TYPES.BUY) {
                  text = 'bought';
                }
                delta = 'from price at which you ' + text;
              }
            }
          } else if (c.wi === 'trailing') {
            delta = 'trailing';
          }
        }

        if (isLeverageExchange && c.ifc !== CONDITION_TYPES.SYMBOL && this.props.sequenceIndex === 0) {
          symbols = '---';
        }

        if (
          symbols &&
          typeof symbols === 'string' &&
          symbols.includes(',') &&
          symbols.includes('---')
        ) {
          symbols = symbols.replace(', ---', '');
        }

        return (
          <li
            key={i}
            data-marker={this.props.sequenceIndex}
            className={`ruleSummaryText__section
                        ruleSummaryText__section_condition
                        ${
                          stores.addRule.sfpPendingSectionId === +this.props.sequenceIndex &&
                          'ruleSummaryText__section_preloader'
                        }`}
            onClick={() => this.props.handleClick(this.props.sequenceIndex)}
            style={{ paddingLeft: `${this.props.sequenceIndex * 12}px` }}
          >
            <span
              className={`ruleSummaryText__keyWord
                          ruleSummaryText__keyWord_condition`}
            >
              {i > 0 || hasPreviousCondition ? `${c.co} if` : 'if'}
            </span>
            <strong> {symbols} </strong>
            {this.renderConditionFieldsBasedOnType(c, within, delta)}
          </li>
        );
      });
    }

    return <React.Fragment>{conditions}</React.Fragment>;
  }
}

export default withRouter(DetailTextConditions);
