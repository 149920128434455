import React, { Component } from 'react';
import { observer } from 'mobx-react';
import stores from '../../../stores';
import { DOMAIN } from 'constants/isProd';

import './style.scss';

@observer
class AuthFooter extends Component {
  render() {
    return (
      <footer
        className={`footerBlock
                          ${stores.auth.isSideMenuOpened && 'footerBlock_shifted'}`}
      >
        {/*Check address hint*/}
        <div
          className={`checkAddressHint
                          footerBlock__checkAddressHint`}
        >
          <div className={`checkAddressHint__title`}>
            Please check that you are visiting the correct URL
          </div>
          <div className={`checkAddressHint__addressBar`}>
            <span className={`checkAddressHint__sslHighlight`}>https://</span>{DOMAIN}
          </div>
        </div>

        <div className={`footerBlock__copyright`}>
          &copy; Coinrule Ltd {new Date().getFullYear()} All rights reserved
        </div>
      </footer>
    );
  }
}

export default AuthFooter;
