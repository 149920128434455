import { sleep } from 'components/ErrorBoundary';
import { IObservable, toJS } from 'mobx';
import React from 'react';
import DeviceStorage from 'react-device-storage';
import { IHandleDataStorage } from 'typings/types';
import { getDataCookies, getDataLocalStorage, numberWithCommas, setDataCookies, setDataLocalStorage } from './utils';

export interface IHandleFilter {
  items: Record<string, any>[];
  query: string;
  key: string;
}

export function handleFilter({ items, query = '', key = '' }: IHandleFilter) {
  return items.filter((item) => {
    let queryValue = item[key];

    if (typeof item[key] === 'object') {
      const [first, , last] = item[key].props.children || [];
      queryValue = first + last;
    }

    return queryValue.toLowerCase().indexOf(query.toLowerCase()) > -1;
  });
}

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const normalizeObservables = (observable: IObservable | IObservable[] | any) => {
  if (!Array.isArray(observable)) return toJS(observable);

  return observable.map((ob) => toJS(ob));
};

export const shortenSentences = (sentence: string, count: number) =>
  sentence.length > count ? `${sentence.slice(0, count)}...` : sentence;

export const generateRandomNumber = (max = 15) => Math.floor(Math.random() * (max - 1) + 1);

export function randomInRange(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export const handleDataStorage = ({
  label,
  data,
  storage,
  action,
  options,
}: IHandleDataStorage) => {
  const s = new DeviceStorage({
    cookieFallback: true,
    cookie: {
      secure: window.location.protocol === 'https:',
    },
    ...options,
  })[storage]();

  if (action === 'read') return s[action](label);

  s[action](label, data);
};

export function findItemInArray<T>(items: T[], key: string, itemToFind: any) {
  return items.find((item: any) => item[key] === itemToFind);
}

export const makeDotDecimals = (value: string): string => {
  if (value.length > 1 || value !== '.') return value;

  return '0.';
};

export const prepareInputValue = (inputValue: string, symbol: string, callback?: Function) => {
  const value = symbol === '%' && +inputValue > 100 ? '100' : inputValue;

  return callback ? callback(value) : makeDotDecimals(value);
};

export type TEmailTypes = 'info' | 'support';

export const getEmail = (type: TEmailTypes = 'support', subject = '') =>
  `mailto:${type}@coinrule.com${subject ? `?subject=${subject}` : ''}`;

export const reload = () => window.location.reload();

interface IRetryRequestOptions {
  request: Function;
  retryCount?: number;
  callback: (response: any) => boolean | Promise<boolean>;
  retryDelay?: number;
}

export const retryRequest = async ({
  request,
  retryCount = 3,
  callback,
  retryDelay = 500,
}: IRetryRequestOptions) => {
  let response;

  for (let i = 0; i < retryCount; i++) {
    response = await request();
    const stop = await callback(response);

    if (stop) {
      break;
    } else {
      await sleep(retryDelay);
    }
  }

  return response;
};

export const formatAmount = (amount: number, options: Record<string, any> = {}) =>
  new Intl.NumberFormat('en-US', options).format(amount);

export const getCurrencySymbol = (currency: string) => {
  let symbol;

  switch (currency.toLocaleUpperCase()) {
    case 'USD':
      symbol = '$';
      break;

    case 'EUR':
      symbol = '€';
      break;

    case 'GBP':
      symbol = '£';
      break;

    case 'JPY':
      symbol = '¥'; // Japanese Yen
      break;

    default:
      symbol = '$';
      break;
  }

  return symbol;
};

export const isEmpty = (value: number) => !value;

export const isNegativeNumber = (number: number) => Math.sign(number) === -1;

export const getSignedNumber = (number: number) => {
  if (isNegativeNumber(number)) return number;

  return `+${number}`;
};

export const isOld = (number: number) => number % 2 === 1;

export const roundNumber = (number: number, precision = 2) => {
  const factor = Math.pow(10, precision);

  return Math.round(number * factor) / factor;
};

export function storeInCookieArray(label: string, data: any) {
  const result = getFromCookieArray(label);
  if (Array.isArray(result)) {
    if (result.indexOf(data) === -1) {
      result.push(data);
      // const str = JSON.stringify(result);
      setDataCookies(label, result);
    }
  } else {
    const array = [];
    array.push(result, data);
    // const str = JSON.stringify(array);
    setDataCookies(label, array);
  }
}

export function getFromCookieArray(label: string) {
  const result = getDataCookies(label);
  try {
    return typeof result === 'string' ? JSON.parse(result) : result;
  } catch (error) {
    return [];
  }
}

export const pluralise = (count: number, word: string) => {
  if (!word.endsWith('s') && count > 1) {
    return `${word}s`;
  }

  return word;
};

export const handleMultipleRequests = async (items: any[], callback: any) => {
  const promises = items.map((item) => callback(item));
  return await Promise.all(promises);
};

export const isObjEmpty = (obj: object) => {
  return Object.keys(obj).length === 0;
};

export const centToDollar = (amount_in_cents: number) => {
  return amount_in_cents / 100;
};

export const getConditionalClass = (className: string, condition?: boolean) =>
  condition ? className : '';

export interface IConditionalClass {
  className: string;
  condition?: boolean | any;
}

export const getConditionalClasses = (classesWithCondtions: IConditionalClass[]) => {
  const classes = classesWithCondtions.reduce((acc: Array<string>, { className, condition }) => {
    if (condition) {
      acc.push(className);
    }

    return acc;
  }, []);

  return classes.join(' ');
};

export interface FormatLargeNumberOptions {
  amount: number;
  divider: number;
  string: string;
}
export const formatLargeNumber = ({
  amount,
  divider = 1_000_000,
  string = 'M',
}: FormatLargeNumberOptions) => {
  const digit = amount / divider;

  if (!Number.isInteger(digit)) return `${digit.toFixed(2)}${string}`;

  return `${digit}${string}`;
};

export const convertLargeNumbers = (amount = 0) => {
  const [integer] = `${amount}`.split('.');
  const { length } = integer;
  let result = '';

  if (length >= 13) {
    result = formatLargeNumber({ amount, divider: 1_000_000_000_000, string: 'T' });
  } else if (length >= 10 && length <= 12) {
    // Billions
    result = formatLargeNumber({
      amount,
      divider: 1_000_000_000,
      string: 'B',
    });
  } else if (length >= 7 && length <= 9) {
    // millions
    result = formatLargeNumber({
      amount,
      divider: 1_000_000,
      string: 'M',
    });
  } else if (length >= 4 && length <= 6) {
    // thousands
    result = formatLargeNumber({
      amount,
      divider: 1_000,
      string: 'K',
    });
  } else {
    result = numberWithCommas(amount);
  }

  return result;
};
export const setter = (
  newState: Record<string, any>,
  setState: React.Dispatch<React.SetStateAction<any>>
) => setState((prev: any) => ({ ...prev, ...newState }));

export const getAmountWithCurrency = (amount: string | number, currency: string) => {
  const symbol = getCurrencySymbol(currency);
  return `${symbol}${amount}`;
};

export const getTimezone = () => {
  const timezoneOffset = new Date().getTimezoneOffset() / -60;
  const signedOffset = getSignedNumber(timezoneOffset);

  return `UTC ${signedOffset}`
}

export const walletBalancesWithExchanges = (wallets: Record<string, number>, exchanges: any[]) => {
  if(!wallets || !Object.keys(wallets)?.length || !exchanges?.length) return [];

  return exchanges.map(exchange => {
    const wallet = wallets[exchange.id] || 0;
    return {
      ...exchange,
      balance: wallet
    }
  })  
};

export const getBooleanFromString = (string: string) => string === 'true' ? true : false;

// utility to determine if user just logged in or not
export const getJustLoggedInLabel = (userId: string, loginCount: number) => `hasJustLoggedIn-${userId}-${loginCount || 0}`;
export const setHasJustLoggedIn = (userId: string, loginCount: number) => {
  const label = getJustLoggedInLabel(userId, loginCount);

  const hasJustLoggedInExistsInStorage = getDataLocalStorage(label);
  if(hasJustLoggedInExistsInStorage) return false;

  setDataLocalStorage(label, true);
  return true;
}

enum ERuleTitleDisplayStyle {
  INLINE_BLOCK = 'inline-block',
  WEBKIT_BOX = '-webkit-box'
}
export const getRuleTitleDisplayStyle = (title: string): ERuleTitleDisplayStyle => {
  const titleArray = title.split(' ');

  if(title.length >= 50 && titleArray.length <= 2)
    return ERuleTitleDisplayStyle.INLINE_BLOCK;

  return ERuleTitleDisplayStyle.WEBKIT_BOX;

}