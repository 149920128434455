import React from 'react'

import type { ListItemProps} from '@mui/material';
import { Typography } from '@mui/material'

import { useTheme, ListItem, ListItemText, Stack, ListItemIcon } from '@mui/material'

export interface DropdownItemThreeLineProps extends ListItemProps {
  primary: React.ReactNode
  secondary: React.ReactNode
  tertiary: React.ReactNode,
  icons: React.ReactNode,
}

const testId = 'DropdownItemThreeLine'
export const DropdownItemThreeLine = (props: DropdownItemThreeLineProps) => {
  const {
    sx,
    primary,
    secondary,
    tertiary,
    icons,
    ...otherProps
  } = props

  const theme = useTheme()

  return (
    <ListItem
      data-testid={testId}
      className={testId}
      sx={{
        border: `1px solid ${theme.palette.coinrule.basic.greyBorder}`,
        borderRadius: '10px',
        ...sx
      }}
      {...otherProps}
    >
      <ListItemText 
        primaryTypographyProps={{
          noWrap: true,
          sx: {
            fontWeight: 500,
            fontSize: 24,
            lineHeight: '28px',
          }
        }}
        secondaryTypographyProps={{
          noWrap: true,
          sx: {
            fontWeight: 400,
            fontSize: 18,
            lineHeight: '28px',
          },
          component: 'div',
        }}
        primary={primary}
        secondary={
          <Stack>
            <Typography
              noWrap={true}
              sx={{
                color: theme.palette.coinrule.basic.grey
              }}
            >
              {secondary}
            </Typography>
            <Typography
              noWrap={true}
              sx={{
                color: theme.palette.coinrule.basic.black
              }}
            >
              {tertiary}
            </Typography>
          </Stack>
        }
      />
      <ListItemIcon
        sx={{ 
          height: '100%',
          minWidth: 0,
          alignSelf: 'flex-start',
          marginTop: '10px',
        }}
      >
        {icons}
      </ListItemIcon>
    </ListItem>
  )
}

export default DropdownItemThreeLine
