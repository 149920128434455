import React from 'react'

import type { BoxProps } from '@mui/material'

import { Box } from '@mui/material'

import type { TExchangeUid} from 'design-system';
import { ImageExchangesLogosWithWordmarksSprite } from 'design-system'

const WIDTH = 100

const exchangesSpritesPositions: Record<TExchangeUid, { left: number }> = {
  poloniex: {
    left: -WIDTH * 0,
  },
  bittrex: {
    left: -WIDTH * 1,
  },
  okex: {
    left: -WIDTH * 2,
  },
  'binance-us': {
    left: -WIDTH * 3,
  },
  hitbtc: {
    left: -WIDTH * 4,
  },
  kraken: {
    left: -WIDTH * 5,
  },
  coinbasepro: {
    left: -WIDTH * 6,
  },
  bitstamp: {
    left: -WIDTH * 7,
  },
  bitmex: {
    left: -WIDTH * 8,
  },
  liquid: {
    left: -WIDTH * 9,
  },
  binance: {
    left: -WIDTH * 10,
  },
  'binance-futures': {
    left: -WIDTH * 16,
  },
  bitpanda: {
    left: -WIDTH * 11,
  },
  demo: {
    left: -WIDTH * 12,
  },
  bitfinex: {
    left: -WIDTH * 13,
  },
  deribit: {
    left: -WIDTH * 14,
  },
  kucoin: {
    left: -WIDTH * 15,
  }
}

export interface ExchangeLogoWithWordmarkSpriteProps extends BoxProps {
  exchange: TExchangeUid
}

const testId = 'ExchangeLogoWithWordmarkSprite'
export const ExchangeLogoWithWordmarkSprite = (props: ExchangeLogoWithWordmarkSpriteProps) => {
  const {
    sx,
    exchange,
    ...otherProps
  } = props

  return (
    <Box
      data-testid={testId}
      className={testId}
      sx={{
        width: `${WIDTH}px`,
        height: '43px',
        display: 'inline-block',
        overflow: 'hidden',
        position: 'relative',
        ...sx
      }}
      {...otherProps}
    >
      {
        // do not render first logo when exchange is not passed
        exchange ?
          <ImageExchangesLogosWithWordmarksSprite
            style={{
              height: '100%',
              position: 'absolute',
              top: 0,
              ...exchangesSpritesPositions[exchange]
            }}
          />
          :
          null
      }
    </Box>
  )
}

export default ExchangeLogoWithWordmarkSprite
