import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import stores from '../../../stores';
import Loading from '../../../components/Loading';
import { infoNotification } from '../../../helpers/notification';
import { PAGES, STEPS } from 'stores/auth';
import AuthPage from '..';

const FacebookCallback = (props) => {
  const [step, setStep] = useState('');
  const [page, setPage] = useState('');
  stores.auth.setHistory(props.history);
  const fetchAuthStoreData = useCallback(async () => {
    const query = queryString.parse(props.location.search);
    if (query.code) {
      const { currentStep, currentPage } = await stores.auth.facebookVerify(props.location.search);
      setStep(currentStep);
      setPage(currentPage);
    } else {
      infoNotification(`There was an error. Please try again`);
      props.history.push('/');
    }
  }, [props.history, props.location.search]);
  useEffect(() => { 
    fetchAuthStoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (page === PAGES.FACEBOOK && step === STEPS.TWO_FA) {
    return <AuthPage />;
  }
  return <Loading text='Authorizing...' />;
}

export default withRouter(FacebookCallback);
