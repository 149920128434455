import React from 'react'

import type { CoinruleColorPalette } from 'design-system'
import type { StackProps } from '@mui/material'

import { useTheme, Stack, Typography } from '@mui/material'

export interface RuleProfitIndicatorProps extends StackProps {
  profit?: string
  growth?: string
  priceTrend: keyof CoinruleColorPalette['prices']
}

const testId = 'RuleProfitIndicator'
export const RuleProfitIndicator = (props: RuleProfitIndicatorProps) => {
  const {
    sx,
    profit,
    growth,
    priceTrend,
    ...otherProps
  } = props

  const theme = useTheme()

  const displayProfit = profit !== undefined
  const displayGrowth = growth !== undefined

  return (
    <Stack
      data-testid={testId}
      className={testId}
      gap={'5px'}
      direction={'row'}
      sx={{
        color: theme.palette.coinrule.prices[priceTrend],
        ...sx
      }}
      {...otherProps}
    >
      {
        displayProfit ?
          <Typography
            sx={{
              fontSize: 28,
              fontWeight: 600,
            }}
          >
            {profit}
          </Typography>
          :
          null
      }
      {
        displayGrowth ?
          <Typography
            sx={{
              fontSize: 28,
              fontWeight: 300,
            }}
          >
            {
              displayProfit ?
                `(${growth})`
                :
                growth
            }
          </Typography>
          :
          null
      }
    </Stack>
  )
}

export default RuleProfitIndicator
