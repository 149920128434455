/* eslint-disable react/no-unescaped-entities */
import React from 'react'

import type { TExchangeUid , TExchangeAddApiTutorial } from 'design-system'
import { Link, Code} from 'design-system'

export const exchangesAddApiTutorialData: Partial<Record<TExchangeUid, TExchangeAddApiTutorial>> = {
  'binance-futures': {
    title: 'How to connect your Binance account',
    steps: [
      {
        title: (
          <>
              Go On Your <strong>Binance</strong> Account
          </>
        ),
        video: 'https://youtube.com/embed/bZvh8aZOP-Y?autoplay=1',
        videoLength: 10,
        url: 'https://www.binance.com/en/my/settings/api-management',
        text: (
          <>
              Login to{' '}
            <Link
              colorVariant={'primary'}
              href={"https://www.binance.com/en/my/settings/api-management"}
              target={"__blank"}
            >
                Binance
            </Link>
             ,{' '}select your account settings on the top right, and click on “API Management”.
          </>
        ),
      },
      {
        title: (
          <>
              Create an <strong>API Key</strong>
          </>
        ),
        video: 'https://youtube.com/embed/nZaXseiVjLY?autoplay=1',
        shouldReceiveIps: true,
        videoLength: 78,
        text: (
          <>
              On your API Key, enable 'Reading' and 'Spot & Margin Trading' and add the following
              Coinrule IP as a trusted IP on Binance Futures{' '}
          </>
        ),
      },
      {
        title: (
          <>
            <strong>Copy</strong> and Paste Your Key
          </>
        ),
        video: 'https://youtube.com/embed/IWb9xt0mE8k?autoplay=1',
        videoLength: 17,
        text: (
          <>
              Copy/paste your API key and Secret Key and click on Connect. You are set for trading!
          </>
        ),
      }
    ],
  },
  'binance': {
    title: 'How to connect your Binance account',
    steps: [
      {
        title: (
          <>
              Go On Your <strong>Binance</strong> Account{' '}
          </>
        ),
        video: 'https://youtube.com/embed/4BEZDwcv0D0?autoplay=1',
        videoLength: 16,
        url: 'https://www.binance.com/en/my/settings/api-management',
        text: (
          <>
              Login to{' '}
            <Link
              colorVariant={'primary'}
              href={"https://www.binance.com/en/my/settings/api-management"}
              target={"__blank"}
            >
                Binance
            </Link>
              ,{' '}click on the top right on the "ON" button, and then click on “API Management”.
          </>
        ),
      },
      {
        title: (
          <>
              Create an <strong>API Key</strong>
          </>
        ),
        video: 'https://youtube.com/embed/fg0KkpN6VoU?autoplay=1',
        videoLength: 69,
        shouldReceiveIps: true,
        text: (
          <>
              On your API Key, enable 'Reading' and 'Spot & Margin Trading' and add the following
              Coinrule IP as a trusted IP on Binance{' '}
          </>
        ),
      },
      {
        title: (
          <>
            <strong>Copy</strong> and Paste Your Key
          </>
        ),
        video: 'https://youtube.com/embed/cULtJ6NnjHE?autoplay=1',
        videoLength: 21,
        text: (
          <>
              As final step, copy/paste the API key and Secret Key{' '}
            <Code>eg. z3UPj1wmzjCoru4302</Code> into Coinrule. You are set for trading!
          </>
        ),
      }
    ]
  },
  'binance-us': {
    title: 'How to connect your Binance US account',
    steps: [
      {
        title: (
          <>
              Go On Your <strong>Binance US</strong> Account
          </>
        ),
        video: 'https://youtube.com/embed/4BEZDwcv0D0?autoplay=1',
        url: 'https://www.binance.com/en/my/settings/api-management',
        videoLength: 16,
        text: (
          <>
              Login to{' '}
            <Link
              colorVariant={'primary'}
              href={"https://www.binance.com/en/my/settings/api-management"}
              target={"__blank"}
            >
                Binance US
            </Link>
              ,{' '}click on the top right on the "ON" button, and then click on “API Management”.
          </>
        ),
      },
      {
        title: (
          <>
              Create an <strong>API Key</strong>
          </>
        ),
        video: 'https://youtube.com/embed/fg0KkpN6VoU?autoplay=1',
        shouldReceiveIps: true,
        videoLength: 69,
        text: (
          <>
              On your API Key, enable 'Reading' and 'Spot & Margin Trading' and add the following
              Coinrule IP as a trusted IP on Binance{' '}
          </>
        ),
      },
      {
        title: (
          <>
            <strong>Copy</strong> and Paste Your Key
          </>
        ),
        video: 'https://youtube.com/embed/cULtJ6NnjHE?autoplay=1',
        videoLength: 21,
        text: (
          <>
              As final step, copy/paste the API key and Secret Key{' '}
            <Code>eg. z3UPj1wmzjCoru4302</Code> into Coinrule. You are set for trading!
          </>
        ),
      }
    ],
  },
  'bitmex': {
    title: 'How to connect your Bitmex account',
    steps: [
      {
        title: (
          <>
              Go On Your <strong>Bitmex</strong> Account{' '}
          </>
        ),
        video: 'https://youtube.com/embed/nj-fS4IPAJ4?autoplay=1',
        url: 'https://www.bitmex.com/app/apiKeys',
        videoLength: 9,
        text: (
          <>
              Select "Account" on the top of the page, then, on the left, click on API keys
          </>
        ),
      },
      {
        title: (
          <>
              Create an <strong>API Key</strong>
          </>
        ),
        video: 'https://youtube.com/embed/MB5nAJSXeuk?autoplay=1',
        videoLength: 22,
        text: (
          <>
              Set the required permissions. Once confirmed, Bitmex will show the API and Secret Key.
          </>
        ),
      },
      {
        title: (
          <>
            <strong>Copy</strong> and Paste Your Key
          </>
        ),
        video: 'https://youtube.com/embed/kOHtl-XBDpI?autoplay=1',
        videoLength: 19,
        text: (
          <>
              Finally, all you need to do is to copy and paste the API and Secret keys. You are set
              for trading!
          </>
        ),
      }
    ]
  },
  'kucoin': {
    title: 'How to connect your KuCoin account',
    steps: [
      {
        title: (
          <>
              Go On Your <strong>KuCoin</strong> Account{' '}
          </>
        ),
        url: 'https://www.kucoin.com/',
        video: 'https://www.youtube.com/embed/lUPznUrt9UQ',
        videoLength: 21,
        text: (
          <>
            Select “Account” on the top right of the page, then click on “API Management” and then “Create API”.
          </>
        ),
      },
      {
        title: (
          <>
              Create an <strong>API Key</strong>
          </>
        ),
        video: 'https://www.youtube.com/embed/nA366ZD1hn4',
        videoLength: 25,
        text: (
          <>
            Name your API and set an API passphrase. Next, check the box next to “Trade” and under IP restrictions check the box next to “No”.
          </>
        ),
      },
      {
        title: (
          <>
            <strong>Copy</strong> and Paste Your Key
          </>
        ),
        video: 'https://www.youtube.com/embed/fp9huu8IbwQ',
        videoLength: 49,
        text: (
          <>
          Finally, go through the security verification, and copy and paste the both the public and secret keys into Coinrule. You’re all set for trading! 
          </>
        ),
      }
    ],
  },
  'hitbtc': {
    title: 'How to connect your HitBTC account',
    steps: [
      {
        title: (
          <>
              Go On Your <strong>HitBTC</strong> Account{' '}
          </>
        ),
        url: 'https://hitbtc.com/settings/api-keys',
        video: 'https://youtu.be/LqBOuo-bNTE',
        videoLength: 27,
        text: (
          <>
              Login to{' '}
            <Link
              colorVariant={'primary'}
              href={"https://hitbtc.com/settings/api-keys"}
              target={"__blank"}
            >
                HitBTC{' '}
            </Link>
              and select “Settings” from the top menu bar. In the “API Key” tab click on "New API
              Key". When created, copy the Secret Key.
          </>
        ),
      },
      {
        title: (
          <>
              Grant The Required <strong>Permissions</strong>
          </>
        ),
        video: 'https://youtu.be/sedRUwg7CVU',
        videoLength: 57,
        text: 'Flag "Trading Balance", "Place/cancel orders" and "Payment Information". Insert the Authenticator Code and click on the verification link when provided by email.',
      },
      {
        title: (
          <>
            <strong>Copy</strong> and Paste Your Key
          </>
        ),
        video: 'https://youtu.be/eHvRm2jk004',
        videoLength: 33,
        text: 'As final step, copy/paste the API key and Secret Key in the Exchange section of Coinrule. You are set for trading!',
      }
    ],
  },
  'bitstamp': {
    title: 'How to connect your Bitstamp account',
    steps: [
      {
        title: (
          <>
              Go On Your <strong>Bitstamp</strong> Account{' '}
          </>
        ),
        video: 'https://youtube.com/embed/X1GJOi1Wxc0?autoplay=1',
        videoLength: 14,
        url: 'https://www.bitstamp.net/settings/access-control/api/',
        text: (
          <>
            {' '}
              Login to{' '}
            <Link
              colorVariant={'primary'}
              href={"https://www.bitstamp.net/settings/access-control/api/"}
              target={"__blank"}
            >
                Bitstamp,{' '}
            </Link>
              and select “Account” from the top menu bar. “API access” is in the “Security” section
              on the left.
          </>
        ),
      },
      {
        title: (
          <>
              Create an <strong>API Key</strong>
          </>
        ),
        video: 'https://youtube.com/embed/PC4rUS30nQc?autoplay=1',
        videoLength: 53,
        text: "Give Coinrule permissions to view your Account balance and place orders and confirm by clicking on 'Generate Key'. Activate your keys in the next screen.",
      },
      {
        title: (
          <>
            <strong>Copy</strong> and Paste Your Key
          </>
        ),
        video: 'https://youtube.com/embed/61u31Kv7I4A?autoplay=1',
        videoLength: 49,
        text: (
          <>
              As final step, copy/paste the API key and Secret Key{' '}
            <Code>eg. z3UPj1wmzjCoru4302</Code> into Coinrule. You are set for trading!
          </>
        ),
      }
    ]
  },
  'coinbasepro': {
    title: 'How to connect your Coinbase | Pro account',
    steps: [
      {
        url: 'https://pro.coinbase.com/profile/api',
        title: (
          <>
              Go On Your <strong> Coinbase | Pro</strong> Account{' '}
          </>
        ),
        video: 'https://youtube.com/embed/w0r408cXS1A?autoplay=1',
        videoLength: 7,
        text: (
          <>
              Login to{' '}
            <Link
              colorVariant={'primary'}
              href={"https://pro.coinbase.com/profile/api"}
              target={"__blank"}
            >
                Coinbase Pro,{' '}
            </Link>
              select ‘Account Settings’ on the top right, and press “New API Key”.,
          </>
        ),
      },
      {
        title: (
          <>
              Create an <strong>API Key</strong>
          </>
        ),
        video: 'https://youtube.com/embed/C4z_VCDLevM?autoplay=1',
        videoLength: 67,
        text: 'Add the details required in the form. Make sure to only give ‘View’ and ‘Trade’ permissions. Ultimately, take a note of your Secret Key. Press ‘Create’.',
      },
      {
        title: (
          <>
            <strong>Copy</strong> and Paste Your Key
          </>
        ),
        video: 'https://youtube.com/embed/dcWK2qbJh6k?autoplay=1',
        videoLength: 42,
        text: (
          <>
              As final step, copy/paste the two keys you created{' '}
            <Code>eg. z3UPj1wmzjCoru4302</Code> and pass phrase into Coinrule. You are set
              for trading!
          </>
        ),
      }
    ]
  },
  'kraken': {
    title: 'How to connect your Kraken account',
    steps:[
      {
        title: (
          <>
              Go On Your <strong> Kraken</strong> Account{' '}
          </>
        ),
        url: 'https://www.kraken.com/u/security/api',
        video: 'https://youtube.com/embed/m-a258nm2bQ?autoplay=1',
        videoLength: 17,
        text: (
          <>
              Login to{' '}
            <Link
              colorVariant={'primary'}
              href={"https://www.kraken.com/u/security/api"}
              target={"__blank"}
            >
                Kraken,{' '}
            </Link>
              and click on the top right button. Under “Settings” you will find API.
          </>
        ),
      },
      {
        title: (
          <>
              Create an <strong>API Key</strong>
          </>
        ),
        video: 'https://youtube.com/embed/WiD_YKU-D7M?autoplay=1',
        videoLength: 29,
        text: 'Click on the “Generate New Key” button, name the API and grant all the required permissions. Then click on “Generate Key”.',
      },
      {
        title: (
          <>
            <strong>Copy</strong> and Paste Your Keys
          </>
        ),
        video: 'https://youtube.com/embed/zM75NNjZDvQ?autoplay=1',
        videoLength: 46,
        text: (
          <>
              Copy/paste the API Key and Private Key into the "Exchanges" page on Coinrule. You are
              now set for trading!
          </>
        ),
      },
    ]
  },
  'bitpanda': {
    title: 'How to connect your Bitpanda account',
    steps: [
      {
        title: (
          <>
              Go On Your <strong> Bitpanda Pro</strong> Account{' '}
          </>
        ),
        url: 'https://exchange.bitpanda.com/account/keys',
        video: 'https://youtube.com/embed/BLv2TweyyGg?autoplay=1',
        videoLength: 15,
        text: (
          <>
              Login to{' '}
            <Link
              colorVariant={'primary'}
              href={"https://exchange.bitpanda.com/account/keys"}
              target={"__blank"}
            >
                Bitpanda,{' '}
            </Link>
              click on the Panda icon on the top right and select API. Then click on "New Api Key".
          </>
        ),
      },
      {
        title: (
          <>
              Create an <strong>API Key</strong>
          </>
        ),
        video: 'https://youtube.com/embed/GYfVgwz5WCk?autoplay=1',
        videoLength: 28,
        text: 'Name your API Key and grant the required permissions, then click on “Create API Key”. You will receive a confirmation email, click on the link provided.',
      },
      {
        title: (
          <>
            <strong>Copy</strong> and Paste Your Key
          </>
        ),
        video: 'https://youtube.com/embed/G5X47KGufqo?autoplay=1',
        videoLength: 24,
        text: (
          <>
              As final step, copy/paste the Key you created into Coinrule. You are set for trading!
          </>
        ),
      },
    ]
  },
  'okex': {
    title: 'How to connect your OKX account',
    steps: [
      {
        title: (
          <>
              Go On Your <strong> Okx</strong> Account{' '}
          </>
        ),
        url: 'https://www.okex.com/account/my-api',
        video: 'https://youtube.com/embed/DXWTWeCitVQ?autoplay=1',
        videoLength: 20,
        text: (
          <>
              Login to{' '}
            <Link
              colorVariant={'primary'}
              href={"https://www.okex.com/account/my-api"}
              target={"__blank"}
            >
                OKX,{' '}
            </Link>
              Select the account settings on the top right and click on "API", then make sure you
              create a V3 API Key.
          </>
        ),
      },
      {
        title: (
          <>
              Create an <strong>API Key</strong>
          </>
        ),
        video: 'https://youtube.com/embed/oSfD3IWxoWE?autoplay=1',
        videoLength: 46,
        text: 'Name the new API, choose a passphrase, grant the needed permissions and confirm with your Google Authenticator code.',
      },
      {
        title: (
          <>
            <strong>Copy</strong> and Paste Your Key
          </>
        ),
        video: 'https://youtube.com/embed/JcXPkQRbcNE?autoplay=1',
        videoLength: 41,
        text: (
          <>
              Copy/paste both the API Key and the Secret Key in the "Exchanges" section of your
              Coinrule account and digit your passphrase.
          </>
        ),
      }
    ]
  },
  'bittrex': {
    title: 'How to connect your Bittrex account',
    steps: [
      {
        title: (
          <>
              Go On Your <strong>Bittrex</strong> Account{' '}
          </>
        ),
        video: 'https://youtube.com/embed/DUS4D0bxjUQ?autoplay=1',
        videoLength: 14,
        url: 'https://global.bittrex.com/account/login',
        text: (
          <>
              Login to{' '}
            <Link
              colorVariant={'primary'}
              href={"https://global.bittrex.com/account/login"}
              target={"__blank"}
            >
                Bittrex,{' '}
            </Link>
              On the top right click on Account. In the next page select API Keys in the side menu
              on the left.
          </>
        ),
      },
      {
        title: (
          <>
              Create an <strong>API Key</strong>
          </>
        ),
        video: 'https://youtube.com/embed/hEx-ccAxjFI?autoplay=1',
        videoLength: 32,
        text: 'Select "add new KEY" and enable the necessary permissions. Then click on Save.',
      },
      {
        title: (
          <>
            <strong>Copy</strong> and Paste Your Key
          </>
        ),
        video: 'https://youtube.com/embed/cbIlwSG3NKs?autoplay=1',
        videoLength: 32,
        text: (
          <>
              You can now copy/paste the generated keys in the "Exchanges" section of your Coinrule
              account.
          </>
        ),
      }
    ]
  },
  'poloniex': {
    title: 'How to connect your Poloniex account',
    steps: [
      {
        title: (
          <>
              Go On Your <strong>Poloniex</strong> Account{' '}
          </>
        ),
        url: 'https://poloniex.com/apiKeys',
        video: 'https://youtube.com/embed/4-hipaKW-_0?autoplay=1',
        videoLength: 13,
        text: (
          <>
              Login to{' '}
            <Link
              colorVariant={'primary'}
              href={"https://poloniex.com/apiKeys"}
              target={"__blank"}
            >
                Poloniex,
            </Link>{' '}
              select the Setting tab on the top right, and click on "API Keys".
          </>
        ),
      },
      {
        title: (
          <>
              Create an <strong>API Key</strong>
          </>
        ),
        video: 'https://youtube.com/embed/kL8P4COFe6Y?autoplay=1',
        videoLength: 58,
        text: 'Click on "Create new Key", digit your 2FA code and confirm the link in the email that you will receive.',
      },
      {
        title: (
          <>
            <strong>Copy</strong> and Paste Your Key
          </>
        ),
        video: 'https://youtube.com/embed/F_JvEpowukk?autoplay=1',
        videoLength: 46,
        text: (
          <>
              As a final step, copy/paste the API key and secret key you created into Coinrule. You
              are set for trading!
          </>
        ),
      }
    ]
  },
  'liquid': {
    title: 'How to connect your Liquid account',
    steps: [
      {
        title: (
          <>
              Go On Your <strong>Liquid</strong> Account{' '}
          </>
        ),
        video: 'https://youtube.com/embed/tJg5WpAUPHI?autoplay=1',
        videoLength: 13,
        url: 'https://app.liquid.com/settings/api-tokens',
        text: (
          <>
              Login to{' '}
            <Link
              colorVariant={'primary'}
              href={"https://app.liquid.com/settings/api-tokens"}
              target={"__blank"}
            >
                Liquid,{' '}
            </Link>{' '}
              select account icon on the top right, and click on "Security".
          </>
        ),
      },
      {
        title: (
          <>
              Create an <strong>API Token</strong>
          </>
        ),
        video: 'https://youtube.com/embed/2r9tRzaizeU?autoplay=1',
        videoLength: 52,
        text: 'Create a new API token and grant all the required permissions. Digit your Google Authenticator code to confirm.',
      },
      {
        title: (
          <>
            <strong>Copy</strong> and Paste Your Token
          </>
        ),
        video: 'https://youtube.com/embed/3UHGACrdsug?autoplay=1',
        videoLength: 33,
        text: (
          <>
              Copy/paste your Token ID and the Token Secret and click on Connect. You are set for
              trading!
          </>
        ),
      }
    ]
  }
}

export default exchangesAddApiTutorialData