import { PlanContext } from 'components/PlanBox';
import { IS_PROD } from 'constants/isProd';
import { ANALYTICS_EVENTS, sendAnalytics } from 'helpers/analytics';
import { SELECTED_PLAN_DATA } from 'helpers/payment';
import { INITIATE_CHECKOUT } from 'helpers/trackings';
import { setDataLocalStorage } from 'helpers/utils';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import './style.scss';

const handleAnalytics = () => {
  sendAnalytics('mixpanel', {
    event: ANALYTICS_EVENTS.PAYMENT_BUTTON_CLICKED,
    data: { location: 'billing page' },
  });

  sendAnalytics('facebook', { events: ['track'], data: INITIATE_CHECKOUT });
};

type PaymentButtonViewProps = {
  buttonText: string,
  onClick: () => any,
}


export const PaymentButtonTemplate: React.FC<PaymentButtonViewProps> = observer(({ buttonText, onClick }) => {
  const [isLoading, setIsLoading] = useState(false);

  const contextValue: any = React.useContext(PlanContext);

  const { plan_code, amountToPay } =contextValue;


  if (isLoading) return <span className='current'>Please wait...</span>;

  const isLoadingWrapper = (onClickFn: (args: IArguments) => {}) => {
    return function(){
      try {
        setIsLoading(true)
        if (IS_PROD) handleAnalytics();
        setDataLocalStorage(SELECTED_PLAN_DATA, { label: plan_code, value: amountToPay });
        onClickFn(arguments)
      } finally {
        setIsLoading(false);
      }
    }
  }

    const cssClasses = 'coinruleBut coinruleBut_medium coinruleBut_crimson coinruleBut_iconPlus';
    return (
      <div role='button' className={cssClasses} onClick={isLoadingWrapper(onClick)}>
        {buttonText}
      </div>
    );
  }
);
