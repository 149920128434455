import Spinner from 'components/Loading/Spinner';
import React from 'react';
import TradeFeed from '../TradeFeed';
import ScrollBar from 'components/ScrollBar';

const TradeFeeds = ({ tradeFeeds, isLoading }) => {
  if(isLoading) return <Spinner />

  return (
    <ScrollBar>
      {tradeFeeds.map((tradeFeed, index) => 
        <TradeFeed 
          {...tradeFeed}
          key={index}
        />
      )}
    </ScrollBar>
  )
}
export default React.memo(TradeFeeds);