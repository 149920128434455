/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ReferencesDto {
  externalId: string;
  stripeIdYearly: string;
  stripeIdMonthly: string;
}

export interface SettingsDto {
  fee: number;
  trial: number;
  clearance: number;
  isDefault: boolean;
  isEnabled: boolean;
  isPremium: boolean;
  isVisible: boolean;
  isEnterprise: boolean;
}

export interface LimitsDto {
  maxRules: number;
  hasAnyTime: boolean;
  maxDemoRules: number;
  maxPortfolio: number;
  maximumActions: number;
  maxTradingVolume: number;
  maximumConditions: number;
  numberOfExchanges: number;
  numberOfTemplates: number;
  maximumIntersections: number;
}

export interface PlanDto {
  /** @format decimal */
  price: string;

  /** @format decimal */
  annualPrice: string;
  id: number;
  name: string;
  code: string;
  references: ReferencesDto;
  settings: SettingsDto;
  limits: LimitsDto;
}

export interface CouponDTO {
  status: 'active' | 'expired' | 'archived' | 'deleted';
  apply_on: 'invoice_amount' | 'each_specified_item';
  discount_type: 'fixed_amount' | 'percentage';
  duration_type: 'one_time' | 'forever' | 'limited_period';
  period_unit?: 'day' | 'week' | 'month' | 'year';
  id: string;
  name: string;

  /** @format date-time */
  created_at: string;

  /** @format date-time */
  updated_at: string;
  period?: number;
  discount_percentage?: number;
  discount_amount?: number;
  constraints?: string[];
}

export interface SubscriptionDto {
  status: string;

  /** @format date-time */
  current_term_start: string;

  /** @format date-time */
  current_term_end: string;

  /** @format date-time */
  started_at: string;

  /** @format date-time */
  updated_at: string;
  subscription_code: string;
  amount_in_cents: number;
  billing_period_unit: string;
  currency_code: string;

  /** @format date-time */
  next_billing_at?: string;
}

export interface ChargeBeeClientRequestDto {
  item_price_id: string;
  quantity: number;
  cf_affiliate_id?: string;
  coupons?: string[];
}

export interface UpdatePaymentMethodDto {
  id?: string;
  type?: object;
  url?: string;
  state?: object;
  pass_thru_content?: string;
  created_at?: number;
  expires_at?: number;
  content?: object;
  updated_at?: number;
  resource_version?: number;
  checkout_info?: object;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
          ? JSON.stringify(property)
          : `${property}`
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      requestParams.headers.common = { Accept: '*/*' };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Cointrule Payment Service
 * @version 0.0.4
 * @contact
 *
 * Coinrule API spec for the payments service
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  ps = {
    /**
     * No description
     *
     * @name PlanControllerFindByName
     * @request GET:/ps/plan/name/{name}
     */
    planControllerFindByName: (name: string, params: RequestParams = {}) =>
      this.request<PlanDto, any>({
        path: `/ps/plan/name/${name}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name PlanControllerFindActive
     * @request GET:/ps/plan
     */
    planControllerFindActive: (params: RequestParams = {}) =>
      this.request<PlanDto[], any>({
        path: `/ps/plan`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name PlanControllerFindById
     * @request GET:/ps/plan/id/{id}
     */
    planControllerFindById: (id: number, params: RequestParams = {}) =>
      this.request<PlanDto, any>({
        path: `/ps/plan/id/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CouponControllerGetCouponById
     * @request GET:/ps/coupon/{coupon_id}
     */
    couponControllerGetCouponById: (couponId: string, params: RequestParams = {}) =>
      this.request<CouponDTO, any>({
        path: `/ps/coupon/${couponId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name SubscriptionControllerCancel
     * @request DELETE:/ps/subscription
     */
    subscriptionControllerCancel: (params: RequestParams = {}) =>
      this.request<SubscriptionDto, any>({
        path: `/ps/subscription`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name SubscriptionControllerGetSubscriptionByCustomerId
     * @request GET:/ps/subscription
     */
    subscriptionControllerGetSubscriptionByCustomerId: (params: RequestParams = {}) =>
      this.request<SubscriptionDto, void>({
        path: `/ps/subscription`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name SubscriptionControllerKeep
     * @request POST:/ps/subscription
     */
    subscriptionControllerKeep: (params: RequestParams = {}) =>
      this.request<SubscriptionDto, any>({
        path: `/ps/subscription`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name HostedPagesControllerCreate
     * @request POST:/ps/hosted-page
     */
    hostedPagesControllerCreate: (data: ChargeBeeClientRequestDto, params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/ps/hosted-page`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdatePaymentMethodControllerUpdate
     * @request GET:/ps/update-payment-method
     */
    updatePaymentMethodControllerUpdate: (params: RequestParams = {}) =>
      this.request<UpdatePaymentMethodDto, any>({
        path: `/ps/update-payment-method`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
}
