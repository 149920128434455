import type { IActionsInterceptor } from "v2/types/IActionsInterceptor";
import type { TActionsInterceptorListener } from "v2/types/TActionsInterceptorListener";

export class ActionsInterceptor implements IActionsInterceptor {
  private listeners: TActionsInterceptorListener[]

  constructor(){
    this.listeners = []
  }

  public getListeners(){
    return this.listeners
  }

  public addActionListener({ listener }: { listener: TActionsInterceptorListener }){
    this.listeners = this.listeners.concat(listener)
  }

  public removeActionListener({ listener: listenerToRemove }: { listener: TActionsInterceptorListener }){
    this.listeners = this.listeners.filter((listener) => listener === listenerToRemove)
  }
}