import React from 'react'

export interface IconRuleFinishedProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconRuleFinished'
export const IconRuleFinished = (props: IconRuleFinishedProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 23 23"}
      enableBackground={"new 0 0 23 23"}
      xmlSpace={"preserve"}
      width={"1em"}
      height={"1em"}
      {...otherProps}
    >
      <path
        d={"M17.8 3.3H5.2c-.9 0-1.7.7-1.7 1.7v12.6c0 .9.8 1.7 1.7 1.7h12.6c.9 0 1.7-.8 1.7-1.7V5c0-1-.8-1.7-1.7-1.7zm0 14.2H5.2V5h12.6v12.5zm-1.3-8.6L10.3 15c-.2.2-.4.2-.6 0l-3.2-3.3c-.2-.2-.2-.4 0-.6l.8-.8c.2-.2.4-.2.6 0l2.1 2.2 5-5c.2-.2.4-.2.6 0l.8.8c.3.2.3.4.1.6z"}
        fill={"#05b4bc"}
      />
    </svg>
  )
}

export default IconRuleFinished
