import React from 'react';

import {
  defaultNotify,
  NOTIFY_TYPE,
} from '../addRule';

import Then from '../../pages/AddRulePage/Then';

export const handleNotifyType = (
  addRuleStore,
  addRuleParent,
  { idCount, data = defaultNotify  }
) => {

  const component = {
    id: idCount,
    errors: [],
    type: NOTIFY_TYPE,
    data: {
      notifies: [data],
    },
    component: (
      <Then key={idCount} store={addRuleStore} parent={addRuleParent} id={idCount} partIndex={0} />
    ),
  };

  return component;
};
