import React, { useEffect, useState } from 'react'

import GlobalContent from '../../../components/GlobalContent';
import BackIcon from '../BackIcon';
import FirstTicker from '../FirstTicker';

import AboutCoin from '../About';
import SymbolWidget from '../SymbolWidget';
import CryptoWidget from '../CryptoWidget';
import WatchList from '../WatchList';
import IconLoading from 'components/Loading/IconLoading';
import { observer } from 'mobx-react';
import { toJS, autorun } from 'mobx';

import { Col, Row } from 'antd';
import { formatExchangePairsData } from 'helpers/utils';
import NewsTradeFeeds from '../NewsTradeFeeds';
import stores from 'stores';

export interface DataStudioContentProps {
  isLoading: string
  onToggleStudio: () => void
}

const DataStudioContent = ({
  isLoading, 
  onToggleStudio, 
}: DataStudioContentProps) => {
  const [state, setState] = useState({
    from: 'BTC',
    to: stores.user.user.baseCurrency?.toUpperCase() || 'USD',
    exchangeName: 'BINANCE',
    exchangePairs: [],
    exchangeId: '',
  });

  useEffect(() => {
    const { info, userInfo } = stores;
    // @FIXME this is probably unnecessary call as we already should have lists loaded
    info.getLists().then((authorized: boolean) => stores.app.unauthorized(authorized));
    userInfo.getExchanges().then((authorized: boolean) => stores.app.unauthorized(authorized));
  }, []);

  useEffect(
    () =>
      autorun(() => {
        const getExchangePairs = () => {
          const markets = toJS(stores.exchangeInfo.markets);
          const exchanges = toJS(stores.info.exchanges);

          const exchangePairs: any = formatExchangePairsData(markets, exchanges);

          setState((initialState) => ({
            ...initialState,
            exchangePairs,
          }));
        };

        getExchangePairs();
      }),
    []
  );

  const updatePairNames = ({ from, to, exchangeId, exchangeName }: {from: string, to: string, exchangeId: string, exchangeName: string}) =>
    setState((initialValue) => ({
      ...initialValue,
      from,
      to,
      exchangeName,
      exchangeId,
    }));

  const { from, to, exchangeName, exchangePairs, exchangeId } = state;

  return (
    <GlobalContent extraClass={`DataStudioPage__wrapperBody`}>
    <BackIcon isLink={false} onClick={onToggleStudio} title={<h1 className='heading-text'>Data Studio</h1>} />

    {isLoading ? (
      <IconLoading normalLoader />
    ) : (
      <>
        <div className='DataStudioPage__contentWrapper'>
          <Row className={'top-mover margin-bottom-20'}>
            <Col xs={{ span: 3 }}>
              <h4 className={'small-text'}>Top movers</h4>
            </Col>

            <FirstTicker />
          </Row>

          <SymbolWidget
            from={from}
            to={to}
            exchangeName={exchangeName}
            exchanges={exchangePairs}
            updatePairNames={updatePairNames}
          />

          <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Col xs={{ span: 24 }} lg={{ span: 17 }}>
              <AboutCoin exchangeId={exchangeId} coin={from} />

              <WatchList exchangeCoins={exchangePairs} />

              <div style={{ marginTop: '30px' }}>
                <h1 className='sub-heading'>Today's Prices</h1>
                <CryptoWidget />
              </div>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <NewsTradeFeeds />
            </Col>
          </Row>
        </div>
      </>
    )}
  </GlobalContent>
  )
}

export default observer(DataStudioContent)