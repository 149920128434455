import React from 'react'

export interface IconPlayCircleProps extends React.SVGProps<SVGSVGElement> { }

const testId = 'IconPlayCircle'
export const IconPlayCircle = (props: IconPlayCircleProps) => {

  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      width={"1em"}
      height={"1em"}
      viewBox={"0 0 19 19"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...otherProps}
    >
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={"M9.86719 0C14.8378 0 18.8672 4.02944 18.8672 9C18.8672 13.9706 14.8378 18 9.86719 18C4.89663 18 0.867191 13.9706 0.867191 9C0.867191 4.02944 4.89663 0 9.86719 0ZM9.0283 6.71289C8.57866 6.43452 7.99805 6.75795 7.99805 7.28682V10.7134C7.99805 11.2422 8.57866 11.5657 9.0283 11.2873L11.7959 9.57402C12.2222 9.31013 12.2222 8.69005 11.7959 8.42616L9.0283 6.71289Z"}
      />
    </svg>
  )
}

export default IconPlayCircle
