import type { DTOShareRuleSlug } from "v2/domains/shareRule/DTOs/DTOShareRuleSlug"
import type { TModelShareRuleSlug } from "./externalDataModels/TModelShareRuleSlug"

export const converterShareRule =  {
  toDTO: ({ shareRuleSlug, ruleId }: { shareRuleSlug: TModelShareRuleSlug, ruleId: string }): DTOShareRuleSlug => {
    return {
      slug: shareRuleSlug.data.slug,
      ruleId
    }
  }
}