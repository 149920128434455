import React from 'react'

export interface IconRuleEnabledProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconRuleEnabled'
export const IconRuleEnabled = (props: IconRuleEnabledProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      width={"1.4em"}
      height={"1.4em"}
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 23 23"}
      xmlSpace={"preserve"}
      {...otherProps}
    >
      <path
        d={"M11.6 20c-4.7 0-8.5-3.6-8.5-8.1 0-2.6 1.3-4.9 3.4-6.5l1.3 1.7c-1.5 1.1-2.5 2.8-2.5 4.7 0 3.3 2.9 6 6.4 6s6.4-2.7 6.4-6c0-1.9-1-3.7-2.5-4.8l1.3-1.7c2.1 1.6 3.3 3.9 3.3 6.5-.1 4.6-3.9 8.2-8.6 8.2zM10.4 3h2.3v8.2h-2.3V3z"}
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#05b4bc",
        }}
      />
    </svg>
  )
}

export default IconRuleEnabled
