import React from 'react';

import { ReactComponent as BuyTheDip } from 'assets/images/graphs/buy-the-dips.svg';
import { ReactComponent as BuyDipBull } from 'assets/images/graphs/buy-dip-in-bull.svg';
import { ReactComponent as BuyUpTrend } from 'assets/images/graphs/buy-the-uptrend.svg';
import { ReactComponent as GoldenCrossTrading } from 'assets/images/graphs/golden-cross-trading.svg';
import { ReactComponent as HAStrategy } from 'assets/images/graphs/HAStrategy.svg';
import { ReactComponent as LowVol } from 'assets/images/graphs/low-volatility.svg';
import { ReactComponent as GMAScalper } from 'assets/images/graphs/MAScalper..svg';
import { ReactComponent as MaxBollinger } from 'assets/images/graphs/maxBollingerBand.svg';
import { ReactComponent as MaxMACD } from 'assets/images/graphs/maxMACD.svg';
import { ReactComponent as MaxMACrossing } from 'assets/images/graphs/maxMACrossing.svg';
import { ReactComponent as MaxRSIStrategy } from 'assets/images/graphs/maxRSIStrategy.svg';
import { ReactComponent as MaxSTrend } from 'assets/images/graphs/maxSTrend.svg';
import { ReactComponent as MultiMACrossing } from 'assets/images/graphs/multiMACrossing.svg';
import { ReactComponent as RideTrend } from 'assets/images/graphs/rideTrend.svg';
import { ReactComponent as RSIBTC } from 'assets/images/graphs/RSI_BTC.svg';
import { ReactComponent as RSIETH } from 'assets/images/graphs/RSI_ETH.svg';
import { ReactComponent as RSILTC } from 'assets/images/graphs/RSI_LTC.svg';
import { ReactComponent as RSISOL } from 'assets/images/graphs/RSI_SOL.svg';
import { ReactComponent as RSIXRP } from 'assets/images/graphs/RSI_XRP.svg';
// import { ReactComponent as RSIClassicStrategy } from 'assets/images/graphs/rsi-classic-strategy.svg';
import { ReactComponent as ScalpingDips } from 'assets/images/graphs/scalping-dips-on-trend.svg';
import { ReactComponent as ShortTerm } from 'assets/images/graphs/short-term-swing.svg';

export const EXCHANGE_UNLOCKS_TEMPLATES = 3; // how many extra templates the user gets after connecting an exchange

export interface ITemplateAdditionalData extends Record<string, any> {
  tutorialUrl?: string;
}

const buyTheDipsData = {
  label: 'Tested',
  trades: 110,
  profit: 59.09,
  pair: 'BTC/USDT',
  duration: '8 months',
  graphName: <BuyTheDip />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
  returns: 15.31,
};

const buyTheUptrend = {
  label: 'Tested',
  trades: 116,
  profit: 50.86,
  pair: 'ETH/USDT',
  duration: '8 months',
  graphName: <BuyUpTrend />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
  returns: 29,
  tutorialUrl: 'https://help.coinrule.com/en/articles/4754877-buy-the-uptrend-flash-crash',
};

const lowVolatility = {
  label: 'Tested',
  trades: 21,
  returns: 323,
  profit: 33.33,
  pair: 'ETH/USDT',
  duration: '8 months',
  graphName: <LowVol />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
  tutorialUrl: 'https://help.coinrule.com/en/articles/4787594-low-volatility-buy-and-sell',
};

const scalpingDipsOnTrend = {
  label: 'Tested',
  trades: 20,
  returns: 57.37,
  profit: 90,
  pair: 'BTC/USDT',
  duration: '8 months',
  graphName: <ScalpingDips />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
  tutorialUrl: 'https://help.coinrule.com/en/articles/5108170-scalping-dips-on-trend',
};

const catchThePriceSWing = {
  label: 'Tested',
  trades: 66,
  returns: 10.74,
  profit: 65.15,
  pair: 'BTC/USDT',
  duration: '8 months',
  graphName: <ShortTerm />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
  tutorialUrl: 'https://help.coinrule.com/en/articles/4930109-catch-the-price-swing',
};

const shortTermSwing = {
  label: 'Tested',
  trades: 20,
  returns: 57.37,
  profit: 90,
  pair: 'ETH/USDT',
  duration: '8 months',
  graphName: <ShortTerm />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
};

const goldenCrossTrading = {
  label: 'Tested',
  trades: 101,
  returns: 111.07,
  profit: 35,
  pair: 'ETH/USDT',
  duration: '8 months',
  graphName: <GoldenCrossTrading />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
};

const buyTheDipInBull = {
  label: 'Tested',
  trades: 20,
  returns: 57.37,
  profit: 90,
  pair: 'BTC/USDT',
  duration: '8 months',
  graphName: <BuyDipBull />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
  tutorialUrl: 'https://help.coinrule.com/en/articles/4798879-buy-the-dips-in-bull-market',
};

const RSI_BTC = {
  label: 'Tested',
  trades: 26,
  returns: 14.29,
  profit: 65,
  pair: 'BTC/USDT',
  duration: '8 months',
  graphName: <RSIBTC />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
  tutorialUrl: 'https://help.coinrule.com/en/articles/5612485-rsi-based-dollar-cost-averaging',
};

const RSI_LTC = {
  label: 'Tested',
  trades: 35,
  returns: 11.61,
  profit: 57,
  pair: 'LTC/USDT',
  duration: '8 months',
  graphName: <RSILTC />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
  tutorialUrl: 'https://help.coinrule.com/en/articles/5612485-rsi-based-dollar-cost-averaging',
};

const RSI_ETH = {
  label: 'Tested',
  trades: 21,
  returns: 74.5,
  profit: 100,
  pair: 'ETH/USDT',
  duration: '8 months',
  graphName: <RSIETH />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
  tutorialUrl: 'https://help.coinrule.com/en/articles/5612485-rsi-based-dollar-cost-averaging',
};

const RSI_RIPPLE = {
  label: 'Tested',
  trades: 25,
  returns: 57.56,
  profit: 96,
  pair: 'XRP/USDT',
  duration: '8 months',
  graphName: <RSIXRP />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
};

const RSI_SOLANA = {
  label: 'Tested',
  trades: 22,
  returns: 314.02,
  profit: 100,
  pair: 'SOL/USDT',
  duration: '8 months',
  graphName: <RSISOL />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
};

const rideTrend = {
  label: 'Tested',
  trades: 26,
  returns: 46.97,
  profit: 58,
  pair: 'ETH/USDT',
  duration: '8 months',
  graphName: <RideTrend />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
  tutorialUrl: 'https://help.coinrule.com/en/articles/4791144-ride-the-trend',
};

const maxRSIStrategy = {
  label: 'Tested',
  trades: 81,
  returns: 70,
  profit: 23.01,
  pair: 'ETH/USDT',
  duration: '8 months',
  graphName: <MaxRSIStrategy />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
  tutorialUrl: 'https://help.coinrule.com/en/articles/4832534-maximized-rsi-strategy',
};

const maxMACrosing = {
  label: 'Tested',
  trades: 26,
  returns: 196.38,
  profit: 40,
  pair: 'ETH/USDT',
  duration: '8 months',
  graphName: <MaxMACrossing />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
  tutorialUrl: 'https://help.coinrule.com/en/articles/4786855-maximized-moving-average-crossing',
};

const multiMACrosing = {
  label: 'Tested',
  trades: 26,
  returns: 49,
  profit: 44,
  pair: 'ETH/USDT',
  duration: '8 months',
  graphName: <MultiMACrossing />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
  tutorialUrl: 'https://help.coinrule.com/en/articles/4966475-multi-moving-average-crossing',
};

const maxScalpingOnTrend = {
  label: 'Tested',
  trades: 144,
  returns: 1244.52,
  profit: 60,
  pair: 'ETH/USDT',
  duration: '8 months',
  graphName: <MaxSTrend />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
  tutorialUrl: 'https://help.coinrule.com/en/articles/5320587-maximized-scalping-on-trend',
};

const MAScalper = {
  label: 'Tested',
  returns: 13.69,
  profit: 69.05,
  trades: 42,
  pair: 'ETH/USDT',
  duration: '8 months',
  graphName: <GMAScalper />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
  tutorialUrl: 'https://help.coinrule.com/en/articles/5179248-moving-average-scalper',
};

const HAshiStrategy = {
  label: 'Tested',
  returns: 284.6,
  profit: 75,
  trades: 24,
  pair: 'ETH/USDT',
  duration: '20 months',
  graphName: <HAStrategy />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
  description: 'This strategy from the TradingView community uses an optimized Heikin Ashi with a modified MA to catch price swings. The system has a solid track record, and works best on the 1-hour time frame',
  videoUrl: `https://youtube.com/embed/xKuOKxxi2kw`,
  tutorialUrl: `https://coinrule.com/help/knowledgebase/trade-with-heikin-ashi`,
};

const maxBollingerBands = {
  label: 'Tested',
  returns: 54.52,
  profit: 83,
  trades: 12,
  pair: 'ETH/USDT',
  duration: '32 months',
  graphName: <MaxBollinger />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
  videoUrl: `https://youtube.com/embed/I-OJlikpgiU`,
  tutorialUrl:
    'https://coinrule.com/help/knowledgebase/trade-with-bollinger-bands-and-aroon-indicator/',
};

const maxMACD = {
  label: 'Tested',
  returns: 40.99,
  profit: 52.27,
  trades: 88,
  pair: 'ETH/USDT',
  duration: '44 months',
  graphName: <MaxMACD />,
  startDate: 'Jan 2021',
  endDate: 'Aug 2021',
  videoUrl: 'https://youtube.com/embed/PCzRZZCCPa8',
  tutorialUrl: 'https://coinrule.com/help/knowledgebase/trade-with-macd-dmi-volatility-stop/',
};

export const TEMPLATES_DATA: Record<string, ITemplateAdditionalData> = {
  '5fa67fde15e3e90008fa76ba': buyTheDipsData,

  '5fbd385a83917b00086ac5ca': buyTheUptrend,

  '5faa6f09d1624c0008aaa2bc': lowVolatility,

  '5f58c035607f9a00089ea78b': scalpingDipsOnTrend,

  '5f843b6d6dff73000801e17e': catchThePriceSWing,

  '5f8439396dff73000801e17c': shortTermSwing,

  '5ea15fca645eb5000985a53e': goldenCrossTrading,

  '5feda2d0f359a500094bba6b': buyTheDipInBull,

  '5ea15fca645eb5000985a543': RSI_BTC,

  '5ea15fca645eb5000985a546': RSI_LTC,

  '5ea15fca645eb5000985a549': RSI_ETH,

  '5ea15fca645eb5000985a54c': RSI_RIPPLE,

  '5ea15fca645eb5000985a54f': RSI_SOLANA,

  '5fed9c278de9710008276696': rideTrend,

  '601073609ff5a70008f435e5': maxRSIStrategy,

  '5f8434b26dff73000801e173': maxMACrosing,

  '5f8436576dff73000801e177': multiMACrosing,

  '60c0c8a04ea54600085c50fc': maxScalpingOnTrend,

  '608adf0873d611000896c732': MAScalper,

  '613b480d02ee985438732365': HAshiStrategy,

  '6142eda1f732f469d4ca377c': maxBollingerBands,

  '6142ef465644532789d120eb': maxMACD,

  '5f843e236dff73000801e182': {
    tutorialUrl: 'https://help.coinrule.com/en/articles/5686166-multi-time-frame-rsi-scalping',
  },

  '619f69d7ca38e62a709f89d5': {
    tutorialUrl: 'https://help.coinrule.com/en/articles/5762918-grid-trading-in-range',
  },

  '5f842b3a92c26e000880b758': {
    tutorialUrl:
      'https://help.coinrule.com/en/articles/5649497-multi-time-frame-buy-low-sell-high-short-term',
  },

  '60cb1ff6c525080008270218': {
    tutorialUrl: 'https://help.coinrule.com/en/articles/5341503-grid-trading-strategy',
  },

  '5ea15e78390ff300070a43e4': {
    tutorialUrl:
      'https://help.coinrule.com/en/articles/5127748-catch-the-falling-knife-with-an-exception',
  },

  '5ea15e78390ff300070a43dd': {
    tutorialUrl: 'https://help.coinrule.com/en/articles/4894990-rebalance-trend-following',
  },

  // STAGING
  '61288c03cd040315ff4d1842': {
    videoUrl: `https://youtube.com/embed/xKuOKxxi2kw`,
    tutorialUrl: `https://coinrule.com/help/knowledgebase/trade-with-heikin-ashi`,
  },
};

export const getAdditionalTemplateData = (templateId: string) => TEMPLATES_DATA[templateId];
