import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import HeaderNavigation from 'components/Header/Navigation';
import React, { ReactNode } from 'react';
import { AddRuleStore, NewSequence } from 'stores/addRuleStore.interface';
import AddApiExchange from '../AddApiExchange';

import './style.scss';

export interface IAddExchangesModal extends ModalProps {
  handleLaunchLive?: Function;
  store?: AddRuleStore<NewSequence>;
  closeModals: () => void;
  title: ReactNode,
  isDashboardPage?: boolean
}

const AddExchangesModal: React.FC<IAddExchangesModal> = ({
  visible,
  handleLaunchLive,
  store,
  closeModals,
  title,
  isDashboardPage,
}) => {
  return (
    <Modal
      centered
      footer={null}
      visible={visible}
      className='AddExchangesModal'
      width='100%'
      closeIcon={null}
    >
      <HeaderNavigation onClose={closeModals} goBack={closeModals} />

      {
        title && (
          <h3 className='AddExchangesModal__title'>
            {title}
          </h3>
        )
      }
      <AddApiExchange
        showLoader={true}
        handleLaunchLive={handleLaunchLive}
        store={store}
        closeModals={closeModals}
        isDashboardPage={isDashboardPage}
      />
    </Modal>
  );
};

export default AddExchangesModal;
