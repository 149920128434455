import React, { Component } from 'react';
import { observer } from 'mobx-react';

import stores from '../../../stores';

function ErrorMessage() {
  return (
    <div className='authWindow__inputErrorMessage'>
      {stores.auth.errorMessage('password')}
    </div>
  );
}

@observer
class PasswordField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPasswordVisible: false,
      typingTimer: null,
      passwordError: false,
    };

    this.togglePasswordVisible = this.togglePasswordVisible.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.state.typingTimer);
  }

  togglePasswordVisible() {
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible,
    });
  }

  handleChange(e) {
    stores.auth.setPassword(e.target.value);
    e.persist();

    // check whether password field is correct when user stops typing
    if (this.state.typingTimer) {
      clearTimeout(this.state.typingTimer);
      this.setState({
        passwordError: false,
      });
    }

    this.setState({
      typingTimer: setTimeout(() => {
        if (e?.target?.value) {
          stores.auth.validatePassword();
          this.setState({
            passwordError: !stores.auth.isValidPassword,
          });
        }
      }, 500)
    });
  }

  showInvalidMark() {
    return !stores.auth.isValidPassword && stores.auth.showIfValidPassword;
  }

  render() {
    return (
      <div className='authWindow__inputWrap authWindow__inputWrap_withHint'>
        <div
          className={`authWindow__passwordVisibilityToggleBut 
                            ${this.state.isPasswordVisible &&
            'authWindow__passwordVisibilityToggleBut_hide'
            }`}
          onClick={this.togglePasswordVisible}
        >
          &nbsp;
        </div>

        <input
          type={this.state.isPasswordVisible ? 'text' : 'password'}
          // Using one-time-code autocomplete param as has the largest change to not get saved if plaintext.
          autoComplete={this.state.isPasswordVisible ? 'one-time-code' : undefined}
          name='current-password'
          autoFocus
          className={`authWindow__textInput 
                              authWindow__textInput_password 
                              ${stores.auth.password && 'authWindow__textInput_filled'} 
                              ${this.showInvalidMark() && 'authWindow__textInput_invalid'}`}
          onChange={this.handleChange}
          value={stores.auth.password}
          data-private={true}
        />
        <div className='authWindow__inputPlaceholder'>Password</div>
        <div className='authWindow__inputPasswordPlaceholder'>............</div>
        {this.state.passwordError ? <ErrorMessage /> : null}
      </div>
    );
  }
}

export default PasswordField;
