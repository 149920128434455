// component inspired by https://javascript.plainenglish.io/stop-using-margin-use-spacer-component-instead-953d9b2dbacc

import React from 'react'

import type { BoxProps} from '@mui/material'

import { useTheme, Box } from '@mui/material'

export interface SpacerProps extends BoxProps {
  x?: number; // multiplier of theme.spacing
  y?: number; // multiplier of theme.spacing
  basis?: number; // multiplier of theme.spacing
}

const testId = 'Spacer'
export const Spacer = (props: SpacerProps) => {
  const {
    sx,
    x,
    y,
    basis,
    ...otherProps
  } = props

  const theme = useTheme();

  return (
    <Box
      data-testid={testId}
      className={testId}
      sx={{
        ...sx
      }}
      width={x ? theme.spacing(x) : undefined}
      height={y ? theme.spacing(y) : undefined}
      flexBasis={basis ? theme.spacing(basis) : undefined}
      flexGrow={0}
      flexShrink={0}
      {...otherProps}
    />
  )
}

export default Spacer
