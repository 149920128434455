import React from 'react'

export interface IconPlayProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconPlay'
export const IconPlay = (props: IconPlayProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      width={"1em"}
      height={"1em"}
      viewBox={"0 0 435 508"}
      {...otherProps}
    >
      <path
        d={"M411.732,213.089L70.49,7.161C42.764-9.563.3,6.665,0.3,48.028V459.786c0,37.111,39.456,59.473,70.187,40.871l341.242-205.83c30.44-18.307,30.534-63.431,0-81.738h0Z"}
      />
    </svg>
  )

}

export default IconPlay
