import React from 'react';
import { Divider } from 'antd';
import stores from 'stores';
import { toJS } from 'mobx';
import { DataStudioContext } from '..';
import { formatDate } from '../../../helpers/utils';
import { useHistory, useRouteMatch } from 'react-router-dom';

const getAmount = ({
  baseCurrency,
  totalUsd,
  totalEur,
  totalUSD,
  totalEUR,
  totalGBP,
  totalBTC,
}) => {
  let amount;

  switch (baseCurrency) {
    case 'EUR':
      amount = totalEUR || totalEur;
      break;

    case 'GBP':
      amount = totalGBP;
      break;

    case 'BTC':
      amount = totalBTC;
      break;

    default:
      amount = totalUSD || totalUsd;
      break;
  }

  return +amount?.toFixed(2);
};

const TradeFeed = ({ size, base, quote, tnam, timeStamp, side, index, ...otherProps }) => {
  const { toggleDataStudio } = React.useContext(DataStudioContext);
  const { baseCurrency } = toJS(stores.user.user.user);

  const history = useHistory();
  const { path } = useRouteMatch();

  const amount = getAmount({ baseCurrency, ...otherProps });

  const onViewStrategyClick = (templateName) => {
    if (!templateName) return;

    const showTemplatesList = toJS(stores.ruleDetailsStore.showTemplatesList);

    if (!showTemplatesList) {
      stores.ruleDetailsStore.toggleTemplatesList();
    } else {
      // Close and re-open so TemplatesList picks up updates
      // Should be changed/removed once TemplatesList is refactored
      // to fuction component and uses useEffect for updates
      stores.ruleDetailsStore.toggleTemplatesList();
      stores.ruleDetailsStore.toggleTemplatesList();
    }

    stores.ruleDetailsStore.setSelectedTemplate(templateName);

    toggleDataStudio();

    if (path !== '/rule') {
      history.push('/rule');
    }
  };

  return (
    <div className='TradeFeed'>
      <p
        style={{
          color: '#F2F5F7',
          fontSize: '12.5px',
          marginTop: '6px',
          lineHeight: '1.7em',
          marginBottom: '2px',
          fontWeight: 'light',
        }}
      >
        A strategy {side === 'sell' ? 'sold' : 'bought'} {size} {base}{' '}
        {amount > 0.0 ? `(${amount}${baseCurrency})` : null} {side === 'sell' ? 'to' : 'with'}{' '}
        {quote}. {tnam && `The trader used the template ${tnam}`}
      </p>
      <div style={{ marginTop: '10px', marginBottom: '10px', display: 'flex' }}>
        <span
          style={{
            color: '#F2F5F7',
            marginRight: '10px',
            fontSize: '11px',
            fontWeight: 'light',
          }}
        >
          {formatDate(timeStamp)}
        </span>
        {tnam && (
          <div
            onClick={() => onViewStrategyClick(tnam || '')}
            style={{
              color: '#768AFF',
              textDecoration: 'underline',
              marginRight: '10px',
              fontSize: '11px',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
          >
            View Strategy
          </div>
        )}
      </div>
      <Divider />
    </div>
  );
};

export default TradeFeed;
