import React from 'react'

import type { StackProps , TypographyProps } from '@mui/material'

import { useTheme, Stack, Typography } from '@mui/material'

export interface IconHeaderProps extends StackProps {
  icon: React.ReactNode
  TypographyProps?: TypographyProps
}

const testId = 'IconHeader'
export const IconHeader = (props: IconHeaderProps) => {
  const {
    sx,
    icon,
    TypographyProps,
    children,
    ...otherProps
  } = props

  const theme = useTheme()

  return (
    <Stack
      data-testid={testId}
      className={testId}
      direction={'row'}
      gap={'15px'}
      alignItems={'center'}
      sx={{
        fontSize: { xs: '17px', md: '22px', xl: '28px' },
        fontWeight: 600,
        fill: theme.palette.coinrule.brand.primary,
        ...sx
      }}
      {...otherProps}
    >
      <Typography
        sx={{
          marginTop: '6px',
          fontSize: 'inherit',
          alignSelf: 'flex-start',
          justifySelf: 'flex-start',
        }}
      >
        {icon}
      </Typography>
      <Typography
        sx={{
          fontSize: 'inherit',
          fontWeight: { xs: 600, lg: 500 },
          ...TypographyProps?.sx
        }}
        {...TypographyProps}
      >
        {children}
      </Typography>
    </Stack>
  )
}

export default IconHeader
