import { useRef, useCallback } from 'react';

interface IUseIntersectionObserverOptions {
  hasMore: boolean;
  isLoading: boolean;
  setCounter: Function;
}

const useIntersectionObserver = ({
  hasMore,
  isLoading,
  setCounter,
}: IUseIntersectionObserverOptions) => {
  const observer = useRef<IntersectionObserver>();

  const elementRef = useCallback(
    (node) => {
      if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && hasMore) {
          setCounter((count: number) => count + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore]
  );

  return {
    elementRef,
  };
};

export default useIntersectionObserver;
