import React from 'react';
import './styles.scss';

export interface DotsProps {
  count: number;
  active: number;
  activeColor: string;
  inactiveColor: string;
}

const Dots: React.FC<DotsProps> = ({ count, active, activeColor, inactiveColor }) => {
  const cls = 'custom-dot';
  return (
    <div className={`${cls}s`}>
      {Array(3).fill(null).map((_, index) => (
        <div
          key={index}
          className={`${cls} ${index <= active ? `${cls}--active` : ''}`}
          style={{ backgroundColor: index <= active ? activeColor : inactiveColor }}
        />
      ))}
    </div>
  );
};

export default Dots;
