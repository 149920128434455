import { EAsyncStatus } from "v2/types/EAsyncStatus"

import type { DTOErrorGetSharedRule } from "./DTOs/DTOErrorGetSharedRule"
import type { DTOSharedRule } from "./DTOs/DTOSharedRule"

import type { TReducer } from "v2/types/TReducer"

import type { TDomainActionAccessSharedRule } from "./actionsAccessSharedRule";
import { EDomainActionsAccessSharedRule } from "./actionsAccessSharedRule"

export type TDomainStateAccessSharedRule = {
  data: DTOSharedRule | null,
  error: DTOErrorGetSharedRule | null
  status: EAsyncStatus,
  showOnlyTrades: boolean,
  offset: number,
}

export const initialStateAccessSharedRule: TDomainStateAccessSharedRule = {
  data: null,
  error: null,
  status: EAsyncStatus.IDLE,
  showOnlyTrades: false,
  offset: 0,
}

export const reducerAccessSharedRule: TReducer<TDomainStateAccessSharedRule, TDomainActionAccessSharedRule> = (state = initialStateAccessSharedRule, action): TDomainStateAccessSharedRule => {
  switch (action.type) {
    case EDomainActionsAccessSharedRule.FETCH_STARTED: {
      return {
        ...state,
        error: null,
        status: EAsyncStatus.PENDING
      }
    }
    case EDomainActionsAccessSharedRule.FETCH_SUCCEEDED: {
      const fetchFirstPage = state.offset === 0
      return {
        ...state,
        data: {
          ...action.payload.sharedRule,
          ruleActivityLogs: {
            ...action.payload.sharedRule.ruleActivityLogs,
            // if offset is zero then we need to overwrite the data
            activities: !fetchFirstPage ? 
              // `state.data` is always here as there is no way to load next pages without loading first before
              state.data!.ruleActivityLogs.activities.concat(action.payload.sharedRule.ruleActivityLogs.activities)
              :
              action.payload.sharedRule.ruleActivityLogs.activities
          }
        },
        error: null,
        status: EAsyncStatus.RESOLVED
      }
    }
    case EDomainActionsAccessSharedRule.FETCH_FAILED: {
      return {
        ...state,
        data: null,
        error: action.payload.error,
        status: EAsyncStatus.REJECTED
      }
    }
    case EDomainActionsAccessSharedRule.FETCH_RESET: {
      return {
        ...state,
        data: initialStateAccessSharedRule.data,
        error: initialStateAccessSharedRule.error,
        status: initialStateAccessSharedRule.status,
      }
    }
    case EDomainActionsAccessSharedRule.SET_OFFSET: {
      return {
        ...state,
        offset: action.payload.offset,
      }
    }
    case EDomainActionsAccessSharedRule.SET_SHOW_ONLY_TRADES: {
      return {
        ...state,
        showOnlyTrades: action.payload.showOnlyTrades,
      }
    }
    default:
      return state
  }
}
