import type { TReducer } from 'v2/types/TReducer'
import type { TDomainActionConnectExchangeBinanceOAuthGetAccessToken } from './actionsFetchGetAccessToken'
import type { TDomainActionConnectExchangeBinanceOAuthPrepare } from './actionsFetchPrepare'
import type { TDomainStateConnectExchangeBinanceOAuthGetAccessToken } from './stateFetchGetAccessToken';
import type { TDomainStateConnectExchangeBinanceOAuthPrepare } from './stateFetchPrepare';
import type { TDomainStateConnectExchangeBinanceOAuthCreateApiKeys } from './stateFetchCreateApiKeys';
import type { TDomainActionConnectExchangeBinanceOAuthCreateApiKeys } from './actionsFetchCreateApiKeys';

import { initialStateConnectExchangeBinanceOAuthGetAccessToken } from './stateFetchGetAccessToken';
import { reducerConnectExchangeBinanceOAuthGetAccessToken } from './stateFetchGetAccessToken'
import { initialStateConnectExchangeBinanceOAuthCreateApiKeys } from './stateFetchCreateApiKeys';
import { reducerConnectExchangeBinanceOAuthCreateApiKeys } from './stateFetchCreateApiKeys';
import { initialStateConnectExchangeBinanceOAuthPrepare } from './stateFetchPrepare';
import { reducerConnectExchangeBinanceOAuthPrepare } from './stateFetchPrepare'

export type TDomainStateConnectExchangeBinanceOAuth = {
  prepare: TDomainStateConnectExchangeBinanceOAuthPrepare,
  getAccessToken: TDomainStateConnectExchangeBinanceOAuthGetAccessToken,
  createApiKeys: TDomainStateConnectExchangeBinanceOAuthCreateApiKeys,
}

export type TDomainActionConnectExchangeBinanceOAuth = TDomainActionConnectExchangeBinanceOAuthPrepare | TDomainActionConnectExchangeBinanceOAuthGetAccessToken | TDomainActionConnectExchangeBinanceOAuthCreateApiKeys

const initialStateConnectExchangeBinanceOAuth = {
  prepare: initialStateConnectExchangeBinanceOAuthPrepare,
  getAccessToken: initialStateConnectExchangeBinanceOAuthGetAccessToken,
  createApiKeys: initialStateConnectExchangeBinanceOAuthCreateApiKeys,
}

export const reducerConnectExchangeBinanceOAuth: TReducer<TDomainStateConnectExchangeBinanceOAuth, TDomainActionConnectExchangeBinanceOAuth> = (state = initialStateConnectExchangeBinanceOAuth, action): TDomainStateConnectExchangeBinanceOAuth => {
  return {
    prepare: reducerConnectExchangeBinanceOAuthPrepare(state.prepare, action as TDomainActionConnectExchangeBinanceOAuthPrepare),
    getAccessToken: reducerConnectExchangeBinanceOAuthGetAccessToken(state.getAccessToken, action as TDomainActionConnectExchangeBinanceOAuthGetAccessToken),
    createApiKeys: reducerConnectExchangeBinanceOAuthCreateApiKeys(state.createApiKeys, action as TDomainActionConnectExchangeBinanceOAuthCreateApiKeys),
  }
}
