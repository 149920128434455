import React from 'react'

export interface IconListWithBulletsProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconListWithBullets'
export const IconListWithBullets = (props: IconListWithBulletsProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      xmlns={"http://www.w3.org/2000/svg"}
      xmlnsXlink={"http://www.w3.org/1999/xlink"}
      x={"0px"}
      y={"0px"}
      viewBox={"0 0 512.2 512.2"}
      xmlSpace={"preserve"}
      width={"1em"}
      height={"1em"}
      {...otherProps}
    >
      <g>
        <g>
          <path
            className={"st0"}
            d={"M448.2,80h-320c-17.7,0-32,14.3-32,32s14.3,32,32,32h320c17.7,0,32-14.3,32-32S465.9,80,448.2,80z"}
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className={"st0"}
            d={"M64.2,112c0-8.5-3.4-16.6-9.4-22.6C42.3,77,22.1,77,9.6,89.4c-6,6-9.4,14.1-9.4,22.6c-0.2,2.1-0.2,4.2,0,6.2 c0.4,2.1,1,4.1,1.8,6.1c0.9,1.9,1.9,3.7,3,5.4c1.1,1.8,2.5,3.5,4,5c1.5,1.4,3.1,2.7,4.8,3.8c1.7,1.2,3.5,2.2,5.4,3 c2.1,1.1,4.4,1.9,6.7,2.4c2.1,0.2,4.2,0.2,6.2,0c8.5,0,16.6-3.3,22.6-9.3c1.5-1.5,2.9-3.2,4-5c1.2-1.7,2.2-3.5,3-5.4 c1-1.9,1.8-4,2.4-6.1C64.4,116.2,64.4,114.1,64.2,112z"}
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className={"st0"}
            d={"M64.2,256c0.2-2.1,0.2-4.2,0-6.2c-0.6-2.1-1.4-4.1-2.4-5.9c-0.8-2-1.8-3.8-3-5.6c-1.1-1.8-2.5-3.4-4-4.8 c-12.5-12.4-32.6-12.4-45.1,0c-6,6-9.4,14.1-9.4,22.6c0.1,4.2,0.9,8.4,2.4,12.3c0.8,1.9,1.8,3.7,2.9,5.4c1.2,1.7,2.6,3.3,4.2,4.8 c1.4,1.5,3,2.9,4.8,4c1.7,1.2,3.5,2.2,5.4,3c2,0.8,4,1.4,6.1,1.8c2,0.5,4.1,0.7,6.2,0.6c2.1,0.2,4.2,0.2,6.2,0 c2-0.3,4-0.9,5.9-1.8c2-0.8,3.9-1.8,5.6-3c1.8-1.1,3.4-2.5,4.8-4c1.5-1.4,2.9-3,4-4.8c1.2-1.7,2.3-3.5,3-5.4 c1.1-2.1,1.9-4.4,2.4-6.7C64.4,260.2,64.4,258.1,64.2,256z"}
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className={"st0"}
            d={"M64.2,400c0.2-2.1,0.2-4.2,0-6.2c-0.6-2.1-1.4-4.2-2.4-6.1c-0.9-1.9-1.9-3.7-3-5.4c-1.1-1.8-2.5-3.4-4-4.8 c-12.5-12.4-32.6-12.4-45.1,0c-1.5,1.4-2.9,3-4,4.8c-1.2,1.7-2.2,3.5-3,5.4c-0.8,1.9-1.4,4-1.8,6.1c-0.5,2-0.7,4.1-0.6,6.2 c0,8.5,3.4,16.6,9.4,22.6c1.4,1.5,3,2.9,4.8,4c1.7,1.2,3.5,2.2,5.4,3c2,0.8,4,1.4,6.1,1.8c2,0.5,4.1,0.7,6.2,0.6 c2.1,0.2,4.2,0.2,6.2,0c2-0.3,4-0.9,5.9-1.8c2-0.8,3.9-1.8,5.6-3c1.8-1.1,3.4-2.5,4.8-4c1.5-1.4,2.9-3,4-4.8 c1.2-1.7,2.3-3.5,3-5.4c1.1-2.1,1.9-4.4,2.4-6.7C64.4,404.2,64.4,402.1,64.2,400z"}
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className={"st0"}
            d={"M480.2,224h-352c-17.7,0-32,14.3-32,32s14.3,32,32,32h352c17.7,0,32-14.3,32-32S497.9,224,480.2,224z"}
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className={"st0"}
            d={"M336.2,368h-208c-17.7,0-32,14.3-32,32s14.3,32,32,32h208c17.7,0,32-14.3,32-32S353.9,368,336.2,368z"}
          />
        </g>
      </g>
    </svg>
  )
}

export default IconListWithBullets
