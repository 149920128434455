import React from 'react'

export interface IconCopyProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconCopy'
export const IconCopy = (props: IconCopyProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      height={"1em"}
      viewBox={"-21 0 512 512"}
      width={"1em"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...otherProps}
    >
      <path d={"M325.332 512H58.668C26.305 512 0 485.695 0 453.332v-288c0-32.363 26.305-58.664 58.668-58.664h10.664c8.832 0 16 7.168 16 16s-7.168 16-16 16H58.668c-14.7 0-26.668 11.965-26.668 26.664v288C32 468.032 43.969 480 58.668 480h266.664c14.7 0 26.668-11.969 26.668-26.668v-10.664c0-8.832 7.168-16 16-16s16 7.168 16 16v10.664C384 485.695 357.695 512 325.332 512zm0 0"} />
      <path d={"M410.668 384h-224C154.305 384 128 357.695 128 325.332V58.668C128 26.305 154.305 0 186.668 0h224c32.363 0 58.664 26.305 58.664 58.668v266.664c0 32.363-26.3 58.668-58.664 58.668zm-224-352C171.968 32 160 43.969 160 58.668v266.664c0 14.7 11.969 26.668 26.668 26.668h224c14.7 0 26.664-11.969 26.664-26.668V58.668c0-14.7-11.965-26.668-26.664-26.668zm0 0"} />
    </svg>
  )
}

export default IconCopy
