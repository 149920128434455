import { PaymentButtonTemplate } from 'components/PaymentButton';
import { IS_PROD } from 'constants/isProd';
import { CHARGEBEE_SITE_NAME } from 'helpers/payment';
import React, { useEffect } from 'react';
import PlanBox from '../PlanBox';

const PlansContainer = ({
  plans,
  user,
  currentPlan,
  paymentProvider,
  yearPeriod,
  period,
  ...otherProps
}) => {
  useEffect(() => {
    const site = IS_PROD ? CHARGEBEE_SITE_NAME.PROD : CHARGEBEE_SITE_NAME.TEST;
    if (window && window.Chargebee && window.Chargebee.init) {
      window.Chargebee.init({ site, enableRedirectMode: false });
    }
    const loadData = async () => {
      await paymentProvider?.getUserSubscription?.();
    }

    loadData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return plans.map((plan) => {
    const coupon = paymentProvider.getCoupons(`${plan.code}-${period}`);

    const planDetails = {
      plan,
      user,
      period,
      paymentProvider,
      coupon_ids: coupon?.coupon_ids,
      currentPlan,
    };
    const planButton = buttonBuilder(planDetails);

    return (
      <PlanBox
        key={plan.code}
        plan={plan}
        user={user}
        yearPeriod={yearPeriod}
        coupon={coupon}
        button={planButton}
        period={period}
        changePaymentFunction={paymentProvider.handleChangePaymentMethod}
        {...otherProps}
      />
    );
  });
};

export default PlansContainer;

export const buttonBuilder = ({
  plan,
  user,
  period,
  paymentProvider,
  amountToPay,
  coupon_ids,
  currentPlan,
}) => {
  const commonProps = {
    plan: plan.code,
    amountToPay,
  };

  const isCurrentPlan = currentPlan?.code === plan.code;
  const isLowerPlan = +currentPlan?.price > +plan.price;
  const isHigherPlan = +currentPlan?.price < +plan.price;

  const buttonsAndPredicates = [
    {
      button: (
        <PaymentButtonTemplate
          buttonText={'Start Trial'}
          onClick={paymentProvider.handlePayment(plan, period, coupon_ids)}
          {...commonProps}
        />
      ),
      predicate: () => !user.plan && user.hasTrial,
    },
    {
      button: (
        <PaymentButtonTemplate
          buttonText={'Keep Plan'}
          onClick={paymentProvider.keepPlan(plan, period)}
          {...commonProps}
        />
      ),
      predicate: () => isCurrentPlan && user?.cancelledPlan,
    },

    {
      button: (
        <PaymentButtonTemplate
          buttonText={'Downgrade'}
          onClick={paymentProvider.handleDowngrade(plan, period, coupon_ids)}
          {...commonProps}
        />
      ),
      predicate: () => isLowerPlan,
    },
    {
      button: <span className='current'>Current Plan</span>,
      predicate: () => isCurrentPlan,
    },
    {
      button: (
        <PaymentButtonTemplate
          buttonText={'Upgrade'}
          onClick={paymentProvider.handlePayment(plan, period, coupon_ids)}
          {...commonProps}
        />
      ),
      predicate: () => isHigherPlan || !user.plan || !currentPlan
    },
  ];
  const predicate = buttonsAndPredicates.find((element) => element.predicate());
  return predicate?.button;
};
