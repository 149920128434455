import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';

import stores from '../../stores';
import Loading from '../Loading';
import { ErrorBoundary } from '../ErrorBoundary';

class GeneralRoute extends Component {
  checkAuthorization(Component, props) {
    if (!stores.user.authChecked) {
      return <Loading />;
    }

    return <ErrorBoundary name={"boundary_GeneralRoute"} isFullScreen><Component {...props} /></ErrorBoundary>;
  }

  render() {
    const { component: Component, ...rest } = this.props;

    return <Route {...rest} render={(props) => this.checkAuthorization(Component, props)} />;
  }
}

export default withRouter(GeneralRoute);
