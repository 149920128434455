import React from 'react'

import stores from 'stores/'
import storesInjector from 'stores/storesInjector';

import { BoxProps, Box, useTheme } from '@mui/material'

import { ExchangesAddApiTutorial, ExchangeSelector, VideoDialog, EExchangeTradingType } from 'design-system'

import type { TExchange, TExchangesWithTutorials } from 'design-system'
import type InfoStore from 'stores/info';
import type AddRuleStore from 'stores/addRule';
import type UserStore from 'stores/user';
import type UserInfoStore from 'stores/userInfo';
import type ExchangeProfileStore from 'stores/exchangeProfile'

type TMarketType = TExchangesWithTutorials | 'select_exchange'

const analytics = window.mixpanel

export interface ExchangeTutorialProps extends BoxProps {
  type: TMarketType,
  store: ExchangeProfileStore,
  info: InfoStore,
  userInfo: UserInfoStore,
  addRule: AddRuleStore,
  user: UserStore,
  promoBinance?: React.ReactNode
}

const testId = 'ExchangeTutorial'
export const ExchangeTutorial = (props: ExchangeTutorialProps) => {

  const theme = useTheme()

  const {
    type,
    store,
    info,
    userInfo,
    addRule,
    user,
    promoBinance,
  } = props

  const isFreeUser = stores.user.getUser().plan.name === 'Free'

  const [modalProps, setModalProps] = React.useState({
    showModal: false,
    address: '',
  })
  const clickPlayExchangeVideo = (e: React.MouseEvent, address: string, type: string, number: number) => {
    e.preventDefault()
    setModalProps({ showModal: true, address: address })
    analytics.track('Video clicked', {
      exchange: type,
      videoNumber: number,
    })
  }

  const filterAndSortExchanges = (exchanges: TExchange[], exchangeType: string) =>
    exchanges
      .filter(({ isDemo, type }) => !isDemo && type === exchangeType)
      .sort((a, b) => {
        if (!a.isEnabled !== !b.isEnabled) {
          return !a.isEnabled ? 1 : -1
        }
        const textA = a.uid.toUpperCase()
        const textB = b.uid.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })

  const getLogoProps = (exchange: TExchange) => {
    const { isEnabled, uid } = exchange

    const isInUserExchanges = userInfo.exchanges.find(({ uid: exUid }: { uid: TExchangesWithTutorials }) => exUid === uid)
    const isClickable = isEnabled && !isInUserExchanges

    return {
      isClickable,
      isNew:
        uid === 'binance-futures' ||
        uid === 'bitmex' ||
        uid === 'kucoin'
    }
  }

  const handleChange = (event: React.MouseEvent, isClickable: boolean, exchange: TExchange) => {
    event.preventDefault()

    // @ts-ignore FIXME user.user is not typed as userStore is plain js file 
    const showPaywall = +(user?.user)?.plan?.clearance === 0 && exchange.type === EExchangeTradingType.LEVERAGE

    if (showPaywall) return addRule.setPaymentGateVisible({ type: 'promo', isVisible: true })

    if (isClickable) {
      store.setSelectedExchange(exchange.uid)
    }
  }

  return (
    <Box
      data-testid={testId}
      className={testId}
      sx={{
        marginRight: '1rem',
      }}
    >
      <Box
        sx={{
          width: 0,
          height: 0,
          borderTop: '20px solid transparent',
          borderBottom: '20px solid transparent',
          borderRight: `20px solid ${theme.palette.coinrule.basic.white}`,
          position: 'absolute',
          left: '-20px',
          top: '90px',
        }}
      />

      {type === 'select_exchange' ?
        <ExchangeSelector
          promoBinance={promoBinance}
          handleChange={handleChange}
          exchangesSpot={
            filterAndSortExchanges(info?.exchanges, EExchangeTradingType.SPOT).map((exchange: TExchange) => {
              const { isNew, isClickable } = getLogoProps(exchange)
              return ({ ...exchange, isNew, isClickable })
            })
          }
          exchangeLeverage={
            filterAndSortExchanges(info?.exchanges, EExchangeTradingType.LEVERAGE).map((exchange: TExchange) => {
              const { isNew, isClickable } = getLogoProps(exchange)
              return ({ ...exchange, isNew, isClickable })
            })
          }
          isFreeUser={isFreeUser}
        />
        :
        <ExchangesAddApiTutorial
          ips={stores.info.ips}
          market={type}
          onThumbnailClick={clickPlayExchangeVideo}
        />
      }
      <VideoDialog
        onClick={() => setModalProps({ address: '', showModal: false })}
        onClose={() => setModalProps({ address: '', showModal: false })}
        open={modalProps.showModal}
        IFrameProps={{
          sx: {
            width: {
              xs: 250,
              sm: 450,
              md: 600,
              lg: 650,
              xl: 650,
            }
          },
          height: '380px',
          src: `${modalProps.address}`,
          frameBorder: '0',
          title: 'Video',
          allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
          allowFullScreen: true,
        }}
      />
    </Box>
  )
}

interface SelectedStores {
  info: InfoStore;
  userInfo: UserInfoStore;
  addRule: AddRuleStore;
  user: UserStore;
}

const selector = ({ info, userInfo, addRule, user }: SelectedStores) => ({
  info,
  userInfo,
  addRule,
  user,
});

export default storesInjector<SelectedStores>(selector)(ExchangeTutorial)
