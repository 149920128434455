// react adapters
import { createUseCasesProvider } from 'v2/application/adapters/store/react/createUseCasesProvider';
import { createStoreProvider } from 'v2/application/adapters/store/react/createStoreProvider';

// app logic and store
import type { TCoreState } from 'v2/application/createApplication';
import { createApplication } from 'v2/application/createApplication';

// views
import { createViewShareRuleShareButton } from './shareRule/view/createViewShareRuleShareButton';
import createViewAccessSharedRule from './accessSharedRule/view/ViewAccessSharedRule';
import createViewLoginDialogForNotAuthenticatedUsers from './loginDialogForNotAuthenticatedUsers/view/ViewLoginDialogForNotAuthenticatedUsers';
import createViewConnectExchangeBinanceOAuthPrepare from './connectExchangeBinanceOAuth/view/createViewConnectExchangeBinanceOAuthPrepare';
import createViewConnectExchangeBinanceOAuthGetAccessTokenAndCreateApiKeys from './connectExchangeBinanceOAuth/view/createViewConnectExchangeBinanceOAuthGetAccessTokenAndCreateApiKeys';

export const { useCases, store } = createApplication()

export const { UseCasesProvider, useUseCasesContext } = createUseCasesProvider({ useCases })
export const { StoreProvider, useSelector } = createStoreProvider<TCoreState>()

export const ViewShareRuleShareButton = createViewShareRuleShareButton({
  useCasesShareRule: useCases.shareRule,
  useSelector: (selector) => useSelector((state, ...rest) => selector(state.shareRule, ...rest))
})

export const ViewAccessSharedRule = createViewAccessSharedRule({
  useCasesAccessSharedRule: useCases.accessSharedRule,
  useSelector: (selector) => useSelector((state, ...rest) => selector(state.accessSharedRule, ...rest))
})

export const ViewLoginDialogForNotAuthenticatedUsers = createViewLoginDialogForNotAuthenticatedUsers({
  useCasesLoginDialogForNotAuthenticatedUsers: useCases.loginDialogForNotAuthenticatedUsers,
  useSelector: (selector) => useSelector((state, ...rest) => selector(state.loginDialogForNotAuthenticatedUsers, ...rest))
})

export const ViewConnectExchangeBinanceOAuthPrepare = createViewConnectExchangeBinanceOAuthPrepare({
  useCasesConnectExchangeBinanceOAuth: useCases.connectExchangeBinanceOAuth,
  useSelector: (selector) => useSelector((state, ...rest) => selector(state.connectExchangeBinanceOAuth.prepare, ...rest))
})

export const ViewConnectExchangeBinanceOAuthGetAccessTokenAndCreateApiKeys = createViewConnectExchangeBinanceOAuthGetAccessTokenAndCreateApiKeys({
  useCases: useCases.connectExchangeBinanceOAuth,
  useSelector: (selector) => useSelector((state, ...rest) => selector(state.connectExchangeBinanceOAuth, ...rest))
})
