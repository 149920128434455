import React from 'react'

import type { ChipProps } from '@mui/material'

import { useTheme, Chip } from '@mui/material'

export interface RuleStatusBadgeProps extends ChipProps {
  executionTimesCounter: number
}

const testId = 'RuleStatusBadge'
export const RuleStatusBadge = (props: RuleStatusBadgeProps) => {
  const {
    sx,
    executionTimesCounter,
    ...otherProps
  } = props

  const theme = useTheme()

  return (
    <Chip
      data-testid={testId}
      className={testId}
      label={executionTimesCounter ? `Executed ${executionTimesCounter} times` : `NOT YET TRIGGERED`}
      sx={{
        borderRadius: '4px',
        fontSize: '10px',
        height: '18px',
        padding: '0 8px',
        backgroundColor: theme.palette.coinrule.brand.primary,
        color: theme.palette.coinrule.basic.white,
        '& .MuiChip-label': {
          padding: 0
        },
        ...sx
      }}
      {...otherProps}
      onDelete={undefined}
    />
  )
}

export default RuleStatusBadge
