import React from 'react'

import { Box, Grid } from '@mui/material'
import { ExchangeSelectorGridItem } from 'design-system'

import type { BoxProps } from '@mui/material'
import type { TExchange, TExpandedExchange } from 'design-system'
export interface ExchangesGridProps extends BoxProps {
  exchanges: TExpandedExchange[],
  handleChange: (event: React.MouseEvent, isClickable: boolean, exchange: TExchange) => void,
}

const testId = 'ExchangesGrid'
export const ExchangesGrid = (props: ExchangesGridProps) => {
  const {
    sx,
    exchanges,
    handleChange,
    ...otherProps
  } = props

  if (!exchanges) return null

  return (
    <Box
      component={Grid}
      container={true}
      data-testid={testId}
      className={testId}
      sx={{
        ...sx
      }}
      {...otherProps}
    >
      {exchanges.map((exchange) => {

        return (
          <ExchangeSelectorGridItem
            key={exchange.uid}
            xs={12}
            sm={6}
            md={4}
            selectExchange={handleChange}
            exchange={exchange}
          />
        )
      })}
    </Box>
  )
}

export default ExchangesGrid