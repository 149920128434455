import type { TCreateStore } from 'v2/types/TCreateStore';

import type { Reducer } from 'redux';

import { composeWithDevTools } from '@redux-devtools/extension';
import { combineReducers, createStore as createStoreRedux, applyMiddleware } from 'redux'

import { createActionsInterceptorMiddleware } from './actionsInterceptorMiddleware';

export const RESET_ALL_ACTION_TYPE = '@RESET/ALL'

export const createStore: TCreateStore = ({ reducers, actionsInterceptor }) => {
  const actionsInterceptorMiddleware = createActionsInterceptorMiddleware({ actionsInterceptor })

  const rootReducer: Reducer = (state, action) => {
    const combinedReducers =  combineReducers(reducers)
    if(action.type === RESET_ALL_ACTION_TYPE) {
      return combinedReducers(undefined, action)
    }

    return combinedReducers(state, action)
  }

  const store = createStoreRedux(
    rootReducer,
    composeWithDevTools(
      applyMiddleware(actionsInterceptorMiddleware)
    )
  )

  return store
}