import { IS_PROD } from 'constants/isProd';
import {
  COUPON_ID,
  COUPON_URL_KEY,
  getOnboardingClosedKey,
  LAST_IS_AUTHORIZED,
} from 'helpers/constants';
import { action, observable } from 'mobx';
import cookie from 'react-cookies';
import DeviceStorage from 'react-device-storage';
import dbg from '../helpers/dbg';
import history from '../helpers/history';
import http from '../helpers/http';
import { errorNotification } from '../helpers/notification';
import { getDataLocalStorage, setDataCookies, setDataLocalStorage } from '../helpers/utils';
import { DEFAULT_AMOUNT_SYMBOL } from './addRule';
import stores from './index';
import { formatUserCurrentPlan } from 'helpers/plan';
import { AbstractStore } from './AbstractStore';

const queryString = require('query-string');
const analytics = window.mixpanel;

export default class UserStore extends AbstractStore {
  constructor(rootStore) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  @observable isInputVerification = false;
  @observable isCheckingAuthorization = false;
  @observable authChecked = false;
  @observable isAuthorized = false;
  @observable user = null;
  @observable isAdmin = false;
  @observable isSupport = false;
  @observable justLoggedOut = false;
  @observable userTour = '';
  @observable hasRule = false;
  @observable hasLiveRule = false;
  @observable sessionStarted = false;
  @observable tabUUID = null;
  @observable lastOpenPage = null;
  @observable newRule = '';
  @observable tooltipCount = null;
  @observable planEnds = null;
  @observable trialEnds = null;
  @observable coupon = null;
  @observable couponv2 = null;
  @observable onboarding3stepsGuidePassed = false;
  @observable paymentProcessed = false;
  @observable paymentSuccessful = false;
  @observable sequenceValidationDef = {};
  @observable volumeLimitUsed = null;
  @observable twoFaEnabled = false;
  @observable twoFaAuthorized = false;
  @observable intercomUserHash = '';
  @observable tradingViewWebhook = '';
  @observable userAccountInfo = null;

  isLoggedInInterval;

  @action
  changeOnboarding3stepsGuideStatus(value = true) {
    this.onboarding3stepsGuidePassed = value;
  }

  @action
  updateUserAccountInfo = (userAccountInfo) => this.userAccountInfo = userAccountInfo;

  parseUkey1Params(query) {
    const queryParams = queryString.parse(query);

    this.uk1Code = queryParams['_ukey1[code]'];
    this.uk1ConnectId = queryParams['_ukey1[connect_id]'];
    this.uk1RequestId = queryParams['_ukey1[request_id]'];
    this.uk1Result = queryParams['_ukey1[result]'];
    this.uk1Signature = queryParams['_ukey1[signature]'];
  }

  checkUkey1Params() {
    if (this.uk1Result !== 'authorized') {
      return false;
    }

    const storage = new DeviceStorage({
      cookieFallback: true,
      cookie: {
        secure: window.location.protocol === 'https:',
      },
    }).localStorage();

    if (this.uk1RequestId !== storage.read('requestId')) {
      return false;
    }

    if (this.uk1ConnectId !== storage.read('connectId')) {
      return false;
    }

    storage.delete('requestId');
    storage.delete('connectId');

    return true;
  }

  resetOnboardingModalVisibility() {
    try {
      const storage = new DeviceStorage({
        cookieFallback: false,
      }).localStorage();

      storage.delete(getOnboardingClosedKey(this.getUserId()));
    } catch (err) {
      // Do nothing
    }
  }

  saveXsrfToken(xsrfToken) {
    const cookies = new DeviceStorage({
      cookie: {
        secure: window.location.protocol === 'https:',
      },
    }).cookies();

    cookies.save('xsrf_token', { token: xsrfToken });
  }

  @action
  async verify(query) {
    this.isCheckingAuthorization = true;
    this.parseUkey1Params(query);
    this.resetOnboardingModalVisibility();
    let error = false;

    if (!this.checkUkey1Params()) {
      this.isCheckingAuthorization = false;

      dbg('Invalid input params', query);

      if (this.uk1Result === 'canceled') {
        errorNotification(`Request has been canceled.`);
      } else {
        errorNotification(`You're not eligible to access Coinrule yet.`);
      }
    } else {
      this.isInputVerification = true;

      try {
        const verifyRes = await http.post(
          '/auth/verify-ukey',
          JSON.stringify({
            connectId: this.uk1ConnectId,
            requestId: this.uk1RequestId,
            result: this.uk1Result,
            code: this.uk1Code,
            signature: this.uk1Signature,
          }),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (verifyRes.data && verifyRes.data.status === 'OK') {
          // User authenticated
          this.saveXsrfToken(verifyRes.data.xsrfToken);
          await this.checkUser(true);

          if (verifyRes.data.userTour) {
            this.userTour = verifyRes.data.userTour;
          }
        } else {
          this.isCheckingAuthorization = false;
          error = true;
        }
      } catch (err) {
        this.isCheckingAuthorization = false;
        error = true;
      }
    }

    if (error) {
      this.isCheckingAuthorization = false;
      errorNotification(`We're sorry, something has gone wrong. Please try it again later.`);
    }

    this.isInputVerification = false;
    history.push('/');
  }

  @observable sendingCheckout = false;

  @action
  async logout() {
    setDataCookies(COUPON_URL_KEY, '');
    setDataCookies(COUPON_ID, '');
    clearInterval(this.isLoggedInInterval);
    this.isLoggedInInterval = null;

    window.Intercom('shutdown');
    analytics.track('User logged out');

    this.justLoggedOut = true;

    await this.checkUser(true, true)
    await http.get('/auth/logout');

    this.resetOnboardingModalVisibility();
    stores.info.reset();
    this.user = null;
    this.isAuthorized = false;
    stores.userInfo.resetExchanges();

    stores.reset();

    history.push('/login');
  }

  @action
  resetJustLoggedOut() {
    this.justLoggedOut = false;
  }

  getUser() {
    return this.user;
  }

  @action
  getUserId = () => {
    return this.user?.user?.id || '';
  };

  @action
  setAuth(auth) {
    this.isAuthorized = auth;
  }

  @action
  removeTour() {
    this.userTour = '';
  }

  @action
  setTour(tour) {
    this.userTour = tour;
  }

  addRuleTour() {
    this.setTour('addRule');
  }

  @action
  updateUserPlan = (updates = {}) => {
    this.user.plan = {
      ...this.user.plan,
      ...updates,
    };
  };

  @action
  async checkUser(force, refreshToken = false) {
    if (
      (force || !this.isCheckingAuthorization) &&
      (!this.isAuthorized || (refreshToken && this.isAuthorized))
    ) {
      this.isCheckingAuthorization = true;

      let params = '';

      if (refreshToken) {
        params = `?refreshToken=true&is2faAuthorized=${this.twoFaAuthorized}`; // TODO: this is security vulnerability
      }

      try {
        const userRes = await http.get(`/auth/check${params}`);

        if (userRes.data && userRes.data.status === 'OK') {
          this.user = { ...userRes.data.auth, plan: formatUserCurrentPlan(userRes.data.auth.plan) };

          if (!this.isAuthorized) {
            window.Intercom('update', {
              email: userRes.data.auth.user.email,
              user_id: userRes.data.auth.user.id,
              user_hash: userRes.data.auth.intercomUserHash,
              company: {
                company_id: stores.user.user.plan.uid,
                name: stores.user.user.plan.name.toUpperCase(),
                plan: stores.user.user.plan.name,
              },
            });
          }

          this.updateStatesWithUserData(userRes);

          if (!this.hasRule) {
            this.userTour = 'addRule';
          }

          if (!this.user.user.baseCurrency) {
            this.user.user.baseCurrency = DEFAULT_AMOUNT_SYMBOL;
          }

          stores.addRule.setTempCurrency(this.user.user.baseCurrency);

          stores.walletsStores.getWallets();

          await this.sendIsLoggedInConfirmation();

          if (!this.isLoggedInInterval) {
            this.isLoggedInInterval = setInterval(this.sendIsLoggedInConfirmation, 60000); // every 1 minute
          }

          if (!this.sessionStarted) {
            await stores.userInfo.getExchanges();
            this.startUserSessionAndSendAnalytics(this.user);
          }
        }

        await this.getAccountInfo();
      } catch (err) {
        this.isCheckingAuthorization = false;
        throw err;
      }
    }

    this.isCheckingAuthorization = false;
    this.authChecked = true;

    this.setIsAuthorizedInStorage(this.isAuthorized);

    return this.isAuthorized;
  }

  @action
  startUserSessionAndSendAnalytics = async (user) => {
    this.sessionStarted = new Date();

    try {
      analytics.register_once({
        createdAt: user.user.createdAt,
        email: user.user.email,
        plan: user.plan.name,
        maxRules: user.plan.maxRules,
        maxActions: user.plan.maximumActions,
        maxConditions: user.plan.maximumConditions,
        maxExchanges: user.plan.numberOfExchanges,
        hasLiveRule: this.hasLiveRule,
        hasRule: this.hasRule,
        isTrialUser: this.hasTrial,
        coupon: this.coupon,
        TrafficSource: cookie.load('initialReferrer') || 'none',
      });

      analytics.identify(user.user.id);
      analytics.track('User started new session');

      if (IS_PROD) {
        /* eslint-disable no-unused-expressions */
        window.fbq?.('customTrack', 'UserLogin'); // Facebook conversion tracking
        /* eslint-enable no-unused-expressions */
      }
    } catch (err) {
      console.error('Error while performing tracking: ', err);
    }
  };

  @action
  updateStatesWithUserData = (userData) => {
    this.isAuthorized = true;
    this.isAdmin = userData.data.isAdmin === true;
    this.isSupport = userData.data.isSupport === true;
    this.hasRule = userData.data.auth.user.hasRule;
    this.hasLiveRule = userData.data.auth.user.hasLiveRule;
    this.tooltipCount = userData.data.tooltipCount;
    this.planEnds = userData.data.planEnds;
    this.trialEnds = userData.data.trialEnds;
    this.coupon = userData.data.coupon;
    this.loginCount = userData.data.loginCount;
    this.sequenceValidationDef = { ...userData.data.auth.validation, notify: { ...userData.data.auth.validation.action } };    
    this.volumeLimitUsed = userData.data.auth.user.volumeLimitUsed;
    this.twoFaEnabled = userData.data.auth.user.tfa.enabled;
    this.twoFaAuthorized = userData.data.auth.user.tfa.authorized;
    this.intercomUserHash = userData.data.auth.intercomUserHash;
    this.tradingViewWebhook = userData.data.whtv;

    if (this.twoFaEnabled) {
      this.isAuthorized = this.twoFaAuthorized;
    }
  };

  setIsAuthorizedInStorage = (isAuthorized) => {
    setDataLocalStorage(LAST_IS_AUTHORIZED, isAuthorized);
  };

  getLastIsAuthorized = () => {
    return getDataLocalStorage(LAST_IS_AUTHORIZED);
  };

  async verifyJWTToken(hash) {
    try {
      const body = {
        hash: hash,
      };
      const response = await http.post('/auth/verifyJWTToken', body);
      if (!response.error) {
        return {
          ...response,
          isValidHash: true,
        };
      }
      return {
        ...response.error,
        isValidHash: false,
      };
    } catch (error) {
      //return error;
    }
  }

  async getSMSNotificationPreferences() {
    try {
      const response = await http.get(`/settings/getSMSNotifsPreferences`);

      if (response.data && response.data.status === 'OK') {
        return response.data.data.notifs;
      } else {
        throw new Error('Unexpected response');
      }
    } catch (err) {
      errorNotification(
        'Something has gone wrong while getting your SMS notifications preferences.'
      );
    }
  }

  async updateSMSNotificationPreferences(notifs) {
    try {
      const response = await http.put('/settings/updateSMSNotifs', {
        notifs,
      });

      if (response.data && response.data.status === 'OK') {
        return;
      } else {
        throw new Error('Unexpected response');
      }
    } catch (err) {
      throw new Error('Something went wrong while updating your SMS notifications preferences');
    }
  }

  async getTelegramNotificationPreferences() {
    try {
      const response = await http.get(`/settings/getTelegramNotifsPreferences`);

      if (response.data && response.data.status === 'OK') {
        return response.data.data.notifs;
      } else {
        throw new Error('Unexpected response');
      }
    } catch (err) {
      errorNotification(
        'Something has gone wrong while getting your Telegram notifications preferences.'
      );
    }
  }

  async updateTelegramNotificationPreferences(notifs) {
    try {
      const response = await http.put('/settings/updateTelegramNotifs', {
        notifs,
      });

      if (response.data && response.data.status === 'OK') {
        return response.data.updatedNotifs;
      } else {
        throw new Error('Unexpected response');
      }
    } catch (err) {
      throw new Error(
        'Something went wrong while updating your Telegram notifications preferences'
      );
    }
  }

  async getAccountInfo() {
    try {
      const response = await http.get(`/settings/getAccountInfo`);

      if (response.data && response.data.status === 'OK') {
        if (response.data.data.phoneNumber === '') {
          await this.updateSMSNotificationPreferences([]);
        }
        if (response.data.data.telegramId === '') {
          await this.updateTelegramNotificationPreferences([]);
        }

        this.updateUserAccountInfo(response.data.data);
        return response.data.data;
      } else {
        throw new Error('Unexpected response');
      }
    } catch (err) {
      errorNotification('Something has gone wrong while getting your account information');
    }
  }

  async updateAccountInfo(newInfo) {
    try {
      const response = await http.put('/settings/updateAccountInfo', {
        newInfo,
      });

      if (response.data && response.data.status === 'OK') {
        return {
          success: true,
        };
      } else {
        if (response.data.message === 'ALREADY_IN_USE') {
          errorNotification('This phone number is already in use by someone else');
          return {
            success: false,
          };
        }
        throw new Error(response.data.message);
      }
    } catch (err) {
      errorNotification('Something has gone wrong while getting your account information');
      return {
        success: false,
      };
    }
  }

  async updateBaseCurrency(newCurrency) {
    try {
      const response = await http.put('/settings/updateBaseCurrency', {
        newCurrency,
      });

      if (response.data && response.data.status === 'OK') {
        await this.checkUser(true, true);
        await stores.walletsStores.syncUserExchanges();
        await stores.info.getLists(true);
        return;
      } else {
        throw new Error('Unexpected response');
      }
    } catch (err) {
      errorNotification('Something went wrong while updating your base currency');
    }
  }

  async updateMarketCapProtection(newMcp) {
    try {
      const response = await http.put('/settings/updateMarketCapProtection', { newMcp });

      if (response.data && response.data.status === 'OK') {
        await this.checkUser(true, true);
      } else {
        throw new Error('Unexpected response');
      }
    } catch (err) {
      errorNotification('Something went wrong while updating your market cap protection');
    }
  }

  async updateRegion(newRegion) {
    try {
      const response = await http.put('/settings/updateServerRegion', { newRegion });

      if (response.data && response.data.status === 'OK') {
        await this.checkUser(true, true);
      } else {
        throw new Error('Unexpected response');
      }
    } catch (err) {
      errorNotification('Something went wrong while updating your region');
    }
  }

  updateTelegramId = async(newId) => {
    try {
      const response = await http.put('/settings/updateTelegramChatID', {
        chatId: newId,
      });

      if (response.data && response.data.status === 'OK') {
        await this.checkUser(true, true);
        return;
      } else {
        throw new Error('Unexpected response');
      }
    } catch (err) {
      console.error('error: ', err);
      errorNotification('Something went wrong while updating your telegram id');
    }
  }

  async updateLimitOrderMargin(newValue) {
    try {
      const response = await http.put('/settings/updateLimitOrderMargin', {
        newValue,
      });

      if (response.data && response.data.status === 'OK') {
        await this.checkUser(true, true);
        return;
      } else {
        throw new Error('Unexpected response');
      }
    } catch (err) {
      errorNotification('Something went wrong while updating your limit order range');
    }
  }

  async sendIsLoggedInConfirmation() {
    try {
      await http.post('/auth/isLoggedIn');
    } catch (err) { }
  }

  @action
  async get2FAInfo() {
    try {
      const { data: response } = await http.get('/settings/get2faInfo');
      if (response && response.status === 'OK') {
        await this.checkUser(true, true);
        this.twoFaEnabled = response.data.enabled;
      }
      return { enabled: this.twoFaEnabled, authorized: this.twoFaAuthorized };
    } catch (error) {
      errorNotification('Something has gone wrong while getting your 2FA information');
    }
  }

  @action
  async init2FA() {
    try {
      const { data: response } = await http.post('/auth/2fa/init');
      if (response && response.status === 'OK') {
        await this.checkUser(true, true);
        return {
          qrUrl: response.data.qrUrl,
          qrString: response.data.qrString,
        };
      }
      throw new Error();
    } catch (error) {
      errorNotification(error?.message || 'Error initializing two factor authentication');
      return null;
    }
  }

  @action
  async enable2FA(token) {
    try {
      const { data: response } = await http.post('/auth/2fa/enable', { token });
      if (response && response.status === 'OK') {
        await this.checkUser(true, true);
        if (response.data.authorized) {
          return response.data.backupCodes;
        }
        throw new Error('Incorrect 2FA Code');
      }
      throw new Error();
    } catch (error) {
      errorNotification(error?.message || 'Error enabling two factor authentication');
      return [];
    }
  }

  @action
  async disable2FA(token) {
    try {
      const { data: response } = await http.post('/auth/2fa/disable', { token });
      if (response && response.status === 'OK') {
        await this.checkUser(true, true);
        return response.data.disabled;
      }
      throw new Error('Incorrect 2FA Code');
    } catch (error) {
      errorNotification(error?.message || 'Error disabling two factor authentication');
    }
  }

  // Promobar "Invest in us"
  @observable isPromoBarInvestShowed = false;

  @action
  setVisibilityPromoBarInvest = (event, whatToDo) => {
    const barWasClosed = +sessionStorage.getItem('promoBar_invest_wasClosed') === 1;

    if (barWasClosed) return;

    if (whatToDo === 'show') {
      this.isPromoBarInvestShowed = true;
    } else if (whatToDo === 'close') {
      sessionStorage.setItem('promoBar_invest_wasClosed', 1);
      this.isPromoBarInvestShowed = false;
    }
  };

  @action
  setHasRule(hasRule) {
    this.hasRule = hasRule;
  }

  // "Connect exchange" popover counter
  // @observable connectExPopoverCounter = 0;
  //
  // @action
  // increaseConnectExPopoverCounter = () => {
  //   this.connectExPopoverCounter += 1;
  // }
}
