import { Component } from 'react';

import './style.scss';
import { observer } from 'mobx-react';
@observer
class SimpleSequenceLoader extends Component {
  render() {
    //const text = this.props.text;
    return null;
    // if(!this.props.store.seqInit.preloadSequences){
    //   return null;
    // }
    // return (
    //   <div className="simple-loader-rule-sequence icon-loader">
    //     <div className="cover">
    //       <div className='loader' />
    //     </div>
    //     <div className="text">{text}</div>
    //   </div>
    // );
  }
}

export default SimpleSequenceLoader;
