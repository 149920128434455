import { useState, useCallback } from 'react';

export function useInputState<T>(initialState: T): [T, (e: any) => void] {
  const [state, setState] = useState(initialState);

  const setInputState = useCallback(
    (e: any) => {
      if (e?.target?.name && e.target.value != null) {
        setState({
          ...state,
          [e.target.name]: e.target.value,
        });
      } else {
        setState({
          ...state,
          ...e,
        });
      }
    },
    [state, setState]
  );

  return [state, setInputState];
}
