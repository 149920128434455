import { observable, action } from 'mobx';
import http from '../helpers/http';
import { errorNotification } from '../helpers/notification';
import { calculateTotalAmountByExchange, sortAndCalculatePriceWallets } from '../helpers/conversion'
import stores from './index';
import { EXCHANGES_TYPE } from '../constants/exchanges';
import { AbstractStore } from './AbstractStore';

export default class ExchangeStore extends AbstractStore {
  constructor(rootStore) {
    super();
    this.root = rootStore;
    this.storeInitialState();
  }

  @observable assets = {};
  @observable markets = {};
  @observable instruments = {};
  @observable fiats = {};
  @observable isLoadingAssets = false;
  @observable isLoadedAssets = [];
  @observable exchangeType = {};

  @action
  async getAssets(exchangeId, force = false, newCurrency = '') {
    if (newCurrency === stores.user?.user?.user?.baseCurrency) {
      newCurrency = '';
    }

    if (!force) {
      if (this.isLoadingAssets || this.isLoadedAssets.indexOf(exchangeId + newCurrency) >= 0) {
        return;
      }
    }

    try {
      this.isLoadingAssets = true;

      let resp;

      if (newCurrency !== '') {
        resp = await http.get(`/assets?exchange_id=${exchangeId}&currency=${newCurrency}`);
      } else {
        resp = await http.get(`/assets?exchange_id=${exchangeId}`);
      }

      this.isLoadingAssets = false;

      if (resp.data) {
        if (resp.data.status === 'OK') {
          this.assets[exchangeId + newCurrency] = resp.data.data.assets;
          this.markets[exchangeId + newCurrency] =
            resp.data.data.instruments.length > 0 ? null : resp.data.data.markets;
          this.instruments[exchangeId + newCurrency] =
            resp.data.data.instruments.length > 0 ? resp.data.data.instruments : null;
          this.fiats[exchangeId + newCurrency] = resp.data.data.fiats;
          this.exchangeType[exchangeId + newCurrency] = resp.data.data.exchangeType;

          if (
            (this.markets[exchangeId + newCurrency] &&
              this.markets[exchangeId + newCurrency].length > 0) ||
            (this.instruments[exchangeId + newCurrency] &&
              this.instruments[exchangeId + newCurrency].length > 0)
          ) {
            this.isLoadedAssets.push(exchangeId + newCurrency);
          }

          const walletStores = stores.walletsStores;

          if (
            !walletStores.syncExchangeAssetsDone.includes(exchangeId + newCurrency) &&
            walletStores.syncUpdateRunning
          ) {
            walletStores.addToSyncExchangeBalancesDone(exchangeId + newCurrency);

            if (
              (walletStores.syncExchangeBalancesDone.length ===
                stores.userInfo.exchanges.length) ===
              walletStores.syncExchangeAssetsDone.length
            ) {
              walletStores.changeSyncUpdateStatus(false);
            }
          }

          const exchangeIdCurrency = exchangeId + newCurrency;

          const results = calculateTotalAmountByExchange({
            exchangeId: exchangeIdCurrency,
            force: true,
            wallets: stores.userInfo.balances[exchangeIdCurrency]?.data,
            assets: stores.exchangeInfo.assets[exchangeIdCurrency],
            instruments: stores.exchangeInfo.instruments[exchangeIdCurrency],
            exchangeType: stores.exchangeInfo.exchangeType[exchangeIdCurrency],
            totalBalance: stores.userInfo.totalBalances[exchangeIdCurrency],
            currencies: stores.info.currencies,
            baseCurrency: stores.user.user.user.baseCurrency,
            tempCurrency: newCurrency || stores.addRule.tempCurrency,
          });

          if(results) {
            const {balances, totalBalance} = results;
            stores.userInfo.setTotalBalance(exchangeIdCurrency, totalBalance);
            stores.userInfo.setBalances(exchangeIdCurrency, balances);

          }

        } else {
          errorNotification(resp.data.message);
        }

        return resp.data.authorized;
      } else {
        throw new Error('Invalid response ' + resp.toString());
      }
    } catch (error) {
      console.error(error);
      errorNotification(`Something has gone wrong. Please try it again later.`);
      this.isLoadingAssets = false;
    }
  }

  @action
  async setCoinsData(newData, exchange) {
    if (this.exchangeType[exchange] === EXCHANGES_TYPE.LEVERAGE) {
      if (!this.instruments[exchange]) {
        await this.getAssets(exchange, true);
      }
    } else {
      if (!this.assets[exchange]) {
        await this.getAssets(exchange, true);
      }
    }

    if (!stores.userInfo.balances[exchange]) {
      await stores.userInfo.getBalances(exchange, true);
    }

    if (this.exchangeType[exchange] === EXCHANGES_TYPE.LEVERAGE) {
      for (const i in this.instruments[exchange]) {
        const symbol = this.instruments[exchange][i].symbol;

        if (newData[symbol]) {
          this.instruments[exchange][i] = {
            ...this.instruments[exchange][i],
            ...newData[symbol],
          };
        }
      }

      const results = sortAndCalculatePriceWallets({
        balances: stores.userInfo.balances[exchange]?.data,
        assets: this.instruments[exchange],
        exchangeId: exchange,
        currencies: stores.info.currencies,
        baseCurrency: stores.user.user.user.baseCurrency,
        tempCurrency: stores.addRule.tempCurrency
      }
      );

      if(results) {
        const {balances, totalBalance} = results;
        stores.userInfo.setTotalBalance(exchange, totalBalance);
        stores.userInfo.setBalances(exchange, balances);
      }
    } else {
      for (const symbol in this.assets[exchange]) {
        this.assets[exchange][symbol] = {
          ...this.assets[exchange][symbol],
          ...newData[symbol],
        };
      }

      // sortAndCalculatePriceWallets(
      //   stores.userInfo.balances[exchange].data,
      //   this.assets[exchange],
      //   exchange
      // );

      const results = sortAndCalculatePriceWallets({
        balances: stores.userInfo.balances[exchange]?.data,
        assets: this.assets[exchange],
        exchangeId: exchange,
        currencies: stores.info.currencies,
        baseCurrency: stores.user.user.user.baseCurrency,
        tempCurrency: stores.addRule.tempCurrency
      });

      if(results) {
        const {balances, totalBalance} = results;
        stores.userInfo.setTotalBalance(exchange, totalBalance);
        stores.userInfo.setBalances(exchange, balances);
      }
    }
  }
}
