import { useEffect, useState } from 'react';

export interface IElementSizeProps {
  width: HTMLElement['offsetWidth'];
  left: HTMLElement['offsetLeft'];
}

const useResize = (element: HTMLElement) => {
  const [width, setWidth] = useState<number>(0);
  const [left, setLeft] = useState<number>(0);
  const [right, setRight] = useState<number>(0);

  useEffect(() => {
    if (!element) return;

    const handleResize = () => {
      setWidth(element.offsetWidth);
      setLeft(element.offsetLeft);
      setRight(element.getBoundingClientRect().right);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => element.removeEventListener('resize', handleResize);
  });

  return { width, left, right };
};

export default useResize;
