import type { TDataProviderConnectExchangeBinanceOAuthGetAccessToken } from "v2/dataProviders/connectExchangeBinanceOAuth/createDataProviderConnectExchangeBinanceOAuthGetAccessToken"
import type { TDataProviderConnectExchangeBinanceOAuthPrepare } from "v2/dataProviders/connectExchangeBinanceOAuth/createDataProviderConnectExchangeBinanceOAuthPrepare"
import type { TDataProviderConnectExchangeBinanceOAuthCreateApiKeys } from "v2/dataProviders/connectExchangeBinanceOAuth/createDataProviderConnectExchangeBinanceOAuthCreateApiKeys"
import type { TDomainStateConnectExchangeBinanceOAuth } from "./stateConnectExchangeBinanceOAuth"
import type { TDispatchAction, TGetState } from "v2/types"

import { createUseCasesConnectExchangeBinanceOAuthGetAccessToken } from "./createUseCasesFetchGetAccessToken"
import { createUseCasesConnectExchangeBinanceOAuthPrepare } from "./createUseCasesFetchPrepare"
import { createUseCasesConnectExchangeBinanceOAuthCreateApiKeys } from "./createUseCasesFetchCreateApiKeys"

export type TDomainUseCasesConnectExchangeBinanceOAuth = ReturnType<typeof createUseCasesConnectExchangeBinanceOAuth>

export const createUseCasesConnectExchangeBinanceOAuth = ({
  serviceLocation, 
  dataProviderConnectExchangeBinanceOAuthPrepare,
  dataProviderConnectExchangeBinanceOAuthGetAccessToken,
  dataProviderConnectExchangeBinanceOAuthCreateApiKeys,
  dispatch,
  getState
}: {
  serviceLocation: { replace: (url: string) => void }, 
  dataProviderConnectExchangeBinanceOAuthPrepare: TDataProviderConnectExchangeBinanceOAuthPrepare,
  dataProviderConnectExchangeBinanceOAuthGetAccessToken: TDataProviderConnectExchangeBinanceOAuthGetAccessToken, 
  dataProviderConnectExchangeBinanceOAuthCreateApiKeys: TDataProviderConnectExchangeBinanceOAuthCreateApiKeys
  dispatch: TDispatchAction,
  getState: TGetState<TDomainStateConnectExchangeBinanceOAuth> 
}) => {
  const useCasesConnectExchangeBinanceOAuthGetAccessToken = createUseCasesConnectExchangeBinanceOAuthGetAccessToken({
    dataProviderConnectExchangeBinanceOAuthGetAccessToken,
    dispatch,
    getState: () => getState().getAccessToken,
  })

  const useCasesConnectExchangeBinanceOAuthPrepare = createUseCasesConnectExchangeBinanceOAuthPrepare({
    serviceLocation,
    dataProviderConnectExchangeBinanceOAuthPrepare,
    dispatch,
    getState: () => getState().prepare,
  })

  const useCasesConnectExchangeBinanceOAuthCreateApiKeys = createUseCasesConnectExchangeBinanceOAuthCreateApiKeys({
    dataProviderConnectExchangeBinanceOAuthCreateApiKeys,
    dispatch,
    getState: () => getState().createApiKeys,
  })

  return {
    ...useCasesConnectExchangeBinanceOAuthPrepare,
    ...useCasesConnectExchangeBinanceOAuthGetAccessToken,
    ...useCasesConnectExchangeBinanceOAuthCreateApiKeys,
    getAccessTokenAndCreateApiKeys: async ({ code }: { code: string }) => {
      await useCasesConnectExchangeBinanceOAuthGetAccessToken.getAccessToken({ code })

      const state = getState()
      const accessToken = state.getAccessToken.data?.binance.accessToken

      if(!accessToken) return

      await useCasesConnectExchangeBinanceOAuthCreateApiKeys.createApiKeys({ accessToken })
    }
  }
}
