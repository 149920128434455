import React from 'react'

import { Dialog, Box } from 'design-system'

import type { DialogProps, BoxProps } from 'design-system'
import { IconClose } from 'design-system'

import { useTheme } from '@mui/material'

export interface VideoDialogProps extends DialogProps {
  IFrameProps: BoxProps<'iframe'>
}

const testId = 'VideoDialog'
export const VideoDialog = (props: VideoDialogProps) => {
  const {
    sx,
    IFrameProps,
    onClick,
    ...otherProps
  } = props

  const theme = useTheme()

  return (
    <Dialog
      data-testid={testId}
      className={testId}
      sx={{
        ...sx
      }}
      PaperProps={{
        sx: {
          borderRadius: 15,
          maxWidth: 'none',
          [theme.breakpoints.down('sm')]: {
            margin: 0,
          },
        }
      }}
      contentOverlay={
        <Box
          component={'div'}
          sx={{
            position: 'absolute',
            top: 20,
            right: 20,
            cursor: 'pointer'
          }}
          onClick={onClick}
        >
          <IconClose
            fill={theme.palette.coinrule.basic.greyLight}
          />
        </Box>
      }
      {...otherProps}
    >
      <Box
        component={'iframe'}
        {...IFrameProps}
      />
    </Dialog>
  )
}

export default VideoDialog
