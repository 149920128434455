import React from 'react'

import { useTheme } from '@mui/material'
import { TimelineDot as MuiTimelineDot } from '@mui/lab'

import type { TimelineDotProps as MuiTimelineDotProps } from '@mui/lab'

export interface TimelineDotProps extends MuiTimelineDotProps {}

const testId = 'TimelineDot'
export const TimelineDot = (props: TimelineDotProps) => {
  const {
    sx,
    ...otherProps
  } = props

  const theme = useTheme()

  return (
    <MuiTimelineDot
      data-testid={testId}
      className={testId}
      sx={{
        width: 50,
        height: 50,
        borderWidth: 4,
        borderColor: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
      variant={'outlined'}
      {...otherProps}
    />
  )
}

export default TimelineDot
