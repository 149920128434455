import type { TDataProviderAccessSharedRule } from "v2/dataProviders/accessSharedRule"

import type { TGetState, TDispatchAction, THTTPClientError } from "v2/types"
import { actionCreatorsAccessSharedRule, EDomainActionsAccessSharedRule } from "./actionsAccessSharedRule"
import type { DTOErrorGetSharedRule } from "./DTOs"

import type { TDomainStateAccessSharedRule } from "./stateAccessSharedRule"

export type TDomainUseCasesAccessSharedRule = ReturnType<typeof createUseCasesAccessSharedRule>

export const createUseCasesAccessSharedRule = ({ dataProviderAccessSharedRule, dispatch, getState }: { dataProviderAccessSharedRule: TDataProviderAccessSharedRule, dispatch: TDispatchAction, getState: TGetState<TDomainStateAccessSharedRule> }) => {
  return {
    setOffset: ({ offset }: { offset: number }) => {
      dispatch(actionCreatorsAccessSharedRule[EDomainActionsAccessSharedRule.SET_OFFSET]({ offset }))
    },
    toggleShowOnlyTrades: () => {
      const { showOnlyTrades } = getState();
      dispatch(actionCreatorsAccessSharedRule[EDomainActionsAccessSharedRule.SET_SHOW_ONLY_TRADES]({ showOnlyTrades: !showOnlyTrades }))
    },
    getSharedRule: async ({ ruleSlug }: { ruleSlug: string }) => {
      const { showOnlyTrades, offset } = getState();

      dispatch(actionCreatorsAccessSharedRule[EDomainActionsAccessSharedRule.FETCH_STARTED]())

      try {
        const dtoSharedRule = await dataProviderAccessSharedRule.getSharedRule({ ruleSlug, showOnlyTrades, offset })

        dispatch(actionCreatorsAccessSharedRule[EDomainActionsAccessSharedRule.FETCH_SUCCEEDED]({ sharedRule: dtoSharedRule }))
      } catch (error) {
        const httpClientError = error as THTTPClientError 
        let domainError: DTOErrorGetSharedRule
        if(httpClientError.isAxiosError && httpClientError.response?.status === 404 ) {
          domainError = { 
            code: 404,
            message: 'Rule not found',
            origin: 'domains/accessSharedRule' as const,
          }
        } else {
          domainError = { 
            code: 500, 
            message: 'Something went wrong while getting shared rule data',
            origin: 'domains/accessSharedRule' as const,
          }
        }
        dispatch(actionCreatorsAccessSharedRule[EDomainActionsAccessSharedRule.FETCH_FAILED]({ error: domainError }))
      }
    },
    fetchReset: () => {
      dispatch(actionCreatorsAccessSharedRule[EDomainActionsAccessSharedRule.FETCH_RESET]())
    }
  }
}
