import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { ACTION_TYPE } from '../../../../stores/addRule';
import { TRADE_TYPES } from '../../../../stores/addRule';
import stores from '../../../../stores';
import Popover from '../../../../components/Popover';
import { toJS } from 'mobx';
import ErrorBlock from '../../../../components/Sequences/ErrorBlock';
import { EXCHANGES_TYPE } from '../../../../constants/exchanges';
import { ReactComponent as AddIcon } from 'assets/images/icons/CoinMultipleCoinSelectAddIcon.svg';

const Option = Select.Option;

@observer
class DoNot extends Component {
  isLeverage() {
    const exchangeType = stores.exchangeInfo.exchangeType[this.props.store.rule.ex];
    return exchangeType && exchangeType === EXCHANGES_TYPE.LEVERAGE;
  }

  checkIfExchangeLoaded() {
    return (
      this.props.store.rule.ex &&
      stores.userInfo.balances[this.props.store.rule.ex] &&
      stores.exchangeInfo.assets[this.props.store.rule.ex] &&
      ((this.isLeverage() && stores.exchangeInfo.instruments[this.props.store.rule.ex]) ||
        stores.exchangeInfo.markets[this.props.store.rule.ex])
    );
  }

  renderCoinsOptions(previousElement) {
    const previousAction = previousElement.data.actions[0];
    let coins = [];
    let options = [];

    if (this.checkIfExchangeLoaded()) {
      coins = Object.keys(stores.exchangeInfo.assets[this.props.store.rule.ex]).sort();

      options = coins.map((asset, i) => {
        if (
          stores.exchangeInfo.assets[this.props.store.rule.ex][asset] &&
          previousAction.b.indexOf(asset) === -1
        ) {
          const assetObj = stores.exchangeInfo.assets[this.props.store.rule.ex][asset];
          const name =
            assetObj?.name?.toUpperCase() === asset ? asset : `${asset} ${assetObj?.name}`;

          return (
            <Option key={asset} value={asset} selected_label={asset}>
              {name}
            </Option>
          );
        }

        return null;
      });
    } else {
      options = (
        <Option value='No exchange' disabled={true}>
          No exchange selected
        </Option>
      );
    }

    return options;
  }

  renderLabel(previousElement) {
    return <span className={'first-titile'}> {previousElement.data.actions[0].at} </span>;
  }

  changeDoNotSelect(value) {
    this.props.store.seqInit.sequences[this.props.sequenceIndex].data.operators[0].dn = value;
    this.props.store.seqInit.checkIfStillError(this.props.sequenceIndex, 'dn');
  }

  renderNonCoinSpecificOptions = () => {
    const options = [];

    if (!this.checkIfExchangeLoaded()) return null;

    if (
      this.props.store.seqInit.sequences[this.props.sequenceIndex - 1].data.actions[0].at ===
      TRADE_TYPES.BUY
    ) {
      options.push(
        <Option value='_anycoins' key='_anycoins' selected_label='any of my coins'>
          any of my coins
        </Option>
      );
    }

    options.push(
      <Option value='_samecoins' key='_samecoins' selected_label='same coin twice'>
        same coin twice
      </Option>
    );

    options.push(
      <Option value='_stablecoins' key='_stablecoins' selected_label='stable coins'>
        stable coins
      </Option>,
      <Option key={'-sep2-'} value='-sep2-' disabled={true}>
        {' '}
      </Option>
    );

    return options;
  };

  renderSelectBoxes(previousElement) {
    let options = this.renderCoinsOptions(previousElement);

    if (options.length > 0) {
      options = options.filter((item) => item !== null);
    }

    const value = this.props.store.seqInit.sequences[this.props.sequenceIndex].data.operators[0].dn;

    const sortOptions = () => {
      const selectedOptions = options.filter((option) => value.includes(option.props.value));

      options.unshift(
        ...selectedOptions,
        <Option value='-sep3-' title='' disabled={true} key='-sep3-'>
          {' '}
        </Option>
      );

      const flag = {};
      const uniqueOptions = [];

      // Removing duplicate options
      options.forEach((option) => {
        if (option?.props && !flag[option?.props?.value]) {
          flag[option?.props?.value] = true;
          uniqueOptions.push(option);
        }
      });

      options = uniqueOptions;
    };

    if (Array.isArray(value) && value?.length && options?.length) {
      sortOptions();
    }

    const isMobile = window.innerWidth <= 575;

    return (
      <React.Fragment>
        <Popover text='Select coins'>
          <Select
            value={value}
            mode='multiple'
            showSearch={true}
            size={'default'}
            showArrow={true}
            maxTagCount={isMobile ? 1 : 3}
            optionLabelProp='selected_label'
            style={{ minWidth: '450px' }}
            className={`green-shadow multiple-coin-select do-not-select-boxes`}
            placeholder='select coins'
            onChange={(value) => {
              this.changeDoNotSelect(value);
            }}
            filterOption={(input, option) => {
              if (option.props.children) {
                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }

              return false;
            }}
            suffixIcon={<AddIcon />}
          >
            {this.renderNonCoinSpecificOptions()}
            {options}
          </Select>
        </Popover>
      </React.Fragment>
    );
  }

  render() {
    let showError = false;
    let errorText = '';
    const sequenceIndex = this.props.sequenceIndex;
    let previousElement = this.props.store.seqInit.getPreviousElement(sequenceIndex);
    if (previousElement && previousElement.type !== ACTION_TYPE) {
      return null;
    }

    if (
      toJS(this.props.store.seqInit.errors[sequenceIndex]) !== undefined &&
      this.props.store.seqInit.errors[sequenceIndex][0] !== undefined
    ) {
      showError = true;
      errorText = this.props.store.seqInit.errors[sequenceIndex][0].text;
    } else {
      showError = false;
    }

    return (
      <React.Fragment>
        <div className='content' id='DoNotItem'>
          {this.props.removeBut}
          {this.props.helpIcon}

          <div
            className={
              'select-grey wrap-area do-not ' +
              this.props.store.seqInit.getErrorClass(sequenceIndex, 'dn')
            }
          >
            {this.renderLabel(previousElement)}
            {this.renderSelectBoxes(previousElement)}
          </div>
        </div>
        <ErrorBlock content={errorText} show={showError} />
      </React.Fragment>
    );
  }
}

export default DoNot;
