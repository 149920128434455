import React from 'react';

import '../style.scss';
import logo from '../../../assets/images/logo-robot-gray.svg';

export interface IProps {
  text?: string;
  normalLoader?: boolean;
}

const IconLoading: React.FC<IProps> = ({ text, normalLoader }) => {
  return (
    <div
      data-testid='IconLoading'
      className={`loading-page icon-loader ${normalLoader ? 'icon-loader--normal' : ''}`}
    >
      <div className='cover'>
        <img src={logo} alt='coinrule logo' className='icon-logo' />
        <div className='loader' />
      </div>
      <div className='text'>{text}</div>
    </div>
  );
};

export default IconLoading;
