import React from 'react'

import type { CardProps } from '@mui/material'

import { Card, ClickAwayListener, tooltipClasses } from '@mui/material'

import { Button, Tooltip, IconInfo, Box } from 'design-system'

export interface PromoCardProps extends CardProps {
  onClick: () => void,
  buttonLabel: string,
  bgImage: string,
  showInfoTooltip?: boolean,
  tooltipText?: string,
}

const testId = 'PromoCard'
export const PromoCard = (props: PromoCardProps) => {
  const {
    sx,
    onClick,
    buttonLabel,
    bgImage,
    showInfoTooltip = false,
    tooltipText = '',
    ...otherProps
  } = props

  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <Card
      data-testid={testId}
      className={testId}
      sx={{
        backgroundImage: `url(${bgImage})`,
        width: '100%',
        height: 100,
        backgroundSize: 'cover',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        position: 'relative',
        boxShadow: 'none',
        marginBottom: '15px',
        ...sx,
      }}
      {...otherProps}
    >
      {
        showInfoTooltip ?
          <ClickAwayListener
            onClickAway={handleTooltipClose}
          >
            <div>
              <Tooltip
                title={tooltipText}
                onClick={handleTooltipOpen}
                onClose={handleTooltipClose}
                open={open}
                PopperProps={{
                  // @ts-ignore according to docs and runtime sx is a valid prop on Popper component https://mui.com/api/popper/
                  sx: {
                    [`& .${tooltipClasses.arrow}`]: {
                      color: '#1c2236',
                    },
                    [`& .${tooltipClasses.tooltip}`]: {
                      backgroundColor: '#1c2236',
                    },
                  }
                }}
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '7px',
                  cursor: 'pointer',
                }}
              >
                <Box>
                  <IconInfo />
                </Box>
              </Tooltip>
            </div>
          </ClickAwayListener>
          :
          null
      }
      <Button
        onClick={onClick}
        colorVariant={'binancePromo'}
        sx={{
          color: 'white',
          marginBottom: '10px',
          width: '40%',
          textTransform: 'lowercase',
          zIndex: 0,
          '& span': {
            fontSize: 15,
            lineHeight: 17,
          },
        }}
      >
        {buttonLabel}
      </Button>
    </Card>
  )
}

export default PromoCard
