import React from 'react'

export interface IconRuleAlertProps extends React.SVGProps<SVGSVGElement> {}

const testId = 'IconRuleAlert'
export const IconRuleAlert = (props: IconRuleAlertProps) => {
  const {
    ...otherProps
  } = props

  return (
    <svg
      data-testid={testId}
      width={"1em"}
      height={"1em"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...otherProps}
    >
      <title>iconfinder_arrows-37_808423</title>
      <g
        fill={"#ffd800"}
        fillRule={"nonzero"}
      >
        <path d={"M8.5.291C3.825.291 0 4.116 0 8.791s3.825 8.5 8.5 8.5 8.5-3.825 8.5-8.5-3.825-8.5-8.5-8.5Zm0 15.583c-3.896 0-7.083-3.187-7.083-7.083 0-3.896 3.187-7.083 7.083-7.083 3.896 0 7.083 3.187 7.083 7.083 0 3.896-3.187 7.083-7.083 7.083Z"} />
        <path d={"M9.51 9.082h-2l-.218-4.393H9.68zM9.672 11.845a1.247 1.247 0 1 1-2.494 0 1.247 1.247 0 0 1 2.494 0Z"} />
      </g>
    </svg>
  )
}

export default IconRuleAlert
