import React from 'react';
import { observer } from 'mobx-react';

@observer
class DirectOrder extends React.Component {
  render() {
    return (
      <div className='newRuleFormSection content'>
        {this.props.removeBut}
        {this.props.helpIcon}
        <label className='smartphoneView30width'>A market order will be placed immediately</label>
      </div>
    );
  }
}

export default DirectOrder;
