import PaymentStore from '../stores/payment';
import { CustomWindow, ICoupon } from '../typings/types';
import { getEmail } from '../helpers/helpers';
import { reduceCoupons } from 'helpers/coupon';
import stores from '../stores';
import { History } from 'history';
import history from '../helpers/history';

type Plan = { code: string; price: number; name: string };

export interface PaymentProvider {
  handleDowngrade(plan: Plan, period: string, coupons?: string[]): unknown;
  handlePayment(plan: Plan, period: string, coupons?: string[]): unknown;
  downgradeUser(planCode: string, period: string, coupons?: string[]): unknown;
  handleChangePaymentMethod(): unknown;
  keepPlan(plan: Plan, period: string): unknown;
  verifyPayment(plan: Plan, period: string): unknown;
  getSubscriptionCancelled(): string;
  getSubscriptionEnds(): string;
  getUserSubscription(): unknown;
  fetchCoupons(): unknown;
  getCoupons: (planCode: string) => ICoupon | undefined;
  unSubscribe(plan: Plan): unknown;
  pauseSubscription(plan: Plan): unknown;
  isFetchingSubscription: boolean;
}

declare let window: CustomWindow;

export const PaymentProviderFactory = {
  getInstance: () => {
    return ChargebeePaymentProvider(stores.payment, history);
  },
};

const ChargebeePaymentProvider = (
  paymentStore: PaymentStore,
  history: History
): PaymentProvider => {
  return {
    handleDowngrade(plan: Plan, period: string, coupons?: string[]) {
      return () => {
        const urlQueries = coupons ? `?coupons=${JSON.stringify(coupons)}` : '';

        history.push(`/settings/billing/downgrade/${plan.code}-${period}${urlQueries}`);
      };
    },
    handlePayment(plan: Plan, period: string, coupons: string[]) {
      return () => {
        const chargebeeInstance = window.Chargebee.getInstance();

        chargebeeInstance.openCheckout({
          hostedPage: () => paymentStore.getPaymentHostedPage(`${plan.code}-${period}`, 1, coupons),
          success: () =>
            paymentStore.onSuccessfulChargebeeSubscription(chargebeeInstance, 'upgrade'),
        });
      };
    },
    downgradeUser: async (planCode: string, period: string, coupons?: string[]) => {
      const chargebeeInstance = window.Chargebee.getInstance();

      chargebeeInstance.openCheckout({
        hostedPage: () => paymentStore.getPaymentHostedPage(`${planCode}-${period}`, 1, coupons),
        success: () =>
          paymentStore.onSuccessfulChargebeeSubscription(chargebeeInstance, 'downgrade'),
      });
    },
    handleChangePaymentMethod() {
      return () => {
        const chargebeeInstance = window.Chargebee.getInstance();

        chargebeeInstance.openCheckout({
          hostedPage: () => paymentStore.getChangePaymentMethodHostedPage(),
          success: () => paymentStore.onSuccessfulPaymentMethodChange(chargebeeInstance),
        });
      };
    },
    keepPlan(plan: Plan, period: string) {
      return () => stores.payment.keepSubscription();
    },
    verifyPayment(plan: Plan, period: string) { },
    getSubscriptionCancelled() {
      return stores.payment.getSubscriptionCancelled();
    },
    getSubscriptionEnds() {
      return stores.payment.getNextBillingDate();
    },
    getUserSubscription: async () => {
      await stores.payment.getUserSubscription();
    },
    fetchCoupons: async () => {
      await stores.payment.handleFetchCoupon();
    },
    getCoupons: (planCode: string) => {
      const { coupon } = stores.payment;
      if (coupon) {
        const coupons = reduceCoupons([coupon]);
        return coupons && coupons[planCode];
      }
    },
    unSubscribe: async () => {
      await stores.payment.cancelSubscription();
    },
    pauseSubscription: (plan) => {
      window.location.href = getEmail('support', `Pause Subscription to  ${plan?.name}`);
    },
    isFetchingSubscription: stores.payment.isFetchingSubscription,
  };
};
