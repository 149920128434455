import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import './style.scss';
import { STEPS } from '../../../stores/auth';

class SocialMedia extends Component {
  render() {
    return (
      <div className={`additionalLinks`}>
        <div className={`additionalLinks_account`}>
          <div className='additionalLinks__additionalLink'>
            <NavLink
              to={{
                pathname: '/forgotpassword',
                state: { step: STEPS.EMAIL },
              }}
            >
              Forgot password?
            </NavLink>
          </div>
          <div className='additionalLinks__additionalLink'>
            <NavLink
              to={{
                pathname: '/register',
                state: { step: STEPS.EMAIL_PASSWORD },
              }}
            >
              Create account
            </NavLink>
          </div>
        </div>

        <div className={`additionalLinks_terms`}>
          <div className='additionalLinks__additionalLink'>
            <a href='https://coinrule.com/help/contact/' target='_blank' rel='noopener noreferrer'>
              Contact us
            </a>
          </div>
          <div className='additionalLinks__additionalLink'>
            <a href='https://coinrule.com/terms.html' target='_blank' rel='noopener noreferrer'>
              Terms
            </a>
          </div>
          <div className='additionalLinks__additionalLink'>
            <a href='https://coinrule.com/privacy.html' target='_blank' rel='noopener noreferrer'>
              Policy
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default SocialMedia;
