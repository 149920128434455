import React, { Component } from 'react';
import { Button } from 'antd';
import Footer from '../Footer';
import MenuItems from '../MenuItems';
import { observer } from 'mobx-react';

import './style.scss';
import { ErrorBoundary } from '../ErrorBoundary';
import { CoinruleButton } from '../CoinruleButton';

@observer
class GlobalSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLogoAvaliable: false,
    };
  }

  renderLoginButton() {
    return (
      <div className='sidebar-content'>
        <div className='login'>
          <Button loading={this.state.isLoading} onClick={this.handleLogin}>
            Login
          </Button>
        </div>
      </div>
    );
  }

  makeLogoAvailable = () => {
    this.setState(() => {
      return { isLogoAvaliable: true };
    });
  };

  makeLogoAvailableTimeout;

  componentDidMount() {
    this.makeLogoAvailableTimeout = setTimeout(this.makeLogoAvailable, 850);
  }

  componentWillUnmount(prevProps, prevState, snapshot) {
    clearTimeout(this.makeLogoAvailableTimeout);
  }

  render() {
    const isEditRulePage = false;
    const isAdminPage = false;

    if (!this.props.loggedOut) {
      return (
        /*Global Sidebar*/
        <div
          className={`globalSidebar
													${(isEditRulePage || isAdminPage) && 'globalSidebar_foldable'}
													${this.props.extraClass}`}
        >
          <div
            className={`globalSidebar__wrapper
														${this.state.isSidebarFolded && `globalSidebar__wrapper_folded`}`}
            style={{ paddingBottom: '0' }}
          >
            {/*Logo in Global Sidebar*/}
            <div
              className={`gsLogo
														${!this.state.isLogoAvaliable && 'gsLogo_unavailable'}
														${this.state.isSidebarFolded && 'gsLogo_folded'}
														globalSidebar__logo`}
            >
              <div className={`gsLogo__clickableArea`}>&nbsp;</div>

              <div className={`gsLogo__emblem`}>
                <img
                  src={require('../../assets/images/gsLogo_emblemImg.gif')}
                  alt='Coinrule'
                  className={`gsLogo__emblemImg`}
                />
              </div>

              <div className={`gsLogo__textPartWrap`}>
                <div className={`gsLogo__text`}>
                  <img src={require('../../assets/images/logo-text.svg')} alt='Coinrule' />
                </div>

                <div className={`gsLogo__badgesWrap`}>
                  <div className={`gsLogo__versionBadge`}>Beta</div>
                </div>
              </div>
            </div>

            {/*Main Menu in Global Sidebar*/}
            <div
              className={`gsMainMenu
															${this.state.isSidebarFolded && 'gsMainMenu_folded'}
															globalSidebar__mainMenu`}
            >
              <MenuItems useImg={true} noIcons={true} />
            </div>

            {/*Unfold arrow*/}
            <div className={`globalSidebar__unfoldArrow`}>&nbsp;</div>

            {/*Bottom buttons*/}
            <div className={`globalSidebar__bottomButtons`}>
              <CoinruleButton
                icon='check'
                fillType='solid'
                className={`sidebar-bottom__buttonLink
                            sidebar-bottom__getPlanButtonLink`}
                to='/settings/billing'
                ariaLabel='Get Plan'
              >
                <span className={`coinruleBut__text`}>Get Plan</span>
              </CoinruleButton>

              <CoinruleButton
                color='violet'
                icon='HelpCenter'
                component={'a'}
                className={`sidebar-bottom__buttonLink`}
                href='https://coinrule.com/help'
                target='_blank'
                rel='noopener noreferrer'
                ariaLabel='Help Center'
              >
                <span className={`coinruleBut__text`}>Help Center</span>
              </CoinruleButton>

              <div to='/logout' className={`globalSidebar__logoutButton`}>
                <span className={`globalSidebar__logoutButtonText`}>Logout</span>
              </div>
            </div>

            <div className={`globalSidebar__copyright`}>
              <Footer mixinClass={`globalSidebar`} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={`globalSidebar ${this.props.extraClass}`}>
          <ErrorBoundary name={'boundary_GlobalSidebarWithoutRouter'}>
            <div className='sidebar'>
              <div className='sidebar-top'>
                <div className='logo'>
                  <img src={require('../../assets/images/logo-robot.svg')} alt='logo' />
                </div>
                <div className='cr-name'>
                  <img src={require('../../assets/images/logo-text.svg')} alt='' />
                </div>
                <div className='alpha'>ALPHA</div>

                {this.renderLoginButton()}
              </div>
            </div>
          </ErrorBoundary>
        </div>
      );
    }
  }
}

export default GlobalSidebar;
