import React, { Component } from 'react';
import { Col } from 'antd';
import { NavLink } from 'react-router-dom';

import stores from 'stores';
import { CoinruleButton } from '../../../components/CoinruleButton';

const analytics = window.mixpanel;

export default class AddPlanButton extends Component {
  clickBilling = () => {
    analytics.track('"Get Plan" button clicked', {
      page: 'Exchanges page',
    });
  };

  renderH2() {
    if (stores.user.user.plan.isPremium) {
      return 'You need to upgrade to connect more exchanges';
    }

    return 'You need a paid plan to connect more exchanges';
  }

  render() {
    if (this.props.show) {
      return (
        <Col lg={24} xl={10}>
          <h2>{this.renderH2()}</h2>
          <div className='getPlan'>
            <CoinruleButton
              fillType='solid'
              icon='Check'
              component={NavLink}
              to='/settings/billing'
              onClick={this.clickBilling}
            >
              Get Plan
            </CoinruleButton>
          </div>
          <h3>
            Your current plan is <b>{stores.user.user.plan.name}</b>
          </h3>
        </Col>
      );
    }

    return null;
  }
}
