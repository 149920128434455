import React from 'react'

import { Grid } from '@mui/material'

import { ExchangeLogoWithWordmarkSprite } from 'design-system'

import type { GridProps } from '@mui/material'
import type { TExchange, TExpandedExchange } from 'design-system'
import { BadgeOutlinedSuccess, Spacer } from 'design-system'
export interface ExchangeSelectorGridItemProps extends GridProps {
  selectExchange: (event: React.MouseEvent, isClickable: boolean, exchange: TExchange) => void,
  exchange: TExpandedExchange,
}

const testId = 'ExchangeSelectorGridItem'
export const ExchangeSelectorGridItem = (props: ExchangeSelectorGridItemProps) => {
  const {
    sx,
    selectExchange,
    exchange,
    ...otherProps
  } = props

  return (
    <Grid
      data-testid={testId}
      className={testId}
      onClick={(event: React.MouseEvent<Element, MouseEvent>) => selectExchange(event, exchange?.isClickable, exchange)}
      item={true}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '12px',
        marginTop: '12px',
        ...sx,
      }}
      {...otherProps}
    >
      {exchange.isNew ? <Spacer x={10} /> : null}
      <ExchangeLogoWithWordmarkSprite
        exchange={exchange?.uid}
        sx={{
          cursor: 'pointer',
          '& :hover': {
            opacity: '0.7'
          },
        }}
      />
      {exchange.isNew ? <Spacer x={10} /> : null}
      {
        exchange?.isNew &&
        <BadgeOutlinedSuccess
          sx={{
            top: '-11px',
          }}
        >
          new
        </BadgeOutlinedSuccess>
      }
    </Grid>
  )
}

export default ExchangeSelectorGridItem
