import React, { ReactNode } from 'react';

export interface ICoin {
  label: ReactNode;
  subLabel: ReactNode;
  value: string;
}

const coins: ICoin[] = [
  {
    label: 'Bitcoin',
    subLabel: 'BTC',
    value: 'BTC',
  },
  {
    label: 'Ethereum',
    value: 'ETH',
    subLabel: 'ETH',
  },
  {
    label: 'Tether',
    value: 'USDT',
    subLabel: 'USDT',
  },
  {
    label: 'Cardano',
    subLabel: 'ADA',
    value: 'ADA',
  },
  {
    label: 'Binance Coin',
    value: 'BNB',
    subLabel: 'BNB',
  },
  {
    label: 'Polkadot',
    value: 'DOT',
    subLabel: 'DOT',
  },
  {
    label: 'Ripple',
    value: 'XRP',
    subLabel: 'XRP',
  },
  {
    label: 'Uniswap',
    value: 'UNI',
    subLabel: 'UNI',
  },
  {
    label: 'Litecoin',
    value: 'LTC',
    subLabel: 'LTC',
  },
  {
    label: 'Chainlink',
    value: 'LINK',
    subLabel: 'LINK',
  },

  /////////////////
  {
    label: 'Dogecoin',
    value: 'DOGE',
    subLabel: 'DOGE',
  },
  {
    label: 'USD Coin',
    value: 'USDC',
    subLabel: 'USDC',
  },
  {
    label: 'Polygon',
    value: 'MATIC',
    subLabel: 'MATIC',
  },
  {
    label: 'Solana',
    value: 'SOL',
    subLabel: 'SOL',
  },
  {
    label: 'VeChain',
    value: 'VET',
    subLabel: 'VET',
  },
  {
    label: (
      <>
        Ethereum <br /> Classic
      </>
    ),
    value: 'ETC',
    subLabel: 'ETC',
  },
  {
    label: 'THETA',
    value: 'THETA',
    subLabel: 'THETA',
  },
  {
    value: 'WBTC',
    label: (
      <>
        Wrapped <br /> Bitcoin
      </>
    ),
    subLabel: 'WBTC',
  },
  {
    label: 'EOS',
    value: 'EOS',
    subLabel: 'EOS',
  },
  {
    label: 'TRON',
    value: 'TRX',
    subLabel: 'TRX',
  },
  {
    label: 'Filecoin',
    value: 'FIL',
    subLabel: 'FIL',
  },
  {
    label: 'Aave',
    value: 'AAVE',
    subLabel: 'AAVE',
  },
  {
    label: 'Monero',
    value: 'XMR',
    subLabel: 'XMR',
  },
  {
    label: 'Dai',
    value: 'DAI',
    subLabel: 'DAI',
  },
  {
    label: 'Neo',
    value: 'NEO',
    subLabel: 'NEO',
  },
  {
    label: 'SHIBA INU',
    value: 'SHIB',
    subLabel: 'SHIB',
  },
  {
    label: 'IOTA',
    value: 'MIOTA',
    subLabel: 'MIOTA',
  },
  {
    label: 'Maker',
    value: 'MKR',
    subLabel: 'MKR',
  },
  {
    label: 'PancakeSwap',
    value: 'CAKE',
    subLabel: 'CAKE',
  },
  {
    label: 'Klaytn',
    value: 'KLAY',
    subLabel: 'KLAY',
  },
  {
    label: 'Bitcoin SV',
    value: 'BSV',
    subLabel: 'BSV',
  },
  {
    label: 'Cosmos',
    value: 'ATOM',
    subLabel: 'ATOM',
  },
  {
    label: 'Tezos',
    value: 'XTZ',
    subLabel: 'XTZ',
  },
  {
    label: 'FTX Token',
    value: 'FTT',
    subLabel: 'FTT',
  },
  {
    label: (
      <>
        Crypto.com <br />
        Coin
      </>
    ),
    value: 'CRO',
    subLabel: 'CRO',
  },
  {
    label: 'Algorand',
    value: 'ALGO',
    subLabel: 'ALGO',
  },
  {
    label: 'Huobi Token',
    value: 'HT',
    subLabel: 'HT',
  },
  {
    label: 'Terra',
    value: 'LUNA',
    subLabel: 'LUNA',
  },
  {
    label: 'Bitcoin BEP2',
    value: 'BTCB',
    subLabel: 'BTCB',
  },
  {
    label: 'THORChain',
    value: 'RUNE',
    subLabel: 'RUNE',
  },
  {
    label: (
      <>
        UNUS SED <br /> LEO
      </>
    ),
    value: 'LEO',
    subLabel: 'LEO',
  },
  {
    label: 'Avalanche',
    value: 'AVAX',
    subLabel: 'AVAX',
  },
  {
    label: 'BitTorrent',
    value: 'BTT',
    subLabel: 'BTT',
  },
  {
    label: 'Compound',
    value: 'COMP',
    subLabel: 'COMP',
  },
  {
    label: 'Dash',
    value: 'DASH',
    subLabel: 'DASH',
  },
];

export default coins;
